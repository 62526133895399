import React from "react";
// compo
import MyEarnings from "../../distribution/myearnings";

function AdminRoyaltiesDisplayComponent() {
  return (
    <div className="adminRoyalties-display">
      <MyEarnings isAdmin />
    </div>
  );
}

export default AdminRoyaltiesDisplayComponent;
