import React, { useState } from "react";
import { Spinner } from "../../screens/Airplay/Airplay";
import ButtonComponent from "../form/ButtonComponent";
import InputComponent from "../form/InputComponent";
import ValidationTextBox from "../form/ValidationTextBox";
import { string } from "yup";
import X from "../../components/distribution/assets/X.svg";

const passwordValidationSchema = string().required("Password is required");

const ConfirmPasswordModal = ({
  onCloseRequest,
  onSubmit,
  helperText = "",
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [touched, setTouched] = useState(false);
  const [disabling, setDisabling] = useState(false);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setDisabling(true);

    await onSubmit(password, setErrorMsg);

    setDisabling(false);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);

    if (touched) {
      validate();
    }
  };

  const handlePasswordBlur = () => {
    validate();
    setTouched(true);
  };

  const validate = () => {
    try {
      passwordValidationSchema.validateSync(password);

      return true;
    } catch (error) {
      setErrorMsg(error.message);

      return false;
    }
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />

        <h2 className="text-white font-weight-bold">Confirm your password</h2>

        <div className="mt-4 infoText text-muted">{helperText}</div>

        <form onSubmit={handleOnSubmit}>
          <div className="mt-4">
            <div className="inputUpperComment iuc1a  d-none">Password</div>
            <InputComponent
              addon={false}
              alt="password"
              value={password}
              commentField=".iuc1a "
              paddingTopOnInput={true}
              placeholder="Enter your password"
              inputGroupTextClassName="inputGroupText"
              inputClassName="TwoFAInput"
              inputGroupClassName="nrInputGroup nrInputGroup-title"
              changeCallback={handlePasswordChange}
              inputType="password"
              databaseField="password"
              inputBlurCallback={handlePasswordBlur}
            />
            <ValidationTextBox
              className="loginValidationBox position-static ml-0"
              display={errorMsg?.length}
            >
              {errorMsg}
            </ValidationTextBox>
          </div>
          <div className="row justify-content-end ml-0 mt-4">
            <ButtonComponent
              className="playtreksButton bg-secondary w-100 h-100"
              disabled={!password}
              onClick={handleOnSubmit}
              type="submit"
            >
              {disabling ? (
                <>
                  Disabling...
                  <span className="ml-2">
                    <Spinner />
                  </span>
                </>
              ) : (
                "Disable"
              )}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmPasswordModal;
