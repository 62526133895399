import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import ReactTooltip from "react-tooltip";

const GRADIENT_STRETCH = global.config.gradientStretch;
const BACKGROUND_GRAY = "#525f7f";

const tooltips = [
  "Danceability <br/> Describes how suitable a track is for dancing based on a combination <br/> \
  of musical elements including tempo, rhythm stability, beat <br/> \
  strength, and overall regularity.",
  "Energy <br/> Represents a perceptual measure of intensity and activity. <br/> \
   Typically, energetic tracks feel fast, loud, and noisy.",
  "Valence <br/> Describes musical positiveness conveyed by a track. Tracks with high <br/> \
    valence sound more positive (e.g. happy, cheerful, euphoric).",
  "Speechiness <br/> Detects the presence of spoken words in a track. The more <br/> \
    exclusively speech-like the recording (e.g. talk show, audio book, <br/> \
    poetry), the closer to 1.0 the attribute value.",
  "Acousticness <br/> A confidence measure from 0.0 to 1.0 of whether the track is acoustic. <br/> \
     1.0 represents high confidence the track is acoustic.",
  "Instrumentalness <br/> Predicts whether a track contains no vocals. <br/> \
   “Ooh” and “aah” sounds are treated as instrumental in this context. <br/> \
    Rap or spoken word tracks are clearly “vocal”.",
  "Liveness <br/> Detects the presence of an audience in the recording. <br/> \
   Higher liveness values represent an increased probability that the track was performed live. ",
];

const getGradientOffset = (value) =>
  100 - value * 100 - Math.min(value * 100 + 2, GRADIENT_STRETCH);

function FeatureChart(props) {
  const width = props.width || 500;
  const height = props.height || 50;

  if (props?.featureChartData)
    var {
      danceability,
      energy,
      valence,
      speechiness,
      acousticness,
      instrumentalness,
      liveness,
    } = props.featureChartData;

  return (
    <span>
      <ResponsiveBar
        {...props}
        width={width}
        height={height}
        layout="vertical"
        colors={(d) => d.data.color}
        padding={0.5}
        enableGridY={false}
        label={(d) =>
          d.data.value !== false &&
          d.data.value !== undefined &&
          d.data.value !== "NaN"
            ? Math.round(d.data.value) + "%"
            : ""
        }
        axisBottom={{
          tickSize: 0,
          tickPadding: 7,
          tickRotation: 0,
          legend: "id",
          legendPosition: "bottom",
          legendOffset: 32,
        }}
        theme={{
          axis: {
            ticks: {
              text: {
                fill: "#ffffff",
                fontSize: "15px",
              },
            },
          },
          tooltip: {
            container: {
              background: "rgb(29, 32, 37)",
              border: "1px solid #0ef1db",
              borderRadius: "8px",
            },
          },
        }}
        margin={{ bottom: 30 }}
        tooltip={(object) => {
          let { value, tooltipColor, tooltipName } = object.data;
          if (value === "NaN") return <div></div>;
          return (
            <strong style={{ color: tooltipColor }}>
              {tooltipName}: {value}%
            </strong>
          );
        }}
        defs={[
          {
            id: "gradientA",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(danceability),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - danceability * 100,
                color: props.colorScheme[0],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientB",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(energy),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - energy * 100,
                color: props.colorScheme[1],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientC",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(valence),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - valence * 100,
                color: props.colorScheme[2],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientD",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(speechiness),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - speechiness * 100,
                color: props.colorScheme[3],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientE",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(acousticness),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - acousticness * 100,
                color: props.colorScheme[4],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientF",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(instrumentalness),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - instrumentalness * 100,
                color: props.colorScheme[5],
                opacity: 10,
              },
            ],
          },
          {
            id: "gradientG",
            type: "linearGradient",
            colors: [
              {
                offset: getGradientOffset(liveness),
                color: BACKGROUND_GRAY,
              },
              {
                offset: 100 - liveness * 100,
                color: props.colorScheme[6],
                opacity: 10,
              },
            ],
          },
        ]}
        fill={[
          { match: { id: "danceability" }, id: "gradientA" },
          { match: { id: "energy" }, id: "gradientB" },
          { match: { id: "valence" }, id: "gradientC" },
          { match: { id: "speechiness" }, id: "gradientD" },
          { match: { id: "acousticness" }, id: "gradientE" },
          { match: { id: "instrumentalness" }, id: "gradientF" },
          { match: { id: "liveness" }, id: "gradientG" },
        ]}
        pointSize={10}
        pointColor={{ theme: "background" }}
        borderColor={{ theme: "background" }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
      <div style={{ display: "inline-flex", width: "100%" }}>
        {tooltips.map((tip, i) => (
          <div
            data-tip={tip}
            data-for="featuresGraphTooltip"
            style={{
              position: "relative",
              // display: "inline-block",
              bottom: 30,
              height: "40px",
              width: i === 0 ? "16%" : "14%",
            }}
          />
        ))}
        <ReactTooltip
          place="bottom"
          backgroundColor="#1d2025"
          className="ptTooltip"
          border
          borderColor="#0ef1db"
          effect="float"
          multiline={true}
          id="featuresGraphTooltip"
        />
      </div>
    </span>
  );
}
export default FeatureChart;
