import { getJWT } from "../../components/utils";
import axios from "axios";
import { parseQueryParams } from "../utils";

export async function getAllNewUsersSales({ queryKey }) {
  let [_key, queryParams] = queryKey;

  if (queryParams.group === "artist") {
    queryParams.fields = "user";
  }
  if (queryParams.group === "release") {
    queryParams.fields = "upc";
  }
  if (queryParams.group === "track") {
    queryParams.fields = "isrc";
  }

  let url = `${global.config.API_URL}/api/admin/royalties/new${parseQueryParams(
    queryParams
  )}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
}

export async function getMyEarningsFilters(admin = false) {
  let url = `${global.config.API_URL}/api/sales/filters${
    admin ? "?admin=true" : ""
  }`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
}
