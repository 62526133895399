import React, { useEffect, useState } from "react";
// import Sidebar from "../artistDashboard/sidebar";
import Sidebar from "../../../artistDashboard/sidebar";
import MobileMenuBurger, {
  MobileMenu,
} from "../../../artistDashboard/MobileMenu/MobileMenu";

export default function DistributionMetadataGuidelines() {
  return (
    <div>
      <div
        style={{ overflowX: "none !important", backgroundColor: "#343a47" }}
        className="ptTermsContent welcomePage"
      >
        {/* <MobileMenu /> */}

        <p class="c11">
          <span class="c9">Metadata Guidelines</span>
        </p>
        <p class="c11 c5">
          <span class="c9"></span>
        </p>
        <ul class="c4 lst-kix_q0b8vhftgkde-0 start">
          <li class="c1 c2">
            <span class="c6">Genre requirements</span>
          </li>
        </ul>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ol class="c4 lst-kix_hupb5ur8bgia-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              General requirements. Genre must always match with the content of
              the tracks. Current genre list is available on the platform, both
              at the album level and at the track level.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Restrictions for classical music. The genre
              &ldquo;Classical&rdquo; and the ones derived (e.g.
              &ldquo;Classical/Orchestral&rdquo;, &ldquo;Classical/Opera&rdquo;
              or &ldquo;Classical/Piano&rdquo;) can not be sent to the iTunes
              and Apple Music channels unless they are original or modern
              compositions.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of the genre &ldquo;Soundtrack&rdquo;. The genre
              &ldquo;Soundtrack&rdquo; only must be used when the music is
              related to movies, documentaries, series, musicals, video games or
              any other audiovisual productions.
            </span>
          </li>
        </ol>
        <p class="c3">
          <span class="c0">
            If the music is only &ldquo;inspired&rdquo; in a video work or is
            not connected to any media production, the genre tag can not be
            &ldquo;Soundtrack&rdquo; but the one that indicates the style of the
            song.
          </span>
        </p>
        <ol class="c4 lst-kix_hupb5ur8bgia-0" start="4">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of the genre &ldquo;Karaoke&rdquo;. When an album or a
              track is a karaoke or a playback, the primary genre must be
              &ldquo;Karaoke&rdquo;. The secondary genre should be the one
              corresponding to the song&rsquo;s style.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of the genre &ldquo;Fitness &amp; Workout&rdquo;. This
              genre can be used as long as it&rsquo;s in line with the metadata
              and the release&rsquo;s concept. Generic names such as
              &ldquo;Motivational&rdquo;, &ldquo;Workout&rdquo; or
              &ldquo;Cardio&rdquo; can not be used at the artist&rsquo;s and
              title&rsquo;s level.
            </span>
          </li>
        </ol>
        <p class="c11 c5">
          <span class="c6"></span>
        </p>
        <ul class="c4 lst-kix_wenooyyh82w0-0 start">
          <li class="c1 c2">
            <span class="c6">Titles of albums and tracks</span>
          </li>
        </ul>
        <p class="c1 c5">
          <span class="c6"></span>
        </p>
        <ol class="c4 lst-kix_g8ps5vukws4e-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Accuracy. Albums and tracks titles, should always match the
              cover image titles as accurately as possible without any class of
              abbreviation.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Additional information. All titles of albums and tracks
              should not include additional information, unless it is really
              necessary for the identification of the content.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Generic titles. Do not use generic titles for the tracks.
              Titles as &ldquo;Track 01&rdquo;, &ldquo;Track 02&rdquo;, will not
              be accepted unless they are really the original track titles. This
              same rule is applied for the release titles, titles as
              &quot;Album&quot;, &quot;EP&quot; or &quot;Single&quot; won&#39;t
              be accepted. &nbsp;
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Multiple titles for album and tracks. The albums and tracks
              containing multiple titles must be separated with a slash
              (&ldquo;/&rdquo;). Please insert a space before and after the
              slash.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Tracks version information. To differentiate between
              multiple versions of the same track or indicate that a track is
              different from its original version, use the corresponding
              description in its version field.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of &quot;Exclusive&quot; or &quot;Limited Edition&quot;.
              The titles of albums and / or tracks must not contain terms such
              as &quot;Exclusive&quot; or &quot;Limited Edition&quot;, because
              the titles are a permanent part of the content.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              References to physical content, digital content, or content which
              is not included. The album titles should not include terms
              referring to contents which are not included in the album. Such
              as, physical formats, digital formats or geographic location. Some
              examples are: E-Release, Digital Version, Digital Only, Digital
              Download, with Booklet, European Edition, American Edition, etc.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;References to video format. The titles must not include
              references to the video format or specifications like
              &ldquo;Video&rdquo;, &ldquo;Clip&rdquo;, &ldquo;PAL&rdquo; or
              &ldquo;Music Video&rdquo;.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Unnecessary version information. The standard version, i.e.,
              the original version of a track, must not include additional
              information. For example: Original Version, Album Version,
              Original Mix, etc.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Live content. If a track is recorded live, it must be
              indicated in the version field as &quot;Live&quot;, &quot; Ao
              Vivo&quot;, &quot;En Vivo&quot;, and so on, according to the
              language of the album. If all tracks are recorded live, it must
              also be indicated in the version field of the album.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;&ldquo;Deluxe Edition&rdquo; / &ldquo;Extended&rdquo; and
              &ldquo;Bonus Tracks&rdquo; use. A &quot;Deluxe Edition&rdquo; or
              &ldquo;Extended&rdquo; release can be published when a previous
              album version exists, providing additional material to the first
              one. The incorporation of the old material can&#39;t be omitted.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            As well, a &ldquo;Bonus Track&rdquo; will be accepted if it is
            included in this type of releases. It must be indicated on the field
            &ldquo;Version or remix&rdquo;. In no case will be accepted a track
            title as &ldquo;Bonus Track&rdquo;.
          </span>
        </p>
        <ol class="c4 lst-kix_g8ps5vukws4e-0" start="12">
          <li class="c1 c2">
            <span class="c0">
              Censoring Words. Artist names, track titles, and album titles must
              be submitted in the original form that was intended by the artist.
              Explicit words are automatically censored in some channels, and
              would appear as for example: &ldquo;f**k&rdquo; or
              &ldquo;s**t&rdquo;. So do not insert the asterisks in the titles.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Side-by-side translations. Side-by-side translations in
              Korean are not allowed (this means, translating the title to the
              English and including it in the own title as a part of it). For
              example: &quot;&#51060;&#52852;&#47336;&#49828;&quot; is correct,
              but &quot;Rise &#51060;&#52852;&#47336;&#49828;&quot; won&#39;t be
              accepted. The rest of languages cannot include this resource
              either.
            </span>
          </li>
        </ol>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_vdi041mfx8jj-0 start">
          <li class="c1 c2">
            <span class="c6">Artist or band names</span>
          </li>
        </ul>
        <p class="c1 c5">
          <span class="c6"></span>
        </p>
        <ol class="c4 lst-kix_vtjmw2i4meq-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Artist name choice. Before distributing your album, make
              sure that no other artists are currently using the same artist or
              band name (similar or identical), since this can cause conflicts
              in the channels and your albums may be combined under the catalog
              of the other artist with similar artist or band name, or
              vice-versa.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Roles at album and track level. In the releases with two or
              more tracks, only the primary artists should be featured at the
              album level. Except from classical albums, where the composer/s
              must be introduced at the album level too.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            About one-track singles, they must have the same information at the
            album and track level. Therefore all the roles have to be reflected
            at the album level.
          </span>
        </p>
        <ol class="c4 lst-kix_vtjmw2i4meq-0" start="3">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Primary artist assignation. The following must be
              considered:
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_87p9y24lo3ge-0 start">
          <li class="c1 c2">
            <span class="c0">If the release has two or more tracks:</span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If there is only 1 primary artist, the main artist must appear as
              the primary throughout all the tracks and also at the album level.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If there are 2 or 3 primary artists, each one of them must appear
              at least one time in one track. And all of them must appear at the
              album level.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If there are 4 or more primary artists, each one of them must
              appear in their corresponding track. At the album level the
              primary artist must be &ldquo;Various Artists&rdquo;, as more than
              three primary artists can not be included.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If the release is a one-track single: The primary artists must be
              the same at the album and the track level.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_vtjmw2i4meq-0" start="4">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of &quot;Various Artists&quot;. If there are four or
              more primary artists on the album, the artist name to be mentioned
              at album&rsquo;s level must be &quot;Various Artists&quot;. The
              tag &ldquo;Various Artists&rdquo; can not be used at the track
              level and can not be combined with other primary artists&rsquo;
              names. At the track level is preferable that do not appear too
              many primary artists&rsquo; names. It is important not to confuse
              the role with &quot;performer&quot;. For example, if it&#39;s
              about a band, the band name must appear as primary artist and the
              members names as performers.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Incorrect display of &quot;Various Artists&quot;. Variations
              or abbreviations of &quot;Various Artists&quot; (e.g.
              &quot;V/A&quot;, &quot;VA&quot;, &quot;Various&quot;,
              &quot;Various Artist&quot; or similar) should not be used as an
              artist name for the content in English. The translation of
              &quot;Various Artists&quot; is accepted, but only if it&rsquo;s
              consistent with the language of the content.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Composer. Composers have to be indicated only at the track
              level, except from one-track singles and in classical music.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            In classical releases the composers have to appear at both levels
            (track and album). If there are more than 4 composers appearing in
            all tracks the only composer introduced at the album level will be
            &ldquo;Various Composers&rdquo;. Please, do not use the term
            &ldquo;Various Composers&rdquo; in non-classical releases.
          </span>
        </p>
        <ol class="c4 lst-kix_vtjmw2i4meq-0" start="7">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Format. The spelling of the artists&rsquo; names must be
              correct and consistent across the entire contents of that artist.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            The artist&rsquo;s name should not contain all capital letters
            except from:
          </span>
        </p>
        <ul class="c4 lst-kix_ffbl3rs0kvgh-0 start">
          <li class="c1 c2">
            <span class="c0">
              Cacophonies. That is unpronounceable words usually consisting of
              various consonants altogether.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Real acronyms. The dots are compulsory in these cases.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Special requests. If a profile is already created in the channels
              with capital letters or there&rsquo;s a special request to keep
              the artist&rsquo;s name in capital letters backed with social
              networks&rsquo; profiles and other official web pages, then the
              name can be sent in capital letters. This request has to be
              notified to the Support Team prior to the upload of the album.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_vtjmw2i4meq-0" start="8">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Other information. The artist&#39;s name must not include
              any additional information, such as the role, dates, instruments,
              band type, web page, etc.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Compound artist. Each artist field must only contain one
              artist name.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            Two or more performers or groups in the same artist field are
            considered a compound artist. However, artists who are generally
            listed together as a band (their names make the artist&rsquo;s name)
            are not considered compound artists and can be listed together.
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            If this is not the case, and there is more than one performing
            artist, each artist must be listed individually and assigned as
            Primary.
          </span>
        </p>
        <ol class="c4 lst-kix_vtjmw2i4meq-0" start="10">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Generic artists. Generic artists such as
              &ldquo;Chorus&rdquo;, &ldquo;Orchestra&rdquo; and
              &ldquo;Singer&rdquo; are not accepted in any genre. The artist
              names should be specific to a person, a group or band, a show or a
              production.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Original artist name in the artist field. For karaoke,
              tribute, orgel, parody, cover albums and ringtones, the name of
              the original artist must not be displayed in any artist
              &#64257;eld.
            </span>
          </li>
        </ol>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_2zbajt46tzp5-0 start">
          <li class="c1 c2">
            <span class="c6">
              Using uppercase, lowercase, abbreviations, accents and special
              characters
            </span>
          </li>
        </ul>
        <p class="c11 c5">
          <span class="c6"></span>
        </p>
        <p class="c3">
          <span class="c0">
            To ensure that the accents and capitalization appear correctly on
            all channels, you must specify an appropriate spelling in the
            metadata fields. Our Quality Control team reserves the right to
            correct errors in grammar, in spelling and punctuation.
          </span>
        </p>
        <ol class="c4 lst-kix_upk6d5ujesih-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Nonstandard capitalization. Titles have to contain capital
              letters at the beginning of each sentence and in proper names. For
              other cases, please check the next points.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            The arbitrary use of capital letters can be accepted if the first
            word&rsquo;s letter is in capital and is not written entirely in
            capital letters.
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            Titles should not contain all capital letters except in the
            following cases:
          </span>
        </p>
        <ul class="c4 lst-kix_ylrswgrh4hd5-0 start">
          <li class="c1 c2">
            <span class="c0">
              If there is a cacophony. It means a succession of letters that
              result in an unpronounceable word and that can be associated to
              some acronyms.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If they are real acronyms. In that case, it is obligatory to
              insert the letters with dots (.).
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              For the artist&rsquo;s names. Only when there&rsquo;s a requested
              update in the channels from this profile or with a previous
              notification asking for the preservation of the name entirely
              written in capital letters. To communicate this kind of questions,
              please contact our Support Team.
            </span>
          </li>
        </ul>
        <p class="c1">
          <span class="c0">
            Each language has its own requirements in the use of capital
            letters. For more information, please check Language requirements.
          </span>
        </p>
        <ol class="c4 lst-kix_upk6d5ujesih-0" start="2">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Abbreviation. The words &quot;Part&quot; and
              &quot;Volume&quot; should be abbreviated as &quot;Pt.&quot; and
              &quot;Vol.&quot;.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            The titles that require the use of one of those two words, should
            have the following format: &quot;Title, Vol. X&quot; and
            &quot;Title, Pt. X&quot; (where &quot;X&quot; is a number).
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            The use of abbreviations will be obligatory when there is an
            homonymous topics succession in different parts, or when there is a
            numerical succession with same titles songs. The use of Arabic or
            Roman numbers can be chosen by the user, but it will have to support
            the criterion along the album.
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            Preferably the expression &quot;Pt.&quot; has to be used only for
            tracks and &quot;Vol.&quot; just for albums, with the exception of
            the singles, where is better to obviate the expression
            &quot;Vol.&quot; unless it belongs to the real track title.
          </span>
        </p>
        <ol class="c4 lst-kix_upk6d5ujesih-0" start="3">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Accents and required characters. All western languages
              should include all appropriate accents and characters, as required
              by the correct spelling of each language respectively.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            Spelling mistakes will be allowed only if it is possible to argue
            any premeditation in certain contexts.
          </span>
        </p>
        <ol class="c4 lst-kix_upk6d5ujesih-0" start="4">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Use of special characters. Special characters use (as $,
              &cent;, &infin;, &not;, _, +, =, &ne;, &acute;&sbquo; `, *, etc.)
              won&#39;t be accepted, except when:
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_6tfv8sw242c9-0 start">
          <li class="c1 c2">
            <span class="c0">
              Same content or / and artist has been published previously in the
              same channels and the info can be confirmed by QC Team.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Special characters belong to a trademark and its confirmation
              document can be presented.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Following symbols: &lt;, &gt;, |, /, \, -, , , ., :, ;, !,
              &iexcl;, ?, &iquest;, -, (, ), &middot;, &amp;, &quot;, &lsquo;.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Following symbols as long as they go with a digit: &ordm;, %.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Following symbols as long as they are not used with promotional
              proposes or referring to social media: #, @.
            </span>
          </li>
        </ul>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <p class="c11 c5">
          <span class="c6"></span>
        </p>
        <ul class="c4 lst-kix_c1y8gycb59nn-0 start">
          <li class="c1 c2">
            <span class="c6">Languages requirements</span>
          </li>
        </ul>
        <ol class="c4 lst-kix_s3cxzytub1kh-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Assigning languages. The language at the album level must be
              the same as the titles. If there are various languages in the
              titles, the main one has to be chosen.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            The language at the track level should be the one used in the song.
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            If the language does not appear in the list, the most similar one
            must be assigned.
          </span>
        </p>
        <p class="c1">
          <span class="c0">
            If the track is instrumental or the language is invented, then it
            must be indicated with the option &ldquo;no linguistic
            content&rdquo;.
          </span>
        </p>
        <ol class="c4 lst-kix_s3cxzytub1kh-0" start="2">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Considerations on non-Latin metadata. If an album contains
              one of the following languages in the metadata, it must follow the
              requirements settled below.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_4s19zikbjx2a-0 start">
          <li class="c1 c2">
            <span class="c0">
              Hebrew metadata. All the titles and names must be written in
              Hebrew. Transliterations are not allowed.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Chinese, Japanese, Korean and Thai metadata. The metadata must be
              introduced accordingly to the original alphabet. However, the
              artist&rsquo;s names in Chinese and Korean must be written in
              English (or transliterated). For instance, only the second option
              would be valid in the following example:
            </span>
          </li>
        </ul>
        <ul class="c4 lst-kix_4s19zikbjx2a-1 start">
          <li class="c11 c2 c12">
            <span class="c0">Korean: &#48149;&#49888;&#54812;</span>
          </li>
          <li class="c11 c12 c2">
            <span class="c0">English: Park Shin Hye</span>
          </li>
        </ul>
        <ul class="c4 lst-kix_4s19zikbjx2a-0">
          <li class="c1 c2">
            <span class="c0">
              Russian, Belarusian, Bulgarian and Ukranian metadata. The releases
              in these languages must use the Cyrillic alphabet.
              Transliterations are not allowed, and the titles should follow the
              sentence format.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Arabic and farsi metadata. All the metadata in arabic and farsi
              albums must be written according to their original alphabet.
              Transliterations and translations are not allowed.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_s3cxzytub1kh-0" start="3">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Side-by-side translation. Side-by-side translations are not
              allowed. This means, translating the title to the English and
              including it in the own title as a part of it.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            For example: &ldquo;&#51060;&#52852;&#47336;&#49828;&rdquo; is
            correct, but &ldquo;Rise &#51060;&#52852;&#47336;&#49828;&rdquo;
            wouldn&rsquo;t be accepted.
          </span>
        </p>
        <ol class="c4 lst-kix_s3cxzytub1kh-0" start="4">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Layout of the titles. Depending on the language, the
              capitalization of the titles can differ.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_jw5hwd2vna9u-0 start">
          <li class="c1 c2">
            <span class="c0">
              Titles in English. The titles for albums and tracks in English
              language should have a title case format (all words are
              capitalized except articles and conjunctions). In addition, the
              first letter of the words before and after a hyphen
              (&ldquo;-&rdquo;), a slash (&ldquo;/&rdquo;) or a colon
              (&ldquo;:&rdquo;) and at the beginning and the end of a sentence
              should be capitalized.
            </span>
          </li>
        </ul>
        <p class="c1">
          <span class="c0">
            The following words should always be lowercase:
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            a, an, and, as, but, for, from, nor, of, or, so, the, to, y yet, at,
            by, for, from, in, into, of, off, on, onto, out, over, to, up, with
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            For example: &ldquo;In the Still of the Night&rdquo;.
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            As an exception to this rule, these words must be capitalised as
            long as they are part of a phrasal verb.
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_qbjves7baki9-0 start">
          <li class="c1 c2">
            <span class="c0">
              Titles in Spanish and Portuguese. For albums and tracks&rsquo;
              titles in Spanish and Portuguese, you can decide on either title
              or sentence casing, as long as the format is consistent throughout
              the entire album and the first letter of each sentence is
              uppercase. In addition, the first letter of the words before and
              after a hyphen (&ldquo;-&rdquo;), a slash (&ldquo;/&rdquo;) or a
              colon (&ldquo;:&rdquo;) and at the beginning and the end of a
              sentence should be capitalized.
            </span>
          </li>
        </ul>
        <p class="c1">
          <span class="c0">
            The following words in Spanish should always be in lower case:
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            a, al, de, del, e, el, en, la, las, los, o, para, por, un, una, y
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            The following words in Portuguese should always be in lower case:
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c1">
          <span class="c0">
            a, &agrave;, ao, aos, as, &agrave;s, da, das, de, das, do, dos, e,
            em, na, nas, no, nas, nos, o, os, ou, para, pela, pelas, pelo,
            pelos, pra, pro, por, um, uma
          </span>
        </p>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_v9gcqylu47et-0 start">
          <li class="c1 c2">
            <span class="c0">
              Titles in Swedish, French, Italian and Latin. The titles of albums
              and tracks in Swedish, French, Italian and Latin should be in
              sentence format, therefore only the first word of the sentence
              should have the first letter capitalized, all others must be
              lowercase, except in cases of names and / or abbreviations.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Titles in German. German albums and tracks&rsquo; titles must use
              sentence case, following in this case the rules of capitalisation
              of this language.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_s3cxzytub1kh-0" start="5">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Accents and required characters. All western languages
              should include all appropriate accents and characters, as required
              by the correct spelling of each language respectively.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Explicit lyrics. The box of &ldquo;Explicit lyrics&rdquo;
              must be checked at the track level when the title or the lyrics
              have explicit content (mostly if they mention topics like sex,
              drugs or violence). If the explicit tracks are indicated, the
              album will be automatically flagged as explicit.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_oexkj5ppihxh-0 start">
          <li class="c1 c2">
            <span class="c0">
              If the cover image contains the logo of &ldquo;Parental
              Advisory&rdquo;, at least one track must be flagged as explicit.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If the album is flagged as explicit, the tracks with explicit
              content must be indicated. Unless the cover image is the only
              explicit content of the album (and the lyrics and the metadata are
              clean), in this case the tracks have to be kept as clean.
            </span>
          </li>
        </ul>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_wslgdaod3ta1-0 start">
          <li class="c1 c2">
            <span class="c6">Lyrics Requirements</span>
          </li>
        </ul>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ol class="c4 lst-kix_dw96113umqo3-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Current supported languages. Currently, the languages our QC
              Team supports are: English, Espa&ntilde;ol, Fran&ccedil;ais,
              Catal&agrave;, Portugu&ecirc;s, Italiano. Other languages can
              still be used and included, but if they are not respecting
              strictly the following requirements, they could not be accepted by
              iTunes and they will probably be deleted from the platform.
              Please, remember that instrumental music cannot include lyrics and
              its language must always be &ldquo;no linguistic content&rdquo;.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Structure. Lyrics must reflect the structure of the song.
              Each sentence must be separated by a single space, and each
              different section (for example: chorus, verse, bridge&hellip;)
              must be separated by a double space. The first letter of every
              word at the beginning of a sentence or a parenthesis must be
              written in capital letters, as well as proper nouns. Please, do
              not include additional information to the lyrics, such as the
              chords, the title of the track, the composers, etc. Also, do not
              write the lyrics only in capital letters or lowercase.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Punctuation. The punctuation has to follow the grammar rules
              of each language. However, stops and commas are not allowed at the
              end of a sentence, neither repeated punctuation (like
              &ldquo;??&rdquo; or &ldquo;!!&rdquo;). Ellipses
              (&ldquo;...&rdquo;) are only permitted to indicate a fade out.
              Expressions like &ldquo;x2&rdquo; or &ldquo;etc.&rdquo; are
              forbidden when it comes to a repetition, the lyrics should be
              written as many times as they are expressed or they should finish
              with an ellipse if they fade out. &nbsp;
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Secondary content. All the lyrics that are related to the
              main content of the song must be transcribed.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_qx9zquo9t3iz-0 start">
          <li class="c1 c2">
            <span class="c0">
              Background vocals must be indicated in parenthesis.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Live speeches must be written as long as they do not interfere in
              the flow of the song. This is only applied to live content.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Non-word vocal sounds must be transcribed unless it is
              improvisation (like scatting) or sound effects.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">Spoken word content must not be transcribed.</span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              Explicit content must be written as it is heard in the song.
              Please, do not censor the words unless they are actually censored
              in the song itself. &nbsp;If a part or a full word has been
              censored in the audio, the part that is missing has to be replaced
              by asterisks (for example, &ldquo;f***&rdquo;).
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If there are different languages in the same track, they have to
              be transcribed following each language rules (not phonetically).
            </span>
          </li>
        </ul>
        <p class="c1">
          <span class="c0">
            See an example below of a correct way of writing lyrics:
          </span>
        </p>
        <p class="c3 c5">
          <span class="c0"></span>
        </p>
        <p class="c3">
          <span class="c0">I live for the applause, applause, applause</span>
        </p>
        <p class="c3">
          <span class="c0">I live for the applause-plause</span>
        </p>
        <p class="c3">
          <span class="c0">Live for the applause-plause</span>
        </p>
        <p class="c3">
          <span class="c0">
            Live for the way that you cheer and scream for me
          </span>
        </p>
        <p class="c3">
          <span class="c0">The applause, applause, applause</span>
        </p>
        <p class="c3">
          <span class="c0">
            Give me that thing that I love (I&#39;ll turn the lights out)
          </span>
        </p>
        <p class="c3">
          <span class="c0">
            Put your hands up, make &#39;em touch, touch (make it real loud)
          </span>
        </p>
        <p class="c3">
          <span class="c0">
            Give me that thing that I love (I&#39;ll turn the lights out)
          </span>
        </p>
        <p class="c3">
          <span class="c0">
            Put your hands up, make &#39;em touch, touch (make it real loud)
          </span>
        </p>
        <p class="c3">
          <span class="c0">(A-P-P-L-A-U-S-E) Make it real loud</span>
        </p>
        <p class="c3">
          <span class="c0">
            (A-P-P-L-A-U-S-E) Put your hands up, make &#39;em touch, touch
          </span>
        </p>
        <p class="c3">
          <span class="c0">(A-P-P-L-A-U-S-E) Make it real loud</span>
        </p>
        <p class="c3">
          <span class="c0">
            (A-P-P-L-A-U-S-E) Put your hands up, make &#39;em touch, touch
          </span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <p class="c3">
          <span class="c0">
            Please, keep in mind that, since these are optional and not
            mandatory aspects of a release, our Support team reserves the right
            to erase the lyrics of a track or a whole release in case these
            rules are not strictly followed.
          </span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_nceatw2wa6ie-0 start">
          <li class="c1 c2">
            <span class="c6">
              Versions, karaokes, tributes, parodies, orgels and covers
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_bp61udftushj-0 start" start="1">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Versions. To indicate that a Version track is different from
              the original, please use the corresponding field for this purpose.
              &nbsp;
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_cf2o7bc4fv85-0 start">
          <li class="c1 c2">
            <span class="c0">
              Version information will not be accepted as a part of a title.
              Except from &ldquo;palos&rdquo; (flamenco) and popular dances of
              the XXth century (like tango or vals), which must be indicated
              between parenthesis after the main title.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              The information should follow the standard spelling, without
              abbreviations, and make a proper use of capitalization.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_bp61udftushj-0" start="2">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Name of the original artist on parody, version, karaoke,
              tribute and cover tracks or albums. For parodies, karaokes,
              tributes and cover albums, the name of the original artist must
              not be displayed in any artist field.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Name of the original artist in the track or album titles.
              The titles included in a cover or tribute album must not make any
              reference to the original artist. Do not use phrases such as:
              &ldquo;Original Performed by&rdquo;, &ldquo;In the Style
              of&rdquo;, &ldquo;Tribute to&rdquo;, &ldquo;Cover of&rdquo; or
              similar.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Deceptive or misleading information. Tribute or cover albums
              must not be deceptive or misleading. Do not use genres, popular
              song lyrics or the original artist names as the album title, track
              title or artist for karaoke.
            </span>
          </li>
        </ol>
        <p class="c1">
          <span class="c0">
            Content that is considered deceptive or misleading will not be
            accepted and distributed.
          </span>
        </p>
        <ol class="c4 lst-kix_bp61udftushj-0" start="5">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Information of karaoke version. The karaoke albums or tracks
              must be indicated with the expression &ldquo;Karaoke
              Version&rdquo; in the corresponding field.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_xtwsv3f1le6w-0 start">
          <li class="c1 c2">
            <span class="c0">
              The primary genre must also be set as &ldquo;Karaoke&rdquo;, while
              the secondary one must show the main style of the song or the
              release.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              The original composers or artists can not appear in any part of
              the metadata.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If the tracks are instrumental, the language introduce at the
              track level must be &ldquo;no linguistic content&rdquo;, while the
              one selected for the album must be the one of the metadata.
            </span>
          </li>
        </ul>
        <ol class="c4 lst-kix_bp61udftushj-0" start="6">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Sound-alikes and unauthorized remixes. Sound-alikes (cover
              songs that sound like copies of the original) or unauthorized
              remixes with deceptive or misleading audio will not be
              distributed.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Cover licenses. In some cases, you may need a license to
              distribute a cover, as the work covered can be protected by
              Copyright laws.
            </span>
          </li>
        </ol>
        <ul class="c4 lst-kix_r0wm2nu16d9i-0 start">
          <li class="c1 c2">
            <span class="c0">
              If your release is a version of a song registered in a
              Spanish-speaking or latin country (Southern Europe, South America
              and Central America, with the exception of Mexico), you only need
              to indicate the composers of the original track.
            </span>
          </li>
          <li class="c1 c2">
            <span class="c0">
              If your release is a cover of a song registered in an
              Anglo-American country or by a Major (e.g. &ldquo;Universal Music
              Latino&rdquo;, &ldquo;Sony Music Latino&rdquo;, etc.), a
              mechanical license is required to distribute the new song.
            </span>
          </li>
        </ul>
        <p class="c5 c11">
          <span class="c0"></span>
        </p>
        <p class="c3">
          <span class="c0">
            This license can be an authorization signed by the original rights
            owner or by a management society, who will allow you to use the work
            for commercial purposes.
          </span>
        </p>
        <p class="c3">
          <span>
            You can obtain a license through different online services, as for
            example{" "}
          </span>
          <span class="c10">
            <a
              class="c7"
              href="https://www.google.com/url?q=http://www.easysonglicensing.com/&amp;sa=D&amp;ust=1608740927156000&amp;usg=AOvVaw37WFpYENFowACnspJPSIZ6"
            >
              Easy Song Licensing
            </a>
          </span>
          <span>
            . Finding out who owns the rights has become a lot easier these
            days. There are organisations in each country to help to find out
            who the publishers are. In the US there is{" "}
          </span>
          <span class="c10">
            <a
              class="c7"
              href="https://www.google.com/url?q=http://www.bmi.com/search/&amp;sa=D&amp;ust=1608740927157000&amp;usg=AOvVaw04HOi7koAl6qXqeKKfRNaT"
            >
              Broadcast Music, Inc. (BMI)
            </a>
          </span>
          <span>&nbsp;and/or </span>
          <span class="c10">
            <a
              class="c7"
              href="https://www.google.com/url?q=http://www.ascap.com/&amp;sa=D&amp;ust=1608740927157000&amp;usg=AOvVaw1dNhk2J7_0YGDAtlOKasr5"
            >
              ASCAP
            </a>
          </span>
          <span class="c0">
            &nbsp;to get the publisher&rsquo;s information.Once you know who the
            publisher is, you can then apply for a mechanical licence.
          </span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ul class="c4 lst-kix_qm9rgnatn9bs-0 start">
          <li class="c1 c2">
            <span class="c0">
              If the version or cover is from a territory that is not included
              above, please, contact our Support team for more information.
            </span>
          </li>
        </ul>
        <p class="c1 c5">
          <span class="c0"></span>
        </p>
        <p class="c3">
          <span class="c0">
            If the version modifies substantially the work, as a radical
            variation of the lyrics / musical content, you must present a more
            specific rights owner permission to distribute the content. The
            documentation reported has to be contrastable and true.
          </span>
        </p>
        <p class="c3">
          <span class="c0">
            Please, do not mix up the terms &ldquo;Version&rdquo; or
            &ldquo;Cover&rdquo; with &ldquo;Remix&rdquo;. If you do a remix or
            you are using any recording samples that belong to another artist,
            an authorization from the original master&rsquo;s owner must be
            requested.
          </span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <ol class="c4 lst-kix_bp61udftushj-0" start="8">
          <li class="c1 c2">
            <span class="c0">
              &nbsp;Continuous mixes and DJ sets. DJ sets (with own or other
              artists tracks) and continuous mixes (that is, putting all the
              tracks of an album together in a single track creating a
              continuous mix) are not allowed.
            </span>
          </li>
        </ol>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>
        <p class="c11 c5">
          <span class="c0"></span>
        </p>

        <div className="ppFooter">
          {/*<div className="lpfText1">{t("lpFooterText1")}</div>
        <br 
        <div className="lpfText2">{t("lpFooterText2")}</div> }
        <br /> 
        <ButtonComponent
          className="playtreksButton lpButton lpBtn5"
          onClick={handleSendEmail}
        >
          {t("contactPt")}
        </ButtonComponent> */}

          {/* <div className="lpfLegal">
            © 2021 Playtreks BV, Hendrik Van Velde Singel 150, 3500 Hasselt,
            Belgium Company No. BE0749505934 &nbsp;&nbsp; <a href="/">Home</a>{" "}
            &nbsp;&nbsp; <a href="/About">About</a>&nbsp;&nbsp;{" "}
            <a href="/HowitRolls">How it rolls</a>&nbsp;&nbsp;{" "}
            <a href="/privacypolicy">Privacy Policy</a> &nbsp;&nbsp;{" "}
            <a href="/terms">Terms & Conditions</a>
          </div> */}

          {/* <div className="lpfSocials">
          <img
            alt="fb"
            src={fb}
            onClick={() =>
              window.open("https://www.facebook.com/playtreksapp/")
            }
            style={{ cursor: "pointer" }}
          />
          <img
            alt="tw"
            src={tw}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://twitter.com/PlayTreks")}
          />
          <img
            alt="ig"
            src={ig}
            onClick={() => window.open("https://www.instagram.com/playtreks/")}
            style={{ cursor: "pointer" }}
          />
        </div> */}
        </div>
      </div>
    </div>
  );
}

// module.exports = { DistributionMetadataGuidelines };
