import React from "react";

function ValidationTextBox(props) {
  return (
    <>
      {props.display ? (
        <div
          className={
            "valTextBox " + (!props.noMt ? "mt-1 " : "") + props.className
          }
          style={props.style}
        >
          {props.children}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ValidationTextBox;
