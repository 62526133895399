import {
  REDUX_SET_CURRENT_FILTERS,
  REDUX_SET_STORES_FILTER_CONTENT,
  REDUX_SET_COUNTRIES_FILTER_CONTENT,
  REDUX_SET_RELEASES_FILTER_CONTENT,
  REDUX_SET_TRACKS_FILTER_CONTENT,
  REDUX_SET_ARTISTS_FILTER_CONTENT,
} from "../actions/earningsActions";

var initialState = {
  storesFilterContent: [],
  countriesFilterContent: [],
  releasesFilterContent: [],
  tracksFilterContent: [],
  artistsFilterContent: [],
};

function earningsReducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_SET_CURRENT_FILTERS:
      return state;
    case REDUX_SET_STORES_FILTER_CONTENT:
      state.storesFilterContent = action.filterContent;
      return state;
    case REDUX_SET_COUNTRIES_FILTER_CONTENT:
      state.countriesFilterContent = action.filterContent;
      return state;
    case REDUX_SET_RELEASES_FILTER_CONTENT:
      state.releasesFilterContent = action.filterContent;
      return state;
    case REDUX_SET_TRACKS_FILTER_CONTENT:
      state.tracksFilterContent = action.filterContent;
      return state;
    case REDUX_SET_ARTISTS_FILTER_CONTENT:
      state.artistsFilterContent = action.filterContent;
      return state;
    default:
      return state;
  }
}

export default earningsReducer;
