import axios from "axios";
import { getJWT } from "../components/utils";

const axiosInstance = axios.create();

export const sendSMSOTP = (phone, countryCode, token) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/sendSMSOTP`,
    {
      phone,
      countryCode,
      token,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const verifyProfileMobilePhone = (otp, phone, countryCode) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/verifyPhonenumber`,
    {
      otp,
      phone,
      countryCode,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const setupSMSTwoFA = (phone, countryCode, token) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/2fa/sms/setup`,
    {
      phone,
      countryCode,
      token,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const verifySMSTwoFASetup = (otp) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/2fa/sms/verify`,
    {
      otp,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const changePhone = (otp, newPhone, countryCode) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/2fa/sms/changePhone`,
    {
      otp,
      newPhone,
      countryCode,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const disableSMSTwoFASetup = (password) => {
  return axiosInstance.post(
    `${global.config.API_URL}/api/user/2fa/sms/disable`,
    {
      password,
    },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const generate2FACode = async () => {
  let url = `${global.config.API_URL}/api/user/2fa/totp/generate`;

  return axiosInstance.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const setup2FACode = async (totp) => {
  let url = `${global.config.API_URL}/api/user/2fa/totp/setup`;

  return axiosInstance.post(
    url,
    { totp },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const get2FAState = async () => {
  let url = `${global.config.API_URL}/api/user/2fa/state`;

  return axiosInstance.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const disable2FAAuthenticator = async (password) => {
  let url = `${global.config.API_URL}/api/user/2fa/totp/disable`;

  return axiosInstance.put(
    url,
    { password },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const getBackupCodes = async () => {
  let url = `${global.config.API_URL}/api/user/2fa/backupCodes`;

  return axiosInstance.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const regenerateBackupCodes = async () => {
  let url = `${global.config.API_URL}/api/user/2fa/backupCodes/regenerate`;

  return axiosInstance.post(
    url,
    {},
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
};

export const deleteBackupCodes = async () => {
  let url = `${global.config.API_URL}/api/user/2fa/backupCodes`;

  return axiosInstance.delete(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};
