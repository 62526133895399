import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "reactstrap";
import Portal from "../Portal";

const MEDIUM_DELAY = 5000;

const Toast = ({
  open = false,
  onClose,
  toastType = "success",
  toastMessage,
  toastDelay = MEDIUM_DELAY,
}) => {
  return (
    <Portal selector="body">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={toastDelay}
        onClose={onClose}
        style={{ zIndex: 109999 }}
      >
        <Alert
          isOpen={open}
          color={toastType}
          className="fs-16 font-weight-normal font-family-Inter"
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default Toast;
