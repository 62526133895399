import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CalendarComponent from "../../components/form/CalendarComponent";
import InputComponent from "../../components/form/InputComponent";
import SelectComponent from "../../components/form/SelectComponent";
import calendar from "../../assets/icons/calendar.svg";
import { pushToEntityData } from "../../redux/actions/dashboardActions";
import ChartsAggregationMap from "./components/ChartsAggregationMap";
import ChartSummaryStats from "./components/ChartSummaryStats";
import EntityHistoricPerformance from "./components/EntityHistoricPerformance";
import "./index.scss";
import useLocalStorage from "../../hooks/useLocalStorage";
import moment from "moment";
import { useQuery } from "react-query";
import {
  getEntitySpotifyChartsAggByCountry,
  getEntitySpotifyChartsHistory,
  getEntitySpotifySummaryStats,
} from "../../services/charts/spotify";
import { isEqual } from "lodash";
const defaultChartName = { label: "Top 200", value: "regional" };
const defaultChartDate = moment().subtract(2, "days").format("YYYY-MM-DD");
const defaultCountry = { label: "Global", value: "global" };

const chartsList = {
  regional: { label: "Top 200", value: "regional" },
  viral: { label: "Viral 50", value: "viral" },
};

const getRelatedMainEntityDataFromStore = ({
  dashboardReducer: {
    mainEntity: { id, type, name, service_profiles = {} } = {},
  } = {},
}) => ({
  id,
  type,
  name,
  service_profiles,
});

const EntityCharts = () => {
  const reduxMainEntity = useSelector(
    getRelatedMainEntityDataFromStore,
    isEqual
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [openChartDatePicker, setOpenChartDatePicker] = useState(false);
  const [chartDate, setChartDate] = useLocalStorage(
    "chartDate",
    defaultChartDate
  );
  const [chartName, setChartName] = useLocalStorage(
    "chartName",
    defaultChartName
  );
  const [countryFilter, setCountryFilter] = useLocalStorage(
    "chartCountry",
    defaultCountry
  );
  const userSpotifyId =
    reduxMainEntity?.service_profiles?.selectedSpotifyID || reduxMainEntity?.id;
  const type = reduxMainEntity?.type;

  let commonQueryParams = {
    chart_name: chartName.value,
    chart_date: chartDate,
    spotifyId: userSpotifyId,
    type,
  };

  const queryConfig = {
    enabled: !!userSpotifyId && !!type,
    refetchOnWindowFocus: false,
    cacheTime: 3_600_000, //milliseconds
    refetchOnMount: false,
  };

  /**
   * Loads the airplay data of corresponding entity from the url
   */
  useEffect(() => {
    let id = new URLSearchParams(location.search).get("id");
    let name = new URLSearchParams(location.search).get("entityName");
    let type = new URLSearchParams(location.search).get("type");
    if (id && name && type) {
      dispatch(pushToEntityData({ id, name, type }));
    }
  }, [location]);

  // Queries
  const {
    isLoading: isSummaryStatsLoading,
    data: { data: summaryStats = {} } = {},
  } = useQuery(
    ["summaryStats", commonQueryParams],
    getEntitySpotifySummaryStats,
    queryConfig
  );

  const {
    isLoading: isAggByCountryDataLoading,
    data: { data: aggByCountry = [] } = {},
  } = useQuery(
    ["aggByCountry", commonQueryParams],
    getEntitySpotifyChartsAggByCountry,
    queryConfig
  );

  const {
    isLoading: isHistoricPerfDataLoading,
    data: { data: historicPerfData = [] } = {},
  } = useQuery(
    [
      "historicPerfData",
      {
        chart_country_code: countryFilter.value,
        ...commonQueryParams,
      },
    ],
    getEntitySpotifyChartsHistory,
    queryConfig
  );

  const datePickCallback = useCallback((date) => {
    setChartDate(date);
  }, []);

  const handleOpenCalendar = useCallback(() => {
    setOpenChartDatePicker((prevState) => !prevState);
  }, []);

  const handleFilterChange = useCallback(
    (value, dbField, commentField, databaseKey, type) => {
      if (type?.action !== "input-change") {
        setChartName(chartsList[value]);
      }
    },
    []
  );

  return (
    <section className="dashboard-entitycharts-container">
      {/* Filters */}
      <div className="w-100">
        <div className="row text-white mx-0">Filter by:</div>
        <div className="row">
          <div className="col col-lg-3 py-2">
            <div className="inputUpperComment iuc3 playtreksSelector">
              Chart
            </div>
            <SelectComponent
              value={chartName.label}
              valuePaddingTop={true}
              placeholderTop="50%"
              placeholderFont="Inter-Medium"
              placeholderColor="white"
              placeholderOpacity="1"
              options={Object.values(chartsList)}
              customNoOptionsMessage={<p classNam="ml-2">No options</p>}
              selectChangeCallback={handleFilterChange} // to be used for data transfer
              selectBlurCallback={() => {}} // leave empty
            />
          </div>
          <div className="col col-lg-3 py-2">
            <div className=" position-relative">
              <div
                className={"inputUpperComment iuc6 " + (chartDate || "d-none")}
              >
                Chart date
              </div>
              <InputComponent
                disabled={true}
                value={chartDate} // take from calendar, or pre-populate
                paddingTopOnInput={true}
                inputGroupClassName="nrInputGroup"
                inputClassName={
                  "nrInput nrInput-opacityFix " +
                  (chartDate || chartDate.length
                    ? "nrCalendarInputPopulated"
                    : "")
                }
                commentField=".iuc6"
                placeholder="Chart date"
                addonClickCallback={handleOpenCalendar}
                for="nrReleaseDate"
                id="chartDate"
                // calendar addon
                addon="right"
                calendarAddon={true}
                calImg={calendar}
                calImgClass="nrAddonRightIcon"
                calAlt="pick date"
                inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                databaseField="chartDate"
              />
              <CalendarComponent
                calendarState={openChartDatePicker}
                calendarClassName="nrReleaseDateCalendar"
                datePickCallback={datePickCallback}
                calendarId="chartDate"
                databaseField="chartDate"
                shouldCloseOnSelect={true}
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Summary stats */}
      <div>
        <ChartSummaryStats
          data={summaryStats}
          loading={isSummaryStatsLoading}
        />
      </div>

      {/* Agg by country */}
      <div className="dashboard-entitycharts">
        <div className="text-white">
          <b>Top countries by tracks count and streams</b>
        </div>
        <ChartsAggregationMap
          data={aggByCountry}
          loading={isAggByCountryDataLoading}
          setCountryFilter={setCountryFilter}
        />
      </div>

      {/* Historic performance */}
      <div>
        <EntityHistoricPerformance
          data={historicPerfData}
          loading={isHistoricPerfDataLoading}
          setCountryFilter={setCountryFilter}
          countryFilter={countryFilter}
        />
      </div>
    </section>
  );
};

export default EntityCharts;
