import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import { TableHeader, TableBody } from "../../../../components/Table";
import { setPath } from "../../../../components/utils";
import { artistsJoined } from "../../../../components/utilsJSX";
import TrendIconUp from "../../../../assets/trend-icon-up.svg";
import TrendIconDown from "../../../../assets/trend-icon-down.svg";
import TrendIconStale from "../../../../assets/trend-icon-stale.svg";
import TrendIconDot from "../../../../assets/trend-icon-dot.svg";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../index.scss";

export const Track = ({
  trackData: { thumbnail, track_name, track_id, artists, album_name, album_id },
}) => {
  return (
    <>
      <div className="playlistsContainer align-items-center" title="">
        <img width="50" height="50" className={"rounded"} src={thumbnail} />

        <div className="pl-2">
          {/* Track */}
          <div className="spotifycharts-title pb-1">
            Track:&nbsp;
            <span>
              <Link
                className="textLink"
                to={setPath(track_id, track_name, "track", "/artistDashboard")}
                title={track_name}
              >
                {track_name}
              </Link>
            </span>
          </div>

          {/* Artist */}
          <div className="spotifycharts-title pb-1">
            Artist:&nbsp;
            <span className="ArtistsLink__ArtistLink-gru0tc-0 iPgjWu">
              {artistsJoined(artists, "/artistDashboard")}
            </span>
          </div>

          {/* Album */}
          <div className="spotifycharts-title">
            Album:&nbsp;
            <span>
              <Link
                className="textLink"
                to={setPath(album_id, album_name, "album", "/artistDashboard")}
                title={track_name}
              >
                {album_name}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export const Label = ({ labelName: { label } }) => (
  <Link
    className="textLink"
    to={setPath(label, label, "label", "/artistDashboard")}
    title={label}
  >
    {label}
  </Link>
);

const formatNumber = new Intl.NumberFormat().format;
export const Trend = ({ diff }) => {
  if (diff > 0) {
    return (
      <div class="chart-table-trend__icon">
        <img src={TrendIconUp} />
        <span className="trend-upwards ml-1">+{formatNumber(diff)}</span>
      </div>
    );
  }
  if (diff < 0) {
    return (
      <div class="chart-table-trend__icon">
        <img src={TrendIconDown} />
        <span className="trend-downwards ml-1">{formatNumber(diff)}</span>
      </div>
    );
  }

  if (diff === 0) {
    return (
      <div class="chart-table-trend__icon">
        <img src={TrendIconStale} />
      </div>
    );
  }

  return (
    <div class="chart-table-trend__icon">
      <img src={TrendIconDot} />
    </div>
  );
};

const SpotifyChartTable = memo(({ loading = false, data = [], columns }) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const tableInstance = useTable({
    columns,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows: page,
  } = tableInstance;

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].boundingClientRect.top < 0) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    });
    observer.observe(document.querySelector("thead"));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div className="dashboard-airplay">
        <table {...getTableProps()} className="airplay-table">
          <TableHeader headerGroups={headerGroups} />
          <TableBody
            numberOfColumns={columns.length}
            loading={loading}
            page={page}
            prepareRow={prepareRow}
            getTableBodyProps={getTableBodyProps}
          />
        </table>
      </div>
      <div
        onClick={() => window.scrollTo(0, 0)}
        className={`d-inline-flex rounded-circle position-fixed justify-content-center 
        align-items-center scroll-top-btn ${showScrollToTop ? "show" : ""}`}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </div>
    </>
  );
});

export default SpotifyChartTable;
