export const REDUX_SET_CURRENT_FILTERS = "REDUX_SET_CURRENT_FILTERS";
export const REDUX_SET_STORES_FILTER_CONTENT =
  "REDUX_SET_STORES_FILTER_CONTENT";
export const REDUX_SET_COUNTRIES_FILTER_CONTENT =
  "REDUX_SET_COUNTRIES_FILTER_CONTENT";
export const REDUX_SET_RELEASES_FILTER_CONTENT =
  "REDUX_SET_RELEASES_FILTER_CONTENT";
export const REDUX_SET_TRACKS_FILTER_CONTENT =
  "REDUX_SET_TRACKS_FILTER_CONTENT";
export const REDUX_SET_ARTISTS_FILTER_CONTENT =
  "REDUX_SET_ARTISTS_FILTER_CONTENT";

export function reduxSetCurrentFilters(filters) {
  return {
    type: REDUX_SET_CURRENT_FILTERS,
    filters: filters,
  };
}

export function reduxSetStoresFilterContent(filterContent) {
  return {
    type: REDUX_SET_STORES_FILTER_CONTENT,
    filterContent: filterContent,
  };
}

export function reduxSetCountriesFilterContent(filterContent) {
  return {
    type: REDUX_SET_COUNTRIES_FILTER_CONTENT,
    filterContent: filterContent,
  };
}

export function reduxSetReleasesFilterContent(filterContent) {
  return {
    type: REDUX_SET_RELEASES_FILTER_CONTENT,
    filterContent: filterContent,
  };
}

export function reduxSetTracksFilterContent(filterContent) {
  return {
    type: REDUX_SET_TRACKS_FILTER_CONTENT,
    filterContent: filterContent,
  };
}

export function reduxSetArtistsFilterContent(filterContent) {
  return {
    type: REDUX_SET_ARTISTS_FILTER_CONTENT,
    filterContent: filterContent,
  };
}
