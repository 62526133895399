import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getColorScheme } from "../../../components/utils";
import Spinner from "../../../assets/spinner.svg";
import { useSelector } from "react-redux";

const SIZE = 25;

const AirplayAgregationChart = ({
  data: { topPlaces = [] },
  height = "450px",
  loading,
}) => {
  const reduxMainEntity = useSelector(
    (state) => state.dashboardReducer.mainEntity
  );
  let innerWidth = window.innerWidth;
  const options = {
    colors: getColorScheme(), // default color before colors get loaded and passed along with the data
    chart: {
      type: "column",
      backgroundColor: "transparent",
      polar: true,
      zoomType: "xy",
      panning: true,
      panKey: "shift",
      plotBorderWidth: 1,
      height: height,
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      left: innerWidth < 769 ? "5%" : undefined, // mobile
      width: innerWidth < 769 ? "95%" : undefined, // mobile
      categories: topPlaces
        .slice(0, SIZE)
        .map(({ id: { country, title } }) => `${title}(${country})`),
      labels: {
        format: "{value:%b %e}",
        // step: 2,
        style: {
          color: "#FFFFFF",
        },
      },
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "",
      },
    },
    legend: {
      itemStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
      itemHoverStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "13px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: true,
      xDateFormat: "%A, %b %e",
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0,
        shadow: false,
      },
    },
    series: [
      {
        name: "Streams",
        data: topPlaces.slice(0, SIZE).map((obj) => obj.count),
      },
    ],
  };
  return (
    <div className="position-relative">
      {loading && (
        <div
          className="position-absolute top-40 left-50"
          style={{ height: height, zIndex: 2 }}
        >
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      )}
      {topPlaces.length === 0 && !loading ? (
        <div className="dashboard-playlists-top-empty">
          We haven't found any airplay stats for the selected{" "}
          {(reduxMainEntity?.type !== "user"
            ? reduxMainEntity?.type
            : "artist") + "."}
        </div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default AirplayAgregationChart;
