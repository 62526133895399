import React, { useState } from "react";

import SpotifyPagePicker from "../../../onboarding/components/SpotifyPagePicker";

import X from "../../../distribution/assets/X.svg";

import { setSpotifyArtist } from "../../../../routes/users";
import { getJWT } from "../../../utils";

const SPOTIFY = "spotify";

function AddSpotifyArtistModal({ closeModalCallback, className }) {
  const [artistId, setArtistId] = useState("");
  const [isError, setIsError] = useState(false);

  function isErrorCallback(v) {
    setIsError(v);
  }

  function artistIdCallback(artistId) {
    setArtistId(artistId);
  }

  function handleConnect() {
    setSpotifyArtist(artistId, getJWT())
      .then(() => closeModalCallback(true, SPOTIFY)) // true if refresh artist
      .catch((e) => setIsError(true));
  }

  return (
    <div
      className={
        "playtreksModal-overlay playtreksModal-overlay-insideDashboard playtreksModal-overlay-insideEditProfile " +
        className
      }
    >
      <div className="playtreksModal-modal playtreksModal-modal-insideDashboard playtreksModal-modal-insideEditProfile">
        <div className="playtreksModal-modal-header-editProfile">
          Pick your Spotify artist page
        </div>
        <img
          src={X}
          alt="close"
          className="playtreksModal-modal-close playtreksModal-modal-close-insideEditProfile"
          onClick={() => closeModalCallback(false, SPOTIFY)}
        />
        <SpotifyPagePicker
          artistIdCallback={artistIdCallback}
          isErrorCallback={isErrorCallback}
          style={{ marginTop: "27px" }}
        />
        {artistId ? (
          <div
            className="playtreksButton addSpotifyArtistButton"
            onClick={handleConnect}
          >
            Connect
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddSpotifyArtistModal;
