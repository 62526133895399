import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  getAllUsersWithdrawals,
  updateWithdrawalStatus,
} from "../../routes/admin";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CSVLink, CSVDownload } from "react-csv";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { GlobalFilter } from "../admin/ReleasesAdmin";
import TableView from "../../screens/Admin/Releases/components/TableView";
import Toast from "../Toast";
import "./admin.scss";
import Spinner from "../../assets/spinner.svg";
import SelectComponent from "../form/SelectComponent";
import { periods } from "./periods";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const INITIAL_PAGE_SIZE = 25;

const monthMap = {
  1: "Janurary",
  2: "Feburary",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const periodOptions = [
  { label: "All", value: null },
  ...periods.map((p) => ({
    label: ` ${monthMap[new Date(p.endDate.$date).getMonth() + 1]}, ${new Date(
      p.endDate.$date
    ).getFullYear()}`,
    value: p._id.$oid,
  })),
];

const PeriodFilterOptions = ({ setFilter, filters }) => {
  const selectedPeriodId = filters.find((f) => f.id === "period")?.value || "";
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"period"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Period</div>
      <SelectComponent
        value={periodOptions.find((o) => o.value === selectedPeriodId) || ""}
        extendOptionWidth
        id={"period"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(periodOptions)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") {
            setFilter("period", val);
          }
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

function TransactionsAdmin() {
  const queryClient = useQueryClient();
  const [showToast, setShowToast] = useState(initialToastState);

  const { data: usersWithdrawals, isLoading: areWithdrawalsLoading } = useQuery(
    "allWithdrawals",
    getAllUsersWithdrawals
  );

  const withdrawals = useMemo(
    () => usersWithdrawals?.data?.data ?? [],
    [usersWithdrawals]
  );

  const totalSuccessRequests = useMemo(
    () =>
      withdrawals.reduce((acc, curr) => {
        if (curr.status === "success") {
          return acc + 1;
        }
        return acc;
      }, 0),
    [withdrawals]
  );

  const totalPendingRequests = useMemo(
    () =>
      withdrawals.reduce((acc, curr) => {
        if (curr.status === "pending") {
          return acc + 1;
        }
        return acc;
      }, 0),
    [withdrawals]
  );

  const totalSuccessAmount = useMemo(
    () =>
      withdrawals.reduce((acc, curr) => {
        if (curr.status === "success") {
          return acc + curr.amount;
        }
        return acc;
      }, 0),
    [withdrawals]
  );

  const totalPendingAmount = useMemo(
    () =>
      withdrawals.reduce((acc, curr) => {
        if (curr.status === "pending") {
          return acc + curr.amount;
        }
        return acc;
      }, 0),
    [withdrawals]
  );

  const paymentStatus = useMutation(updateWithdrawalStatus);

  const headers = [
    { label: "Date", key: "createdAt" },
    { label: "PT User ID", key: "metadata.sender.id" },
    { label: "PT User email", key: "metadata.sender.email" },
    { label: "Description", key: "description" },
    { label: "Recipient host", key: "metadata.recipient.host" },
    { label: "Recipient email", key: "metadata.recipient.email" },
    { label: "Amount", key: "amount" },
    { label: "Status", key: "status" },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdAt",
        Cell: ({ value }) => value?.split("T")[0],
      },

      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ value }) => {
          return <div className="font-weight-bolder">€ {value}</div>;
        },
      },

      {
        Header: "User PT Email",
        accessor: "metadata.sender.email",
      },

      {
        Header: "Recipient email",
        accessor: "metadata.recipient.email",
      },

      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "User PT ID",
        accessor: "metadata.sender.id",
      },
      {
        Header: "Recipient host",
        accessor: "metadata.recipient.host",
        disableSortBy: true,
      },
      {
        Header: "Payment Status",
        accessor: "status",

        Cell: ({ row, value }) => {
          const [switchValue, setSwitchValue] = useState(
            value === "success" ? true : false
          );

          const handlePaymentStatusChange = (transactionId, status) => {
            const payload = {
              transactionId,
              status,
            };

            if (
              (status === "success" &&
                window.confirm(
                  "Are you sure you want to mark this payment as success?"
                )) ||
              (status === "pending" &&
                window.confirm(
                  "Are you sure you want to mark this payment as pending?"
                ))
            ) {
              paymentStatus.mutate(payload, {
                onSuccess: () => {
                  queryClient.invalidateQueries("allWithdrawals");
                  setShowToast({
                    open: true,
                    message: "Payment status updated successfully",
                    type: "success",
                  });
                },
                onError: (error) => {
                  setShowToast({
                    open: true,
                    message: error.response.data.message
                      ? error.response.data.message
                      : "An unexpected error occured. Please try again.",
                    type: "danger",
                  });
                  setSwitchValue((prevSwitchValue) => !prevSwitchValue);
                },
              });
            } else {
              setSwitchValue((prevSwitchValue) => !prevSwitchValue);
            }
          };

          return (
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={switchValue}
                    onChange={() => {
                      const newStatus = !switchValue ? "success" : "pending";
                      setSwitchValue(!switchValue);
                      handlePaymentStatusChange(row.original._id, newStatus);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className="font-weight-bold fs-14">
                {switchValue ? "Success" : "Pending"}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Period",
        id: "period",
        accessor: "metadata.period._id",
      },
    ],
    [withdrawals]
  );

  const tableInstance = useTable(
    {
      columns,
      data: withdrawals,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["metadata.sender.id", "metadata.period._id"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const paymentFilterOptions = {
    "": { label: "All", value: "" },
    pending: {
      label: "Pending",
      value: "pending",
    },
    success: {
      label: "Success",
      value: "success",
    },
  };
  const PaymentStatusFilter = ({
    setFilter,
    filters,
    options = paymentFilterOptions,
  }) => {
    const initialPaymentStatusFilter = filters.find(
      (filter) => filter.id === "status"
    );

    const [paymentStatusFilter, setPaymentStatusFilter] = useState(
      initialPaymentStatusFilter?.value ?? ""
    );
    return (
      <div
        className={
          "nrAlbumTitleInputWrapper col-12 col-md-4 col-lg-3 py-2 py-md-0 text-white"
        }
        id={"status"}
      >
        <div className="inputUpperComment iuc3 playtreksSelector">
          Payment Status
        </div>
        <SelectComponent
          value={options[paymentStatusFilter].label}
          extendOptionWidth
          id={"status"}
          valuePaddingTop={true}
          placeholderTop="50%"
          placeholderFont="Inter-Medium"
          placeholderColor="white"
          placeholderOpacity="1"
          options={Object.values(options)}
          customNoOptionsMessage={<p classNam="ml-2">No options</p>}
          selectChangeCallback={(
            val,
            databaseField,
            commentField,
            databaseKey,
            type
          ) => {
            if (type.action !== "input-change") {
              setPaymentStatusFilter(val);
              setFilter("status", val);
            }
          }}
          // to be used for data transfer
          selectBlurCallback={() => {}} // leave empty
        />
      </div>
    );
  };

  return (
    <div div className="my-money-section pt-5 mt-5 mx-2">
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />

      <div className="userWithdrawalHeader mb-1">
        All users withdrawals (requests)
      </div>
      <div style={{ width: "100%", textAlign: "center", color: "white" }}>
        <small>
          Please use the "Description" field as description of PayPal transfer,
          and recipient email as the PayPal email for the transfer. Checkbox
          "fulfilled" is for reference only.
        </small>
        <br />
      </div>
      <br />
      <div class="row justify-content-end mb-3">
        <div class="pr-5">
          <CSVLink
            download={`PayoutRequests.${Date.now()}.csv`}
            className="playtreksButton w-100 h-75 btn btn-secondary"
            headers={headers}
            data={preGlobalFilteredRows.map((r) => r.original)}
          >
            Export CSV
          </CSVLink>
        </div>
      </div>

      <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
        {areWithdrawalsLoading ? (
          <div className="dashboard-overview-streaming-stats-spinner-wrapper">
            <img
              src={Spinner}
              alt="loading"
              className="dashboard-overview-streaming-stats-spinner"
            />
          </div>
        ) : (
          <div className="row mx-0 px-0 mt-4">
            <div className="row mx-0  col-sm-12 col-md-6 px-0">
              <div className="earnings p-3 debit w-100">
                <div className="font-weight-bold earnings-amount fs-14  text-white">
                  Total pending <span>({totalPendingRequests} requests) </span>
                  <div className="font-weight-bold earnings-amount fs-14 mt-2 text-primary">
                    € {totalPendingAmount.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0  col-sm-12 col-md-6 px-0 px-md-3">
              <div className="earnings p-3 credit w-100">
                <div className="font-weight-bold earnings-amount fs-14 text-white">
                  Total paid <span>({totalSuccessRequests} requests)</span>
                  <div className="font-weight-bold earnings-amount fs-14 mt-2 text-primary">
                    € {totalSuccessAmount.toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-2 mt-3">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <PaymentStatusFilter setFilter={setFilter} filters={filters} />
                <PeriodFilterOptions setFilter={setFilter} filters={filters} />
              </div>
            </div>
          </div>
        </div>

        <TableView
          tableInstance={tableInstance}
          loading={areWithdrawalsLoading}
        />
      </main>
    </div>
  );
}

export default TransactionsAdmin;
