import * as Yup from "yup";

export const ExpenseValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contract: Yup.object()
    .shape({
      label: Yup.string().required("Contract is required"),
      value: Yup.string().required("Contract is required"),
    })
    .required("Contract is required"),
  expenseDate: Yup.date()
    .typeError("Invalid date")
    .required("Expense Date is required"),
  expenseSplits: Yup.array()
    .of(
      Yup.object().shape({
        rightholderName: Yup.string().required("Required!"),
        paidAmount: Yup.number()
          .typeError("Invalid amount")
          .required("Required!")
          .min(0, "Amount cannot be negative"),
        expenseShare: Yup.number()
          .typeError("Invalid Percentage")
          .required("Required!")
          .min(0, "Percentage cannot be negative")
          .max(100, "Percentage cannot be greater than 100"),
        recoupableAmount: Yup.number().typeError("Invalid amount"),
      })
    )
    .required("Cannot be empty"),

  totalExpenseSplitPercentage: Yup.mixed().test(
    "totalExpenseSplitPercentage",
    "Check the total Percentage",
    function (value) {
      const { path, createError } = this;
      if (value !== 100) {
        return createError({
          path,
          message: "Total percentage should be equal to 100%",
        });
      }
      return true;
    }
  ),
});
