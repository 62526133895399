import React, { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { getAllUserReleases } from "../../routes/distribution";
import ReleaseCard from "./components/ReleaseCard";
import RoyaltiesSplitForm from "./components/RoyaltiesSplitForm";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";
import { getJWT } from "../../components/utils";
import { useQuery } from "react-query";
import { isEmpty } from "lodash";
import TrackInfo from "./components/TrackInfo";
import PlatformsConfig from "./PlatformsConfig";
import { deleteUserRelease } from "../../services/distribution";
import Toast from "../../components/Toast";
import { Spinner } from "../Airplay/Airplay";

const MoreInfo = ({ release = {}, platforms }) => {
  return (
    <div className="prTracksCard pb-0">
      <p className="prtcTitle position-relative">Info</p>

      <div className="p-3" style={{ overflowWrap: "anywhere" }}>
        {(release?.socialMediaLink1 ||
          release?.socialMediaLink2 ||
          release?.socialMediaLink3) && (
          <>
            <div>Social platform links:</div>
            {release?.socialMediaLink1 && (
              <span className="mb-2">
                <a href={release?.socialMediaLink1} target="_blank">
                  {release?.socialMediaLink1 || ""}
                </a>
                <br />
              </span>
            )}
            {release?.socialMediaLink2 && (
              <span className="mb-2">
                <a href={release?.socialMediaLink2} target="_blank">
                  {release?.socialMediaLink2 || ""}
                </a>
                <br />
              </span>
            )}
            {release?.socialMediaLink3 && (
              <a href={release?.socialMediaLink3} target="_blank">
                {release?.socialMediaLink3}
              </a>
            )}
            <br />
          </>
        )}

        <div className="pb-3">
          Platforms (
          {/* {release?.platforms?.length === platforms?.length
            ? "all "
            : release?.platforms?.length > platforms?.length
            ? "all+ "
            : ""} */}
          {release?.platforms?.length}
          ):
        </div>

        <div className=" row platforms-container w-100 ">
          {release?.platforms?.map((platform) => (
            <div
              className="mb-3 col-lg-3 col-md-4 text-truncate"
              title={platform}
            >
              <img
                width="45"
                height="45"
                className="rounded"
                loading="lazy"
                src={PlatformsConfig[platform]?.src}
                alt={PlatformsConfig[platform]?.alt}
              />{" "}
              <span className="ml-2">{platform}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const ReleaseDetails = memo(({ location: { release }, platforms = [] }) => {
  const history = useHistory();
  const { releaseId } = useParams();
  const user = useSelector(
    ({ dashboardReducer }) => dashboardReducer?.userData,
    isEqual
  );

  const [toast, setToast] = useState(initialToastState);
  const [isDeleting, setIsDeleting] = useState(false);

  const queryConfig = {
    refetchOnWindowFocus: false,
    cacheTime: 3_600_000, //milliseconds
    enabled: !release && !isEmpty(user),
  };

  const addUniqueIdToShareHolders = (track) => ({
    ...track,
    shareholders:
      track?.shareholders?.map((shareholder) => ({
        ...shareholder,
        id: shareholder.id || uuidv4(),
      })) || [],
  });

  const {
    isLoading: areReleasesLoading,
    data: releasesResponse,
    refetch: refetchReleases,
  } = useQuery(["userAllReleases", getJWT()], getAllUserReleases, queryConfig);

  let userRelease =
    release ||
    (releasesResponse?.data?.find((r) => r.releaseId === releaseId) ?? {});

  userRelease.tracks =
    userRelease?.tracks?.map(addUniqueIdToShareHolders) ?? [];

  const isOwner = user.email === userRelease.owner?.email;

  const handleDelete = async (releaseId) => {
    let answer = window.confirm("Are you sure you want to delete the release?");
    if (answer) {
      setIsDeleting(true);
      try {
        await deleteUserRelease(releaseId);

        setToast({
          type: "success",
          open: true,
          message: "Deleted successfully",
        });

        setTimeout(() => {
          history.replace("/artistDashboard/myReleases");
        }, 1500);
      } catch {
        setIsDeleting(false);
        setToast({
          type: "danger",
          open: true,
          message: "Something went wrong",
        });
      }
    }
  };

  return (
    <section className="dashboard-airplay-container text-white">
      <ReleaseCard
        data={userRelease}
        direction="horizontal"
        isOwner={isOwner}
      />

      {/* <RoyaltiesSplitForm
        loading={areReleasesLoading}
        tracks={userRelease.tracks}
        ownerEmail={userRelease.owner?.email}
        ownerName={userRelease.owner?.name}
        releaseState={userRelease.state}
      /> */}

      <TrackInfo
        tracks={userRelease.tracks}
        refetch={refetchReleases}
        isOwner={isOwner}
      />

      <MoreInfo release={userRelease} platforms={platforms} />
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />
      {userRelease.state === "draft" && isOwner ? (
        <div className="row justify-content-center mt-4">
          <button
            className="delete-release d-flex xyFlexCenter w-25 py-2"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleDelete(userRelease.releaseId);
            }}
          >
            {isDeleting ? (
              <>
                Deleting...
                <span className="ml-2">
                  <Spinner />
                </span>
              </>
            ) : (
              "Delete release"
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </section>
  );
});

export default ReleaseDetails;
