import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// REDUX
import { useSelector, useDispatch } from "react-redux";

import moment from "moment";
import { isEqual, findIndex, cloneDeep } from "lodash";

import Header from "./components/RuslanHeader";

// import InputComponent from "../form/InputComponent";
import ReleaseProgressCircles from "./components/ReleaseProgressCircles";
import Checkbox from "../form/RuslanCheckbox";

// import SearchIcon from "../../assets/icons/search.svg";
import CheckCircle from "../../assets/icons/check-circle.svg";
import MoreHorizontal from "../../assets/icons/more-horizontal.svg";
import spinner from "../../assets/spinner.svg";

import * as platformImg from "./data/distrPlatformsData";

import {
  finishRelease,
  getReleaseData,
  getDspList,
} from "../../routes/distribution";

import {
  checkIfUserCompletedPage2,
  convertBackToFrontEnd,
} from "./helpers/distrHelpers";

import PaymentModal from "../welcomepage/components/PaymentModal";

import { getSubscriptionStatuses } from "../../routes/payments";
import ReactTooltip from "react-tooltip";

import {
  getConnectedAccounts,
  getEmailVerificationStatus,
  getFbProfiles,
  resendValidationEmail,
} from "../../routes/users";

import { useLocation } from "react-router-dom";
import EmailVerificationModal from "./components/EmailVerificationModal";

import CustomAlert from "../customAlert/index";
import ErrorPopup from "../error/ErrorPopup";

import {
  checkPlatformConstraints,
  getJWT,
  isUserProfileIncomplete,
  SOCIAL_PLATFORM,
} from "../utils";
import LoadingPage from "../LoadingPage";
import {
  populateReleaseForm,
  setUsedArtistFields,
} from "../../redux/actions/distributionActions";
import { addAlert } from "../../redux/actions/appActions";

import Switch from "../form/SwitchComponent";

import fb from "../onboarding/assets/connect/fb.svg";
import ig from "../onboarding/assets/connect/ig.svg";
import tw from "../onboarding/assets/connect/tw.svg";
import ConnectBox from "../onboarding/components/ObConnectBox";
import SocialShareComponent from "./components/SocialShareComponent";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import ProfileCompletenessRequiredModal from "./components/ProfileCompletenessRequiredModal";
import Portal from "../../components/Portal";
import { deletePlatforms } from "../../screens/Releases/PlatformsConfig";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DISTRIBUTION = 2;

const INITIAL = "initial";
const SUBSCRIBED = "subscribed";

// COMPONENT

function DistributionReleaseFinish() {
  const jwtToken = getJWT();
  const currentReleaseId = window.localStorage.getItem("currentReleaseId");

  let query = useQuery();

  const [checked, setChecked] = useState({
    releaseOnAllPlatforms: false,
  });
  // These keys end up in the backend and the db
  const [checkedSocialMedia, setCheckedSocialMedia] = useState({
    facebook: {},
    instagram: {},
    twitter: false,
    userFacebook: false,
  });
  const [expanded, setExpanded] = useState(0);
  const [platforms, setPlatforms] = useState([]);
  const [pickedPlatformsAmount, setPickedPlatformsAmount] = useState();
  const [releaseTitle, setReleaseTitle] = useState("your release");
  const [releaseVersion, setReleaseVersion] = useState(""); // this defaults to "your release", as a fallback in case the name does not come in time, so that the success modal upon redirect does not look incomplete
  // payment status
  const [paymentModalState, setPaymentModalState] = useState({
    open: false,
    product: DISTRIBUTION,
  });

  // state persist through query for the case if user pays before finishing
  const [pickedPlatformsQuery, setPickedPlatformsQuery] = useState();

  // email verification
  const [emailVerificationNeeded, setEmailVerificationNeeded] = useState(false);
  const [verifyAccountModal, setVerifyAccountModal] = useState(false);
  const [verifyAccountAlert, setVerifyAccountAlert] = useState(false);
  const [verificationResent, setVerificationResent] = useState(false);
  const reCaptchaRef = useRef();
  const [error, setError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [customTextError, setCustomTextError] = useState("");
  const [showProfileCompleteReqModal, setShowProfileCompleteReqModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // // TODO: bring back the full popup content [clickup task - #gv6yce]
  // // verify account msg
  // let verifyMsgContent =
  //   "hey! in order to make a release, we need you to verify your account.";
  // //   <div style={{ textAlign: "left" }}>
  // //     <span></span>
  // //     <div className="customAlert-resend" onClick={handleResendVerification}>
  // //       {verificationResent ? "sent! ✓" : "resend activation link ↺"}
  // //     </div>
  // //   </div>
  // // );
  // useEffect(() => dispatch(addAlert(verifyMsgContent)), []);

  // user msgs
  const reduxUserMessages = useSelector(
    (state) => state.appReducer.customAlerts
  );

  function addUserMessage(msg) {
    const preventDuplicateMessages = true;
    dispatch(addAlert(msg, null, null, preventDuplicateMessages));
  }
  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );

  const reduxArtistSlots = useSelector(
    (state) => state?.distributionReducer?.usedArtistSlots
  );

  const reduxReleaseData = useSelector(
    (state) => state?.distributionReducer?.releaseForm
  );

  const reduxAllowedArtistSlots = useSelector(
    (state) =>
      state?.subscriptionReducer?.subscription?.rules
        ?.distributionPrimaryArtistSlots?.amount
  );

  const reduxSubscriptionDisabledPlatforms = useSelector(
    (state) =>
      state?.subscriptionReducer?.subscription?.rules
        ?.distributionPlatformRestrictions?.disabledPlatforms
  );

  const reduxParticipants = useSelector(
    (state) => state?.distributionReducer?.releaseForm?.["#participants"]
  );

  const [userSlotsRemaining, setUserSlotsRemaining] = useState(); // contains the number of available slots, undefined if not matching
  const [userSlotsRemainingValidation, setUserSlotsRemainingValidation] =
    useState(); // true if we need to block, false if ok

  const [userSocialProfiles, setUserSocialProfiles] = useState({
    facebook: [],
    instagram: [],
    twitter: [],
  });

  const performCaptchaMessage = "Please confirm that you are not a robot.";

  const newFilteredPlatforms = platforms.filter(
    (platform) => !deletePlatforms.includes(platform.img)
  );

  useEffect(async () => {
    await getReleaseData(currentReleaseId, jwtToken).then((release) => {
      if (!checkIfUserCompletedPage2(release.data[0]))
        window.location.assign("/distribution/newRelease/1");
      else {
        setReleaseTitle(release.data[0]["#title"]);
        setReleaseVersion(
          release.data[0]["#remix_or_version"]
            ? ` (${release.data[0]["#remix_or_version"]})`
            : ""
        );

        dispatch(
          populateReleaseForm(convertBackToFrontEnd(release.data[0], "release"))
        );

        // getting the custom DSP requirements to update the platforms list (genre incompatibilities etc.)
        getDspList(jwtToken).then((dspList) => {
          setPlatforms(dspList.data);

          // displaying the incompatibilities that regard the current release
          const releaseData = release.data[0];

          // checking incompatible and required fields
          ["incompatible", "required"].forEach((type) =>
            checkPlatformConstraints(
              type,
              dspList.data,
              releaseData,
              addUserMessage,
              reduxUserMessages
            )
          );
        });
      }
    });
    // auth & payments

    getEmailVerificationStatus(jwtToken).then((r) => {
      setEmailVerificationNeeded(!r.data);
      setVerifyAccountAlert(!r.data);
    });
    if (query.get("pay")) {
      setPaymentModalState({ ...paymentModalState, open: true });
    }
    // populate redux used artists
    dispatch(setUsedArtistFields());

    let newUserSocialProfiles = {};

    // populate users social profiles data
    newUserSocialProfiles.userFacebook = await getConnectedAccounts(
      "facebook",
      jwtToken
    );

    if (newUserSocialProfiles.userFacebook.data.facebook.connected)
      newUserSocialProfiles.userFacebook =
        newUserSocialProfiles.userFacebook.data.facebook;
    else newUserSocialProfiles.userFacebook = { connected: false };

    newUserSocialProfiles.facebook = await getFbProfiles(jwtToken, null, {
      isInstagram: false,
    });

    if (!newUserSocialProfiles.facebook.data.serviceNotConnected)
      newUserSocialProfiles.facebook =
        newUserSocialProfiles.facebook.data.fbProfiles;
    else newUserSocialProfiles.facebook = [];

    newUserSocialProfiles.instagram = await getFbProfiles(jwtToken, null, {
      isInstagram: true,
    });

    if (!newUserSocialProfiles.instagram.data.serviceNotConnected)
      newUserSocialProfiles.instagram =
        newUserSocialProfiles.instagram.data.fbProfiles;
    else newUserSocialProfiles.instagram = [];

    newUserSocialProfiles.twitter = await getConnectedAccounts(
      "twitter",
      jwtToken
    );

    if (newUserSocialProfiles.twitter.data.twitter.connected) {
      newUserSocialProfiles.twitter =
        newUserSocialProfiles.twitter.data.twitter;
      newUserSocialProfiles.twitterConnected = true;
    } else {
      newUserSocialProfiles.twitter = [];
      newUserSocialProfiles.twitterConnected = false;
    }

    setUserSocialProfiles(newUserSocialProfiles);
  }, []); // on page load

  // check user subscription specific platform constraint
  useEffect(() => {
    if (reduxSubscriptionDisabledPlatforms && platforms)
      reduxSubscriptionDisabledPlatforms.forEach((disabledPlatform) => {
        const indexToDisable = findIndex(platforms, {
          value: disabledPlatform,
        });
        if (indexToDisable > -1) {
          platforms[indexToDisable].disabled = true;
          platforms[indexToDisable].msg = "Please upgrade";
        }
      });
  }, [platforms, reduxSubscriptionDisabledPlatforms]);

  // take platforms from query on subscribe flow
  useEffect(() => {
    let platformsFromQuery = query.get("platforms"); // prepopulated before paying
    if (
      platformsFromQuery !== "undefined" &&
      platformsFromQuery?.length > 0 &&
      platformsFromQuery !== "all"
    ) {
      let queryPlatformsArray = platformsFromQuery.split(",");
      queryPlatformsArray.map((queryPlatform) => {
        platforms.map((platform) => {
          if (platform.value === queryPlatform) {
            platform.picked = true;
          }
        });
      });
      setPlatforms(platforms);
    } else if (platformsFromQuery === "all") {
      setChecked({ releaseOnAllPlatforms: true });
    }
  }, [query]);

  useEffect(() => {
    setPickedPlatformsAmount(platforms.filter((v) => v.picked === true).length);
    var currentlyPicked = platforms.filter((platform) => platform.picked);
    if (currentlyPicked.length) {
      setPickedPlatformsQuery(currentlyPicked.map((p) => p.value).join(","));
    }
  }, [platforms]);

  useEffect(() => {
    if (checked.releaseOnAllPlatforms) {
      // TODO: BRING THIS BACK
      // setPlatforms(platformsList); // reset the picked values on "release all" picked
      setPickedPlatformsQuery("all"); // pass to query in case of subscription payment flow
    }
  }, [checked]);

  function handleCheckbox(e) {
    setChecked({ releaseOnAllPlatforms: !checked["releaseOnAllPlatforms"] });
    setExpanded(0);
  }

  function handleSocialMediaCheckbox(service, profileID) {
    // Toggle if exists, otherwise set to true

    let newCheckedSocialMedia = cloneDeep(checkedSocialMedia);
    if (["twitter", "userFacebook"].includes(service)) {
      // Here we only store a boolean
      newCheckedSocialMedia[service] = !checkedSocialMedia[service];
    } else {
      // Here we store a boolean per profileID
      const newValue =
        profileID in checkedSocialMedia[service]
          ? !checkedSocialMedia[service][profileID]
          : true;

      newCheckedSocialMedia[service][profileID] = newValue;
    }
    setCheckedSocialMedia(newCheckedSocialMedia);
  }

  function handlePickPlatform(e) {
    let platformsNew = newFilteredPlatforms.map((platform, index) => {
      if (Number(e.target.id) === index) {
        return {
          value: platform?.value,
          picked: !platform?.picked,
          img: platform?.img,
          disabled: platform?.disabled,
        };
      } else {
        return {
          value: platform?.value,
          picked: platform?.picked,
          img: platform?.img,
          disabled: platform?.disabled,
        };
      }
    });
    setPlatforms(platformsNew);
  }

  // check artist slots
  useEffect(() => {
    var reduxPrimaryArtists;
    if (reduxArtistSlots && reduxAllowedArtistSlots !== undefined) {
      reduxPrimaryArtists = reduxParticipants?.primary;
      checkArtistSlots(reduxPrimaryArtists);
    }
  }, [reduxArtistSlots, reduxAllowedArtistSlots]);

  // check artist slots on primary artist field blur
  function checkArtistSlots(reduxPrimaryArtists) {
    if (reduxUserData?.email === "statements@frcstrcrds.com") {
      return setUserSlotsRemainingValidation(false);
    }
    const primaryArtistsArray = reduxPrimaryArtists?.map(
      (artist) => artist.primary
    );
    let isMatching = reduxArtistSlots?.some((slot) =>
      isEqual(primaryArtistsArray, slot.artist_slot)
    );
    if (!isMatching) {
      // means user is creating a new artist slot
      setUserSlotsRemaining(reduxAllowedArtistSlots - reduxArtistSlots.length);

      // block if requesting one slot AND there are no more slots available
      setUserSlotsRemainingValidation(
        reduxAllowedArtistSlots - reduxArtistSlots.length <= 0
      );
    } else {
      // if using existing slot, all good
      setUserSlotsRemainingValidation(false);
    }
  }

  function verifyCaptchaCallback(response) {
    if (response) {
      setCaptchaToken(response);
      setIsCaptchaVerified(true);
    } else {
      setCaptchaToken("");
      setIsCaptchaVerified(false);
    }
  }

  function handleOnCaptchaError() {
    setCaptchaToken("");
    setIsCaptchaVerified(false);
  }

  function handleOnCaptchaExpiry() {
    setCaptchaToken("");
    setIsCaptchaVerified(false);
  }

  function handleSubmit() {
    if (isLoading) return;

    setError(false);

    if (isUserProfileIncomplete(reduxUserData)) {
      setShowProfileCompleteReqModal(true);

      return;
    }

    if (!isCaptchaVerified || !captchaToken) {
      setCustomTextError(performCaptchaMessage);
      setError(true);
      return;
    }

    if (!checked.releaseOnAllPlatforms) {
      var pickedPlatforms = newFilteredPlatforms.filter(
        (platform) => platform.picked
      );
    } else {
      pickedPlatforms = newFilteredPlatforms.filter(
        (platform) => !platform?.disabled
      );
    }

    if (userSlotsRemainingValidation) {
      // setError("Please upgrade");
      return;
    }

    if (pickedPlatforms.length > 0) {
      // TODO: missing release creation execution after subscription started ??? ???

      setIsLoading(true);

      if (emailVerificationNeeded) {
        setVerifyAccountModal(true);
      } else {
        finishRelease(
          pickedPlatforms.map((platform) => platform.value),
          checkedSocialMedia,
          currentReleaseId,
          moment().format("YYYY-MM-DD"),
          captchaToken
        )
          .then((r) => {
            // if successful, clear local storage release id (so that user can start over with a new release)
            window.localStorage.removeItem("currentReleaseId");
            // and redirect to distribution start
            window.location.assign(
              "/artistDashboard/myReleases?name=" +
                releaseTitle +
                " " +
                releaseVersion
            );
          })
          .catch((err) => {
            if (reCaptchaRef?.current) {
              reCaptchaRef.current.reset();
            }

            if (err.response?.data?.msg) {
              setCustomTextError(err.response.data.msg);
              setError(true);
            } else if (err?.message?.validation === true) {
              setError(
                "Failed subscription check. Please try again or contact support"
              );
            } else {
              setError("there was an error, please try again");
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }

  function handleResendVerification() {
    setVerificationResent(true);
    resendValidationEmail(jwtToken);
    setTimeout(() => setVerificationResent(false), 5000);
  }

  if (platforms.length === 0) return <LoadingPage />;
  else
    return (
      <div
        className="distributionRoot"
        style={{
          backgroundColor: "#343a47",
        }}
      >
        <ErrorPopup
          error={error}
          customText={customTextError}
          style={{
            fontSize: "15px",
            // width: "auto",
            zIndex: "999",
            textAlign: "center",
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        />
        <Header
          quitCallback={() =>
            window.location.assign("/artistDashboard/myReleases")
          }
        />
        <div className="toastrStyleAlert-wrapper mt-5">
          {/* {verifyAccountAlert && (
            <section>
              <CustomAlert
                variant="warning toastrStyleAlert toastrStyleAlert-opacity"
                xButton={true}
                closeCallback={() => setVerifyAccountAlert(false)}
              >
                <div className="customAlert">
                  <span>
                    hey! in order to make a release, we need you to verify your
                    account. Please click on the link that we sent to your
                    email.
                  </span>
                  <div
                    className="customAlert-resend"
                    onClick={handleResendVerification}
                  >
                    {verificationResent
                      ? "sent! ✓"
                      : "resend activation link ↺"}
                  </div>
                </div>
              </CustomAlert>
            </section>
          )} */}
        </div>
        <span className={paymentModalState.open ? "" : "d-none"}>
          <PaymentModal
            paymentModalState={paymentModalState}
            setPaymentModalState={setPaymentModalState}
            redirectTo={"/distribution/newRelease/4"}
            additionalQuery={
              "&platforms=" + (pickedPlatformsQuery || query.get("platforms"))
            }
          />
        </span>
        <span className={verifyAccountModal ? "" : "d-none"}>
          <EmailVerificationModal
            closeModalCallback={() => window.location.reload()}
            handleResendVerification={handleResendVerification}
            verificationResent={verificationResent}
          />
        </span>
        <div className="newReleaseTitle">new release</div>
        <ReleaseProgressCircles activeCircle={"all"} />
        {/* content */}
        <div className="releaseFinishTextWrapper">
          <div className="releaseFinishTitle">
            Select the platform(s) for your release
          </div>
          <br />
          <div className="rfAllPlatformsText">
            <span onClick={handleCheckbox} style={{ cursor: "pointer" }}>
              Release on all platforms
            </span>
            <Checkbox
              className="rfCheckbox"
              checked={checked.releaseOnAllPlatforms}
              id="releaseOnAllPlatforms"
              onClick={handleCheckbox}
            />
          </div>
        </div>
        {!checked.releaseOnAllPlatforms ? (
          <div className="rfPlatformsWrapper">
            {newFilteredPlatforms
              .filter((item, index) => index < 10) // only show the first 10 results
              .map((item, index) => {
                return (
                  <PlatformBox
                    handlePickPlatform={handlePickPlatform}
                    index={index}
                    item={item}
                  />
                );
              })}
          </div>
        ) : (
          ""
        )}
        {!checked.releaseOnAllPlatforms ? (
          <MorePlatforms
            ref={reCaptchaRef}
            platforms={newFilteredPlatforms}
            handlePickPlatform={handlePickPlatform}
            count={expanded}
            expanded={expanded}
            setExpanded={setExpanded}
            pickedPlatformsAmount={pickedPlatformsAmount}
            handleSubmit={handleSubmit}
            error={error}
            isLoading={isLoading}
            userSlotsRemaining={userSlotsRemaining}
            userSlotsRemainingValidation={userSlotsRemainingValidation}
            handleSocialMediaCheckbox={handleSocialMediaCheckbox}
            checkedSocialMedia={checkedSocialMedia}
            userSocialProfiles={userSocialProfiles}
            releaseData={reduxReleaseData}
            verifyCaptchaCallback={verifyCaptchaCallback}
            handleOnCaptchaError={handleOnCaptchaError}
            handleOnCaptchaExpiry={handleOnCaptchaExpiry}
          />
        ) : (
          // <div>
          //   <ErrorText
          //     error={error}
          //     style={{ position: "relative", top: "530px" }}
          //   />
          //   <div
          //     className="applyForReleaseButton allPlatformsButton"
          //     onClick={handleSubmit}
          //   >
          //     <span>
          //       {INITIAL === INITIAL ? (
          //         "Subscribe and release"
          //       ) : isLoading ? (
          //         <img
          //           alt="loading"
          //           src={spinner}
          //           // className={trackUploadIndex < 1 ? "d-none" : ""}
          //           style={{ width: "30px", height: "30px" }}
          //         />
          //       ) : (
          //         "Apply to release on all major platforms"
          //       )}
          //     </span>
          //   </div>
          //   <div className="nrTermsAndConditionsText rfTerms rfTerms-all">
          //     By clicking you agree with{" "}
          //     <a href="/terms" target="_blank">
          //       <u>Terms & Conditions</u>
          //     </a>
          //   </div>
          // </div>
          <div>
            <SubmitButtonGroup
              ref={reCaptchaRef}
              error={error}
              userSlotsRemaining={userSlotsRemaining}
              pickedPlatformsAmount={pickedPlatformsAmount}
              userSlotsRemainingValidation={userSlotsRemainingValidation}
              handleSubmit={handleSubmit}
              isLoading={isLoading}
              allPlatforms
              className="rfAllPlatformsButtonGroup"
              handleSocialMediaCheckbox={handleSocialMediaCheckbox}
              checkedSocialMedia={checkedSocialMedia}
              userSocialProfiles={userSocialProfiles}
              releaseData={reduxReleaseData}
              verifyCaptchaCallback={verifyCaptchaCallback}
              handleOnCaptchaError={handleOnCaptchaError}
              handleOnCaptchaExpiry={handleOnCaptchaExpiry}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <Portal selector="body">
          {showProfileCompleteReqModal && (
            <ProfileCompletenessRequiredModal
              onCloseRequest={() => setShowProfileCompleteReqModal(false)}
            />
          )}
        </Portal>
      </div>
    );
}

const MorePlatforms = React.forwardRef(
  (
    {
      platforms,
      handlePickPlatform,
      count,
      expanded,
      setExpanded,
      pickedPlatformsAmount,
      handleSubmit,
      error,
      isLoading,
      userSlotsRemaining,
      userSlotsRemainingValidation,
      handleSocialMediaCheckbox,
      checkedSocialMedia,
      userSocialProfiles,
      releaseData,
      verifyCaptchaCallback,
      handleOnCaptchaError,
      handleOnCaptchaExpiry,
    },
    ref
  ) => {
    const [limit, setLimit] = useState();

    // will only expand two times -> change the value if more platforms become listed in the future
    const maximumExpandTimes = 2;

    useEffect(() => {
      if (count > 0 && count <= maximumExpandTimes) {
        setLimit(10 + count * 20);
      }
    }, [count]);

    return (
      <div key={count} className="rfLowerPlatformsOuterWrapper">
        {count > 0 ? <div className="rfHr" /> : ""}
        <div
          className={
            "rfPlatformsWrapper rfLowerPlatformsWrapper " +
            (expanded === 0 ? "d-none" : "")
          }
        >
          {platforms
            .filter((item, index) => index > 9 && index < limit) // skip the first 10 results
            .map((item, index) => {
              let properIndex = index + 10;
              return (
                <PlatformBox
                  handlePickPlatform={handlePickPlatform}
                  index={properIndex}
                  item={item}
                />
              );
            })}
        </div>
        {count < maximumExpandTimes ? ( // don't show the expand button if no more platforms to show
          <div
            className="rfMoreButton"
            onClick={() => setExpanded(expanded + 1)}
          >
            <img alt="more" src={MoreHorizontal} />
          </div>
        ) : (
          ""
        )}
        <SubmitButtonGroup
          ref={ref}
          error={error}
          userSlotsRemaining={userSlotsRemaining}
          pickedPlatformsAmount={pickedPlatformsAmount}
          userSlotsRemainingValidation={userSlotsRemainingValidation}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          handleSocialMediaCheckbox={handleSocialMediaCheckbox}
          checkedSocialMedia={checkedSocialMedia}
          userSocialProfiles={userSocialProfiles}
          releaseData={releaseData}
          verifyCaptchaCallback={verifyCaptchaCallback}
          handleOnCaptchaError={handleOnCaptchaError}
          handleOnCaptchaExpiry={handleOnCaptchaExpiry}
        />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
);

const SubmitButtonGroup = React.forwardRef(
  (
    {
      error,
      userSlotsRemaining,
      pickedPlatformsAmount,
      userSlotsRemainingValidation,
      handleSubmit,
      isLoading,
      allPlatforms = false,
      className,
      handleSocialMediaCheckbox,
      checkedSocialMedia,
      userSocialProfiles,
      releaseData,
      verifyCaptchaCallback,
      handleOnCaptchaError,
      handleOnCaptchaExpiry,
    },
    ref
  ) => {
    let instagramPagesConnected =
      userSocialProfiles?.userFacebook?.connected &&
      userSocialProfiles.instagram.length;

    function handleFBShareClick() {
      window.FB.ui(
        {
          display: "popup",
          method: "share",
          href: "https://developers.facebook.com/docs/",
        },
        function (response) {}
      );
    }

    // const socialShareMessage = releaseData;
    const primaryArtistsString = releaseData["#participants"].primary
      .filter((p) => p.primary !== undefined)
      .map((p) => p.primary);

    const socialShareMessage = `Just submitted for release ${primaryArtistsString} - ${releaseData["#title"]} through @playtreks!`;

    return (
      <div className={className}>
        <ErrorText error={error} />
        <div className="rfSocialsCheckboxes">
          <div className="rfSocialsCheckboxes-wrapper">
            <div className="rfSocialsCheckboxes-header">
              Would you like to share and inform the world about your new
              release? <br />
              <br />
              {/*Select your channels here, and we'll do the rest for you! */}
              <FacebookShareButton
                url="https://playtreks.com"
                quote={socialShareMessage}
                hashtag="PlayTreks"
              >
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton
                url="https://playtreks.com"
                title={socialShareMessage}
                // related="playtreks"
                hashtags={["distribution", "playtreks"]}
              >
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <PinterestShareButton
                url="https://playtreks.com"
                media={releaseData["#cover_url"]}
                description={socialShareMessage}
              >
                <PinterestIcon size={32} round={true} />
              </PinterestShareButton>
              <RedditShareButton
                url="https://playtreks.com"
                title={socialShareMessage}
              >
                <RedditIcon size={32} round={true} />
              </RedditShareButton>
              <TumblrShareButton
                url="https://playtreks.com"
                title={socialShareMessage}
                // caption={socialShareMessage}
              >
                <TumblrIcon size={32} round={true} />
              </TumblrShareButton>
            </div>
            <div className="rfSocialsCheckboxes-header">
              Also, would you like PlayTreks to inform the world on your behalf?{" "}
              <br />
              Select your channels here, and we'll do the rest for you!
            </div>
            <br />

            <SocialShareComponent
              active={userSocialProfiles?.userFacebook?.connected}
              service={SOCIAL_PLATFORM.FACEBOOK}
              serviceImg={fb}
              userPersonalService="userFacebook"
              userPersonalServiceImg={userSocialProfiles?.userFacebook?.image}
              handleSocialMediaCheckbox={handleSocialMediaCheckbox}
              userSocialProfiles={userSocialProfiles}
              checkedSocialMedia={checkedSocialMedia}
            />
            <br />
            <SocialShareComponent
              active={instagramPagesConnected}
              service={SOCIAL_PLATFORM.INSTAGRAM}
              serviceImg={ig}
              userPersonalService="userFacebook"
              userPersonalServiceImg={userSocialProfiles?.userFacebook?.image}
              handleSocialMediaCheckbox={handleSocialMediaCheckbox}
              userSocialProfiles={userSocialProfiles}
              checkedSocialMedia={checkedSocialMedia}
            />
            <br />
            <SocialShareComponent
              active={userSocialProfiles.twitterConnected}
              service={SOCIAL_PLATFORM.TWITTER}
              serviceImg={tw}
              userPersonalService="twitter"
              userPersonalServiceImg={userSocialProfiles?.twitter?.image}
              handleSocialMediaCheckbox={handleSocialMediaCheckbox}
              userSocialProfiles={userSocialProfiles}
              checkedSocialMedia={checkedSocialMedia}
            />
            <div
              className="rfSocialsCheckboxes-header"
              style={{ color: "grey" }}
            >
              Schedule posts & smart marketing cooming soon!
            </div>
          </div>
        </div>
        <br />
        {userSlotsRemaining <= 0 && (
          <div className="rfSlotsFeedback-warning">
            This release creates a new artist slot. Please{" "}
            <a href="/artistDashboard/subscriptions" target="_blank">
              <u>upgrade your plan</u>
            </a>{" "}
            in order to submit this release. <br /> Your release is saved as
            draft in "My Releases" section, and you can always come back to it
            later.
          </div>
        )}
        {userSlotsRemaining > 0 && (
          <div className="rfSlotsFeedback-warning">
            This release creates a new artist slot. You have{" "}
            {userSlotsRemaining} slots remaining.{" "}
            <a href="/artistDashboard/subscriptions" target="_blank">
              <u>Upgrade now</u>
            </a>{" "}
            for more slots.
          </div>
        )}
        <div className="releaseFinishCaptcha">
          <ReCAPTCHA
            ref={ref}
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
            render="explicit"
            onChange={verifyCaptchaCallback}
            onExpired={handleOnCaptchaExpiry}
            onErrored={handleOnCaptchaError}
            theme="dark"
          />
        </div>
        <div
          className={
            "applyForReleaseButton " +
            (allPlatforms ? "allPlatformsButton " : "") +
            ((pickedPlatformsAmount === 0 && !allPlatforms) ||
            userSlotsRemainingValidation
              ? "rfButtonInactive"
              : "")
          }
          onClick={handleSubmit}
        >
          {allPlatforms ? (
            "Release on all platforms"
          ) : pickedPlatformsAmount > 0 ? (
            <span>
              {isLoading ? (
                <img
                  alt="loading"
                  src={spinner}
                  // className={trackUploadIndex < 1 ? "d-none" : ""}
                  style={{ width: "30px", height: "30px" }}
                />
              ) : (
                <span>
                  Apply to release on {pickedPlatformsAmount} platform
                  {pickedPlatformsAmount > 1 ? "s" : ""}
                </span>
              )}
            </span>
          ) : (
            "No platforms selected"
          )}
        </div>
        <br />
        <div className="nrTermsAndConditionsText rfTerms">
          By clicking you agree with{" "}
          <a href="/terms" target="_blank">
            <u>Terms & Conditions</u>
          </a>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
);

function ErrorText({ error, style }) {
  return (
    <div className="mt-4 xyFlexCenter" style={{ ...style, color: "red" }}>
      {error}
    </div>
  );
}

const PlatformBox = ({ handlePickPlatform, index, item }) => {
  const reduxDataSubscription = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription
  );
  const isSoundExchangeChannel = item.value === "Soundexchange";
  const isShazamChannel = item.value === "Shazam";
  const isLabelPlan = reduxDataSubscription?.plan.includes("label");

  let msg = "";
  if (isSoundExchangeChannel && isLabelPlan) {
    msg =
      "If you are a member of SoundExchange and you want to use PlayTreks to register sound recordings and collect neighbouring rights through SoundExchange, please contact PlayTreks Support to setup and configure this channel.";
  } else if (isSoundExchangeChannel && !isLabelPlan) {
    msg =
      "SoundExchange distribution is available in label plans. Please upgrade to a label plan to make use of this feature.";
  }

  if (isShazamChannel) {
    msg =
      "Shazam distribution is now combined with Apple Music. Please select Apple Music if you want to distribute your music to Shazam.";
  }

  const toolTipProps =
    isSoundExchangeChannel || isShazamChannel
      ? {
          "data-tip": msg,
          "data-for": "distributionChannelTooltip",
        }
      : {};

  return (
    <>
      {isSoundExchangeChannel && (
        <ReactTooltip
          place="top"
          backgroundColor="#1d2025"
          className="ptTooltip"
          border
          borderColor="#0ef1db"
          effect="solid"
          id={"distributionChannelTooltip"}
        />
      )}
      <div
        className="rfPlatformBox ml-2"
        onClick={!item?.disabled ? handlePickPlatform : ""}
        id={index}
        {...toolTipProps}
      >
        {item.picked ? (
          <img
            alt="selected platform"
            src={CheckCircle}
            className="rfPlatformBoxPicked"
            id={index}
          />
        ) : (
          ""
        )}
        <div className="rfPlatformBoxCircle" id={index}>
          <img
            alt={item.value}
            src={platformImg[item.img]}
            style={{
              filter: item?.disabled && "grayscale(100%)brightness(60%)",
              width: "100%",
              height: "100%",
              borderRadius: "100px",
            }}
            id={index}
          />
        </div>
        <div
          className="rfPlatformBoxText"
          style={{ color: item?.disabled && "grey" }}
          id={index}
        >
          {item.value}
        </div>
        {item?.disabled && (
          <div className="rfPlatformBoxText-comment">{item.msg}</div>
        )}
      </div>
    </>
  );
};

export default DistributionReleaseFinish;
