import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import highchartsMap from "highcharts/modules/map";
import map from "@highcharts/map-collection/custom/world-palestine-highres.geo.json";
import proj4 from "proj4";
import Spinner from "../../../assets/spinner.svg";
import Countries from "../../../shared/countries.json";

// Required for to use maps with data points as lat/long
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

highchartsMap(Highcharts);

const AirplayAggregationMap = ({
  data: { topCountries = [], topPlaces = [] },
  loading,
  height = "450px",
}) => {
  const chartOptions = {
    title: {
      text: "",
    },
    plotOptions: {
      map: {
        borderWidth: "0.2",
        borderColor: "white",
        nullColor: "#1d2025",
      },
    },
    chart: {
      backgroundColor: "#1d2025",
      height: height,
    },
    credits: {
      enabled: false,
    },
    legend: {
      itemStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
      itemHoverStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "13px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: false,
    },
    subtitle: {
      floating: true,
      align: "right",
      y: 50,
      style: {
        fontSize: "16px",
      },
    },
    colorAxis: [
      {
        min: 0,
        minColor: "#383A3A",
        maxColor: "#3ec0b3",
        showInLegend: false,
      },
    ],
    series: [
      {
        // Use the gb-all map with no data as a basemap
        mapData: map,
        name: "Basemap",
        borderColor: "#A0A0A0",
        showInLegend: false,
      },
      {
        name: "Separators",
        type: "mapline",
        data: Highcharts.geojson(map, "mapline"),
        color: "#FFFFFF",
        showInLegend: false,
        enableMouseTracking: false,
      },
      {
        mapData: map,
        name: "Countries",
        color: "#066a60",
        colorAxis: 0,
        data: topCountries.map((c) => [Countries[c._id], c.count]),
        tooltip: {
          pointFormatter: function () {
            return `<b>Country: ${this?.name ?? ""}</b><br/>Streams: ${
              this?.value
            }`;
          },
        },
      },
      {
        // Specify points using lat/lon
        type: "mapbubble",
        name: "Cities",
        color: "#0ef1db",
        maxSize: "12%",
        colorAxis: false,
        data: topPlaces.map(({ count, id: { title, geo } }) => ({
          count,
          z: count,
          name: title,
          lat: geo[1],
          lon: geo[0],
        })),
        tooltip: {
          pointFormatter: function () {
            return `<b>City: ${this?.name ?? ""}</b><br/>Streams: ${
              this?.count
            }`;
          },
        },
      },
    ],
    mapNavigation: {
      enabled: true,
      enableMouseWheelZoom: false,
      buttonOptions: {
        verticalAlign: "bottom",
      },
    },
  };

  return (
    <div className="position-relative">
      {loading && (
        <div className="position-absolute top-40 left-50" style={{ zIndex: 1 }}>
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      )}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        constructorType={"mapChart"}
      />
    </div>
  );
};

export default AirplayAggregationMap;
