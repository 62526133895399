export const upperCards = [
  {
    name: "album length",
    objectPath: "duration",
    icon: "",
  },
  {
    name: "total tracks",
    objectPath: "total_tracks",
    icon: "",
  },
  {
    name: "avg track length",
    objectPath: "trackFeaturesAggregations.average.duration",
    icon: "",
  },
  {
    name: "average tempo",
    objectPath: "trackFeaturesAggregations.average.tempo",
    icon: "",
  },
  {
    name: "main key",
    subName: "key",
    objectPath: "trackFeaturesAggregations.average.key.main.value",
    icon: "",
    tooltip: true,
    customInfoPath: "trackFeaturesAggregations.average.key.main.percentage",
    countPath: "trackFeaturesAggregations.average.key.main.count",
  },
  {
    name: "main mode",
    subName: "mode",
    objectPath: "trackFeaturesAggregations.average.mode.main.value",
    icon: "",
    tooltip: true,
    customInfoPath: "trackFeaturesAggregations.average.mode.main.percentage",
    countPath: "trackFeaturesAggregations.average.mode.main.count",
  },
  {
    name: "average loudness",
    objectPath: "trackFeaturesAggregations.average.loudness",
    icon: "",
  },
  {
    name: "main time signature",
    subName: "time signature",
    objectPath: "trackFeaturesAggregations.average.time_signature.main.value",
    icon: "",
    tooltip: true,
    customInfoPath:
      "trackFeaturesAggregations.average.time_signature.main.percentage",
    countPath: "trackFeaturesAggregations.average.time_signature.main.count",
  },
];

// maximum two for now, third is the link to track page
export const belowGraphCards = [
  { name: "tempo", objectPath: "tempo", icon: "" },
  { name: "key", objectPath: "key", icon: "" },
];

// for mobile album features
export const mobileFeatures = [
  {
    name: "danceability",
    objectPath: "danceability",
    icon: "",
  },
  {
    name: "energy",
    objectPath: "energy",
    icon: "",
  },
  {
    name: "valence",
    objectPath: "valence",
    icon: "",
  },
  {
    name: "speechiness",
    objectPath: "speechiness",
    icon: "",
  },
  {
    name: "acousticness",
    objectPath: "acousticness",
    icon: "",
  },
  {
    name: "instrumentalness",
    objectPath: "instrumentalness",
    icon: "",
  },
  {
    name: "liveness",
    objectPath: "liveness",
    icon: "",
  },
];
