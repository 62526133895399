import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
  Link,
} from "react-router-dom";
import DashboardOverview from "./dashboardOverview";
import Sidebar from "./sidebar";
import "./artistDashboard.scss";
import Playlists from "./Playlists";
import StreamingStats from "./StreamingStats";
import SocialStats from "./SocialStats";
import EditProfile from "./Profile";
import MyReleases from "../distribution/myreleases/MyReleases";
import MyEarnings from "../distribution/myearnings";
import MyMoney from "../distribution/mymoney";

import MyReleasesSuccessModal from "../distribution/components/MyReleasesSuccessModal";

import {
  getSubscriptionStatuses,
  getPaymentMethod,
} from "../../routes/payments";
import PaymentModal from "../welcomepage/components/PaymentModal";
import WelcomePage from "../welcomepage/WelcomePage";

import Spinner from "../../assets/spinner.svg";
import DashboardHeader from "./DashboardHeader";
import MobileMenuBurger, { MobileMenu } from "./MobileMenu/MobileMenu";

import playtreksTransparentBg from "../../assets/playtreksTransparentBg.png";
import { getSubscriptionPlan } from "../../routes/payments";
import { setEntityLimit } from "../../redux/actions/dashboardActions";
import Subscriptions from "./Subscriptions";
import MyArtists from "../distribution/myartists";
import Airplay from "../../screens/Airplay/Airplay";

import Charts from "../../screens/Charts/Charts";
import EntityCharts from "../../screens/EntityCharts/EntityCharts";
import { getJWT } from "../utils";
import Expenses from "../distribution/Expenses/Expenses";
import Contracts from "./Contracts/Contracts";
import Contacts from "./Contacts/Contacts";
import Transactions from "./Transactions";
const DATA_DASHBOARD = 1;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ArtistDashboard() {
  const jwtToken = getJWT();

  let match = useRouteMatch();
  let query = useQuery();

  const [loading, setLoading] = useState(true);
  const [dataDashboardSubscriptionStatus, setDataDashboardSubscriptionStatus] =
    useState(false);

  useEffect(() => {
    // getSubscriptionStatuses(jwtToken)
    //   .then((r) => {
    //     setDataDashboardSubscriptionStatus(
    //       r?.data?.dataSubscription?.safeToProvision
    //     );
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     //
    //   });
  }, []);

  // function PaidComponent({ targetComponent }) {
  //   if (loading) {
  //     return (
  //       <main
  //         className="dashboard-overview xyFlexCenter"
  //         style={{ height: "100vh" }}
  //       >
  //         <div>
  //           <img
  //             src={Spinner}
  //             alt="please wait"
  //             className="playtreksBigSpinner"
  //           />
  //         </div>
  //       </main>
  //     );
  //   } else {
  //     if (dataDashboardSubscriptionStatus) {
  //       return targetComponent;
  //     } else {
  //       window.location.assign(
  //         "/welcomePage?pay=true&product=" + DATA_DASHBOARD
  //       );
  //     }
  //   }
  // }

  const MobileLogo = () => (
    <Link to="/welcomePage">
      <img
        src={playtreksTransparentBg}
        alt="playtreks"
        className="artist-dashboard-logoMobile"
      />
    </Link>
  );

  return (
    <div className="artist-dashboard">
      <Sidebar />
      <MobileLogo />
      <MobileMenu />
      <MobileMenuBurger />
      <MyReleasesSuccessModal submittedReleaseName={query.get("name")} />
      <Switch>
        {/* <Route path={`${match.path}/`} exact>
          <DashboardHeader />
          <DashboardOverview />
        </Route>
        <Route path={`${match.path}/streamingStats`}>
          <DashboardHeader />
          <StreamingStats />
        </Route>
        <Route path={`${match.path}/socialStats`}>
          <DashboardHeader />
          <SocialStats />
        </Route>
        <Route path={`${match.path}/playlists`}>
          <DashboardHeader />
          <Playlists />
        </Route>

        <Route path={`${match.path}/entityCharts`}>
          <DashboardHeader />
          <EntityCharts />
        </Route>

        <Route path={`${match.path}/airplay`}>
          <DashboardHeader />
          <Airplay />
        </Route>

        <Route path={`${match.path}/charts`}>
          <DashboardHeader />
          <Charts />
        </Route> */}

        <Route path={`${match.path}/myReleases`} exact>
          <MyReleases />
        </Route>
        {/* <Route path={`${match.path}/allReleases`}>
          <MyReleases allUsers />
        </Route> */}
        <Route path={`${match.path}/revenue`}>
          <MyEarnings />
        </Route>
        <Route path={`${match.path}/myEarnings`}>
          <MyMoney />
        </Route>
        <Route path={`${match.path}/contracts`} exact>
          <Contracts />
        </Route>
        <Route path={`${match.path}/people`} exact>
          <Contacts />
        </Route>
        <Route path={`${match.path}/myArtists`}>
          <MyArtists />
        </Route>
        <Route path={`${match.path}/profile`}>
          <EditProfile />
        </Route>
        <Route path={`${match.path}/expenses`} exact>
          <Expenses />
        </Route>
        <Route path={`${match.path}/transactions`} exact>
          <Transactions />
        </Route>
        <Route path={`${match.path}/subscriptions`}>
          <Subscriptions />
        </Route>
      </Switch>
    </div>
  );
}
export default ArtistDashboard;
