import { getJWT } from "../../components/utils";
import axios from "axios";

export async function regenerateReleaseCSV(userEmail, releaseId) {
  let url = `${global.config.API_URL}/api/admin/regenerateReleaseCSV`;

  return axios.put(
    url,
    { userEmail, releaseId },
    {
      headers: { Authorization: "Bearer " + getJWT() },
    }
  );
}
