import * as Yup from "yup";

export const ContractSplitsValidationSchemaLabel = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().typeError("Invalid date").required("Required"),
  rightholders: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        royaltiesShare: Yup.number()
          .typeError("Invalid split")
          .required("Required")
          .min(0, "Cannot be less than 0")
          .max(100, "Cannot be greater than 100"),
        expenseShare: Yup.number()
          .typeError("Invalid split")
          .required("Required")
          .min(0, "Cannot be less than 0")
          .max(100, "Cannot be greater than 100"),
      })
    )
    .required("Cannot be empty"),
  totalShare: Yup.number()
    .typeError("Invalid percentage")
    .min(100, "Total royalties share must be equal to 100%")
    .max(100, "Total royalties share must be equal to 100%"),
  totalExpenseShare: Yup.number()
    .typeError("Invalid percentage")
    .min(100, "Total expenses share must be equal to 100%")
    .max(100, "Total expenses share must be equal to 100%"),
});

export const ContractSplitsValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  startDate: Yup.date().typeError("Invalid date").required("Required"),
  rightholders: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Required"),
        royaltiesShare: Yup.number()
          .typeError("Invalid split")
          .required("Required")
          .min(0, "Cannot be less than 0")
          .max(100, "Cannot be greater than 100"),
      })
    )
    .required("Cannot be empty"),
  totalShare: Yup.number()
    .typeError("Invalid percentage")
    .min(100, "Total royalties share must be equal to 100%")
    .max(100, "Total royalties share must be equal to 100%"),
});
