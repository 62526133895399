import React, { useEffect, useState } from "react";
import { performPayment } from "../../../routes/payments";

// REDU
import { useSelector, useDispatch } from "react-redux";

import spinner from "../../../assets/spinner.svg";

import { useLocation } from "react-router-dom";

import InputComponent from "../../form/InputComponent";
import ValidationTextBox from "../../form/ValidationTextBox";

import PerformPaypalPaymentComponent from "./PerformPaypalPaymentComponent";
import { getJWT, planMap } from "../../utils";
import "../payments.scss";

const PAYPAL = "paypal";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BUNDLE = 0;
const DATA_DASHBOARD = 1;
const DISTRIBUTION = 2;

const INITIAL = "initial";
const IN_PROGRESS = "in_progress";
const SUCCESS = "success";
const ERROR = "error";
const ERROR_403 = "error_403";

function PerformPaymentComponent({
  paymentModalState,
  product,
  productDisplay,
  redirectTo,
}) {
  const jwtToken = getJWT();

  let query = useQuery();

  const productFromQuery = query.get("product");
  const processorFromQuery = query.get("processor");
  const reduxPaymentProcessor = useSelector(
    (state) => state.subscriptionReducer?.subscription?.processor
  );
  const reduxCard = useSelector(
    (state) => state.subscriptionReducer?.subscription?.card
  );
  const usedArtistSlots = useSelector(
    (state) => state.distributionReducer?.usedArtistSlots
  );

  const paymentProcessor = processorFromQuery || reduxPaymentProcessor;

  const [paymentStatus, setPaymentStatus] = useState(INITIAL);

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeValidation, setPromoCodeValidation] = useState(false);

  function handleStripePayment() {
    setPaymentStatus(IN_PROGRESS);
    performPayment(
      productFromQuery || product,
      promoCode.toUpperCase(),
      jwtToken
    )
      .then((r) =>
        r.status === 200 ? setPaymentStatus(SUCCESS) : setPaymentStatus(ERROR)
      )
      .catch((e) => {
        if (e.response.status === 404) {
          setPaymentStatus(INITIAL);
          setPromoCodeValidation(true);
        } else if (e.response.status === 403) {
          setPaymentStatus(ERROR_403);
        } else {
          setPaymentStatus(ERROR);
        }
      });
  }

  useEffect(() => {
    if (paymentStatus === SUCCESS) {
      setTimeout(() => window.location.assign(redirectTo), 3000);
    }
  }, [paymentStatus]);

  // reset state on modal close
  useEffect(() => {
    if (!paymentModalState?.open) {
      setPaymentStatus(INITIAL);
    }
  }, [paymentModalState]);

  function handlePromoCode(value) {
    setPromoCode(value);
    setPromoCodeValidation(false);
  }

  if ([INITIAL, IN_PROGRESS].includes(paymentStatus)) {
    return (
      <div className="performPayment-main">
        <div>
          <div className="performPayment-header">
            <div>
              You are about to subscribe to <br className="mobile480visible" />
              {productDisplay || planMap?.[productFromQuery]?.name} access.
            </div>
          </div>
          <br />
          {/* <div className="performPayment-sub">You will pay x yearly ... ...</div> */}
          {/* <br /> */}
          {paymentProcessor === "stripe" ? (
            <div className="performPayment-couponInput-wrapper">
              <div className="performPayment-couponInput-upperComment d-none">
                PROMOTION CODE
              </div>
              <InputComponent
                commentField=".performPayment-couponInput-upperComment"
                placeholder="PROMOTION CODE"
                paddingTopOnInput={true}
                addon={false}
                inputGroupClassName="performPayment-couponInput-group"
                inputClassName="performPayment-couponInput"
                inputBlurCallback={() => {}}
                changeCallback={(value) => handlePromoCode(value)}
              ></InputComponent>
            </div>
          ) : (
            ""
          )}
          {!promoCodeValidation ? <br /> : ""}
          <ValidationTextBox
            display={promoCodeValidation}
            className="performPayment-couponInput-validation"
          >
            Sorry, the promo code you have provided is not valid.
          </ValidationTextBox>
          <div className="performPayment-btn-wrapper">
            {paymentProcessor === "stripe" ? (
              <div
                className="playtreksButton performPayment-btn"
                onClick={handleStripePayment}
              >
                {paymentStatus === INITIAL ? (
                  <span>
                    Pay with a {reduxCard?.brand} card ending with{" "}
                    {reduxCard?.last4}
                  </span>
                ) : (
                  <span>
                    Payment in progress... &nbsp;
                    <img
                      src={spinner}
                      alt="loading"
                      className="performPayment-btn-spinner"
                    />
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
            <br />
            {paymentProcessor !== "stripe" ? (
              <PerformPaypalPaymentComponent
                className="playtreksButton performPayment-btn"
                logo={false}
                text="Pay with PayPal"
                productId={productFromQuery || product}
                redirectTo={redirectTo}
                paymentModalState={paymentModalState}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  } else if (paymentStatus === SUCCESS) {
    return (
      <div className="performPayment-main">
        <div className="performPayment-header">
          <div>
            <br />
            Subscription successful!{" "}
            <span alt="ok" aria-label="ok" className="nrTick">
              ✅
            </span>{" "}
            <br />
            <br /> Thank you for your purchase!
            <br />
            <br />
            <small>Redirecting...</small>
            <br />
            <img
              src={spinner}
              alt="loading"
              className="performPayment-btn-spinner"
            />
          </div>
        </div>
      </div>
    );
  } else if (paymentStatus === ERROR_403) {
    return (
      <div className="performPayment-main">
        <div className="performPayment-header">
          <div>
            <span
              alt="forbidden"
              aria-label="forbidden"
              style={{ fontSize: "3rem" }}
            >
              ⛔
            </span>
            <br /> <br />
            You are currently using {usedArtistSlots.length} artist slots within
            PlayTreks. Therefore, you cannot downgrade to this plan. Please
            choose a plan with at least {usedArtistSlots.length} artist slots.
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="performPayment-main">
        <div className="performPayment-header">
          <div>
            <span alt="error" aria-label="error" style={{ fontSize: "3rem" }}>
              ❌
            </span>
            <br />
            <br />
            There has been an error, please try again{" "}
            <div
              className="playtreksUnderlinePrimaryText performPayment-error-retry"
              onClick={() => setPaymentStatus(INITIAL)}
            >
              Retry
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PerformPaymentComponent;
