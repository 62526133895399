import React, { useState } from "react";

// REDUX
import { useSelector } from "react-redux";

// packages
import { get as sliceObject } from "lodash";

// compo
import HorizontalScrollButtonGroup from "../HorizontalScrollButtonGroup";
import TrackFeaturesCard from "./TrackFeaturesCard";

// assets
import Spinner from "../../../assets/spinner.svg";
import cards from "./trackFeaturesCardsConfig";

const HORIZONTAL_SCROLL_SIZE = 200;

function TrackFeaturesCardsBox({ featureData }) {
  const [streamingStatsBtn, setStreamingStatsBtn] = useState({
    left: true,
    right: false,
    scrollLeft: 1,
  });

  const reduxTrackFeatures = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.trackFeatures
  );

  function handleStreamingStatScroll(element, direction) {
    const scrollVal =
      direction === "left"
        ? element.scrollLeft - HORIZONTAL_SCROLL_SIZE
        : element.scrollLeft + HORIZONTAL_SCROLL_SIZE;
    setStreamingStatsBtn((state) => ({
      left: element.scrollLeft === 0,
      right: state.scrollLeft > 0 && state.scrollLeft === scrollVal,
      scrollLeft: scrollVal,
    }));
    direction === "left"
      ? (element.scrollLeft -= HORIZONTAL_SCROLL_SIZE)
      : (element.scrollLeft += HORIZONTAL_SCROLL_SIZE);
  }

  return (
    <div>
      <div className="dashboard-overview-streaming-stats-header">
        <span className="dashboard-overview-streaming-stats-header-left">
          <span
            className="track-features-left-content-header-emoji"
            role="img"
            aria-label={"track analysis"}
          >
            {"🔈"}
          </span>
          <label>&nbsp;Track Analysis</label>
        </span>
        <div className="dashboard-overview-streaming-stats-header-scroll">
          <HorizontalScrollButtonGroup
            // disableLeft={streamingStatsBtn["left"]}
            // disableRight={streamingStatsBtn["right"]} // no disabling arrows (problematic)
            handleScroll={(direction) =>
              handleStreamingStatScroll(
                document.querySelector(
                  ".dashboard-overview-streaming-stats-cards"
                ),
                direction
              )
            }
          />
        </div>
      </div>
      {!featureData ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <div className="dashboard-overview-streaming-stats-cards">
          {cards.map((card) => (
            <TrackFeaturesCard
              name={card.name}
              objectPath={sliceObject(reduxTrackFeatures, card?.objectPath)}
              cardIcon={""}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default TrackFeaturesCardsBox;
