import React, { useState, useEffect } from "react";

import Select, { components } from "react-select";
import ReactTooltip from "react-tooltip";

import Arrow from "../../assets/icons/selectInputArrow.svg";

function SelectComponent(props) {
  const [inputPresent, setInputPresent] = useState(false);
  const [value, setValue] = useState();
  const [input, setInput] = useState("");

  var paddingLeftHackFix = "";

  useEffect(() => {
    if (props.value) {
      setInputPresent(true); // <-- for field css (upload page)
      setValue(props.value);
    }
  }, [props.value]);

  function IndicatorSeparator() {
    return <div></div>;
  }

  function DropdownIndicator() {
    return (
      <div>
        {props.addon ? (
          <span onClick={props.addonClickCallback}>
            <img
              alt={props.alt}
              src={props.img}
              className={props.imgClassName}
              style={{
                marginRight: "17px",
                marginBottom: props.shortInput ? "10px" : "",
                cursor: "pointer",
              }}
              data-tip={props.tooltipText}
            />
            <ReactTooltip
              place="top"
              backgroundColor="#1d2025"
              className="ptTooltip"
              border
              borderColor="#0ef1db"
              effect="float"
              multiline={true}
            />
          </span>
        ) : (
          ""
        )}
        {!props.disabled && !props.disableArrow ? (
          <img
            alt="arrow"
            src={Arrow}
            className="dropdownArrow"
            style={{
              marginRight: "20px",
              marginBottom: props.shortInput ? "10px" : "",
            }}
          ></img>
        ) : (
          ""
        )}
      </div>
    );
  }

  function Placeholder(props) {
    return <components.Placeholder {...props} />;
  }

  const inputTopic = props.groupId;

  function handleChange(e, type) {
    if (type?.action === "input-blur" || type?.action === "menu-close") {
      return;
    }

    if (!props.disabled) setInput(e);
    // if (e.length > 0 || props.form?.[props.databaseField]?.length > 0) {
    setInputPresent(true);
    props.selectChangeCallback(
      e,
      props.databaseField,
      props.commentField,
      props.databaseKey,
      type,
      e?.length > 0 ? true : false // dont validate if no input in this case
    );
    // }
  }

  function handleBlur(e) {
    props.selectBlurCallback(
      props.commentField,
      props.databaseField,
      value,
      props.databaseKey,
      props.id
    );
  }

  function handleClick(e) {
    if (props.disabled) e.target.blur(); // WATCH OUT
    if (props.onClick) {
      props.onClick(e);
    }
    // props.clickCallback(e, props.dropdownField, props.groupId); <== TODO: might be needed for data transfer
  }

  var options = props.options === "loading" ? "" : props.options;

  const customNoOptionsMessage = props.customNoOptionsMessage;

  const customMenuAction = props.customMenuAction;

  const NoOptionsMessage = (props) => {
    return customNoOptionsMessage || <components.NoOptionsMessage {...props} />;
  };

  // custom menu list, only appears if menuListCustomComponent passed
  function MenuList(props) {
    if (props.options[0] === "idle") {
      // when first opened
      return (
        <div>
          <div className="menuListText">Please type to search for labels</div>
        </div>
      );
    } else if (props.options[0] === "loading") {
      // when loading
      return (
        <div>
          <div className="menuListText">Loading...</div>
        </div>
      );
    } else if (props.options.length === 0 && !customNoOptionsMessage) {
      // when label is not found
      return (
        <div>
          <div className="menuListEmptyText">Can’t find your {inputTopic}?</div>
          <div className="menuListEmptyButton">Add new one</div>
        </div>
      );
    }
    // when label is found
    return (
      <components.MenuList {...props}>
        {props.children}

        {customMenuAction && customMenuAction(props)}
      </components.MenuList>
    );
  }

  let placeholder = props.value || props.placeholder;
  if (props.isMulti) {
    placeholder = props.placeholder;
  }

  return (
    <Select
      // defaultInputValue={props.value}
      isMulti={props.isMulti || false}
      value={props.value}
      id={props.id}
      options={options}
      components={{
        DropdownIndicator,
        IndicatorSeparator,
        Placeholder,
        MenuList,
        NoOptionsMessage,
      }}
      label="Single select"
      className={props.inputGroupClassName}
      classNamePrefix="ruslanSelect"
      placeholder={placeholder}
      onBlur={handleBlur}
      onFocus={handleClick}
      onInputChange={(e, t) => handleChange(e, t)}
      onChange={(e, t) => handleChange(props.isMulti ? e : e.value, t)}
      openMenuOnClick={!props.noMenuOnClick}
      menuIsOpen={props.menuIsOpen !== undefined ? props.menuIsOpen : undefined} // if menuIsOpen is passed, then opening / closing is controlled by state. Otherwise, it's auto handled by the package.
      styles={{
        control: (base) => ({
          ...base,
          lineHeight: props.shortInput ? "54px" : "34px",
          paddingLeft: "2px", // experimental
        }),
        placeholder: (base) => ({
          ...base,
          paddingTop:
            inputPresent && !props.shortInput
              ? "12px"
              : props.placeholderPaddingTop || "",
          paddingBottom: props.shortInput ? "11px" : "",
          paddingLeft: paddingLeftHackFix,
          fontFamily: inputPresent
            ? "Inter-Medium"
            : props.placeholderFont || "Inter-Light",
          color: inputPresent ? "white" : props.placeholderColor || "",
          opacity: inputPresent ? "1" : props.placeholderOpacity || "0.65",
          top: props.placeholderTop || "50%",
          ...(props?.styles?.placeholder || {}),
        }),
        input: (base) => ({
          ...base,
          paddingTop: inputPresent && !props.shortInput ? "10px" : "",
          color: "white",
          cursor: props.disabled ? "arrow" : "",
          flex: "1 1 auto",
          display: "inline-grid",
          gridArea: "1 / 1 / 2 / 3",
          gridTemplateColumns: "0px min-content",
        }),
        option: (base, target) => {
          return {
            ...base,
            marginTop: target.data.index === 0 ? "-4px" : "",
            zIndex: 999,
            background:
              (target.isFocused || target.isSelected) === true
                ? "rgba(255, 255, 255, 0.1)"
                : "",
            borderRadius: "0 10px 10px 0",
            color: "#FFFFFF !important",
            width: props.shortInput
              ? "80%"
              : props.extendOptionWidth
              ? "90%"
              : "75%",
            height: "10px",
            paddingBottom: "29px",
            fontSize: "14px",
          };
        },
        menuList: (base) => ({
          ...base,
          overflowY: "scroll",
          height: "100%",
        }),
        menu: (base) => ({
          ...base,
          display: props.disabled ? "none" : "",
        }),
        singleValue: (base) => ({
          ...base,
          color: "#FFFFFF",
          paddingTop: props.shortInput ? "15px" : "25px",
          paddingLeft: "1px",
        }),
        container: (base) => ({
          ...base,
          // opacity: props.disabled ? "0.5" : "1",
        }),
      }}
      // more custom styles are in the scss file
    ></Select>
  );
}

const styles = {
  control: (styles) => ({
    ...styles,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    backgroundColor: "#1d2025",
  }),
  input: (styles) => ({ ...styles, color: "white" }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "#1d2025",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    fontFamily: "Inter",
    backgroundColor:
      isFocused || isSelected ? "rgba(255, 255, 255, 0.1)" : "#1d2025",
    color: isSelected ? "#0ef1db" : "white",
  }),
  singleValue: (base) => ({
    ...base,
    color: "#FFFFFF",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: "#FFBDAD",
    color: "#DE350B",
  }),
};

export const SelectV2 = (props) => {
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          primary: "#0ef1db",
        },
      })}
      styles={styles}
      classNamePrefix="select-v2"
      {...props}
    />
  );
};

export default SelectComponent;
