import React from "react";

import MoreIcon from "../../../assets/icons/more-horizontal-gray.svg";

function GoodMorning({ name }) {
  return (
    <div className="wpGoodMorning">
      <p className="wpGoodMorning-greeting">Hello 🌞</p>
      <br />
      <p className="wpGoodMorning-artistName">{name}</p>
      <p className="wpGoodMorning-dots">
        <img src={MoreIcon} alt="more" />
      </p>
    </div>
  );
}

export default GoodMorning;
