import { REDUX_SET_USER_TRANSACTIONS } from "../actions/moneyActions";

var initialState = {
  userTransactions: [],
  transactionsNoData: undefined,
};

function earningsReducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_SET_USER_TRANSACTIONS:
      state.userTransactions = action.transactions;
      state.transactionsNoData = !action.transactions.length;
      return state;
    default:
      return state;
  }
}

export default earningsReducer;
