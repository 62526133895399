import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import StatisticsChartBox from "../StatisticsChartBox";

import StatsCard from "../StatsCard";
import StatsCardIcon from "../StatsCardIcon";

import FeedBack from "../FeedBack";

import SpotifyColorIcon from "../../../assets/icons/spotify-color.svg";
import AppleColorIcon from "../../../assets/icons/apple-color.svg";

import "./index.scss";
import DashboardHeader from "../DashboardHeader";
import StreamingStatsCardBox from "./StreamingStatsCardsBox";

import AddFbPageModal from "../Profile/components/AddFbPageModal";
import AddIgPageModal from "../Profile/components/AddInstagramPageModal";
import {
  reduxSetUserData,
  reduxSetPlaylistsDisplayPlatform,
} from "../../../redux/actions/dashboardActions";
import AddSpotifyArtistModal from "../Profile/components/AddSpotifyArtistModal";

const APPLE = "apple";
const SPOTIFY = "spotify";
const INSTAGRAM = "instagram";
const FACEBOOK = "facebook";
const TWITTER = "twitter";

function StreamingStats() {
  // modals
  const [spotifyArtistModal, setSpotifyArtistModal] = useState(false);
  const [facebookArtistModal, setFacebookArtistModal] = useState(false);
  const [instagramArtistModal, setInstagramArtistModal] = useState(false);

  // redux
  const entityStats = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.deltaStats?.stats
  );
  const entityStatsDelta = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.deltaStats?.diffStats
  );

  const dispatch = useDispatch();

  function handleSocialClick() {}

  function handlePlusIconClick(platformName) {
    switch (platformName) {
      case SPOTIFY: {
        setSpotifyArtistModal(true);
        break;
      }
      case FACEBOOK: {
        setFacebookArtistModal(true);
        break;
      }
      case INSTAGRAM: {
        setInstagramArtistModal(true);
        break;
      }
      case TWITTER: {
        window.location.assign("/artistDashboard/profile");
        break;
      }
    }
  }

  function closeModal() {
    setSpotifyArtistModal(false);
    setFacebookArtistModal(false);
    setInstagramArtistModal(false);
    dispatch(reduxSetUserData());
  }

  return (
    <main className="streaming-stats-overview">
      <AddSpotifyArtistModal
        closeModalCallback={closeModal}
        className={!spotifyArtistModal ? "d-none" : ""}
      />
      <AddFbPageModal
        closeModalCallback={closeModal}
        className={!facebookArtistModal ? "d-none" : ""}
        connectPlatformUrl={"/artistDashboard/profile"}
      />
      <AddIgPageModal
        closeModalCallback={closeModal}
        className={!instagramArtistModal ? "d-none" : ""}
        connectPlatformUrl={"/artistDashboard/profile"}
      />
      <section className="streaming-stats-overview-streaming-stats">
        <StreamingStatsCardBox
          entityStats={entityStats}
          entityStatsDelta={entityStatsDelta}
          handlePlusIconClick={handlePlusIconClick}
          noFooter
        />
      </section>
      <StatisticsChartBox
        title="spotify popularity"
        platforms={[
          {
            name: "spotify",
            icon: SpotifyColorIcon,
            objectPath: "stats.popularity",
          },
        ]}
      />
      <StatisticsChartBox
        title="spotify followers"
        platforms={[
          {
            name: "spotify",
            icon: SpotifyColorIcon,
            objectPath: "stats.followers",
          },
        ]}
      />
      <StatisticsChartBox
        title="number of spotify playlists"
        singleSelect={true}
        groupName={"playlists"}
        platforms={[
          {
            name: "all",
            text: "all",
            icon: SpotifyColorIcon,
            objectPath: "stats.spotify_playlists.all_spotify_playlists_count",
          },
          {
            name: "editorial",
            text: "editorial",
            icon: SpotifyColorIcon,
            objectPath:
              "stats.spotify_playlists.editorial_spotify_playlists_count",
          },
          {
            name: "user",
            text: "user",
            icon: SpotifyColorIcon,
            objectPath: "stats.spotify_playlists.user_spotify_playlists_count",
          },
        ]}
      />
      {/* <StatisticsChartBox
        title="number of spotify editorial playlists"
        platforms={[
          {
            name: "editorial spotify",
            icon: SpotifyColorIcon,
            objectPath:
              "stats.spotify_playlists.editorial_spotify_playlists_count",
          },
        ]}
      />

      <StatisticsChartBox
        title="number of user playlists"
        platforms={[
          {
            name: "editorial spotify",
            icon: SpotifyColorIcon,
            objectPath: "stats.spotify_playlists.user_spotify_playlists_count",
          },
        ]}
      /> */}
      <StatisticsChartBox
        title="spotify playlists reach"
        singleSelect={true}
        groupName={"playlists"}
        platforms={[
          {
            text: "all",
            name: "all",
            icon: SpotifyColorIcon,
            objectPath: "stats.spotify_playlists.all_spotify_playlists_reach",
          },
          {
            name: "editorial",
            text: "editorial",
            icon: SpotifyColorIcon,
            objectPath:
              "stats.spotify_playlists.editorial_spotify_playlists_reach",
          },
          {
            text: "user",
            name: "user",
            icon: SpotifyColorIcon,
            objectPath: "stats.spotify_playlists.user_spotify_playlists_reach",
          },
        ]}
        buttonConfig={{
          link: "/artistDashboard/playlists",
          text: "See all Spotify playlists",
          onClick: () => dispatch(reduxSetPlaylistsDisplayPlatform(SPOTIFY)),
        }}
      />

      <StatisticsChartBox
        title="number of apple playlists"
        singleSelect={true}
        groupName={"playlists"}
        platforms={[
          {
            name: "all",
            text: "all",
            icon: AppleColorIcon,
            objectPath: "stats.apple_playlists.all_apple_playlist_count",
          },
          {
            name: "editorial",
            text: "editorial",
            icon: AppleColorIcon,
            objectPath: "stats.apple_playlists.editorial_apple_playlist_count",
          },
          {
            name: "user",
            text: "user",
            icon: AppleColorIcon,
            objectPath: "stats.apple_playlists.external_apple_playlist_count",
          },
        ]}
        buttonConfig={{
          link: "/artistDashboard/playlists",
          text: "See all Apple playlists",
          onClick: () => dispatch(reduxSetPlaylistsDisplayPlatform(APPLE)),
        }}
        // chartColors={["#A157FF"]}
      />

      <br />
      <br />

      {/* <section className="streaming-stats-overview-feedback">
        <FeedBack />
      </section> */}
    </main>
  );
}
export default StreamingStats;
