import React, { useEffect, useState } from "react";

import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { getAllUsersBasicInfo } from "../../../routes/admin";

const styles = {
  textAlign: "left",
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "grey" : provided.backgroundColor,
  }),
  input: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    color: "#a5aaa8",
  }),
  group: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025",
    color: "#fff",
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025",
    color: "#0ef1db",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025",
    color: "#a5aaa8 !important",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "transparent",
    border: "unset",
    borderRadius: "10px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    borderRadius: "10px",
    border: "1px solid grey",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#a5aaa8",
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025 !important",
    color: "#a5aaa8",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    backgroundColor: "#1d2025 !important",
    color: "#a5aaa8",
  }),
  multiValue: (base) => ({
    ...base,
    borderBottom: "1px solid #0ef1db",
    marginRight: "0.5em",
    backgroundColor: "transparent",
    color: "white",
  }),
};

const MultiValueContainer = (props) => {
  return (
    <components.MultiValueContainer {...props}>
      {props.data.value}
    </components.MultiValueContainer>
  );
};

function UserSearch({ setUserFilter, userFilter, isMulti = true }) {
  const [userEmailsList, setUserEmailsList] = useState();

  const filterUsers = (inputValue) => {
    return userEmailsList.filter((el, i) => el.label.includes(inputValue));
  };

  useEffect(() => {
    getAllUsersBasicInfo()
      .then((r) => {
        setUserEmailsList(
          r.data.map((r) => {
            return {
              value: r.email,
              label: `${r.email} ${r.firstname} ${r.lastname}  ${
                r?.artistname ? "aka " + r.artistname : ""
              }`,
            };
          })
        );
      })
      .catch((e) => console.error(e));
  }, []);

  async function promiseOptions(inputValue) {
    return filterUsers(inputValue);
  }

  function handleChange(e) {
    if (!Array.isArray(e)) {
      setUserFilter([e.value]);
      return;
    }
    let pickedUsersEmails = e.map((e) => e.value);
    setUserFilter(pickedUsersEmails);
  }

  return (
    <AsyncSelect
      isMulti={isMulti}
      styles={styles}
      placeholder="All users"
      loadOptions={promiseOptions}
      defaultOptions={userEmailsList}
      onChange={handleChange}
      components={{ MultiValueContainer }}
    />
  );
}

export default UserSearch;
