import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import StatisticsChart from "../../HighCharts/StatisticsChart";
import SocialRadioItem from "../SocialRadioItem";

import "./index.scss";
import { getColorScheme, isIframe, PLATFORM } from "../../utils";
import { get as sliceObject, isEmpty } from "lodash";
import moment from "moment";
import { reduxSetStatsChartGroupName } from "../../../redux/actions/dashboardActions";
import { Link } from "react-router-dom";
import ButtonComponent from "../../form/ButtonComponent";

function StatisticsChartBox({
  title,
  platforms,
  comingSoon,
  chartColors,
  singleSelect,
  groupName,
  buttonConfig,
  source,
  chartButtonsConfig,
}) {
  const defaultEnabledPlatforms = platforms.filter(
    (platform) => platform?.defaultEnabled
  );
  const [pickedPlatforms, setPickedPlatforms] = useState(
    defaultEnabledPlatforms.length > 0
      ? defaultEnabledPlatforms
      : [platforms[0]]
  );

  // REDUX
  const dispatch = useDispatch();
  const reduxEntityDataHistory = useSelector(
    (state) => state.dashboardReducer.entityDataHistory
  );

  const welcomePageDataHistory =
    useSelector(
      (state) => state.dashboardReducer?.welcomePageData?.statsOvertime
    ) || [];

  // source
  const entityDataHistory =
    source === "welcomePage" ? welcomePageDataHistory : reduxEntityDataHistory;

  const reduxUserData = useSelector((state) => state.dashboardReducer.userData);

  const reduxGroupName = useSelector(
    (state) => state.dashboardReducer.statisticsChartBoxGroupName
  );

  function mapHighChartsData(reduxEntityDataHistory) {
    function mapHighChartsPlatformData(data, platform) {
      return {
        marker: {
          enabled: true,
          // TODO symbol spotify vs apple
          // symbol: "url(../../../../assets/icons/spotify-color.svg)",
        },
        name: data?.name + " " + platform.name,
        data:
          data?.statsOvertime
            ?.filter(
              (item) => sliceObject(item, platform.objectPath) !== undefined
            )
            ?.map((chartData) => {
              const platformCount = sliceObject(chartData, platform.objectPath);
              return [
                moment(chartData.date.slice(0, 10)).valueOf(),
                Number(platformCount),
              ];
            })
            ?.reverse() || [],
        color: data?.color,
      };
    }

    const series = pickedPlatforms.flatMap((pickedPlatform) =>
      reduxEntityDataHistory.map((entity) =>
        mapHighChartsPlatformData(entity, pickedPlatform)
      )
    );

    function getPlotLines(data, platform) {
      return data?.albumsStats?.albums?.map(obj => ({...obj, slug: data?.name + " " + platform.name}));
    }

    const plotLines = pickedPlatforms.flatMap((pickedPlatform) =>
      reduxEntityDataHistory.map((entity) => getPlotLines(entity, pickedPlatform))
    );

    return {
      series: series,
      plotLines: plotLines,
    };
  }

  function handleSocialIconChange(event, platform) {
    var newPickedPlatforms;
    if (singleSelect) newPickedPlatforms = [platform];
    else
      newPickedPlatforms = pickedPlatforms
        .map((p) => p.name)
        .includes(platform.name)
        ? pickedPlatforms.filter((i) => i.name !== platform.name)
        : [...pickedPlatforms, platform];

    setPickedPlatforms(newPickedPlatforms);
    if (groupName)
      dispatch(
        reduxSetStatsChartGroupName({
          groupName: groupName,
          state: newPickedPlatforms,
        })
      );
  }

  useEffect(() => {
    if (!groupName || !reduxGroupName || !(groupName in reduxGroupName)) return;

    const names = reduxGroupName[groupName].map((p) => p.name);
    setPickedPlatforms(
      platforms.filter((platform) => names.includes(platform.name))
    );
  }, [reduxGroupName]);

  return (
    <section className="statistics-chart-box">
      <div className="statistics-chart-box-artists-stats">
        <div className="statistics-chart-box-artists-stats-buttons">
          {platforms.map((platform, index) => {
            return (
              <SocialRadioItem
                className="mr-2"
                checked={pickedPlatforms
                  .map((p) => p.name)
                  .includes(platform.name)}
                handleChange={(e) => handleSocialIconChange(e, platform)}
              >
                <img src={platform.icon} alt={platform} />
                {platform.text && <span>{platform.text}</span>}
              </SocialRadioItem>
            );
          })}
        </div>
        <div className="statistics-chart-box-artists-stats-name">
          <label>{title}</label>
        </div>
      </div>
      <div
        className="statistics-chart-box-chart"
        style={comingSoon && { opacity: "0.3" }}
      >
        <StatisticsChart
          data={mapHighChartsData(
            [
              // attached user data if present
              !isEmpty(reduxUserData) && { ...reduxUserData, name: "My" },
              ...entityDataHistory,
            ].filter(Boolean)
          )}
          colors={
            isIframe() || source === "welcomePage"
              ? getColorScheme()
              : "#525f7f"
          }
          chartButtonsConfig={chartButtonsConfig}
        />
      </div>
      <div
        className={comingSoon ? "statistics-chart-box-comingSoon" : "d-none"}
      >
        coming soon...
      </div>
      {buttonConfig && (
        <div className={"dashboard-overview-your-top-playlists-more-details"}>
          <Link to={buttonConfig.link}>
            <ButtonComponent
              className="dashboard-overview-your-top-playlists-more-details-button"
              reduxse
              onClick={buttonConfig.onClick}
            >
              {buttonConfig.text}
            </ButtonComponent>
          </Link>
        </div>
      )}
    </section>
  );
}

export default StatisticsChartBox;
