import React, { useCallback, useState } from "react";
import InputComponent from "../../../components/form/InputComponent";
import ButtonComponent from "../../../components/form/ButtonComponent";

import { FieldArray, Formik, ErrorMessage, useFormikContext } from "formik";

import Toast from "../../../components/Toast";
import { Spinner } from "../../../screens/Airplay/Airplay";
import CalendarComponent from "../../form/CalendarComponent";
import calendar from "../../../assets/icons/calendar.svg";

import { useMutation, useQuery } from "react-query";
import { createContract, updateContract } from "../../../services/contracts";
import { useSelector } from "react-redux";
import RightHolder from "./RightHolder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TrashIcon from "../../../assets/icons/trash.svg";
import { v4 as uuidv4 } from "uuid";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { memo } from "react";
import { useMemo } from "react";
import ContactForm from "../Contacts/ContactForm";
import {
  ContractSplitsValidationSchema,
  ContractSplitsValidationSchemaLabel,
} from "./Validation";
import { getContacts } from "../../../services/contact";
import { labelPlans } from "../../utils";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const TotalShare = memo((props) => {
  const {
    values: { rightholders, totalShare },
    errors: { totalShare: errTotalShare = false },
    touched: { totalShare: touchedTotalShare = false },
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    const total = rightholders?.reduce(
      (acc, curr) => acc + Number(curr.royaltiesShare),
      0
    );

    if (total >= 0) {
      setFieldValue("totalShare", total);
    } else {
      setFieldValue("totalShare", 0);
    }
  }, [rightholders, setFieldValue]);

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div
        className={
          (totalShare ? "" : "d-none") + " inputUpperComment utiuc3_totalShare"
        }
      >
        Total
      </div>
      <div>
        <div className="position-relative">
          <InputComponent
            disabled={true}
            commentField={".utiuc3_totalShare"}
            placeholder="Share"
            inputGroupClassName="nrInputGroup "
            inputClassName={
              " nrInput utepInput " +
              (totalShare ? " interMediumInput interMediumPaddingTop " : "") +
              (errTotalShare && touchedTotalShare ? "text-danger" : "")
            }
            paddingTopOnInput={true}
            addon={false}
            tooltip={true}
            maxlength={3}
            value={totalShare}
          />
        </div>
      </div>
    </div>
  );
});

const TotalExpenseShare = memo((props) => {
  const {
    values: { rightholders, totalExpenseShare },
    errors: { totalExpenseShare: errTotalExpenseShare = false },
    touched: { totalExpenseShare: touchedTotalExpenseShare = false },
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    const total = rightholders?.reduce(
      (acc, curr) => acc + Number(curr.expenseShare),
      0
    );

    if (total >= 0) {
      setFieldValue("totalExpenseShare", total);
    } else {
      setFieldValue("totalExpenseShare", 0);
    }
  }, [rightholders, setFieldValue]);

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div
        className={
          (totalExpenseShare ? "" : "d-none") +
          " inputUpperComment utiuc3_totalExpenseShare"
        }
      >
        Total
      </div>
      <div>
        <div className="position-relative">
          <InputComponent
            disabled={true}
            commentField={".utiuc3_totalExpenseShare"}
            placeholder="Share"
            inputGroupClassName="nrInputGroup "
            inputClassName={
              " nrInput utepInput " +
              (totalExpenseShare
                ? " interMediumInput interMediumPaddingTop "
                : "") +
              (errTotalExpenseShare && touchedTotalExpenseShare
                ? "text-danger"
                : "")
            }
            paddingTopOnInput={true}
            addon={false}
            tooltip={true}
            maxlength={3}
            value={totalExpenseShare || 0}
          />
        </div>
      </div>
    </div>
  );
});

const addDay = (date) => {
  const newdate = new Date(date);

  return newdate.setDate(newdate.getDate() + 1);
};

const ContractForm = ({ onClose, onUpdate, edit, contract, refetch }) => {
  const [showToast, setShowToast] = useState(initialToastState);

  const [openContactForm, setContactForm] = useState({
    isOpen: false,
    index: null,
  });

  const [calendarState, setCalendarState] = useState({
    startDate: false,
    endDate: false,
  });

  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );

  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const handleOpenCalendar = useCallback((className, forField, id) => {
    setCalendarState((prevCalendarState) => ({
      ...prevCalendarState,
      [id]: !prevCalendarState[id],
    }));
  }, []);

  const { data: { data: contacts = [] } = {}, refetch: refetchContacts } =
    useQuery("contacts", getContacts);

  const contactsOptions = useMemo(() => {
    return contacts.reduce((acc, contact) => {
      acc[contact._id] = {
        label: contact.firstName + " " + contact.lastName,
        value: contact._id,
        ...contact,
      };
      return acc;
    }, {});
  }, [contacts]);

  const createContractMutation = useMutation(createContract);
  const updateContractMutation = useMutation(updateContract);

  const handleSubmit = (values, formActions) => {
    const payload = {
      name: values.name,
      rightholders: values.rightholders,
      startDate: values.startDate,
      endDate: values.endDate,
    };

    if (edit) {
      updateContractMutation.mutate(
        { contractId: contract._id, ...payload },
        {
          onSuccess: () => {
            setShowToast({
              open: true,
              message: "Contract updated successfully",
              type: "success",
            });
            setTimeout(() => {
              formActions.setSubmitting(false);
              onUpdate({ isOpen: false });
            }, 1250);
            refetch();
          },
          onError: (e) => {
            formActions.setSubmitting(false);
            formActions.resetForm();
            setShowToast({
              open: true,
              message: e.response.data.message
                ? e.response.data.message
                : "An unexpected error occured. Please try again.",
              type: "danger",
            });
          },
        }
      );
    } else {
      createContractMutation.mutate(payload, {
        onSuccess: () => {
          setShowToast({
            open: true,
            message: "Contract has been created successfully",
            type: "success",
          });
          setTimeout(() => {
            formActions.setSubmitting(false);
            onUpdate({ isOpen: false });
          }, 1250);
          refetch();
        },
        onError: (e) => {
          formActions.setSubmitting(false);
          formActions.resetForm();
          setShowToast({
            open: true,
            message: e.response.data.message
              ? e.response.data.message
              : "An unexpected error occured. Please try again.",
            type: "danger",
          });
        },
      });
    }
  };

  const customMenuAction = (index) => () => {
    return (
      <div
        className="d-flex mt-3 justify-content-center add-contact  align-items-center  py-md-0  text-primary cursor-pointer"
        onClick={() => setContactForm({ isOpen: true, index })}
      >
        <span>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ width: "16px", height: "16px", rotate: "45deg" }}
          />
        </span>
        <span className="ml-2 mr-1 py-2 contract-header ">Add New</span>
      </div>
    );
  };

  const initialTotalShare = useMemo(
    () =>
      contract?.rightholders?.reduce(
        (acc, curr) => acc + Number(curr.royaltiesShare),
        0
      ),
    [contract?.rightholders]
  );

  const initialTotalExpenseShare = useMemo(
    () =>
      contract?.rightholders?.reduce(
        (acc, curr) => acc + Number(curr.expenseShare),
        0
      ),
    [contract?.rightholders]
  );

  const isLabelPlan = labelPlans.some((plan) =>
    plan.includes(reduxCurrentPlan)
  );

  const contractRightholders = contract?.rightsholders?.map((r) => ({
    ...r,
    contact: r?.contact?._id,
    name: `${r?.contact?.firstName ?? ""} ${r?.contact?.lastName ?? ""}`.trim(),
  })) || [
    {
      contact: reduxUserData._id,
      name: `${reduxUserData?.firstname ?? ""} ${
        reduxUserData?.lastname ?? ""
      }`.trim(),
      royaltiesShare: 100,
      expenseShare: isLabelPlan ? 100 : 0,
      type: "owner",
    },
  ];

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />

      <Formik
        enableReinitialize
        initialValues={{
          name: contract?.name || "",
          rightholders: contractRightholders,
          totalShare: initialTotalShare,
          totalExpenseShare: initialTotalExpenseShare,
          startDate: contract?.startDate
            ? contract?.startDate?.split("T")[0]
            : "",
          endDate: contract?.endDate ? contract?.endDate?.split("T")[0] : "",
        }}
        onSubmit={handleSubmit}
        validationSchema={
          !isLabelPlan
            ? ContractSplitsValidationSchema
            : ContractSplitsValidationSchemaLabel
        }
      >
        {(props) => {
          const {
            values: {
              name,
              startDate,
              endDate,
              rightholders,
              totalShare,
              totalExpenseShare,
            },
            dirty,
            isValid,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <div className="row mx-0 pb-4">
              {/* contract name */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    (name ? "" : "d-none") + " inputUpperComment utiuc3_upcCode"
                  }
                >
                  Contract name*
                </div>
                <InputComponent
                  value={name}
                  commentField={".utiuc3_upcCode"}
                  placeholder="Contract Name*"
                  autofocus
                  inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100"
                  inputClassName={
                    "utepInput" +
                    (name ? " interMediumInput interMediumPaddingTop" : "")
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("name", value)}
                  inputBlurCallback={() => setFieldTouched("name", true)}
                />
              </div>
              <ErrorMessage
                name={"name"}
                component="div"
                className="w-100 text-danger line-height-normal ml-3 mb-2 fs-12 "
              />

              {/* Start date */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    "inputUpperComment iuc6 " + (startDate || "d-none")
                  }
                >
                  Start date*
                </div>
                <InputComponent
                  value={startDate} // take from calendar, or pre-populate
                  paddingTopOnInput={true}
                  inputGroupClassName="nrInputGroup w-100"
                  inputClassName={
                    "nrInput nrInput-opacityFix " +
                    (startDate || startDate.length
                      ? "nrCalendarInputPopulated"
                      : "")
                  }
                  commentField=".iuc6"
                  placeholder="Start date*"
                  addonClickCallback={handleOpenCalendar}
                  for="nrReleaseDate"
                  id="startDate"
                  // calendar addon
                  addon="right"
                  calendarAddon={true}
                  calImg={calendar}
                  calImgClass="nrAddonRightIcon"
                  calAlt="pick date"
                  inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                  databaseField="startDate"
                  changeCallback={(value) => setFieldValue("startDate", value)}
                  inputBlurCallback={() => setFieldTouched("startDate", true)}
                />
                <CalendarComponent
                  calendarState={calendarState["startDate"]}
                  calendarClassName="nrReleaseDateCalendar"
                  datePickCallback={(value) =>
                    setFieldValue("startDate", value)
                  }
                  calendarId="startDate"
                  databaseField="startDate"
                  shouldCloseOnSelect={true}
                  // minDate={new Date()}
                />

                <ErrorMessage
                  name={"startDate"}
                  component="div"
                  className="w-100 text-danger line-height-normal  mt-2 fs-12 "
                />
              </div>

              {/* end date */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={"inputUpperComment iuc6 " + (endDate || "d-none")}
                >
                  End date
                </div>
                <InputComponent
                  value={endDate} // take from calendar, or pre-populate
                  paddingTopOnInput={true}
                  inputGroupClassName="nrInputGroup w-100"
                  inputClassName={
                    "nrInput nrInput-opacityFix " +
                    (endDate || endDate.length
                      ? "nrCalendarInputPopulated"
                      : "")
                  }
                  commentField=".iuc6"
                  placeholder="End date"
                  addonClickCallback={handleOpenCalendar}
                  for="nrReleaseDate"
                  id="endDate"
                  // calendar addon
                  addon="right"
                  calendarAddon={true}
                  calImg={calendar}
                  calImgClass="nrAddonRightIcon"
                  calAlt="pick date"
                  inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                  databaseField="endDate"
                  changeCallback={(value) => setFieldValue("endDate", value)}
                  inputBlurCallback={() => setFieldTouched("endDate", true)}
                />
                <CalendarComponent
                  calendarState={calendarState["endDate"]}
                  calendarClassName="nrReleaseDateCalendar"
                  datePickCallback={(value) => setFieldValue("endDate", value)}
                  calendarId="endDate"
                  databaseField="endDate"
                  minDate={startDate ? addDay(startDate) : null}
                  shouldCloseOnSelect={true}
                />
              </div>

              {/* Contract splits */}
              <div
                className="col-12 p-3 rounded"
                style={{ backgroundColor: "#2c313a" }}
              >
                <div className="mb-3">Rights-holders & shares</div>
                <FieldArray name="rightholders">
                  {({ remove, push }) => (
                    <>
                      {openContactForm.isOpen ? (
                        <ContactForm
                          onClose={() =>
                            setContactForm({ isOpen: false, index: null })
                          }
                          onUpdate={(contact) => {
                            setFieldValue(
                              `rightholders.${openContactForm.index}`,
                              {
                                contact: contact._id,
                                name: `${contact.firstName} ${contact.lastName}`,
                                royaltiesShare: 0,
                                expenseShare: 0,
                                type: "contact",
                              }
                            );
                            setContactForm({ isOpen: false, index: null });
                            refetchContacts();
                          }}
                        />
                      ) : (
                        <>
                          <div
                            className={`row px-0 mx-0 ${
                              isLabelPlan ? "col" : "col-11"
                            }`}
                          >
                            <div
                              className={isLabelPlan ? "col-5" : "col-8"}
                            ></div>
                            <div
                              className={`col-3 mb-1 align-items-start ${
                                isLabelPlan ? "pl-5" : ""
                              }`}
                            >
                              <b>Royalties</b>
                            </div>

                            {isLabelPlan && (
                              <div className="col-3 mb-1 pl-4 ml-1 align-items-start">
                                <b>Expenses</b>
                              </div>
                            )}
                            <div className="col-1"></div>
                          </div>
                          {rightholders?.map((rightholder, index) => (
                            <>
                              <div
                                className="row px-0 mx-0 col"
                                key={rightholder.id}
                              >
                                <div className="col-11 mx-0 px-0 ">
                                  <RightHolder
                                    index={index}
                                    disabled={index === 0}
                                    rightholder={rightholder}
                                    rightholders={rightholders}
                                    inputChangeCallback={setFieldValue}
                                    inputBlurCallback={setFieldTouched}
                                    customMenuAction={customMenuAction}
                                    contactsOptions={contactsOptions}
                                    reduxUserData={reduxUserData}
                                    isLabelPlan={isLabelPlan}
                                  />
                                </div>

                                {index > 0 && (
                                  <div
                                    className="col-1 row px-0 mx-0 align-items-center"
                                    onClick={() => remove(index)}
                                  >
                                    <span className="cursor-pointer">
                                      <img
                                        src={TrashIcon}
                                        alt="delete rightholder"
                                      />
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div
                                className="utepHr w-100 mb-3 mt-0"
                                style={{ top: 0 }}
                              />
                            </>
                          ))}
                        </>
                      )}
                      <>
                        {!openContactForm.isOpen && (
                          <div className="row col px-0 mx-0 justify-content-between w-100 my-2 mt-3 align-items-center flex-md-wrap">
                            <div className="col-11 mx-0 px-0">
                              <div className="row mx-0 px-0 mb-2">
                                <div
                                  className={`align-items-center d-flex py-md-0 col-6 ${
                                    isLabelPlan ? "col-6" : "col-8"
                                  }`}
                                  onClick={() =>
                                    push({
                                      contact: null,
                                      name: "",
                                      royaltiesShare: "",
                                      expenseShare: "",
                                      type: "contact",
                                    })
                                  }
                                >
                                  <span>
                                    <span
                                      className="text-primary rounded  p-2 px-3 font-weight-bold cursor-pointer mr-2 w-50 h-75"
                                      style={{ backgroundColor: "#2c313a" }}
                                    >
                                      +
                                    </span>
                                    <span
                                      className="text-primary font-family-Inter"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Add rights-holder
                                    </span>
                                  </span>
                                </div>
                                <div className="pb-2 py-md-0 col-3">
                                  <TotalShare value={`${totalShare}%`} />
                                </div>
                                {isLabelPlan && (
                                  <div className="pb-2 py-md-0 col-3 align-items-start">
                                    <TotalExpenseShare />
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-1 mx-0 px-0" />

                            <div className="pb-2 py-md-0 col-12  align-self-center fs-14">
                              <ErrorMessage
                                name={"totalShare"}
                                component="div"
                                className="text-danger mx-0"
                              />
                              <ErrorMessage
                                name={"totalExpenseShare"}
                                component="div"
                                className="text-danger mx-0"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    </>
                  )}
                </FieldArray>
              </div>

              {!openContactForm.isOpen && (
                <div className="ml-0 row col justify-content-end align-items-end mt-4">
                  <ButtonComponent
                    onClick={onClose}
                    className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
                  >
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    disabled={isSubmitting || !dirty}
                    onClick={handleSubmit}
                    buttonWrapperClassName="pr-3"
                    className="playtreksButton w-100 h-100"
                  >
                    {isSubmitting ? (
                      <>
                        <span className="mr-2">Saving... </span>
                        <span>
                          <Spinner />
                        </span>
                      </>
                    ) : (
                      "Save"
                    )}
                  </ButtonComponent>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ContractForm;
