import React, { useEffect, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { setUserTransactions } from "../../../redux/actions/moneyActions";

// pckgs
import { cloneDeep } from "lodash";

//query string support
import { useLocation, useHistory } from "react-router-dom";

// components
import Button from "reactstrap/lib/Button";
import InputComponent from "../../form/InputComponent";
import CurrencyInput from "react-currency-input-field";

// assets
import X from "..//assets/X.svg";

// validation helpers
import {
  valEmailRegex,
  valPhoneNumber,
  valDateOfBirth,
  fieldRequired,
} from "../validation/distrValidationHelpers";
import {
  requestPayout,
  getUserBillingInfo,
  updateUserBillingInfo,
} from "../../../routes/sales";

const TRESHOLD_VAL = "treshold_val";
const FUNDS_VAL = "funds_val";
const NO_AMOUNT = "no_amount";

const IN_PROGRESS = "in_progress";
const SUCCESS = "success";
const ERROR = "error";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// NOTE: when adding a new field, make sure billingFormData has a respective property
export const billingFormConfig = [
  {
    id: "firstname",
    label: "First name*",
    validation: fieldRequired,
    validationMsg: "Please provide your first name",
  },
  {
    id: "lastname",
    label: "Last name*",
    validation: fieldRequired,
    validationMsg: "Please provide your last name",
  },
  {
    id: "companyName",
    label: "Company Name",
    validation: (v) => false,
  },
  {
    id: "street",
    label: "Street*",
    validation: fieldRequired,
    validationMsg: "Please provide your postal address street",
  },
  {
    id: "zipcode",
    label: "Zip code*",
    validation: fieldRequired,
    validationMsg: "Please provide your postal address zip code",
  },
  {
    id: "city",
    label: "City*",
    validation: fieldRequired,
    validationMsg: "Please provide your postal address city",
  },
  {
    id: "country",
    label: "Country*",
    validation: fieldRequired,
    validationMsg: "Please provide your postal address country",
  },
  {
    id: "phoneNumber",
    label: "Phone number",
    validation: valPhoneNumber,
    validationMsg:
      "Please provide a valid phone number or leave this field blank",
  },
  {
    id: "companyRegistrationNumber",
    label: "Company registration number",
    validation: (v) => false,
  },
  {
    id: "vatNumber",
    label: "Vat number",
    validation: (v) => false,
  },
  {
    id: "dateOfBirth",
    label: "Date of birth",
    validation: valDateOfBirth, // FIXME:
    validationMsg:
      "Please provide a valid date of birth or leave this field blank",
  },
  {
    id: "taxIdentificationNumber",
    label: "Tax Identification Number",
    validation: (v) => false,
  },
];

const initFormData = {
  firstname: { value: undefined, validation: false },
  lastname: { value: undefined, validation: false },
  companyName: { value: undefined, validation: false },
  companyCountry: { value: undefined, validation: false },
  street: { value: undefined, validation: false },
  zipcode: { value: undefined, validation: false },
  city: { value: undefined, validation: false },
  country: { value: undefined, validation: false },
  phoneNumber: { value: undefined, validation: false },
  companyRegistrationNumber: { value: undefined, validation: false },
  vatNumber: { value: undefined, validation: false },
  dateOfBirth: { value: undefined, validation: false },
  taxIdentificationNumber: { value: undefined, validation: false },
};

function RequestPayoutModal({
  overlayClassName = "",
  modalClassName = "my-money-payoutModal",
  setModal,
  userBalance,
  infoEditMode,
}) {
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();

  // email form
  const [payPalEmail, setPayPalEmail] = useState();
  const [payPalEmailRepeated, setPayPalEmailRepeated] = useState();
  const [payPalEmailValidation, setPayPalEmailValidation] = useState();
  const [emailsMatching, setEmailsMatching] = useState();

  // amount input
  const [requestedAmount, setRequestedAmount] = useState();
  const [amountValidation, setAmountValidation] = useState();

  function handleAmountValidation() {
    let parsedRequestedAmount = Number(requestedAmount?.replace(",", ".")); // decimal sensitive fix
    if (!requestedAmount) {
      setAmountValidation(NO_AMOUNT);
      return true;
    } else if (parsedRequestedAmount < 25) {
      setAmountValidation(TRESHOLD_VAL);
      return true;
    } else if (parsedRequestedAmount > userBalance) {
      setAmountValidation(FUNDS_VAL);
      return true;
    } else {
      setAmountValidation(false);
      return false;
    }
  }

  const [billingFormData, setBillingFormData] = useState(initFormData);

  const [billingInfo, setBillingInfo] = useState();
  const [billingInfoReady, setBillingInfoReady] = useState(false); // is true if billing info is filled out and ready for payout request (not in edit mode)

  const [requestStatus, setRequestStatus] = useState(IN_PROGRESS);

  const [endValidation, setEndValidation] = useState();

  const [confirmRequestPayoutActive, setConfirmRequestPayoutActive] =
    useState(true);

  const isBillingDataPresent = () => billingInfo?.billingFormData !== undefined;
  const isEmailPresent = () => billingInfo?.payPalEmail?.length;

  useEffect(() => {
    // fetch billing info, not saving into redux for more personal data obfuscation
    getUserBillingInfo().then((r) => {
      setBillingInfo(r.data);
      if (r.data.billingFormData) {
        // convert to FE
        let populatedFormData = {};
        Object.keys(r.data.billingFormData).forEach((field) => {
          populatedFormData[field] = {
            value: r.data.billingFormData[field],
          };
        });
        setBillingFormData(populatedFormData);
      }
      setPayPalEmail(r.data.payPalEmail);
    });
  }, []);

  function resetState() {
    setBillingFormData(initFormData);
    setRequestStatus(IN_PROGRESS);
    setPayPalEmail();
    setPayPalEmailRepeated();
    setPayPalEmailValidation();
    setEmailsMatching();
    setRequestedAmount();
    setAmountValidation();
    setEndValidation();
    history.replace({
      search: "",
    });
  }

  function handleRequestPayout() {
    // reset end val
    setEndValidation();

    if (setConfirmRequestPayoutActive === false) return;
    setConfirmRequestPayoutActive(false);

    // end validation
    // update all billing form fields with validation values
    let newBillingForm = {};
    billingFormConfig.forEach((field) => {
      newBillingForm[field?.id] = {
        value: billingFormData?.[field?.id]?.value,
        validation: field.validation(billingFormData?.[field?.id]?.value),
      };
    });
    setBillingFormData(newBillingForm);

    let billingFormPassing = Object.keys(newBillingForm).every(
      (field) => newBillingForm[field].validation === false
    );

    // run email validation
    setPayPalEmailValidation(valEmailRegex(payPalEmail));
    setEmailsMatching(payPalEmail === payPalEmailRepeated);

    // run amount validation
    let amountValidation = handleAmountValidation();

    // if form is filled, or data already present
    let billingInfoOk =
      billingInfo.billingFormData || billingFormPassing === true;
    let emailOk =
      billingInfo.payPalEmail ||
      (payPalEmailValidation === false && emailsMatching === true);

    let formOk = billingInfoOk && emailOk && !amountValidation;

    // end values for the request
    let newFormData = billingFormData || [];
    Object.keys(billingFormData).forEach(
      (item) => (newFormData[item] = billingFormData?.[item]?.value)
    );

    let formData = billingInfo.billingFormData || newFormData;
    let formPayPalEmail = payPalEmail || billingInfo.payPalEmail;

    // validation for edit mode
    let editModeFormOk =
      billingInfoOk &&
      payPalEmailValidation === false &&
      emailsMatching === true &&
      billingFormPassing === true;

    if (infoEditMode && editModeFormOk) {
      if (confirmRequestPayoutActive === true)
        updateUserBillingInfo(newFormData, payPalEmail)
          .then((r) => setRequestStatus(SUCCESS))
          .catch((e) => setRequestStatus(ERROR));
    } else if (formOk) {
      // approve
      if (confirmRequestPayoutActive === true)
        requestPayout(requestedAmount, formData, formPayPalEmail)
          .then((r) => setRequestStatus(SUCCESS))
          .catch((e) => setRequestStatus(ERROR));
    } else {
      setEndValidation(true);
      setConfirmRequestPayoutActive(true);
    }
  }

  // TODO:
  // responsiveness

  if (requestStatus === IN_PROGRESS) {
    function handleCloseModal() {
      setModal(false);
      resetState();
    }
    return (
      <div className={"playtreksModal-overlay " + overlayClassName}>
        <div className={"playtreksModal-modal " + modalClassName}>
          <img
            src={X}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={() => handleCloseModal()}
          />
          <div className="playtreksModal">
            {infoEditMode ? (
              <>
                <div className={modalClassName + "-header"}>
                  Edit payout details
                </div>
                <br />
                <br />
              </>
            ) : (
              <div>
                <div className={modalClassName + "-header"}>Payout request</div>{" "}
                <br />
                <br />
                <CurrencyInput
                  prefix="€"
                  value={requestedAmount}
                  onValueChange={setRequestedAmount}
                  className={modalClassName + "-amountInput"}
                  placeholder="Enter amount"
                  disableGroupSeparators
                  allowNegativeValue={false}
                  step={25}
                  onBlur={handleAmountValidation}
                />
                {amountValidation === TRESHOLD_VAL && (
                  <div className={modalClassName + "-fieldValidation-amount"}>
                    The specified amount is lower than the minimum payout
                    treshold. <br />
                    Please provide a higher amount.
                  </div>
                )}
                {amountValidation === FUNDS_VAL && (
                  <div className={modalClassName + "-fieldValidation-amount"}>
                    The specified amount is higher than your balance. <br />
                    Please provide a lower amount.
                  </div>
                )}
                {amountValidation === NO_AMOUNT && (
                  <div className={modalClassName + "-fieldValidation-amount"}>
                    Please provide the requested amount.
                  </div>
                )}
                <br />
                <br />
                <br />
              </div>
            )}
            {!isEmailPresent() || infoEditMode ? (
              <>
                <PayPalEmailForm
                  modalClassName={modalClassName}
                  setPayPalEmail={setPayPalEmail}
                  payPalEmail={payPalEmail}
                  setPayPalEmailRepeated={setPayPalEmailRepeated}
                  payPalEmailRepeated={payPalEmailRepeated}
                  payPalEmailValidation={payPalEmailValidation}
                  setPayPalEmailValidation={setPayPalEmailValidation}
                  emailsMatching={emailsMatching}
                  setEmailsMatching={setEmailsMatching}
                  infoEditMode={infoEditMode}
                />
              </>
            ) : (
              <div>
                <b>Your paypal email address is:</b> <br />{" "}
                <div className={modalClassName + "-payPalEmail"}>
                  {billingInfo?.payPalEmail}
                </div>{" "}
                <small
                  className={modalClassName + "-edit"}
                  onClick={() =>
                    window.location.assign(
                      "/artistDashboard/profile?editPayoutDetails=true"
                    )
                  }
                >
                  edit
                </small>
              </div>
            )}
            <br />
            <br />
            {!isBillingDataPresent() || infoEditMode ? (
              <BillingForm
                billingFormConfig={billingFormConfig}
                billingFormData={billingFormData}
                setBillingFormData={setBillingFormData}
                modalClassName={modalClassName}
              />
            ) : (
              ""
            )}
            {endValidation ? (
              <small
                style={{ color: "red", position: "relative", bottom: "0.5rem" }}
              >
                Please review the form and try again
              </small>
            ) : (
              <br />
            )}
            <Button
              className={
                "playtreksButton " +
                modalClassName +
                "-btn " +
                (confirmRequestPayoutActive
                  ? ""
                  : modalClassName + "-btn-inactive")
              }
              onClick={handleRequestPayout}
            >
              {infoEditMode
                ? "Save"
                : confirmRequestPayoutActive === true
                ? "Confirm payout request"
                : "Please wait..."}
            </Button>
            <br />
          </div>
        </div>
      </div>
    );
  } else if (requestStatus === SUCCESS) {
    function handleCloseModal() {
      resetState();
      setModal(false);
      setTimeout(() => {
        if (infoEditMode) window.location.reload();
        else dispatch(setUserTransactions());
      }, 0);
    }
    return (
      <div className={"playtreksModal-overlay " + overlayClassName}>
        <div className={"playtreksModal-modal " + modalClassName}>
          <img
            src={X}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={() => handleCloseModal()}
          />
          <span aria-label="ok" alt="ok">
            ✅
          </span>
          &nbsp;{" "}
          {infoEditMode ? (
            "Data edited successfully"
          ) : (
            <>
              <b>We're on it!</b> <br />
              <br /> We have successfully received your payout request. Please
              allow some time for processing. Usually, the requested amount is
              available on your PayPal account within a few hours.
            </>
          )}
        </div>
      </div>
    );
  } else {
    function handleCloseModal() {
      resetState();
      setConfirmRequestPayoutActive(true);
      setModal(false);
    }
    function handleReset() {
      resetState();
      setConfirmRequestPayoutActive(true);
    }
    return (
      <div className={"playtreksModal-overlay " + overlayClassName}>
        <div className={"playtreksModal-modal " + modalClassName}>
          <img
            src={X}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={() => handleCloseModal()}
          />
          <span aria-label="ok" alt="ok">
            ❌
          </span>{" "}
          &nbsp; {infoEditMode ? "Data edit failed" : "Payout request failed!"}{" "}
          <br />
          <span
            className={modalClassName + "-edit"}
            onClick={() => handleReset()}
          >
            try again
          </span>
        </div>
      </div>
    );
  }
}

const fieldPopulatedClassName = "interMediumPaddingTop interMediumInput";

const PayPalEmailForm = ({
  payPalEmail,
  setPayPalEmail,
  payPalEmailRepeated,
  setPayPalEmailRepeated,
  modalClassName,
  payPalEmailValidation,
  setPayPalEmailValidation,
  emailsMatching,
  setEmailsMatching,
  infoEditMode,
}) => {
  function handleEmailValidation(value) {
    setPayPalEmailValidation(valEmailRegex(value));
    checkEmailsMatching();
  }

  const checkEmailsMatching = () =>
    setEmailsMatching(payPalEmail === payPalEmailRepeated);

  return (
    <div>
      {!infoEditMode ? (
        <div>
          We currently support payouts through PayPal. <br /> Please fill in
          your PayPal account details below to proceed. If you do not have a
          PayPal account, you can create one{" "}
          <a
            href="https://www.paypal.com/webapps/mpp/account-selection"
            target="_blank"
          >
            here
          </a>
          .
          <br />
          <br />
        </div>
      ) : (
        <div>Please provide your PayPal email:</div>
      )}
      <br />
      <div>
        {/* upper comment inside the field, shows on input change */}
        <div
          className={
            "inputUpperComment rpic1 " + (payPalEmail?.length ? "" : "d-none")
          }
        >
          PayPal email
        </div>
        <InputComponent
          value={payPalEmail}
          commentField=".rpic1"
          placeholder="PayPal email"
          inputClassName={
            modalClassName +
            "-input " +
            (payPalEmail?.length ? fieldPopulatedClassName : "")
          }
          inputGroupClassName="noBoxShadow"
          addon={false}
          paddingTopOnInput={true}
          changeCallback={setPayPalEmail}
          inputBlurCallback={handleEmailValidation}
          onCopy={(e) => e.preventDefault()}
        />
      </div>
      {payPalEmailValidation === true && (
        <div className={modalClassName + "-fieldValidation"}>
          Please enter a valid e-mail address
          <br />
        </div>
      )}
      <br />
      <div>
        {/* upper comment inside the field, shows on input change */}
        <div
          className={
            "inputUpperComment rpic2 " +
            (payPalEmailRepeated?.length ? "" : "d-none")
          }
        >
          Repeat PayPal E-mail
        </div>
        <InputComponent
          value={payPalEmailRepeated}
          commentField=".rpic2"
          placeholder="Repeat PayPal E-mail"
          inputClassName={modalClassName + "-input"}
          inputGroupClassName="noBoxShadow"
          addon={false}
          paddingTopOnInput={true}
          changeCallback={setPayPalEmailRepeated}
          inputBlurCallback={handleEmailValidation}
          onPaste={(e) => e.preventDefault()}
        />
      </div>
      {emailsMatching === false && (
        <div className={modalClassName + "-fieldValidation"}>
          The email addresses are not matching
          <br />
        </div>
      )}
      <br />
    </div>
  );
};

const BillingForm = ({
  billingFormData,
  billingFormConfig,
  setBillingFormData,
  modalClassName,
}) => {
  function handleFieldChange(field, value, index) {
    let newBillingFormData = cloneDeep(billingFormData);
    newBillingFormData[field?.id] = {
      value: value,
      validation: billingFormConfig?.[index]?.validation(value),
    };
    setBillingFormData(newBillingFormData);
  }

  return (
    <>
      <div>Please provide your contact information:</div>
      <br />
      {billingFormConfig.map((field, index) => (
        <>
          <div
            className={
              "inputUpperComment rpfic" +
              index +
              (billingFormData?.[field.id]?.value ? "" : " d-none")
            }
          >
            {field.label}
          </div>
          <InputComponent
            value={billingFormData?.[field.id]?.value}
            commentField={".rpfic" + index}
            placeholder={field.label}
            inputClassName={
              modalClassName +
              "-input " +
              (billingFormData?.[field.id]?.value
                ? fieldPopulatedClassName
                : "")
            }
            inputGroupClassName="noBoxShadow"
            addon={false}
            paddingTopOnInput={true}
            changeCallback={(value) => handleFieldChange(field, value, index)}
            inputBlurCallback={() => {}}
          />
          {billingFormData?.[field.id]?.validation && (
            <div className={modalClassName + "-fieldValidation"}>
              {field?.validationMsg}
            </div>
          )}
          <br />
        </>
      ))}
    </>
  );
};

export default RequestPayoutModal;
