import { Formik } from "formik";
import React, { useState } from "react";
import X from "../../../components/distribution/assets/X.svg";
import { Spinner } from "../../../screens/Airplay/Airplay";
import { createTransaction } from "../../../services/admin/transactions";
import ButtonComponent from "../../form/ButtonComponent";
import InputComponent from "../../form/InputComponent";
import Toast from "../../Toast";
import UserSearch from "../components/AdminUserSearch";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const AdminEarningsModal = ({ onCloseRequest, userEmail }) => {
  const [showToast, setShowToast] = useState(initialToastState);
  const [fromUser, setFromUser] = useState([]);
  const [toUser, setToUser] = useState([]);

  const handleSubmit = async (values) => {
    if (
      !values.amount ||
      values.amount <= 0 ||
      !values.description ||
      !fromUser?.length ||
      !toUser?.length
    ) {
      setShowToast({
        open: true,
        message: "Please fill in all fields correctly",
        type: "danger",
      });
      return;
    }

    const { amount, description } = values;
    const payload = {
      amount,
      description,
      from: fromUser[0],
      to: toUser[0],
    };

    try {
      await createTransaction(payload);
      setShowToast({
        open: true,
        message: "Transaction added successfully",
        type: "success",
      });

      setTimeout(() => onCloseRequest(), 1000);
    } catch (e) {
      setShowToast({
        open: true,
        message: e.response.data.message
          ? e.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />

      <Formik
        initialValues={{
          amount: "",
          description: "",
        }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            setFieldValue,
            handleSubmit,
            setFieldTouched,
            isSubmitting,
            values,
          } = props;

          return (
            <div className="playtreksModal-overlay">
              <div className="TwoFAModal">
                <img
                  src={X}
                  width={16}
                  height={16}
                  alt="close"
                  className="playtreksModal-modal-close-absolute"
                  onClick={onCloseRequest}
                />
                <div className="mt-4">
                  <div className="mb-2">From</div>
                  <UserSearch
                    isMulti={false}
                    userFilter={fromUser}
                    setUserFilter={setFromUser}
                  />
                </div>
                <div className="mt-4">
                  <div className="mb-2">To</div>
                  <UserSearch
                    isMulti={false}
                    userFilter={toUser}
                    setUserFilter={setToUser}
                  />
                </div>
                <div className="mt-4">
                  <InputComponent
                    value={values.amount}
                    commentField=".iuc1a "
                    placeholder="Amount"
                    inputGroupTextClassName="inputGroupText"
                    inputClassName="TwoFAInput"
                    inputGroupClassName="nrInputGroup nrInputGroup-title"
                    addon={false}
                    preventChangeAnimation={true}
                    inputType="number"
                    changeCallback={(value) => setFieldValue("amount", value)}
                    inputBlurCallback={() => setFieldTouched("amount", true)}
                  />
                </div>
                <div className="mt-4">
                  <InputComponent
                    value={values.description}
                    commentField=".iuc1a "
                    placeholder="Description"
                    inputGroupTextClassName="inputGroupText"
                    inputClassName="TwoFAInput"
                    inputGroupClassName="nrInputGroup nrInputGroup-title"
                    addon={false}
                    preventChangeAnimation={true}
                    inputType="textarea"
                    changeCallback={(value) =>
                      setFieldValue("description", value)
                    }
                    inputBlurCallback={() =>
                      setFieldTouched("description", true)
                    }
                  />
                </div>
                <div className="mt-2 row ml-0 justify-content-end">
                  <ButtonComponent
                    className="playtreksButton w-100 h-75"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    {isSubmitting ? (
                      <>
                        <span className="mr-2">Adding... </span>
                        <span>
                          <Spinner />
                        </span>
                      </>
                    ) : (
                      "Add"
                    )}
                  </ButtonComponent>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AdminEarningsModal;
