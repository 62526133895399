import React, { useEffect, useState } from "react";

import VectorStar from "../../../assets/vectorStar.svg";
import Group from "../../../assets/Group.png";
import Sp from "../../../assets/distrSpotify.png";
import Drawer from "@material-ui/core/Drawer";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { GlobalFilter } from "../../admin/ReleasesAdmin";

import TableView from "../../../screens/Admin/Releases/components/TableView";
import { Link } from "react-router-dom";
import TrashIcon from "../../../assets/icons/trash.svg";
import Toast from "../../Toast";
import ExpenseForm from "./ExpenseForm";
import { deleteExpense, getExpenses } from "../../../services/expenses";
import { useQuery } from "react-query";
import { labelPlans } from "../../utils";
import { useSelector } from "react-redux";
import ButtonComponent from "../../form/ButtonComponent";
import ExpenseContractForm from "./ExpenseContractForm";
import { getContract } from "../../../services/contracts";

const INITIAL_PAGE_SIZE = 10;

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const Expenses = () => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const [contractDrawerState, setContractDrawerState] = useState({
    isOpen: false,
  });

  const [toast, setToast] = useState(initialToastState);

  const [contractId, setContractId] = useState(null);

  const [editing, setEditing] = useState({
    state: false,
    expense: {},
  });

  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const isLabelPlan = labelPlans.some((plan) =>
    plan.includes(reduxCurrentPlan)
  );

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ isOpen });
    setEditing({
      state: false,
      expense: {},
    });
  };

  const toggleContractDrawer = (isOpen, contractId) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setContractId(contractId);
    setContractDrawerState({ isOpen });
  };

  useEffect(() => {
    contractId && refetch();
  }, [contractId]);

  const handleEditRow = (expense) => {
    setDrawerState({ isOpen: true });
    setEditing({
      state: true,
      expense,
    });
  };

  const handleDeleteRow = async (expenseId) => {
    if (window.confirm("Are you sure you want to delete this expense?")) {
      await deleteExpense(expenseId);
      try {
        setToast({
          type: "success",
          open: true,
          message: "Expense deleted successfully.",
        });
        refetch();
      } catch (err) {
        const toastMessage =
          err?.response?.data?.message ?? "Something went wrong";
        setToast({
          open: true,
          message: toastMessage,
          type: "danger",
        });
      }
    }
  };

  const { data: { data: contractDetails } = {}, isLoading } = useQuery(
    ["contract", contractId],
    () => getContract(contractId),
    {
      enabled: !!contractId,
    }
  );

  const {
    data: expenseData,
    isLoading: areExpensesLoading,
    refetch,
  } = useQuery("allExpenses", getExpenses);

  const expenses = React.useMemo(() => expenseData?.data ?? [], [expenseData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "Contract",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => (
          <div
            className="admin-release-details-link"
            title={value?.contract?._id}
            onClick={toggleContractDrawer(true, value?.contract?._id)}
          >
            {value?.contract?.name}
          </div>
        ),
      },

      {
        Header: "Expense Date",
        accessor: "expenseDate",
        Cell: ({ value }) => value?.split("T")[0],
      },

      {
        Header: "Total paid amount",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => {
          const paidAmount = value.expenseSplits.reduce(
            (total, current) => total + current.paidAmount,
            0
          );
          return <div>{paidAmount}</div>;
        },
      },
      {
        Header: "Actions",
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div
            className="d-inline-flex justify-content-center align-items-center"
            title=""
          >
            <div
              className="admin-release-details-link mr-2"
              onClick={() => handleEditRow(value)}
            >
              Edit
            </div>{" "}
            |{" "}
            <Link to={`/artistDashboard/expense/${value._id}`}>
              <div className="admin-release-details-link mx-2">Details</div>{" "}
            </Link>
            |{" "}
            <span
              role="button"
              onClick={() => handleDeleteRow(value._id)}
              className="ml-2 cursor-pointer"
              title="Delete expense"
            >
              <img src={TrashIcon} alt="delete expense" />
            </span>
          </div>
        ),
      },
      {
        Header: " ",
        accessor: "_id",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: expenses,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["_id"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;
  return (
    <main className="myReleases-overview">
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />
      <section className="myReleases-header">
        <div
          className={`distributionButton ${isLabelPlan ? "" : "disabled"}`}
          onClick={toggleDrawer(true)}
        >
          New Expense
        </div>
        <br />
        <br />
        {/* decoration boxes */}
        <div className="dDecoration1">
          <div className="dd1a">+</div>
        </div>
        <div className="dShader"></div>
        <div className="dDecoration2">
          <div className="dd2Text">Genre</div>
          <div className="dd2badge1">future house</div>
          <div className="dd2badge2">techno</div>
          <div className="dd2badge3">hard style</div>
          <div className="dd2badge4"></div>
        </div>
        <div className="dDecoration3">
          <img src={VectorStar} alt="star" className="dd3star" />
          <img src={Group} alt="group" className="dd3group" />
        </div>
        <div className="dDecoration4">
          <div className="dd4Inner1">
            <img src={Sp} alt="Spotify" className="dd4InnerSpotify" />
          </div>
        </div>
      </section>

      {/* GRID AND LIST VIEW */}
      {isLabelPlan ? (
        <div className="allowUserSelect">
          <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
            <div className="d-block mb-3" style={{ zIndex: 1 }}>
              <p className="font-weight-bold text-white mb-2">Filter by:</p>
              <div className="row pb-2 align-items-center justify-content-between">
                <div className="col-12 col-md">
                  <div className="row">
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </div>
                </div>
              </div>
            </div>

            <TableView
              tableInstance={tableInstance}
              loading={areExpensesLoading}
            />
          </main>
        </div>
      ) : (
        <div className=" text-center rounded upgrade-message py-5 border border-white">
          <div className="upgrade-message">
            Please upgrade your plan to Label to use this feature
          </div>

          <Link to="/artistDashboard/subscriptions">
            <ButtonComponent className="upgrade-button">
              Upgrade
            </ButtonComponent>
          </Link>
        </div>
      )}

      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 expense-drawer `}
          role="presentation"
        >
          <div
            onClick={toggleDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span>{`${editing.state ? "Edit" : "Create"} Expense`}</span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>

          <ExpenseForm
            onUpdate={setDrawerState}
            onClose={toggleDrawer(false)}
            edit={editing.state}
            expense={editing.expense}
            refetch={refetch}
          />
        </div>
      </Drawer>

      {/* contract details drawer */}
      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={contractDrawerState.isOpen}
        onClose={toggleContractDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 admin-release-drawer`}
          role="presentation"
        >
          <div
            onClick={toggleContractDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span>Contract details</span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>

          <ExpenseContractForm
            onClose={toggleContractDrawer(false)}
            contractDetails={contractDetails}
            isLoading={isLoading}
          />
        </div>
      </Drawer>
    </main>
  );
};

export default Expenses;
