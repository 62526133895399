import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { graphColorScheme } from "../../config.js";
import { PLATFORM } from "../utils.js";

import { numFormatter } from "./helpers";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function Line(props) {
  const totalTracks = props.totalTracks;
  const platform = props.platform;
  let data = props.data.map((innerHit) => {
    const trackInfo =
      platform === PLATFORM.SPOTIFY ? innerHit._source.track : innerHit._source;
    const historyData = trackInfo.history.map((el) => ({
      x: el.timestamp.slice(0, 10),
      y: el.position,
    }));

    return {
      id:
        platform === PLATFORM.SPOTIFY
          ? trackInfo.name
          : trackInfo.attributes.albumName,
      data: historyData,
    };
  });

  function ticksDisplay() {
    // const precision = totalTracks < 150 ? 5 : 10;
    return getTicks(6, 5);
  }

  function getTicks(nr_ticks, closest_to) {
    let interval_length =
      Math.round(totalTracks / (nr_ticks - 1) / closest_to) * closest_to;
    let ticks_values = Array.from(Array(nr_ticks).keys()).map(
      (el) => el * interval_length
    );
    ticks_values[0] = 1;
    ticks_values[ticks_values.length - 1] = totalTracks;
    return ticks_values;
  }

  return (
    <span>
      {
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 40, bottom: 50, left: 40 }}
          xScale={{
            format: "%Y-%m-%d",
            type: "time",
            useUTC: false,
            precision: "day",
          }}
          xFormat="time:%b %d %Y"
          yScale={{
            type: "linear",
            min: "1",
            max: totalTracks,
            stacked: false,
            reverse: true,
            // tickValues: 5,
          }}
          curve="catmullRom"
          axisBottom={null}
          axisRight={null}
          axisTop={{
            orient: "top",
            tickSize: 5,
            tickPadding: 0,
            tickRotation: 0,
            format: "%b %d",
            tickValues: 5,
            tickColor: "black",
          }}
          axisLeft={{
            orient: "left",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: ticksDisplay(),
            format: (v) => {
              return v === 0 ? 1 : numFormatter(v);
            },
          }}
          enableGridX={false}
          enableGridY={false}
          colors={{ scheme: graphColorScheme }}
          lineWidth={3}
          pointSize={2}
          pointColor={{ theme: "background" }}
          pointBorderWidth={3}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          enableSlices="y"
          enableCrosshair={false}
          useMesh={true}
          sliceTooltip={({ slice }) => {
            return (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <div
                  key={slice.points[0].id}
                  style={{
                    fontSize: "0.5em",
                  }}
                  className="row"
                >
                  <span
                    className="col-1"
                    style={{
                      fontSize: "2em",
                      color: slice.points[0].serieColor,
                    }}
                  >
                    <strong className="position-relative">
                      {slice.points[0].data.yFormatted}
                    </strong>
                  </span>
                  <span className="col">
                    {slice.points[0].serieId} <br />
                    {slice.points[0].data.xFormatted}
                  </span>
                </div>
              </div>
            );
          }}
          theme={{
            axis: {
              fontSize: "14px",
              tickColor: "#eee",
              ticks: {
                line: {
                  stroke: "gray",
                },
                text: {
                  fill: "white",
                },
              },
              legend: {
                text: {
                  fill: "black",
                },
              },
              domain: {
                line: {
                  stroke: "gray",
                  strokeWidth: 1,
                },
              },
            },
            tooltip: {
              container: { fontSize: "0.5em" },
            },
          }}
        />
      }
    </span>
  );
}

export default Line;
