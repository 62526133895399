import React from "react";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// export const MyDataMenu = [
//   {
//     label: "overview",
//     url: "/artistDashboard",
//   },
//   {
//     label: "streaming stats",
//     url: "/artistDashboard/streamingStats",
//   },
//   {
//     label: "social stats",
//     url: "/artistDashboard/socialStats",
//   },
//   {
//     label: "playlists",
//     url: "/artistDashboard/playlists",
//   },
//   {
//     label: "charts",
//     url: "/artistDashboard/entityCharts",
//   },
//   {
//     label: "airplay (beta)",
//     url: "/artistDashboard/airplay",
//   },
// ];

// export const Charts = [
//   {
//     label: "charts",
//     url: "/artistDashboard/charts",
//   },
// ];

export const DistributionMenu = [
  {
    label: "releases",
    url: "/artistDashboard/myReleases",
  },
  {
    label: "revenue",
    url: "/artistDashboard/revenue",
  },
  {
    label: "my earnings",
    url: "/artistDashboard/myEarnings",
  },
];

export const AccountingMenu = [
  {
    label: "people",
    url: "/artistDashboard/people",
  },
  {
    label: "contracts",
    url: "/artistDashboard/contracts",
  },
  {
    label: "expenses",
    url: "/artistDashboard/expenses",
  },
  {
    label: "transactions",
    url: "/artistDashboard/transactions",
  },
  // {
  //   label: "my earnings",
  //   url: "/artistDashboard/myEarnings",
  // },
];

export const SubscriptionMenu = [
  {
    label: "my plan",
    url: "/artistDashboard/subscriptions",
  },
  {
    label: "my artists",
    url: "/artistDashboard/myArtists",
  },
];

export const Support = [
  {
    label: (
      <div className="row col justify-content-between">
        help center
        <span>
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            style={{ fill: "#8e8f92" }}
            className="ml-2 "
          />
        </span>
      </div>
    ),
    url: "https://support.playtreks.com/",
  },
];
export const UserMenu = [
  {
    label: "account settings",
    url: "/artistDashboard/profile",
  },
  {
    label: "subscription settings",
    url: "/artistDashboard/subscriptions",
  },
  {
    label: "wallet details",
    url: "https://marketplace.playtreks.com/wallet",
  },
  {
    label: "logout",
    url: `${global.config.AUTH_REDIRECT_URL}/logout`,
  },
];
