const axios = require("axios");
const config = require("../config");

// Number of Seconds to sleep
let sleep = (s) => new Promise((resolve) => setTimeout(resolve, s * 1000));

axios.interceptors.request.use((req) => {
  if (!req?.headers?.["User-Agent"])
    req.headers["User-Agent"] =
      "playtreksBot (Music data application) info@playtreks.com";

  return req;
}, null);

axios.interceptors.response.use(null, async (error) => {
  if (
    !error.config ||
    ("_numRetries" in error.config &&
      error.config._numRetries >= config.axiosMaxRetries) ||
    !(
      error.message === "Network Error" || // retry on network error
      // intercept only 429 and 5xx codes
      (error.response &&
        (error.response.status === 429 ||
          Math.floor(error.response.status / 100) === 5))
    )
  )
    return Promise.reject(error);

  if (error?.response?.headers?.["retry-after"])
    await sleep(error.response.headers["retry-after"]);
  else {
    if (!("_numRetries" in error.config && "_sleepTime" in error.config)) {
      // First time this request is failing
      error.config._sleepTime = 1;
      error.config._numRetries = 1;
    } else {
      error.config._sleepTime *= 2;
      error.config._numRetries++;
    }

    await sleep(error.config._sleepTime);
  }

  return axios.request(error.config);
});

// module.exports = axios;
export default axios;
