import React, { useState, useEffect } from "react";

import paypal from "../assets/paypal.svg";

// REDU
import { useSelector } from "react-redux";

import { performPayment } from "../../../routes/payments";

import spinner from "../../../assets/spinner.svg";
import { getJWT } from "../../utils";

const PAYPAL = "paypal";

const INITIAL = "initial";
const IN_PROGRESS = "in_progress";
const ERROR = "error";
const ERROR_403 = "error_403";

function PerformPaypalPaymentComponent({
  productId,
  redirectTo,
  className,
  textClassName,
  logo,
  text,
  paymentModalState,
}) {
  // get jwt for auth
  const jwtToken = getJWT();

  // used artist slots and requested amount (for downgrade error handling)
  const usedArtistSlots = useSelector(
    (state) => state.distributionReducer?.usedArtistSlots
  );

  const [status, setStatus] = useState(INITIAL);

  // call to get payment method, check if connected
  function handlePay() {
    if (status !== IN_PROGRESS) {
      setStatus(IN_PROGRESS);
      performPayment(productId, null, jwtToken, PAYPAL)
        .then(async (r) => {
          window.location.replace(r.data.checkoutUrl);
        })
        .catch((e) => {
          if (e.response.status === 403) {
            setStatus(ERROR_403);
          } else {
            setStatus(ERROR);
          }
        });
    }
  }

  // reset state on modal close
  useEffect(() => {
    if (!paymentModalState?.open) {
      setStatus(INITIAL);
    }
  }, [paymentModalState]);

  return (
    <div className="setPaymentMethod-button-wrapper">
      <span>
        <div className={className} onClick={handlePay}>
          {[INITIAL, ERROR, ERROR_403].includes(status) ? (
            <span>
              <div className={textClassName}>{text || "PayPal"}</div>
              <div
                className={logo ? "setPaymentMethod-button-logoBox" : "d-none"}
              >
                <img src={paypal} alt="paypal" />
              </div>
            </span>
          ) : (
            ""
          )}
          {status === IN_PROGRESS ? (
            <span style={{ height: "100%" }} className="xyFlexCenter">
              <img src={spinner} alt="loading" style={{ height: "80%" }} />
            </span>
          ) : (
            ""
          )}
        </div>
        {status === ERROR ? (
          <small className="text-danger">Error, please try again</small>
        ) : status === ERROR_403 ? (
          <small
            className="text-danger position-relative"
            style={{ top: "10px" }}
          >
            You are currently using {usedArtistSlots.length} artist slots within
            PlayTreks. Therefore, you cannot downgrade to this plan. Please
            choose a plan with at least {usedArtistSlots.length} artist slots.
          </small>
        ) : (
          ""
        )}
      </span>
    </div>
  );
}

export default PerformPaypalPaymentComponent;
