import React, { useState, useEffect } from "react";
import "./onboarding.scss";
import "../../assets/scss/pt/forms.scss";

import ButtonComponent from "../form/ButtonComponent";

import Footer from "./components/FooterComponent";

import { setUserFacebookArtistPage } from "../../routes/users";

import ObDecorations from "./components/ObDecorations";
import ObProgressCircles from "./components/ObProgressCircles";

import FbPagePicker from "./components/FbPagePicker";
import Stepper from "../Stepper";
import { getJWT } from "../utils";

function Onboarding3() {
  const jwtToken = getJWT();

  const [pickedPageId, setPickedPageId] = useState();

  function pickedPageIdCallback(pageId) {
    setPickedPageId(pageId);
  }

  function handleSubmit() {
    if (pickedPageId) {
      setUserFacebookArtistPage(pickedPageId, jwtToken, { isInstagram: false })
        .then((r) => {
          window.location.assign("/welcomePage");
          console.log(r);
        })
        .catch((e) => console.log(e));
    }
  }

  const steps = [
    {
      name: "",
      callback: () => window.location.replace(`/onboarding1`),
    },
    {
      name: "",
      callback: () => window.location.replace(`/onboarding2`),
    },
    {
      name: "",
      callback: () => window.location.replace(`/onboarding4`),
    },
    {
      name: "",
      callback: () => {},
    },
  ];

  const color = "#15161c";
  const activeColor = "#0ef1db";

  return (
    <>
      <ObDecorations />
      <div className="obCenterBox obCenterBox-2 obBox">
        <div className="obModal obModal-3">
          <Stepper
            steps={steps}
            currentStep={3}
            containerClasses={"oboarding-container"}
            color={color}
            activeColor={activeColor}
            lineColor={color}
            finishLineColor={activeColor}
          />
          <br />
          <div className="obModal-Header obModal-Header-3 noWrap">
            select facebook page
          </div>
          <div className="obModal-sub mobile480invisible mt-2">
            Page that is related to your artist profile
          </div>
          {/* if 3 items no carousel */}
          <br />
          <FbPagePicker pickedPageIdCallback={pickedPageIdCallback} />
          <div
            className="obSkip obSkip-2"
            onClick={() => window.location.assign("/welcomePage")}
          >
            Skip and finish
          </div>
          <ButtonComponent
            className="obNextButton obNextButton-2"
            onClick={handleSubmit}
          >
            Finish
          </ButtonComponent>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Onboarding3;
