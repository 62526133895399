import React from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

// assets
import Spinner from "../../../assets/spinner.svg";
import { isIframe, setPath } from "../../utils";

import "./index.scss";

function TrackList({
  handleTrackHover = () => {},
  trackFeatures,
  linksPathname,
  scrollableMode, // currently disabled on desktop in css
}) {
  if (trackFeatures?.length > 0) {
    return (
      <div
        className={
          "track-list " +
          (scrollableMode && "track-list-mobileScrollable ") +
          (isIframe() && "track-list-alwaysScrollable")
        }
      >
        <div className="track-list-title">Tracks</div>
        {trackFeatures?.map((track, i) => (
          <div
            className="track-list-item"
            id={"track-list-item" + i}
            onMouseOver={() => handleTrackHover(i)}
          >
            <div className="m-2">{i + 1}. </div>
            <Link
              to={setPath(track.id, track.name, "track", linksPathname)}
              className="track-list-item-name textLink"
              target={isIframe() ? "_blank" : "_self"}
            >
              {track?.name}
            </Link>
            <span className="track-list-item-right">
              {track?.explicit && (
                <span className="track-list-item-right-explicit">
                  explicit &nbsp;
                </span>
              )}
              <span className="track-list-item-right-length">
                {track?.duration}
              </span>
            </span>
          </div>
        ))}
        {scrollableMode && <br />}
      </div>
    );
  } else {
    return (
      <div className="track-list">
        <div className="track-list-spinner-wrapper">
          <img alt="spinner" src={Spinner} className="track-list-spinner" />
        </div>
      </div>
    );
  }
}

export default TrackList;
