import React, { useState } from "react";
import ButtonComponent from "../../../../form/ButtonComponent";
import X from "../../../../../components/distribution/assets/X.svg";
import { Spinner } from "../../../../../screens/Airplay/Airplay";
import { regenerateBackupCodes } from "../../../../../services/auth";
import ViewBackupCodesModal from "./ViewBackupCodesModal";

const RegenerateBackupCodesModal = ({
  setToast,
  refetchCodes,
  onCloseRequest,
  remainingCodes,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [backupCodes, setBackupCodes] = useState({
    codes: [],
    createdAt: null,
  });

  const handleRegenerateCodes = async () => {
    setIsSubmitting(true);
    try {
      const response = await regenerateBackupCodes();

      if (response.data.success) {
        setBackupCodes(response.data.backup);
        setPage(1);
        setTimeout(refetchCodes, 1000);

        setToast({
          type: "success",
          open: true,
          message: "New codes have generated successfully",
        });
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setToast({
        type: "danger",
        open: true,
        message:
          error?.response?.data?.message ||
          "An unexpected error occured. Please try again",
      });
      onCloseRequest(false);
    }
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal ">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />
        {page === 0 && (
          <div className="mt-3">
            <div className="mb-3">{`${
              remainingCodes > 0 ? "Regenerate" : "Generate"
            } backup codes?`}</div>

            <span className="text-muted">
              {remainingCodes > 0
                ? "If you regenerate backup codes, any old ones you have left will be unusable"
                : "These codes help you to finish two factor authentication when you lose access to your device."}
            </span>

            <div className="row justify-content-end ml-0 mt-4">
              <ButtonComponent
                onClick={onCloseRequest}
                className=" playtreksButton w-100 h-100 bg-transparent textLink mr-2"
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                className=" playtreksButton w-100 h-100"
                onClick={handleRegenerateCodes}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    Generating...
                    <span className="ml-2">
                      <Spinner />
                    </span>
                  </>
                ) : remainingCodes > 0 ? (
                  "Regenerate"
                ) : (
                  "Generate"
                )}
              </ButtonComponent>
            </div>
          </div>
        )}

        {page === 1 && (
          <div className="mt-3">
            <ViewBackupCodesModal backupCodes={backupCodes} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RegenerateBackupCodesModal;
