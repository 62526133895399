import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import StatsCard from "./StatsCard";
import StatsCardIcon from "./StatsCardIcon";
import Bar from "../graphs/Bar";
import HorizontalScrollButtonGroup from "./HorizontalScrollButtonGroup";
import Item from "../content/Item";
import FeedBack from "./FeedBack";
import SuggestedArtists from "../welcomepage/components/SuggestedArtists";
import StatisticsChart from "../HighCharts/StatisticsChart";

import ButtonComponent from "../form/ButtonComponent";
import Spinner from "../../assets/spinner.svg";

import TrackFeatures from "./TrackFeatures";
import TrackFeaturesCardsBox from "./TrackFeatures/TrackFeaturesCardsBox";
import Popularity from "./Popularity";

import StatsUpIcon from "../../assets/icons/icon-stats-up.svg";
import StatsDownIcon from "../../assets/icons/icon-stats-down.svg";
import IgColorIcon from "../../assets/icons/ig-color.svg";
import TwColorIcon from "../../assets/icons/tw-color.svg";
import YtColorIcon from "../../assets/icons/yt_icon_rgb.png";
import FbColorIcon from "../../assets/icons/fb-color.svg";
import SpotifyColorIcon from "../../assets/icons/spotify-color.svg";
import AppleColorIcon from "../../assets/icons/apple-color.svg";
import { numberWithCommas, PLATFORM } from "../utils";
import DashboardHeader from "./DashboardHeader";
import SocialStatsCardsBox from "./SocialStats/SocialStatsCardsBox";
import RadioMonitoringTable from "./RadioMonitoringTable";
import StreamingStatsCardBox from "./StreamingStats/StreamingStatsCardsBox";
import AddSpotifyArtistModal from "./Profile/components/AddSpotifyArtistModal";
import {
  reduxSetUserData,
  REDUX_SET_RADIO_AGGREGATION_DATA,
} from "../../redux/actions/dashboardActions";
import AddFbPageModal from "./Profile/components/AddFbPageModal";
import AddIgPageModal from "./Profile/components/AddInstagramPageModal";
import PlaylistsComponent from "./Playlists/PlaylistsComponent";
import TrackList from "./TrackList/TrackList";
import AlbumFeatures from "./AlbumFeatures";
import MetadataSection from "./MetadataSection";
import AlbumFeaturesCardsBox from "./AlbumFeatures/AlbumFeaturesCardsBox";
import MobileTrackFeaturesCardsBox from "./TrackFeatures/MobileTrackFeaturesCardsBox";
import AirplayTopPlacesAgregationChart from "./../../screens/Airplay/components/AirplayAgregationChart";
import { getAirplayAggregations } from "./../../services/airplay";

const HORIZONTAL_SCROLL_SIZE = 200;

const SPOTIFY = "spotify";
const INSTAGRAM = "instagram";
const FACEBOOK = "facebook";
const TWITTER = "twitter";

const USER = "user";

function useChartDimention(entityStats) {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      const getHeight = (selector) =>
        document.querySelector(selector)
          ? Number(
              getComputedStyle(
                document.querySelector(selector),
                null
              ).height.replace(/px/, "")
            )
          : 0;
      const height = getHeight(".dashboard-overview-totalfans-chart");
      let width = (window.innerWidth - (227 + 48 + 48)) / 2;
      width -= 40;
      setSize([width, height]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [entityStats]);
  return size;
}

function Dashboard() {
  const [aggregationsLoading, setAggregationsLoading] = React.useState(false);
  // Total fans cards
  const [playlistsChipLists, setPlaylistsChipLists] = useState([]);

  const [selectedPlaylist, setSelectedPlaylist] = useState(0);
  const [selectedApplePlaylist, setSelectedApplePlaylist] = useState(0);

  const [streamingStatsChartData, setStreamingStatsChartData] = useState([]);

  // modals
  const [spotifyArtistModal, setSpotifyArtistModal] = useState(false);
  const [facebookArtistModal, setFacebookArtistModal] = useState(false);
  const [instagramArtistModal, setInstagramArtistModal] = useState(false);

  // redux
  const reduxMainEntity = useSelector(
    (state) => state.dashboardReducer.mainEntity
  );
  const entityStats = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.deltaStats?.stats
  );
  const entityStatsDelta = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.deltaStats?.diffStats
  );
  const reduxSuggestedArtists = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.suggestedArtists
  );
  const spotifyPlaylists = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.spotifyPlaylists
  );
  const playlistsLoading = useSelector(
    (state) => state.dashboardReducer?.playlistsLoading
  );
  const applePlaylists = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.applePlaylists
  );
  const reduxUserData = useSelector(
    (state) => state.dashboardReducer?.userData
  );
  const featureChartData = useSelector(
    (state) => state.dashboardReducer?.mainEntity?.trackFeatures
  );
  const featureAggregationsChartDataAvg = useSelector(
    (state) =>
      state.dashboardReducer?.mainEntity?.trackFeaturesAggregations?.average
  );

  const [chartWidth, chartHeight] = useChartDimention(entityStats);

  const trackHover = useSelector((state) => state.dashboardReducer?.trackHover);

  const isMainEntityUser = reduxMainEntity?.type === USER;
  const spotifyPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedSpotifyID?.length > 0
    : true;
  const facebookConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.facebook?.email?.length > 0
    : true;
  const facebookPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedFacebookArtistPageID?.length > 0
    : true;
  const twitterConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.twitter?.screen_name?.length > 0
    : true;
  const instagramPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedInstagramPageID?.length > 0
    : true;
  const isSubscribedToAirplay = useSelector(
    (state) =>
      state.subscriptionReducer?.subscription?.rules?.airplayData?.enabled
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(reduxMainEntity?.radioAggregationData || {}).length) {
      fetchAirplayAggregations();
    }
  }, [
    reduxMainEntity?.type,
    reduxMainEntity?.service_profiles?.selectedSpotifyID,
    reduxMainEntity?.id,
  ]);

  const fetchAirplayAggregations = async () => {
    const userSpotifyId =
      reduxMainEntity?.service_profiles?.selectedSpotifyID ||
      reduxMainEntity?.id;
    const type = reduxMainEntity?.type;

    if (userSpotifyId && type) {
      setAggregationsLoading(true);

      let queryParams = {
        size: 100,
        spotifyId: userSpotifyId,
        type,
      };

      try {
        const response = await getAirplayAggregations(queryParams);

        if (response?.data) {
          dispatch({
            type: REDUX_SET_RADIO_AGGREGATION_DATA,
            id: userSpotifyId,
            radioAggregationData: response.data,
            target: type === "user" ? "user" : "history",
          });
          // setTopPlacesDataByStreamCount(response.data.topPlaces);
        }
      } catch (e) {
        setAggregationsLoading(false);
      }
    }
    setAggregationsLoading(false);
  };

  function handleSocialClick() {}

  function handleSpotifyPlaylistScroll(direction) {
    direction === "left"
      ? setSelectedPlaylist(selectedPlaylist === 0 ? 0 : selectedPlaylist - 1)
      : setSelectedPlaylist(
          selectedPlaylist === spotifyPlaylists.length - 1
            ? spotifyPlaylists.length - 1
            : selectedPlaylist + 1
        );
  }
  function handleApplePlaylistScroll(direction) {
    direction === "left"
      ? setSelectedApplePlaylist(
          selectedApplePlaylist === 0 ? 0 : selectedApplePlaylist - 1
        )
      : setSelectedApplePlaylist(
          selectedApplePlaylist === applePlaylists.length - 1
            ? applePlaylists.length - 1
            : selectedApplePlaylist + 1
        );
  }

  function handlePlusIconClick(platformName) {
    switch (platformName) {
      case SPOTIFY: {
        setSpotifyArtistModal(true);
        break;
      }
      case FACEBOOK: {
        setFacebookArtistModal(true);
        break;
      }
      case INSTAGRAM: {
        setInstagramArtistModal(true);
        break;
      }
      case TWITTER: {
        window.location.assign("/artistDashboard/profile");
        break;
      }
    }
  }

  function closeModal() {
    setSpotifyArtistModal(false);
    setFacebookArtistModal(false);
    setInstagramArtistModal(false);
    dispatch(reduxSetUserData());
  }

  const sumValues = (obj) =>
    obj ? Object.values(obj).reduce((a, b) => parseInt(a) + parseInt(b), 0) : 0;

  return (
    <main className="dashboard-overview">
      <AddSpotifyArtistModal
        closeModalCallback={closeModal}
        className={!spotifyArtistModal ? "d-none" : ""}
      />
      <AddFbPageModal
        closeModalCallback={closeModal}
        className={!facebookArtistModal ? "d-none" : ""}
        connectPlatformUrl={"/artistDashboard/profile"}
      />
      <AddIgPageModal
        closeModalCallback={closeModal}
        className={!instagramArtistModal ? "d-none" : ""}
        connectPlatformUrl={"/artistDashboard/profile"}
      />

      {["track", "album", "artist", "user"].includes(reduxMainEntity?.type) && (
        <section className="dashboard-overview-metadata">
          <MetadataSection />
        </section>
      )}

      {["artist", "user"].includes(reduxMainEntity?.type) && (
        <section className="dashboard-overview-totalfans">
          {entityStats && entityStatsDelta ? (
            <>
              <div className="dashboard-overview-totalfans-left">
                <div className="dashboard-overview-totalfans-left-stats">
                  <span>
                    <label>total fans</label>
                    <span
                      className="your-earnings-header-emoji"
                      role="img"
                      aria-label={"party popper"}
                    >
                      {"🎉"}
                    </span>
                  </span>
                  <span className="dashboard-overview-totalfans-left-stats-follower">
                    <span className="dashboard-overview-totalfans-left-stats-follower-count">
                      {numberWithCommas(sumValues(entityStats?.totalFans))}
                    </span>
                    <span className="dashboard-overview-totalfans-left-stats-follower-time">
                      <img
                        src={
                          sumValues(entityStatsDelta?.totalFans) > 0
                            ? StatsUpIcon
                            : StatsDownIcon
                        }
                        alt="stats"
                      />
                      <label
                        className={
                          sumValues(entityStatsDelta?.totalFans) > 0
                            ? "social-up"
                            : "social-down"
                        }
                      >
                        {numberWithCommas(
                          sumValues(entityStatsDelta?.totalFans)
                        ) + " last week"}
                      </label>
                    </span>
                  </span>
                </div>
                <div className="dashboard-overview-totalfans-left-cards">
                  <StatsCard
                    name="instagram"
                    platform="instagram"
                    objectPath={entityStats?.instagram?.followers_count}
                    objectPathDelta={
                      entityStatsDelta?.instagram?.followers_count
                    }
                    connected={facebookConnected}
                    pageConnected={instagramPageConnected}
                    handlePlusIconClick={handlePlusIconClick}
                  >
                    <StatsCardIcon
                      name="instagram"
                      icon={IgColorIcon}
                      handleClick={handleSocialClick()}
                    />
                  </StatsCard>
                  <StatsCard
                    name="twitter"
                    platform="twitter"
                    objectPath={entityStats?.twitter?.followers_count}
                    objectPathDelta={entityStatsDelta?.twitter?.followers_count}
                    connected={twitterConnected}
                    handlePlusIconClick={handlePlusIconClick}
                  >
                    <StatsCardIcon
                      name="twitter"
                      icon={TwColorIcon}
                      handleClick={handleSocialClick()}
                    />
                  </StatsCard>
                  <StatsCard
                    name="YouTube"
                    platform="youtube"
                    objectPath={entityStats?.youtube?.subscriberCount}
                    objectPathDelta={entityStatsDelta?.youtube?.subscriberCount}
                    // comingSoon={true}
                  >
                    <StatsCardIcon
                      name="youtube"
                      icon={YtColorIcon}
                      handleClick={handleSocialClick()}
                    />
                  </StatsCard>
                  <StatsCard
                    name="facebook"
                    platform="facebook"
                    objectPath={entityStats?.facebook?.fan_count}
                    objectPathDelta={entityStatsDelta?.facebook?.fan_count}
                    connected={facebookConnected}
                    pageConnected={facebookPageConnected}
                    handlePlusIconClick={handlePlusIconClick}
                  >
                    <StatsCardIcon
                      name="facebook"
                      icon={FbColorIcon}
                      handleClick={handleSocialClick}
                    />
                  </StatsCard>

                  <StatsCard
                    name="spotify"
                    platform="spotify"
                    objectPath={entityStats?.followers}
                    objectPathDelta={entityStatsDelta?.followers}
                    connected={true} // spotify data does not depend on connected platform, hence always true
                    pageConnected={spotifyPageConnected}
                    handlePlusIconClick={handlePlusIconClick}
                  >
                    <StatsCardIcon
                      name="spotify"
                      icon={SpotifyColorIcon}
                      handleClick={handleSocialClick}
                    />
                  </StatsCard>
                </div>
              </div>
              <div className="dashboard-overview-totalfans-chart">
                <div
                  className="dashboard-overview-totalfans-chart-wrapper"
                  // style={{ height: `${chartHeight}px` }}
                >
                  <Bar
                    data={[
                      {
                        key: "followers",
                        instagram: entityStats?.instagram?.followers_count,
                        instagramColor: "hsl(309, 64%, 53%)",
                        twitter: entityStats?.twitter?.followers_count,
                        twitterColor: "hsl(191, 83%, 62%)",
                        youtube: entityStats?.youtube?.subscriberCount,
                        youtubeColor: "hsl(2, 82%, 54%)",
                        facebook: entityStats?.facebook?.fan_count,
                        facebookColor: "hsl(234, 100%, 67%)",
                        spotify: entityStats?.followers,
                        spotifyColor: "hsl(141, 73%, 42%)",
                      },
                    ]}
                    keys={[
                      "instagram",
                      "twitter",
                      "youtube",
                      "facebook",
                      "spotify",
                    ]}
                    indexBy="key"
                    width={chartWidth}
                    height={chartHeight}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="dashboard-overview-totalfans-left">
              <div className="dashboard-overview-totalfans-left-stats">
                <span>
                  <label>total fans</label>
                  <span
                    className="your-earnings-header-emoji"
                    role="img"
                    aria-label={"party popper"}
                  >
                    {"🎉"}
                  </span>
                </span>
              </div>
              <div className="dashboard-overview-totalfans-spinner-wrapper">
                <img
                  alt="loading..."
                  src={Spinner}
                  className="dashboard-overview-totalfans-spinner"
                ></img>
              </div>
            </div>
          )}
        </section>
      )}

      {reduxMainEntity?.type === "track" && (
        <section className="dashboard-overview-streaming-stats">
          <TrackFeaturesCardsBox featureData={featureChartData} />
        </section>
      )}

      {/* for mobile */}
      {reduxMainEntity?.type === "track" && (
        <section className="album-features-wrapper-mobile">
          <MobileTrackFeaturesCardsBox entityData={reduxMainEntity} />
        </section>
      )}

      {reduxMainEntity?.type === "track" && (
        <section className="dashboard-overview-track-features">
          <span className="dashboard-overview-streaming-stats-header-left">
            <span
              className="track-features-left-content-header-emoji"
              role="img"
              aria-label={"track features"}
            >
              {"⚗️"}
            </span>
            <label>&nbsp; Track Features</label>
          </span>
          <TrackFeatures featureData={featureChartData} />
        </section>
      )}

      {reduxMainEntity?.type === "album" && (
        <section className="dashboard-overview-streaming-stats">
          <AlbumFeaturesCardsBox featureData={featureChartData} />
        </section>
      )}

      {/* for mobile */}
      {reduxMainEntity?.type === "album" && (
        <section className="album-features-wrapper-mobile">
          <MobileTrackFeaturesCardsBox entityData={reduxMainEntity} />
        </section>
      )}

      {reduxMainEntity?.type === "album" && (
        <section className="album-features-wrapper">
          <AlbumFeatures mainEntity={reduxMainEntity} />
        </section>
      )}

      {["track", "album"].includes(reduxMainEntity?.type) && (
        <section className="dashboard-overview-popularity">
          <Popularity />
        </section>
      )}

      {["artist", "user"].includes(reduxMainEntity?.type) && (
        <section className="dashboard-overview-streaming-stats">
          <StreamingStatsCardBox
            entityStats={entityStats}
            entityStatsDelta={entityStatsDelta}
            handlePlusIconClick={handlePlusIconClick}
          />
        </section>
      )}

      {["artist", "user"].includes(reduxMainEntity?.type) && (
        <section className="dashboard-overview-social-stats">
          <SocialStatsCardsBox
            entityStats={entityStats}
            entityStatsDelta={entityStatsDelta}
            handlePlusIconClick={handlePlusIconClick}
          />
        </section>
      )}

      <section className="dashboard-overview-your-top-playlists">
        {/* <PlaylistsComponent
          setSpotifyArtistModal={setSpotifyArtistModal}
          playlistsPerPage={5}
          overrideOffset={0}
          text="top spotify playlists"
          overridePlatform="spotify"
          noPagination
        /> */}
        <div className="dashboard-overview-your-top-playlists-more-details">
          <Link to="/artistDashboard/playlists">
            <ButtonComponent className="dashboard-overview-your-top-playlists-more-details-button">
              See all playlists
            </ButtonComponent>
          </Link>
        </div>
      </section>

      <section className="dashboard-overview-your-top-playlists">
        {/* <PlaylistsComponent
          setSpotifyArtistModal={setSpotifyArtistModal}
          playlistsPerPage={5}
          overrideOffset={0}
          text="top apple playlists"
          overridePlatform="apple"
          noPagination
        /> */}
        <div className="dashboard-overview-your-top-playlists-more-details">
          <Link to="/artistDashboard/playlists">
            <ButtonComponent className="dashboard-overview-your-top-playlists-more-details-button">
              See all playlists
            </ButtonComponent>
          </Link>
        </div>
      </section>

      {/* <section className="dashboard-overview-followed-artists">
        <div className="dashboard-overview-followed-artists-header">
          <span className="dashboard-overview-followed-artists-header-left">
            <label>followed artists</label>
            <span
              className="dashboard-overview-followed-artists-header-emoji"
              role="img"
              aria-label={"rock on!"}
            >
              {"🤘"}
            </span>
          </span>
        </div>
        <div className="dashboard-overview-followed-artists-content">
          <span className="dashboard-overview-followed-artists-content-title">
            <label>nothing here yet</label>
            <span
              className="dashboard-overview-followed-artists-content-title-emoji"
              role="img"
              aria-label={"crying face"}
            >
              {"😢"}
            </span>
          </span>
          <InputComponent
            inputGroupClassName="dashboard-overview-followed-artists-content-search"
            inputClassName="dspInput"
            groupAddonClassName="dspInputAddon"
            inputGroupTextClassName="dspInputText"
            placeholder="search other artists"
            img={SearchIcon}
            imgClass="dspSearchIcon inputIcon"
            changeCallback={searchCallback}
            onBlur={inputBlurCallback}
          />
        </div>
      </section> */}

      <section>
        <div className="dashboard-airplay">
          <div className="text-white">
            <label>Top 25 places by streams</label>
          </div>
          {["user", "artist", "track"].includes(reduxMainEntity?.type) ? (
            isSubscribedToAirplay ? (
              ["artist", "track"].includes(reduxMainEntity?.type) ||
              (reduxMainEntity?.type === "user" &&
                reduxMainEntity?.service_profiles?.selectedSpotifyID) ? (
                <>
                  <AirplayTopPlacesAgregationChart
                    data={reduxMainEntity?.radioAggregationData || {}}
                    loading={aggregationsLoading}
                  />
                  <div className="mt-1 dashboard-overview-your-top-playlists-more-details">
                    <Link to="/artistDashboard/airplay">
                      <ButtonComponent className="dashboard-overview-your-top-playlists-more-details-button">
                        see more details
                      </ButtonComponent>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <AddSpotifyArtistModal
                    closeModalCallback={closeModal}
                    className={!spotifyArtistModal ? "d-none" : ""}
                  />
                  <div className="dashboard-playlists-top-empty">
                    <div>
                      Spotify artist page not connected
                      <br />
                      <div
                        className="social-card-not-connected"
                        onClick={() => setSpotifyArtistModal(true)}
                      >
                        Pick the artist page
                      </div>
                    </div>
                  </div>
                </>
              )
            ) : (
              isSubscribedToAirplay !== undefined && (
                <div className="dashboard-playlists-top-empty">
                  This is a premium feature.
                  <Link to="/artistDashboard/subscriptions">
                    <div className="social-card-not-connected">
                      Please subscribe to view
                    </div>
                  </Link>
                </div>
              )
            )
          ) : (
            reduxMainEntity?.type && (
              <div className="row justify-content-center align-items-center py-5">
                <p className="text-white">
                  Available only for User, Artist and Track
                </p>
              </div>
            )
          )}
        </div>
      </section>

      <section className="dashboard-overview-suggested-artists">
        <SuggestedArtists suggestedArtists={reduxSuggestedArtists} />
      </section>

      <br />
      <br />

      {/* <section className="dashboard-overview-feedback">
				<FeedBack />
			</section> */}
    </main>
  );
}
export default Dashboard;
