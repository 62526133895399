// data
import {
  administrativeOptions,
  instrumentalOptions,
  engineeringOptions,
  otherPerformersOptions,
  writersOptions,
  vocalOptions,
  secondaryPerformersOptions
} from "../data/distrFieldsData";

// prepare data for transporting to the backend
export function prepareFormForBackend(form, fieldCase) {
  return cleanEmptyFormEntries(flattenParticipants(form), fieldCase); // flatten participants array and clean empty entries
}

// merging the separate "primary" and "contributors" arrays into one "#participants" array for proper backend data structure
export function flattenParticipants(form) {
  let newForm = form;
  let newParticipants = newForm["#participants"]?.primary?.concat(
    newForm["#participants"].contributors
  );
  newForm["#participants"] = newParticipants;
  return newForm;
}

// a version of the above function that copies the object
export function flattenParticipantsCopy(form) {
  let newForm = { ...form };
  let newParticipants = newForm["#participants"]?.primary?.concat(
    newForm["#participants"].contributors
  );
  newForm["#participants"] = newParticipants;
  return newForm;
}

// function cleans empty entries for participants / contributors - they have to stay there before submission because the UI depends on them
function cleanEmptyFormEntries(form, fieldCase) {
  let newPart;
  if (fieldCase === "release") {
    newPart = form["#participants"].filter((item) => {
      if (Object.keys(item)[0] === "primary" && item?.primary.length > 0) {
        return item;
      } else if (
        Object.keys(item)[0] !== "primary" &&
        item?.type?.length > 0 &&
        item?.value?.length > 0
      ) {
        return item;
      }
    });

    if (form?.tracks) {
      form = {
        ...form,
        tracks: form.tracks.map((track) => {
          return {
            ...track,
            form: { ...track.form, "#primary_genre": form["#primary_genre"] },
          };
        }),
      };
    }
  } else if (fieldCase === "tracks") {
    newPart = form["#participants"]?.filter((item) => {
      if (item?.type?.length > 0 && item?.name?.length > 0) {
        return item;
      }
    });
  }
  form["#participants"] = newPart;
  return form;
}

export function convertBackToFrontEnd(form, fieldCase) {
  let newPrimary = [];
  let newContributors = [];
  form["#participants"].forEach((item, index) => {
    if (Object.keys(item)[0] === "primary" && fieldCase === "release") {
      // in case of basic information page (release passed to fieldCase), primary artists
      newPrimary.push({ primary: item.primary });
    } else if (fieldCase === "release") {
      newContributors.push(item);
    } else if (fieldCase === "tracks") {
      // for upload tracks page
      if (item.type === "primary") {
        newPrimary.push(item);
      } else {
        newContributors.push(item);
      }
    }
  });
  // handling the case if there is no primary, or no contributors
  let hasPrimary =
    form["#participants"].filter((item) => {
      if (fieldCase === "tracks") {
        return item.type === "primary";
      } else if (fieldCase === "release") {
        return item.primary;
      }
    }).length > 0
      ? true
      : false;
  let hasContributors =
    form["#participants"].filter((item) => item.type && item.type !== "primary")
      .length > 0
      ? true
      : false;

  if (!hasPrimary) {
    newPrimary.push({ type: "", value: "", name: "", email: "", share: "" });
  }
  if (!hasContributors) {
    newContributors.push({
      type: "",
      value: "",
      name: "",
      email: "",
      share: "",
    });
  }

  form["#participants"] = {
    primary: newPrimary,
    contributors: newContributors,
  };
  form["#secondarycontributors"] = {
    contributors: []
  };
  form["#writercontributors"] = {
    contributors: []
  };
  form["#othercontributors"] = {
    contributors: []
  };
  form["#engineeringcontributors"] = {
    contributors: []
  };
  form["#instrumentalcontributors"] = {
    contributors: []
  };
  form["#adminiscontributors"] = {
    contributors: []
  };
  form["#vocalcontributors"] = {
    contributors: []
  };
  let filtered = [];
  // split options   
  // set these to divide or contributors
  filtered = form["#participants"].contributors.filter(item =>
      secondaryPerformersOptions.some(
        criterion => item.type === criterion.value
      )
    );
  if (filtered.length < 1) {
    form["#secondarycontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#secondarycontributors"].contributors=[...filtered];
  }

  filtered = form["#participants"].contributors.filter(item =>
    writersOptions.some(
      criterion => item.type === criterion.value
    )
  );
  if (filtered.length < 1) {
    form["#writercontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#writercontributors"].contributors=[...filtered];
  }

  filtered = form["#participants"].contributors.filter(item =>
      otherPerformersOptions.some(
        criterion => item.type === criterion.value
      )
    );
  if (filtered.length < 1) {
    form["#othercontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#othercontributors"].contributors=[...filtered];
  }
  
  filtered = form["#participants"].contributors.filter(item =>
      engineeringOptions.some(
        criterion => item.type === criterion.value
      )
    );

  if (filtered.length < 1) {
    form["#engineeringcontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#engineeringcontributors"].contributors=[...filtered];
  }
  
  filtered = form["#participants"].contributors.filter(item =>
      instrumentalOptions.some(
        criterion => item.type === criterion.value
      )
    );
  if (filtered.length < 1) {
    form["#instrumentalcontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
     form["#instrumentalcontributors"].contributors=[...filtered];
  }
  
  filtered = form["#participants"].contributors.filter(item =>
      administrativeOptions.some(
        criterion => item.type === criterion.value
      )
    );
  if (filtered.length < 1) {
    form["#adminiscontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#adminiscontributors"].contributors=[...filtered];
  }

  filtered = form["#participants"].contributors.filter(item =>
      vocalOptions.some(
        criterion => item.type === criterion.value
      )
    );
  if (filtered.length < 1) {
    form["#vocalcontributors"] = {
      contributors: [{
        type: "",
        value: "",
        name: "",
        email: "",
        share: "",
      }],
    };
  } else {
    form["#vocalcontributors"].contributors=[...filtered];
  }
  
  return form;
}

export function checkIfUserCompletedPage2(formData) {
  if (formData.tracks) return true;
  else return false;
}
