import React from "react";

import X from "../assets/X.svg";
import playtreksTransparentBg from "../../../assets/playtreksTransparentBg.png";

function Header({ quitCallback }) {
  return (
    <div className="distrHeaderPlaytreks">
      <img
        className="distrheader-logo"
        onClick={() => window.location.assign("/welcomePage")}
        src={playtreksTransparentBg}
      />
      <div className="distrheader-X" onClick={quitCallback}>
        <img src={X} alt="back to dashboard" />
      </div>
    </div>
  );
}

export default Header;
