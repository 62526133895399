import axios from "axios";
import { getJWT } from "../../components/utils";

const axiosInstance = axios.create();

export async function getAccountingPeriods() {
  let url = `${global.config.API_URL}/api/admin/accountingperiods`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function setPaymentStatus({ periodId, status }) {
  let url = `${global.config.API_URL}/api/admin/accountingperiods/${periodId}/paymentstatus`;

  return axiosInstance.put(
    url,
    { status },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}
