import React, { useState, useEffect } from "react";

//pckgs
import { cloneDeep } from "lodash";

// config
import { dateRangeFilterMap, musicFiltersMap, countriesMap } from "../config";

// spinner
import spinner from "../../../../assets/spinner.svg";
import InputComponent from "../../../form/InputComponent";
import CalendarComponent from "../../../form/CalendarComponent";
import calendar from "../../../../assets/icons/calendar.svg";
import { useCallback } from "react";
import AppliedFilters from "../../../../screens/Airplay/components/AppliedFilters";

// filter types
const FROM_DATE_RANGE_FILTER = "from_date_range_filter";
const UNTIL_DATE_RANGE_FILTER = "until_date_range_filter";
const MUSIC_RELEASES_FILTER = "music_releases_filter";
const MUSIC_TRACKS_FILTER = "music_tracks_filter";
const MUSIC_ARTISTS_FILTER = "music_artists_filter";
const STORES_FILTER = "stores_filter";
const COUNTRIES_FILTER = "countries_filter";

// dropdowns
const DATE_RANGE_DROPDOWN = "date_range_dropdown";
const MUSIC_DROPDOWN = "music_dropdown";
const STORES_DROPDOWN = "stores_dropdown";
const COUNTRIES_DROPDOWN = "countries_dropdown";

const Spinner = () => (
  <div style={{ width: "100%" }} className="xyFlexCenter">
    <img src={spinner}></img>
  </div>
);

function Filters({ currentFilters, setCurrentFilters, filters }) {
  const [openFilter, setOpenFilter] = useState(); // dropdown state
  const [openMusicFilter, setOpenMusicFilter] = useState(); // inner dropdown for "Music" filter state
  const [pickedMusicFilter, setPickedMusicFilter] = useState();
  const [pickedDate, setPickedDate] = useState({
    fromDate: "",
    untilDate: "",
  });

  const [calendarState, setCalendarState] = useState({
    fromDate: false,
    untilDate: false,
  });

  const storesFilter = filters.channels;
  const countriesFilter = filters.countries;
  const releasesFilter = filters.releases;
  const tracksFilter = filters.tracks;
  const artistsFilter = filters.artists;

  const storesFilterContent = storesFilter.sort();
  const countriesFilterContent = countriesFilter.sort();
  const releasesFilterContent = releasesFilter.sort();
  const tracksFilterContent = tracksFilter.sort();
  const artistsFilterContent = artistsFilter.sort();

  const fromDatePickCallback = useCallback(
    (date, calendarId, databaseField) => {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        [FROM_DATE_RANGE_FILTER]: date,
      }));
      setPickedDate((prevPickedState) => ({
        ...prevPickedState,
        [calendarId]: date,
      }));
    },
    []
  );

  const untilDatePickCallback = useCallback(
    (date, calendarId, databaseField) => {
      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        [UNTIL_DATE_RANGE_FILTER]: date,
      }));
      setPickedDate((prevPickedState) => ({
        ...prevPickedState,
        [calendarId]: date,
      }));
    },
    []
  );

  const handleOpenCalendar = useCallback((className, forField, id) => {
    setCalendarState((prevCalendarState) => ({
      ...prevCalendarState,
      [id]: !prevCalendarState[id],
    }));
  }, []);

  const appliedFilters = React.useMemo(
    () => ({
      fromDate: { label: "From date:", value: pickedDate?.fromDate },
      untilDate: { label: "Until date:", value: pickedDate?.untilDate },
    }),
    [pickedDate?.fromDate, pickedDate?.untilDate]
  );

  const handleResetFilter = (filtername) => {
    switch (filtername) {
      case "fromDate":
        setPickedDate((prevPickedState) => ({
          ...prevPickedState,
          [filtername]: "",
        }));
        setCurrentFilters((prevFilters) => ({
          ...prevFilters,
          [FROM_DATE_RANGE_FILTER]: 0,
        }));
        break;

      case "untilDate":
        setPickedDate((prevPickedState) => ({
          ...prevPickedState,
          [filtername]: "",
        }));
        setCurrentFilters((prevFilters) => ({
          ...prevFilters,
          [UNTIL_DATE_RANGE_FILTER]: 0,
        }));
        break;
      default:
        break;
    }
  };

  // LOGIC
  function setFilter(type, value) {
    let newFilters = cloneDeep(currentFilters);
    newFilters[type] = value;
    setCurrentFilters(newFilters);
    setOpenFilter(); // collapse dropdown
  }

  function resetMusicFilters() {
    setCurrentFilters({
      [FROM_DATE_RANGE_FILTER]: currentFilters[FROM_DATE_RANGE_FILTER],
      [UNTIL_DATE_RANGE_FILTER]: currentFilters[UNTIL_DATE_RANGE_FILTER],
      [MUSIC_RELEASES_FILTER]: 0,
      [MUSIC_TRACKS_FILTER]: 0,
      [MUSIC_ARTISTS_FILTER]: 0,
      [STORES_FILTER]: currentFilters[STORES_FILTER],
      [COUNTRIES_FILTER]: currentFilters[COUNTRIES_FILTER],
    });
    setPickedMusicFilter();
    toggleMusicDropdown();
  }

  useEffect(() => {
    setOpenFilter(); // collapse dropdown
    // NOTE: picking "no filter" from any dropdown resets the whole music filter, cancelling out other sub filters
    // updating the inner filter state from the "Music" filter
    if (!pickedMusicFilter) return;

    let newCurrentFilters = cloneDeep(currentFilters);
    let subFilterValue =
      pickedMusicFilter?.value === "0" ? Number(0) : pickedMusicFilter?.value; // parse 0 (no filter) to number
    newCurrentFilters[pickedMusicFilter?.subFilter] = subFilterValue;
    // clearing other filter states
    [MUSIC_RELEASES_FILTER, MUSIC_TRACKS_FILTER, MUSIC_ARTISTS_FILTER].forEach(
      (filter) => {
        if (filter !== pickedMusicFilter?.subFilter)
          newCurrentFilters[filter] = 0;
      }
    );
    if (JSON.stringify(currentFilters) !== JSON.stringify(newCurrentFilters))
      setCurrentFilters(newCurrentFilters);
  }, [pickedMusicFilter]);

  useEffect(() => {
    // if music filter comes from the query string
    [MUSIC_RELEASES_FILTER, MUSIC_TRACKS_FILTER, MUSIC_ARTISTS_FILTER].forEach(
      (filter) => {
        if (currentFilters[filter] !== 0)
          setPickedMusicFilter({
            subFilter: filter,
            value: currentFilters[filter],
          });
      }
    );
  }, [currentFilters]);

  // UI
  function toggleFilterDropdown(dropdownId) {
    openFilter === dropdownId ? setOpenFilter() : setOpenFilter(dropdownId);
  }
  function toggleHoverFilter(dropdownId) {
    if (openFilter?.length) setOpenFilter(dropdownId);
  }
  // inner music dropdowns
  function toggleMusicDropdown(innerDropdownId) {
    if (openMusicFilter === innerDropdownId) setOpenMusicFilter();
    else setOpenMusicFilter(innerDropdownId);
  }

  // music filter helpers
  const musicFilterContentIsLoading =
    !releasesFilterContent || !tracksFilterContent || !artistsFilterContent;
  const noMusicFilterIsPicked =
    currentFilters[pickedMusicFilter?.subFilter] === 0 || !pickedMusicFilter;
  const isMusicFilterSet = (subFilterName) =>
    pickedMusicFilter?.subFilter === subFilterName;
  const isMusicSubOpen = (subDropdownName) =>
    openMusicFilter === subDropdownName;
  const isFilterActive = (filterName) => currentFilters[filterName] !== 0;

  return (
    <>
      <div className="row mx-0 px-0 justify-content-between align-items-center my-2">
        <div className="ariplay-date-filter-wrapper position-relative ">
          <div
            className={
              "inputUpperComment iuc6 " + (pickedDate["fromDate"] || "d-none")
            }
          >
            From date
          </div>
          <InputComponent
            disabled={true}
            value={pickedDate["fromDate"]} // take from calendar, or pre-populate
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["fromDate"] || pickedDate["fromDate"].length
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="From date"
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="fromDate"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            databaseField={FROM_DATE_RANGE_FILTER}
          />
          <CalendarComponent
            calendarState={calendarState["fromDate"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={fromDatePickCallback}
            calendarId="fromDate"
            databaseField={FROM_DATE_RANGE_FILTER}
            maxDate={new Date()}
            shouldCloseOnSelect={true}
          />
        </div>
        <div className="ariplay-date-filter-wrapper position-relative">
          <div
            className={
              "inputUpperComment iuc6 " + (pickedDate["untilDate"] || "d-none")
            }
          >
            Until date
          </div>
          <InputComponent
            disabled={true}
            value={pickedDate["untilDate"]} // take from calendar, or pre-populate
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["untilDate"] || pickedDate["untilDate"].length
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="Until date"
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="untilDate"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            databaseField={UNTIL_DATE_RANGE_FILTER}
          />
          <CalendarComponent
            calendarState={calendarState["untilDate"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={untilDatePickCallback}
            calendarId="untilDate"
            databaseField={UNTIL_DATE_RANGE_FILTER}
            minDate={new Date(pickedDate["fromDate"])}
            maxDate={new Date()}
            shouldCloseOnSelect={true}
          />
        </div>

        <AppliedFilters
          appliedFilters={appliedFilters}
          onReset={handleResetFilter}
        />
      </div>

      <div className="my-earnings-section-1-filters">
        <div className="my-earnings-section-1-filters-filter">
          <div
            className={
              "my-earnings-section-1-filters-filter-button" +
              ([
                MUSIC_RELEASES_FILTER,
                MUSIC_TRACKS_FILTER,
                MUSIC_ARTISTS_FILTER,
              ]
                ?.map((subFilter) => isFilterActive(subFilter))
                ?.includes(true)
                ? "-active"
                : "")
            }
            onClick={() => toggleFilterDropdown(MUSIC_DROPDOWN)}
            onMouseOver={() => toggleHoverFilter(MUSIC_DROPDOWN)}
          >
            <div className="interBold">
              {noMusicFilterIsPicked
                ? "Music"
                : musicFiltersMap[pickedMusicFilter?.subFilter]}
            </div>
            <br />
            <small>
              {noMusicFilterIsPicked
                ? "All music"
                : currentFilters[pickedMusicFilter?.subFilter]}
            </small>
          </div>
          <div
            className={
              "my-earnings-section-1-filters-filter-dropdown" +
              (openFilter === MUSIC_DROPDOWN ? "" : "-hidden")
            }
          >
            {musicFilterContentIsLoading ? (
              <Spinner />
            ) : (
              <>
                <div onClick={() => resetMusicFilters()}>All music</div>
                <div className="my-earnings-section-1-filters-filter-selectable">
                  <div className="withSelector">
                    {musicFiltersMap[MUSIC_ARTISTS_FILTER]}{" "}
                  </div>
                  <select
                    className="my-earnings-section-1-filters-filter-selectable-picker"
                    onChange={(e) =>
                      setPickedMusicFilter({
                        subFilter: MUSIC_ARTISTS_FILTER,
                        value: e.target.value,
                      })
                    }
                    value={
                      isMusicFilterSet(MUSIC_ARTISTS_FILTER)
                        ? pickedMusicFilter?.value
                        : 0
                    }
                  >
                    <option value={0}>All artists</option>
                    {artistsFilterContent.map((el) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </div>
                <div className="my-earnings-section-1-filters-filter-selectable">
                  <div className="withSelector">
                    {musicFiltersMap[MUSIC_RELEASES_FILTER]}{" "}
                  </div>
                  <select
                    className="my-earnings-section-1-filters-filter-selectable-picker"
                    onChange={(e) =>
                      setPickedMusicFilter({
                        subFilter: MUSIC_RELEASES_FILTER,
                        value: e.target.value,
                      })
                    }
                    value={
                      isMusicFilterSet(MUSIC_RELEASES_FILTER)
                        ? pickedMusicFilter?.value
                        : 0
                    }
                  >
                    <option value={0}>All releases</option>
                    {releasesFilterContent.map((el) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </div>
                <div className="my-earnings-section-1-filters-filter-selectable">
                  <div className="withSelector">
                    {musicFiltersMap[MUSIC_TRACKS_FILTER]}{" "}
                  </div>
                  <select
                    className="my-earnings-section-1-filters-filter-selectable-picker"
                    onChange={(e) =>
                      setPickedMusicFilter({
                        subFilter: MUSIC_TRACKS_FILTER,
                        value: e.target.value,
                      })
                    }
                    value={
                      isMusicFilterSet(MUSIC_TRACKS_FILTER)
                        ? pickedMusicFilter?.value
                        : 0
                    }
                  >
                    <option value={0}>All tracks</option>
                    {tracksFilterContent.map((el) => (
                      <option value={el}>{el}</option>
                    ))}
                  </select>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="my-earnings-section-1-filters-filter">
          <div
            className={
              "my-earnings-section-1-filters-filter-button" +
              (isFilterActive(STORES_FILTER) ? "-active" : "")
            }
            onClick={() => toggleFilterDropdown(STORES_DROPDOWN)}
            onMouseOver={() => toggleHoverFilter(STORES_DROPDOWN)}
          >
            <div className="interBold">Stores</div>
            <br />
            <small>
              {currentFilters[STORES_FILTER] === 0
                ? "All stores"
                : currentFilters[STORES_FILTER]}
            </small>
          </div>
          <div
            className={
              "my-earnings-section-1-filters-filter-dropdown" +
              (openFilter === STORES_DROPDOWN ? "" : "-hidden")
            }
          >
            {!storesFilterContent ? (
              <Spinner />
            ) : (
              <>
                <div onClick={() => setFilter(STORES_FILTER, 0)}>
                  All stores
                </div>
                {storesFilterContent?.map((store) => (
                  <div onClick={() => setFilter(STORES_FILTER, store)}>
                    {store}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="my-earnings-section-1-filters-filter">
          <div
            className={
              "my-earnings-section-1-filters-filter-button" +
              (isFilterActive(COUNTRIES_FILTER) ? "-active" : "")
            }
            onClick={() => toggleFilterDropdown(COUNTRIES_DROPDOWN)}
            onMouseOver={() => toggleHoverFilter(COUNTRIES_DROPDOWN)}
          >
            <div className="interBold">Countries</div>
            <br />
            <small>
              {currentFilters[COUNTRIES_FILTER] === 0
                ? "All countries"
                : countriesMap[currentFilters[COUNTRIES_FILTER]]}
            </small>
          </div>
          <div
            className={
              "my-earnings-section-1-filters-filter-dropdown" +
              (openFilter === COUNTRIES_DROPDOWN ? "" : "-hidden")
            }
          >
            {!countriesFilterContent ? (
              <Spinner />
            ) : (
              <>
                <div onClick={() => setFilter(COUNTRIES_FILTER, 0)}>
                  All countries
                </div>
                {countriesFilterContent?.map((country) => (
                  <div onClick={() => setFilter(COUNTRIES_FILTER, country)}>
                    {countriesMap[country]}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
