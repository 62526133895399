import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

export async function genericSearch(value, limit, type) {
  let url = `${global.config.API_URL}/api/generic_text_search?query=${value}&limit=${limit}&type=${type}`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
}

export async function entityDataSearch(type, spotify_id) {
  let url = `${
    global.config.API_URL
  }/api/get_entity_data?name=${encodeURIComponent(
    spotify_id
  )}&searchBy=${type}&exactID=1`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
}

//TODO: Refactor this function to dynamically append query params
// export async function radioMonitroingData(
//   spotify_id,
//   type,
//   queryParamsString = "&size=25"
// ) {
//   let url = `${global.config.API_URL}/api/airplay?spotifyId=${spotify_id}&type=${type}${queryParamsString}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

// export async function webProfilesSearch(spotify_id) {
//   let url = `${global.config.API_URL}/api/get_web_profiles?name=${spotify_id}&exactID=1`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

// export async function entityStatsOvertime(spotify_id, { type = "artist" }) {
//   let url = `${global.config.API_URL}/api/entity_stats_over_time?spotifyID=${spotify_id}&type=${type}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

// export async function deltaArtistStats(
//   spotifyID = "",
//   fromDate = "",
//   untilDate = "",
//   { type = "artist" }
// ) {
//   let url = `${global.config.API_URL}/api/entity_delta_stats?spotifyID=${spotifyID}&fromDate=${fromDate}&untilDate=${untilDate}&type=${type}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

export async function youtubeSubscribersCount(youtubeID) {
  let url = `${global.config.API_URL}/api/get_youtube_subscribers?channelId=${youtubeID}`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
}

export async function getTwitterFollowers(twitterName) {
  let url = `${global.config.API_URL}/api/get_twitter_followers?name=${twitterName}`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
}

// export async function playlistSearch(size, offset, type, id, platform) {
//   let url = `${
//     global.config.API_URL
//   }/api/search_playlist?name=${encodeURIComponent(
//     id
//   )}&size=${size}&offset=${offset}&searchBy=${type}&exactID=1&platform=${platform}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }
