import React, { useState } from "react";
import "./index.css";

function Switch({ isChecked, handleToggle }) {
  console.log(isChecked);

  return (
    <label className="switch">
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <span className="slider"></span>
    </label>
  );
}

export default Switch;
