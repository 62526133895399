///
/// Note about tooltips: The tooltip <ReactTooltip/> tag must appear only once,
/// if it appears twice all the tooltips will appear doubled.
/// Right now, the tag is placed inside of the SelectComponent.js file,
/// because that's the only way the tooltips appear in the right way (also propagates to input components)
/// (Reason for this behaviour is unknown, yet that's the way it works correctly, hence the explanation)
///
import React, { useState, useEffect } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import {
  editReleaseField,
  populateReleaseForm,
  addReleasePrimaryArtistField,
  addReleaseContributorField,
  removeReleasePrimaryArtistField,
  removeReleaseContributorField,
  lockFormField,
  setUsedArtistFields,
} from "../../redux/actions/distributionActions.js";

// packages
import { v4 as uuidv4 } from "uuid";
import addDays from "date-fns/addDays";
import { isEqual } from "lodash";

// data
import {
  contributorsOptions,
  administrativeOptions,
  instrumentalOptions,
  engineeringOptions,
  otherPerformersOptions,

  writersOptions,
  vocalOptions,

  secondaryPerformersOptions,

  genres,
  languages,
  licenseTypes,
  licenseInfoTypes,
  tooltipData,
} from "./data/distrFieldsData";

// components
import Header from "./components/RuslanHeader";
import InputComponent from "../form/InputComponent";
import SelectComponent from "../form/SelectComponent";
import Checkbox from "../form/RuslanCheckbox";
import Calendar from "../form/CalendarComponent";

// distribution specific
import CoverArtUploadComponent from "./components/CoverArtUploadComponent";
import ContributorsFieldComponent from "./components/ContributorsFieldComponent";
import ReleaseProgressCircles from "./components/ReleaseProgressCircles";
import PrimaryArtistFieldComponent from "./components/PrimaryArtistFieldComponent";

import calendar from "../../assets/icons/calendar.svg";
import trash from "../../assets/icons/trash.svg";
import spinner from "../../assets/spinner.svg";
import info from "../../assets/icons/info.svg";
import infoRed from "../../assets/icons/infoRed.svg";

// Backend
import {
  submitDistributionForm,
  uploadCoverArtToS3,
  getReleaseData,
} from "../../routes/distribution";
import { genericSearch } from "../../routes/search";
import { getUserData } from "../../routes/users";
import { getSubscriptionPlan } from "../../routes/payments";

// helper functions
import {
  prepareFormForBackend,
  convertBackToFrontEnd,
} from "./helpers/distrHelpers";
import {
  mobileExpandHelper,
  contributorsMobileLogic,
  artistsMobileLogic,
  desktopExpandablesLogic,
} from "./helpers/biExpandablesLogic";

// validation
import { validateBI } from "./validation/distrValidation";
import ValidationTextBox from "../form/ValidationTextBox";
import QuitNewReleaseModal from "./components/QuitNewReleaseModal";
import { getUsedArtists } from "../../routes/distribution";
import { addAlert } from "../../redux/actions/appActions.js";
import { getJWT } from "../utils.js";
import Portal from "../Portal/index.js";
import VersionErrorModal from "./components/VersionErrorModal.js";

const CREATIVE_COMMON_LICENSE_VALUE = "cc";
const COPYRIGHT_LICENSE_VALUE = "(c)";
const NEW_RELEASE_DATE_DAYS_BLOCKED = 10;

function DistributionBasicInformation() {
  // get jwt token for authentication
  const jwtToken = getJWT();

  const [prSwitch, setPrSwitch] = useState({
    explicitLyrics: false,
    previouslyReleased: false,
  });
  const [artistsArray, setArtistsArray] = useState([""]);
  const [contributorsArray, setContributorsArray] = useState([0]);

  const [secondaryArray, setSecondaryArray] = useState([0]);

  const [contributorsArrayOtherPerformers, setContributorsArrayOtherPerformers] = useState([0]);
  const [contributorsArrayEngineering, setContributorsArrayEngineering] = useState([0]);
  const [contributorsArrayInstrumental, setContributorsArrayInstrumental] = useState([0]);
  const [contributorsArrayAdminis, setContributorsArrayAdminis] = useState([0]);

  const [writerContributorsArray, setWriterContributorsArray] = useState([0]);
  const [vocalContributorsArray, setVocalContributorsArray] = useState([0]);

  const [extraRows, setExtraRows] = useState([]);
  const [extraMobileRows, setExtraMobileRows] = useState([]);
  const [checked, setChecked] = useState({
    releaseUpcCodeCheckbox: false,
    catalogNumberCheckbox: false,
  });
  const [calendarState, setCalendarState] = useState({
    calendar1: false,
    calendar2: false,
  });
  const [pickedDate, setPickedDate] = useState({
    calendar1: "",
    calendar2: "",
  });

  const [changesPresent, setChangesPresent] = useState(false); // for the quit modal to conditionally display

  const [prepopulated, setPrepopulated] = useState(false);
  const [coverArtFile, setCoverArtFile] = useState();
  const [preloadImage, setPreloadImage] = useState(false);

  // const [socialPrepop, setSocialPrepop] = useState(false);

  const [validation, setValidation] = useState({});

  const [valMessages, setValMessages] = useState({
    "#digital_release": "",
    "#original_release": "",
  });

  const [endError, setEndError] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [mobileExtFieldPushDown, setMobileExtFieldPushDown] = useState(0);

  // REDUX

  const reduxForm = useSelector(
    (state) => state.distributionReducer?.releaseForm
  );
  const dispatch = useDispatch();

  /// FORM STATE INITIALIZATION

  useEffect(() => {
    let previousReleaseId = window.localStorage.getItem("currentReleaseId");
    if (previousReleaseId) {
      // getting form from the backend
      getReleaseData(previousReleaseId, jwtToken).then((r) => {
        if (r.data.length === 0) {
          // if form for the current release id is empty, refresh and create a new one
          let newId = uuidv4();
          // save it in the cookie
          window.localStorage.setItem("currentReleaseId", newId);
          // REDUX update form
          dispatch(editReleaseField(newId, "releaseId"));
        } else {
          // if data present, pre-populate the form (user is navigating backwards)
          // also converting the form back to the front end version from the back-end parsed one
          //
          // setting prepopulated state for visual changes to commentFields
          setPrepopulated(true);
          // mutating, converting
          convertBackToFrontEnd(r.data[0], "release");
          // updating redux state
          dispatch(populateReleaseForm(r.data[0]));
          // populate the visual state variables for expandable fields presentation (if array empty don't touch, let the array auto generate)
          if (r.data[0]["#participants"].contributors.length > 0) {
            setContributorsArray(
              r.data[0]["#participants"].contributors.map((item) => 1)
            );
            let filtered = [];
            filtered = r.data[0]["#participants"].contributors.filter(item =>
                writersOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setWriterContributorsArray([{ type: '', value: '' }]);
            } else {
              setWriterContributorsArray(filtered);
            }
            
            // split options
            filtered = r.data[0]["#participants"].contributors.filter(item =>
                secondaryPerformersOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setSecondaryArray([{ type: '', value: '' }]);
            } else {
              setSecondaryArray(filtered);
            }
            
            filtered = r.data[0]["#participants"].contributors.filter(item =>
                otherPerformersOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setContributorsArrayOtherPerformers([{ type: '', value: '' }]);
            } else {
              setContributorsArrayOtherPerformers(filtered);
            }
            
            filtered = r.data[0]["#participants"].contributors.filter(item =>
                engineeringOptions.some(
                  criterion => item.type === criterion.value
                )
              );

            if (filtered.length < 1) {
              setContributorsArrayEngineering([{ type: '', value: '' }]);
            } else {
              setContributorsArrayEngineering(filtered);
            }
            
            // console.log("engineering",filtered,engineeringOptions,r.data[0]["#engineeringcontributors"].contributors);

            filtered = r.data[0]["#participants"].contributors.filter(item =>
                instrumentalOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setContributorsArrayInstrumental([{ type: '', value: '' }]);
            } else {
              setContributorsArrayInstrumental(filtered);
            }
           
            filtered = r.data[0]["#participants"].contributors.filter(item =>
                administrativeOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setContributorsArrayAdminis([{ type: '', value: '' }]);
            } else {
              setContributorsArrayAdminis(filtered);
            }

            filtered = r.data[0]["#participants"].contributors.filter(item =>
                vocalOptions.some(
                  criterion => item.type === criterion.value
                )
              );
            if (filtered.length < 1) {
              setVocalContributorsArray([{ type: '', value: '' }]);
            } else {
              setVocalContributorsArray(filtered);
            }
                      }
          if (r.data[0]["#participants"].primary.length > 0) {
            setArtistsArray(
              r.data[0]["#participants"].primary.map((item) => 1)
            );
          }
          // setting checkboxes
          setChecked({
            releaseUpcCodeCheckbox: r.data[0]["#upc"] === "auto",
            catalogNumberCheckbox: r.data[0]["#catalog_number"] === "auto",
          });
          // setting switches
          setPrSwitch({
            explicitLyrics: r.data[0]["#explicit_lyrics"],
            previouslyReleased: r.data[0]["#original_release"].length > 0,
          });
          // setting preloaded cover art
          setPreloadImage({
            file: "preloaded",
            previewUrl: r.data[0]["#cover_url"],
          });
        }
      });
    } else {
      // if no rId, create new one
      let newId = uuidv4();
      // update the form
      dispatch(editReleaseField(newId, "releaseId"));
      // store it in the cookie for further reference
      window.localStorage.setItem("currentReleaseId", newId);
    }
    // fetch social media links
    // getUserData(jwtToken).then((r) => {
    //   if (
    //     r.data?.service_profiles?.selectedFacebookArtistPageID ||
    //     r.data?.service_profiles?.selectedSpotifyID
    //   ) {
    //     let importedSocialLinks = [];
    //     importedSocialLinks.push(
    //       // facebook
    //       r.data?.service_profiles?.selectedFacebookArtistPageID &&
    //         "https://www.facebook.com/" +
    //           r.data?.service_profiles?.selectedFacebookArtistPageID,
    //       // spotify
    //       r.data?.service_profiles?.selectedSpotifyID &&
    //         "https://open.spotify.com/artist/" +
    //           r.data?.service_profiles?.selectedSpotifyID
    //     );
    //     let socialLinks = importedSocialLinks.filter(
    //       (item) => item !== undefined
    //     );
    //     // REDUX
    //     ["socialMediaLink1", "socialMediaLink2"].forEach((field, index) =>
    //       dispatch(editReleaseField(socialLinks[index], field))
    //     );
    //     // indicates that social links have been prepopulated
    //     setSocialPrepop(true);
    //   }
    //   // TODO: populate artist dropdown
    // });
  }, []); // run on page load

  // subscription rules logic

  const reduxSubscriptionRules = useSelector(
    (state) => state.subscriptionReducer.subscription.rules
  );

  useEffect(() => {
    // sets restrictions according to current user subscription
    // set locked
    if (reduxSubscriptionRules?.distributionLabelField?.isEditable === false) {
      dispatch(lockFormField("#label", "", ""));
    }
  }, [reduxSubscriptionRules]);

  useEffect(() => {
    // populate redux used artists
    dispatch(setUsedArtistFields());
  }, []);

  /// EXPANDABLES LOGIC
  // functions in helpers/biExpandablesLogic file

  useEffect(() => {
    desktopExpandablesLogic(
      window,
      extraRows,
      setExtraRows,
      artistsArray,
      contributorsArray
    );
  }, [artistsArray, contributorsArray, extraRows]); // run on expandable fields change

  useEffect(() => {
    contributorsMobileLogic(window, extraRows, setExtraRows, contributorsArray);
  }, [contributorsArray, extraRows]);

  /// CHECKBOX / SWITCH STATE -> FORM

  useEffect(() => {
    // updating the state bound to checkboxes and switches
    dispatch(
      editReleaseField(
        checked.releaseUpcCodeCheckbox ? "auto" : reduxForm?.["#upc"],
        "#upc"
      )
    );
    dispatch(
      editReleaseField(
        checked.catalogNumberCheckbox ? "auto" : reduxForm?.["#catalog_number"],
        "#catalog_number"
      )
    );
    if (prSwitch.previouslyReleased) {
      setValidation({ ...validation, "#original_release": false });
    }
  }, [checked, prSwitch]); // run on checkboxes and switch change

  /// #LICENSE_TYPE & #LICENSE_INFO LOGIC

  useEffect(() => {
    // set default value for license info if no value
    if (
      reduxForm["#license_type"] === CREATIVE_COMMON_LICENSE_VALUE &&
      reduxForm["#license_info"] === ""
    ) {
      dispatch(editReleaseField("BY", "#license_info"));
    } else if (
      reduxForm["#license_type"] !== CREATIVE_COMMON_LICENSE_VALUE &&
      reduxForm["#license_info"] !== ""
    ) {
      // and clear the value if license type is not cc
      dispatch(editReleaseField("", "#license_info"));
    }
  }, [reduxForm["#license_type"]]); // on license type field change

  // callbacks

  function inputChangeCallback(
    value,
    databaseField,
    databaseKey,
    inputId,
    participantsSubKey
  ) {
    // console.log("dispatch",value,databaseField,databaseKey,participantsSubKey,inputId);
    // REDUX
    dispatch(
      editReleaseField(
        value?.replace('"', ""),
        databaseField,
        databaseKey,
        participantsSubKey,
        inputId
      )
    );
    setChangesPresent(true);
  }

  function inputBlurCallback(value, databaseField, fieldId, databaseKey) {
    validate(value, databaseField, fieldId, databaseKey);
  }

  async function selectChangeCallback(
    value,
    databaseField,
    commentFieldClassName,
    databaseKey,
    handlerType,
    ifValidate
  ) {
    // bypass setting the value to empty (fix)
    if (handlerType === "inputChange") return false;
    //console.log("dispatch",value,databaseField,databaseKey);
    // REDUX
    dispatch(
      editReleaseField(value?.replace('"', ""), databaseField, databaseKey)
    );

    // state for editing
    setChangesPresent(true);

    // validation
    if (ifValidate) {
      validate(value, databaseField);
    }

    // visual
    if (value.length > 0) {
      if (commentFieldClassName) {
        document
          .querySelector(commentFieldClassName)
          .classList.remove("d-none");
      }
    } else {
      if (commentFieldClassName && !reduxForm[databaseField]) {
        document.querySelector(commentFieldClassName).classList.add("d-none");
      }
    }
  }

  function selectBlurCallback(
    commentField,
    databaseField,
    value,
    databaseKey,
    fieldId
  ) {
    validate(reduxForm[databaseField], databaseField, fieldId, databaseKey);
    // hacky fix long select input blur behaviour
    setTimeout(() => {
      if (reduxForm[databaseField]?.length > 0) {
        //console.log("Commentfield",commentField);
        try {
          document.querySelector(commentField).classList.remove("d-none");
        } catch(error) {

        }
      }
    }, 0);
  }

  function datePickCallback(date, calendarId, databaseField) {
    // REDUX
    dispatch(editReleaseField(date, databaseField));
    //
    setChangesPresent(true);
    //
    setPickedDate({ ...pickedDate, [calendarId]: date });
  }

  function coverArtUploadCallback(file) {
    if (file && file !== "preloaded") {
      setChangesPresent(true);
      validate("ready", "#cover_url");
      dispatch(editReleaseField("ready", "#cover_url"));
      setCoverArtFile(file[0]);
    } else if (file === "preloaded") {
      setCoverArtFile("preloaded");
    } else {
      validate("error", "#cover_url");
      dispatch(editReleaseField("error", "#cover_url"));
    }
  }

  // handlers

  function handlePrSwitch(e) {
    //
    setChangesPresent(true);
    // switch toggle
    setPrSwitch({ ...prSwitch, [e.target.id]: !prSwitch[e.target.id] });
    if (e.target.id === "explicitLyrics") {
      // REDUX
      dispatch(editReleaseField(!prSwitch.explicitLyrics, "#explicit_lyrics"));
    }
    // MOBILE RESPONSIVE EXPAND
    if (e.target.id === "previouslyReleased" && window.innerWidth <= 768) {
      if (!prSwitch.previouslyReleased) {
        document
          .querySelector(".nrLicenseTypeWrapper")
          .classList.add("nrBottomPushDownMobile");
      } else {
        document
          .querySelector(".nrLicenseTypeWrapper")
          .classList.remove("nrBottomPushDownMobile");
      }
    }
  }

  // this check happens also in the distributionReducer, can be eliminated if artistsArray is controlled by redux
  const reduxAllowedArtistFieldAmount = useSelector(
    (state) =>
      state.subscriptionReducer.subscription?.rules
        ?.distributionPrimaryArtistsFields?.fieldAmount
  );
  const currentNumberOfFields = useSelector(
    (state) =>
      state.distributionReducer.releaseForm?.["#participants"]?.primary?.length
  );

  function addArtistCallback() {
    // this is only for the visual state
    if (reduxAllowedArtistFieldAmount === currentNumberOfFields) {
      dispatch(addAlert("Please upgrade", "/artistDashboard/subscriptions"));
      return;
    }
    dispatch(addReleasePrimaryArtistField());
    setArtistsArray([...artistsArray, 1]);
  }
  function handleAddContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setContributorsArray([...contributorsArray, 1]);
  }

  function handleAddSecondaryContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setSecondaryArray([...secondaryArray, 1]);
  }
  function handleAddOtherContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setContributorsArrayOtherPerformers([...contributorsArrayOtherPerformers, 1]);
  }
  function handleAddEngineeringContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setContributorsArrayEngineering([...contributorsArrayEngineering, 1]);
  }
  function handleAddInstrumentalContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setContributorsArrayInstrumental([...contributorsArrayInstrumental, 1]);
  }
  function handleAddAdmisContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setContributorsArrayAdminis([...contributorsArrayAdminis, 1]);
  }
  function handleAddWriterContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setWriterContributorsArray([...writerContributorsArray, 1]);
  }
  function handleAddVocalContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addReleaseContributorField(databaseField));
    // this is only for the visual state
    setVocalContributorsArray([...vocalContributorsArray, 1]);
  }

  function removeInputCallback(target, name, itemIndex, value) {
    // console.log('Remove', itemIndex, name);
    // TODO: move to expandables helper file?
    if (name === "primary") {
      // visual update
      let newArtistsArray = artistsArray.filter((v, i) => i !== itemIndex); // removing the object according to its position in the corresponding array
      setArtistsArray(newArtistsArray);

      // redux
      dispatch(removeReleasePrimaryArtistField(itemIndex));

      // MOBILE, REMOVING ARTISTS
      if (window.innerWidth <= 768) {
        mobileExpandHelper(
          false,
          null,
          mobileExtFieldPushDown,
          setMobileExtFieldPushDown
        );
      }
    } 
    else if (name === "secondarycontributors") {
      // visual
      let newSecondaryArray = secondaryArray.filter(
        (v, i) => i !== itemIndex
      );
      setSecondaryArray(newSecondaryArray);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "othercontributors") {
      // visual
      let newContributorsArrayOtherPerformers = contributorsArrayOtherPerformers.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayOtherPerformers(newContributorsArrayOtherPerformers);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "engineeringcontributors") {
      // visual
      let newContributorsArrayEngineering = contributorsArrayEngineering.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayEngineering(newContributorsArrayEngineering);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "instrumentalcontributors") {
      // visual
      let newContributorsArrayInstrumental = contributorsArrayInstrumental.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayInstrumental(newContributorsArrayInstrumental);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "vocalcontributors") {
      // visual
      let newVocalContributorsArray = vocalContributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setVocalContributorsArray(newVocalContributorsArray);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "writercontributors") {
      // visual
      let newWriterContributorsArray = writerContributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setWriterContributorsArray(newWriterContributorsArray);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "adminiscontributors") {
      // visual
      let newContributorsArrayAdminis = contributorsArrayAdminis.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayAdminis(newContributorsArrayAdminis);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
    else if (name === "contributors") {
      // visual
      let newContributorsArray = contributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArray(newContributorsArray);

      // redux
      dispatch(removeReleaseContributorField("#"+name, itemIndex));
    }
  }

  function handleCheckbox(e) {
    setChangesPresent(true);
    setChecked({ ...checked, [e.target.id]: !checked[e.target.id] });
    if (e.target.id === "releaseUpcCodeCheckbox") {
      // REDUX
      dispatch(
        editReleaseField(
          !checked["releaseUpcCodeCheckbox"] ? "auto" : "",
          "#upc"
        )
      );
      setValidation({
        ...validation,
        "#upc": checked.releaseUpcCodeCheckbox,
      });
    } else if (e.target.id === "catalogNumberCheckbox") {
      // REDUX
      dispatch(
        editReleaseField(
          !checked["catalogNumberCheckbox"] ? "auto" : "",
          "#catalog_number"
        )
      );
      setValidation({
        ...validation,
        "#catalog_number": checked.catalogNumberCheckbox,
      });
    }
  }

  function handleOpenCalendar(className, forField, id) {
    setCalendarState({ ...calendarState, [id]: !calendarState[id] });
  }
  function handleGeneralBlur(e) {
    // hacky state update on calendar blur
    const targetClass = e.target.className;
    if (
      targetClass.search("react-datepicker") < 0 &&
      targetClass.search("nrRightAddon") < 0 &&
      targetClass.search("nrAddonRightIcon") < 0
    ) {
      setCalendarState(false);
      // validation
      if (calendarState.calendar1 === true) {
        validate(reduxForm["#digital_release"], "#digital_release");
      }
      if (calendarState.calendar2 === true) {
        validate(reduxForm["#original_release"], "#original_release");
      }
    }
  }

  // VALIDATION
  function validate(value, databaseField, inputId, databaseKey) {
    // combine all to the ContributorsArray
    setContributorsArray([...contributorsArrayOtherPerformers,...vocalContributorsArray, ...contributorsArrayEngineering, ...contributorsArrayInstrumental, ...contributorsArrayAdminis, ...writerContributorsArray, ...secondaryArray], 1);
    
    // combine for validation
    reduxForm["#participants"].contributors = [
      ...reduxForm["#writercontributors"].contributors,
      ...reduxForm["#secondarycontributors"].contributors, 
      ...reduxForm["#othercontributors"].contributors, 
      ...reduxForm["#engineeringcontributors"].contributors, 
      ...reduxForm["#instrumentalcontributors"].contributors, 
      ...reduxForm["#adminiscontributors"].contributors, 
      ...reduxForm["#vocalcontributors"].contributors];
   
    console.log("Form", reduxForm); 
    /*
        secondarycontributors
        vocalcontributors
        writercontributors
        othercontributors
        engineeringcontributors
        instrumentalcontributors
        adminiscontributors
    */

    // console.log("Contributors", reduxForm["#participants"].contributors);

    return validateBI(
      value,
      databaseField,
      inputId,
      checked,
      valMessages,
      setValMessages,
      reduxForm,
      prSwitch,
      validation,
      setValidation,
      reduxCurrentPlan,
      databaseKey
    );
  }

  // submit logic

  async function handleSubmit() {
    // end validate all fields
  
    if (!validate()) {
      setSubmitLoading(true); // displaying spinner
      await submitDistributionForm(
        prepareFormForBackend(reduxForm, "release"),
        jwtToken
      ); // updating form
      if (coverArtFile !== "preloaded") {
        await uploadCoverArtToS3(coverArtFile, reduxForm.releaseId, jwtToken); // uploading image
      }
      setSubmitLoading("done"); // displaying done message
      window.location.assign("/distribution/newRelease/2"); // redirecting to page 2
    } else {
      setEndError(true); // displaying end validation error above submit button
      console.log("Error messages",valMessages);
    }
  }

  const [quitModal, setQuitModal] = useState(false);

  function quitCallback() {
    if (changesPresent) {
      setQuitModal(true);
    } else {
      window.location.assign("/artistDashboard/myReleases");
    }
  }

  // prepop custom fixes
  const langValue = useSelector(
    (state) => state.distributionReducer?.releaseForm?.["#language"]
  );
  const getLanguageLabel = () =>
    languages.find((lang) => lang.value === langValue)?.label;

  const licenseTypeValue = useSelector(
    (state) => state.distributionReducer.releaseForm["#license_type"]
  );

  const getLicenseTypeLabel = () =>
    licenseTypes.find((licenseType) => licenseType.value === licenseTypeValue)
      ?.label;

  const licenseInfoValue = useSelector(
    (state) => state.distributionReducer.releaseForm["#license_info"]
  );

  const getLicenseInfoLabel = () =>
    licenseInfoTypes.find(
      (licenseInfo) => licenseInfo.value === licenseInfoValue
    )?.label;

  // reduxCurrentPlan is the currently active subscription
  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const reduxLockedFields = useSelector(
    (state) => state.distributionReducer.lockedFields
  );

  function isFieldLocked(databaseField, databaseKey, databaseSubKey) {
    if (databaseField in reduxLockedFields) {
      return true;
    }
  }

  const reduxArtistSlots = useSelector(
    (state) => state.distributionReducer.usedArtistSlots
  );
  const reduxPrimaryArtists = useSelector(
    (state) => state.distributionReducer.releaseForm["#participants"].primary
  );

  const [userSlotsRemaining, setUserSlotsRemaining] = useState(); // contains the number of available slots
  const [isMatching, setIsMatching] = useState();

  // check artist slots on primary artist field blur
  function primaryArtistBlurCallback(
    commentField,
    databaseField,
    value,
    databaseKey
  ) {
    validate(
      reduxForm[databaseField],
      databaseField,
      commentField,
      databaseKey
    );
    const primaryArtistsArray = reduxPrimaryArtists.map(
      (artist) => artist.primary
    );
    let match = reduxArtistSlots.some((slot) =>
      isEqual(primaryArtistsArray, slot.artist_slot)
    );
    setIsMatching(match);
    if (!match) {
      // means user is creating a new artist slot
      setUserSlotsRemaining(
        reduxSubscriptionRules?.distributionPrimaryArtistSlots?.amount -
          reduxArtistSlots.length
      );
    }
  }

  const [isOpenVersionModal, setIsOpenVersionModal] = useState(false);

  const handleOpenVersionModal = () => {
    setIsOpenVersionModal(true);
  };

  const handleOnModalClose = () => {
    setIsOpenVersionModal(false);
  };

  return (
    <div
      className="distributionRoot"
      style={{ backgroundColor: "#343a47" }}
      onClick={handleGeneralBlur}
    >
      <Header quitCallback={quitCallback} />
      <QuitNewReleaseModal
        className={quitModal ? "" : "d-none"}
        setQuitModal={setQuitModal}
      />
      {/* title */}
      <div className="newReleaseTitle">new release</div>
      <ReleaseProgressCircles activeCircle={1} />
      {/* == FORM == */}
      <div className="nrForm">
        <div>
          Requirements Guidelines:{" "}
          <a href="/distribution/metadataGuidelines" target="blank">
            Metadata
          </a>
          /
          <a href="/distribution/coverartRequirements" target="blank">
            Cover Art
          </a>
        </div>
        <div className="nrBasicInformationHeader ">Basic information</div>
        <br />
        <div className="InputContainer">
          <div className="nrAlbumTitleInputWrapper">
            {/* upper comment inside the field, shows on input change */}
            <div
              className={
                (!prepopulated ? "d-none" : "") + " inputUpperComment iuc1"
              }
            >
              Release title*
            </div>
            <InputComponent
              value={useSelector(
                (state) => state.distributionReducer?.releaseForm?.["#title"]
              )}
              commentField=".iuc1"
              placeholder="Release title*"
              inputGroupClassName="nrInputGroup nrInputGroup-title"
              inputClassName={
                (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
                " nrInput"
              }
              addon={false}
              paddingTopOnInput={true}
              changeCallback={inputChangeCallback}
              inputBlurCallback={inputBlurCallback}
              databaseField="#title"
            />
          </div>

          <ValidationTextBox
            display={validation["#title"]}
            className="nrValTextBox nrValTextBox-title cur-pointer"
            for="#title"
          >
            <span data-tip='These characters are not allowed: <, >, |, /, , -, ., :, ;, !, ¡, ?, ¿, -, ·, &, ", ‘'>
              Please provide a valid title for your release{" "}
              <img
                src={infoRed}
                className="nrValTextBox-infoImage cur-pointer"
              />
            </span>
          </ValidationTextBox>

          <div className="VersionContainer">
            <div className="nrAlbumVersionInputWrapper">
              {/* upper comment inside the field, shows on input change */}
              <div
                className={
                  ((!prepopulated && !isFieldLocked("#remix_or_version")) ||
                  (prepopulated &&
                    !isFieldLocked("#remix_or_version") &&
                    (reduxForm["#remix_or_version"]?.length === 0 ||
                      !reduxForm["#remix_or_version"]))
                    ? "d-none"
                    : "") + " inputUpperComment iuc1a"
                }
              >
                Release version
              </div>
              <InputComponent
                value={useSelector(
                  (state) =>
                    state.distributionReducer?.releaseForm?.[
                      "#remix_or_version"
                    ]
                )}
                disabled={isFieldLocked("#remix_or_version")}
                commentField=".iuc1a"
                placeholder="Release version"
                inputGroupClassName="nrInputGroup nrInputGroup-title"
                inputClassName={
                  ((!prepopulated && !isFieldLocked("#remix_or_version")) ||
                  (prepopulated &&
                    !isFieldLocked("#remix_or_version") &&
                    (reduxForm["#remix_or_version"]?.length === 0 ||
                      !reduxForm["#remix_or_version"]))
                    ? ""
                    : "interMediumInput interMediumPaddingTop") + " nrInput"
                }
                addon={false}
                paddingTopOnInput={true}
                changeCallback={inputChangeCallback}
                inputBlurCallback={inputBlurCallback}
                databaseField="#remix_or_version"
              />
            </div>
            <ValidationTextBox
              display={validation["#remix_or_version"]}
              className="nrValTextBox  cur-pointer"
              for="#remix_or_version"
            >
              <span data-tip="Please click on more info button for the list of words that are not allowed in version field">
                This word is not allowed in the version field.{" "}
                <img
                  src={infoRed}
                  className="nrValTextBox-infoImage cur-pointer"
                />
              </span>
              <span className="ml-3 textLink" onClick={handleOpenVersionModal}>
                More info
              </span>
            </ValidationTextBox>

            <Portal selector="body">
              {isOpenVersionModal && (
                <VersionErrorModal onCloseRequest={handleOnModalClose} />
              )}
            </Portal>
          </div>
        </div>
        <br />
        {/* cover art upload */}
        <div className="nrCoverArtTitle">Cover art*</div>
        <CoverArtUploadComponent
          validation={validation}
          coverArtUploadCallback={coverArtUploadCallback}
          preloadImage={preloadImage} // in case of user navigating backwards
          className="nrCoverArtUploadWrapper"
        />
        {/* dynamically created fields (user plus click) */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
          
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Enter all primary artists here (use + to add new ones) &nbsp;{" "}
            <img
              src={info}
              className="cursor-pointer"
              data-tip={tooltipData.artistSlots}
            />
          </div>
            {artistsArray.map((v, i) => {
              return (
                <div>
                  <PrimaryArtistFieldComponent
                    i={i}
                    artistsArray={artistsArray}
                    InputComponent={InputComponent}
                    inputChangeCallback={inputChangeCallback}
                    inputBlurCallback={inputBlurCallback}
                    trash={trash}
                    addArtistCallback={addArtistCallback}
                    removeInputCallback={removeInputCallback}
                    validationDisplay={validation["#participants.primary"]}
                    ValidationTextBox={ValidationTextBox}
                    prepopulated={prepopulated}
                    primaryArtistBlurCallback={primaryArtistBlurCallback}
                  />
                </div>
              );
            })}
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Featuring and secondary artists
            </div>
            {/* contributors input group */}
            {secondaryArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={secondaryPerformersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={secondaryArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddSecondaryContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="secondarycontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.contributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.contributors"]}
            </ValidationTextBox>
          </div>
          </div>

          

          {/* hr */}
          <br />
          <div className="nrExpandableFieldsWrapper overrule-row-grid">
          {/* contributors vocal performers */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Vocal performers
          </div>
            {/* contributors input group */}
            {vocalContributorsArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={vocalOptions}
                  InputComponent={InputComponent}
                  contributorsArray={vocalContributorsArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddVocalContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="vocalcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.instrumentalcontributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.instrumentalcontributors"]}
            </ValidationTextBox>
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Writers
            </div>
            {/* contributors input group */}
            {writerContributorsArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={writersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={writerContributorsArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddWriterContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="writercontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.contributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.writing"]}
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
        {/* contributors other performers group */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Other performers
          </div>
            {/* contributors input group */}
            {contributorsArrayOtherPerformers.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={otherPerformersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayOtherPerformers}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddOtherContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="othercontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.instrumentalcontributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.instrumentalcontributors"]}
            </ValidationTextBox>
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Producten and engineering
            </div>
            {/* contributors input group */}
            {contributorsArrayEngineering.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={engineeringOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayEngineering}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddEngineeringContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="engineeringcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.contributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.engineering"]}
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
        {/* contributors other Instrumental group */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
              Instrumental performers
            </div>
            {/* contributors input group */}
            {contributorsArrayInstrumental.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={instrumentalOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayInstrumental}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddInstrumentalContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="instrumentalcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.instrumentalcontributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.instrumentalcontributors"]}
            </ValidationTextBox>
          </div>
           <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Administrative roles
            </div>
            {/* contributors input group */}
            {contributorsArrayAdminis.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={administrativeOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayAdminis}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddAdmisContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="adminiscontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={validation["#participants.contributors"]}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              {valMessages["#participants.contributors"]}
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />

        {/* <br className="mobile768visible" /> */}
        <div className="nrHr" />
        <br />
        {/* ...form */}
        <div className="nrPrimaryGenreWrapper">
          <div
            className={
              (!prepopulated ||
              (prepopulated && reduxForm["#primary_genre"].length === 0)
                ? "d-none"
                : "") + " inputUpperComment iuc3b playtreksSelector"
            }
          >
            Primary Genre*
          </div>
          <SelectComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.releaseForm?.["#primary_genre"]
            )}
            menuListCustomComponent={true}
            commentField=".iuc3b"
            selectChangeCallback={selectChangeCallback}
            paddingTopOnInput={true}
            placeholder="Primary genre*"
            groupId="genre"
            addon={false}
            databaseField="#primary_genre"
            options={genres}
            selectBlurCallback={selectBlurCallback}
            form={reduxForm}
          ></SelectComponent>
          <ValidationTextBox
            display={validation["#primary_genre"]}
            className="nrValTextBox"
            for="#primary_genre"
          >
            Please select the primary genre of your release
          </ValidationTextBox>
        </div>
        <br />
        <div className="nrSecondaryGenreWrapper">
          <div
            className={
              (!prepopulated ||
              (prepopulated && reduxForm["#secondary_genre"].length === 0)
                ? "d-none"
                : "") + " inputUpperComment iuc3c playtreksSelector"
            }
          >
            Secondary genre
          </div>
          <SelectComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.releaseForm?.["#secondary_genre"]
            )}
            commentField=".iuc3c"
            selectChangeCallback={selectChangeCallback}
            paddingTopOnInput={true}
            placeholder="Secondary genre"
            groupId="genre"
            addon={false}
            databaseField="#secondary_genre"
            options={genres}
            selectBlurCallback={selectBlurCallback}
            form={reduxForm}
          ></SelectComponent>
          <ValidationTextBox
            display={validation["#secondary_genre"]}
            className="nrValTextBox"
            for="#secondary_genre"
          >
            Primary and secondary genres cannot be the same
          </ValidationTextBox>
        </div>
        <div className="nrExpFieldsHint  mb-4">
          Release title language &nbsp;{" "}
          <img
            src={info}
            className="cursor-pointer"
            data-tip={tooltipData.language}
          />
        </div>
        <div className="nrLanguageWrapper">
          <div
            className={
              (!prepopulated ? "d-none" : "") +
              " inputUpperComment iuc3d playtreksSelector"
            }
          >
            Language*
          </div>
          <SelectComponent
            value={getLanguageLabel()}
            commentField=".iuc3d"
            selectChangeCallback={selectChangeCallback}
            paddingTopOnInput={true}
            placeholder="Language*"
            groupId="language"
            addon={false}
            databaseField="#language"
            options={languages}
            selectBlurCallback={selectBlurCallback}
            form={reduxForm}
          ></SelectComponent>
          <ValidationTextBox
            display={validation["#language"]}
            className="nrValTextBox nrValTextBox-absoluteImportant nrValTextBox-lang"
            for="#language"
          >
            Please select the language of your release
          </ValidationTextBox>
        </div>
        <div className="nrExplicitLyricsWrapper">
          <div
            className="nrExplicitLyricsText"
            onClick={handlePrSwitch}
            id="explicitLyrics"
            style={{ cursor: "pointer" }}
          >
            Explicit lyrics*
          </div>
          <div
            className="nrExplicitLyricsSwitch nrSwitch"
            onClick={handlePrSwitch}
            id="explicitLyrics"
          >
            <div
              className={
                "nrHandle " +
                (prSwitch.explicitLyrics ? "nrHandleOn" : "nrHandleOff")
              }
              id="explicitLyrics"
            ></div>
            {prSwitch.explicitLyrics ? (
              <div className="nrOn" id="explicitLyrics">
                Yes
              </div>
            ) : (
              <div className="nrOff" id="explicitLyrics">
                No
              </div>
            )}
          </div>
        </div>
        <br />
        <div className="nrReleaseUpcCodeWrapper">
          <div
            className={
              (!prepopulated ? "d-none" : "") + " inputUpperComment iuc4"
            }
          >
            Release UPC code*
          </div>
          <InputComponent
            value={useSelector(
              (state) => state.distributionReducer?.releaseForm?.["#upc"]
            )}
            disabled={checked.releaseUpcCodeCheckbox}
            paddingTopOnInput={true}
            commentField=".iuc4"
            placeholder="Release UPC code*"
            inputGroupClassName="nrInputGroup"
            inputClassName={
              (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
              " nrInput"
            }
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            databaseField="#upc"
            maxlength={13}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.upc}
          ></InputComponent>
          <span id="releaseUpcCodeCheckbox">
            <Checkbox
              className="nrReleaseUpcCodeCheckbox"
              checked={checked.releaseUpcCodeCheckbox}
              id="releaseUpcCodeCheckbox"
              onClick={handleCheckbox}
            />
            <div
              className="nrReleaseUpcCodeCheckboxText playtreksCheckboxText"
              id="releaseUpcCodeCheckbox"
              onClick={handleCheckbox}
            >
              I don’t have one, assign an UPC code to this release automatically
            </div>
          </span>
          <ValidationTextBox
            display={validation["#upc"]}
            className="ml-0 mb-2 position-static line-height-normal"
            for="#upc"
          >
            Please provide a 12 or 13 digit UPC code, or tick the box to
            generate a new code.
          </ValidationTextBox>
        </div>
        <div className="nrCatalogNumberWrapper">
          <div
            className={
              (!prepopulated ? "d-none" : "") + " inputUpperComment iuc5"
            }
          >
            Catalog number*
          </div>
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.releaseForm?.["#catalog_number"]
            )}
            disabled={checked.catalogNumberCheckbox}
            paddingTopOnInput={true}
            commentField=".iuc5"
            placeholder="Catalog number*"
            inputGroupClassName="nrInputGroup"
            inputClassName={
              (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
              " nrInput"
            }
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            databaseField="#catalog_number"
            maxlength={45}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.catalogNumber}
          ></InputComponent>
          <span id="catalogNumberCheckbox">
            <Checkbox
              className="nrCatalogNumberCheckbox"
              checked={checked.catalogNumberCheckbox}
              id="catalogNumberCheckbox"
              onClick={handleCheckbox}
            />
            <div
              className="nrCatalogNumberCheckboxText playtreksCheckboxText"
              id="catalogNumberCheckbox"
              onClick={handleCheckbox}
            >
              I don’t have one, assign a catalog number to this release
              automatically
            </div>
          </span>
          <ValidationTextBox
            display={validation["#catalog_number"]}
            className="nrValTextBoxLower nrValTextBoxLower-2"
            for="#catalog_number"
          >
            Please provide a catalog number or tick the box above
          </ValidationTextBox>
        </div>
        <br />
        <div
          className="nrLabelWrapper mt-2"
          onClick={
            isFieldLocked("#label")
              ? () => window.open("/artistDashboard/subscriptions", "_blank")
              : () => {}
          }
        >
          <div
            className={
              (!prepopulated ? "d-none" : "") + " inputUpperComment iuc5a"
            }
          >
            Label
          </div>
          <InputComponent
            value={useSelector(
              (state) => state.distributionReducer?.releaseForm?.["#label"]
            )}
            disabled={isFieldLocked("#label")}
            paddingTopOnInput={true}
            commentField=".iuc5a"
            placeholder="Label"
            inputGroupClassName="nrInputGroup"
            inputClassName={
              (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
              " nrInput"
            }
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            databaseField="#label"
            maxlength={45}
            // addon
            addon={
              reduxSubscriptionRules?.distributionLabelField?.callToAction
                ? "right"
                : false
            } // right?
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={
              reduxSubscriptionRules?.distributionLabelField?.callToAction
            }
            tooltipText={
              reduxSubscriptionRules?.distributionLabelField?.callToActionText
            }
          ></InputComponent>
        </div>
        <br />
        {/* license information */}
        <br />
        <div className="nrLicenseInformationHeader">License information</div>
        <br />
        <div className="nrReleaseDateWrapper">
          <div
            className={
              "inputUpperComment iuc6 " +
              (pickedDate["calendar1"] || prepopulated ? "" : "d-none")
            }
          >
            Release date*
          </div>
          {/* calendar input field blocker div */}
          {/* <div className="nrCalendarInputBlocker" /> */}
          <InputComponent
            disabled={true}
            value={useSelector(
              (state) =>
                state.distributionReducer?.releaseForm?.["#digital_release"]
            )} // take from calendar, or pre-populate
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["calendar1"] || prepopulated
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="Release date*"
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="calendar1"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            // info addon
            img={info}
            imgClass="nrAddonRightIcon mr-2"
            alt="info"
            tooltipText={tooltipData.digitalRelease}
            databaseField="#digital_release"
          />
          <ValidationTextBox
            display={validation["#digital_release"]}
            className="nrValTextBox"
            for="#digital_release"
          >
            {valMessages["#digital_release"]}
          </ValidationTextBox>
          <Calendar
            calendarState={calendarState["calendar1"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={datePickCallback}
            calendarId="calendar1"
            databaseField="#digital_release"
            minDate={addDays(new Date(), NEW_RELEASE_DATE_DAYS_BLOCKED)}
          />
        </div>
        <div
          className="nrPreviouslyReleasedText"
          onClick={handlePrSwitch}
          id="previouslyReleased"
          style={{ cursor: "pointer" }}
        >
          Was this album previously released?
        </div>
        <div
          className="nrPreviouslyReleasedSwitch nrSwitch"
          onClick={handlePrSwitch}
          id="previouslyReleased"
        >
          <div
            className={
              "nrHandle " +
              (prSwitch.previouslyReleased ? "nrHandleOn" : "nrHandleOff")
            }
            id="previouslyReleased"
          ></div>
          {prSwitch.previouslyReleased ? (
            <div className="nrOn" id="previouslyReleased">
              Yes
            </div>
          ) : (
            <div className="nrOff" id="previouslyReleased">
              No
            </div>
          )}
        </div>
        <div
          className={
            "nrPreviouslyReleasedDateWrapper " +
            (!prSwitch.previouslyReleased ? "d-none" : "")
          }
        >
          <div
            className={
              "inputUpperComment iuc6 " +
              (pickedDate["calendar2"] || prepopulated ? "" : "d-none")
            }
          >
            Previous release date*
          </div>
          {/* calendar input field blocker div */}
          {/* <div className="nrCalendarInputBlocker" /> */}
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.releaseForm?.["#original_release"]
            )} // pick from calendar, or prepopulate
            disabled={true}
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup nrInputGroup-opacityFix"
            inputClassName={
              "nrInput nrInput-opacityFix " +
              (pickedDate["calendar2"] || prepopulated
                ? "nrCalendarInputPopulated"
                : "")
            }
            commentField=".iuc6"
            placeholder="Previous release date*"
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            addonClickCallback={handleOpenCalendar}
            for="nrReleaseDate"
            id="calendar2"
            // calendar addon
            addon="right"
            calendarAddon={true}
            calImg={calendar}
            calImgClass="nrAddonRightIcon"
            calAlt="pick date"
            inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
            // info addon
            img={info}
            imgClass="nrAddonRightIcon mr-2"
            alt="info"
            tooltipText={tooltipData.originalRelease}
            databaseField="#original_release"
          />
          <ValidationTextBox
            display={validation["#original_release"]}
            className="nrValTextBox"
            for="#original_release"
          >
            {valMessages["#original_release"]}
          </ValidationTextBox>
          <Calendar
            calendarState={calendarState["calendar2"]}
            calendarClassName="nrReleaseDateCalendar"
            datePickCallback={datePickCallback}
            calendarId="calendar2"
            databaseField="#original_release"
            popperClassName="nrReleaseDateCalendar-popper2"
          />
        </div>
        <br />
        <div className="nrLicenseTypeWrapper">
          <div
            className={
              (!prepopulated ? "d-none" : "") +
              " inputUpperComment iuc6a playtreksSelector"
            }
          >
            License type*
          </div>
          <SelectComponent
            value={getLicenseTypeLabel()}
            commentField=".iuc6a"
            placeholder="License type*"
            selectBlurCallback={selectBlurCallback}
            groupId="licenseType"
            paddingTopOnInput={true}
            databaseField="#license_type"
            options={licenseTypes}
            selectChangeCallback={selectChangeCallback}
            form={reduxForm}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.licenseType}
          ></SelectComponent>
          <ValidationTextBox
            display={validation["#license_type"]}
            className="nrValTextBox nrValTextBox-absoluteImportant nrValTextBox-licenseType"
            for="#license_type"
          >
            Please provide the type of your license
          </ValidationTextBox>
        </div>

        {/* License info*/}
        <div
          className={
            reduxForm["#license_type"] === CREATIVE_COMMON_LICENSE_VALUE
              ? "nrLicenseInfoWrapper"
              : "d-none"
          }
        >
          <br />
          <div
            className={
              // (!prepopulated ? "d-none" : "") +
              " inputUpperComment iuc6b playtreksSelector"
            }
          >
            License Info*
          </div>
          <SelectComponent
            value={getLicenseInfoLabel()}
            commentField=".iuc6b"
            placeholder="License Info*"
            selectBlurCallback={selectBlurCallback}
            groupId="licenseInfo"
            paddingTopOnInput={true}
            databaseField="#license_info"
            options={licenseInfoTypes}
            selectChangeCallback={selectChangeCallback}
            form={reduxForm}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={true}
            extendOptionWidth={true}
            tooltipText={tooltipData.licenseInfoType}
          ></SelectComponent>
        </div>

        {/*  Copyright holder(s) of the composition */}
        <div className="CopyrightWrapper">
          <div
            className={
              reduxForm["#license_type"] === CREATIVE_COMMON_LICENSE_VALUE
                ? "nrCopyrightWrapper nrCopyrightWrapper-pushDown mb-4"
                : "nrCopyrightWrapper mb-4"
            }
          >
            <div
              className={
                (!prepopulated ? "d-none" : "") + " inputUpperComment iuc7"
              }
            >
              YYYY
            </div>
            <InputComponent
              value={useSelector(
                (state) => state.distributionReducer?.releaseForm?.["#c_year"]
              )}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup nrltLeft"
              inputClassName={
                (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
                " nrInput text-center"
              }
              commentField=".iuc7"
              addon={false}
              placeholder="YYYY"
              changeCallback={inputChangeCallback}
              inputBlurCallback={inputBlurCallback}
              databaseField="#c_year"
              maxlength={4}
            ></InputComponent>
            <ValidationTextBox
              display={
                validation["#c_year"] ||
                validation["#c_line"] ||
                (validation["#c_year"] && validation["#c_line"])
              }
              className="nrValTextBox nrValTextBoxHigher mb-2"
              for="#c_year, #c_line"
            >
              Please provide a valid year and the holder of your license
            </ValidationTextBox>
            <div
              className={
                (!prepopulated ? "d-none" : "") + " inputUpperComment iuc8"
              }
            >
              © Copyright holder(s) of the composition*
            </div>
            <InputComponent
              value={useSelector(
                (state) => state.distributionReducer?.releaseForm?.["#c_line"]
              )}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup nrltRight"
              inputClassName={
                (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
                " nrInput"
              }
              commentField=".iuc8"
              placeholder="© Copyright holder(s) of the composition*"
              changeCallback={inputChangeCallback}
              inputBlurCallback={inputBlurCallback}
              databaseField="#c_line"
              // addon
              addon="right"
              inputGroupTextClassName="nrRightAddon"
              img={info}
              imgClass="nrAddonRightIcon"
              alt="info"
              addonClickCallback={() => {}} // leave empty!
              // tooltip
              tooltip={true}
              tooltipText={tooltipData.licenseHolder}
            ></InputComponent>
          </div>

          {/* Owner license */}
          <div
            className={
              reduxForm["#license_type"] === CREATIVE_COMMON_LICENSE_VALUE
                ? "nrCopyrightWrapper nrCopyrightWrapper-pushDown"
                : "nrCopyrightWrapper"
            }
          >
            <div
              className={
                (!prepopulated ? "d-none" : "") + " inputUpperComment iuc9"
              }
            >
              YYYY
            </div>
            <InputComponent
              value={useSelector(
                (state) => state.distributionReducer?.releaseForm?.["#p_year"]
              )}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup nrcLeft"
              inputClassName={
                (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
                " nrInput text-center"
              }
              commentField=".iuc9"
              addon={false}
              placeholder="YYYY"
              changeCallback={inputChangeCallback}
              inputBlurCallback={inputBlurCallback}
              databaseField="#p_year"
              maxlength={4}
            ></InputComponent>
            <ValidationTextBox
              display={
                validation["#p_year"] ||
                validation["#p_line"] ||
                (validation["#p_year"] && validation["#p_line"])
              }
              className="nrValTextBox nrValTextBoxHigher mb-2"
              for="#p_year, #p_line"
            >
              Please provide a valid year and the holder of your copyright
            </ValidationTextBox>
            <div
              className={
                (!prepopulated ? "d-none" : "") + " inputUpperComment iuc10"
              }
            >
              ℗ Owner(s) or licensee(s) of the master recording*
            </div>
            <InputComponent
              value={useSelector(
                (state) => state.distributionReducer?.releaseForm?.["#p_line"]
              )}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup nrcRight"
              inputClassName={
                (prepopulated ? "interMediumInput interMediumPaddingTop" : "") +
                " nrInput"
              }
              commentField=".iuc10"
              placeholder="℗ Owner(s) or licensee(s) of the master recording*"
              changeCallback={inputChangeCallback}
              inputBlurCallback={inputBlurCallback}
              databaseField="#p_line"
              inputGroupTextClassName="nrRightAddon"
              img={info}
              imgClass="nrAddonRightIcon"
              alt="info"
              // addon
              addon="right"
              addonClickCallback={() => {}} // leave empty!
              // tooltip
              tooltip={true}
              tooltipText={tooltipData.copyright}
            ></InputComponent>
          </div>
        </div>

        <p className="text-muted support-article ">
          For more information and examples of (p) and (c) lines, please read
          our{" "}
          <a
            href="https://support.playtreks.com/articles/what-is-difference-p-line-and-c-line-in-music-copyright"
            target="#"
          >
            support article
          </a>
        </p>

        <div style={{ color: "white", lineHeight: "20px", marginTop: "50px" }}>
          In order to speed up the release process, please provide links to your
          artist social media profiles. <br /> Ex. Instagram page, Facebook
          Artist Page, Soundcloud etc. <br />
          <br />{" "}
          {/* {socialPrepop && (
            <small>
              We have already filled some of them in basing on your connected
              platforms.
            </small>
          )} */}
          <ValidationTextBox
            display={validation["socialMediaLinks"]}
            className="nrValTextBox"
            for="socialMediaLink1"
          >
            You must provide at least one link to one of your profile.
          </ValidationTextBox>
        </div>
        <InputComponent
          value={useSelector(
            (state) => state.distributionReducer?.releaseForm?.socialMediaLink1
          )}
          inputGroupClassName="nrInputGroup mt-3"
          inputClassName={
            "nrInput nrInput-thin " +
            (reduxForm?.socialMediaLink1 ? "nrInput-populated" : "")
          }
          addon={false}
          inputBlurCallback={() => {}}
          changeCallback={inputChangeCallback}
          placeholder="Social media link 1"
          databaseField="socialMediaLink1"
        />
        <InputComponent
          value={useSelector(
            (state) => state.distributionReducer?.releaseForm?.socialMediaLink2
          )}
          inputGroupClassName="nrInputGroup mt-2"
          inputClassName={
            "nrInput nrInput-thin " +
            (reduxForm?.socialMediaLink2 ? "nrInput-populated" : "")
          }
          addon={false}
          inputBlurCallback={() => {}}
          changeCallback={inputChangeCallback}
          placeholder="Social media link 2"
          databaseField="socialMediaLink2"
        />
        <InputComponent
          value={useSelector(
            (state) => state.distributionReducer?.releaseForm?.socialMediaLink3
          )}
          inputGroupClassName="nrInputGroup mt-2"
          inputClassName="nrInput nrInput-thin"
          addon={false}
          inputBlurCallback={() => {}}
          changeCallback={inputChangeCallback}
          placeholder="Social media link 3"
          databaseField="socialMediaLink3"
        />
        <br />
        <div className={!endError ? "d-none" : "nrBottomFeedback"}>
          Please review the form and try again
        </div>
        {!isMatching && userSlotsRemaining <= 0 && (
          <div className="nrBottomFeedback-warning">
            This release creates a new artist slot. Please{" "}
            <a href="/artistDashboard/subscriptions" target="_blank">
              <u>upgrade your plan</u>
            </a>{" "}
            in order to submit this release. You may still save this release as
            draft.
          </div>
        )}
        {/*  {!isMatching && userSlotsRemaining > 0 && (
          <div className="nrBottomFeedback-warning">
            This release creates a new artist slot. You have{" "}
            {userSlotsRemaining} slots remaining.{" "}
            <a href="/artistDashboard/subscriptions" target="_blank">
              <u>Upgrade now</u>
            </a>{" "}
            for more slots.
          </div>
        )} */}
        <div className="nrSaveAndUploadTracksButton" onClick={handleSubmit}>
          {!submitLoading ? (
            "Save and upload tracks"
          ) : (
            <div>
              {submitLoading !== "done" ? (
                <div>
                  Uploading cover art... &nbsp;&nbsp;
                  <img
                    alt="loading"
                    src={spinner}
                    style={{ width: "30px", height: "30px" }}
                  />
                </div>
              ) : (
                <div>Done! Redirecting...</div>
              )}
            </div>
          )}
        </div>
        <div className="nrTermsAndConditionsText">
          By clicking you agree with{" "}
          <a href="/terms" target="_blank">
            <u>Terms & Conditions</u>
          </a>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
}

export default DistributionBasicInformation;
