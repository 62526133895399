import React from "react";
import Switch from "../../form/SwitchComponent";
import { handleExternalLogin } from "../../onboarding/login/ExternalLogin";
import { capitalizeFirstLetter, SOCIAL_PLATFORM } from "../../utils";
import spinner from "../../../assets/spinner.svg";

function SocialShareComponent({
  active,
  service,
  serviceImg,
  userPersonalService, // the private page of the user
  userPersonalServiceImg,
  handleSocialMediaCheckbox,
  userSocialProfiles,
  checkedSocialMedia,
}) {
  function handleSwitch(platform, profileId) {
    if (active) handleSocialMediaCheckbox(platform, profileId);
  }

  function handleConnect() {
    window.localStorage.setItem(
      "locationBeforeExternalLogin",
      window.location.pathname
    );
    let connectService =
      service === SOCIAL_PLATFORM.INSTAGRAM
        ? SOCIAL_PLATFORM.FACEBOOK
        : service;
    handleExternalLogin(connectService);
  }

  let socialProfilesLoading = Object.values(userSocialProfiles).every(
    (item) => !item.length
  );

  const isTwitter = service === SOCIAL_PLATFORM.TWITTER;

  // loading state spinner
  if (socialProfilesLoading && active === undefined)
    return (
      <div className="rfSocialsCheckboxes-section-loading">
        <div className="rfSocialsCheckboxes-section-label">
          {capitalizeFirstLetter(service)}
        </div>
        <img src={spinner} />
      </div>
    );

  return (
    <div className="rfSocialsCheckboxes-section">
      <div className="rfSocialsCheckboxes-section-label">
        {capitalizeFirstLetter(service)}
      </div>
      <div
        onClick={() => handleSwitch(userPersonalService)}
        className={
          isTwitter || active === false // because fb and ig doesn't allow posting on public page
            ? "rfSocialsCheckboxes-section-item"
            : "d-none"
        }
      >
        <div className="rfSocialsCheckboxes-section-item-img">
          <img src={userPersonalServiceImg || serviceImg} />
        </div>

        {active ? (
          <div className="rfSocialsCheckboxes-section-item-text">
            {isTwitter && userSocialProfiles?.twitter?.username}{" "}
            {capitalizeFirstLetter(service)} profile
          </div>
        ) : (
          <div className="rfSocialsCheckboxes-section-item-text-inactive">
            <div>{capitalizeFirstLetter(service)} profile not connected</div>
            <div
              className="rfSocialsCheckboxes-section-item-text-connectNow"
              onClick={handleConnect}
            >
              Connect now
            </div>
          </div>
        )}
        <Switch
          onClick={() => handleSwitch(userPersonalService)}
          switchState={checkedSocialMedia[userPersonalService]}
          inactive={!active}
        />
      </div>
      {!isTwitter &&
        userSocialProfiles?.[service].map((profile) => {
          const profilePicture = () => {
            switch (service) {
              case SOCIAL_PLATFORM.FACEBOOK:
                return profile.picture.data.url;
              case SOCIAL_PLATFORM.INSTAGRAM:
                return profile.profile_picture_url;
              default:
                return serviceImg;
            }
          };
          return (
            <div
              onClick={() => handleSwitch(service, profile.id)}
              className="rfSocialsCheckboxes-section-item"
            >
              <div className="rfSocialsCheckboxes-section-item-img">
                <img src={profilePicture()} />
              </div>
              <div className="rfSocialsCheckboxes-section-item-text">
                {profile.name} {capitalizeFirstLetter(service)}
              </div>
              <Switch
                onClick={() => handleSwitch(service, profile.id)}
                switchState={checkedSocialMedia[service][profile.id]}
              />
            </div>
          );
        })}
    </div>
  );
}

export default SocialShareComponent;
