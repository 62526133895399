import axios from "axios";
import { getJWT } from "../components/utils";
import jwtDecode from "jwt-decode";

const axiosInstance = axios.create();

const endpoints = {
  createContact: "/contacts",
  updateContact: "/contacts",
  deleteContact: "/contacts",
  getContacts: "/contacts",
};

export async function createContact(payload) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.createContact}`;
  return axiosInstance.post(url, payload, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function updateContact({ contactId, ...payload }) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.updateContact}/${contactId}`;
  return axiosInstance.put(
    url,
    { ...payload },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
  );
}

export async function getContacts() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getContacts}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function deleteContact(contactId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.deleteContact}/${contactId}`;
  return axiosInstance.delete(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}
