import SpotifyColorIcon from "../../../assets/icons/spotify-color.svg";
import AppleColorIcon from "../../../assets/icons/apple-color.svg";

/**
 *
 * @property {String} name - name displayed on card and as icon alt
 * @property {Sting} platform - the platform concerned
 * @property {String} objectPath - path to the cards data
 * @property {Object} icon - icon object (needs to be imported on top of this file)
 */
export default [
  {
    name: "spotify popularity",
    platform: "spotify",
    objectPath: "popularity",
    icon: SpotifyColorIcon,
  },
  {
    name: "spotify followers",
    platform: "spotify",
    objectPath: "followers",
    icon: SpotifyColorIcon,
  },
  {
    name: "spotify playlists",
    platform: "spotify",
    objectPath: "spotify_playlists.all_spotify_playlists_count",
    icon: SpotifyColorIcon,
  },
  {
    name: "spotify playlists reach",
    platform: "spotify",
    objectPath: "spotify_playlists.all_spotify_playlists_reach",
    icon: SpotifyColorIcon,
  },
  {
    name: "editorial spotify playlists",
    platform: "spotify",
    objectPath: "spotify_playlists.editorial_spotify_playlists_count",
    icon: SpotifyColorIcon,
  },
  {
    name: "user spotify playlists",
    platform: "spotify",
    objectPath: "spotify_playlists.user_spotify_playlists_count",
    icon: SpotifyColorIcon,
  },
  {
    name: "apple playlists",
    platform: "apple",
    objectPath: "apple_playlists.all_apple_playlist_count",
    icon: AppleColorIcon,
  },
  {
    name: "editorial apple playlists",
    platform: "apple",
    objectPath: "apple_playlists.editorial_apple_playlist_count",
    icon: AppleColorIcon,
  },
  {
    name: "user apple playlists",
    platform: "apple",
    objectPath: "apple_playlists.external_apple_playlist_count",
    icon: AppleColorIcon,
  },
];
