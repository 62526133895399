// // DESKTOP EXPANDABLES LOGIC

import { contributorsOptions } from "../data/distrFieldsData";

export function desktopExpandablesLogic(
  window,
  extraRows,
  setExtraRows,
  artistsArray,
  contributorsArray
) {
  if (window.innerWidth > 768) {
    // panel expanding logic (desktop)
    if (
      extraRows.length <= artistsArray.length - 2 ||
      extraRows.length <= contributorsArray.length - 2
    ) {
      setExtraRows([...extraRows, 1]);
    }
    // panel contracting logic
    if (
      extraRows.length > artistsArray.length - 1 &&
      extraRows.length > contributorsArray.length - 1
    ) {
      var newExtraRowsLength =
        Math.max(artistsArray.length, contributorsArray.length) - 1;
      var newExtraRows = [];
      for (let i = 0; i < newExtraRowsLength; i++) {
        newExtraRows.push(1);
      }
      setExtraRows(newExtraRows);
    }
  }
}

// // // //

// MOBILE EXPANDABLES LOGIC

export function contributorsMobileLogic(
  window,
  extraRows,
  setExtraRows,
  contributorsArray
) {
  if (window.innerWidth <= 768) {
    // expanding
    if (extraRows.length <= contributorsArray.length - 2) {
      setExtraRows([...extraRows, 1]);
    }

    // contracting
    if (extraRows.length > contributorsArray.length - 1) {
      var newExtraRowsLength = contributorsArray.length - 1;
      var newExtraRows = [];
      for (let i = 0; i < newExtraRowsLength; i++) {
        newExtraRows.push(1);
      }
      setExtraRows(newExtraRows);
    }
  }
}

export function mobileExpandHelper(
  direction,
  initNum = 88,
  mobileExtFieldPushDown,
  setMobileExtFieldPushDown
) {
  // direction true - adding, false - subtracting
  let num = direction ? initNum : -initNum;
  document.querySelector(".nrContributorsOuterWrapper").style.marginTop =
    mobileExtFieldPushDown + num + "px";
  // state update for remembering the last value
  setMobileExtFieldPushDown(mobileExtFieldPushDown + num);
}

// // // // //
