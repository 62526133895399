import {
  deltaArtistStats,
  entityDataSearch,
  entityStatsOvertime,
  playlistSearch,
  webProfilesSearch,
} from "../../routes/search";
import { getUserData } from "../../routes/users";
import { getSuggestedArtists } from "../../routes/artist";
import { getFeaturedEntity, getTrackFeatures } from "../../routes/track";
import { REDUX_ADD_ALERT } from "./appActions";

export const PUSH_TO_ENTITY_DATA_HISTORY = "PUSH_TO_ENTITY_DATA_HISTORY";

export const REPLACE_ENTITY_DATA_IN_HISTORY = "REPLACE_ENTITY_DATA_IN_HISTORY";
export const REDUX_SET_ENTITY_DELTA_STATS = "REDUX_SET_ENTITY_DELTA_STATS";

export const REDUX_SET_RADIO_MONITORING_DATA =
  "REDUX_SET_RADIO_MONITORING_DATA";
export const REDUX_SET_RADIO_AGGREGATION_DATA =
  "REDUX_SET_RADIO_AGGREGATION_DATA";

export const REDUX_SET_ENTITY_STATS_OVERTIME =
  "REDUX_SET_ENTITY_STATS_OVERTIME";
export const REDUX_SET_WEB_PROFILES = "REDUX_SET_WEB_PROFILES";
export const REDUX_SET_SUGGESTED_ARTISTS = "REDUX_SET_SUGGESTED_ARTISTS";
export const REDUX_SET_TRACK_FEATURES = "REDUX_SET_TRACK_FEATURES";

export const LOAD_PLAYLISTS = "LOAD_PLAYLISTS";

export const REDUX_SET_USER_DATA = "REDUX_SET_USER_DATA";

export const PLAYLISTS_NEXT_PAGE = "PLAYLISTS_NEXT_PAGE";
export const PLAYLISTS_PREV_PAGE = "PLAYLISTS_PREV_PAGE";
export const PLAYLISTS_RESET_OFFSET = "PLAYLISTS_RESET_OFFSET";

export const REDUX_SET_PLAYLISTS_DISPLAY_PLATFORM =
  "REDUX_SET_PLAYLISTS_DISPLAY_PLATFORM";

export const REDUX_SET_USER_AS_MAIN_ARTIST = "REDUX_SET_USER_AS_MAIN_ARTIST";
export const PIN_MAIN_ENTITY = "PIN_MAIN_ENTITY";
export const UNPIN_MAIN_ENTITY = "UNPIN_MAIN_ENTITY";
export const TOGGLE_MAIN_ENTITY = "TOGGLE_MAIN_ENTITY";

export const REMOVE_ENTITY_FROM_HISTORY = "REMOVE_ENTITY_FROM_HISTORY";
export const REDUX_SET_MAIN_ARTIST_FROM_CHIP =
  "REDUX_SET_MAIN_ARTIST_FROM_CHIP";

export const REDUX_SET_STATS_CHART_GROUP_NAME =
  "REDUX_SET_STATS_CHART_GROUP_NAME";

export const PUSH_TO_USER_DATA = "PUSH_TO_USER_DATA";

export const REDUX_SET_SEARCH_FILTERS = "REDUX_SET_SEARCH_FILTERS";
export const REDUX_RESET_SEARCH_FILTERS = "REDUX_RESET_SEARCH_FILTERS";
export const REDUX_SET_SEARCH_CACHING = "REDUX_SET_SEARCH_CACHING";

export const REDUX_SET_WELCOME_PAGE_DATA = "REDUX_SET_WELCOME_PAGE_DATA";

export const REDUX_SET_SUBSCRIPTION_PLAN = "REDUX_SET_SUBSCRIPTION_PLAN";

export const POP_ENTITY = "POP_ENTITY";

///

export const HISTORY = "history";
export const USER = "user";

const ARTIST = "artist";
const TRACK = "track";
const ALBUM = "album";
const LABEL = "label";

export function pushToEntityData({ type, id, name }) {
  window.scrollTo(0, 0); // always scroll to top on new entity
  return async (dispatch, getState) => {
    if (
      getState().subscriptionReducer?.subscription?.rules
        ?.dashboardEntityDataHistory?.entityAmount ===
      getState().dashboardReducer?.entityDataHistory?.length
    ) {
      dispatch({
        type: POP_ENTITY,
      });
      dispatch({
        type: REDUX_ADD_ALERT,
        text: getState().subscriptionReducer?.subscription?.rules
          ?.dashboardEntityDataHistory?.callToActionText,
        link: "/artistDashboard/subscriptions",
      });
    }

    // 1. Update the UI state and the box on top immediately
    if ([ARTIST, TRACK, ALBUM, LABEL].includes(type)) {
      var target = HISTORY;
      var playlistSearchType = type;

      dispatch({
        type: PUSH_TO_ENTITY_DATA_HISTORY,
        singleEntityData: {
          type: type,
          spotifyId: id,
          name: name,
        },
      });

      try {
        // 2. Fetch more metadata about the selected entity
        const entityDataResponse = await entityDataSearch(type, id);
        dispatch({
          type: REPLACE_ENTITY_DATA_IN_HISTORY,
          singleEntityData: {
            ...entityDataResponse.data,
            spotifyId: entityDataResponse.data.id,
          },
        });
      } catch (e) {
        // TODO: dispatch some error action?
        console.log(e);
      }
    } else if ([USER].includes(type)) {
      target = USER;
      playlistSearchType = ARTIST;
      var selectedSpotifyID =
        getState()?.dashboardReducer?.userData?.service_profiles
          ?.selectedSpotifyID;

      // Little hack to get stats over time if user has selected spotify ID
      id = selectedSpotifyID;
      dispatch({ type: PUSH_TO_USER_DATA });
    } else return;

    // if ([ARTIST, ALBUM, USER, TRACK].includes(type)) {
    //   // 3. Fetch data that is displayed in the cards
    //   // TODO: enable this for track and album
    //   try {
    //     // This backend call is created so that if we pass undefined as id, it assumes
    //     // it is user data.
    //     const queryID = type === USER ? undefined : id;
    //     const deltaStatsResponse = await deltaArtistStats(queryID, "", "", {
    //       type: type,
    //     });
    //     dispatch({
    //       type: REDUX_SET_ENTITY_DELTA_STATS,
    //       singleEntityDeltaStats: {
    //         ...deltaStatsResponse.data,
    //         id: deltaStatsResponse.data.id,
    //       },
    //       target: target,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // 4. Fetch data that is displayed in the line charts
    // TODO: enable this for track and album
    // if ([ARTIST, USER, ALBUM].includes(type) && id) {
    //   try {
    //     console.log("LOOOOl");
    //     const statsOvertimeResponse = await entityStatsOvertime(id, {
    //       type: type,
    //     });
    //     dispatch({
    //       type: REDUX_SET_ENTITY_STATS_OVERTIME,
    //       singleEntityStatsOvertime: statsOvertimeResponse?.data,
    //       target: target,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // 5. Fetch all the social/streaming webprofiles of the entity
    // if ([ARTIST, USER].includes(type)) {
    //   try {
    //     var webProfilesResponse = await webProfilesSearch(id);
    //     dispatch({
    //       type: REDUX_SET_WEB_PROFILES,
    //       webProfiles: webProfilesResponse?.data,
    //       target: target,
    //       id: id,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // 6. Prefetch 20 playlists data so it is available (could be even before it is displayed)

    // 6.1 Get spotify playlists
    // const emptyPlaylistsAction = {
    //   type: LOAD_PLAYLISTS,
    //   size: 0,
    //   offset: 0,
    //   entityType: type,
    //   id: id,
    //   playlists: [],
    //   totalPlaylists: 0,
    //   target: target,
    // };
    // if (id)
    //   dispatch(
    //     reduxSetPlaylists({
    //       size: 2 * global.config.playlistsPerPage,
    //       offset: 0,
    //       type: playlistSearchType,
    //       searchId: id,
    //       reduxId: id,
    //       platform: "spotify",
    //       target: target,
    //     })
    //   );
    // else {
    //   dispatch({
    //     ...emptyPlaylistsAction,
    //     platform: "spotify",
    //   });
    // }

    // For artists and users we need their itunesID, however for labels we do
    // a regular text search with the name as the search criterion.
    // For tracks we send the spotify id and the BE requests the isrc and
    // performs the search
    // const searchCriterion =
    //   type === LABEL
    //     ? name
    //     : type === TRACK
    //     ? id
    //     : webProfilesResponse?.data?.itunesID;

    // 6.2 Get apple playlists for artists, users and label
    // if ([ARTIST, USER, LABEL, TRACK].includes(type) && id && searchCriterion) {
    //   try {
    //     dispatch(
    //       reduxSetPlaylists({
    //         size: 2 * global.config.playlistsPerPage,
    //         offset: 0,
    //         type: playlistSearchType,
    //         searchId: searchCriterion,
    //         reduxId: id,
    //         platform: "apple",
    //         target: target,
    //       })
    //     );
    //   } catch (e) {
    //     console.log(e);
    //   }
    // } else {
    //   dispatch({
    //     ...emptyPlaylistsAction,
    //     platform: "apple",
    //   });
    // }
    // 7. Get the suggested artists
    // TODO: Enable all entities in CU #en2mjx
    // if ([ARTIST, USER, TRACK, ALBUM, LABEL].includes(type)) {
    //   try {
    //     let queryID = id;
    //     if (type === TRACK || type === ALBUM) {
    //       // returns suggested on the base of the first artist
    //       queryID = getState().dashboardReducer?.mainEntity?.artists?.[0]?.id;
    //     } else if (type === LABEL) {
    //       // returns base artist on the basis of first in the playlists
    //       // will return random if no playlists for label
    //       queryID = name;
    //     }
    //     const suggestedArtistsResponse = await getSuggestedArtists(queryID, {
    //       type: type,
    //     });
    //     dispatch({
    //       type: REDUX_SET_SUGGESTED_ARTISTS,
    //       suggestedArtists: suggestedArtistsResponse?.data?.artists,
    //       target: target,
    //       id: id,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    // 8. Get the spotify features of the selected entity
    // TODO: enable features for all types
    // if ([TRACK, ALBUM].includes(type)) {
    //   try {
    //     const trackFeaturesResponse = await getTrackFeatures(id, {
    //       type: type,
    //     });

    //     dispatch({
    //       type: REDUX_SET_TRACK_FEATURES,
    //       trackFeatures: trackFeaturesResponse?.data?.audio_features,
    //       trackFeaturesAggregations: trackFeaturesResponse?.data?.aggregations,
    //       id: id,
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // }
  };
}

// export function reduxSetPlaylists({
//   size,
//   offset,
//   type,
//   searchId,
//   reduxId,
//   platform,
//   target,
// }) {
//   return async (dispatch, getState) => {
//     try {
//       // TODO: do not make the request if not necessary, i.e. requested playlists already exist

//       // const key = platform + "Playlists";
//       // if (getState().dashboardReducer.mainEntity[key].length >= size + offset)
//       //   return;

//       const playlistsResponse = await playlistSearch(
//         size,
//         offset,
//         type,
//         searchId,
//         platform
//       );

//       dispatch({
//         type: LOAD_PLAYLISTS,
//         size: size,
//         offset: offset,
//         entityType: type,
//         id: reduxId,
//         platform: platform,
//         playlists: playlistsResponse?.data?.hits?.hits,
//         totalPlaylists: playlistsResponse?.data?.hits?.total?.value,
//         target: target,
//       });
//     } catch (e) {
//       console.log(e);
//     }
//   };
// }

export function reduxSetUserData() {
  return async (dispatch, getState) => {
    const userDataResponse = await getUserData();
    dispatch({
      type: REDUX_SET_USER_DATA,
      userData: userDataResponse.data,
    });

    dispatch(pushToEntityData({ type: USER }));

    if (
      !getState().dashboardReducer.mainEntity ||
      getState().dashboardReducer.mainEntity.type === "user"
    )
      dispatch(reduxSetUserAsMainArtist());
  };
}

// export function playlistsNextPage() {
//   return async (dispatch, getState) => {
//     let { playlistsOffset, mainEntity, playlistsDisplayPlatform } =
//       getState().dashboardReducer;
//     dispatch({
//       type: PLAYLISTS_NEXT_PAGE,
//     });
//     dispatch(
//       reduxSetPlaylists({
//         size: global.config.playlistsPerPage,
//         offset: playlistsOffset + 2 * global.config.playlistsPerPage,
//         type: mainEntity.type === "user" ? "artist" : mainEntity.type,
//         searchId:
//           mainEntity.type === "label"
//             ? mainEntity.name
//             : playlistsDisplayPlatform == "spotify"
//             ? mainEntity.type === "user"
//               ? mainEntity?.service_profiles?.selectedSpotifyID
//               : mainEntity?.id
//             : mainEntity.webProfiles?.itunesID, // if apple
//         reduxId: mainEntity.id,
//         platform: playlistsDisplayPlatform,
//         target: mainEntity.type === "user" ? USER : HISTORY,
//       })
//     );
//   };
// }
//

export function playlistsPrevPage() {
  return {
    type: PLAYLISTS_PREV_PAGE,
  };
}

export function playlistsResetOffset() {
  return {
    type: PLAYLISTS_RESET_OFFSET,
  };
}

export function reduxSetPlaylistsDisplayPlatform(platform) {
  return {
    type: REDUX_SET_PLAYLISTS_DISPLAY_PLATFORM,
    platform: platform,
  };
}

export function reduxSetUserAsMainArtist() {
  return {
    type: REDUX_SET_USER_AS_MAIN_ARTIST,
  };
}

export function pinMainEntity() {
  return { type: PIN_MAIN_ENTITY };
}

export function unpinMainEntity() {
  return { type: UNPIN_MAIN_ENTITY };
}

export function removeEntityFromHistory(spotifyId) {
  return { type: REMOVE_ENTITY_FROM_HISTORY, spotifyId: spotifyId };
}

export function toggleMainEntity() {
  return { type: TOGGLE_MAIN_ENTITY };
}

export function reduxSetMainArtistFromChip(singleEntityData) {
  return {
    type: REDUX_SET_MAIN_ARTIST_FROM_CHIP,
    singleEntityData: singleEntityData,
  };
}

export function reduxSetStatsChartGroupName({ groupName, state }) {
  return {
    type: REDUX_SET_STATS_CHART_GROUP_NAME,
    groupName: groupName,
    state: state,
  };
}

export function reduxSetSearchFilters(searchFilters) {
  return {
    type: REDUX_SET_SEARCH_FILTERS,
    searchFilters: searchFilters,
  };
}

export function reduxResetSearchFilters() {
  return {
    type: REDUX_RESET_SEARCH_FILTERS,
  };
}

export function reduxSetSearchCaching(searchCaching) {
  return {
    type: REDUX_SET_SEARCH_CACHING,
    searchCaching: searchCaching,
  };
}

export function reduxSetWelcomePageData() {
  return async (dispatch, getState) => {
    try {
      const trackFeaturesResponse = await getFeaturedEntity();
      dispatch({
        type: REDUX_SET_WELCOME_PAGE_DATA,
        name: trackFeaturesResponse?.data?.name,
        trackFeatures: trackFeaturesResponse?.data?.audio_features,
        trackFeaturesAggregations: trackFeaturesResponse?.data?.aggregations,
        images: trackFeaturesResponse?.data?.images,
        external_urls: trackFeaturesResponse?.data?.external_urls,
        entityType: trackFeaturesResponse?.data?.type,
        statsOvertime: trackFeaturesResponse?.data?.statsOvertime,
      });
    } catch (e) {
      console.log(e);
    }
  };
}
