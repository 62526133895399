import React, { useState } from "react";
import X from "../../../components/distribution/assets/X.svg";
import ButtonComponent from "../../../components/form/ButtonComponent";
import Toast from "../../../components/Toast";
import { resendCollaboratorMail } from "../../../services/distribution";
import { Spinner } from "../../Airplay/Airplay";

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const ConfirmResendCollaboratorMailModal = ({
  onCloseRequest,
  trackData,
  rightholderToResendMail,
  trackIndex,
  setIsOpenConfirmMailModal,
  clearSelectedCheckboxes,
  releaseId,
}) => {
  const handleOnClose = () => onCloseRequest?.();

  const [toast, setToast] = useState(initialToastState);

  const [isLoading, isSetLoading] = useState(false);

  const title =
    trackData["#track_title"] +
    (trackData["#remix_or_version"]
      ? ` (${trackData["#remix_or_version"]})`
      : "");

  const payload = {
    releaseId,
    trackIndex,
    rightholderToResendMail,
    title,
  };

  const handleResendCollaboratorMail = async () => {
    try {
      isSetLoading(true);
      await resendCollaboratorMail(payload);

      isSetLoading(false);

      setToast({
        open: true,
        message: "Mail has been successfully sent!",
        type: "success",
      });
      clearSelectedCheckboxes();
      setTimeout(() => {
        setIsOpenConfirmMailModal(false);
      }, 1500);
    } catch (e) {
      console.log(e);
      setToast({
        type: "danger",
        open: true,
        message: "Something went wrong. Please try again!",
      });
      isSetLoading(false);
      setTimeout(() => {
        setIsOpenConfirmMailModal(false);
      }, 1500);
    }
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="playtreksModal-modal row justify-content-center">
        <img
          src={X}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={handleOnClose}
        />
        <p>
          {`This will send an email to all selected rights holders, confirming their royalty shares for the track `}
          <span className="prrcText">
            {trackData["#track_title"]}{" "}
            {trackData["#remix_or_version"]
              ? ` (${trackData["#remix_or_version"]})`
              : ""}
          </span>
          .
          <br />
        </p>

        <div className="row  mx-0 px-3 justify-content-center">
          <ButtonComponent
            buttonWrapperClassName="pr-3"
            className="playtreksButton w-100 "
            onClick={handleResendCollaboratorMail}
          >
            {isLoading ? (
              <>
                <span className="mr-2">Sending... </span>
                <span>
                  <Spinner />
                </span>
              </>
            ) : (
              "Confirm and send"
            )}
          </ButtonComponent>
          <ButtonComponent
            buttonWrapperClassName="pr-3"
            className="playtreksButton bg-transparent textLink w-100"
            onClick={handleOnClose}
          >
            Close
          </ButtonComponent>

          <Toast
            open={toast.open}
            onClose={() =>
              setToast((prevState) => ({ open: false, type: prevState.type }))
            }
            toastMessage={toast.message}
            toastType={toast.type}
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmResendCollaboratorMailModal;
