import React, { useState } from "react";
import ButtonComponent from "../form/ButtonComponent";
import X from "../../components/distribution/assets/X.svg";
import VerifyOTPModal from "./VerifyOTPModal";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import ValidationTextBox from "../form/ValidationTextBox";
import { changePhone, sendSMSOTP } from "../../services/auth";

const ChangePhoneModal = ({
  onCloseRequest,
  setToast,
  refetch2FAState,
  twoFAState,
  reCaptchaRef,
}) => {
  const [touched, setTouched] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [page, setPage] = useState(0);
  const [countryCode, setCountryCode] = useState(0);

  const handlePhoneChange = (value) => {
    setNewPhone(value);
    if (touched) validate();
  };

  const handlePhoneBlur = () => {
    validate();
    setTouched(true);
  };

  const validate = () => {
    let isError = false;
    if (!Number(newPhone)) {
      isError = true;
      setPhoneError("Invalid number");
    } else if (newPhone && !isPossiblePhoneNumber(newPhone)) {
      isError = true;
      setPhoneError("Invalid number");
    } else {
      isError = false;
      setPhoneError("");
    }
    return isError;
  };

  const handleSetupNew2FASMSPhone = async () => {
    if (validate()) return;

    if (twoFAState?.phone === newPhone) {
      setToast({
        open: true,
        type: "danger",
        message: `${newPhone} had already been verified`,
      });
      return;
    }

    try {
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();

      const res = await sendSMSOTP(newPhone, countryCode, token);

      if (res.data.success) {
        setToast({
          open: true,
          type: "success",
          message: `Verication code has been sent to ${newPhone}. Please check and enter your code to disable 2FA by SMS.`,
        });

        setPage(1);
      }
    } catch (error) {
      reCaptchaRef.current.reset();
      setToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occurred. Please try again.",
        type: "danger",
      });
    }
  };

  const handleVerifyNew2FASMSSetup = async (otp) => {
    try {
      const verified = await changePhone(otp, newPhone, countryCode);
      if (verified) {
        setTimeout(() => {
          refetch2FAState();

          onCloseRequest();
        }, 1000);

        setToast({
          open: true,
          type: "success",
          message: "Phone number has been changed successfully",
        });
      }
    } catch (error) {
      setToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occurred.",
        type: "danger",
      });
    }
  };

  const handleResendOTP = () => handleSetupNew2FASMSPhone();

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />
        {page === 0 ? (
          <>
            <div className=" mb-4">
              <div>Change phone</div>
            </div>
            <div className="mt-4 infoText text-muted mb-2">
              Please enter your new phone number and click continue.
            </div>

            <div className="mb-4">
              <div class="position-relative col px-0 verify-modal">
                <div className="inputUpperComment phone-label">
                  Phone number*
                </div>
                <PhoneInput
                  name="phone"
                  placeholder="Phone number"
                  limitMaxLength
                  // defaultCountry="BE"
                  international
                  value={newPhone}
                  numberInputProps={{
                    style: { backgroundColor: "#343947" },
                  }}
                  onCountryChange={setCountryCode}
                  onBlur={handlePhoneBlur}
                  onChange={handlePhoneChange}
                />
                <ValidationTextBox
                  className="loginValidationBox position-static ml-0"
                  display={phoneError?.length}
                >
                  {phoneError}
                </ValidationTextBox>
              </div>
            </div>
            <div className="mb-4 text-muted">
              <p className="infoText">
                Playtreks will only use this number for account security.
                <br />
                Message and data rates may apply.
              </p>
            </div>

            <div className="row mt-6 justify-content-end ml-0 align-items-center">
              <ButtonComponent
                buttonWrapperClassName="pr-3"
                className="playtreksButton w-100 h-100"
                onClick={handleSetupNew2FASMSPhone}
              >
                Change phone
              </ButtonComponent>
            </div>
          </>
        ) : (
          <VerifyOTPModal
            onVerifyOTP={handleVerifyNew2FASMSSetup}
            onResendOTP={handleResendOTP}
            phone={newPhone}
          />
        )}
      </div>
    </div>
  );
};

export default ChangePhoneModal;
