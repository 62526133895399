import React, { useEffect, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { setUsedArtistFields } from "../../../redux/actions/distributionActions.js";

import ProfileHeader from "../../artistDashboard/Profile/components/ProfileHeader";
import ButtonComponent from "../../form/ButtonComponent";

import "./index.scss";

function MyArtists() {
  const dispatch = useDispatch();

  const [freeSlots, setFreeSlots] = useState([]);

  useEffect(() => {
    // populate redux used artists
    dispatch(setUsedArtistFields());
  }, []);

  const artistSlots = useSelector(
    (state) => state.distributionReducer?.usedArtistSlots
  );
  const numberOfSlotsAvailable = useSelector(
    (state) =>
      state.subscriptionReducer?.subscription?.rules
        ?.distributionPrimaryArtistSlots?.amount
  );

  useEffect(() => {
    let array = [];
    if (numberOfSlotsAvailable - artistSlots.length >= 0)
      for (let a = 1; a <= numberOfSlotsAvailable - artistSlots.length; a++) {
        array.push(
          <div className="my-artists-slot my-artists-slot-free xyFlexCenter">
            {/* TODO: some nice icon */}
            <div className="opacity-05">unused</div>
          </div>
        );
      }
    setFreeSlots(array);
  }, [numberOfSlotsAvailable, artistSlots]);

  const isPlural = numberOfSlotsAvailable > 1;
  return (
    <main className="my-artists">
      <ProfileHeader label="Your artists" />
      <section className="my-artists-section">
        <div>This is a list of your artist slots:</div>
        <br />
        <div className="my-artists-slotsWrapper">
          {artistSlots.map((slot) => (
            <div className="my-artists-slot">
              <ul>
                {slot.artist_slot.map((artist) => (
                  <li>{artist}</li>
                ))}
              </ul>
              Appears on: <br />
              <ul>
                {slot.releases.map((release) => (
                  <li>{release["#title"]}</li>
                ))}
              </ul>
            </div>
          ))}
          {freeSlots}
          <Link to="/artistDashboard/subscriptions">
            <div className="my-artists-slot my-artists-slot-free my-artists-slot-add xyFlexCenter">
              <u>+ add more slots</u>
            </div>
          </Link>
        </div>
        <hr className="my-artists-section-hr" />
        <div className="my-artists-section-upgradeSection">
          Your current plan allows using {isPlural && "up to"}{" "}
          {numberOfSlotsAvailable} artist slot{isPlural && "s"}. <br />
          <a href="/artistDashboard/subscriptions">Upgrade your plan</a> in
          order to raise the limit.
          <br />
          <br />
          <ButtonComponent
            className="playtreksButton my-artists-section-upgradeSection-btn"
            onClick={() =>
              window.location.assign("/artistDashboard/subscriptions")
            }
          >
            Upgrade
          </ButtonComponent>
        </div>
      </section>
    </main>
  );
}

export default MyArtists;
