import React from "react";

function NoDataAggregation() {
  return (
    <div className="my-earnings-noData">
      <div className="my-earnings-noData-text">
        <b>Watch this space!</b>
        <br />
        As soon as we receive your streams and downloads performance data from
        stores and streaming services, it will be displayed here. <br />
        Usually, earnings are reported 3 months after the track was first sold
        or streamed.
      </div>
    </div>
  );
}

export default NoDataAggregation;
