import React, { useState } from "react";
import InputComponent from "../../../components/form/InputComponent";
import ButtonComponent from "../../../components/form/ButtonComponent";
import { updateRelease } from "../../../services/distribution";
import { Formik } from "formik";
import { Spinner } from "../../Airplay/Airplay";
import { useMutation, useQueryClient } from "react-query";
import { getJWT } from "../../../components/utils";
import Toast from "../../../components/Toast";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

function ReleaseMetaSection({ release = {}, userEmail, releaseId, onUpdate }) {
  const { upc } = release;
  const { catalog_number } = release;
  const queryClient = useQueryClient();
  const [showToast, setShowToast] = useState(initialToastState);

  // Release Mutation handler
  const releaseUPCMutation = useMutation(updateRelease);

  const handleSubmit = (values, formActions) => {
    const payload = {
      userEmail,
      releaseId,
      upc: values.upc,
      catalog_number: values.catalog_number,
      title: values.title,
      version: values.version,
      label: values.label,
    };

    // Using the mutation handler to update release data
    releaseUPCMutation.mutate(payload, {
      onSuccess: () => {
        setShowToast({
          open: true,
          message: "Release has been updated successfully",
          type: "success",
        });

        // Delaying loading as the spinner flashes in ui due to fast updation
        setTimeout(() => {
          queryClient.setQueryData(["adminAllReleases", getJWT()], (old) => ({
            ...old,
            // Updating the cached version
            data: old.data.map((release) => {
              if (release.release.releaseId === releaseId) {
                const updatedState = {
                  ...release,
                  release: {
                    ...release.release,
                    upc: values.upc,
                    catalog_number: values.catalog_number,
                    ["#title"]: values.title,
                    ["#remix_or_version"]: values.version,
                    ["#label"]: values.label,
                  },
                };
                onUpdate({ isOpen: true, release: updatedState });

                return updatedState;
              }
              return release;
            }),
          }));
        }, 1250);
      },
      onError: (e) => {
        formActions.setSubmitting(false);
        formActions.resetForm();

        setShowToast({
          open: true,
          message: e.response.data.message
            ? e.response.data.message
            : "An unexpected error occured. Please try again.",
          type: "danger",
        });
      },
    });
  };

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <Formik
        enableReinitialize
        initialValues={{
          upc: upc ? upc : release["#upc"] === "auto" ? "" : release["#upc"],
          catalog_number: catalog_number ? catalog_number : release["#catalog_number"] === "auto" ? "" : release["#catalog_number"],
          title: release["#title"],
          version: release["#remix_or_version"] || "",
          label: release["#label"],
        }}
        initialStatus={{ edit: false }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            values: { upc, catalog_number, title, version, label },
            status: { edit },
            dirty,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setStatus,
          } = props;

          return (
            <div
              className="pb-0"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderRadius: "10px",
              }}
            >
              <p className="prtcTitle position-relative fs-24">Release:</p>

              <div className="row mx-0 pb-2 pr-4 justify-content-end">
                <div
                  className={
                    "text-white text-decoration-underline font-family-Inter " +
                    (edit ? "opacity-05" : "cursor-pointer")
                  }
                  onClick={() => setStatus({ edit: true })}
                >
                  Edit
                </div>
              </div>
              <div className=" row mx-0 pl-2 pb-4 justify-content-start ">
                {/* Release Title */}
                <div className="position-relative py-2 py-md-0 col-12 mb-2 ">
                  <div
                    className={
                      (title ? "" : "d-none") +
                      " inputUpperComment utiuc3_upcCode"
                    }
                  >
                    Title*
                  </div>
                  <InputComponent
                    value={title}
                    disabled={!edit}
                    commentField={".utiuc3_upcCode"}
                    placeholder="Title*"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (title ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#title"
                    addon={false}
                    changeCallback={(value) => setFieldValue("title", value)}
                    inputBlurCallback={() => setFieldTouched("title", true)}
                  />
                </div>
                {/* Release version */}
                <div className="position-relative py-2 py-md-0 col-12 mb-2">
                  <div
                    className={
                      (version ? "" : "d-none") +
                      " inputUpperComment utiuc3_upcCode"
                    }
                  >
                    Version
                  </div>
                  <InputComponent
                    value={version}
                    disabled={!edit}
                    commentField={".utiuc3_upcCode"}
                    placeholder="Version"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (version ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#remix_or_version"
                    maxlength={13}
                    addon={false}
                    changeCallback={(value) => setFieldValue("version", value)}
                    inputBlurCallback={() => setFieldTouched("version", true)}
                  />
                </div>

                {/* upc */}
                <div className="position-relative py-2 py-md-0 col-12 mb-2">
                  <div
                    className={
                      (upc ? "" : "d-none") +
                      " inputUpperComment utiuc3_upcCode"
                    }
                  >
                    UPC
                  </div>
                  <InputComponent
                    value={upc}
                    disabled={!edit}
                    commentField={".utiuc3_upcCode"}
                    placeholder="UPC"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (upc ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#upcCode"
                    maxlength={13}
                    addon={false}
                    changeCallback={(value) => setFieldValue("upc", value)}
                    inputBlurCallback={() => setFieldTouched("upc", true)}
                  />
                </div>

                {/* catalog */}
                <div className="position-relative py-2 py-md-0 col-12 mb-2">
                  <div
                    className={
                      (catalog_number ? "" : "d-none") +
                      " inputUpperComment utiuc3_catalogNumber"
                    }
                  >
                    Catalog number
                  </div>
                  <InputComponent
                    value={catalog_number}
                    disabled={!edit}
                    commentField={".utiuc3_catalogNumber"}
                    placeholder="Catalog number"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (upc ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#catalog_number"
                    maxlength={13}
                    addon={false}
                    changeCallback={(value) => setFieldValue("catalog_number", value)}
                    inputBlurCallback={() => setFieldTouched("catalog_number", true)}
                  />
                </div>

                {/* label */}
                <div className="position-relative py-2 py-md-0 col-12 ">
                  <div
                    className={
                      (label ? "" : "d-none") +
                      " inputUpperComment utiuc3_upcCode"
                    }
                  >
                    Label
                  </div>
                  <InputComponent
                    value={label}
                    disabled={!edit}
                    commentField={".utiuc3_upcCode"}
                    placeholder="Label"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (label ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#label"
                    addon={false}
                    changeCallback={(value) => setFieldValue("label", value)}
                    inputBlurCallback={() => setFieldTouched("label", true)}
                  />
                </div>
              </div>

              {/* cancel/save buttons */}

              {edit && (
                <div className="row mx-0 px-3 pb-2 justify-content-end">
                  <div>
                    <ButtonComponent
                      onClick={() => {
                        resetForm();
                        setStatus({ edit: false });
                      }}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton bg-transparent textLink w-100 h-75"
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                  <div>
                    <ButtonComponent
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton  w-100 h-75"
                    >
                      {isSubmitting ? (
                        <>
                          <span className="mr-2">Saving... </span>
                          <span>
                            <Spinner />
                          </span>
                        </>
                      ) : (
                        "Save"
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
}

export default ReleaseMetaSection;
