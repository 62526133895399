import React, { useEffect } from "react";
import {
  externalRedirectCall,
  externalRedirectCallProtected,
} from "../../../routes/external";
import LoadingPage from "../../LoadingPage";
import { protectedRoute } from "../../../routes/users";
import jwtDecode from "jwt-decode";
import { getJWT } from "../../utils";

export function SpotifyRedirect() {
  useEffect(() => genericRedirect("spotify"), []); // <-- will get executed only once on component load (passing an empty array to useEffect)
  return <LoadingPage />;
}

export function FacebookRedirect() {
  useEffect(() => genericRedirect("facebook"), []); // <-- will get executed only once on component load (passing an empty array to useEffect)
  return <LoadingPage />;
}

export function GoogleRedirect() {
  useEffect(() => genericRedirect("google"), []); // <-- will get executed only once on component load (passing an empty array to useEffect)
  return <LoadingPage />;
}

export function TwitterRedirect() {
  useEffect(() => genericRedirect("twitter"), []); // <-- will get executed only once on component load (passing an empty array to useEffect)
  return <LoadingPage />;
}

async function genericRedirect(service) {
  let search = window.location.search.trim("?");
  let params = new URLSearchParams(search);
  let code = params.get("code");
  let state = params.get("state");
  let oauth_token = params.get("oauth_token"); // oauth v1
  let oauth_verifier = params.get("oauth_verifier"); // oauth v1

  if (params.get("error")) {
    alert("Could not log in with " + service);
    window.location.replace(
      window.localStorage.getItem("locationBeforeExternalLogin") || "/"
    );
  }

  const storedState = window.localStorage.getItem(`${service}State`);
  // TODO: display spinner, perhaps in the empty div?

  // If we have a JWT in local storage, then the user might be logged in, therefore
  // we call a separate route which is protected, since JWT passport
  // returns 401 immediately if the jwt token is not valid (or missing)
  //
  // TODO: find out how to avoid returning 401 in JWT? Two separate routes is not
  // too bad either.
  const currentJwtToken = getJWT();
  let jwtResponse,
    locationToRedirect,
    authenticated = false;

  try {
    await protectedRoute(currentJwtToken);
    authenticated = true;
  } catch (err) {
    authenticated = false;
  }

  // Check whether user is logged in using the api protected route, not existance of jwtToken

  try {
    if (!authenticated) {
      // user is not authenticated, "classical" case
      jwtResponse = await externalRedirectCall(
        code,
        state,
        storedState,
        oauth_token,
        oauth_verifier,
        service
      );
    } else {
      // user is authenticated, now we want to connect the additional accounts
      jwtResponse = await externalRedirectCallProtected(
        code,
        state,
        storedState,
        oauth_token,
        oauth_verifier,
        service,
        currentJwtToken
      );
    }

    const jwtToken = jwtResponse.data.jwt.token;
    window.localStorage.setItem("jwtToken", jwtToken);
    if (jwtDecode(jwtToken)?.twoFARequired) {
      window.location.replace("/2fa/verify");
      return;
    }
    document.cookie = `jwtToken=${jwtToken}; path=/; max-age=${
      24 * 60 * 60
    }; domain=${global.config.ssoAppDomain}; secure`;

    if (jwtResponse.data.redirect_to_onboarding)
      locationToRedirect = "/onboarding";
    else locationToRedirect = window.localStorage.getItem("playtreksNextStep");
    window.location.replace(locationToRedirect);
  } catch (err) {
    // Oauth log in not successful.
    // TODO: display a message to inform the user?
    locationToRedirect = window.localStorage.getItem("playtreksCurrentStep");
    window.location.replace(locationToRedirect);
  }

  // TODO
  // remove spotifyState from storage
  // if not jwt => some error

  //  window close
}
