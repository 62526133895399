import React, { memo } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Arrow from "../../../assets/icons/selectInputArrow.svg";
import ShareHoldersForm from "./ShareHoldersForm";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";

const RoyaltiesSplitForm = memo(
  ({ tracks = [], loading = true, ownerEmail, ownerName }) => {
    const user = useSelector(
      ({ dashboardReducer }) => dashboardReducer.userData || {},
      isEqual
    );

    const isOwner = user.email === ownerEmail;
    const { releaseId } = useParams();

    const handleRedirectToUploadTracksPage = (e) => {
      e.stopPropagation();
      window.localStorage.setItem("currentReleaseId", releaseId);
      setTimeout(() => {
        window.location.assign("/distribution/newRelease/1");
      }, 0);
    };

    return (
      <div className="prTracksCard pb-0">
        <p className="prtcTitle position-relative">Royalty shares</p>
        {isOwner && (
          <>
            <p className="pt-2 px-3">
              PlayTreks makes it easy to collaborate. You can route any
              percentage of earnings from any track to anyone.
              <br /> Please{" "}
              <span className="text-primary">
                <a
                  href=" https://support.playtreks.com/articles/pay-collaborators-automatically-with-royalty-splits"
                  target="_blank"
                >
                  read our support article
                </a>
              </span>{" "}
              before you use this feature.
            </p>
          </>
        )}
        {tracks?.map((track, trackIndex) => {
          return (
            <div className="p-3" key={trackIndex}>
              <Accordion
                className="rounded text-white"
                style={{ backgroundColor: "#2c313a" }}
              >
                <AccordionSummary
                  expandIcon={
                    <img
                      src={Arrow}
                      alt="expand"
                      style={{ width: "14px", height: "14px" }}
                    />
                  }
                  id="panel1a-header"
                >
                  <div class="prrcText font-weight-normal">
                    <span class="prrcWhiteText">
                      {track.form["#track_title"]}
                      {track.form["#remix_or_version"]
                        ? ` (${track.form["#remix_or_version"]})`
                        : ""}
                    </span>
                  </div>
                </AccordionSummary>

                <AccordionDetails className="d-block">
                  <div class="prrcText font-weight-normal">
                    ISRC:{" "}
                    {track?.isrc ? (
                      <span class="prrcWhiteText">{track?.isrc}</span>
                    ) : (
                      <span class="prrcWhiteText">
                        {track?.form["#isrc"] ? track?.form["#isrc"] : "auto"}
                      </span>
                    )}
                  </div>

                  <ShareHoldersForm
                    shareholders={track.shareholders}
                    trackTitle={`${track.form["#track_title"]} ${
                      track.form["#remix_or_version"]
                        ? `(${track.form["#remix_or_version"]})`
                        : ""
                    }`}
                    trackIndex={trackIndex}
                    ownerEmail={ownerEmail}
                    ownerName={ownerName}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}

        {!!!tracks?.length && !loading && (
          <>
            <div className="row justify-content-center my-3">
              <div
                className="uploadTracksButton  position-static"
                onClick={handleRedirectToUploadTracksPage}
              >
                Upload tracks
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default RoyaltiesSplitForm;
