import React from "react";

import X from "..//assets/X.svg";

function QuitNewReleaseModal({ className, setQuitModal }) {
  return (
    <div>
      <div className={"playtreksModal-overlay " + className}>
        <div className="playtreksModal-modal">
          <div className="playtreksModal-quitNewRelease">
            <img
              src={X}
              alt="close"
              className="playtreksModal-modal-close-absolute"
              onClick={() => setQuitModal(false)}
            />
            <div className="playtreksModal-modal-header">
              Warning! &nbsp;
              <span aria-label="warning" alt="warning">
                ⚠️
              </span>
            </div>
            <br />
            You will lose your unsaved changes. <br />
            <br />
            <div className="playtreksModal-textSmall">
              If you wish to save your current progress, <br /> please click the
              save button on the bottom of the page.
            </div>
            <br />
            <br />
            <span
              className="playtreksButton playtreksModal-modal-btn1"
              onClick={() => setQuitModal(false)}
            >
              Back
            </span>
            <span
              className="playtreksButton playtreksModal-modal-btn2 playtreksModal-modal-btnInactive"
              onClick={() =>
                window.location.assign("/artistDashboard/myReleases")
              }
            >
              Proceed
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuitNewReleaseModal;
