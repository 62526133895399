import React from "react";

import Stepper from "../../Stepper";

const ReleaseProgressCircles = (props) => {
  let active = props.activeCircle;
  let currentStep = active - 1;
  if (active === "all") currentStep = 3;

  function handleLink(index) {
    if (index < active || active === "all") {
      window.location.replace("/distribution/newRelease/" + index);
    }
  }

  const steps = [
    {
      name: "album details",
      callback: () => handleLink("1"),
    },
    {
      name: "upload tracks",
      callback: () => handleLink("2"),
    },
    {
      name: "preview",
      callback: () => handleLink("3"),
    },
    {
      name: "release",
      callback: () => {},
    },
  ];

  return (
    <>
      <Stepper
        steps={steps}
        currentStep={currentStep}
        containerClasses={"new-release-stepper"}
        showLabels={true}
      />
      <div className="nrpWrapper" style={{ display: "none" }}>
        {/* release progress circles */}
        <div className="newReleaseProgress">
          <div>
            <div
              className={
                "nrpCircle1 " +
                (active === 1 || active === "all" ? "nrpCircleActive" : "")
              }
              style={
                active > 1 || active === "all" ? { cursor: "pointer" } : {}
              }
              onClick={() => handleLink(1)}
            ></div>{" "}
            <br />
            <div>
              album <br /> details
            </div>
          </div>
          <div
            className={"nrpLine1 " + (active === "all" ? "nrpLineActive" : "")}
          ></div>
          <div
            className={
              "nrpCircle2 " +
              (active === 2 || active === "all" ? "nrpCircleActive" : "")
            }
            style={active > 2 || active === "all" ? { cursor: "pointer" } : {}}
            onClick={() => handleLink(2)}
          ></div>
          <div
            className={"nrpLine2 " + (active === "all" ? "nrpLineActive" : "")}
          ></div>
          <div
            className={
              "nrpCircle3 " +
              (active === 3 || active === "all" ? "nrpCircleActive" : "")
            }
            style={active > 3 || active === "all" ? { cursor: "pointer" } : {}}
            onClick={() => handleLink(3)}
          ></div>
          <div
            className={"nrpLine3 " + (active === "all" ? "nrpLineActive" : "")}
          ></div>
          <div
            className={
              "nrpCircle4 " + (active === "all" ? "nrpCircleActive" : "")
            }
          ></div>
        </div>
        <div className="nrpTextDiv">
          <div
            className={
              "nrpText1 " +
              (active === 1 || active === "all" ? "nrpTextActive" : "")
            }
            onClick={() => handleLink("/distribution/newRelease/1")}
          >
            {/* album <br /> details */}
          </div>
          <div
            className={
              "nrpText2 " +
              (active === 2 ? "nrpTextActive" : "") +
              (active === "all" ? "nrpTextActiveFix1" : "")
            }
            style={active === 3 ? { marginLeft: "-8px" } : {}}
            onClick={() => handleLink("/distribution/newRelease/2")}
          >
            upload <br /> tracks
          </div>
          <div
            className={
              "nrpText3 " +
              (active === 3 ? "nrpTextActive" : "") +
              (active === "all" ? "nrpTextActiveFix2" : "")
            }
            onClick={() => handleLink("/distribution/newRelease/3")}
          >
            preview
          </div>
          <div
            className={"nrpText4 " + (active === "all" ? "nrpTextActive" : "")}
          >
            release
          </div>
        </div>
      </div>
    </>
  );
};

export default ReleaseProgressCircles;
