import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

export async function submitDistributionForm(data, jwtToken) {
  let url = `${global.config.API_URL}/api/distribution/submitForm`;
  return new Promise((res, rej) => {
    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        }, // <== in case of post request, authorization has to be passed as 3rd argument
      })
      .then((r) => {
        res(r);
        rej("error");
      });
  });
}

export async function submitSingleTrackMetadata(
  track,
  index,
  releaseId,
  jwtToken
) {
  let url = `${global.config.API_URL}/api/distribution/submitSingleTrackMetadata`;
  return axios.post(
    url,
    { track: track, releaseId: releaseId, index: index },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export async function submitTracks(tracks, releaseId, jwtToken) {
  let url = `${global.config.API_URL}/api/distribution/submitTracks`;
  return axios.post(
    url,
    { tracks: tracks, releaseId: releaseId },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export async function uploadTrackToS3(file, releaseId, jwtToken) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("releaseId", releaseId);

  let url = `${global.config.API_URL}/api/distribution/uploadTrackToS3`;
  return axios.post(url, formData, {
    headers: {
      Authorization: "Bearer " + jwtToken,
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function uploadTrackLicenseFile(file, releaseId, jwtToken) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("releaseId", releaseId);

  let url = `${global.config.API_URL}/api/distribution/uploadTrackLicenseFile`;
  return axios.post(url, formData, {
    headers: {
      Authorization: "Bearer " + jwtToken,
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function uploadCoverArtToS3(file, releaseId, jwtToken) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("releaseId", releaseId);

  let url = `${global.config.API_URL}/api/distribution/uploadCoverArtToS3`;
  return axios.post(url, formData, {
    headers: {
      Authorization: "Bearer " + jwtToken,
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getReleaseData(releaseId, jwtToken) {
  let url = `${global.config.API_URL}/api/distribution/getReleaseData`;
  return new Promise((res, rej) => {
    axios
      .get(url, {
        params: { releaseId },
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((r) => {
        res(r);
        rej("error");
      });
  });
}

export async function finishRelease(
  pickedPlatforms,
  pickedSocialMediaPlatForms,
  releaseId,
  currentDate,
  captchaToken
) {
  let url = `${global.config.API_URL}/api/distribution/finishRelease`;
  return axios.post(
    url,
    {
      releaseId: releaseId,
      pickedPlatforms: pickedPlatforms,
      pickedSocialMediaPlatForms: pickedSocialMediaPlatForms,
      currentDate: currentDate,
      token: captchaToken,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function getAllUserReleases(jwtToken) {
  if (jwtToken) jwtToken = getJWT();

  let url = `${global.config.API_URL}/api/distribution/getAllUserReleases`;
  return new Promise((res, rej) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((r) => {
        res(r);
        rej("error");
      });
  });
}

export async function withdrawDAI(data) {
  let url = `${global.config.API_URL}/api/distribution/withdraw`;
  return await axios.post(url, data, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    }, // <== in case of post request, authorization has to be passed as 3rd argument
  });
}

export async function confirmDAIPayment(data) {
  console.log(data);
  let url = `${global.config.API_URL}/api/distribution/confirmPayment\
?artistEmail=${data.artistEmail}\
&releaseId=${data.releaseId}\
&smartContractAddress=${data.contractAddress}\
&walletAddress=${data.walletAddress}\
&amount=${data.amount}`;
  return await axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getDspList(jwtToken) {
  if (jwtToken) jwtToken = getJWT();

  let url = `${global.config.API_URL}/api/distribution/getDspList`;
  return new Promise((res, rej) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + jwtToken,
        },
      })
      .then((r) => {
        res(r);
        rej("error");
      });
  });
}

export async function getUsedArtists() {
  const jwtToken = getJWT();
  let url = `${global.config.API_URL}/api/distribution/usedArtists`;
  return axios.get(url, { headers: { Authorization: "Bearer " + jwtToken } });
}
