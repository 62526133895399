import * as Colors from "@nivo/colors";
import React from "react";
import { Link } from "react-router-dom";
import { isIframe, setPath } from "./utils.js";

const artistsJoined = (artists, pathname) =>
  artists?.map((artist, i) => {
    let comma;
    if (artists.length !== 1 && i !== artists.length - 1) {
      comma = ", ";
    }
    return (
      <span>
        <Link
          to={setPath(artist.id, artist.name, "artist", pathname)}
          className="textLink"
          target={isIframe() ? "_blank" : "_self"}
        >
          {artist.name}
        </Link>
        {comma}
      </span>
    );
  });

export { artistsJoined };
