import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

import Invite from "./invite";

import LineChartIcon from "../../assets/icons/line_chart_up.svg";
import AddToQueueIcon from "../../assets/icons/add_to_queue.svg";
import HelpCenterIcon from "../../assets/icons/helpcenter.png";

import playtreksTransparentBg from "../../assets/playtreksTransparentBg.png";

import {
  // MyDataMenu,
  // Charts,
  DistributionMenu,
  SubscriptionMenu,
  Support,
  AccountingMenu,
} from "./menuConfig";
import AppSwitcher from "./AppSwitcher/AppSwitcher";
import { useSelector } from "react-redux";

function SidebarMenu({ headerIcon, headerTitle, menus, path }) {
  return (
    <div className="sidebar-menu">
      <label className="sidebar-menu-header">
        <img src={headerIcon} alt="header" />
        {headerTitle}
      </label>
      <ul>
        {menus.map((item, index) => {
          if (item.onlyMobile) return;
          let isActive = item.url === path;
          if (
            (item.url === "/artistDashboard/myReleases" &&
              path.match(/\/artistDashboard\/myReleases\/[\w\d-]+/gi)) ||
            (item.url === "/artistDashboard/contracts" &&
              path.match(/\/artistDashboard\/contract\/[\w\d-]+/gi))
          ) {
            isActive = true;
          }
          return item.url === "https://support.playtreks.com/" ? (
            <a href={item.url} key={index} target="_blank">
              <li className={`sidebar-menu-item ${isActive ? "active" : ""}`}>
                {item.label}
              </li>
            </a>
          ) : (
            <Link to={item.url} key={index}>
              <li className={`sidebar-menu-item ${isActive ? "active" : ""}`}>
                {item.label}
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

// const getDefaultSwitcherState = (location) => {
//   // if (MyDataMenu.some((menu) => location.pathname === menu.url)) {
//   //   return "analytics";
//   // }

//   return "distribution";
// };

function Sidebar() {
  const location = useLocation();
  const history = useHistory();
  const [selectedDropDown, setSelectedDropDown] = useState("distribution");

  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );

  const handleDropDownValueChange = (event) => {
    const value = event.target.value;

    // if (value === "analytics") {
    //   setSelectedDropDown(value);
    //   history.push("/artistDashboard");
    // }
    if (value === "distribution") {
      setSelectedDropDown(value);
      history.push("/artistDashboard/myReleases");
    }

    if (value === "marketplace") {
      window.location.href = "https://marketplace.playtreks.com/";
    }

    if (value === "copyright") {
      window.location.href = "https://copyright.playtreks.com/";
    }
  };

  const onInviteClick = () => console.log("invite link click");

  const inviteEmoji = {
    name: "party popper",
    emoji: "🎉",
  };
  return (
    <div className="sidebar">
      <Link to="/welcomePage">
        <h2 className="sidebar-title">
          <img
            src={playtreksTransparentBg}
            alt="logo"
            className="sidebar-logo"
          />
        </h2>
      </Link>

      <AppSwitcher
        handleDropDownValueChange={handleDropDownValueChange}
        selectedDropDown={selectedDropDown}
      />

      {/* {selectedDropDown === "analytics" && (
        <>
          <SidebarMenu
            headerIcon={LineChartIcon}
            headerTitle="my data"
            menus={MyDataMenu}
            path={location.pathname}
          />

          <SidebarMenu
            headerIcon={LineChartIcon}
            headerTitle="charts"
            menus={Charts}
            path={location.pathname}
          />
        </>
      )} */}

      {selectedDropDown === "distribution" && (
        <>
          <SidebarMenu
            headerIcon={AddToQueueIcon}
            headerTitle="distribution"
            menus={DistributionMenu}
            path={location.pathname}
          />

          <SidebarMenu
            headerIcon={AddToQueueIcon}
            headerTitle="accounting"
            menus={AccountingMenu}
            path={location.pathname}
          />

          <SidebarMenu
            headerIcon={AddToQueueIcon}
            headerTitle="subscription"
            menus={SubscriptionMenu}
            path={location.pathname}
          />
        </>
      )}
      <SidebarMenu
        headerIcon={HelpCenterIcon}
        headerTitle="support"
        menus={Support}
        path={location.pathname}
      />
    </div>
  );
}
export default Sidebar;
