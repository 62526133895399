import React, { useEffect, useState } from "react";

import UserProfile from "../../artistDashboard/UserProfile";
import UserIcon from "../../../assets/icons/user.svg";

import VectorStar from "../../../assets/vectorStar.svg";
import Group from "../../../assets/Group.png";
import Sp from "../../../assets/distrSpotify.png";
import ReleasePlaceholder from "../../../assets/releasePlaceholder.png";

import { getAllUserReleases } from "../../../routes/distribution";

import "./myReleases.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faThList } from "@fortawesome/free-solid-svg-icons";
import useLocalStorage from "../../../hooks/useLocalStorage";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import UserGridView from "../../../screens/Releases/components/UserGridView";
import TableView from "../../../screens/Admin/Releases/components/TableView";

import { Link } from "react-router-dom";
import {
  GlobalFilter,
  includes,
  ReleaseStateFilter,
} from "../../admin/ReleasesAdmin";
import { useQuery } from "react-query";
import ReactTooltip from "react-tooltip";
import { getJWT, isUserProfileIncomplete } from "../../utils";
import ProfileCompletenessRequiredModal from "../components/ProfileCompletenessRequiredModal";
import Portal from "../../Portal";
import { useSelector } from "react-redux";

const INITIAL_PAGE_SIZE = 10;

const statusCssClasses = {
  submitted: "info",
  submitted_to_qc: "warning",
  failed: "danger",
  taken_down: "light",
  succeeded: "success",
};

const customGlobalFilter = (rows, ids, filterValue) => {
  rows = rows.filter((row) => {
    return ids.some((id) => {
      let rowValue = row.values[id];

      if (id === "release") {
        const release = rowValue;

        return (
          includes(release["#title"], filterValue) ||
          includes(release["upc"], filterValue) ||
          release["tracks"]?.some((track) => includes(track?.isrc, filterValue))
        );
      }

      return includes(rowValue, filterValue);
    });
  });

  return rows;
};

const releaseStateFilterOptions = {
  "": { label: "All", value: "" },
  submitted: { label: "Submitted / In progress", value: "submitted" },
  submitted_to_qc: { label: "In quality control", value: "submitted_to_qc" },
  failed: { label: "Failed", value: "failed" },
  taken_down: { label: "Taken down", value: "taken_down" },
  succeeded: { label: "Succeeded", value: "succeeded" },
  draft: { label: "Draft", value: "draft" },
};

const queryConfig = {
  refetchOnWindowFocus: false,
  cacheTime: 3_600_000, //milliseconds
  // refetchOnMount: false,
};

function MyReleases() {
  const jwtToken = getJWT();

  const [view, setView] = useLocalStorage("userReleasesViewType", true);
  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );
  // Releases query
  const { isLoading: areReleasesLoading, data: releasesResponse } = useQuery(
    ["userAllReleases", jwtToken],
    getAllUserReleases,
    queryConfig
  );
  const releases = React.useMemo(
    () => releasesResponse?.data.reverse() ?? [],
    [releasesResponse]
  );
  const [showProfileCompleteReqModal, setShowProfileCompleteReqModal] =
    useState(false);

  function handleNewRelease() {
    if (isUserProfileIncomplete(reduxUserData)) {
      setShowProfileCompleteReqModal(true);

      return;
    }

    window.localStorage.removeItem("currentReleaseId");
    setTimeout(() => window.location.assign("/distribution/newRelease/1"), 0);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Release",
        accessor: (originalRow) => originalRow,
        id: "release",
        Cell: ({ value }) => (
          <div className="playlistsContainer align-items-center" title="">
            <img
              width="50"
              height="50"
              className="rounded"
              loading="lazy"
              src={
                value?.["#cover_url"] === "" ||
                value?.["#cover_url"] === "initial"
                  ? ReleasePlaceholder
                  : value?.["#cover_url"]
              }
            />
            <div className="pl-2 text-white">
              <div className="spotifycharts-title pb-1">
                Release:&nbsp;
                <span className="text-white">{value?.["#title"]}</span>
              </div>
              <div className="spotifycharts-title pb-1">
                Version:&nbsp;
                <span className="text-white">
                  {value?.["#remix_or_version"]}
                </span>
              </div>
            </div>
          </div>
        ),
      },

      {
        Header: "Artist(s)",
        accessor: (originalRow) => originalRow,
        id: "participants",
        Cell: ({ value }) => {
          return (
            <div>
              {value?.["#participants"]
                .filter((participant) => participant.primary)
                .map((participant, index, arr) => (
                  <div key={index}>
                    {participant.primary}
                    {index !== arr.length - 1 ? "," : ""}
                  </div>
                ))}
            </div>
          );
        },
      },

      {
        Header: "Label",
        accessor: "#label",
      },

      {
        Header: "Catalog number",
        accessor: "#catalog_number",
      },
      {
        Header: "Primary genre",
        accessor: "#primary_genre",
      },
      {
        Header: "Release date",
        accessor: "#digital_release",
      },
      {
        id: "releaseState",
        Header: "Status",
        accessor: "state",
        disableSortBy: true,
        Cell: ({ value }) => (
          <span
            className={`font-weight-bold fs-14 badge badge-${statusCssClasses[value]}`}
          >
            {value}
          </span>
        ),
        filter: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id] || false;

            if (filterValue === "") return true;

            return rowValue === filterValue;
          });
        },
      },
      {
        Header: "",
        id: "more-info",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => (
          <Link
            to={{
              pathname: `/artistDashboard/myReleases/${value?.["releaseId"]}`,
              release: value,
            }}
            className="admin-release-details-link"
            title="View detailed release information"
          >
            Details
          </Link>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: releases,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["user", "firstname", "lastname"],
      },
      globalFilter: customGlobalFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <div>
      <main className="myReleases-overview">
        <section className="myReleases-header">
          <div className="distributionButton" onClick={handleNewRelease}>
            {releases?.length > 0 ? "New Release" : "Make your first release"}
          </div>
          <br />
          <div className="distributionGuidelinesNote">
            Please carefully read our{" "}
            <a
              href="https://support.playtreks.com/collections/uploading-music-requirements"
              target="_blank"
            >
              uploading music requirements
            </a>{" "}
            to avoid any delays.
          </div>
          <br />
          {/* decoration boxes */}
          <div className="dDecoration1">
            <div className="dd1a">+</div>
          </div>
          <div className="dShader"></div>
          <div className="dDecoration2">
            <div className="dd2Text">Genre</div>
            <div className="dd2badge1">future house</div>
            <div className="dd2badge2">techno</div>
            <div className="dd2badge3">hard style</div>
            <div className="dd2badge4"></div>
          </div>
          <div className="dDecoration3">
            <img src={VectorStar} alt="star" className="dd3star" />
            <img src={Group} alt="group" className="dd3group" />
          </div>
          <div className="dDecoration4">
            <div className="dd4Inner1">
              <img src={Sp} alt="Spotify" className="dd4InnerSpotify" />
            </div>
          </div>
        </section>

        {/* GRID AND LIST VIEW */}
        <div className="allowUserSelect">
          <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
            <div className="d-block mb-3" style={{ zIndex: 1 }}>
              <p className="font-weight-bold text-white mb-0">Filter by:</p>
              <div className="row pb-2 align-items-center justify-content-between">
                <div class="col-12 col-md">
                  <div class="row">
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                    <ReleaseStateFilter
                      setFilter={setFilter}
                      filters={filters}
                      options={releaseStateFilterOptions}
                    />
                  </div>
                </div>
                <div className="pr-md-3 pt-2 pl-3 col-12 col-md-auto text-right ">
                  <span
                    className={`mr-2 cursor-pointer text-${
                      view ? "primary" : "white"
                    }`}
                    onClick={() => setView(true)}
                    data-for="view-type"
                    data-tip="Grid view"
                  >
                    <FontAwesomeIcon
                      icon={faTh}
                      style={{
                        width: "24px",
                        height: "24px",
                      }}
                      className="mr-2"
                    />
                  </span>
                  <span
                    className={`cursor-pointer text-${
                      !view ? "primary" : "white"
                    }`}
                    onClick={() => setView(false)}
                    data-for="view-type"
                    data-tip="Table view"
                  >
                    <FontAwesomeIcon
                      icon={faThList}
                      style={{ width: "24px", height: "24px" }}
                      className="mr-2"
                    />
                  </span>
                </div>
              </div>
            </div>
            {view ? (
              <div className="row justify-content-center" style={{ gap: "4%" }}>
                <UserGridView
                  tableInstance={tableInstance}
                  loading={areReleasesLoading}
                />
              </div>
            ) : (
              <TableView
                tableInstance={tableInstance}
                loading={areReleasesLoading}
              />
            )}
            <ReactTooltip
              place="top"
              backgroundColor="#1d2025"
              className="ptTooltip"
              border
              borderColor="#0ef1db"
              effect="float"
              id="view-type"
            />
          </main>
        </div>
      </main>
      <Portal selector="body">
        {showProfileCompleteReqModal && (
          <ProfileCompletenessRequiredModal
            onCloseRequest={() => setShowProfileCompleteReqModal(false)}
            body2={
              <>
                Please proceed to <a href="/artistDashboard/profile">profile</a>{" "}
                page to finish your profile and come back here to make a
                release.
              </>
            }
          />
        )}
      </Portal>
    </div>
  );
}

export default MyReleases;
