import React from "react";
import "./index.scss";

function Stepper({
  steps,
  currentStep,
  containerClasses,
  color,
  activeColor,
  lineColor,
  finishLineColor,
  showLabels = false,
}) {
  const len = steps.length;

  const getStepperColumns = (steps) => {
    let stepr = new Array(steps.length + (steps.length - 1));
    stepr.fill("1fr");
    return stepr
      .map((_, i) => {
        if (i === 1 || i === stepr.length - 2) return "3fr";
        return i % 2 === 0 ? "1fr" : "2fr";
      })
      .join(" ");
  };

  const getFR = (len, fr) => `repeat(${len}, ${fr})`;
  return (
    <div className={`stepper ${containerClasses}`}>
      <div
        className="stepper-progress"
        style={{ gridTemplateColumns: getStepperColumns(steps) }}
      >
        {steps.map((step, i) => (
          <>
            <span
              key={i}
              style={{
                background:
                  i < currentStep || i === currentStep ? activeColor : color,
              }}
              className={`circle ${
                i < currentStep ? `finished cursor-pointer` : ""
              } ${i === currentStep ? `active` : ""}`}
              onClick={i < currentStep ? step.callback : () => {}}
            ></span>
            {i < len - 1 && (
              <span
                key={i + 10}
                style={{
                  borderColor: i < currentStep ? finishLineColor : lineColor,
                }}
                className={`line ${i < currentStep ? `finished` : ""}`}
              ></span>
            )}
          </>
        ))}
      </div>
      {showLabels && (
        <div
          className="stepper-steps"
          style={{ gridTemplateColumns: getFR(len, "1fr") }}
        >
          {steps.map((step, i) => (
            <>
              <span
                key={i}
                className={`pointer label ${
                  i === currentStep ? "active" : ""
                } ${i < currentStep ? "cursor-pointer" : ""}`}
                onClick={i < currentStep ? step.callback : () => {}}
              >
                {step.name}
              </span>
            </>
          ))}
        </div>
      )}
    </div>
  );
}

export default Stepper;
