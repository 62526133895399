import React, { useState } from "react";
import { Spinner } from "../../../../../screens/Airplay/Airplay";
import ButtonComponent from "../../../../form/ButtonComponent";
import X from "../../../../../components/distribution/assets/X.svg";
import { deleteBackupCodes } from "../../../../../services/auth";

const DeleteBackupCodesModal = ({ setToast, refetchCodes, onCloseRequest }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDeleteCodes = async () => {
    setIsSubmitting(true);
    try {
      const response = await deleteBackupCodes();

      if (response.data.success) {
        setTimeout(refetchCodes, 1000);

        setToast({
          type: "success",
          open: true,
          message: "The codes have deleted successfully",
        });
        onCloseRequest();
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      setToast({
        type: "danger",
        open: true,
        message:
          error?.response?.data?.message ||
          "An unexpected error occured. Please try again",
      });
      onCloseRequest();
    }
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />
        <div className="mt-3">
          <div className="mb-3">Delete backup codes?</div>

          <span className="text-muted">
            You won't be able to use these backup codes to complete two factor
            authentication when you lose access to your device.
          </span>

          <div className="row justify-content-end ml-0 mt-4">
            <ButtonComponent
              onClick={onCloseRequest}
              className=" playtreksButton w-100 h-100 bg-transparent textLink mr-2"
            >
              Cancel
            </ButtonComponent>
            <ButtonComponent
              onClick={handleDeleteCodes}
              disabled={isSubmitting}
              className=" playtreksButton w-100 h-100 bg-secondary"
            >
              {isSubmitting ? (
                <>
                  Deleting...
                  <span className="ml-2">
                    <Spinner />
                  </span>
                </>
              ) : (
                "Delete"
              )}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteBackupCodesModal;
