import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { validateRegisteredGuid } from "../../../routes/users";

import ObDecorations from "../components/ObDecorations";

import "./validateRegisteredUser.scss";

function ValidateRegisteredUser() {
  window.localStorage.removeItem("jwtToken");
  window.localStorage.setItem("playtreksCurrentStep", window.location.pathname);
  document.cookie = `jwtToken= ; path=/; max-age=${0}; domain=${
    global.config.ssoAppDomain
  }; secure`;
  const [guid, setGuid] = useState(
    new URLSearchParams(useLocation().search).get("GUID")
  );
  const [showValidateUserBlock, setShowValidateUserBlock] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");

  const [verificationResent, setVerificationResent] = useState(false);

  useEffect(() => {
    (async () => {
      validateGuid();
    })();
  }, []);

  async function validateGuid() {
    try {
      const validatedGuid = await validateRegisteredGuid({ guid });
      setShowValidateUserBlock(validatedGuid.status === 200);
    } catch (err) {
      if (err.response.status == 401) {
        setShowValidateUserBlock(false);
        setShowErrorMessage(err.response.data.msg);
      }
    }
  }

  function handleResendVerification() {
    setVerificationResent(true);
    setTimeout(() => setVerificationResent(false), 5000);
  }

  return (
    <div>
      <ObDecorations />
      <div className="d-flex">
        <div className="validate-user pt-5">
          {showValidateUserBlock && (
            <div>
              <p className="validate-user-header mb-2">
                {
                  "thank you for verifying your account! you are now officially on board."
                }
              </p>
              <p className="validate-user-header mb-0">
                {"click on below link to login"}
              </p>
            </div>
          )}
          {!showValidateUserBlock && (
            <div>
              <div className="validate-user-header mb-2">
                oops! seems like this link isn't valid anymore. please log in
                and request a new link through the welcome page.
              </div>
            </div>
          )}
          <br />
          <br />
          <div
            className="validate-user-login mb-2"
            onClick={() => window.location.replace("/")}
          >
            log in
          </div>
        </div>
      </div>
    </div>
  );
}

export default ValidateRegisteredUser;
