import SpotifyColorIcon from "../../../assets/icons/spotify-color.svg";

export default [
  {
    name: "Tracks count",
    objectPath: "uniqueTracksCount[0].count",
    icon: SpotifyColorIcon,
  },
  {
    name: "Charts count",
    objectPath: "uniqueChartsCount[0].count",
    icon: SpotifyColorIcon,
  },
  {
    name: "Countries count",
    objectPath: "uniqueCountriesCount[0].count",
    icon: SpotifyColorIcon,
  },
  {
    name: "Total streams",
    objectPath: "sumStreams[0].sum_streams",
    icon: SpotifyColorIcon,
  },
];
