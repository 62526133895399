import React from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import SpotifyColorIcon from "../../assets/icons/spotify-color.svg";
import { reduxSetWelcomePageData } from "../../redux/actions/dashboardActions";

import AlbumFeatures from "../artistDashboard/AlbumFeatures";
import StatisticsChartBox from "../artistDashboard/StatisticsChartBox";
import MobileTrackFeaturesCardsBox from "../artistDashboard/TrackFeatures/MobileTrackFeaturesCardsBox";
import TrackList from "../artistDashboard/TrackList/TrackList";
import { isIframe } from "../utils";

function FeaturedEntity({ entityData }) {
  let dispatch = useDispatch();

  return (
    <span className={isIframe() ? "welcomePage-featured-iframe" : ""}>
      {/* desktop version featured entity */}
      <section
        className={
          "welcomePage-featured-wrapper" +
          (isIframe() ? " welcomePage-featured-iframe" : "")
        }
      >
        <AlbumFeatures
          mainEntity={entityData}
          CustomHeader={() => FeaturedHeader(entityData)}
          linksPathname={"/artistDashboard"}
        />
        <StatisticsChartBox
          title="spotify popularity"
          source="welcomePage"
          platforms={[
            {
              name: "spotify",
              icon: SpotifyColorIcon,
              objectPath: "stats.popularity",
            },
          ]}
        />
      </section>
      {/* mobile version featured entity */}
      <section className="welcomePage-featured-mobile-wrapper">
        <FeaturedHeaderMobile entityData={entityData} />
        <MobileTrackFeaturesCardsBox entityData={entityData} />
        <br />
        <TrackList
          trackFeatures={entityData?.trackFeatures}
          linksPathname={"/artistDashboard"}
          scrollableMode
        />
        <StatisticsChartBox
          title="spotify popularity"
          source="welcomePage"
          platforms={[
            {
              name: "spotify",
              icon: SpotifyColorIcon,
              objectPath: "stats.popularity",
            },
          ]}
        />
      </section>
    </span>
  );
}

function FeaturedHeader(entityData) {
  const dispatch = useDispatch();
  return (
    <div className="welcomePage-featured-header">
      <span>
        <img
          src={entityData?.images?.[0]?.url}
          className="welcomePage-featured-header-img"
        />
      </span>
      <span className="welcomePage-featured-header-text">
        Featured {entityData?.type || "playlist"}
        <a target="blank" href={entityData?.external_urls?.spotify}>
          <img
            src={SpotifyColorIcon}
            style={{ verticalAlign: "super", marginLeft: "10px" }}
          />
        </a>
      </span>
      <span className="welcomePage-featured-header-rightText">
        Try out the data dashboard by hovering and clicking on song names!
        <div
          className="wpBox-actions-left wpBox-actions-active textLink"
          style={{ color: "#0ef1db" }}
          onClick={() => dispatch(reduxSetWelcomePageData())}
        >
          Try another featured item, it's fun!
        </div>
      </span>
    </div>
  );
}

function FeaturedHeaderMobile({ entityData }) {
  const dispatch = useDispatch();
  return (
    <span className="welcomePage-featured-mobile-header">
      Featured {entityData?.type || ""}
      <a target="blank" href={entityData?.external_urls?.spotify}>
        <img
          src={SpotifyColorIcon}
          style={{ verticalAlign: "super", marginLeft: "10px" }}
        />
      </a>
      <br />
      <span
        className="welcomePage-featured-mobile-header-tryanother"
        onClick={() => dispatch(reduxSetWelcomePageData())}
      >
        <span className="textLink" style={{ color: "#0ef1db" }}>
          Try another featured item!
        </span>
        &nbsp;
        <span>🔄</span>
      </span>
      <br /> <br />
      <img
        src={entityData?.images?.[0]?.url}
        className="welcomePage-featured-mobile-img"
      />
      <br />
      <small>{entityData?.name || ""}</small>
      <br />
      <br />
    </span>
  );
}

export default FeaturedEntity;
