import moment from "moment";

import { cloneDeep, groupBy, pickBy } from "lodash";

const MUSIC_RELEASES_FILTER = "music_releases_filter";
const MUSIC_TRACKS_FILTER = "music_tracks_filter";
const MUSIC_ARTISTS_FILTER = "music_artists_filter";

function applyDateRangeFilter(data, filterValue) {
  if (filterValue === 0) {
    return data;
  }

  let newData = cloneDeep(data);

  return newData.filter((el) =>
    moment(el.start_date).isAfter(moment().subtract(filterValue, "months"))
  );

  // TODO: upcoming
  // filter all the documents by the picked date range from-until //
}

function applyStoresFilter(data, filterValue) {
  if (filterValue === 0) {
    return data;
  }
  let newData = cloneDeep(data);

  // convert picked object to array
  return Object.values(pickBy(newData, (el) => el.channel === filterValue));
}

function applyCountriesFilter(data, filterValue) {
  if (filterValue === 0) {
    return data;
  }
  let newData = cloneDeep(data);

  // convert picked object to array
  return Object.values(pickBy(newData, (el) => el.country === filterValue));
}

function applyMusicFilter(data, subFilter, filterValue) {
  if (filterValue === 0) {
    return data;
  }
  let newData = cloneDeep(data);

  switch (subFilter) {
    case MUSIC_RELEASES_FILTER: {
      // pick by the release selected
      return Object.values(pickBy(newData, (el) => el.release === filterValue));
    }
    case MUSIC_TRACKS_FILTER: {
      // pick by the track selected
      return Object.values(pickBy(newData, (el) => el.track === filterValue));
    }
    case MUSIC_ARTISTS_FILTER: {
      // pick by the release selected
      return Object.values(
        pickBy(newData, (el) => el.display_artist === filterValue)
      );
    }
    default: {
      return data;
    }
  }
}

function getFilterValue(query, filter) {
  return query.get(filter) === "0" ? 0 : query.get(filter);
}

export {
  applyDateRangeFilter,
  applyStoresFilter,
  applyCountriesFilter,
  applyMusicFilter,
  getFilterValue,
};
