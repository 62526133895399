import React, { useState, useEffect } from "react";
import "./onboarding.scss";
import "../../assets/scss/pt/forms.scss";

import ButtonComponent from "../form/ButtonComponent";

import Footer from "./components/FooterComponent";
import { setUserFacebookArtistPage } from "../../routes/users";

// import { setSpotifyArtist, getSpotifyProfile } from "../../routes/users";

// import spotifyIcon from "../../assets/icons/spotify_white.png";

import ErrorPopup from "../error/ErrorPopup";
import ObDecorations from "./components/ObDecorations";
import ObProgressCircles from "./components/ObProgressCircles";
// import SpotifyPagePicker from "./components/SpotifyPagePicker";
import FbPagePicker from "./components/FbPagePicker";
import Stepper from "../Stepper";
import { getJWT } from "../utils";

function Onboarding2() {
  const jwtToken = getJWT();
  const nextStep = "/onboarding5";

  const [pickedPageId, setPickedPageId] = useState();
  const [isError, setIsError] = useState(false);

  function pickedPageIdCallback(pageId) {
    setPickedPageId(pageId);
  }

  function handleNext() {
    if (pickedPageId) {
      setUserFacebookArtistPage(pickedPageId, jwtToken, { isInstagram: true })
        .then((r) => {
          window.location.assign(nextStep);
          setIsError(false);
          console.log(r);
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
        });
    }
  }

  // function isErrorCallback(v) {
  //   setIsError(v);
  // }

  const steps = [
    {
      name: "",
      callback: () => window.location.replace(`/onboarding1`),
    },
    {
      name: "",
      callback: () => window.location.replace(`/onboarding2`),
    },
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
  ];

  const color = "#15161c";
  const activeColor = "#0ef1db";

  return (
    <>
      <ObDecorations />
      <div className="obCenterBox obCenterBox-2">
        <div className="obModal obModal-2">
          <Stepper
            steps={steps}
            currentStep={2}
            containerClasses={"oboarding-container"}
            color={color}
            activeColor={activeColor}
            lineColor={color}
            finishLineColor={activeColor}
          />
          <div className="obModal-Header obModal-Header-2 noWrap mt-4 mb-2">
            select instagram profile
          </div>
          <br />
          <FbPagePicker
            pickedPageIdCallback={pickedPageIdCallback}
            isInstagram={true}
          />
          <div
            className="obSkip obSkip-2"
            onClick={() => window.location.assign(nextStep)}
          >
            Skip this step
          </div>
          <ButtonComponent
            className="obNextButton obNextButton-2"
            onClick={handleNext}
          >
            Next
          </ButtonComponent>
        </div>
      </div>
      <div className="w-100 mx-auto">
        <ErrorPopup
          error={isError}
          style={{ fontSize: "15px", position: "relative" }}
          className="text-center mx-auto"
          customText="Something went wrong, please refresh the page"
        />
      </div>
      <Footer />
    </>
  );
}

export default Onboarding2;
