import React, { useState } from "react";
import Portal from "../../../../Portal";
import VerifySMS2FAPhoneModal from "./VerifySMS2FAPhoneModal";
import TextMessageIcon from "../../../../../assets/TextMessageIcon.png";
import EditIcon from "../../../../../assets/EditIcon.png";
import ButtonComponent from "../../../../form/ButtonComponent";
import ChangePhoneModal from "../../../../modals/ChangePhoneModal";
import {
  disableSMSTwoFASetup,
  setupSMSTwoFA,
  verifySMSTwoFASetup,
} from "../../../../../services/auth";
import ConfirmPasswordModal from "../../../../modals/ConfirmPasswordModal";

const SMS2FASetup = ({
  setToast,
  refetch2FAState,
  twoFAState,
  reCaptchaRef,
}) => {
  const [isOpenVerifyPhoneModal, setIsOpenVerifyPhoneModal] = useState(false);
  const [showChangePhoneModal, setShowChangePhoneModal] = useState(false);
  const [showDisableTwoFASMSModal, setShowDisableTwoFASMSModal] =
    useState(false);

  const enabled = twoFAState?.enabled;

  const handleOnModalClose = () => {
    setShowDisableTwoFASMSModal(false);
    setShowChangePhoneModal(false);
    setIsOpenVerifyPhoneModal(false);
  };

  const handleOpenVerifyPhoneModal = () => {
    setIsOpenVerifyPhoneModal(true);
  };

  const handleSetup2FASMS = async (phone, countryCode) => {
    try {
      window.scrollTo(0, 0);
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      const res = await setupSMSTwoFA(phone, countryCode, token);
      if (res.data.success) {
        setToast({
          open: true,
          message: `Verification code has been sent to ${phone}. Please check and enter your code to set up your phone.`,
          type: "success",
        });
      }
      return { success: true };
    } catch (error) {
      reCaptchaRef.current.reset();
      setToast({
        open: true,
        message: error?.response?.data?.message
          ? error.response.data.message
          : "An unexpected error occurred. Please try again.",
        type: "danger",
      });
      return { success: false };
    }
  };

  const handleVerify2FASetup = async (otp, phone) => {
    try {
      const res = await verifySMSTwoFASetup(otp);
      if (res.data.success) {
        setToast({
          open: true,
          message: `${phone} has been setup successfully.`,
          type: "success",
        });

        return { verified: true, backupCodes: res.data.backup };
      }
      return { verified: false };
    } catch (error) {
      setToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occurred. Please try again.",
        type: "danger",
      });
      return { verified: false };
    }
  };

  const handleChangePhone = () => setShowChangePhoneModal(true);

  const handleShowTwoFASMSDisableModal = () => {
    setShowDisableTwoFASMSModal(true);
  };

  const handleDisable2FASMS = async (password, setErrorMsg) => {
    try {
      const res = await disableSMSTwoFASetup(password);
      if (res.data.success) {
        setTimeout(refetch2FAState, 1000);

        setShowDisableTwoFASMSModal(false);

        setToast({
          open: true,
          type: "success",
          message: `2FA by SMS has been disabled successfully.`,
        });
      } else {
        setToast({
          type: "danger",
          open: true,
          message: res.data.message,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMsg(error.response.data.message);
      } else {
        setToast({
          open: true,
          message: "An unexpected error occurred. Please try again.",
          type: "danger",
        });
      }
    }
  };

  return (
    <div className="VerifyPhoneWrapper">
      <div className="row w-100 mx-0">
        <img src={TextMessageIcon} alt="texticon" width={30} height={30} />
        <div className="col">
          <div className="row justify-content-between mb-3">
            <div className="d-flex pl-3">
              <div>Text message</div>{" "}
              {enabled && (
                <span className="ml-3" title="Change phone">
                  <img
                    src={EditIcon}
                    alt="change phone"
                    width={24}
                    height={24}
                    className="cursor-pointer"
                    onClick={handleChangePhone}
                  />
                </span>
              )}
            </div>

            <div>
              <span
                className={`rounded security-two-factor-verification__status-${
                  enabled ? "enabled" : "disabled"
                }`}
              >
                <span className="txt--8px txt--uppercase">
                  {enabled ? "Enabled" : "Disabled"}
                </span>
              </span>
            </div>
          </div>

          {enabled ? (
            <>
              <div className="text-muted">
                Verification codes are sent to mobile number ending with{" "}
                {`****${twoFAState?.phone?.substring(
                  twoFAState?.phone?.length - 4
                )}`}{" "}
                by text message.
              </div>
            </>
          ) : (
            <div className="text-muted">Get codes by text message.</div>
          )}

          <div className="row justify-content-start mx-0">
            {enabled ? (
              <ButtonComponent
                className="playtreksButton  bg-secondary w-100 h-75 mt-4"
                onClick={handleShowTwoFASMSDisableModal}
              >
                Disable
              </ButtonComponent>
            ) : (
              <ButtonComponent
                className="playtreksButton w-100 h-75 mt-4"
                onClick={handleOpenVerifyPhoneModal}
              >
                Set up
              </ButtonComponent>
            )}
          </div>

          <Portal selector="body">
            {isOpenVerifyPhoneModal && (
              <VerifySMS2FAPhoneModal
                onCloseRequest={handleOnModalClose}
                onSendOTP={handleSetup2FASMS}
                onVerifyOTP={handleVerify2FASetup}
                refetch2FAState={refetch2FAState}
                setToast={setToast}
              />
            )}

            {showChangePhoneModal && (
              <ChangePhoneModal
                onCloseRequest={handleOnModalClose}
                refetch2FAState={refetch2FAState}
                setToast={setToast}
                twoFAState={twoFAState}
                reCaptchaRef={reCaptchaRef}
              />
            )}

            {showDisableTwoFASMSModal && (
              <ConfirmPasswordModal
                onCloseRequest={handleOnModalClose}
                onSubmit={handleDisable2FASMS}
                helperText="Enter your password to disable two factor authentication using SMS"
              />
            )}
          </Portal>
        </div>
      </div>
    </div>
  );
};

export default SMS2FASetup;
