import {
  PUSH_TO_ENTITY_DATA_HISTORY,
  REDUX_SET_RADIO_MONITORING_DATA,
  REDUX_SET_RADIO_AGGREGATION_DATA,
  REDUX_SET_USER_DATA,
  REDUX_SET_USER_AS_MAIN_ARTIST,
  PIN_MAIN_ENTITY,
  UNPIN_MAIN_ENTITY,
  REMOVE_ENTITY_FROM_HISTORY,
  TOGGLE_MAIN_ENTITY,
  REDUX_SET_MAIN_ARTIST_FROM_CHIP,
  REPLACE_ENTITY_DATA_IN_HISTORY,
  REDUX_SET_ENTITY_DELTA_STATS,
  REDUX_SET_ENTITY_STATS_OVERTIME,
  PUSH_TO_USER_DATA,
  LOAD_PLAYLISTS,
  REDUX_SET_WEB_PROFILES,
  PLAYLISTS_NEXT_PAGE,
  PLAYLISTS_PREV_PAGE,
  PLAYLISTS_RESET_OFFSET,
  REDUX_SET_PLAYLISTS_DISPLAY_PLATFORM,
  REDUX_SET_SUGGESTED_ARTISTS,
  REDUX_SET_TRACK_FEATURES,
  REDUX_SET_STATS_CHART_GROUP_NAME,
  REDUX_SET_SEARCH_FILTERS,
  REDUX_RESET_SEARCH_FILTERS,
  REDUX_SET_SEARCH_CACHING,
  REDUX_SET_WELCOME_PAGE_DATA,
  POP_ENTITY,
} from "../actions/dashboardActions";
import { cloneDeep, find, findIndex, remove } from "lodash";
import {
  defaultUserChartColor,
  findFreeChartsColor,
  initializeChartsColorsObject,
  removeTrailingCharacter,
} from "../../components/utils";

const HISTORY = "history";
const USER = "user";

const SPOTIFY = "spotify";

var initialState = {
  entityDataHistory: [],
  userData: {},
  mainEntity: undefined,
  isMainEntityPinned: false,
  playlistsOffset: 0,
  playlistsDisplayPlatform: SPOTIFY,
  playlistsLoading: false,
  statisticsChartBoxGroupName: {},
  searchFilters: {
    artist: true,
    track: true,
    album: true,
    label: true,
  },
  searchCaching: true,
  welcomePageData: {},
  chartsColors: initializeChartsColorsObject(),
};

function dashboardReducer(state = initialState, action) {
  let entityDataHistory;
  let userData;

  function updateMainEntity() {
    if (!state.isMainEntityPinned) state.mainEntity = action.singleEntityData;
  }

  switch (action.type) {
    case REDUX_SET_RADIO_MONITORING_DATA:
      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].radioMonitoringData =
              action.radioMonitoringData;
            state.entityDataHistory = entityDataHistory;
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.radioMonitoringData = action.radioMonitoringData;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          break;
        }
      }

      // update main entity with delta stats if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.radioMonitoringData = action.radioMonitoringData;
        state.mainEntity = mainEntity;
      }
      return state;

    case REDUX_SET_RADIO_AGGREGATION_DATA:
      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].radioAggregationData =
              action.radioAggregationData;
            state.entityDataHistory = entityDataHistory;
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.radioAggregationData = action.radioAggregationData;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          break;
        }
      }

      // update main entity with delta stats if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.radioAggregationData = action.radioAggregationData;
        state.mainEntity = mainEntity;
      }
      return state;

    case REDUX_SET_STATS_CHART_GROUP_NAME:
      const statisticsChartBoxGroupName = cloneDeep(
        state.statisticsChartBoxGroupName
      );

      statisticsChartBoxGroupName[action.groupName] = action.state;
      state.statisticsChartBoxGroupName = statisticsChartBoxGroupName;
      return state;
    case PUSH_TO_ENTITY_DATA_HISTORY:
      entityDataHistory = cloneDeep(state.entityDataHistory);
      if (
        !find(entityDataHistory, {
          spotifyId: action.singleEntityData.spotifyId,
        })
      )
        entityDataHistory.unshift(action.singleEntityData);
      state.entityDataHistory = entityDataHistory;

      updateMainEntity();
      state.playlistsOffset = 0;
      return state;
    case REPLACE_ENTITY_DATA_IN_HISTORY: {
      const index = findIndex(state.entityDataHistory, {
        spotifyId: action.singleEntityData.spotifyId,
      });

      if (index !== -1) {
        entityDataHistory = cloneDeep(state.entityDataHistory);
        // add entity data
        entityDataHistory.splice(index, 1, action.singleEntityData);
        // add color
        entityDataHistory[index].color = findFreeChartsColor(
          state.chartsColors
        );
        // mark color as used
        state.chartsColors[entityDataHistory[index].color] = true;
        // update history
        state.entityDataHistory = entityDataHistory;
      }

      updateMainEntity();
      return state;
    }
    case PUSH_TO_USER_DATA: {
      state.playlistsOffset = 0;
      return state;
    }
    case REDUX_SET_ENTITY_DELTA_STATS: {
      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.singleEntityDeltaStats.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].deltaStats = action.singleEntityDeltaStats;
            state.entityDataHistory = entityDataHistory;
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.deltaStats = action.singleEntityDeltaStats;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          break;
        }
      }

      // update main entity with delta stats if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.deltaStats = action.singleEntityDeltaStats;
        state.mainEntity = mainEntity;
      }
      return state;
    }
    case REDUX_SET_ENTITY_STATS_OVERTIME:
      switch (action.target) {
        case HISTORY: {
          // history
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.singleEntityStatsOvertime?.[0]?.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].statsOvertime =
              action.singleEntityStatsOvertime;
            state.entityDataHistory = entityDataHistory;
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.statsOvertime = action.singleEntityStatsOvertime;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          break;
        }
      }

      // update main entity with delta stats if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.statsOvertime = action.singleEntityStatsOvertime;
        state.mainEntity = mainEntity;
      }
      return state;
    case REDUX_SET_WEB_PROFILES: {
      // Remove any trailing slashes from the links
      ["facebook", "instagram", "twitter", "youtube"].forEach((platform) => {
        action.webProfiles[platform] = removeTrailingCharacter(
          action.webProfiles[platform],
          "/"
        );
      });

      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].webProfiles = action.webProfiles;
            state.entityDataHistory = entityDataHistory;
            break;
          }
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.webProfiles = action.webProfiles;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity
          break;
        }
      }

      // update main entity with playlists if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.webProfiles = action.webProfiles;
        state.mainEntity = mainEntity;
      }

      return state;
    }
    case REDUX_SET_SUGGESTED_ARTISTS: {
      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);
            entityDataHistory[index].suggestedArtists = action.suggestedArtists;
            state.entityDataHistory = entityDataHistory;
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          userData.suggestedArtists = action.suggestedArtists;
          state.userData = userData;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          break;
        }
      }
      // update main entity if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.suggestedArtists = action.suggestedArtists;
        state.mainEntity = mainEntity;
      }
      return state;
    }
    case REDUX_SET_TRACK_FEATURES: {
      const index = findIndex(state.entityDataHistory, {
        spotifyId: action.id,
      });

      // TODO: adapt for albums -> multiple tracks
      const { key, mode, tempo, loudness, time_signature, duration_ms } =
        action.trackFeatures;

      if (index !== -1) {
        entityDataHistory = cloneDeep(state.entityDataHistory);
        entityDataHistory[index].trackFeatures = action.trackFeatures;
        entityDataHistory[index].trackFeaturesAggregations =
          action.trackFeaturesAggregations;
        state.entityDataHistory = entityDataHistory;
      }
      // update main entity if not pinned
      if (!state.isMainEntityPinned) {
        let mainEntity = cloneDeep(state.mainEntity);
        mainEntity.trackFeatures = action.trackFeatures;
        mainEntity.trackFeaturesAggregations = action.trackFeaturesAggregations;
        state.mainEntity = mainEntity;
      }
      return state;
    }
    case LOAD_PLAYLISTS: {
      const key = action.platform + "Playlists";
      const totalsKey = action.platform + "TotalPlaylists";
      switch (action.target) {
        case HISTORY: {
          const index = findIndex(state.entityDataHistory, {
            spotifyId: action.id,
          });

          if (index !== -1) {
            entityDataHistory = cloneDeep(state.entityDataHistory);

            // init with empty
            if (!entityDataHistory[index][key])
              entityDataHistory[index][key] = [];

            if (entityDataHistory[index][key].length == action.offset) {
              entityDataHistory[index][key] = entityDataHistory[index][
                key
              ].concat(action.playlists);

              // set totalPlaylists
              entityDataHistory[index][totalsKey] = action.totalPlaylists;

              state.entityDataHistory = entityDataHistory;
            }
            if (state.mainEntity?.id === action?.id) {
              let mainEntity = cloneDeep(state.mainEntity);
              mainEntity[key] = entityDataHistory[index][key];
              mainEntity[totalsKey] = entityDataHistory[index][totalsKey];
              state.mainEntity = mainEntity;
            }
          }
          break;
        }
        case USER: {
          userData = cloneDeep(state.userData);
          // init with empty
          if (!userData[key]) userData[key] = [];

          if (userData[key].length == action.offset) {
            userData[key] = userData[key].concat(action.playlists);
            state.userData = userData;
          }

          // set totalPlaylists
          userData[totalsKey] = action.totalPlaylists;

          if (state.mainEntity.type !== USER || !state.mainEntity.type)
            return state; // Don't update main entity

          // if (!state.isMainEntityPinned) {
          let mainEntity = cloneDeep(state.mainEntity);
          mainEntity[key] = userData[key];
          mainEntity[totalsKey] = userData[totalsKey];
          state.mainEntity = mainEntity;
          // }
          break;
        }
      }

      return state;
    }
    case REDUX_SET_USER_DATA:
      state.userData = action.userData;
      state.userData.color = defaultUserChartColor;
      return state;
    case PLAYLISTS_NEXT_PAGE:
      state.playlistsOffset += global.config.playlistsPerPage;
      return state;
    case PLAYLISTS_PREV_PAGE:
      state.playlistsOffset -= global.config.playlistsPerPage;
      return state;
    case PLAYLISTS_RESET_OFFSET:
      state.playlistsOffset = 0;
      return state;
    case REDUX_SET_PLAYLISTS_DISPLAY_PLATFORM:
      // playlistsDisplayPlatform = cloneDeep(state.playlistsDisplayPlatform);
      state.playlistsDisplayPlatform = action.platform;
      return state;
    case REDUX_SET_USER_AS_MAIN_ARTIST:
      state.mainEntity = {
        ...state.userData,
        type: "user",
        image: state.userData.profilePicture,
        name:
          state.userData.artistname ||
          state.userData.firstname ||
          state.userData.email,
      };
      state.playlistsOffset = 0;
      return state;
    case PIN_MAIN_ENTITY:
      state.isMainEntityPinned = true;
      return state;
    case UNPIN_MAIN_ENTITY:
      state.isMainEntityPinned = false;
      return state;
    case REMOVE_ENTITY_FROM_HISTORY:
      entityDataHistory = cloneDeep(state.entityDataHistory);
      // mark color as unused
      state.chartsColors[
        entityDataHistory.find((el) => el.spotifyId === action.spotifyId).color
      ] = false;
      entityDataHistory = entityDataHistory.filter(
        (el) => el.spotifyId !== action.spotifyId
      );
      state.entityDataHistory = entityDataHistory;
      return state;
    case TOGGLE_MAIN_ENTITY:
      state.isMainEntityPinned = !state.isMainEntityPinned;
      return state;
    case REDUX_SET_MAIN_ARTIST_FROM_CHIP:
      state.mainEntity = action.singleEntityData;
      state.playlistsOffset = 0;
      return state;
    case REDUX_SET_SEARCH_FILTERS:
      state.searchFilters = action.searchFilters;
      return state;
    case REDUX_RESET_SEARCH_FILTERS:
      Object.keys(state.searchFilters).map(
        (key) => (state.searchFilters[key] = true)
      );
      return state;
    case REDUX_SET_SEARCH_CACHING:
      state.searchCaching = action.searchCaching;
      return state;
    case REDUX_SET_WELCOME_PAGE_DATA:
      let {
        name,
        trackFeatures,
        trackFeaturesAggregations,
        images,
        id,
        external_urls,
        entityType: type,
        statsOvertime,
      } = action;
      state.welcomePageData = {
        name: name,
        trackFeatures: trackFeatures,
        trackFeaturesAggregations: trackFeaturesAggregations,
        statsOvertime: statsOvertime,
        images: images,
        external_urls: external_urls,
        type: type,
        id: id,
      };
      return state;
    case POP_ENTITY:
      state.entityDataHistory.pop();
      return state;
    default:
      return state;
  }
}

export default dashboardReducer;
