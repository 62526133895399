import React from "react";

import { Button } from "reactstrap";

const ButtonComponent = (props) => (
  <div className={props.buttonWrapperClassName}>
    <Button
      className={props.className}
      style={{ border: "none" }}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  </div>
);

export default ButtonComponent;
