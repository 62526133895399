import axios from "axios";
import { getJWT } from "../components/utils";
import jwtDecode from "jwt-decode";

const axiosInstance = axios.create();

const endpoints = {
  createContract: "/contracts",
  getContracts: "/contracts",
  getContract: "/contracts",
  deleteContract: "/contracts",
  updateContract: "/contracts",
  getRhBalance: "/contracts",
  getContractTransactions: "/contracts",
  getContractBalanceByPeriods: "/contracts",
};

export async function createContract(payload) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.createContract}`;
  return axiosInstance.post(url, payload, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function getContracts() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getContracts}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function getContract(contractId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getContract}/${contractId}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function deleteContract(contractId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.deleteContract}/${contractId}`;
  return axiosInstance.delete(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function updateContract({ contractId, ...payload }) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.updateContract}/${contractId}`;
  return axiosInstance.put(
    url,
    { ...payload },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
  );
}

export async function getRhBalance(contractId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getRhBalance}/${contractId}/balance`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}

export async function getContractTransactions(
  contractId,
  selectedPeriodId,
  page,
  limit
) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getContractTransactions}/${contractId}/transactions`;

  const params = {
    period: selectedPeriodId,
    page: page,
    limit: limit,
  };

  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
    params: params,
  });
}

export async function getContractBalanceByPeriods(
  contractId,
  selectedPeriodId
) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getContractBalanceByPeriods}/${contractId}/balanceByPeriods`;

  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
    params: {
      period: selectedPeriodId,
    },
  });
}
