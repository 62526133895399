import React, { useState } from "react";
import {
  uploadCatalogueSummaryFile,
  uploadSalesReport,
} from "../../../routes/admin";
import Dropzone from "../../form/DropzoneComponent";
import Spinner from "../../../assets/spinner.svg";
import { periods } from "./../periods";
import SelectComponent from "./../../form/SelectComponent";
import "./../admin.scss";

function RoyaltiesImportComponent() {
  const [dropzoneIsUploading, setDropzoneIsUploading] = useState(false);
  const [importResult, setImportResult] = useState();
  const [catalogueSumDropzoneIsUploading, setCatalogueSumDropzoneIsUploading] =
    useState(false);
  const [catalogueSumImportResult, setCatalogueSumImportResult] = useState();

  const [selectedPeriodId, setSelectedPeriodId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  async function handleAcceptFile(file, e) {
    if (!selectedPeriodId) {
      // setErrorMessage("Please select a period before uploading.");
      // return;
    }
    // UI state (dropzone)
    setDropzoneIsUploading(true);
    // uploading file
    try {
      // get period data
      // let foundPeriod = periods.find(function (period) {
        // return period._id.$oid == selectedPeriodId;
      // });
      // if(foundPeriod) {
        // periodYear = new Date(foundPeriod.endDate.$date).getFullYear();
        // periodMonth = new Date(foundPeriod.endDate.$date).getMonth();
      // }

      const uploadFileResponse = await uploadSalesReport(
        new Blob(file),
        file[0].name,
        0,
        0,
        0
      );
      // UI state
      setDropzoneIsUploading(false);
      setImportResult(uploadFileResponse);
    } catch (err) {
      setDropzoneIsUploading(false);
      setImportResult(err.response);
    }
  }

  function handleRejectFile(r) {
    console.log(r);
  }

  async function handleAcceptCatalogueSumFile(file, e) {
    // UI state (dropzone)
    setCatalogueSumDropzoneIsUploading(true);
    // uploading file

    uploadCatalogueSummaryFile(new Blob(file), file[0].name)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `NotFoundRecords.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();

        // UI state
        setCatalogueSumDropzoneIsUploading(false);
        setCatalogueSumImportResult(response);
      })
      .catch((err) => {
        setCatalogueSumDropzoneIsUploading(false);
        setCatalogueSumImportResult(err.response);
      });
  }

  const monthMap = {
    1: "Janurary",
    2: "Feburary",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const periodOptions = periods.map((p) => ({
    label: ` ${monthMap[new Date(p.endDate.$date).getMonth() + 1]}, ${new Date(
      p.endDate.$date
    ).getFullYear()}`,
    value: p._id.$oid,
  }));

  const PeriodFilterOptions = ({ setSelectedPeriodId, selectedPeriodId }) => {
    return (
      <div
        className={
          "nrAlbumTitleInputWrapper dropdown-width py-2 py-md-0 text-white"
        }
        id={"month"}
      >
        <div className="inputUpperComment iuc3 playtreksSelector">Period</div>
        <SelectComponent
          value={periodOptions.find((o) => o.value === selectedPeriodId) || ""}
          extendOptionWidth
          id={"month"}
          valuePaddingTop={true}
          placeholderTop="50%"
          placeholderFont="Inter-Medium"
          placeholderColor="white"
          placeholderOpacity="1"
          options={Object.values(periodOptions)}
          customNoOptionsMessage={<p classNam="ml-2">No options</p>}
          selectChangeCallback={(
            val,
            databaseField,
            commentField,
            databaseKey,
            type
          ) => {
            if (type.action !== "input-change") setSelectedPeriodId(val);
          }}
          // to be used for data transfer
          selectBlurCallback={() => {}} // leave empty
        />
      </div>
    );
  };
  return (
    <>
      <div className="row mx-0 px-0 mb-5">
        <div className="col-md-6">
          <div className="adminRoyalties-import-dropzone-wrapper">
           
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>

      

      <div className="row">
        <div className="col">
          <div className="adminRoyalties-import-dropzone-wrapper">
            <Dropzone
                    className="adminRoyalties-import-dropzone"
                    inputClassName="adminRoyalties-import-dropzone-input"
                    onAccept={handleAcceptFile}
                    onReject={handleRejectFile}
                    disabled={dropzoneIsUploading}
                    // acceptFileType="csv"
                  >
                    {!dropzoneIsUploading ? (
                      <>
                        ↥ <br /> Upload confirmed sales report
                      </>
                    ) : (
                      <div className="dashboard-overview-streaming-stats-spinner-wrapper d-flex align-items-center justify-content-center flex-column">
                        <img
                          src={Spinner}
                          alt="loading"
                          className="dashboard-overview-streaming-stats-spinner"
                        />
                        <br />
                        <div> Uploading...</div>
                      </div>
                    )}
                  </Dropzone>
          </div>
          <br />
          <br />
          <div
            className={
              "adminRoyalties-import-importResult" +
              (importResult?.status === 200 ? "-success" : "-error")
            }
          >
            {importResult ? (
              <div className="msg">
                {importResult?.status} {importResult?.statusText}:
                <br /> {importResult?.data?.msg || importResult?.data?.message}
              </div>
            ) : (
              ""
            )}

            {errorMessage ? <div className="msg">{errorMessage}</div> : ""}
          </div>
        </div>

        <div class="col">
          <div className="adminRoyalties-import-dropzone-wrapper">
            
          </div>
          <br />
          <br />
          <div
            className={
              "adminRoyalties-import-importResult" +
              (catalogueSumImportResult?.status === 200 ? "-success" : "-error")
            }
          >
            {catalogueSumImportResult ? (
              <div className="msg">
                {catalogueSumImportResult?.status === 200
                  ? "Upload successful"
                  : catalogueSumImportResult?.data?.msg ||
                    catalogueSumImportResult?.data?.message}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default RoyaltiesImportComponent;
