import React, { useState } from "react";
import SelectComponent from "../../form/SelectComponent";

const contractStatusFilterOptions = {
  "": { label: "All", value: "" },
  draft: {
    label: "Draft",
    value: "draft",
  },
  active: {
    label: "Active",
    value: "active",
  },
  expired: {
    label: "Expired",
    value: "expired",
  },
  closed: {
    label: "Closed",
    value: "closed",
  },
};

const ContractStatusFilter = ({
  setFilter,
  filters,
  options = contractStatusFilterOptions,
}) => {
  const initialContractStatusFilter = filters.find(
    (filter) => filter.id === "status"
  );
  const [contractStatusFilter, setContractStatusFilter] = useState(
    initialContractStatusFilter?.value || ""
  );

  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 py-2 py-md-0 text-white"
      }
      id={"releaseStateFilter"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Status</div>
      <SelectComponent
        value={options[contractStatusFilter].label}
        extendOptionWidth
        id={"contractStatusFilter"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(val) => {
          setContractStatusFilter(val);
          setFilter("status", val);
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

export default ContractStatusFilter;
