import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Arrow from "../../../assets/icons/selectInputArrow.svg";
import "../index.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "../../../components/artistDashboard/TabPanel";
import { SelectV2 } from "../../../components/form/SelectComponent";
import { useQuery } from "react-query";
import { getContracts } from "../../../services/contracts";
import ButtonComponent from "../../../components/form/ButtonComponent";
import { Spinner } from "../../Airplay/Airplay";
import { associateContractToTrack } from "../../../services/distribution";
import { Link, useParams } from "react-router-dom";
import Toast from "../../../components/Toast";
import { useSelector } from "react-redux";
import { labelPlans } from "../../../components/utils";
import envelope from "../../../assets/icons/envelope2.svg";
import Portal from "../../../components/Portal";
import ReactTooltip from "react-tooltip";
import { isEqual } from "lodash";

import { useState } from "react";
import ResendCollaboratorMailModal from "./ResendCollaboratorMailModal";
import ConfirmResendCollaboratorMailModal from "./ConfirmResendCollaboratorMailModal";

const tracksWithLicenseFiles = (tracks) =>
  tracks?.filter(
    (track) =>
      track.licenseFiles &&
      (track?.licenseFiles?.[0] !== {} ||
        track?.licenseFiles?.[1] !== {} ||
        track?.licenseFiles?.[2] !== {})
  );

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const transformContracts = (contracts) =>
  contracts.reduce((acc, contract) => {
    acc[contract._id] = {
      label: contract.name,
      value: contract._id,
      ...contract,
    };
    return acc;
  }, {});

const Track = ({ tracks, track, trackIndex, refetch, isReleaseOwner }) => {
  const { releaseId } = useParams();

  const [activeTab, setActiveTab] = React.useState(0);
  const [error, setError] = React.useState("");
  const [selectedContract, setSelectedContract] = React.useState({});
  const [trackData, setTrackData] = React.useState(track);
  const [edit, setEdit] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [splits, setSplits] = React.useState(track?.splits?.data || []);
  const [showToast, setShowToast] = React.useState(initialToastState);
  const [isOpenResendCollabModal, setIsOpenResendCollabModal] = useState(false);
  const [rightholderToResendMail, setRightholderToResendMail] = useState([]);
  const [isCheckedResendEmail, setIsCheckedResendEmail] = useState([]);
  const [isShowRightholderInfo, setIsShowRightholderInfo] = useState(null);
  const [isOpenConfirmMailModal, setIsOpenConfirmMailModal] = useState(false);
  const [allSplitsVisibile, setAllSplitsVisibile] = useState(
    track?.splitsVisibility ? track.splitsVisibility === "view-all" : false
  );

  const { data: { data: contracts = [] } = {} } = useQuery(
    "contracts",
    getContracts
  );

  const contractOptions = React.useMemo(
    () => transformContracts(contracts || []),
    [contracts]
  );

  const user = useSelector(
    ({ dashboardReducer }) => dashboardReducer.userData || {},
    isEqual
  );

  const hasContract = track?.contract !== undefined;
  const isOwner = user?._id === track?.contract?.user;

  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const isLabelPlan = labelPlans.some((plan) =>
    plan.includes(reduxCurrentPlan)
  );

  const total = splits.reduce((total, split) => total + split.share, 0);

  const handleOnModalClose = () => {
    setIsOpenResendCollabModal(false);
  };

  const handleOnConfirmModalClose = () => {
    setIsOpenConfirmMailModal(false);
    setIsCheckedResendEmail([]);
    clearSelectedCheckboxes();
  };

  const handleOpenResendModal = (rightholder) => () => {
    setIsOpenResendCollabModal(true);
    setIsShowRightholderInfo({ ...rightholder, ...rightholder?.contact });
  };

  const handleOpenConfirmModal = () => {
    setIsOpenConfirmMailModal(true);
  };

  const handleOnCheck = (id, event, rightholder) => {
    const { checked } = event.target;
    if (checked) {
      setIsCheckedResendEmail((prevChecked) => [...prevChecked, id]);
    } else {
      setIsCheckedResendEmail((prevChecked) =>
        prevChecked.filter((checkboxId) => checkboxId !== id)
      );
    }
    setRightholderToResendMail((prev) => [...prev, rightholder] || []);
  };

  const clearSelectedCheckboxes = () => {
    setIsCheckedResendEmail([]);
  };

  const associatedContract = React.useMemo(() => {
    if (!track.contract || !contracts?.length) {
      return {
        label: "",
        value: "",
      };
    }
    const trackContracId = track.contract._id;
    const trackContract = (contracts || []).find(
      (c) => trackContracId === c._id
    );

    return {
      label: trackContract?.name,
      value: trackContracId,
      ...trackContract,
    };
  }, [track.contract, contracts]);

  useEffect(() => {
    setSelectedContract(associatedContract);
  }, [associatedContract]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleOnContractsSelect = (selContr) => {
    setSelectedContract(selContr);
    setTrackData({
      ...trackData,
      contract: selContr,
    });

    // const splits = [selContr]
    //   ?.reduce((acc, contract) => {
    //     acc.push(
    //       contract.rightsholders?.map((rh) => ({
    //         ...rh,
    //         contract: contract.name,
    //         contractId: contract._id,
    //       }))
    //     );
    //     return acc;
    //   }, [])
    //   .flat();

    // setSplits(splits);

    // const error = validateSplitsPercentages(splits);
    // if (error) {
    //   setError("Total split percentage should not exceed 100%");
    // }
  };

  const handleCancel = () => {
    setSelectedContract(associatedContract);
    setTrackData({
      ...trackData,
      contract: associatedContract,
    });
    setSplits(track?.splits?.data ?? []);
    setError("");
    setEdit(false);
    setIsCheckedResendEmail([]);
    setAllSplitsVisibile(
      track?.splitsVisibility ? track.splitsVisibility === "view-all" : false
    );
  };

  const validateSplitsPercentages = (splits) => {
    const total = splits.reduce((total, split) => total + split.share, 0);

    if (total > 100 || total < 0) return true;

    return false;
  };

  const handleSave = async () => {
    const error = validateSplitsPercentages(splits);
    if (error) {
      setError("Total split percentage should not exceed 100%");
      return;
    }

    setError("");
    try {
      setSaving(true);

      await associateContractToTrack({
        contract: selectedContract,
        releaseId,
        trackIndex,
        splitsVisibility: allSplitsVisibile ? "view-all" : "view-self",
      });

      refetch();

      setShowToast({
        open: true,
        message: "Successfully updated",
        type: "success",
      });
      setSaving(false);
      setEdit(false);
    } catch (e) {
      setShowToast({
        open: true,
        message: e.response.data.message
          ? e.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
      setSaving(false);
    }
  };

  const handleRadioChange = (e) => {
    setAllSplitsVisibile(e.target.value === "locked");
  };

  return (
    <div className="p-3" key={trackIndex}>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <Accordion
        className="rounded text-white"
        style={{ backgroundColor: "#2c313a" }}
      >
        <AccordionSummary
          expandIcon={
            <img
              src={Arrow}
              alt="expand"
              style={{ width: "14px", height: "14px" }}
            />
          }
          id="panel1a-header"
        >
          <div className="prrcText font-weight-normal row col justify-content-between user-select-text">
            <span class="prrcWhiteText">
              {" "}
              {track.form["#track_title"]}{" "}
              {track.form["#remix_or_version"]
                ? `(${track.form["#remix_or_version"]})`
                : ""}
            </span>

            <span>
              ISRC:{" "}
              <span className="prrcWhiteText">
                {track?.isrc ? track?.isrc : track?.form["#isrc"]}
              </span>
            </span>
          </div>
        </AccordionSummary>

        <AccordionDetails className="d-block">
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
          >
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Royalty shares" {...a11yProps(1)} />
          </Tabs>

          <TabPanel value={activeTab} index={0}>
            <div class="prrcText font-weight-normal">
              {/* Primary genre */}
              Primary genre:{" "}
              <span className="text-white prrcBadge1">
                {track.form["#primary_genre"]}
              </span>
              {/* Secondary genre */}
              {track.form["#secondary_genre"] ? (
                <>
                  Secondary genre:{" "}
                  <span className="text-white prrcBadge2">
                    {track.form["#secondary_genre"]}
                  </span>
                </>
              ) : (
                ""
              )}
              {/* Language */}
              <div className="pb-3">
                Language:{" "}
                <span className="text-white">{track.form["#language"]}</span>
              </div>
              {/* Copyright  */}
              <div className="pb-3">
                Copyright ©:{" "}
                <span className="text-white ">{track.form["#p_line"]}</span>
              </div>
              {/*   Recording year */}
              <div className="pb-3">
                Recording year:{" "}
                <span className="text-white ">{track.form["#p_year"]}</span>
              </div>
              {/* Explicit lyrics */}
              <div className="pb-3">
                Explicit lyrics:{" "}
                <span className="text-white ">
                  {track?.form["#explicit_lyrics"] ? "Yes" : "No"}
                </span>
              </div>
              {/* License files */}
              <div>
                {tracksWithLicenseFiles(tracks)?.length > 0
                  ? "License files:"
                  : "No license files"}
              </div>
              <div>
                <ul>
                  {tracksWithLicenseFiles(tracks)?.map((track) => {
                    return (
                      <div className="text-white">
                        <li>
                          {track?.form["#track_title"]}
                          {track?.licenseFiles?.map((licenseFile, index) => {
                            if (!licenseFile.url) return <></>;
                            return (
                              <a
                                style={{
                                  marginLeft: "5px",
                                  textDecoration: "underline",
                                }}
                                href={licenseFile?.url}
                                target="_blank"
                              >
                                file {index + 1}
                              </a>
                            );
                          })}
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            {hasContract ? (
              <>
                {isOwner ? (
                  <div className="p-3 my-money-transactions">
                    <p className="pt-2">
                      PlayTreks makes it easy to collaborate. You can route any
                      percentage of earnings from any track to anyone. Please{" "}
                      <span className="text-primary">
                        <a
                          href=" https://support.playtreks.com/articles/pay-collaborators-automatically-with-royalty-splits"
                          target="_blank"
                        >
                          read our support article
                        </a>
                      </span>{" "}
                      before you use this feature.
                    </p>

                    <>
                      {edit && (
                        <div className="mb-4">
                          <p className={"prrcText mb-1 ml-1 fs-14"}>
                            Select contract(s) to associate to the track in
                            order to disburse royalties
                          </p>

                          <SelectV2
                            value={selectedContract || associatedContract}
                            placeholder="Choose contracts"
                            onChange={handleOnContractsSelect}
                            options={Object.values(contractOptions)}
                          />
                        </div>
                      )}
                    </>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {isReleaseOwner ? (
                  <div className="p-3 my-money-transactions">
                    <p className="pt-2">
                      PlayTreks makes it easy to collaborate. You can route any
                      percentage of earnings from any track to anyone. Please{" "}
                      <span className="text-primary">
                        <a
                          href=" https://support.playtreks.com/articles/pay-collaborators-automatically-with-royalty-splits"
                          target="_blank"
                        >
                          read our support article
                        </a>
                      </span>{" "}
                      before you use this feature.
                    </p>

                    <>
                      {edit && (
                        <div className="mb-4">
                          <p className={"prrcText mb-1 ml-1 fs-14"}>
                            Select contract(s) to associate to the track in
                            order to disburse royalties
                          </p>

                          <SelectV2
                            value={selectedContract || associatedContract}
                            placeholder="Choose contracts"
                            onChange={handleOnContractsSelect}
                            options={Object.values(contractOptions)}
                          />
                        </div>
                      )}
                    </>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}

            {trackData?.contract?.rightsholders?.length > 0 ? (
              <>
                <table
                  className="rounded w-100"
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.05)" }}
                >
                  <thead>
                    <tr>
                      <th className="px-3  ">
                        <div className="table-header">Name</div>
                      </th>
                      <th className="px-3 ">
                        <div className="table-header">Type</div>
                      </th>
                      <th className="px-3 ">
                        <div className="table-header">Contract</div>
                      </th>
                      <th className="px-3 ">
                        <div className="table-header">Royalties Share</div>
                      </th>
                      {isLabelPlan && (
                        <th className="px-3 ">
                          <div className="table-header">Expense Share</div>
                        </th>
                      )}
                      {isOwner &&
                      trackData?.contract?.rightsholders?.length > 1 ? (
                        <th className="px-3 ">
                          <div className="table-header">Email</div>
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {trackData?.contract?.rightsholders?.map((c) => (
                      <tr className="border-0">
                        <td className="px-3 py-1">
                          {c?.name
                            ? c.name
                            : `${c?.contact?.firstName ?? ""} ${
                                c?.contact?.lastName ?? ""
                              }`.trim()}
                        </td>
                        <td className="px-3 py-1">{c.type}</td>
                        <td className="px-3 py-1">
                          {isOwner ? (
                            <div
                              className="admin-release-details-link"
                              title={trackData?.contract?.name}
                            >
                              <Link
                                to={`/artistDashboard/contract/${trackData?.contract?._id}`}
                              >
                                {trackData?.contract?.name}
                              </Link>
                            </div>
                          ) : (
                            <div>{trackData?.contract?.name}</div>
                          )}
                        </td>
                        <td className="px-3 text-left py-1">
                          {c.royaltiesShare}%
                        </td>
                        {isLabelPlan && (
                          <td className="px-3 text-left py-1">
                            {c.expenseShare}%
                          </td>
                        )}

                        {c?.type !== "owner" && isOwner && (
                          <td onClick={handleOpenResendModal(c)}>
                            <span
                              className="cursor-pointer px-3"
                              data-for="resend-mail"
                              data-tip="Click on checkbox to resend royalty notification"
                            >
                              <img
                                src={envelope}
                                alt="email button"
                                className="text-primary"
                              />
                            </span>
                          </td>
                        )}
                        {c?.type !== "owner" && !edit && isOwner ? (
                          <td className="px-3 py-1">
                            <div className="cursor-pointer">
                              <input
                                type="checkbox"
                                className="cursor-pointer"
                                id={c._id}
                                checked={isCheckedResendEmail[c?.contact?._id]}
                                onChange={(e) =>
                                  handleOnCheck(c?.contact?._id, e, c)
                                }
                              />
                              <label htmlFor={c._id}></label>
                            </div>
                          </td>
                        ) : (
                          <td className="px-3 py-1"></td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {isOwner && (
                  <div className="adminRoyalties-display-userSearch mt-4">
                    <input
                      type="radio"
                      id="visible-all"
                      name="visible-all"
                      value="locked"
                      checked={allSplitsVisibile}
                      onChange={handleRadioChange}
                      disabled={!edit}
                    />
                    <label htmlFor="visible-all" className="ml-1 ">
                      <span>
                        contract names and shares are visible to all
                        rightsholders
                      </span>
                    </label>
                    <br />
                    <input
                      type="radio"
                      id="visible-self"
                      name="visible-self"
                      value="unlocked"
                      checked={!allSplitsVisibile}
                      onChange={handleRadioChange}
                      disabled={!edit}
                    />
                    <label htmlFor="visible-self" className="ml-1">
                      <span>
                        rightsholders can only see their own name and share
                      </span>
                    </label>
                  </div>
                )}
              </>
            ) : (
              <div className="row justify-content-center">
                <p>
                  <b>
                    Please associate contract(s) in order to specify the splits
                  </b>
                </p>
              </div>
            )}

            {!hasContract ? (
              <>
                {edit ? (
                  <div className="row mx-0 mt-4 px-3 justify-content-center">
                    <div>
                      <ButtonComponent
                        disabled={saving}
                        onClick={handleCancel}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton bg-transparent textLink w-100 h-75"
                      >
                        Cancel
                      </ButtonComponent>
                    </div>
                    <div>
                      <ButtonComponent
                        disabled={saving || !selectedContract}
                        onClick={handleSave}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton w-100 h-75"
                      >
                        {saving ? (
                          <>
                            <span className="mr-2">Saving... </span>
                            <span>
                              <Spinner />
                            </span>
                          </>
                        ) : (
                          "Save"
                        )}
                      </ButtonComponent>
                    </div>
                  </div>
                ) : (
                  <div className="row mx-0 mt-4 px-3 justify-content-center">
                    <div>
                      <ButtonComponent
                        disabled={edit}
                        onClick={() => setEdit(true)}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton h-75 w-100"
                      >
                        Edit
                      </ButtonComponent>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}

            <p className="text-danger px-3">{error}</p>

            {isOwner ? (
              <>
                {edit ? (
                  <div className="row mx-0 px-3 justify-content-center">
                    <div>
                      <ButtonComponent
                        disabled={saving}
                        onClick={handleCancel}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton bg-transparent textLink w-100 h-75"
                      >
                        Cancel
                      </ButtonComponent>
                    </div>
                    <div>
                      <ButtonComponent
                        disabled={saving || !selectedContract}
                        onClick={handleSave}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton w-100 h-75"
                      >
                        {saving ? (
                          <>
                            <span className="mr-2">Saving... </span>
                            <span>
                              <Spinner />
                            </span>
                          </>
                        ) : (
                          "Save"
                        )}
                      </ButtonComponent>
                    </div>
                  </div>
                ) : (
                  <div className="row mx-0 px-3 justify-content-center">
                    <div>
                      <ButtonComponent
                        disabled={edit}
                        onClick={() => setEdit(true)}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton h-75 w-100"
                      >
                        Edit
                      </ButtonComponent>
                    </div>
                    <div>
                      <ButtonComponent
                        disabled={isCheckedResendEmail.length === 0}
                        onClick={handleOpenConfirmModal}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton w-100 h-75"
                      >
                        Send Email
                      </ButtonComponent>
                    </div>
                  </div>
                )}
              </>
            ) : null}

            <Portal selector="body">
              {isOpenResendCollabModal && (
                <ResendCollaboratorMailModal
                  onCloseRequest={handleOnModalClose}
                  rightholderToResendMail={isShowRightholderInfo}
                  setIsOpenResendCollabModal={setIsOpenResendCollabModal}
                />
              )}
            </Portal>
            <Portal selector="body">
              {isOpenConfirmMailModal && (
                <ConfirmResendCollaboratorMailModal
                  onCloseRequest={handleOnConfirmModalClose}
                  rightholderToResendMail={rightholderToResendMail}
                  trackData={trackData?.form}
                  setIsOpenConfirmMailModal={setIsOpenConfirmMailModal}
                  clearSelectedCheckboxes={clearSelectedCheckboxes}
                  releaseId={releaseId}
                />
              )}
            </Portal>
            <ReactTooltip
              place="top"
              backgroundColor="#1d2025"
              className="ptTooltip"
              border
              borderColor="#0ef1db"
              effect="float"
              id="resend-mail"
            />
          </TabPanel>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Track;
