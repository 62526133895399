import axios from "axios";
import { getJWT } from "../components/utils";
import jwtDecode from "jwt-decode";

const axiosInstance = axios.create();

export async function getUserTransactions() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserTransactionsByAlbum() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions/album`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserTransactionsByTrack() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions/track`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserTransactionsByContract() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions/contract`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserRightholdersTransactions() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions/rightholders`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserTransactionsByUser() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions/user`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}
