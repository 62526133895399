export const REDUX_ADD_ALERT = "REDUX_ADD_ALERT";
export const REDUX_REMOVE_ALERT = "REDUX_REMOVE_ALERT";

export function addAlert(text, link, variant, preventDuplicate) {
  return {
    type: REDUX_ADD_ALERT,
    text: text,
    link: link,
    variant: variant,
    preventDuplicate: preventDuplicate,
  };
}

export function removeAlert(alert) {
  return {
    type: REDUX_REMOVE_ALERT,
    alert: alert,
  };
}
