import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "../../screens/Airplay/Airplay";
import ButtonComponent from "../form/ButtonComponent";
import InputComponent from "../form/InputComponent";
import ValidationTextBox from "../form/ValidationTextBox";

const retryWaitTimeByAttempt = {
  1: 60,
  2: 120,
  3: 180,
  4: 240,
  5: 300,
};
const maxAttempts = 5;

const VerifyOTPModal = ({ onResendOTP, onVerifyOTP, verifying }) => {
  const [touched, setTouched] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const [counter, setCounter] = useState(0);
  const [otp, setOTP] = useState("");
  const [otpVerificationError, setOTPVerificationError] = useState("");

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  // verify phone handle input
  const handleOTPChange = (value) => {
    setOTP(value);
    if (touched) validateOTP();
  };

  const handleOTPBlur = () => {
    validateOTP();
    setTouched(true);
  };

  //verify phone validation
  const validateOTP = () => {
    let isError = false;
    if (!Number(otp)) {
      isError = true;
      setOTPVerificationError("Invalid code");
    } else if (otp?.length !== 6) {
      isError = true;
      setOTPVerificationError("Should be of length 6");
    } else {
      isError = false;
      setOTPVerificationError("");
    }
    return isError;
  };

  useEffect(() => {
    setCounter(retryWaitTimeByAttempt[attempts]);
  }, [attempts]);

  const handleResendOTP = () => {
    setAttempts(attempts + 1);
    onResendOTP();
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (validateOTP()) return;

    onVerifyOTP(otp);
  };

  return (
    <form onSubmit={handleVerifyOTP}>
      <div className="mt-4">
        <div>Verify mobile number</div>
      </div>
      <div className="mt-4 infoText text-muted">
        Enter the 6-digit code that has been sent to your mobile number.
      </div>
      <div className="mt-4">
        <div className="inputUpperComment iuc1a  d-none">Code</div>
        <InputComponent
          addon={false}
          alt="2fa code"
          value={otp}
          commentField=".iuc1a "
          paddingTopOnInput={true}
          placeholder="Enter your verification code"
          inputGroupTextClassName="inputGroupText"
          inputClassName="TwoFAInput"
          inputGroupClassName="nrInputGroup nrInputGroup-title"
          changeCallback={handleOTPChange}
          inputType="number"
          databaseField="otp"
          maxlength={6}
          inputBlurCallback={handleOTPBlur}
        />

        <div className="row mx-0 justify-content-between">
          <div>
            <ValidationTextBox
              className="loginValidationBox position-static ml-2"
              display={otpVerificationError?.length}
            >
              {otpVerificationError}
            </ValidationTextBox>
          </div>
          <ButtonComponent
            className={`TwoFAButton text-muted bg-transparent fs-12 pr-0 ${
              counter > 0 ? "" : "textLink"
            }`}
            onClick={
              counter > 0
                ? () => {}
                : attempts <= maxAttempts
                ? handleResendOTP
                : () => {}
            }
            disabled={counter > 0}
          >
            {counter > 0
              ? `Try again in ${counter} seconds`
              : attempts <= maxAttempts
              ? "Didn't receive? Resend code"
              : ""}
          </ButtonComponent>
        </div>
      </div>
      <div className="row justify-content-end ml-0 mt-4">
        <ButtonComponent
          className=" TwoFAButton"
          onClick={handleVerifyOTP}
          type="submit"
        >
          {verifying ? (
            <>
              Verifying...
              <span className="ml-2">
                <Spinner />
              </span>
            </>
          ) : (
            "Verify"
          )}
        </ButtonComponent>
      </div>
    </form>
  );
};

export default VerifyOTPModal;
