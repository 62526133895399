import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

// export async function getTopArtists(query) {
//   let url = `${global.config.API_URL}/api/artists/get_top_artists?query=${query}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

// export async function getSuggestedArtists(query, { type = "artist" }) {
//   let url = `${global.config.API_URL}/api/artists/get_suggested_artists?query=${query}&type=${type}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }
