import axios from "axios";
import { getJWT } from "../../components/utils";

const axiosInstance = axios.create();

export async function getUserTransactions(userEmail) {
  let url = `${global.config.API_URL}/api/admin/transactions/${userEmail}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function createTransaction(payload) {
  let url = `${global.config.API_URL}/api/admin/transactions`;
  return axiosInstance.post(url, payload, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getUserBalances(selectedPeriod) {
  let url =
    `${global.config.API_URL}/api/admin/userbalances` +
    (selectedPeriod ? `?period=${selectedPeriod}` : "");
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}
