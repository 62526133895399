import React from "react";
import ReleaseCardComponent from "../../distribution/components/ReleaseCardComponent";
import { Pagination } from "../../Table";
import Spinner from "../../../assets/spinner.svg";

const GridView = ({ tableInstance, toggleDrawer, loading }) => {
  const {
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <>
      {loading && (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper py-3">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      )}
      {page.length > 0
        ? page.map((row, index) => {
            prepareRow(row);
            const release = row.original;

            if (release.release.state === "draft" && !release.release.tracks) {
              var draftType = "draft - uploadTracks";
            }
            return (
              <div className="p-3 col-auto" key={release.release.releaseId}>
                <div
                  onClick={toggleDrawer(true, release)}
                  className="release-card-link"
                >
                  <ReleaseCardComponent
                    key={index}
                    className={"dspRcc" + (index + 1)}
                    state={draftType || release.release.state}
                    release={release.release}
                    releaseId={release.release.releaseId}
                    // disableCoverArt
                  />
                </div>

                <hr className="m-0" />
              </div>
            );
          })
        : !loading && <p class="text-white">No results found</p>}
      {/* Pagination */}
      <div
        className="row justify-content-center  position-sticky  w-100"
        style={{ bottom: 0 }}
      >
        <div
          className="row h-100 w-100 w-md-50 rounded-top justify-content-center pt-3 pb-5 pb-lg-3"
          style={{ background: "rgb(34 38 44)" }}
        >
          <Pagination
            pageSize={pageSize}
            pageIndex={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </>
  );
};

export default GridView;
