import React from "react";
import "./index.scss";

const AmountCard = (props) => {
  const { transactions } = props;

  const totalAmount = React.useMemo(() => {
    let total = 0;
    transactions.forEach((transaction) => {
      total += transaction.amount;
    });
    if (total === 0) {
      return 0;
    }

    return total.toFixed(4);
  }, [transactions]);

  const totalDebit = React.useMemo(() => {
    let total = 0;
    transactions
      .filter((t) => t.type === "debit")
      .forEach((transaction) => {
        if (transaction.type === "debit") {
          total += transaction.amount;
        }
      });

    if (total === 0) {
      return 0;
    }

    return total.toFixed(4);
  }, [transactions]);

  const totalCredit = React.useMemo(() => {
    let total = 0;
    transactions.forEach((transaction) => {
      if (transaction.type === "credit") {
        total += transaction.amount;
      }
    });

    if (total === 0) {
      return 0;
    }

    return total.toFixed(4);
  }, [transactions]);
  return (
    <div className="row mx-0 px-0  mb-2 ">
      <div className="row mx-0  col-sm-12 col-md-4 px-0">
        <div className="transaction total w-100">
          <div className="transaction-title mb-2">Total amount 💸</div>
          <div className="transaction-amount">
            € {Number(totalAmount).toFixed(2)}{" "}
          </div>
        </div>
      </div>
      <div className="row mx-0  col-sm-12 col-md-4 px-0 px-md-3">
        <div className="transaction credit w-100">
          <div className="transaction-title mb-2">Total amount credited 📈</div>
          <div className="transaction-amount text-primary">
            <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
            {Number(totalCredit).toFixed(2)}
          </div>
        </div>
      </div>
      <div className="row mx-0  col-sm-12 col-md-4 px-0">
        <div className="transaction debit w-100">
          <div className="transaction-title mb-2">Total amount debited 📉</div>
          <div className="transaction-amount" style={{ color: "red" }}>
            <i class="fa-solid fa-money-bill-trend-up"></i>€{" "}
            {Number(totalDebit).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmountCard;
