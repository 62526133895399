import { externalLoginCall } from "../../../routes/external";

function handleExternalLoginCallWithState(service, r) {
  let search = r.data.trim("?");
  let params = new URLSearchParams(search);
  let state = params.get("state");
  window.localStorage.setItem(`${service}State`, state);
  window.location.replace(r.data);
}

export function handleExternalLogin(service) {
  return externalLoginCall(service).then((r) =>
    handleExternalLoginCallWithState(service, r)
  );
}

export function handleSpotifyExternalLogin() {
  return handleExternalLogin("spotify");
}
