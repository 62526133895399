import React, { useEffect, useRef, useState } from "react";
import Portal from "../../Portal";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { reduxSetUserData } from "../../../redux/actions/dashboardActions";

//query string support
import { Link, useLocation } from "react-router-dom";

import UserProfile from "../UserProfile";

import ProfilePictureUploadComponent from "./components/ProfilePictureUploadComponent";
import InputComponent from "../../form/InputComponent";
import ButtonComponent from "../../form/ButtonComponent";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import ValidationTextBox from "../../form/ValidationTextBox";
import {
  getUserData,
  updateUserProfile,
  uploadProfilePictureToS3,
  getFbProfiles,
  getSpotifyProfile,
  deleteSpotifyProfile,
  deleteUserFacebookArtistPage,
  deleteUserInstagramArtistPage,
  deleteUserAppleArtistPage,
  resendValidationEmail,
} from "../../../routes/users";

import { getPaymentMethod } from "../../../routes/payments";

import "./index.scss";
import ConnectedPlatforms from "../../onboarding/components/ConnectedPlatforms";
import ConnectedPages from "./components/ConnectedPages";
import AddSpotifyArtistModal from "./components/AddSpotifyArtistModal";
import AddFbPageModal from "./components/AddFbPageModal";
import AddInstagramPageModal from "./components/AddInstagramPageModal";
import AddApplePageModal from "./components/AddApplePageModal";
import SetPaymentMethodComponent from "../../payments/components/SetPaymentMethodComponent";
import EditPaymentMethodComponent from "../../payments/components/EditPaymentMethodComponent";

import MobileMenuBurger, {
  MobileMenu,
} from "../../artistDashboard/MobileMenu/MobileMenu";
import ProfileHeader from "./components/ProfileHeader";

import { getUserBillingInfo } from "../../../routes/sales";

import RequestPayoutModal, {
  billingFormConfig,
} from "../../distribution/components/RequestPayoutModal";
import infoBulbIcon from "../../../assets/icons/bulb.svg";

import { get2FAState } from "../../../services/auth";
import { useQuery } from "react-query";

import { getJWT } from "../../utils";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { Spinner } from "../../../screens/Airplay/Airplay";
import PhoneVerificationModal from "../../modals/PhoneVerificationModal";
import { sendSMSOTP, verifyProfileMobilePhone } from "../../../services/auth";
import Toast from "../../Toast";
import SetUp2FA from "./2FA";
import countryNames from "react-phone-number-input/locale/en.json";
import SelectComponent from "../../form/SelectComponent";
import zipcodeValidationRegex from "../../../shared/zipcodeValidationRegex.json";
import ReCAPTCHA from "react-google-recaptcha";

const SPOTIFY = "spotify";
const FACEBOOK = "facebook";
const INSTAGRAM = "instagram";
const APPLE = "apple";

//Validation schema
const profileFormValidationSchema = Yup.object().shape({
  firstname: Yup.string().required("Firstname required"),
  lastname: Yup.string().required("Lastname required"),
  address: Yup.string().required("Address required"),
  state: Yup.string().required("State required"),
  city: Yup.string().required("City required"),
  phone: Yup.string()
    .required("Phone number required")
    .test(
      "phone",
      "Invalid phone number",
      (value) => value && isPossiblePhoneNumber(value)
    ),
  country: Yup.object().shape({
    label: Yup.string().required("Country required"),
    value: Yup.string().required("Country required"),
  }),
  zipcode: Yup.string()
    .required("Zipcode required")
    .test(
      "zipCode",
      "Invalid zipcode. Please check the code and format",
      function (zipcode) {
        if (!zipcode) return false;

        const country = this.parent.country?.value || "";
        if (!country) return true;

        if (!zipcodeValidationRegex[country]) return zipcode.length <= 20;

        let r;
        try {
          r = new RegExp(zipcodeValidationRegex[country]);
          return r.test(zipcode);
        } catch (error) {
          return zipcode.length <= 20;
        }
      }
    ),
});

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const countriesOptions = getCountries().reduce((accumulator, countryCode) => {
  accumulator[countryCode] = {
    label: countryNames[countryCode],
    value: countryCode,
  };
  return accumulator;
}, {});

function EditProfile() {
  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (query.get("editPayoutDetails")) setPayoutDetailsModal(true);
  }, [query]);

  // local storage - session state
  const jwtToken = getJWT();
  window.localStorage.setItem("playtreksNextStep", window.location.pathname);
  const [showToast, setShowToast] = useState(initialToastState);

  const [user, setUser] = useState({});
  const [userBilling, setUserBilling] = useState();
  const [profilePictureFile, setProfilePictureFile] = useState();
  const [isProfileImageEdited, setIsProfileImageEdited] = useState(false); // => "editing" if edit in progress, "saved" if saved

  // state variables for page profiles
  const [spotifyArtistProfile, setSpotifyArtistProfile] = useState();
  const [facebookArtistProfile, setFacebookArtistProfile] = useState();
  const [instagramArtistProfile, setInstagramArtistProfile] = useState();
  const [appleArtistProfile, setAppleArtistProfile] = useState();

  const [phoneCountryCode, setPhoneCountryCode] = useState("");

  // editing modals
  const [spotifyArtistModal, setSpotifyArtistModal] = useState(false);
  const [fbPageModal, setFbPageModal] = useState(false);
  const [instagramPageModal, setInstagramPageModal] = useState(false);
  const [applePageModal, setApplePageModal] = useState(false);

  const [payoutDetailsModal, setPayoutDetailsModal] = useState(false);

  const [openPhoneVerificationModal, setOpenPhoneVerificationModal] =
    useState(false);

  // payment status
  const [paymentMethodState, setPaymentMethodState] = useState({
    connected: false,
  });

  // email verification status
  const [verificationResent, setVerificationResent] = useState(false);

  // REDUX
  const dispatch = useDispatch();
  const reduxUserProfiles = useSelector(
    (state) => state?.dashboardReducer?.userData?.service_profiles
  );

  const reCaptchaRef = useRef();

  const { data: { data: twoFAState } = {}, loading: twoFAStateLoading } =
    useQuery("2fa-state", get2FAState, { retry: false });

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
    getPaymentMethod(jwtToken).then((r) => {
      if (!r.data.card) setPaymentMethodState({ connected: false });
      else setPaymentMethodState({ connected: true, data: r.data.card });
    });
    getUserBillingInfo()
      .then((r) => setUserBilling(r.data))
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    // TODO: hacky solution - results in too many requests to backend
    // solution - make this pages data depend on redux state entirely
    fetchData();
  }, [reduxUserProfiles]);

  async function fetchData(service) {
    const userData = await getUserData(jwtToken);
    setUser(userData?.data);
    if (service === FACEBOOK || !service) {
      try {
        const fbProfile = await getFbProfiles(
          jwtToken,
          true // onlyUserSelected = true, route will return only one profile
        );
        let fbResult;
        if (fbProfile.data.serviceNotConnected) {
          fbResult = "service not connected";
        } else if (!fbProfile?.data?.fbProfiles?.[0]) {
          fbResult = "no profile";
        } else {
          fbResult = fbProfile?.data?.fbProfiles?.[0];
        }
        setFacebookArtistProfile(fbResult);
      } catch (err) {
        console.log(err);
      }
    }
    if (service === INSTAGRAM || !service) {
      // Repeat the same for Instagram
      try {
        const igProfile = await getFbProfiles(
          jwtToken,
          true, // onlyUserSelected = true, route will return only one profile
          { isInstagram: true }
        );
        let igResult;
        if (igProfile.data.serviceNotConnected) {
          igResult = "service not connected";
        } else if (!igProfile?.data?.fbProfiles?.[0]) {
          igResult = "no profile";
        } else {
          igResult = igProfile?.data?.fbProfiles?.[0];
        }
        setInstagramArtistProfile(igResult);
      } catch (err) {
        console.log(err);
      }
    }
    if (service === SPOTIFY || !service) {
      try {
        const spotifyProfile = await getSpotifyProfile(jwtToken); // this route always returns one profile
        setSpotifyArtistProfile(spotifyProfile?.data || "no profile");
      } catch (err) {
        console.log(err);
      }
    }

    // Hack to not dispatch this action on load
    if (service) dispatch(reduxSetUserData());
  }

  function imageUploadCallback(file) {
    setProfilePictureFile(file);
    setIsProfileImageEdited(true);
  }

  async function handleUpdateProfile(userForm, formActions, verified = false) {
    if (
      !verified &&
      (!user?.phone || !user?.phoneVerified || user.phone !== userForm.phone)
    ) {
      setOpenPhoneVerificationModal(true);
      return;
    }

    try {
      await updateUserProfile(userForm, jwtToken);

      if (profilePictureFile) {
        await uploadProfilePictureToS3(profilePictureFile[0], jwtToken);
        setIsProfileImageEdited(false);
      }

      setOpenPhoneVerificationModal(false);
    } catch (error) {
      formActions.setSubmitting(false);

      setShowToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
      setOpenPhoneVerificationModal(false);
      return;
    }

    formActions.setSubmitting(false);
    dispatch(reduxSetUserData());
  }

  // TODO: these are the endpoints to the new routes, they can be abstracted...
  function deleteProfile(service) {
    if (service === SPOTIFY) {
      let spotifyId = spotifyArtistProfile.id;
      setSpotifyArtistProfile();
      deleteSpotifyProfile(spotifyId, jwtToken).then((r) => fetchData(SPOTIFY));
    } else if (service === FACEBOOK) {
      let facebookId = facebookArtistProfile.id;
      setFacebookArtistProfile();
      deleteUserFacebookArtistPage(facebookId, jwtToken).then((r) =>
        fetchData(FACEBOOK)
      );
    } else if (service === INSTAGRAM) {
      let instagramId = instagramArtistProfile.id;
      setInstagramArtistProfile();
      deleteUserInstagramArtistPage(instagramId, jwtToken).then((r) =>
        fetchData(INSTAGRAM)
      );
    } else if (service === APPLE) {
      let appleId = instagramArtistProfile.id;
      setAppleArtistProfile();
      deleteUserAppleArtistPage(appleId, jwtToken).then((r) =>
        fetchData(APPLE)
      );
    }
  }

  const serviceProfiles = user.service_profiles;

  const userUploadedProfilePicture = user.profilePicture;

  const profilePictureFromPlatform =
    serviceProfiles?.spotify?.images?.[0]?.url ||
    serviceProfiles?.facebook?.picture?.data?.url ||
    serviceProfiles?.google?.picture; // for now taking the image that's available (prioritizing spotify)

  function closeModal(fetch, service) {
    if (service === SPOTIFY) {
      // setSpotifyArtistProfile();
      setSpotifyArtistModal(false);
      if (fetch) fetchData(SPOTIFY);
    } else if (service === FACEBOOK) {
      // setFacebookArtistProfile();
      setFbPageModal(false);
      if (fetch) fetchData(FACEBOOK);
    } else if (service === INSTAGRAM) {
      // setInstagramArtistProfile();
      setInstagramPageModal(false);
      if (fetch) fetchData(INSTAGRAM);
    } else if (service === APPLE) {
      // setAppleArtistProfile();
      setApplePageModal(false);
      if (fetch) fetchData(APPLE);
    }
    dispatch(reduxSetUserData());
  }

  function handleResendVerification() {
    resendValidationEmail(jwtToken);
    setVerificationResent(true);
    setTimeout(() => setVerificationResent(false), 5000);
  }

  const handleOnModalClose = () => {
    setOpenPhoneVerificationModal(false);
  };

  const handleSendOTP = async (phone) => {
    try {
      window.scrollTo(0, 0);
      const token = await reCaptchaRef.current.executeAsync();
      reCaptchaRef.current.reset();
      const res = await sendSMSOTP(phone, phoneCountryCode, token);
      if (res.data.success) {
        setShowToast({
          open: true,
          message:
            "Verification code has been sent successfully. Please check and enter your code to verify your mobile number.",
          type: "success",
        });

        return { success: true };
      }
      return { success: false };
    } catch (error) {
      setShowToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
      return { success: false };
    }
  };

  const handleVerifyOTP = async (otp, phone) => {
    try {
      const res = await verifyProfileMobilePhone(otp, phone, phoneCountryCode);
      if (res.data.success) {
        setShowToast({
          open: true,
          message: "Mobile number has been verified successfully.",
          type: "success",
        });

        return true;
      }
      return false;
    } catch (error) {
      setShowToast({
        open: true,
        message: error?.response?.data?.msg
          ? error.response.data.msg
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
      return false;
    }
  };

  const HeaderSaveComponent = ({ dirty, isSubmitting, handleSubmit }) => (
    <div className="row justify-content-end align-items-center mr-5 mb-3">
      <div className="fs-14">
        {((dirty && !isSubmitting) || isProfileImageEdited) && (
          <span className="text-danger">
            unsaved changes <span>&rarr;</span>
          </span>
        )}
      </div>

      <ButtonComponent
        buttonWrapperClassName="pl-3"
        className={"playtreksButton w-100 h-100"}
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          <>
            Saving...
            <span className="ml-2">
              <Spinner />
            </span>
          </>
        ) : (
          "Save"
        )}
      </ButtonComponent>
    </div>
  );

  let userBillingAddress = userBilling?.billingFormData
    ? Object.keys(userBilling?.billingFormData)
    : [];

  // create map from config
  let billingFormMap = {};
  billingFormConfig.forEach(
    (r, i) => (billingFormMap[r.id] = r.label.replace("*", ""))
  );

  const isTwoFAEnabled = twoFAState?.sms?.enabled || twoFAState?.totp?.enabled;

  function verifyCaptchaCallback(response) {}

  function handleOnCaptchaError() {
    reCaptchaRef.current.reset();
  }

  function handleOnCaptchaExpiry() {
    reCaptchaRef.current.reset();
  }

  return (
    <>
      <div className="releaseFinishCaptcha">
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={process.env.REACT_APP_INVISIBLE_CAPTCHA_SITE_KEY}
          size={"invisible"}
          theme="dark"
          onChange={verifyCaptchaCallback}
          onExpired={handleOnCaptchaExpiry}
          onErrored={handleOnCaptchaError}
        />
      </div>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <main className="dashboard-profile">
        <AddSpotifyArtistModal
          closeModalCallback={closeModal}
          className={!spotifyArtistModal ? "d-none" : ""}
        />
        <AddFbPageModal
          closeModalCallback={closeModal}
          className={!fbPageModal ? "d-none" : ""}
        />
        <AddInstagramPageModal
          closeModalCallback={closeModal}
          className={!instagramPageModal ? "d-none" : ""}
        />
        <AddApplePageModal
          closeModalCallback={closeModal}
          className={!applePageModal ? "d-none" : ""}
        />

        {payoutDetailsModal ? (
          <RequestPayoutModal infoEditMode setModal={setPayoutDetailsModal} />
        ) : (
          ""
        )}

        <ProfileHeader />

        {/* User information */}
        <section className="mx-0">
          <Formik
            validationSchema={profileFormValidationSchema}
            enableReinitialize
            initialValues={{
              firstname: user?.firstname || "",
              lastname: user?.lastname || "",
              artistname: user?.artistname || "",
              address: user?.address || "",
              city: user?.city || "",
              state: user?.state || "",
              zipcode: user?.zipcode || "",
              phone: user?.phone || "",
              country: user?.country ? countriesOptions[user.country] : "",
            }}
            onSubmit={handleUpdateProfile}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleSubmit,
                isSubmitting,
                dirty,
                setFieldValue,
                setFieldTouched,
                setSubmitting,
              } = props;

              const {
                firstname,
                lastname,
                artistname,
                address,
                state,
                city,
                zipcode,
                phone,
                country,
              } = values;

              return (
                <>
                  <Portal selector="body">
                    {openPhoneVerificationModal && (
                      <>
                        <PhoneVerificationModal
                          onCloseRequest={handleOnModalClose}
                          onSendOTP={handleSendOTP}
                          onVerifyOTP={handleVerifyOTP}
                          values={values}
                          handleSubmit={handleUpdateProfile}
                          setSubmitting={setSubmitting}
                        />
                      </>
                    )}
                  </Portal>
                  <Form className="position-relative">
                    <HeaderSaveComponent
                      handleSubmit={handleSubmit}
                      isSubmitting={isSubmitting}
                      dirty={dirty}
                    />
                    <div className="row">
                      <div className="col-xl-4  mx-0 mb-4 ">
                        <div className="row col mx-0 mb-3 justify-content-center align-items-center ">
                          Profile image
                        </div>
                        <div className="row col mx-0 justify-content-center align-items-center ">
                          <ProfilePictureUploadComponent
                            imageFromPlatform={
                              userUploadedProfilePicture ||
                              profilePictureFromPlatform
                            }
                            imageUploadCallback={imageUploadCallback}
                          />
                        </div>
                      </div>

                      <div className="col-xl-8  mx-0 mb-4 ">
                        <div className="row mx-0 mb-3 justify-content-around align-items-end ">
                          <div className=" col-md-5">
                            {/* Email */}
                            <div class="row mb-3">
                              <div className="dashboard-profile-info">
                                <span className="dashboard-profile-info-sub">
                                  <span className="dashboard-profile-title dashboard-profile-title-grey">
                                    Email
                                  </span>
                                  <br />
                                  {user?.email}{" "}
                                  {user?.validated === false ? (
                                    <span
                                      style={{
                                        fontSize: "0.7em",
                                        color: "yellow",
                                      }}
                                    >
                                      validation required{" "}
                                      <span aria-label="warning" alt="warning">
                                        ⚠️
                                      </span>{" "}
                                      &nbsp;
                                      <span
                                        className="dashboard-profile-info-resendEmail"
                                        onClick={handleResendVerification}
                                      >
                                        {verificationResent
                                          ? "sent! ✓"
                                          : "resend activation link ↺"}
                                      </span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontSize: "0.7em",
                                        color: "#90FF00",
                                      }}
                                    >
                                      validated ✓
                                    </span>
                                  )}
                                </span>
                              </div>
                            </div>

                            <div class="row mb-3">
                              {/* Artist name field */}
                              <div
                                className={
                                  (user.artistname ? "" : "d-none") +
                                  " inputUpperComment iuc3"
                                }
                              >
                                Artist name
                              </div>
                              <InputComponent
                                value={artistname}
                                commentField=".iuc3"
                                placeholder="Artist name"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName={
                                  (user.artistname
                                    ? "interMediumPaddingTop"
                                    : "") + " dashboard-profile-input-field"
                                }
                                addon={false}
                                paddingTopOnInput={true}
                                changeCallback={(value) =>
                                  setFieldValue("artistname", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("artistname", true)
                                }
                                databaseField="artistname"
                                maxlength={100}
                              />
                            </div>

                            {/* First name field */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc1">
                                First name*
                              </div>
                              <InputComponent
                                value={firstname}
                                commentField=".iuc1"
                                placeholder="First name*"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("firstname", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("firstname", true)
                                }
                                databaseField="firstname"
                                maxlength={20}
                              />
                              <ErrorMessage
                                name={"firstname"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>

                            {/* Last name field */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc2">
                                Last name*
                              </div>
                              <InputComponent
                                value={lastname}
                                commentField=".iuc2"
                                placeholder="Last name*"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("lastname", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("lastname", true)
                                }
                                databaseField="lastname"
                                maxlength={20}
                              />
                              <ErrorMessage
                                name={"lastname"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>
                            <div class="row mb-3">
                              <div class="position-relative col px-0">
                                <div className="inputUpperComment phone-label">
                                  Mobile phone*
                                </div>
                                <PhoneInput
                                  name="phone"
                                  placeholder="Mobile phone"
                                  limitMaxLength
                                  // defaultCountry="BE"
                                  international
                                  value={phone}
                                  onCountryChange={setPhoneCountryCode}
                                  onBlur={() => setFieldTouched("phone", true)}
                                  onChange={(val) =>
                                    setFieldValue("phone", val)
                                  }
                                />
                                <ErrorMessage
                                  name={"phone"}
                                  component="div"
                                  className="row text-danger line-height-normal mx-0 fs-12 "
                                />
                              </div>
                            </div>
                          </div>

                          {/*Address fields */}
                          <div className=" col-md-5">
                            {/* Street adderss */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc2">
                                Street address*
                              </div>
                              <InputComponent
                                value={address}
                                commentField=".iuc2"
                                placeholder="Street"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("address", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("address", true)
                                }
                                databaseField="address"
                              />
                              <ErrorMessage
                                name={"address"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>

                            {/* Zip code */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc2">
                                Zip code*
                              </div>
                              <InputComponent
                                value={zipcode}
                                commentField=".iuc2"
                                placeholder="Zip code*"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("zipcode", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("zipcode", true)
                                }
                                databaseField="zipcode"
                                maxlength={20}
                              />
                              <ErrorMessage
                                name={"zipcode"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>

                            {/* City */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc2">
                                City*
                              </div>
                              <InputComponent
                                value={city}
                                commentField=".iuc2"
                                placeholder="City*"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("city", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("city", true)
                                }
                                databaseField="city"
                                maxlength={20}
                              />
                              <ErrorMessage
                                name={"city"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>

                            {/* State */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc2">
                                State*
                              </div>
                              <InputComponent
                                value={state}
                                commentField=".iuc2"
                                placeholder="State*"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                inputClassName="dashboard-profile-input-field interMediumPaddingTop"
                                addon={false}
                                preventChangeAnimation={true}
                                changeCallback={(value) =>
                                  setFieldValue("state", value)
                                }
                                inputBlurCallback={() =>
                                  setFieldTouched("state", true)
                                }
                                databaseField="state"
                                maxlength={20}
                              />
                              <ErrorMessage
                                name={"state"}
                                component="div"
                                className="row text-danger line-height-normal mx-0 fs-12 "
                              />
                            </div>

                            {/* Country */}
                            <div class="row mb-3">
                              <div className="inputUpperComment iuc3 ">
                                Country*
                              </div>
                              <SelectComponent
                                value={country.label}
                                valuePaddingTop={true}
                                placeholderTop={country.label ? "50%" : "60%"}
                                placeholderFont="Inter-Medium"
                                inputGroupClassName="dashboard-profile-input col px-0"
                                options={Object.values(countriesOptions)}
                                customNoOptionsMessage={
                                  <p classNam="ml-2">No results</p>
                                }
                                styles={{
                                  placeholder: {
                                    fontSize: "0.875rem",
                                    color: "#8898aa",
                                  },
                                }}
                                selectChangeCallback={(
                                  value,
                                  dbField,
                                  commentField,
                                  databaseKey,
                                  type
                                ) =>
                                  type?.action !== "input-change" &&
                                  setFieldValue(
                                    "country",
                                    countriesOptions[value]
                                  )
                                }
                                selectBlurCallback={() =>
                                  setFieldTouched("country", true)
                                }
                              />
                              <p className="row text-danger line-height-normal mx-0 fs-12 mb-0">
                                {touched.country && errors?.country?.label}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </section>

        {/* Two factor authentication section */}
        <section className="dashboard-airplay mb-3">
          {/* header */}
          <div className="row align-items-center justify-content-between px-4">
            <div className="col">
              <span className="rcKey">Two-factor authentication</span>
            </div>
            <div className="d-flex ml-auto">
              {!twoFAStateLoading && (
                <span
                  className={`rounded security-two-factor-verification__status-${
                    isTwoFAEnabled ? "enabled" : "disabled"
                  }`}
                >
                  <span className="txt--8px txt--uppercase">
                    {isTwoFAEnabled ? "Enabled" : "Disabled"}
                  </span>
                </span>
              )}
            </div>
          </div>

          {/* content */}
          <div className="block-container__content">
            <div className="py-4 px-md-5">
              <div className="security-two-factor-verification__content-container">
                {/* 2fA info */}
                <div className="security-two-factor-verification__description row justify-content-center align-items-center">
                  <div className="row my-money-section-1-text px-3 py-4 mx-0 TwoFACard">
                    <div className="col-auto px-0">
                      <img src={infoBulbIcon} alt="info" />
                    </div>
                    <div className="col">
                      <div>
                        Two-factor authentication is an extra layer of security
                        for your account. Choose the method you prefer from
                        below to setup two-factor authentication
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column  justify-content-center align-items-center mt-4">
                  <SetUp2FA reCaptchaRef={reCaptchaRef} />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Connected Services and payment information */}
        <section className="dashboard-profile-bottom">
          <div
            className="dashboard-profile-modal dashboard-profile-modal-platforms"
            id="edit-profile-platforms"
          >
            <div className="dashboard-profile-modal-title">
              Connected services
            </div>
            <div className="dashboard-profile-modal-content dashboard-profile-modal-content-platforms">
              <ConnectedPlatforms iconMarginTopFix={6} />
              <ConnectedPages
                spotifyArtistProfile={spotifyArtistProfile}
                setSpotifyArtistModal={setSpotifyArtistModal}
                facebookArtistProfile={facebookArtistProfile}
                setFbPageModal={setFbPageModal}
                instagramArtistProfile={instagramArtistProfile}
                setInstagramPageModal={setInstagramPageModal}
                appleArtistProfile={appleArtistProfile}
                setApplePageModal={setApplePageModal}
                deleteProfile={deleteProfile}
              />
            </div>
          </div>
          <br />
          <div className="dashboard-profile-modal">
            <div className="dashboard-profile-modal-title">
              Payment information
            </div>
            <div className="dashboard-profile-modal-content">
              {/* conditionally display setpaymentmethodcomponent / editpaymentmethodcomponent */}
              {/* IF */}
              {paymentMethodState.connected ? (
                <div>
                  <EditPaymentMethodComponent
                    redirectTo="/artistDashboard/profile"
                    cardData={paymentMethodState?.data}
                  />
                </div>
              ) : (
                <div className="dashboard-profile-modal-content-setPaymentMethod">
                  <span
                    style={{ color: "#0EF1DB" }}
                    className="dashboard-profile-modal-textLine2"
                  >
                    {/* Your trial is expiring in XX days */}
                  </span>
                  Please pick your payment method:
                  <SetPaymentMethodComponent redirectTo="/artistDashboard/profile" />
                  <div className="setPaymentMethod-button-comment">
                    You will be safely redirected to Stripe.
                  </div>
                </div>
              )}
            </div>
          </div>
          <br />
          <div className="dashboard-profile-modal" id="payoutDetails">
            <div className="dashboard-profile-modal-title">
              My Money payout details
            </div>
            <div className="dashboard-profile-modal-content">
              <div className="dashboard-profile-modal-content-blockContent">
                <b>PayPal email:</b> <br /> {userBilling?.payPalEmail}
                <br />
                <br />
                <b>Billing address:</b>
                <br />
                {userBillingAddress.map((el) => {
                  return (
                    <div>
                      {billingFormMap?.[el]}:{" "}
                      {userBilling?.billingFormData?.[el]}
                    </div>
                  );
                })}
                <br />
                <div
                  className="smallEdit"
                  onClick={() => setPayoutDetailsModal(true)}
                >
                  edit
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default EditProfile;
