import React, { useEffect, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  populateTracksForm,
  updateTrackType,
} from "../../../redux/actions/distributionActions.js";

import { sortableContainer } from "react-sortable-hoc";

import SortableItem from "./SortableItem";
import "./index.scss";

import arrayMove from "array-move";

const SortableContainer = sortableContainer(({ children, className }) => {
  return <ul className={className}>{children}</ul>;
});

function Sortable({
  containerClassName,
  itemClassName,
  children,
  prepopulated,
  setChangesPresent,
}) {
  const [deleting, setDeleting] = useState(false);

  const [trackType, setTrackType] = useState({});
  function onSortEnd({ oldIndex, newIndex }) {
    dispatch(
      populateTracksForm(arrayMove(reduxTracksForm, oldIndex, newIndex))
    );
  }

  // REDUX
  const reduxTracksForm = useSelector(
    (state) => state.distributionReducer?.tracksForm
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultTrackType = reduxTracksForm.reduce((types, track, index) => {
      types[index] = track.trackType || "lyrics";
      return types;
    }, {});
    setTrackType(defaultTrackType);
    reduxTracksForm.forEach((track, index) => {
      dispatch(updateTrackType(index, track.trackType || "lyrics"));
    });
  }, [reduxTracksForm.length]);

  const handleTrackTypeChange = (e, index) => {
    const { value } = e.target;
    setTrackType((prevTrackType) => {
      return { ...prevTrackType, [index]: value };
    });
    dispatch(updateTrackType(index, value));
  };

  return (
    <SortableContainer
      className={containerClassName}
      onSortEnd={onSortEnd}
      useDragHandle
      useWindowAsScrollContainer
      axis="y"
      lockAxis="y"
      pressDelay={1} // ensures the panel gets closed before dragging
    >
      {reduxTracksForm?.map((track, index) => (
        <>
          <input
            type="radio"
            name={`trackType-${index}`}
            value="lyrics"
            checked={trackType[index] === "lyrics"}
            onChange={(e) => handleTrackTypeChange(e, index)}
            id={`trackTypeLyrics-${index}`}
          />
          <label
            for={`trackTypeLyrics-${index}`}
            className="utConfirmLicenses mr-3"
          >
            This track has lyrics
          </label>

          <input
            type="radio"
            name={`trackType-${index}`}
            value="instrumental"
            checked={trackType[index] === "instrumental"}
            onChange={(e) => handleTrackTypeChange(e, index)}
            id={`trackTypeInstrumental-${index}`}
          />
          <label
            for={`trackTypeInstrumental-${index}`}
            className="utConfirmLicenses"
          >
            This track is instrumental
          </label>

          <SortableItem
            key={`item-${index}`}
            index={index}
            trackIndex={index}
            trackType={trackType[index]}
            value={track.value}
            itemClassName={itemClassName}
            prepopulated={prepopulated} // TODO: REMAKE? REDUX?
            setDeleting={setDeleting}
            deleting={deleting} // passing down to all the components if one component sends true in order to collapse them
            setChangesPresent={setChangesPresent} // TODO: REMAKE? REDUX?
          />
        </>
      ))}
      {children}
    </SortableContainer>
  );
}

export default Sortable;
