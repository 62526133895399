import React, { useEffect } from "react";

const ObProgressCircles = ({ active }) => {
  function handleLink(index) {
    if (index < active || active === "all") {
      window.location.replace("/onboarding" + index);
    }
  }

  return (
    <div className="obProgress">
      <div className={active > 1 ? "obProgressHint" : "d-none"}>
        {" "}
        {/* &darr;  */}
        click the previous circles if you wish to edit{" "}
        <span className="obProgressHint-cutText">your changes</span>
      </div>
      <div
        className={
          "obCircle1 " +
          (active === 1 || active === "all" ? "obCircleActive" : "") +
          (active > 1 ? "obCircleActiveSmall" : "")
        }
        style={active > 1 || active === "all" ? { cursor: "pointer" } : {}}
        onClick={() => handleLink(1)}
      ></div>
      <div
        className={
          "obLine1 " + (active === "all" || active > 1 ? "obLineActive" : "")
        }
      ></div>
      <div
        className={
          "obCircle2 " +
          (active === 2 || active === "all" ? "obCircleActive" : "") +
          (active > 2 ? "obCircleActiveSmall" : "")
        }
        style={active > 2 || active === "all" ? { cursor: "pointer" } : {}}
        onClick={() => handleLink(2)}
      ></div>
      {/* TODO: enable this when adding Apple artist profile */}
      {/* <div
        className={
          "obLine2 " + (active === "all" || active > 2 ? "obLineActive" : "")
        }
      ></div>
      <div
        className={
          "obCircle3 " +
          (active === 3 || active === "all" ? "obCircleActive" : "") +
          (active > 3 ? "obCircleActiveSmall" : "")
        }
        style={active > 3 || active === "all" ? { cursor: "pointer" } : {}}
        onClick={() => handleLink(3)}
      ></div> */}
      <div
        className={
          "obLine3 " + (active === "all" || active > 3 ? "obLineActive" : "")
        }
      ></div>
      <div
        className={
          "obCircle3 " +
          (active === 4 || active === "all" ? "obCircleActive" : "") +
          (active > 4 ? "obCircleActiveSmall" : "")
        }
        style={active > 4 || active === "all" ? { cursor: "pointer" } : {}}
        onClick={() => handleLink(4)}
      ></div>
      <div
        className={
          "obLine3 " + (active === "all" || active > 4 ? "obLineActive" : "")
        }
      ></div>
      <div
        className={
          "obCircle3 " +
          (active === 5 || active === "all" ? "obCircleActive" : "") +
          (active > 5 ? "obCircleActiveSmall" : "")
        }
        style={active > 5 || active === "all" ? { cursor: "pointer" } : {}}
        onClick={() => handleLink(5)}
      ></div>
      {/* <div
        className={
          "obLine3 " + (active === "all" || active > 3 ? "obLineActive" : "")
        }
      ></div>
      <div
        className={"obCircle4 " + (active === "all" ? "obCircleActive" : "")}
      ></div> */}
    </div>
  );
};

export default ObProgressCircles;
