import { getJWT } from "../components/utils";
import axios from "axios";

// ADMIN ROUTES, only for internal usage
const nonRetyrInstance = axios.create();

nonRetyrInstance.interceptors.request.use((req) => {
  if (!req?.headers?.["User-Agent"])
    req.headers["User-Agent"] =
      "playtreksBot (Music data application) info@playtreks.com";

  return req;
}, null);

export async function getAllSubmittedReleases() {
  let url = `${global.config.API_URL}/api/admin/getAllSubmittedReleases`;
  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function cloneRelease(
  jwtToken,
  releaseUserEmail,
  releaseId
) {
  let url = `${global.config.API_URL}/api/admin/cloneRelease`;
  return axios.put(
    url,
    {
      userEmail: releaseUserEmail,
      releaseId: releaseId
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function updateReleaseState(
  jwtToken,
  releaseUserEmail,
  releaseId,
  newState
) {
  let url = `${global.config.API_URL}/api/admin/updateReleaseState`;
  return axios.put(
    url,
    {
      userEmail: releaseUserEmail,
      releaseId: releaseId,
      state: newState,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function getAllUsers(jwtToken) {
  let url = `${global.config.API_URL}/api/admin/allUsers`;
  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getAllUsersBasicInfo() {
  let url = `${global.config.API_URL}/api/admin/allUsersBasicInfo`;
  return new Promise((res, rej) => {
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + getJWT(),
        },
      })
      .then((r) => {
        res(r);
        rej("error");
      });
  });
}

export async function getAllUsersWithdrawals() {
  let url = `${global.config.API_URL}/api/admin/allUsersWithdrawals`;

  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function updateWithdrawalStatus({ transactionId, status }) {
  let url = `${global.config.API_URL}/api/admin/withdrawalStatus`;
  return axios.put(
    url,
    {
      transactionId: transactionId,
      status: status,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function adminGetUserSubscriptionStatus(userEmail) {
  let url = `${global.config.API_URL}/api/admin/userSubscriptionStatus`;
  return axios.get(url, {
    params: { userEmail: userEmail },
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function setUserMsg(jwtToken, userEmail, msg, msgType) {
  let url = `${global.config.API_URL}/api/admin/setUserMsg`;
  return axios.put(
    url,
    {
      userEmail: userEmail,
      msg: msg,
      msgType: msgType,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function setImpersonate(userEmail) {
  let url = `${global.config.API_URL}/api/admin/setImpersonate`;
  return axios.put(
    url,
    {
      email: userEmail,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function setFeePercentage(userEmail, feePercentage) {
  let url = `${global.config.API_URL}/api/admin/royaltiesFeePercentage`;
  return axios.put(
    url,
    {
      email: userEmail,
      percentage: feePercentage,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function setCsvSentStatus(
  jwtToken,
  userEmail,
  releaseId,
  csvSent
) {
  let url = `${global.config.API_URL}/api/admin/setCsvSentStatus`;
  return axios.put(
    url,
    {
      userEmail: userEmail,
      releaseId: releaseId,
      csvSent: csvSent,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function uploadSalesReport(file, fileName, periodId = '', periodMonth = 0, periodYear = 0) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("test", 1);
  formData.append("periodId", periodId);  

  formData.append("periodMonth", periodMonth);
  formData.append("periodYear", periodYear);  

  formData.append("file", file);
  formData.append("fileName", fileName);
  

  let url = `${global.config.API_URL}/api/admin/confirmedSalesReport`;
  return nonRetyrInstance.post(url, formData, {
    headers: {
      Authorization: "Bearer " + getJWT(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function uploadCatalogueSummaryFile(file, fileName) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", fileName);

  let url = `${global.config.API_URL}/api/admin/catalogSummaryReport`;
  return nonRetyrInstance.post(url, formData, {
    headers: {
      Authorization: "Bearer " + getJWT(),
      "Content-Type": "multipart/form-data",
    },
  });
}
