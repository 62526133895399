import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./admin.scss";

function AdminMenu() {
  const location = useLocation();
  return (
    <div>
      <br />
      <br />
      <div className="adminHeader">ADMIN PANEL</div>
      <div className="adminMenu">
        <Link
          to="/admin"
          className={location.pathname === "/admin" ? "adminMenu-active" : ""}
        >
          RELEASES
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/users"
          className={
            location.pathname === "/admin/users" ? "adminMenu-active" : ""
          }
        >
          USERS
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/revenue"
          className={
            location.pathname === "/admin/revenue" ? "adminMenu-active" : ""
          }
        >
          REVENUE
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/payout_requests"
          className={
            location.pathname === "/admin/payout_requests"
              ? "adminMenu-active"
              : ""
          }
        >
          PAYOUT REQUESTS
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/earnings"
          className={
            location.pathname === "/admin/earnings" ? "adminMenu-active" : ""
          }
        >
          ACCOUNT BALANCES MANAGEMENT
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/accounting"
          className={
            location.pathname === "/admin/accounting" ? "adminMenu-active" : ""
          }
        >
          ACCOUNTING PERIODS
        </Link>{" "}
        ||{" "}
        <Link
          to="/admin/userbalance"
          className={
            location.pathname === "/admin/userbalance" ? "adminMenu-active" : ""
          }
        >
          USER BALANCES
        </Link>
      </div>
    </div>
  );
}

export default AdminMenu;
