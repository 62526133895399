import React, { useMemo } from "react";

import { useDropzone } from "react-dropzone";

const baseStyle = {
  //   borderColor: "#eeeeee",
  //
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderStyle: "dashed",
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderStyle: "dashed",
  borderColor: "#0ef1db",
};

const rejectStyle = {
  borderStyle: "dashed",
  borderColor: "#ff1744",
};

function Dropzone(props) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: props.acceptFileType,
    onDropRejected: props.onReject,
    onDropAccepted: props.onAccept,
    disabled: props.disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <div className={props.className}>
      <div className={props.inputClassName} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {props.children}
      </div>
    </div>
  );
}

export default Dropzone;
