import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import TableView from "../../../screens/Admin/Releases/components/TableView";
import { getExpense, getExpenses } from "../../../services/expenses";
import { Link } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContractExpenseForm from "./ContractExpenseForm";

const INITIAL_PAGE_SIZE = 25;

const ContractExpenses = ({ contractId }) => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const [expenseId, setExpenseId] = useState(null);

  const { data: expenseData, isLoading: areExpensesLoading } = useQuery(
    ["allExpenses", contractId],
    () => getExpenses({ contractId })
  );
  const expenses = React.useMemo(() => expenseData?.data ?? [], [expenseData]);

  const {
    data: { data: expense } = {},
    isLoading,
    refetch,
  } = useQuery(["expense", expenseId], () => getExpense(expenseId), {
    enabled: !!expenseId,
  });

  const toggleDrawer = (isOpen, expenseId) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setExpenseId(expenseId);
    setDrawerState({ isOpen });
  };

  useEffect(() => {
    expenseId && refetch();
  }, [expenseId]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => (
          <div
            className="admin-release-details-link"
            title={value?.name}
            onClick={toggleDrawer(true, value._id)}
          >
            {value?.name}
          </div>
        ),
      },
      {
        Header: "Expense Date",
        accessor: "expenseDate",
        Cell: ({ value }) => value?.split("T")[0],
      },

      {
        Header: "Total paid amount",
        accessor: (originalRow) => originalRow,
        Cell: ({ value }) => {
          const paidAmount = value.expenseSplits.reduce(
            (total, current) => total + current.paidAmount,
            0
          );
          return <div>{paidAmount}</div>;
        },
      },

      //   {
      //     Header: "Actions",
      //     accessor: (originalRow) => originalRow,
      //     disableSortBy: true,
      //     Cell: ({ value }) => (
      //       <div
      //         className="d-inline-flex justify-content-center align-items-center"
      //         title=""
      //       >
      //         <div
      //           className="admin-release-details-link mr-2"
      //           onClick={() => handleEditRow(value)}
      //         >
      //           Edit
      //         </div>{" "}
      //         |{" "}
      //         <Link to={`/artistDashboard/expense/${value._id}`}>
      //           <div className="admin-release-details-link mx-2">Details</div>{" "}
      //         </Link>
      //         |{" "}
      //         <span
      //           role="button"
      //           onClick={() => handleDeleteRow(value._id)}
      //           className="ml-2 cursor-pointer"
      //           title="Delete expense"
      //         >
      //           <img src={TrashIcon} alt="delete expense" />
      //         </span>
      //       </div>
      //     ),
      //   },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: expenses,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <TableView tableInstance={tableInstance} loading={areExpensesLoading} />
      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 expense-drawer `}
          role="presentation"
        >
          <div
            onClick={toggleDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span> View Expense </span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>

          <ContractExpenseForm
            onClose={toggleDrawer(false)}
            expense={expense}
            isLoading={isLoading}
          />
        </div>
      </Drawer>
    </>
  );
};

export default ContractExpenses;
