import React, { useState } from "react";
import X from "../../../../../components/distribution/assets/X.svg";
import ButtonComponent from "../../../../form/ButtonComponent";
import ValidationTextBox from "../../../../form/ValidationTextBox";
import VerifyOTPModal from "../../../../modals/VerifyOTPModal";
import "../index.scss";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import ViewBackupCodesModal from "./ViewBackupCodesModal";
import { Spinner } from "../../../../../screens/Airplay/Airplay";

const VerifySMS2FAPhoneModal = ({
  onCloseRequest,
  onSendOTP,
  onVerifyOTP,
  refetch2FAState,
  setToast,
}) => {
  const [touched, setTouched] = useState(false);
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [page, setPage] = useState(0);
  const [countryCode, setCountryCode] = useState(0);
  const [backupCodes, setBackupCodes] = useState({
    codes: [],
    createdAt: null,
  });
  const [verifying, setVerifying] = useState(false);
  const [sending, setSending] = useState(false);

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (touched) validate();
  };

  const handlePhoneBlur = () => {
    validate();
    setTouched(true);
  };

  const validate = () => {
    let isError = false;
    if (!Number(phone)) {
      isError = true;
      setPhoneError("Invalid number");
    } else if (phone && !isPossiblePhoneNumber(phone)) {
      isError = true;
      setPhoneError("Invalid number");
    } else {
      isError = false;
      setPhoneError("");
    }
    return isError;
  };

  const handleOnSendOTP = async (e) => {
    if (e) e.preventDefault();

    if (validate()) return;
    setSending(true);
    const res = await onSendOTP(phone, countryCode);
    setSending(false);

    if (res.success) {
      setPage((next) => next + 1);
    }
  };

  const handleOnResendOTP = async () => {
    if (validate()) return;

    await onSendOTP(phone, countryCode);
  };

  const handleVerifyOTP = async (otp) => {
    setVerifying(true);
    const res = await onVerifyOTP(otp, phone);
    if (res.verified) {
      setToast({
        type: "success",
        open: true,
        message: "2FA has been setup successfully using SMS",
      });

      setTimeout(refetch2FAState, 1000);

      if (res?.backupCodes?.codes?.length) {
        setBackupCodes(res.backupCodes);
        setPage((next) => next + 1);
      } else {
        onCloseRequest();
      }
    }
    setVerifying(false);
  };
  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />
        {page === 0 && (
          <form onSubmit={handleOnSendOTP}>
            <div className="mb-2">Let's set up your phone</div>
            <p className="mb-4">What phone number do you want to use?</p>

            <div className="mb-4">
              <div class="position-relative col px-0 verify-modal">
                <div className="inputUpperComment phone-label">
                  Phone number*
                </div>
                <PhoneInput
                  name="phone"
                  placeholder="Phone number"
                  limitMaxLength
                  // defaultCountry="BE"
                  international
                  value={phone}
                  numberInputProps={{
                    style: { backgroundColor: "#343947" },
                  }}
                  onCountryChange={setCountryCode}
                  onBlur={handlePhoneBlur}
                  onChange={handlePhoneChange}
                />
                <ValidationTextBox
                  className="loginValidationBox position-static ml-0"
                  display={phoneError?.length}
                >
                  {phoneError}
                </ValidationTextBox>
              </div>
            </div>
            <div className="mb-4 text-muted">
              <p className="infoText">
                Playtreks will only use this number for account security.
                <br />
                Message and data rates may apply.
              </p>
            </div>
            <div className=" row mt-7 justify-content-end ml-0 align-items-center">
              <ButtonComponent
                buttonWrapperClassName="pr-3"
                className="playtreksButton w-100 h-100 bg-transparent textLink"
                onClick={onCloseRequest}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                buttonWrapperClassName="pr-3"
                className="playtreksButton w-100 h-100 "
                onClick={handleOnSendOTP}
                type="submit"
              >
                {sending ? (
                  <>
                    Sending...
                    <span className="ml-2">
                      <Spinner />
                    </span>
                  </>
                ) : (
                  "Send code"
                )}
              </ButtonComponent>
            </div>
          </form>
        )}

        {page === 1 && (
          <VerifyOTPModal
            onResendOTP={handleOnResendOTP}
            onVerifyOTP={handleVerifyOTP}
            phone={phone}
            verifying={verifying}
          />
        )}

        {page === 2 && <ViewBackupCodesModal backupCodes={backupCodes} />}
      </div>
    </div>
  );
};

export default VerifySMS2FAPhoneModal;
