import React from "react";
import "./onboarding.scss";
import "../../assets/scss/pt/forms.scss";

import ButtonComponent from "../form/ButtonComponent";

import Footer from "./components/FooterComponent";
import ObDecorations from "./components/ObDecorations";

function Onboarding() {
  function handleClick() {
    window.location.replace("/onboarding1");
  }
  return (
    <>
      <ObDecorations />
      <div className="obCenterBox obBox">
        <div className="obModal obModal-0 mt-1">
          <div className="obModal-Header obModal-Header-smaller">
            Get control over your data!
          </div>
          <br />
          <div className="obModal-content mb-2">
            ...and the most out of the PlayTreks experience. <br />
            <br />
            When your accounts are connected, we will show all your performance
            data in your artist dashboard. <br />
            <br />
            We will guide you through a few simple steps where you can connect
            your accounts so we can provide you with the best insight.
          </div>
          <br />
          <ButtonComponent className="obStartButton" onClick={handleClick}>
            Start
          </ButtonComponent>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Onboarding;
