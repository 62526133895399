import React, { useEffect, useState } from "react";
import ProfileHeader from "../Profile/components/ProfileHeader";
import { useHistory, useLocation } from "react-router-dom";

// REDU
import { useSelector, useDispatch } from "react-redux";

// pack
import { pickBy } from "lodash";
import moment from "moment";

import "./index.scss";

// img
import CheckCircle from "../../../assets/icons/check-circle.svg";
import CheckCircleUnchecked from "../../../assets/icons/check-circle-unchecked.svg";

import spinner from "../../../assets/spinner.svg";
import info from "../../../assets/icons/info.svg";

import ButtonComponent from "../../form/ButtonComponent";

import { Checkbox, Radio, Select } from "@material-ui/core";
import HorizontalScrollButtonGroup from "../HorizontalScrollButtonGroup";
import SelectComponent from "../../form/SelectComponent";
import { performPayment } from "../../../routes/payments";
import PaymentModal from "../../welcomepage/components/PaymentModal";

import { getJWT, planMap } from "../../utils";
import CancelSubscriptionModal from "../../welcomepage/components/CancelSubscriptionModal";
import { addAlert } from "../../../redux/actions/appActions";
import { setUsedArtistFields } from "../../../redux/actions/distributionActions.js";
import ReactTooltip from "react-tooltip";

const HORIZONTAL_SCROLL_SIZE = 500;

// cards
const FREE_CARD = "free";
const UNLIMITED_CARD = "unlimited_dashboard";
const ARTIST_CARD = "artist";
const LABEL_CARD = "label";

const FREE_CARD_INDEX = 0;
const UNLIMITED_CARD_INDEX = 1;
const ARTIST_CARD_INDEX = 2;
const LABEL_CARD_INDEX = 3;

// plans (each plan includes its variant name inside of the string)
const FREE_PLAN = "playtreks_free";
const UNLIMITED_DATA_DASHBOARD_PLAN = "playtreks_unlimited_dashboard";

const ARTIST_1_PLAN = "playtreks_artist_1";
const ARTIST_2_PLAN = "playtreks_artist_2";
const ARTIST_5_PLAN = "playtreks_artist_5";
const ARTIST_9_PLAN = "playtreks_artist_9";

const LABEL_10_PLAN = "playtreks_label_10";
const LABEL_20_PLAN = "playtreks_label_20";
const LABEL_50_PLAN = "playtreks_label_50";
const LABEL_100_PLAN = "playtreks_label_100";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Subscriptions() {
  let dispatch = useDispatch();

  let jwtToken = getJWT();

  let query = useQuery();
  const history = useHistory();

  // which box (variant) is pickedCardIndex
  const [pickedCardIndex, setPickedCardIndex] = useState(undefined);

  // checkboxes state
  const [pickedArtistPlan, setPickedArtistPlan] = useState(ARTIST_2_PLAN);
  const [pickedLabelPlan, setPickedLabelPlan] = useState(LABEL_10_PLAN);

  // if user picks another plan in the UI it changes to true
  const [armed, setArmed] = useState(false);

  // loading state for spinner
  const [isLoading, setIsLoading] = useState(true);

  // reduxCurrentPlan is the currently active subscription
  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );
  const reduxDataSubscription = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription
  );
  const isLegacy = useSelector(
    (state) =>
      state?.subscriptionReducer?.subscription?.dataSubscription?.legacy
  );
  const percentOff = useSelector(
    (state) =>
      state?.subscriptionReducer?.subscription?.dataSubscription?.state
        ?.percent_off
  );
  const isSubscriptionCancelling =
    reduxDataSubscription?.state?.cancel_at_period_end;
  const subscriptionCancellationCountdown = moment
    .unix(reduxDataSubscription?.state?.cancel_at)
    .format("MMMM Do YYYY h:mm:ss a UTC");
  // not using for now
  // const reduxDistributionSubscription = useSelector(
  //   (state) =>
  //     state?.subscriptionReducer?.subscription?.distributionSubscription?.plan
  // );

  // INIT
  // initialize values
  let artistInitPlan = reduxCurrentPlan?.includes("artist")
    ? reduxCurrentPlan
    : undefined;
  let labelInitPlan = reduxCurrentPlan?.includes("label")
    ? reduxCurrentPlan
    : undefined;

  useEffect(() => {
    // populate redux used artists
    dispatch(setUsedArtistFields());
  }, []);

  // update checkboxes on initial plan fetch
  useEffect(() => {
    if (artistInitPlan) setPickedArtistPlan(artistInitPlan);
    if (labelInitPlan) setPickedLabelPlan(labelInitPlan);
  }, [artistInitPlan, labelInitPlan]);

  // PICKED
  // picked card logic (not init)
  const [pickedPrice, setPickedPrice] = useState(undefined);
  const [pickedPlanId, setPickedPlanId] = useState(undefined);
  const currentPrice =
    reduxDataSubscription?.state?.unit_amount_decimal / 100 ||
    planMap?.[reduxCurrentPlan]?.price;

  // if discount
  const discountedPrice = (((100 - percentOff) * currentPrice) / 100).toFixed(
    2
  );

  useEffect(() => {
    switch (pickedCardIndex) {
      case ARTIST_CARD_INDEX: {
        setPickedPlanId(pickedArtistPlan);
        setPickedPrice(planMap?.[pickedArtistPlan]?.price);
        break;
      }
      case LABEL_CARD_INDEX: {
        setPickedPlanId(pickedLabelPlan);
        setPickedPrice(planMap?.[pickedLabelPlan]?.price);
        break;
      }
      case UNLIMITED_CARD_INDEX: {
        setPickedPlanId(UNLIMITED_DATA_DASHBOARD_PLAN);
        setPickedPrice(planMap?.[UNLIMITED_DATA_DASHBOARD_PLAN]?.price);
        break;
      }
      default:
        setPickedPlanId(FREE_PLAN);
        setPickedPrice(planMap?.[FREE_PLAN]?.price);
        break;
    }
  }, [pickedArtistPlan, pickedLabelPlan, pickedCardIndex]);

  function isCardActive(cardpath) {
    return reduxCurrentPlan?.includes(cardpath);
  }

  useEffect(() => {
    if (reduxCurrentPlan) {
      setIsLoading(false);
    }
  }, [reduxCurrentPlan]);

  // button active/inactive logic
  useEffect(() => {
    if (!reduxCurrentPlan) {
      setArmed(false);
    } else if (
      pickedCardIndex === FREE_CARD_INDEX &&
      reduxCurrentPlan === FREE_PLAN
    ) {
      setArmed(false);
    } else if (
      pickedCardIndex === ARTIST_CARD_INDEX &&
      pickedArtistPlan === reduxCurrentPlan // reduxCurrentPlan is the current subscribed plan
    ) {
      setArmed(false);
    } else if (
      pickedCardIndex === LABEL_CARD_INDEX &&
      pickedLabelPlan === reduxCurrentPlan
    ) {
      setArmed(false);
    } else if (
      pickedCardIndex === UNLIMITED_CARD_INDEX &&
      reduxCurrentPlan === UNLIMITED_DATA_DASHBOARD_PLAN
    ) {
      setArmed(false);
    } else if (pickedCardIndex === undefined) {
      // init state
      setArmed(false);
    } else {
      // if user picked something else than what they're currently subscribed to
      setArmed(true);
    }
  }, [pickedCardIndex, pickedArtistPlan, pickedLabelPlan, reduxCurrentPlan]);

  // payment modal
  const [paymentModalState, setPaymentModalState] = useState({
    open: false,
    product: undefined,
  });
  const [cancelSubscriptionModalState, setCancelSubscriptionModalState] =
    useState({
      open: false,
      product: FREE_PLAN,
    });

  function paymentModalCloseCallback() {
    ["pay", "processor", "product"].forEach((param) => query.delete(param));
    history.replace({
      search: query.toString(),
    });
  }

  // handle payment modal from query
  useEffect(() => {
    if (query.get("pay")) {
      setPaymentModalState({
        open: true,
        product: query.get("product"),
        processor: paymentModalState.processor || query.get("processor"),
      });
    }
    if (query.get("subscription_id") && query.get("token")) {
      dispatch(addAlert("Subscription updated!", null, "success"));
    }
  }, []);

  // scrolling
  function handleSocialStatScroll(element, direction) {
    let scrollAmount = 0;
    if (direction === "left") {
      var slideTimer = setInterval(function () {
        element.scrollLeft -= 20;
        scrollAmount += 20;
        if (scrollAmount >= HORIZONTAL_SCROLL_SIZE) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    } else {
      var slideTimer = setInterval(function () {
        element.scrollLeft += 20;
        scrollAmount += 20;
        if (scrollAmount >= HORIZONTAL_SCROLL_SIZE) {
          window.clearInterval(slideTimer);
        }
      }, 25);
    }
  }

  function handleUpgrade() {
    if (pickedPrice === 0) {
      setCancelSubscriptionModalState({
        ...cancelSubscriptionModalState,
        open: true,
      });
    } else {
      setPaymentModalState({
        open: true,
        product: pickedPlanId,
        productDisplay: planMap[pickedPlanId].name,
      });
    }
  }

  const priceBtnDisplay = (pickedPlanId) => {
    if (planMap[pickedPlanId].card_index === FREE_CARD_INDEX) {
      return (
        <div>
          <span aria-label="x" alt="x">
            ✖️
          </span>{" "}
          Cancel subscription
        </div>
      );
    }
    if (planMap[pickedPlanId].plan < planMap[reduxCurrentPlan]?.plan) {
      return (
        <div>
          <span aria-label="down" alt="">
            ⬇
          </span>{" "}
          Downgrade to € {pickedPrice} / m
        </div>
      );
    } else {
      return (
        <div>
          <span aria-label="up" alt="">
            ⬆
          </span>{" "}
          Upgrade to € {pickedPrice} / m
        </div>
      );
    }
  };

  const cardsConfigArgs = {
    pickedArtistPlan: pickedArtistPlan,
    setPickedArtistPlan: setPickedArtistPlan,
    pickedLabelPlan: pickedLabelPlan,
    setPickedLabelPlan: setPickedLabelPlan,
    pickedPrice: pickedPrice,
  };

  return (
    <main className="dashboard-subscriptions">
      <div className={paymentModalState.open ? "" : "d-none"}>
        <PaymentModal
          paymentModalState={paymentModalState}
          setPaymentModalState={setPaymentModalState}
          redirectTo={"/artistDashboard/subscriptions"}
          closeCallback={paymentModalCloseCallback}
        />
      </div>
      <div className={cancelSubscriptionModalState.open ? "" : "d-none"}>
        <CancelSubscriptionModal
          cancelSubscriptionModalState={cancelSubscriptionModalState}
          setCancelSubscriptionModalState={setCancelSubscriptionModalState}
          redirectTo={"/artistDashboard/subscriptions"}
        />
      </div>
      <ProfileHeader label="Your subscriptions" />
      <section className="dashboard-subscriptions-section dashboard-subscriptions-section-0">
        {!isLoading ? (
          !isSubscriptionCancelling ? (
            <div>
              Your current subscription is {planMap?.[reduxCurrentPlan]?.name}
              <br />
              <span
                className={
                  isLegacy || percentOff
                    ? "text-line-through text-inactive"
                    : ""
                }
              >
                {currentPrice} € / month
              </span>{" "}
              &nbsp;
              {isLegacy && (
                <span>
                  9.95 € / month &nbsp;
                  <img
                    src={info}
                    alt="info"
                    data-tip="We have changed our pricing, and you have been upgraded <br/> to a higher plan for the price of your previous subscription."
                    data-for="subscriptions-tooltip"
                    className="cursor-pointer"
                  />
                  <ReactTooltip
                    // place="top"
                    backgroundColor="#1d2025"
                    className="ptTooltip"
                    border
                    borderColor="#0ef1db"
                    effect="float"
                    multiline={true}
                    id="subscriptions-tooltip"
                  />
                </span>
              )}
              {percentOff && (
                <span>
                  {discountedPrice} € / month &nbsp;
                  <br />
                  <span className="dashboard-subscriptions-section-0-badge-discount mb-2">
                    {percentOff}% discount{" "}
                    <span aria-label="heart" alt="heart">
                      💙
                    </span>
                  </span>
                </span>
              )}
            </div>
          ) : (
            <div className="dashboard-subscriptions-section-0-cancellation">
              <div className="dashboard-subscriptions-section-0-badge-cancellation mb-2">
                Cancellation in progress
              </div>
              <u>
                Your {planMap?.[reduxCurrentPlan]?.name} subscription will
                cancel on {subscriptionCancellationCountdown}.
              </u>
            </div>
          )
        ) : (
          <div className="xyFlexCenter">
            <img src={spinner} />
          </div>
        )}
      </section>
      <section className="dashboard-subscriptions-section dashboard-subscriptions-section-1">
        <div className="dashboard-subscriptions-section-1-scrollButtons">
          {/* <HorizontalScrollButtonGroup
            isVisible
            // disableLeft={socialStatsBtn["left"]}
            // disableRight={socialStatsBtn["right"]} // no disabling arrows (problematic)
            handleScroll={(direction) =>
              handleSocialStatScroll(
                document.querySelector(".dashboard-subscriptions-boxes"),
                direction
              )
            }
          /> */}
        </div>
        <div className="dashboard-subscriptions-section-1-contentWrapper">
          <div className="dashboard-subscriptions-plans">
            <div className="dashboard-subscriptions-plans-title">
              Choose your plan
            </div>
            <div className="dashboard-subscriptions-currentPlan-opaque">.</div>
            <div className="dashboard-subscriptions-plans-description">
              <ul>
                <li className="dashboard-subscriptions-price">Price</li>
                {/* <br /> */}
                {/* <li className="dashboard-subscriptions-plans-description-title">
                  Data dashboard
                </li>
                <li>Performance stats</li>
                <li>Social stats</li>
                <li>Streaming stats</li>
                <li>Airplay stats</li> */}
                <br />
                <li className="dashboard-subscriptions-plans-description-title">
                  Distribution
                </li>
                <li>Royalty payout</li>
                <li>Amount of artist slots</li>
                <li>Unlimited releases</li>
                <li>Collaborator royalty shares</li>
                <li>ISRC codes</li>
                <li>Support level</li>
                <li>Label name</li>
                <li>Beatport support</li>
              </ul>
              <br />
            </div>
          </div>
          <div className="dashboard-subscriptions-boxes">
            {distributionCardsConfig(cardsConfigArgs).map((card, index) => (
              <SubscriptionCard
                card={card}
                active={isCardActive(card.path)} // active subscription
                pickedCardIndex={pickedCardIndex} // UI selected subscription
                index={index}
                onClick={() => setPickedCardIndex(index)} // UI
              />
            ))}
          </div>
        </div>
      </section>
      <section className="dashboard-subscriptions-section dashboard-subscriptions-section-2">
        {armed ? (
          <ButtonComponent className="playtreksButton" onClick={handleUpgrade}>
            {priceBtnDisplay(pickedPlanId)}
            {/* TODO: add emoji nice arrow up, / DOWNGRADE */}
          </ButtonComponent>
        ) : (
          <ButtonComponent className="playtreksButton playtreksButton-inactive">
            Pick your upgrade
            {/* TODO: add emoji nice arrow up, / DOWNGRADE */}
          </ButtonComponent>
        )}
      </section>
    </main>
  );
}

function SubscriptionCard({ card, active, pickedCardIndex, index, onClick }) {
  let isPicked = pickedCardIndex === index;
  let isNonePicked = pickedCardIndex === undefined;
  return (
    <div
      className={
        "dashboard-subscriptions-boxes-box nonSelect " +
        (isPicked || (isNonePicked && active)
          ? "dashboard-subscriptions-boxes-box-active"
          : "cursor-pointer")
      }
      onClick={onClick}
    >
      <div className="dashboard-subscriptions-boxes-box-title">
        {card.name}&nbsp;
        {isPicked || (isNonePicked && active) ? (
          <img src={CheckCircle} />
        ) : (
          <img
            src={CheckCircleUnchecked}
            style={{
              filter: "brightness(150%)",
              width: "24px",
              height: "24px",
            }}
          />
        )}
        <br />
        <div
          className={
            "dashboard-subscriptions-currentPlan" + (!active ? "-opaque" : "")
          }
        >
          Your current plan
        </div>
      </div>
      <div className="dashboard-subscriptions-boxes-box-description">
        {card.description}
      </div>
      <div className="dashboard-subscriptions-boxes-box-radioWrapper">
        <Radio checked={isPicked} />
      </div>
    </div>
  );
}

const distributionCardsConfig = ({
  pickedArtistPlan,
  setPickedArtistPlan,
  pickedLabelPlan,
  setPickedLabelPlan,
  pickedPrice,
}) => [
  {
    name: "Free",
    path: FREE_CARD,
    description: (
      <div>
        <ul>
          <li className="dashboard-subscriptions-price">FREE</li>
          <br />
          {/* <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li> */}
          {/* <li>
            <span className="mobile768visible text-underline">
              Performance stats:
            </span>
            Your own + 1{" "}
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Social stats:
            </span>
            Your own + 1{" "}
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Streaming stats:
            </span>
            Your own + 1{" "}
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Airplay stats:
            </span>
            <span alt="no" aria-label="no" className="nrTick">
              ❌
            </span>
          </li> */}

          {/* <br /> */}
          <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Royalty payout:
            </span>
            <span alt="no" aria-label="no" className="nrTick">
              85% of net earnings
            </span>
          </li>

          <li>
            <span className="mobile768visible text-underline">
              Amount of artist slots:
            </span>
            1
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Unlimited releases:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Collaborator royalty shares:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">ISRC codes:</span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Support level:
            </span>
            Basic
          </li>
          <li>
            <span className="mobile768visible text-underline">Label name:</span>
            <span alt="no" aria-label="no" className="nrTick">
              ❌
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Beatport support:
            </span>
            <span alt="no" aria-label="no" className="nrTick">
              ❌
            </span>
          </li>
        </ul>
      </div>
    ),
  },
  // {
  //   name: "Analyst",
  //   path: UNLIMITED_CARD,
  //   description: (
  //     <div>
  //       <ul>
  //         <li className="dashboard-subscriptions-price">
  //           {planMap?.[UNLIMITED_DATA_DASHBOARD_PLAN]?.price} € / month
  //         </li>
  //         <br />
  //         <li className="dashboard-subscriptions-plans-description-title opacity-0">
  //           .
  //         </li>
  //         {/* <li>
  //           <span className="mobile768visible text-underline">
  //             Performance stats:
  //           </span>
  //           Global&nbsp;<span>🌎</span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Social stats:
  //           </span>
  //           Global&nbsp;
  //           <span>🌎</span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Streaming stats:
  //           </span>
  //           Global&nbsp;<span>🌎</span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Airplay stats:
  //           </span>
  //           Global&nbsp;<span>🌎</span>
  //         </li> */}

  //         {/* <br /> */}
  //         <li className="dashboard-subscriptions-plans-description-title opacity-0">
  //           .
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Royalty payout:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Amount of artist slots:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Unlimited releases:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Collaborator royalty shares:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">ISRC codes:</span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Support level:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">Label name:</span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //         <li>
  //           <span className="mobile768visible text-underline">
  //             Beatport support:
  //           </span>
  //           <span alt="no" aria-label="no" className="nrTick">
  //             ❌
  //           </span>
  //         </li>
  //       </ul>
  //     </div>
  //   ),
  // },
  {
    name: "Artist",
    path: ARTIST_CARD,
    description: (
      <div>
        <ul>
          <li className="dashboard-subscriptions-price">
            {planMap?.[pickedArtistPlan]?.price} € / month
          </li>
          <br />
          {/* <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li> */}
          {/* <li>
            <span className="mobile768visible text-underline">
              Performance stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Social stats:
            </span>
            Global&nbsp;
            <span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Streaming stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Airplay stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li> */}
          {/* <br /> */}
          <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Royalty payout:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              100% of net earnings
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Amount of artist slots:
            </span>
            <select
              className="dashboard-subscriptions-boxes-box-description-picker"
              value={pickedArtistPlan}
              onChange={(e) => setPickedArtistPlan(e.target.value)}
            >
              <option value={ARTIST_1_PLAN}>1</option>
              <option value={ARTIST_2_PLAN}>2</option>
              <option value={ARTIST_5_PLAN}>5</option>
              <option value={ARTIST_9_PLAN}>9</option>
            </select>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Unlimited releases:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Collaborator royalty shares:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">ISRC codes:</span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Support level:
            </span>
            Premium{" "}
            <span aria-label="up" alt="up">
              ⬆️
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">Label name:</span>
            <span alt="no" aria-label="no" className="nrTick">
              ❌
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Beatport support:
            </span>
            <span alt="no" aria-label="no" className="nrTick">
              ❌
            </span>
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: "Artist / Label",
    path: LABEL_CARD,
    description: (
      <div>
        <ul>
          <li className="dashboard-subscriptions-price">
            {planMap?.[pickedLabelPlan]?.price} € / month
          </li>
          <br />
          {/* <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Performance stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Social stats:
            </span>
            Global&nbsp;
            <span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Streaming stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Airplay stats:
            </span>
            Global&nbsp;<span>🌎</span>
          </li> */}
          {/* <br /> */}
          <li className="dashboard-subscriptions-plans-description-title opacity-0">
            .
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Royalty payout:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              100% of net earnings
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Amount of artist slots:
            </span>
            <select
              className="dashboard-subscriptions-boxes-box-description-picker"
              value={pickedLabelPlan}
              onChange={(e) => setPickedLabelPlan(e.target.value)}
            >
              <option value={LABEL_10_PLAN}>10</option>
              <option value={LABEL_20_PLAN}>20</option>
              <option value={LABEL_50_PLAN}>50</option>
              <option value={LABEL_100_PLAN}>100</option>
            </select>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Unlimited releases:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Collaborator royalty shares:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">ISRC codes:</span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Support level:
            </span>
            Premium{" "}
            <span aria-label="up" alt="up">
              ⬆️
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">Label name:</span>
            Custom{" "}
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
          <li>
            <span className="mobile768visible text-underline">
              Beatport support:
            </span>
            <span alt="yes" aria-label="yes" className="nrTick">
              ✅
            </span>
          </li>
        </ul>
      </div>
    ),
  },
];

export default Subscriptions;
