import axios from "axios";
import { getJWT } from "../components/utils";
import jwtDecode from "jwt-decode";
import { parseQueryParams } from "./utils";

const axiosInstance = axios.create();

const endpoints = {
  createExpense: "/expenses",
  updateExpense: "/expenses",
  getExpenses: "/expenses",
  getExpense: "/expenses",
  deleteExpense: "/expenses",
};

export async function createExpense(payload) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.createExpense}`;
  return axiosInstance.post(url, payload, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function updateExpense({ expenseId, ...payload }) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.updateExpense}/${expenseId}`;
  return axiosInstance.put(
    url,
    { ...payload },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function getExpenses(queryParams) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${
    endpoints.getExpenses
  }${parseQueryParams(queryParams)}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function getExpense(expenseId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.getExpense}/${expenseId}`;
  return axiosInstance.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function deleteExpense(expenseId) {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.deleteExpense}/${expenseId}`;
  return axiosInstance.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}
