import React from "react";

import { useSelector } from "react-redux";

import ValidationTextBox from "../../form/ValidationTextBox";

import SelectComponent from "../../form/SelectComponent";

function UploadTracksPrimaryArtistFieldComponent({
  i,
  artistsArray,
  inputChangeCallback,
  handleRemoveInput,
  handleAddArtist,
  trash,
  trackIndex,
}) {
  const usedArtists = useSelector(
    (state) => state.distributionReducer.usedArtistFields
  );

  const fieldValue = useSelector(
    (state) =>
      state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
        "#participants"
      ]?.primary?.[i]?.name
  );

  function handleChange(value, inputId) {
    hackHideMenuIfNoOptions();
    inputChangeCallback(value, "#participants", "primary", inputId, "name");
  }

  // only hides menu if the "custom no options message" is inside
  function hackHideMenuIfNoOptions() {
    setTimeout(() => {
      let a1 = document.querySelector(".ruslanSelect__menu");
      if (a1) a1.classList.remove("d-none");
    }, 0);
    setTimeout(() => {
      let a = document.querySelector(".nrInvisibleNoOptionsChild");
      if (a) a.parentElement.parentElement.classList.add("d-none");
    }, 0);
  }

  const customNoOptionsMessage = (
    <div className="nrInvisibleNoOptionsChild"></div>
  );

  return (
    <div className="utepPrimaryArtistWrapper" id={"artistWrapper" + i}>
      <div className="utepExpandableFieldWrapper">
        <div
          className={
            "inputUpperComment utepCommentFieldFixer utiuc3 utiuc3_" + i
          }
        >
          Primary artist*
        </div>
        <SelectComponent
          value={fieldValue}
          inputGroupClassName="utepPrimaryArtistInputGroup utepInputGroup"
          placeholder="Select or type..."
          shortInput={true}
          valuePaddingTop={true}
          placeholderTop="60%"
          placeholderFont="Inter-Medium"
          placeholderColor="white"
          placeholderOpacity="1"
          commentField={".utiuc3_" + i}
          selectBlurCallback={() => {}}
          options={usedArtists}
          selectChangeCallback={(v) => handleChange(v, i, ".iuc2_" + i)}
          for="artist"
          databaseField="#participants"
          databaseKey="primary"
          databaseSubKey="name"
          noMenuOnClick={true}
          customNoOptionsMessage={customNoOptionsMessage}
          addon={i === 0 ? false : "right"}
          inputGroupTextClassName="nrRightAddon"
          img={trash}
          imgClass="nrAddonRightIcon"
          alt="remove artist"
          addonClickCallback={() => handleRemoveInput("artist", i)}
        />
      </div>

      {i === artistsArray.length - 1 ? (
        <div className="utepPlus" onClick={handleAddArtist}>
          +
        </div>
      ) : (
        ""
      )}

      {/* used to validate the first artist */}
      <ValidationTextBox
        display={useSelector(
          (state) =>
            state.distributionReducer?.tracksValidation?.[trackIndex]?.[
              "#participants"
            ]?.primary?.[i]?.name
        )}
        className="utValTextBox utValTextBox-primary-name"
        noMt={true} // prevent margin
        for="#participants"
        style={{ position: "absolute" }}
      >
        {i === 0 ? "Please provide a name for the first artist" : ""}
      </ValidationTextBox>
    </div>
  );
}

export default UploadTracksPrimaryArtistFieldComponent;
