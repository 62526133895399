import React, { useLayoutEffect, useState } from "react";
import FeatureChart from "../../graphs/FeatureChart";
import { getColorScheme } from "../../utils";

import "./index.scss";

import Spinner from "../../../assets/spinner.svg";

const BACKGROUND_GRAY = "#1d2025";

function useChartDimention() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      // note: these two functions are good for reading actual css and passing it
      // to the library config (maybe to utils?)
      const getHeight = (selector) =>
        document.querySelector(selector)
          ? Number(
              getComputedStyle(
                document.querySelector(selector),
                null
              ).height.replace(/px/, "")
            )
          : 0;
      const getWidth = (selector) =>
        document.querySelector(selector)
          ? Number(
              getComputedStyle(
                document.querySelector(selector),
                null
              ).width.replace(/px/, "")
            )
          : 0;
      const height = getHeight(".track-features") - 20;
      let width = getWidth(".track-features");
      //   width -= 40;
      setSize([width, height]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function TrackFeatures({
  featureData,
  containerClassName,
  shortLabels = false,
}) {
  const [chartWidth, chartHeight] = useChartDimention();
  const colorScheme = getColorScheme();

  let labelMode = shortLabels ? "short" : "long";

  const chartLabels = {
    danceability: { long: "danceability", short: "D" },
    energy: { long: "energy", short: "E" },
    valence: { long: "valence", short: "V" },
    speechiness: { long: "speechiness", short: "S" },
    acousticness: { long: "acousticness", short: "A" },
    instrumentalness: { long: "instrumentalness", short: "I" },
    liveness: { long: "liveness", short: "L" },
  };

  function setChartData() {
    return [
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["danceability"][labelMode],
        danceability: 100,
        value: parseFloat(featureData?.danceability * 100).toFixed(2),
        tooltipColor: colorScheme[0],
        tooltipName: chartLabels["danceability"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["energy"][labelMode],
        energy: 100,
        value: parseFloat(featureData?.energy * 100).toFixed(2),
        tooltipColor: colorScheme[1],
        tooltipName: chartLabels["energy"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["valence"][labelMode],
        valence: 100,
        value: parseFloat(featureData?.valence * 100).toFixed(2),
        tooltipColor: colorScheme[2],
        tooltipName: chartLabels["valence"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["speechiness"][labelMode],
        speechiness: 100,
        value: parseFloat(featureData?.speechiness * 100).toFixed(2),
        tooltipColor: colorScheme[3],
        tooltipName: chartLabels["speechiness"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["acousticness"][labelMode],
        acousticness: 100,
        value: parseFloat(featureData?.acousticness * 100).toFixed(2),
        tooltipColor: colorScheme[4],
        tooltipName: chartLabels["acousticness"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["instrumentalness"][labelMode],
        instrumentalness: 100,
        value: parseFloat(featureData?.instrumentalness * 100).toFixed(2),
        tooltipColor: colorScheme[5],
        tooltipName: chartLabels["instrumentalness"]["long"],
      },
      {
        color: BACKGROUND_GRAY,
        name: chartLabels["liveness"][labelMode],
        liveness: 100,
        value: parseFloat(featureData?.liveness * 100).toFixed(2),
        tooltipColor: colorScheme[6],
        tooltipName: chartLabels["liveness"]["long"],
      },
    ];
  }

  return (
    <div className={"track-features " + (containerClassName || "")}>
      <div className="track-features-chart">
        <div
          className={
            !featureData ? "track-features-chart-spinner-wrapper" : "d-none"
          }
        >
          <img src={Spinner} className="track-features-chart-spinner" />
        </div>
        <div
          className={
            "track-features-chart-wrapper " +
            (!featureData && "track-features-chart-wrapper-loading")
          }
          style={{
            height: `${chartHeight}px`,
            minWidth: `${chartWidth}px`,
          }}
        >
          <FeatureChart
            data={setChartData()}
            keys={[
              "danceability",
              "energy",
              "valence",
              "speechiness",
              "acousticness",
              "instrumentalness",
              "liveness",
            ]}
            indexBy="name"
            width={chartWidth}
            height={chartHeight}
            featureChartData={featureData}
            colorScheme={colorScheme}
          />
        </div>
      </div>
    </div>
  );
}

export default TrackFeatures;
