import React, { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import ShareHolder from "./ShareHolder";
import TrashIcon from "../../../assets/icons/trash.svg";
import { Formik, FieldArray, useFormikContext, ErrorMessage } from "formik";
import ButtonComponent from "../../../components/form/ButtonComponent";
import { Spinner } from "../../Airplay/Airplay";
import { v4 as uuidv4 } from "uuid";
import { updateTrackShareholders } from "../../../services/distribution";
import { useParams } from "react-router-dom";
import { RoyaltiesSplitsValidationSchema } from "../validations";
import { getJWT } from "../../../components/utils";
import { useMutation, useQueryClient } from "react-query";
import InputComponent from "../../../components/form/InputComponent";
import { isEqual } from "lodash";
import Toast from "../../../components/Toast";
import "../index.scss";
import envelope from "../../../assets/icons/envelope2.svg";
import ResendCollaboratorMailModal from "./ResendCollaboratorMailModal";
import Portal from "../../../components/Portal";
import ReactTooltip from "react-tooltip";

const TotalShare = memo((props) => {
  const {
    values: { shareholders, totalShare },
    errors: { totalShare: errTotalShare = false },
    touched: { totalShare: touchedTotalShare = false },
    setFieldValue,
  } = useFormikContext();

  React.useEffect(() => {
    const total = shareholders?.reduce(
      (acc, curr) => acc + Number(curr.share),
      0
    );

    if (total >= 0) {
      setFieldValue("totalShare", total);
    }
  }, [shareholders, setFieldValue]);

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div
        className={
          (totalShare ? "" : "d-none") + " inputUpperComment utiuc3_totalShare"
        }
      >
        Total
      </div>
      <div>
        <div className="position-relative">
          <InputComponent
            disabled={true}
            commentField={".utiuc3_totalShare"}
            placeholder="Share"
            inputGroupClassName="nrInputGroup "
            inputClassName={
              " nrInput utepInput " +
              (totalShare ? " interMediumInput interMediumPaddingTop " : "") +
              (errTotalShare && touchedTotalShare ? "text-danger" : "")
            }
            paddingTopOnInput={true}
            id={0}
            addon={false}
            for="artist"
            databaseField="#participants"
            databaseKey="contributors"
            databaseSubKey="share"
            tooltip={true}
            maxlength={3}
            {...props}
          />
        </div>
      </div>
    </div>
  );
});

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const ShareHoldersForm = memo(
  ({ shareholders, trackIndex, ownerEmail, ownerName, trackTitle }) => {
    const { releaseId } = useParams();
    const queryClient = useQueryClient();
    const user = useSelector(
      ({ dashboardReducer }) => dashboardReducer.userData || {},
      isEqual
    );
    const [toast, setToast] = useState(initialToastState);

    const isOwner = user.email === ownerEmail;

    // Release Mutation handler
    const trackShareholdersMutation = useMutation(updateTrackShareholders);

    const [isOpenResendCollabModal, setIsOpenResendCollabModal] =
      useState(false);

    const [shareholderToResendMail, setShareHolderToResendMail] =
      useState(null);

    const handleOnModalClose = () => {
      setIsOpenResendCollabModal(false);
    };

    const handleOpenResendModal = (shareholder) => () => {
      setIsOpenResendCollabModal(true);
      setShareHolderToResendMail(shareholder);
    };

    const handleSubmit = ({ shareholders }, formActions) => {
      const parsedShareholders = shareholders.map((s) => ({
        ...s,
        name: s.name.trim(),
        email: s.email.trim().toLowerCase(),
      }));

      const payload = {
        releaseId,
        trackIndex,
        shareholders: parsedShareholders,
      };

      // Using the mutation handler to update release data
      trackShareholdersMutation.mutate(payload, {
        onSuccess: () => {
          // Delaying loading as the spinner flashes in ui due to fast updation
          setTimeout(() => {
            queryClient.setQueryData(["userAllReleases", getJWT()], (old) => ({
              ...old,
              // Updating the cached version
              data: old.data.map((release) => {
                if (release.releaseId === releaseId) {
                  return {
                    ...release,
                    tracks: release.tracks.map((track, idx) => {
                      if (idx === trackIndex) {
                        return {
                          ...track,
                          shareholders: parsedShareholders,
                        };
                      }
                      return track;
                    }),
                  };
                }
                return release;
              }),
            }));

            formActions.setSubmitting(false);
            formActions.setStatus({ edit: false });

            setToast({
              type: "success",
              open: true,
              message:
                "Royalty shares succesfully saved. Collaborators will receive a confirmation email.",
            });
          }, 1250);
        },
        onError: (e) => {
          setTimeout(() => {
            // TODO: refactor this
            const toastMessage =
              e.response.data === "Emails must be unique"
                ? e.response.data
                : "Something went wrong, try again later";

            setToast({
              open: true,
              message: toastMessage,
              type: "danger",
            });
            formActions.setSubmitting(false);

            if (e.response.data !== "Emails must be unique") {
              formActions.resetForm();
            }
          }, 500);
        },
      });
    };

    const initialTotalShare = useMemo(
      () => shareholders?.reduce((acc, curr) => acc + Number(curr.share), 0),
      [shareholders]
    );

    return (
      <>
        <Toast
          open={toast.open}
          onClose={() =>
            setToast((prevState) => ({ open: false, type: prevState.type }))
          }
          toastMessage={toast.message}
          toastType={toast.type}
        />
        <Formik
          enableReinitialize
          initialValues={{ shareholders, totalShare: initialTotalShare }}
          initialStatus={{ edit: false }}
          validationSchema={RoyaltiesSplitsValidationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              values,
              isValid,
              status: { edit },
              dirty,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              resetForm,
              setStatus,
            } = props;

            return (
              <>
                {!isOwner ? (
                  <>
                    <div className="prrcText font-weight-normal mt-4">
                      Your share
                    </div>
                  </>
                ) : (
                  <div className="prrcText font-weight-normal mt-4">
                    Track shareholders
                  </div>
                )}

                <br />

                <FieldArray name="shareholders">
                  {({ remove, push }) => (
                    <>
                      {values?.shareholders?.map((shareholder, index) => {
                        return (
                          <>
                            <div
                              className="row align-items-center pb-3"
                              key={shareholder.id}
                            >
                              {/* Shareholder section*/}
                              <div className="col-11">
                                <ShareHolder
                                  index={index}
                                  edit={edit}
                                  shareholdersCount={values.shareholders.length}
                                  shareholder={shareholder}
                                  inputChangeCallback={setFieldValue}
                                  inputBlurCallback={setFieldTouched}
                                />
                              </div>

                              {/* Remove shareholder section*/}
                              {index > 0 && edit && (
                                <div
                                  className="col-1 px-0 px-md-3"
                                  onClick={() => remove(index)}
                                >
                                  <span className="cursor-pointer">
                                    <img
                                      src={TrashIcon}
                                      alt="delete shareholder"
                                    />
                                  </span>
                                </div>
                              )}
                              {index > 0 && !edit && (
                                <div
                                  className="col-1 px-0 px-md-3"
                                  onClick={handleOpenResendModal(shareholder)}
                                >
                                  <span
                                    className="cursor-pointer"
                                    data-for="resend-mail"
                                    data-tip="Resend royalty notification"
                                  >
                                    <img src={envelope} alt="email button" />
                                  </span>
                                </div>
                              )}
                              <Portal selector="body">
                                {isOpenResendCollabModal && (
                                  <ResendCollaboratorMailModal
                                    onCloseRequest={handleOnModalClose}
                                    shareHolderToResendMail={
                                      shareholderToResendMail
                                    }
                                    releaseId={releaseId}
                                    trackIndex={trackIndex}
                                    setIsOpenResendCollabModal={
                                      setIsOpenResendCollabModal
                                    }
                                    trackTitle={trackTitle}
                                  />
                                )}
                              </Portal>
                            </div>

                            <ReactTooltip
                              place="top"
                              backgroundColor="#1d2025"
                              className="ptTooltip"
                              border
                              borderColor="#0ef1db"
                              effect="float"
                              id="resend-mail"
                            />

                            {/* Line break */}
                            <div
                              className="utepHr w-100 mb-3 mt-0"
                              style={{ top: 0 }}
                            ></div>
                          </>
                        );
                      })}

                      {/* Edit section */}
                      {!isOwner ? (
                        <>
                          <div className="row mx-0 pb-3 justify-content-center ">
                            <div className="text-white  font-family-Inter">
                              <p>
                                This track is administered by
                                <span> {ownerName}</span> (
                                <span className="text-primary">
                                  {ownerEmail}
                                </span>
                                ). If you have any questions or remarks about
                                your royalty share details, please{" "}
                                <span className="text-primary">
                                  <a
                                    href={`mailto:${ownerEmail}`}
                                    target="_blank"
                                  >
                                    contact the track administrator directly.
                                  </a>
                                </span>
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        !edit && (
                          <>
                            <div className="row mx-0 pb-3 justify-content-center ">
                              <div
                                className={
                                  "text-primary text-decoration-underline font-family-Inter " +
                                  (edit
                                    ? "opacity-05 cursor-default "
                                    : "cursor-pointer")
                                }
                                onClick={() => setStatus({ edit: true })}
                              >
                                Edit track shareholders
                              </div>
                            </div>
                          </>
                        )
                      )}

                      {/* Add shareholder section*/}
                      {edit && (
                        <div className="row justify-content-between my-2 mt-3 align-items-start flex-wrap-reverse flex-md-wrap">
                          <div className="pb-2 py-md-0 col-12 col-md">
                            <div className="row align-items-start">
                              <div className="pb-2 py-md-0 col-12 col-md-3">
                                <TotalShare value={`${values.totalShare}%`} />
                              </div>
                              <div className="pb-2 py-md-0 col-12 col-md align-self-center fs-14">
                                <ErrorMessage
                                  name={"totalShare"}
                                  component="div"
                                  className="text-danger mx-0"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="mb-3 py-md-0 col-12 col-md-4 align-items-center mr-3"
                            onClick={() =>
                              push({
                                id: uuidv4(),
                                name: "",
                                email: "",
                                share: "",
                              })
                            }
                          >
                            <span className="text-primary rounded bg-dark p-2 px-3 font-weight-bold cursor-pointer mr-2 w-50 h-75">
                              +
                            </span>
                            <span
                              className="text-primary font-family-Inter"
                              style={{ cursor: "pointer" }}
                            >
                              Add shareholder
                            </span>
                          </div>
                          {isValid && dirty && (
                            <div className="shareholder-message  mx-3 my-4 rounded ">
                              <p className="m-1">
                                Warning: Upon saving your changes, notifications
                                will be sent to all track shareholders.
                                <p className="font-weight-bold pl-lg-4 mb-0">
                                  Make sure addresses and shares are correct,
                                  before you save the configuration.
                                </p>
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>

                {/* Save/Cancel shareholder info section */}
                {edit && (
                  <div className="row mx-0 px-3 justify-content-center">
                    <div>
                      <ButtonComponent
                        onClick={() => {
                          resetForm();
                          setStatus({ edit: false });
                        }}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton bg-transparent textLink w-100 h-75"
                      >
                        Cancel
                      </ButtonComponent>
                    </div>
                    <div>
                      <ButtonComponent
                        disabled={isSubmitting || !dirty}
                        onClick={handleSubmit}
                        buttonWrapperClassName="pr-3"
                        className="playtreksButton w-100 h-75"
                      >
                        {isSubmitting ? (
                          <>
                            <span className="mr-2">Saving... </span>
                            <span>
                              <Spinner />
                            </span>
                          </>
                        ) : (
                          "Save"
                        )}
                      </ButtonComponent>
                    </div>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </>
    );
  }
);

export default ShareHoldersForm;
