import React from "react";

import { Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "../TabPanel";
import { useState } from "react";
import TrackTransactions from "./TrackTransactions";
import ContractTransactions from "./ContractTransactions";
import RightholdersTransactions from "./RightholdersTransactions";
import AlbumTransactions from "./AlbumTransaction";
import UserTransactions from "./UserTransaction";
import "./index.scss";

const Transactions = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <main className="myReleases-overview py-0 text-white">
      <div className="prTracksCard w-100 py-3">
        <div className="transactions-header">Transactions</div>
        <div
          className="rounded transactions-sub-heading text-white mx-3 p-3"
          style={{ backgroundColor: "#2c313a" }}
        >
          Transparent royalty accounting at your fingertips. This is how
          PlayTreks processes and reports your earnings. First, we collect all
          reported earnings from your distributed albums and tracks on all
          platforms you distributed to. Secondly, we process the income by
          applying the royalty shares and recouping rules that you have
          configured in each contract. Then we take care of transferring the
          resulting income share to all rights holders, including yourself.
        </div>
      </div>
      <div className="prTracksCard w-100 pb-0">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="By Album" {...a11yProps(0)} />
          <Tab label="By Track" {...a11yProps(1)} />
          <Tab label="By Contract" {...a11yProps(2)} />
          <Tab label="By Right holders" {...a11yProps(3)} />
          <Tab label="My balance" {...a11yProps(4)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className="transactions-sub-heading text-white px-2 pt-3">
            PlayTreks collects your earnings for album downloads and then
            converts it to track-level income.
          </div>
          <AlbumTransactions />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className="transactions-sub-heading text-white px-2 pt-3">
            Track-level income is collected from streaming and download
            platforms. The income is then transferred to the track's contract
            for further processing.
          </div>
          <TrackTransactions />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className="transactions-sub-heading text-white px-2 pt-3">
            A contract receives money from one or more tracks, and delivers
            money to the respective rights holders according to the royalty and
            expense recouping rules stored in the contract.
          </div>
          <ContractTransactions />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <div className="transactions-sub-heading text-white px-2 pt-3">
            Rights holders receive money from one or more contracts, taking into
            account their royalty shares and expenses recouping status.
          </div>
          <RightholdersTransactions />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <div className="transactions-sub-heading text-white px-2 pt-3">
            An overview of the resulting income for your organisation. The
            amounts displayed here will become available for withdrawal, as soon
            as PlayTreks has received your earnings.{" "}
          </div>
          <UserTransactions />
        </TabPanel>
      </div>
    </main>
  );
};

export default Transactions;
