import React, { useState, useEffect, useMemo } from "react";

import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import Dropbox from "@uppy/dropbox";
import GoogleDrive from "@uppy/google-drive";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

///
/// Note about tooltips: The tooltip <ReactTooltip/> tag must appear only once,
/// if it appears twice all the tooltips will appear doubled.
/// Right now, the tag is placed inside of the SelectComponent.js file,
/// because that's the only way the tooltips appear in the right way (also propagates to input components)
/// (Reason for this behaviour is unknown, yet that's the way it works correctly, hence the explanation)
///

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  populateTracksForm,
  populateReleaseForm,
  populateUserEmail,
  validateAllTracks,
  cleanUpSubmitting,
  addAndPrepopulateTrackWithBasicInformation,
  autofixSingle,
  setUploadingInProgress,
  toggleUtLicensesConfirmCheckbox,
  setUsedArtistFields,
} from "../../redux/actions/distributionActions.js";

// packages
import path from "path";

// components
import Header from "./components/RuslanHeader";
import CustomAlert from "../customAlert/index";
import Checkbox from "../form/RuslanCheckbox";

// distribution specific
import ReleaseProgressCircles from "./components/ReleaseProgressCircles";
import Dropzone from "../form/DropzoneComponent";
import Sortable from "./components/SortableComponent";
import ExpandedPanel from "./components/UploadTracksFormPanel";

// images
import spinner from "../../assets/spinner.svg";

// helper functions
import {
  prepareFormForBackend,
  convertBackToFrontEnd,
} from "./helpers/distrHelpers";

// Backend
import {
  submitTracks,
  submitSingleTrackFile,
  getReleaseData,
  submitSingleTrackMetadata,
  uploadTrackToS3,
} from "../../routes/distribution";

import { getUserEmail } from "../../routes/users";
import QuitNewReleaseModal from "./components/QuitNewReleaseModal";
import { getUsedArtists } from "../../routes/distribution";
import { getJWT } from "../utils.js";

function DistributionUploadTracks() {
  const jwtToken = getJWT();
  const currentReleaseId = window.localStorage.getItem("currentReleaseId");

  // form state (needs to initialize before fetching form data from DB)
  const [trackUploadIndex, setTrackUploadIndex] = useState(0);
  const [prepopulated, setPrepopulated] = useState(false); // <== for conditional field expanding (if true, and the user comes back to edit the page, the fields have to know not to expand)
  const [error, setError] = useState("");
  const [changesPresent, setChangesPresent] = useState(false); // for the quit modal to conditionally display

  const [trackUploadedFeedback, setTrackUploadedFeedback] = useState(false);
  const [dropzoneIsUploading, setDropzoneIsUploading] = useState(false);

  const [fileSizeError, setFileSizeError] = useState(false);

  // REDUX
  const reduxTracksForm = useSelector(
    (state) => state.distributionReducer?.tracksForm
  );
  const reduxSingleValidation = useSelector(
    (state) => state.distributionReducer?.singleValidation
  );
  const reduxAllTracksFieldsBoolean = useSelector(
    (state) => state.distributionReducer?.allTracksFieldsBoolean
  );
  const reduxAllTracksGeneralShareAmountValidation = useSelector(
    (state) => state.distributionReducer?.allTracksGeneralShareAmountValidation
  );
  const reduxAllTracksReleaseOnePrimaryArtistPresentValidation = useSelector(
    (state) =>
      state.distributionReducer
        ?.allTracksReleaseOnePrimaryArtistPresentValidation
  );

  const uploadingInProgress = useSelector(
    (state) => state.distributionReducer?.uploadingInProgress
  );

  const dispatch = useDispatch();

  function handleFixSingleValidation() {
    dispatch(autofixSingle());
  }

  const uppy = useMemo(() => {
    return (
      new Uppy({
        id: "tracks-upload",
        autoProceed: false,
        meta: {
          releaseId: currentReleaseId,
        },
        restrictions: {
          allowedFileTypes: ["audio/wav", "audio/wave", ".wav"],
        },
        // onBeforeFileAdded: (currentFile) => {
        //   if (
        //     currentFile.source === "react:Dashboard" &&
        //     currentFile.size > 100000000 // 100MB
        //   ) {
        //     uppy.info(
        //       `Due to technical restrictions, files larger than 100MB are
        //     currently supported only via DropBox and GoogleDrive.`,
        //       "error",
        //       3000
        //     );
        //     return false;
        //   }

        //   return true;
        // },
      })
        // .use(Dropbox, {
        //   companionUrl: `${global.config.API_URL}/api/companion`,
        // })
        // .use(GoogleDrive, {
        //   companionUrl: `${global.config.API_URL}/api/companion`,
        // })
        .use(AwsS3, {
          companionUrl: `${global.config.API_URL}/api/companion`,
          companionHeaders: {
            "uppy-auth-token": `Bearer ${jwtToken}`,
          },
          metaFields: ["releaseId"],
        })
    );
  }, []);

  useEffect(() => {
    uppy.on("complete", (result) => {
      result.successful.forEach((file) => {
        const trackNameFromFile = file.name.substr(
          0,
          file.name.length - path.extname(file.name).length
        );

        dispatch(
          addAndPrepopulateTrackWithBasicInformation(
            trackNameFromFile,
            file?.uploadURL
          )
        );
      });
    });

    return () => uppy.close();
  }, [uppy]);

  useEffect(() => {
    let currentReleaseId = window.localStorage.getItem("currentReleaseId");
    if (currentReleaseId) {
      // getting form from the backend
      getReleaseData(currentReleaseId, jwtToken).then((r) => {
        if (r.data.length === 0) {
          // if no data in the DB for the releaseId, means the user has been navigating through urls
          // in this case we redirect them to the first page of the flow
          window.location.assign("/distribution/newRelease/1");
        } else {
          // if data is present
          // if the tracks array is not present, means the user is approaching this step of the form for the first time
          // putting release form back into redux state
          dispatch(
            populateReleaseForm(convertBackToFrontEnd(r.data[0], "release"))
          );
          if (r.data[0].tracks) {
            // if tracks are present, means user is editing the form
            //
            setPrepopulated(true); // <== pass to sortable item to prevent expanding of the fields if user is editing
            // converting back to front-end format
            let parsedTracks = r.data[0].tracks.map((track) => ({
              ...track,
              form: convertBackToFrontEnd(track.form, "tracks"),
            }));
            // setting redux tracks form state
            dispatch(populateTracksForm(parsedTracks));
          }
        }
      });
      getUserEmail(jwtToken).then((r) => dispatch(populateUserEmail(r.data)));
    } else {
      // if no rId, means the user has been navigating through urls
      // in this case we redirect them to the first page of the flow
      window.location.assign("/distribution/newRelease/1");
    }
  }, []); // run on page load

  // subscription restrictions logic

  useEffect(() => {
    // populate redux used artists
    dispatch(setUsedArtistFields());
  }, []);

  function handleFileSizeError(file) {
    setFileSizeError(false);
    if (file?.[0]?.size >= 100000000) {
      setFileSizeError(true);
      return true;
    }
  }

  async function handleAcceptTrack(file, e) {
    if (handleFileSizeError(file)) return;
    setError("");
    setChangesPresent(true);
    setPrepopulated(false); // <== setting back to false, since the user starts uploading tracks and the panels should expand
    let trackNameFromFile = file[0].name.substr(
      0,
      file[0].name.length - path.extname(file[0].name).length
    );

    // UI state (button etc.)
    dispatch(setUploadingInProgress(true));
    // UI state (dropzone)
    setDropzoneIsUploading(true);
    // uploading file to S3
    try {
      const uploadFileResponse = await uploadTrackToS3(
        new Blob(file),
        currentReleaseId,
        jwtToken
      );
      setTrackUploadedFeedback(true);
      setTimeout(() => {
        setTrackUploadedFeedback(false);
      }, 4000);
      // UI state
      dispatch(setUploadingInProgress(false));
      setDropzoneIsUploading(false);

      dispatch(
        addAndPrepopulateTrackWithBasicInformation(
          trackNameFromFile,
          uploadFileResponse.data.wavFileLink
        )
      );
    } catch (err) {
      // TODO: handle in the FE
      dispatch(setUploadingInProgress(false));
      setDropzoneIsUploading(false);
      setError({
        msg: err?.response?.data?.msg,
      });
    }
  }

  function handleRejectTrack(file, e) {
    alert("Please make sure your file format is .wav");
  }

  const reduxLicensesCheckbox = useSelector(
    (state) => state.distributionReducer?.licensesCheckbox
  );

  function handleConfirmLicensesCheckbox() {
    dispatch(toggleUtLicensesConfirmCheckbox());
  }

  function handleSubmit() {
    dispatch(validateAllTracks());
  }

  // hack
  const reduxSubmitting = useSelector(
    (state) => state.distributionReducer?.submitting
  );

  useEffect(() => {
    dispatch(cleanUpSubmitting());
    if (reduxSubmitting) executeSubmit();
  }, [reduxSubmitting]);

  // called by state change of validationFailed inside of useEffect
  async function executeSubmit() {
    // check validation
    if (
      reduxAllTracksFieldsBoolean ||
      reduxAllTracksGeneralShareAmountValidation ||
      reduxAllTracksReleaseOnePrimaryArtistPresentValidation ||
      reduxSingleValidation ||
      !reduxLicensesCheckbox
    )
      return;

    // submitting form
    let parsedTracks = reduxTracksForm.map((track) => {
      track.originalFileName = track.originalFileName;
      track.form = prepareFormForBackend(track.form, "tracks");
      return track;
    });
    try {
      await submitTracks(parsedTracks, currentReleaseId, jwtToken); // submitting tracks form
    } catch (e) {
      setTrackUploadIndex(-1);
      setError({
        msg: e.response.data.msg,
        fileIndex: e.response.data.fileIndex,
      });
    }
    // }
    window.location.assign("/distribution/newRelease/3");
  }

  const [quitModal, setQuitModal] = useState(false);

  function quitCallback() {
    if (changesPresent) {
      setQuitModal(true);
    } else {
      window.location.assign("/artistDashboard/myReleases");
    }
  }

  return (
    <div className="distributionRoot" style={{ backgroundColor: "#343a47" }}>
      <Header quitCallback={quitCallback}></Header>
      <CustomAlert
        variant={
          "utTrackUploadedAlert " + (trackUploadedFeedback ? "" : "d-none")
        }
      >
        Track file uploaded! &nbsp; ✅{/* TODO: get file name in there */}
      </CustomAlert>
      <QuitNewReleaseModal
        className={quitModal ? "" : "d-none"}
        setQuitModal={setQuitModal}
      />
      {/* title */}
      <div className="newReleaseTitle">new release</div>
      {/* release progress circles */}
      <ReleaseProgressCircles activeCircle={2} />
      {/* form */}
      <div className="utDropzoneText1">
        Requirements Guidelines:{" "}
        <a href="/distribution/metadataGuidelines" target="blank">
          Metadata
        </a>
        /
        <a href="/distribution/audioRequirements" target="blank">
          Audio
        </a>
      </div>
      <Dashboard
        uppy={uppy}
        width={window.innerWidth > 1024 ? 864 : "90%"}
        height={300}
        theme="dark"
        className="utDropzone"
        // plugins={["Dropbox", "GoogleDrive"]}
      />
      {/* <Dropzone
        inputClassName="utDropzoneInput"
        className="utDropzone"
        // acceptFileType="audio/wav"
        onAccept={handleAcceptTrack}
        onReject={handleRejectTrack}
        disabled={dropzoneIsUploading}
      >
        {dropzoneIsUploading ? (
          <div className="utDropzone-loadingWrapper">
            <img
              alt="loading"
              src={spinner}
              className="utDropzone-loadingWrapper-spinner"
            />
            <div className="utDropzone-loadingWrapper-text">
              uploading your track...
            </div>
          </div>
        ) : (
          <div>
            <div className="utDropzoneText1">drag and drop in the area</div>
            <div className="utDropzoneButtonWrapper">
              <div className="utDropzoneButton">
                Upload from {window.innerWidth > 768 ? "computer" : "device"}
              </div>
            </div>
            <div className="utDropzoneText2">
              Due to platform requirements, only .wav files are allowed
            </div>
            {fileSizeError ? (
              <div className="utFileSizeError">
                Due to technical restrictions, files larger than 100mb are
                currently not supported.
                <br />
                Please try to use a lower bit rate wav file, or contact support.
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Dropzone> */}

      <div className="utDndWrapper">
        <div className="utTrackErrorMsg">{error.msg}</div>
        <div className="distributionRoot "></div>
        <Sortable
          itemClassName="utDndItem"
          containerClassName="utDndContainer mx-auto"
          prepopulated={prepopulated}
          setChangesPresent={setChangesPresent}
        >
          <div className="utBottomValFeedback">
            {reduxSingleValidation ? (
              <div>
                In case of singles (one track releases) the track fields must
                match release fields exactly. <br /> You may fix the issue
                automatically by clicking the button below - your form data will
                get imported from the first page. <br />
                <div
                  className="playtreksButton utFixSingleButton mt-3"
                  style={{
                    border: "2px solid red",
                    fontSize: "18px",
                    color: "black",
                  }}
                  onClick={handleFixSingleValidation}
                >
                  Fix automatically
                </div>
              </div>
            ) : reduxAllTracksFieldsBoolean ? (
              "Please review the track forms and try again"
            ) : reduxAllTracksGeneralShareAmountValidation ? (
              "You have assigned more than 100% to contributors, please review the share inputs"
            ) : reduxLicensesCheckbox === false ? (
              "Please tick the checkbox below"
            ) : reduxAllTracksReleaseOnePrimaryArtistPresentValidation ? (
              "Each track must have at least one primary artist from the release"
            ) : (
              ""
            )}
          </div>
          <div
            className={trackUploadIndex > -1 ? "d-none" : "utBottomValFeedback"}
          ></div>
          {/* button is inside of the container in order to appear below correctly (hacky) */}
          <div onClick={handleConfirmLicensesCheckbox} id="utLicensesCheckbox">
            <Checkbox
              className="d-inline-block"
              checked={reduxLicensesCheckbox}
            />
            &nbsp;
            <span className="utConfirmLicenses">
              I have submitted all legal and necessary licenses for any samples
              used in the making of this release
            </span>
          </div>
          <div
            className={
              "utSaveAndPreviewButton " +
              (reduxTracksForm?.length === 0 || uploadingInProgress
                ? "rfButtonInactive"
                : "")
            }
            onClick={
              reduxTracksForm?.length > 0 && !uploadingInProgress
                ? () => handleSubmit()
                : ""
            }
          >
            {trackUploadIndex < 1
              ? trackUploadIndex !== -1
                ? "Save and preview"
                : "Retry"
              : `Uploading track ${trackUploadIndex}...`}
            &nbsp;
            <img
              alt="loading"
              src={spinner}
              className={trackUploadIndex < 1 ? "d-none" : ""}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div className="nrTermsAndConditionsText utTerms">
            By clicking you agree with{" "}
            <a href="/terms" target="_blank">
              <u>Terms & Conditions</u>
            </a>
          </div>
        </Sortable>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}

export default DistributionUploadTracks;
