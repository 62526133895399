import React, { useState } from "react";

import yt from "../../../assets/icons/yt_icon_rgb.png";
import fb from "../assets/connect/fb.svg";
import ig from "../assets/connect/ig.svg";
import tw from "../assets/connect/tw.svg";
import ap from "../assets/connect/ap.svg";
import sp from "../assets/connect/sp.svg";
import ConnectBox from "./ObConnectBox";
import ErrorPopup from "../../error/ErrorPopup";

function ConnectedPlatforms({ errorCallback, iconMarginTopFix }) {
  const [isError, setIsError] = useState(false);

  function errorCallback(value) {
    setIsError(value);
  }

  return (
    <div className="obConnectRows">
      <div className="row">
        <div className="mr-3">
          <ConnectBox
            serviceText="spotify"
            serviceImgStyle={{ marginTop: "3px" }}
            img={sp}
            errorCallback={errorCallback}
            // connectionStatus={connectedAccounts.spotify}
          />
        </div>
        <div className="mr-3">
          <ConnectBox
            serviceText="facebook"
            serviceImgStyle={{ marginTop: iconMarginTopFix / 2 + "px" || "0" }}
            img={fb}
            errorCallback={errorCallback}
            // connectionStatus={connectedAccounts.facebook}
          />
        </div>
        <div className="mr-3">
          <ConnectBox
            serviceText="instagram"
            serviceImgStyle={{
              marginTop: "4px",
              filter: "grayscale(1) brightness(120%)",
            }}
            img={ig}
            errorCallback={errorCallback}
            comingSoon={true} // by switching that we can deactivate a service
          />
        </div>
      </div>
      <div className="row">
        <div className="mr-3">
          <ConnectBox
            serviceText="twitter"
            serviceImgStyle={{ marginTop: "4px" }}
            img={tw}
            errorCallback={errorCallback}
            // connectionStatus={connectedAccounts.twitter}
          />
        </div>
        <div className="mr-3">
          <ConnectBox
            serviceText="youtube"
            serviceImgStyle={{
              marginTop: iconMarginTopFix + "px" || "3px !important",
              height: "18px",
            }}
            service="google"
            img={yt}
            errorCallback={errorCallback}
            // connectionStatus={connectedAccounts.youtube}
          />
        </div>
        <div className="mr-3">
          <ConnectBox
            serviceText="apple music"
            img={ap}
            errorCallback={errorCallback}
            serviceImgStyle={{
              filter: "grayscale(1) brightness(120%)",
              marginTop: "3px",
            }}
            service="apple"
            comingSoon={true}
          />
        </div>
      </div>
      <div className="mx-auto w-100">
        <ErrorPopup
          error={isError}
          style={{
            fontSize: "15px",
            textAlign: "center",
          }}
          className="mx-auto"
          customText="Something went wrong, please refresh the page"
        />
      </div>
    </div>
  );
}

export default ConnectedPlatforms;
