import React, { useState } from "react";

import credit_card from "../assets/credit_card.svg";

import spinner from "../../../assets/spinner.svg";

import { setPaymentMethod } from "../../../routes/payments";

import { loadStripe } from "@stripe/stripe-js";
import PerformPaypalPaymentComponent from "./PerformPaypalPaymentComponent";
import { getJWT } from "../../utils";

const INITIAL = "initial";
const IN_PROGRESS = "in_progress";
const ERROR = "error";

const stripePromise = loadStripe(global.config.REACT_APP_STRIPE_PK);

function SetPaymentMethodComponent({ redirectTo }) {
  // get jwt for auth
  const jwtToken = getJWT();

  const [status, setStatus] = useState(INITIAL);

  // call to get payment method, check if connected
  function handleSetPaymentMethod() {
    setStatus(IN_PROGRESS);
    setPaymentMethod(redirectTo, jwtToken)
      .then(async (r) => {
        const stripe = await stripePromise;
        stripe.redirectToCheckout({
          sessionId: r.data.sessionId,
        });
      })
      .catch((e) => setStatus(ERROR));
  }

  return (
    <div className="setPaymentMethod mt-3">
      {status === ERROR ? (
        <small
          className="text-danger"
          style={{ position: "absolute", bottom: "40px" }}
        >
          Error, please try again
        </small>
      ) : (
        ""
      )}
      <span>
        <div
          className="setPaymentMethod-button"
          onClick={handleSetPaymentMethod}
        >
          {[INITIAL, ERROR].includes(status) ? (
            <span>
              <div className="setPaymentMethod-button-text">credit card</div>
              <div className="setPaymentMethod-button-logoBox">
                <img src={credit_card} alt="credit card" />
              </div>
            </span>
          ) : (
            ""
          )}
          {status === IN_PROGRESS ? (
            <span style={{ height: "100%" }} className="xyFlexCenter">
              <img src={spinner} alt="loading" style={{ height: "80%" }} />
            </span>
          ) : (
            ""
          )}
        </div>
      </span>
    </div>
  );
}

export default SetPaymentMethodComponent;
