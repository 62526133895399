import { getJWT } from "../../components/utils";
import axios from "../../lib/axios_retry_wrapper";
import { parseQueryParams } from "../utils";

const endpoints = {
  spotifyCharts: "charts/spotify",
  entityChartsAggByCountry: "charts/spotify/entity/aggByCountry",
  spotifyChartsDownload: "charts/spotify/download",
  entitySpotifyChartsHistory: "charts/spotify/entity/historicPositions",
  entitySummaryStats: "charts/spotify/entity/summaryStats",
};

/**
 * Function to fetch spotify charts of non entity.
 *
 * @param {object} queryParams Object containing query params
 * @param {object} cancelToken Axios cancel token to cancel the request
 * @returns {object} Response object containing list of tracks of a chart
 */
export async function getSpotifyCharts(queryParams = {}, cancelToken = null) {
  let url = `${global.config.API_URL}/api/${
    endpoints.spotifyCharts
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    cancelToken,
  });
}

/**
 * Function to download non entity spotify charts
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Returns downloadable spotify chart data
 */
export async function downloadSpotifyChartsCSV(queryParams = {}) {
  let url = `${global.config.API_URL}/api/${
    endpoints.spotifyChartsDownload
  }${parseQueryParams(queryParams)}`;

  return axios({
    url,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: "Bearer " + getJWT(),
      "Content-Type": "text/csv",
    },
  });
}

//entity summary stats
export async function getEntitySpotifySummaryStats({ queryKey }) {
  const [_key, queryParams] = queryKey;

  let url = `${global.config.API_URL}/api/${
    endpoints.entitySummaryStats
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    // cancelToken,
  });
}

/**
 * Function to fetch top countries list by tracks and streams count
 * of an entity
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Response object containing list of top countries aggregation of an entity
 */
export async function getEntitySpotifyChartsAggByCountry({ queryKey }) {
  const [_key, queryParams] = queryKey;
  let url = `${global.config.API_URL}/api/${
    endpoints.entityChartsAggByCountry
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    // cancelToken,
  });
}

/**
 * Function to fetch entity historic spotify charts data
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Returns entity historic spotify charts data
 */
export async function getEntitySpotifyChartsHistory({ queryKey }) {
  const [_key, queryParams] = queryKey;
  let url = `${global.config.API_URL}/api/${
    endpoints.entitySpotifyChartsHistory
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    // cancelToken,
  });
}
