import React from "react";
import StatsCardIcon from "../StatsCardIcon";

import info from "../../../assets/icons/info.svg";
import ReactTooltip from "react-tooltip";

const GRADIENT_STRETCH = global.config.gradientStretch;

function StatsCard({
  name,
  cardIcon,
  className,
  handleClick,
  comingSoon,
  connected,
  pageConnected,
  objectPath,
  tooltip,
  tooltipText,
  customInfo,
  customInfoClassName,
  barMode,
  valueSuffix,
  customLabelClass,
  barColor,
}) {
  var hideIfNoDataClass; // ??

  // only for barMode
  // inspired by https://cssgradient.io/
  const barBackground = `linear-gradient(90deg, ${barColor} ${objectPath}%, rgba(52,57,71,1) ${
    objectPath + Math.min(objectPath + 2, GRADIENT_STRETCH)
  }%)`;

  return (
    <div
      className={`social-card ${className} ${hideIfNoDataClass}`}
      // onClick={handleClick}
    >
      <div className="social-card-name">
        <label className={customLabelClass}>{name}</label>
        {tooltip &&
          (customInfo ? (
            <div
              className={customInfoClassName}
              data-tip={tooltipText}
              data-for="track-features-card-info"
            >
              {customInfo}
            </div>
          ) : (
            <img
              src={info}
              className="track-features-card-info"
              data-tip={tooltipText}
              data-for="track-features-card-info"
            />
          ))}
        {cardIcon && <StatsCardIcon name="" icon={cardIcon} />}
      </div>

      <ReactTooltip
        backgroundColor="#1d2025"
        className="ptTooltip"
        border
        borderColor="#0ef1db"
        // effect="float"
        multiline={true}
        id="track-features-card-info"
      />

      <span className="social-card-stat">
        {!comingSoon ? (
          <div>
            <label className="track-features-card-value">
              {objectPath}
              {valueSuffix}
            </label>
          </div>
        ) : (
          <div className="social-card-stat-comingSoon mt-3">coming soon</div>
        )}
      </span>
      {/* this is used for displaying track features on mobile */}
      {barMode && (
        <div
          className="social-card-bar social-card-bar-mobile"
          style={{
            background: barBackground,
          }}
        />
      )}
    </div>
  );
}
export default StatsCard;
