import React from "react";
import Spinner from "../assets/spinner.svg";

const LoadingPage = () => (
  <div style={{ width: "100%", height: "100%" }}>
    <img
      alt="spinner"
      src={Spinner}
      className="ptCenteredSpinner mx-auto"
    ></img>
  </div>
);

export default LoadingPage;
