/**
 * Useful callback for the useselector instead of anonymous function
 * function to avoid unnecessary re-renders on function ref change
 *
 * @param {object} state Redux state
 * @returns {object}
 */
export const getIsSubscribedInfoFromStore = (state) =>
  state.subscriptionReducer?.subscription?.rules?.airplayData?.enabled;

/**
 * Useful callback for the useselector instead of anonymous function
 * function to avoid unnecessary re-renders on function ref change
 *
 * @param {object} state Redux state
 * @returns {object}
 */
export const getRelatedMainEntityDataFromStore = ({
  dashboardReducer: {
    mainEntity: {
      id,
      type,
      name,
      service_profiles = {},
      radioMonitoringData = { data: [], metadata: {} },
      radioAggregationData = {},
    } = {},
  } = {},
}) => ({
  id,
  type,
  name,
  service_profiles,
  radioMonitoringData,
  radioAggregationData,
});
