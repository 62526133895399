import React, { useState, useEffect, memo, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RadioMonitoringTable from "../../components/artistDashboard/RadioMonitoringTable";
import moment from "moment";
import {
  pushToEntityData,
  REDUX_SET_RADIO_AGGREGATION_DATA,
  REDUX_SET_RADIO_MONITORING_DATA,
} from "../../redux/actions/dashboardActions";
import CalendarComponent from "../../components/form/CalendarComponent";
import InputComponent from "../../components/form/InputComponent";
import calendar from "../../assets/icons/calendar.svg";
import SelectComponent from "../../components/form/SelectComponent";
import {
  downloadAirplays,
  getAirplayAggregations,
  getAirPlayOptions,
  getAirplays,
} from "../../services/airplay";
import AirplayTopPlacesAgregationChart from "./components/AirplayAgregationChart";
import AirplayAggregationMap from "./components/AirplayAggregationMap";
import AddSpotifyArtistModal from "../../components/artistDashboard/Profile/components/AddSpotifyArtistModal";
import { reduxSetUserData } from "../../redux/actions/dashboardActions";
import { Link, useLocation } from "react-router-dom";
import AppliedFilters from "./components/AppliedFilters";
import ButtonComponent from "../../components/form/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import spinner from "../../assets/spinner.svg";
import axios from "../../lib/axios_retry_wrapper";
import { cancelRequest } from "../../services/utils";
import { isEqual } from "lodash";
import {
  getIsSubscribedInfoFromStore,
  getRelatedMainEntityDataFromStore,
} from "./utils";
import { useInfiniteQuery } from "react-query";

export const Spinner = () => (
  <div className="xyFlexCenter">
    <img src={spinner} style={{ width: "24px" }}></img>
  </div>
);

const SPOTIFY = "spotify";
const columns = [
  {
    Header: "Stream title",
    accessor: "streamTitle", // accessor is the "key" in the data
    disableSortBy: true,
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: ({ value }) =>
      moment(new Date(value)).format("Do, MMM YYYY, h:mm:ss a"),
  },
  {
    Header: "Station",
    accessor: "station.title",
    disableSortBy: true,
  },
  {
    Header: "Place",
    disableSortBy: true,
    accessor: "station.place.title",
  },
  {
    Header: "Country",
    disableSortBy: true,
    accessor: "country",
  },
];

let airplaysCancelToken;
let aggregationsCancelToken;

const Airplay = memo(() => {
  const [spotifyArtistModal, setSpotifyArtistModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [aggregationsLoading, setAggregationsLoading] = React.useState(false);
  const [pagination, setPagination] = useState({
    pageCount: 0,
    pageIndex: 0,
    size: 10,
  });
  const [sortBy, setSortBy] = useState({ id: "date", desc: true });
  const reduxMainEntity = useSelector(
    getRelatedMainEntityDataFromStore,
    isEqual
  );
  const isSubscribedToAirplay = useSelector(getIsSubscribedInfoFromStore);
  const [calendarState, setCalendarState] = useState({
    fromDate: false,
    untilDate: false,
  });
  const [pickedDate, setPickedDate] = useState({
    fromDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    untilDate: moment().format("YYYY-MM-DD"),
  });
  const [countryFilter, setCountryFilter] = useState([]);
  const [countryFilterOptions, setCountryFilterOptions] = useState([]);
  const [downloading, setDownloading] = useState(false);
  const isFirstRender = useRef(true);

  const dispatch = useDispatch();
  const location = useLocation();

  const fetchAirplaysInfinite = async ({ pageParam = 0 }) => {
    const userSpotifyId =
      reduxMainEntity?.service_profiles?.selectedSpotifyID ||
      reduxMainEntity?.id;
    const type = reduxMainEntity?.type;

    let queryParams = {
      size: 25,
      offset: pageParam || 0,
      sortBy: sortBy?.id ?? "",
      sortOrder: sortBy?.id ? (sortBy.desc ? -1 : 1) : 0,
      fromDate: pickedDate.fromDate ? pickedDate.fromDate : "",
      untilDate: pickedDate.untilDate
        ? moment(pickedDate.untilDate).add(1, "days").format("YYYY-MM-DD")
        : "",
      countries: countryFilter
        ? countryFilter?.map((c) => c.value)?.join(",")
        : "",
      spotifyId: userSpotifyId,
      type,
    };

    const results = await getAirplays(queryParams);

    return {
      data: results?.data?.data,
      nextOffset: !results?.data?.data?.length
        ? undefined
        : results.data.metadata.nextOffset,
    };
  };

  const {
    data: airplayData,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      "airplay",
      reduxMainEntity?.service_profiles?.selectedSpotifyID ||
        reduxMainEntity?.id,
      reduxMainEntity?.type,
      countryFilter?.map((c) => c.value)?.join(",") ?? "",
      pickedDate.fromDate,
      pickedDate.untilDate,
      sortBy?.id,
      sortBy?.id ? (sortBy.desc ? -1 : 1) : 0,
    ],
    fetchAirplaysInfinite,
    {
      getNextPageParam: (lastPage) => lastPage.nextOffset,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  /**
   * Loads the airplay data of corresponding entity from the url
   */
  useEffect(() => {
    let id = new URLSearchParams(location.search).get("id");
    let name = new URLSearchParams(location.search).get("entityName");
    let type = new URLSearchParams(location.search).get("type");
    if (id && name && type) {
      dispatch(pushToEntityData({ id, name, type }));
    }
  }, [location]);

  /**
   * Cancels any pending requests on unmounting
   */
  useEffect(
    () => () => {
      cancelRequest(airplaysCancelToken);
      cancelRequest(aggregationsCancelToken);
    },
    []
  );

  /**
   * Fetches airplay country filter options on first load
   */
  useEffect(() => {
    setCountriesOptions();
  }, []);

  /**
   * Effect to fetch airplays of user when the dependencies change
   */
  useEffect(() => {
    cancelRequest(airplaysCancelToken);
    cancelRequest(aggregationsCancelToken);

    // if (!reduxMainEntity?.radioMonitoringData?.data?.length) {
    //   fetchAirplays();
    // } else {
    //   if (reduxMainEntity?.radioMonitoringData?.metadata)
    //     setPagination((prevPagination) => ({
    //       ...prevPagination,
    //       ...reduxMainEntity.radioMonitoringData.metadata,
    //     }));
    // }
    if (!Object.keys(reduxMainEntity.radioAggregationData).length) {
      fetchAirplayAggregations();
    }
  }, [
    reduxMainEntity?.type,
    reduxMainEntity?.service_profiles?.selectedSpotifyID,
    reduxMainEntity?.id,
  ]);

  /**
   * Effect to fetch airplays of main entity
   * when the dependencies change
   */
  // useEffect(() => {
  //   if (!isFirstRender.current) {
  //     fetchAirplays();
  //   }
  // }, [
  //   pagination.pageIndex,
  //   pagination.size,
  //   sortBy?.id,
  //   sortBy?.desc,
  //   countryFilter,
  //   pickedDate.fromDate,
  //   pickedDate.untilDate,
  // ]);

  /**
   * Effect to fetch airplay aggregation data of
   * main entity when the dependencies change
   */
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (getTimeDiff(pickedDate.fromDate, pickedDate.untilDate) <= 7)
        fetchAirplayAggregations();
    }
  }, [countryFilter, pickedDate.fromDate, pickedDate.untilDate]);

  const fetchAirplayAggregations = async () => {
    const userSpotifyId =
      reduxMainEntity?.service_profiles?.selectedSpotifyID ||
      reduxMainEntity?.id;
    const type = reduxMainEntity?.type;

    try {
      if (userSpotifyId && type) {
        if (!aggregationsLoading) setAggregationsLoading(true);

        //Check if there are any previous pending requests
        cancelRequest(aggregationsCancelToken);

        //Save the cancel token for the current request
        aggregationsCancelToken = axios.CancelToken.source();

        let queryParams = {
          size: 100,
          countries: countryFilter
            ? countryFilter?.map((c) => c.value)?.join(",")
            : "",
          fromDate: pickedDate.fromDate ? pickedDate.fromDate : "",
          untilDate: pickedDate.untilDate
            ? moment(pickedDate.untilDate).add(1, "days").format("YYYY-MM-DD")
            : "",
          spotifyId: userSpotifyId,
          type,
        };

        const response = await getAirplayAggregations(
          queryParams,
          aggregationsCancelToken.token
        );

        if (response?.data) {
          dispatch({
            type: REDUX_SET_RADIO_AGGREGATION_DATA,
            id: userSpotifyId,
            radioAggregationData: response.data,
            target: type === "user" ? "user" : "history",
          });
        }
        setAggregationsLoading(false);
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        setAggregationsLoading(false);
      }
    }
  };

  /**
   * Function to set airplay country filter options
   */
  const setCountriesOptions = async () => {
    try {
      const options = await getAirPlayOptions();

      if (options?.data?.countries) {
        setCountryFilterOptions(
          options.data.countries.sort().map((country) => ({
            label: country,
            value: country,
          }))
        );
      }
    } catch (e) {
      //TODO: Handle error
    }
  };

  /**
   * Function to fetch airplays
   */
  // const fetchAirplays = async () => {
  //   const userSpotifyId =
  //     reduxMainEntity?.service_profiles?.selectedSpotifyID ||
  //     reduxMainEntity?.id;
  //   const type = reduxMainEntity?.type;

  //   try {
  //     if (userSpotifyId && type) {
  //       if (!loading) setLoading(true);

  //       //Check if there are any previous pending requests
  //       cancelRequest(airplaysCancelToken);

  //       //Save the cancel token for the current request
  //       airplaysCancelToken = axios.CancelToken.source();

  //       let queryParams = {
  //         size: pagination.size,
  //         offset: pagination.pageIndex * pagination.size,
  //         sortBy: sortBy?.id ?? "",
  //         sortOrder: sortBy?.id ? (sortBy.desc ? -1 : 1) : 0,
  //         fromDate: pickedDate.fromDate ? pickedDate.fromDate : "",
  //         untilDate: pickedDate.untilDate
  //           ? moment(pickedDate.untilDate).add(1, "days").format("YYYY-MM-DD")
  //           : "",
  //         countries: countryFilter ? countryFilter : "",
  //         spotifyId: userSpotifyId,
  //         type,
  //       };

  //       const response = await getAirplays(
  //         queryParams,
  //         airplaysCancelToken.token
  //       );

  //       if (response?.data) {
  //         dispatch({
  //           type: REDUX_SET_RADIO_MONITORING_DATA,
  //           id: userSpotifyId,
  //           radioMonitoringData: response.data,
  //           target: type === "user" ? "user" : "history",
  //         });

  //         setPagination((prevPagination) => ({
  //           ...prevPagination,
  //           ...response.data.metadata,
  //         }));
  //       }
  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     if (!axios.isCancel(e)) setLoading(false);
  //   }
  // };

  const datePickCallback = useCallback((date, calendarId, databaseField) => {
    setPickedDate((prevPickedState) => ({
      ...prevPickedState,
      [calendarId]: date,
    }));
  }, []);

  const handleOpenCalendar = useCallback((className, forField, id) => {
    setCalendarState((prevCalendarState) => ({
      ...prevCalendarState,
      [id]: !prevCalendarState[id],
    }));
  }, []);

  // const handleOnPaginationChange = useCallback(({ pageIndex, size }) => {
  //   setPagination((prevPagination) => ({ ...prevPagination, pageIndex, size }));
  // }, []);

  const handleOnSortChange = useCallback((sortBy) => {
    if (sortBy) {
      setSortBy({
        id: sortBy?.id ?? "",
        desc: sortBy?.desc ? true : false,
      });
    }
  }, []);

  const handleCountryFilterChange = (
    value,
    dbField,
    commentField,
    databaseKey,
    type,
    valid
  ) => {
    if (type?.action !== "input-change") {
      setCountryFilter(value);
    }
  };

  const handleResetFilter = useCallback((filtername) => {
    switch (filtername) {
      case "country":
        setCountryFilter("");
        break;
      case "fromDate":
      case "untilDate":
        setPickedDate((prevPickedState) => ({
          ...prevPickedState,
          [filtername]: "",
        }));
        break;
      default:
        break;
    }
  }, []);

  const handleExportAirplays = () => {
    setDownloading(true);
    const queryParams = {
      spotifyId:
        reduxMainEntity?.service_profiles?.selectedSpotifyID ||
        reduxMainEntity?.id,
      type: reduxMainEntity?.type,
      fromDate: pickedDate?.fromDate,
      untilDate: pickedDate?.untilDate,
      countries: countryFilter?.map((c) => c.value)?.join(",") ?? "",
    };

    if (!queryParams.untilDate)
      queryParams.untilDate = moment().format("YYYY-MM-DD");

    // default fromDate is one day before untilDate. Basically a range of 1 day
    if (!queryParams.fromDate) {
      queryParams.fromDate = moment(queryParams.untilDate)
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }

    const fileName = `${reduxMainEntity?.name}_${Object.values(queryParams)
      .filter((val) => val)
      .slice(2)
      .join("_")}`;

    downloadAirplays(queryParams)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch((e) => {
        setDownloading(false);
      });
  };

  //sportifyartistmodal
  const closeModal = (fetch, service) => {
    if (service === SPOTIFY) {
      setSpotifyArtistModal(false);
    }
    dispatch(reduxSetUserData());
  };

  const data = React.useMemo(
    () => reduxMainEntity?.radioMonitoringData?.data,
    [reduxMainEntity?.radioMonitoringData?.data]
  );

  const aggregationData = React.useMemo(
    () => reduxMainEntity?.radioAggregationData,
    [reduxMainEntity?.radioAggregationData]
  );

  const appliedFilters = React.useMemo(
    () => ({
      fromDate: { label: "From date:", value: pickedDate?.fromDate },
      untilDate: { label: "Until date:", value: pickedDate?.untilDate },
      country: {
        label: "Country",
        value: countryFilter?.map((c) => c.value)?.join(",") ?? "",
      },
    }),
    [pickedDate?.fromDate, pickedDate?.untilDate, countryFilter]
  );

  const getTimeDiff = (date1, date2) => {
    const d1 = moment(date1);
    const d2 = moment(date2);
    return d2.diff(d1, "days");
  };

  return (
    <>
      <section className="dashboard-airplay-container overflow-auto">
        {["user", "artist", "track"].includes(reduxMainEntity?.type) ? (
          isSubscribedToAirplay ? (
            ["artist", "track"].includes(reduxMainEntity?.type) ||
            (reduxMainEntity?.type === "user" &&
              reduxMainEntity?.service_profiles?.selectedSpotifyID) ? (
              <>
                {/* Export CSV */}
                <div className="row justify-content-end align-items-center">
                  <ButtonComponent
                    disabled={downloading}
                    onClick={handleExportAirplays}
                    buttonWrapperClassName="pr-3"
                    className="playtreksButton w-100 h-75"
                  >
                    <FontAwesomeIcon
                      icon={faFileCsv}
                      style={{ opacity: "0.3" }}
                      className="mr-2"
                    />
                    {downloading ? (
                      <>
                        Downloading...
                        <span className="ml-2">
                          <Spinner />
                        </span>
                      </>
                    ) : (
                      "Export CSV"
                    )}
                  </ButtonComponent>
                </div>

                {/* Filters */}
                <div className="col p-0">
                  <div className="row text-white mx-0">Filter by:</div>
                  <div className="row">
                    <div className="col py-2 ">
                      <div className="ariplay-date-filter-wrapper position-relative">
                        <div
                          className={
                            "inputUpperComment iuc6 " +
                            (pickedDate["fromDate"] || "d-none")
                          }
                        >
                          From date
                        </div>
                        <InputComponent
                          disabled={true}
                          value={pickedDate["fromDate"]} // take from calendar, or pre-populate
                          paddingTopOnInput={true}
                          inputGroupClassName="nrInputGroup"
                          inputClassName={
                            "nrInput nrInput-opacityFix " +
                            (pickedDate["fromDate"] ||
                            pickedDate["fromDate"].length
                              ? "nrCalendarInputPopulated"
                              : "")
                          }
                          commentField=".iuc6"
                          placeholder="From date"
                          addonClickCallback={handleOpenCalendar}
                          for="nrReleaseDate"
                          id="fromDate"
                          // calendar addon
                          addon="right"
                          calendarAddon={true}
                          calImg={calendar}
                          calImgClass="nrAddonRightIcon"
                          calAlt="pick date"
                          inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                          databaseField="fromDate"
                        />
                        <CalendarComponent
                          calendarState={calendarState["fromDate"]}
                          calendarClassName="nrReleaseDateCalendar"
                          datePickCallback={datePickCallback}
                          calendarId="fromDate"
                          databaseField="fromDate"
                          maxDate={
                            pickedDate?.untilDate
                              ? new Date(pickedDate?.untilDate)
                              : ""
                          }
                          shouldCloseOnSelect={true}
                        />
                      </div>
                    </div>{" "}
                    <div className="col py-2">
                      <div className="ariplay-date-filter-wrapper position-relative">
                        <div
                          className={
                            "inputUpperComment iuc6 " +
                            (pickedDate["untilDate"] || "d-none")
                          }
                        >
                          Until date
                        </div>
                        <InputComponent
                          disabled={true}
                          value={pickedDate["untilDate"]} // take from calendar, or pre-populate
                          paddingTopOnInput={true}
                          inputGroupClassName="nrInputGroup"
                          inputClassName={
                            "nrInput nrInput-opacityFix " +
                            (pickedDate["untilDate"] ||
                            pickedDate["untilDate"].length
                              ? "nrCalendarInputPopulated"
                              : "")
                          }
                          commentField=".iuc6"
                          placeholder="Until date"
                          addonClickCallback={handleOpenCalendar}
                          for="nrReleaseDate"
                          id="untilDate"
                          // calendar addon
                          addon="right"
                          calendarAddon={true}
                          calImg={calendar}
                          calImgClass="nrAddonRightIcon"
                          calAlt="pick date"
                          inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                          databaseField="untilDate"
                        />
                        <CalendarComponent
                          calendarState={calendarState["untilDate"]}
                          calendarClassName="nrReleaseDateCalendar"
                          datePickCallback={datePickCallback}
                          calendarId="untilDate"
                          databaseField="untilDate"
                          minDate={
                            pickedDate?.fromDate
                              ? new Date(pickedDate?.fromDate)
                              : ""
                          }
                          shouldCloseOnSelect={true}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "nrContributorsWrapper nrContributorsWrapperExtended col py-2 mt-0 text-white"
                      }
                      id={"contributorsWrapper"}
                    >
                      <div className="inputUpperComment iuc3 playtreksSelector">
                        Country
                      </div>
                      <SelectComponent
                        value={countryFilter}
                        // shortInput={true}
                        isMulti
                        valuePaddingTop={true}
                        placeholderTop="50%"
                        placeholderFont="Inter-Medium"
                        placeholderColor="white"
                        placeholderOpacity="1"
                        options={countryFilterOptions}
                        customNoOptionsMessage={
                          <p classNam="ml-2">No options</p>
                        }
                        selectChangeCallback={handleCountryFilterChange} // to be used for data transfer
                        selectBlurCallback={() => {}} // leave empty
                      />
                    </div>
                  </div>
                  <div className="row">
                    <AppliedFilters
                      appliedFilters={appliedFilters}
                      onReset={handleResetFilter}
                    />
                  </div>
                </div>

                <div className="dashboard-airplay position-relative">
                  <div className="text-white">
                    <b>Streams by cities and countries</b>
                  </div>
                  {getTimeDiff(pickedDate.fromDate, pickedDate.untilDate) <=
                  7 ? (
                    <AirplayAggregationMap
                      data={aggregationData || {}}
                      loading={aggregationsLoading}
                    />
                  ) : (
                    <div
                      style={{ height: "9.375rem" }}
                      className="w-100 d-flex justify-content-center align-items-center text-white"
                    >
                      Max time window exceeded. Only available for a maximum
                      time span of 7 days
                    </div>
                  )}
                </div>

                <div className="dashboard-airplay">
                  <div className="text-white">
                    <b>Top 25 places by streams</b>
                  </div>
                  {getTimeDiff(pickedDate.fromDate, pickedDate.untilDate) <=
                  7 ? (
                    <AirplayTopPlacesAgregationChart
                      data={aggregationData || {}}
                      loading={aggregationsLoading}
                    />
                  ) : (
                    <div
                      style={{ height: "9.375rem" }}
                      className="w-100 d-flex justify-content-center align-items-center text-white"
                    >
                      Max time window exceeded. Only available for a maximum
                      time span of 7 days
                    </div>
                  )}
                </div>

                <div
                  className="dashboard-airplay py-0"
                  style={{ maxHeight: "40rem", overflowY: "auto" }}
                >
                  <RadioMonitoringTable
                    columns={columns}
                    data={airplayData || []}
                    pagination={pagination}
                    onSort={handleOnSortChange}
                    // onPaginationChange={handleOnPaginationChange}
                    loading={isLoading}
                    loadMore={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                    hasNextPage={hasNextPage}
                  />
                </div>
              </>
            ) : (
              <>
                <AddSpotifyArtistModal
                  closeModalCallback={closeModal}
                  className={!spotifyArtistModal ? "d-none" : ""}
                />
                <div className="dashboard-playlists-top-empty">
                  <div>
                    Spotify artist page not connected
                    <br />
                    <div
                      className="social-card-not-connected"
                      onClick={() => setSpotifyArtistModal(true)}
                    >
                      Pick the artist page
                    </div>
                  </div>
                </div>
              </>
            )
          ) : (
            isSubscribedToAirplay !== undefined && (
              <div className="dashboard-playlists-top-empty">
                This is a premium feature.
                <Link to="/artistDashboard/subscriptions">
                  <div className="social-card-not-connected">
                    Please subscribe to view
                  </div>
                </Link>
              </div>
            )
          )
        ) : (
          reduxMainEntity?.type && (
            <div className="row justify-content-center align-items-center py-5">
              <p className="text-white">
                Available only for User, Artist and Track
              </p>
            </div>
          )
        )}
      </section>
    </>
  );
});

export default Airplay;
