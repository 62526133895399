import React from "react";
import StatsUpIcon from "../../assets/icons/icon-stats-up.svg";
import StatsDownIcon from "../../assets/icons/icon-stats-down.svg";
import { numberWithCommas, PLATFORM } from "../utils";

import plusCircle from "../../assets/icons/plus_circle.svg";
import StatsCardIcon from "./StatsCardIcon";

function StatsCard({
  name,
  platform,
  children,
  objectPath,
  objectPathDelta,
  className,
  handleClick,
  comingSoon,
  connected,
  pageConnected,
  handlePlusIconClick,
}) {
  objectPathDelta = objectPathDelta == undefined ? "N/A" : objectPathDelta;
  objectPath = objectPath == undefined ? "N/A" : objectPath;
  objectPath = numberWithCommas(objectPath);
  const statIcon = objectPathDelta > 0 ? StatsUpIcon : StatsDownIcon;
  const stats = `${numberWithCommas(objectPathDelta)} last week`;
  const statClass = objectPathDelta > 0 ? "social-up" : "social-down";

  const hideIfNoDataClass =
    objectPath === "N/A" &&
    !comingSoon &&
    connected !== false &&
    pageConnected !== false
      ? "d-none"
      : "";

  return (
    <div
      className={`social-card ${className} ${hideIfNoDataClass}`}
      onClick={handleClick}
    >
      <div className="social-card-name">
        <label>{name}</label>
        {connected !== false ? (
          children
        ) : (
          <StatsCardIcon
            name="connect platform"
            icon={plusCircle}
            handleClick={() => handlePlusIconClick(platform)}
          />
        )}
      </div>

      <span
        className="social-card-follower mt-1"
        style={objectPathDelta === "N/A" ? { fontSize: "25px" } : {}}
      >
        {!comingSoon && objectPath !== "N/A" && objectPath}
      </span>
      <span className="social-card-stat">
        {!comingSoon ? (
          objectPathDelta !== "N/A" && (
            <div>
              <img src={statIcon} alt="" />
              <label className={`social-card-stat-label ${statClass}`}>
                {stats}
              </label>
            </div>
          )
        ) : (
          <div className="social-card-stat-comingSoon mt-3">coming soon</div>
        )}
      </span>
      {connected === false && (
        <div
          className="social-card-not-connected"
          onClick={() => handlePlusIconClick(platform)}
        >
          please connect
        </div>
      )}
      {pageConnected === false && connected === true && (
        <div
          className="social-card-not-connected"
          onClick={() => handlePlusIconClick(platform)}
        >
          pick page
        </div>
      )}
    </div>
  );
}
export default StatsCard;
