import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";

import AdminMenu from "./AdminMenu";
import ReleasesAdmin from "./ReleasesAdmin";
import RoyaltiesAdmin from "./RoyaltiesAdmin";
import TransactionsAdmin from "./TransactionsAdmin";
import UsersAdmin from "./UsersAdmin";
import ReleaseDetailsAdmin from "../../screens/Admin/Releases/ReleaseDetailsAdmin";
import AccountBalancesAdmin from "./AccountBalancesAdmin";
import AccountingPeriodsAdmin from "./AccountingPeriodsAdmin";
import UserBalanceAdmin from "./UserBalanceAdmin";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Admin() {
  let match = useRouteMatch();
  let query = useQuery();

  return (
    <div>
      <AdminMenu />
      <Switch>
        <Route path={`${match.path}/`} exact>
          <ReleasesAdmin />
        </Route>
        <Route path={`${match.path}/users`}>
          <UsersAdmin />
        </Route>
        <Route path={`${match.path}/revenue`}>
          <RoyaltiesAdmin />
        </Route>
        <Route path={`${match.path}/payout_requests`}>
          <TransactionsAdmin />
        </Route>
        <Route path={`${match.path}/earnings`}>
          <AccountBalancesAdmin />
        </Route>
        <Route path={`${match.path}/accounting`}>
          <AccountingPeriodsAdmin />
        </Route>
        <Route path={`${match.path}/userbalance`}>
          <UserBalanceAdmin />
        </Route>
        <Route
          path={`${match.path}/releases/:releaseId`}
          component={ReleaseDetailsAdmin}
        />
      </Switch>
    </div>
  );
}

export default Admin;
