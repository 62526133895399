import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Performance from "../graphs/Performance";
import {
  trackParse,
  getColorScheme,
  PLATFORM,
  parseAppleTrack,
  setPath,
} from "../utils.js";
import { artistsJoined } from "../utilsJSX";
import spotifyLogo from "../../assets/icons/spotify_logo.png";
import appleIcon from "../../assets/icons/apple-color.svg";
import PlaylistPerformanceLineChart from "../HighCharts/PlaylistPerformanceLineChart";
import ReactTooltip from "react-tooltip";
import "./content.scss";

const colorScheme = getColorScheme();

const albumTooltip = (trackObj, platform) =>
  (trackObj.album.upc ? "UPC code: " + trackObj.album.upc + "<br/>" : "") +
  (getCopyright(trackObj.album?.copyrights, "C", platform)
    ? getCopyright(trackObj.album.copyrights, "C", platform)
    : "") +
  (getCopyright(trackObj.album?.copyrights, "P", platform)
    ? getCopyright(trackObj.album.copyrights, "P", platform)
    : "");

const getCopyright = (copyrightData, type, platform) => {
  if (platform === PLATFORM.SPOTIFY) {
    const text = copyrightData?.find(
      (c) => c.type === type.toUpperCase()
    )?.text;

    if (!text) return undefined;

    switch (type) {
      case "C":
        if (text?.includes("©")) return text.replace(/©/g, "<br/>©");
        else return "<br/>© " + text;
      case "P":
        if (text?.includes("℗")) return text.replace(/℗/g, "<br/>℗");
        else return "<br/>℗ " + text;
    }
  } else if (platform === PLATFORM.APPLE) {
    let text = copyrightData;
    switch (type) {
      case "C":
        if (text?.includes("©")) text = text.replace(/©/g, "<br/>©");
        else text = undefined;
        return text;
      case "P":
        if (text?.includes("℗")) text = text.replace(/℗/g, "<br/>℗");
        else text = undefined;
        return text;
    }
  }
};

const trackTooltip = (trackObj) =>
  trackObj?.track?.isrc ? "ISRC code: " + trackObj.track.isrc + "<br/>" : "";

export function ItemTrackList({ trackListObj, platform, containerStyle = {} }) {
  return (
    <div style={containerStyle}>
      {trackListObj.map((trackObj, index) => (
        <span key={index}>
          <div className="playlistsContainer">
            <div className="playlistsContainer-title">
              <div
                className="playlistsContainer-colorCircle"
                style={{
                  backgroundColor: colorScheme[trackObj.index],
                }}
              ></div>
              <span className="playlistsContainer-trackSeen ml-2">
                {trackObj.last_seen_pos + "."}&nbsp;
              </span>
            </div>
            <div className="playlistsContainer-trackName">
              {trackObj?.track?.id ? (
                <Link
                  className="textLink"
                  key={trackObj}
                  to={setPath(trackObj.track.id, trackObj.track.name, "track")}
                >
                  <span
                    data-tip={trackTooltip(trackObj)}
                    data-for="playlistsContainer-tooltip"
                  >
                    {trackObj.name}
                  </span>
                </Link>
              ) : (
                <span
                  data-tip={trackTooltip(trackObj)}
                  data-for="playlistsContainer-tooltip"
                  className="text"
                >
                  {trackObj?.track?.name}
                </span>
              )}
            </div>
            <div className="playlistsContainer-title">Album:&nbsp;</div>
            <div className="playlistsContainer-value">
              {trackObj?.album?.id ? (
                <Link
                  className="textLink"
                  key={index}
                  to={setPath(trackObj?.album.id, trackObj.album.name, "album")}
                >
                  <span
                    data-tip={albumTooltip(trackObj, platform)}
                    data-for="playlistsContainer-tooltip"
                  >
                    {trackObj?.album?.name}
                  </span>
                </Link>
              ) : (
                <span
                  data-tip={albumTooltip(trackObj, platform)}
                  data-for="playlistsContainer-tooltip"
                  className="text"
                >
                  {trackObj?.album?.name}
                </span>
              )}
            </div>
            {/* conditional is done twice because of the grid, can't wrap them in one div */}
            {trackObj?.album?.label && (
              <div className="playlistsContainer-title">
                <span>Label:&nbsp;</span>
              </div>
            )}
            {trackObj?.album?.label && (
              <div className="playlistsContainer-value">
                <Link
                  className="textLink"
                  key={index}
                  to={setPath(
                    trackObj?.album?.label,
                    trackObj?.album?.label,
                    "label"
                  )}
                >
                  <span>{trackObj?.album?.label}</span>
                </Link>
              </div>
            )}
            <div className="playlistsContainer-title">
              Artist{trackObj.artists.length > 1 && "s"}:&nbsp;
            </div>
            <div className="playlistsContainer-value">
              {trackObj.artists && trackObj.artists.length > 0 ? (
                artistsJoined(trackObj.artists)
              ) : (
                <span className="text">{trackObj?.artist_name}</span>
              )}
            </div>
          </div>
          <br />
          <br />
          <ReactTooltip
            // place="top"
            backgroundColor="#1d2025"
            className="ptTooltip"
            border
            borderColor="#0ef1db"
            effect="float"
            multiline={true}
            id="playlistsContainer-tooltip"
          />
        </span>
      ))}
    </div>
  );
}

function Item(props) {
  const platform = props.platform || PLATFORM.SPOTIFY;
  const data = props.entityData;
  const type = data.type;
  const playlist = props.playlist;
  const hits =
    platform === PLATFORM.SPOTIFY
      ? playlist.inner_hits && playlist.inner_hits["tracks.items"].hits.hits
      : playlist.inner_hits &&
        playlist.inner_hits["relationships.tracks.data"].hits.hits;

  if (typeof data.artists == "object") {
    // data type album / track
    var artists = data.artists;
  } else {
    if (data.type === "artist") {
      // data type artist
      artists = data.name;
    }
  }

  var trackListObj =
    platform === PLATFORM.SPOTIFY
      ? trackParse(hits, data, artists)
      : parseAppleTrack(hits, data, artists);

  function getTracks() {
    if (platform === PLATFORM.SPOTIFY) {
      return playlist._source.tracks.total;
    }
    return playlist._source.attributes.trackCount;
  }

  function getFollowersText() {
    if (platform === PLATFORM.SPOTIFY) {
      return `${playlist._source.followers.total} followers`;
    }
    return "";
  }

  function getOwnerName() {
    if (platform === PLATFORM.SPOTIFY) {
      return playlist._source.owner.display_name;
    }
    return playlist._source.attributes.curatorName;
  }

  if (data.name !== "" || type !== "") {
    // TODO: This should be inside an iteration over the inner hits. Then we have
    // one leftPane + performance for each inner hit
    const innerHits =
      platform === PLATFORM.SPOTIFY
        ? playlist.inner_hits["tracks.items"].hits.hits
        : playlist.inner_hits["relationships.tracks.data"].hits.hits;
    const firstInnerHit =
      platform === PLATFORM.SPOTIFY
        ? innerHits[0]._source.track
        : innerHits[0]._source.attributes;
    const totalTracks =
      platform === PLATFORM.SPOTIFY ? playlist._source.tracks.total : 40;

    function PlaylistInfo() {
      let playlistImageThumbnail = "";
      if (platform === PLATFORM.SPOTIFY) {
        if (playlist?._source.images?.[0]) {
          // TODO: remove once all data is cleaned
          playlistImageThumbnail = playlist?._source?.images?.[0]?.url;
        }
      } else if (platform === PLATFORM.APPLE) {
        playlistImageThumbnail = playlist._source.attributes.artwork.url;
        playlistImageThumbnail = playlistImageThumbnail.replace(/{w}/, 90);
        playlistImageThumbnail = playlistImageThumbnail.replace(/{h}/, 90);
      }

      const playlistHref =
        platform === PLATFORM.SPOTIFY
          ? `https://open.spotify.com/playlist/${playlist._source.id}`
          : playlist._source.attributes.url;
      // giving unique css classes to elements for hotjar to work

      var playlistImageWrap = `playlistImageWrap piw${props.index} d-inline`;
      var playlistImageThumb = `d-inline-block playlistImage pi${props.index}`;
      var playlistName = `playlistName pn${props.index} industryFont colorPrimary`;
      var tracksFollowersDiv = `tracksFollowersDiv tfd${props.index} small`;
      var tracksSpan = `tracksSpan ts${props.index} colorPrimary`;
      var followersSpan = `followersSpan fs${props.index} colorPrimary`;
      var playlistDescription = `colorSecondary d-inline playlistDescription pd${props.index}`;
      var spotifyLogoClass = `spotifyLogo slc${props.index}`;

      return (
        <div className="row container">
          {playlistImageThumbnail !== "" ? (
            <div className={playlistImageWrap}>
              <div>
                <img
                  src={playlistImageThumbnail}
                  alt="Playlist Thumbnail"
                  className={playlistImageThumb}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={playlistDescription}>
            <span className={playlistName}>
              {playlist?._source?.name || playlist?._source?.attributes?.name}
            </span>
            <div className={tracksFollowersDiv}>
              <span className={tracksSpan}>{getTracks()} tracks</span>
              {getFollowersText() ? <span>&nbsp;|&nbsp;</span> : ""}
              <span className={followersSpan}>{getFollowersText()}</span>
              <br />
              Created by {getOwnerName()}
            </div>
            <a href={playlistHref} target="_blank" rel="noopener noreferrer">
              {platform === PLATFORM.SPOTIFY ? (
                <div className={spotifyLogoClass}>
                  <img src={spotifyLogo} alt="Spotify Logo" />
                </div>
              ) : (
                <div>
                  <img src={appleIcon} alt="Apple Logo" />
                  <label className="icon-label">Apple</label>
                </div>
              )}
            </a>
          </div>
        </div>
      );
    }

    const LeftPane = () => (
      <span>
        <br />
        <div className="small">
          <ItemTrackList trackListObj={trackListObj} platform={platform} />
        </div>
      </span>
    );

    return (
      <span key={props.key}>
        <Paper elevation={3}>
          <span
            className={`row item colorSecondary itemWrapper iw${props.index}`}
          >
            <div
              className={`playlistInfoWrapper piw${props.index} col-xl-4 col-lg-4 col-md-4 m-2 p-3 leftPane`}
            >
              <PlaylistInfo />
              <LeftPane />
            </div>

            <div
              className={`gbw${props.index} col-md col-xl col-lg m-md-2 m-lg-2 m-xl-2 rightPane`}
            >
              {/* <div className={graphLabel}>POSITION IN PLAYLIST OVER TIME</div> */}
              <div className={`graphWrapper gw${props.index}`}>
                {/* <Performance
                  data={innerHits}
                  totalTracks={totalTracks}
                  platform={platform}
                /> */}
                <PlaylistPerformanceLineChart
                  data={innerHits}
                  totalTracks={totalTracks}
                  platform={platform}
                />
              </div>
            </div>
          </span>
        </Paper>
      </span>
    );
  } else {
    return <div />;
  }
}

export default Item;
