import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { reduxSetUserData } from "../../../redux/actions/dashboardActions";

import {
  getConnectedAccounts,
  disconnectPlatform,
} from "../../../routes/users";
import { getJWT } from "../../utils";
import { handleExternalLogin } from "../login/ExternalLogin";

function ConnectBox(props) {
  const dispatch = useDispatch();
  const jwtToken = getJWT();

  const [connected, setConnected] = useState(false);
  const [image, setImage] = useState(undefined);
  const [username, setUsername] = useState(undefined);

  const service = props.service || props.serviceText.toLowerCase();

  function handleClick() {
    window.localStorage.setItem(
      "locationBeforeExternalLogin",
      window.location.pathname
    );
    handleExternalLogin(service);
  }

  const fetchConnectedAccounts = async () => {
    try {
      var r = await getConnectedAccounts(service, getJWT());

      setConnected(r?.data?.[service]?.connected);
      setImage(r?.data?.[service]?.image);
      setUsername(r?.data?.[service]?.username);
      props.errorCallback(false);
    } catch (err) {
      props.errorCallback(true);
    }
  };

  useEffect(() => {
    fetchConnectedAccounts();
  }, [service]);

  function handleDisconnect() {
    disconnectPlatform(jwtToken, service)
      .then((r) => {
        fetchConnectedAccounts();
      })
      .finally(() => dispatch(reduxSetUserData()));
  }

  return (
    <div className="obConnectBox mx-auto">
      <div className="connectOval">
        {!connected ? (
          <img
            src={props.img}
            style={props.serviceImgStyle}
            alt={props.serviceText}
          ></img>
        ) : (
          <img
            src={image || props.img}
            style={{
              width: "115%",
              minHeight: "115%",
              top: "-3px",
              left: "-3px",
            }}
            alt={props.serviceText}
          ></img>
        )}
      </div>
      <span className="obConnectServiceText">{props.serviceText}</span>
      <br />
      {!connected ? (
        <span className="obConnectUsernameText">
          {!props.comingSoon ? "not connected" : ""}
        </span>
      ) : (
        <span className="obConnectUsernameText">{username}</span>
      )}
      <br />
      {!props.comingSoon ? (
        <>
          {!connected ? (
            <div className="obConnectLink" onClick={handleClick}>
              Connect now
            </div>
          ) : (
            <div className="obDisconnectLink" onClick={handleDisconnect}>
              Disconnect
            </div>
          )}{" "}
        </>
      ) : (
        <div
          className="obDisconnectLink"
          style={{
            marginLeft: "7px",
            cursor: "default",
            textDecoration: "none",
          }}
        >
          <b>Coming soon</b>
        </div>
      )}
    </div>
  );
}

export default ConnectBox;
