import React from "react";
import { Link } from "react-router-dom";

function Box1({ boxPic, BoxText, BoxTag, BoxActions, href }) {
  const Actions = BoxActions ? BoxActions : () => null;

  const Navigate = ({ children }) =>
    href?.indexOf("https://") > -1 ? (
      <a href={href}>{children}</a>
    ) : (
      <Link to={href ? href : ""}>{children}</Link>
    );

  return (
    <Navigate>
      <div
        className="wpBox"
        style={
          href
            ? { background: `url(${boxPic}) 0 0 no-repeat` }
            : {
                background: `url(${boxPic}) 0 0 no-repeat`,
                pointerEvents: "none",
                userSelect: "none",
                opacity: 0.7,
              }
        }
      >
        {BoxTag ? <BoxTag /> : null}
        <div className="wpBox-content-wrapper">
          <div className="wpBox-content">
            <BoxText />
          </div>
          <Actions />
        </div>
      </div>
    </Navigate>
  );
}

export default Box1;
