import React from "react";
import { useSelector } from "react-redux";
import Card from "../../argon/Card";
import { isEqual } from "lodash";
import ReleasePlaceholder from "../../../assets/releasePlaceholder.png";
const BASIC_INFO_PAGE = 1;

// For the bc-demo version please check out
// https://github.com/Playtreks/Dashboard/blob/d3717636bb00b836a6ddce123efb9fc9c6b5f0df/front/src/components/distribution/components/ReleaseCardComponent.js#L12

function ReactCardComponent(props) {
  const { state, release, disableCoverArt } = props;
  const tracksCount = release?.tracks?.length;
  const parsedImgUrl = release["#cover_url"]?.replace("#", "%23");

  const user = useSelector(
    ({ dashboardReducer }) => dashboardReducer?.userData,
    isEqual
  );

  const isOwner = user.email === release.owner?.email;

  function handleContinue(toPage = BASIC_INFO_PAGE) {
    window.localStorage.setItem("currentReleaseId", props.releaseId);
    setTimeout(() => {
      window.location.assign("/distribution/newRelease/" + toPage);
    }, 0);
  }

  var releaseType;
  if (release?.tracks?.length >= 1 && release?.tracks?.length <= 3) {
    releaseType = "Single";
  } else if (release?.tracks?.length >= 4 && release?.tracks?.length <= 6) {
    releaseType = "EP";
  } else {
    releaseType = "Album";
  }

  return (
    <Card className={props.className + " releaseCardComponent"}>
      {!disableCoverArt && (
        <div className="mw-100 overflow-hidden">
          <img
            src={
              parsedImgUrl === "" || parsedImgUrl === "initial"
                ? ReleasePlaceholder
                : parsedImgUrl
            }
            alt="rcImage"
            className="rcImage"
          />
        </div>
      )}
      <div className="rcName">
        {release["#title"]}
        {release["#remix_or_version"]
          ? ` (${release["#remix_or_version"]})`
          : ""}
      </div>

      <div className="rcArtist">
        <span className="rcKey">
          {release?.["#participants"] ? "Artist(s): " : ""}
        </span>
        <span className="rcValue">
          {release?.["#participants"]
            .filter((participant) => participant.primary)
            .map((participant, index, arr) => (
              <span key={index}>
                {participant.primary}
                {index !== arr.length - 1 ? ", " : ""}
              </span>
            ))}
        </span>
      </div>

      <div className="rcGenre">
        {release["#primary_genre"]}
        {release["#secondary_genre"] ? ", " + release["#secondary_genre"] : ""}
      </div>

      <div className="rcLabel">
        <span className="rcKey">{release["#label"] ? "Label: " : ""} </span>
        <span className="rcValue"> {release["#label"]}</span>
      </div>

      <div className="rcLabel">
        <span className="rcKey">
          {release["#catalog_number"] ? "Catalog number: " : ""}{" "}
        </span>
        <span className="rcValue"> {release["#catalog_number"]}</span>
      </div>

      <div className="rcUpc">
        <span className="rcKey">{"UPC: "} </span>

        {release["upc"] ? (
          <span className="rcValue">{release["upc"]}</span>
        ) : (
          <span className="rcValue">
            {release["#upc"] ? release["#upc"] : "auto"}
          </span>
        )}
      </div>
      <div className="rcHr"></div>
      <div className="rcType">
        {/* TODO: where does the type come from? */}
        <span className="rcKey">{release["#type"] || releaseType + ", "}</span>
        <span className="rcValue">
          {state !== "draft - uploadTracks"
            ? "" + tracksCount + " track"
            : "no tracks"}
          {tracksCount > 1 && tracksCount !== 0 ? "s" : ""}
        </span>
      </div>
      {tracksCount > 0 ? (
        <div className="rcDate">
          <span className="rcKey">Release date:</span>
          <span className="rcValue"> {release["#digital_release"]}</span>
        </div>
      ) : (
        <div className="rcDate">
          <div className="rcKey">No uploaded Tracks</div>
        </div>
      )}
      {state === "succeeded" ? (
        <div className="releaseSuccessCard">
          {/* <div className="rcCircle rcc1"></div>
            <div className="rcCircle rcc2"></div>
            <div className="rcCircle rcc3"></div>
            <div className="rcCircle rcc4"></div>
            <div className="rcCircle rcc5">+35</div>
            <div className="rcCircle rcc6">+</div> */}
          <span alt="success" aria-label="success">
            ✅
          </span>
          Release successful!
        </div>
      ) : (
        ""
      )}
      {state === 2 ? <div className="releaseButton">Release</div> : ""}
      {state === "qc_failed" ? (
        <div className="releaseQuality">
          <div className="rqIcon">
            <span role="img" aria-label="redLight">
              🚨
            </span>
          </div>
          <div className="rqTopRed">Release didn't pass quality check</div>
          <div className="rqBottom">Check your email for more information</div>
        </div>
      ) : (
        ""
      )}
      {state === "failed" ? (
        <div className="releaseQuality">
          <div className="rqIcon">
            <span role="img" aria-label="redLight">
              🚨
            </span>
          </div>
          <div className="rqTopRed">There is a problem with your release</div>
          <div className="rqBottom">Check your email for more information</div>
        </div>
      ) : (
        ""
      )}
      {state === "draft - uploadTracks" ? (
        <div
          className="uploadTracksButton"
          onClick={(e) => {
            e.stopPropagation();
            handleContinue(BASIC_INFO_PAGE);
          }}
        >
          Upload tracks
        </div>
      ) : (
        ""
      )}
      {state === "draft" && isOwner ? (
        <div>
          <div
            className="uploadTracksButton"
            onClick={(e) => {
              e.stopPropagation();
              handleContinue(BASIC_INFO_PAGE);
            }}
          >
            Finish the release
          </div>
        </div>
      ) : (
        ""
      )}

      {state === "submitted_to_qc" ? (
        <div className="releaseQuality">
          <div className="rqIcon">
            <span role="img" aria-label="magnifyingGlass">
              🔍
            </span>
          </div>
          <div className="rqTop">Quality control in progress</div>
          <div className="rqBottom">This usually takes 7-10 working days</div>
        </div>
      ) : (
        ""
      )}
      {state === "submitted" ? (
        <div className="releaseQuality">
          <div className="rqIcon">
            <span role="img" aria-label="rocket">
              🚀
            </span>
          </div>
          <div className="rqTop">Release in progress</div>
          <div className="rqBottom">
            Started on {release["releaseApplicationDate"]}
          </div>
        </div>
      ) : (
        ""
      )}
      {state === "taken_down" ? (
        <div className="releaseQuality">
          <div className="rqIcon">
            <span role="img" aria-label="dead">
              😵
            </span>
          </div>
          <div className="rqTop">Taken down</div>
        </div>
      ) : (
        ""
      )}
    </Card>
  );
}

export default ReactCardComponent;
