import React, { useEffect, useState } from "react";
import X from "../../components/distribution/assets/X.svg";
import ButtonComponent from "../form/ButtonComponent";
import InputComponent from "../form/InputComponent";
import "./PhoneVerificationModal.scss";
import ValidationTextBox from "../form/ValidationTextBox";

const retryWaitTimeByAttempt = {
  1: 60,
  2: 120,
  3: 180,
  4: 240,
  5: 300,
};
const maxAttempts = 5;

const PhoneVerificationModal = ({
  onCloseRequest,
  onSendOTP,
  onVerifyOTP,
  values,
  handleSubmit,
  setSubmitting,
}) => {
  const { phone = "" } = values;
  const [touched, setTouched] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [counter, setCounter] = useState(0);
  const [otp, setOTP] = useState("");
  const [otpVerificationError, setOTPVerificationError] = useState("");
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => setCounter(counter - 1), 1000);
    }
  }, [counter]);

  useEffect(() => {
    setCounter(retryWaitTimeByAttempt[attempts]);
  }, [attempts]);

  // verify phone handle input
  const handleOTPChange = (value) => {
    setOTP(value);
    if (touched) validateOTP();
  };

  const handleOTPBlur = () => {
    validateOTP();
    setTouched(true);
  };

  //verify phone validation
  const validateOTP = () => {
    let isError = false;
    if (!Number(otp)) {
      isError = true;
      setOTPVerificationError("Invalid code");
    } else if (otp?.length !== 8) {
      isError = true;
      setOTPVerificationError("Should be of length 6");
    } else {
      isError = false;
      setOTPVerificationError("");
    }
    return isError;
  };

  const handleContinue = async () => {
    const res = await onSendOTP(phone);
    if (res.success) {
      setPage((next) => next + 1);
      setAttempts(attempts + 1);
    }
  };

  const handleVerifyOTP = async () => {
    const verified = await onVerifyOTP(otp, phone);

    if (verified) {
      handleSubmit(values, { setSubmitting }, true);
    }
  };

  const handleResendOTP = () => {
    setAttempts(attempts + 1);
    onSendOTP(phone);
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />

        {page === 0 ? (
          <>
            <h2 className="mb-4 text-white">Phone verification required</h2>

            <div className="mb-4 text-muted">
              <p className="infoText">
                We need to verify your phone number before updating it.
                <br /> <br />
                Please confirm if <b className="text-white">{phone}</b> is your
                phone number. You will receive a verification code on this
                number.
              </p>
            </div>

            <div className=" row justify-content-end ml-0 mt-4">
              <ButtonComponent
                className="TwoFAButton bg-transparent textLink mr-2"
                onClick={onCloseRequest}
              >
                Change
              </ButtonComponent>
              <ButtonComponent
                className="TwoFAButton "
                onClick={handleContinue}
              >
                Confirm
              </ButtonComponent>
            </div>
          </>
        ) : (
          <>
            <div className="mt-4">
              <div>Verify mobile number</div>
            </div>
            <div className="mt-4 infoText text-muted">
              Enter the 6-digit code that sent to your mobile number.
            </div>
            <div className="mt-4">
              <div className="inputUpperComment iuc1a  d-none">Code</div>
              <InputComponent
                addon={false}
                alt="2fa code"
                value={otp}
                commentField=".iuc1a "
                paddingTopOnInput={true}
                placeholder="Enter your verification code"
                inputGroupTextClassName="inputGroupText"
                inputClassName="TwoFAInput"
                inputGroupClassName="nrInputGroup nrInputGroup-title"
                changeCallback={handleOTPChange}
                inputType="number"
                databaseField="otp"
                maxlength={8}
                inputBlurCallback={handleOTPBlur}
              />

              <div className="row mx-0 justify-content-between">
                <div>
                  <ValidationTextBox
                    className="loginValidationBox position-static ml-2"
                    display={otpVerificationError?.length}
                  >
                    {otpVerificationError}
                  </ValidationTextBox>
                </div>
                <ButtonComponent
                  className={`TwoFAButton text-muted bg-transparent fs-12 pr-0 ${
                    counter > 0 ? "" : "textLink"
                  }`}
                  onClick={
                    counter > 0
                      ? () => {}
                      : attempts <= maxAttempts
                      ? handleResendOTP
                      : () => {}
                  }
                  disabled={counter > 0}
                >
                  {counter > 0
                    ? `Try again in ${counter} seconds`
                    : attempts <= maxAttempts
                    ? "Didn't receive? Resend code"
                    : ""}
                </ButtonComponent>
              </div>
            </div>
            <div className="row justify-content-end ml-0 mt-4">
              <ButtonComponent
                className=" TwoFAButton"
                onClick={handleVerifyOTP}
              >
                Verify
              </ButtonComponent>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PhoneVerificationModal;
