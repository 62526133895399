import React, { useEffect, useState } from "react";
// import Sidebar from "../artistDashboard/sidebar";
import Sidebar from "../../../artistDashboard/sidebar";
import MobileMenuBurger, {
  MobileMenu,
} from "../../../artistDashboard/MobileMenu/MobileMenu";

export default function DistributionCoverArtRequirements() {
  return (
    <div>
      <div
        style={{ overflowX: "none !important", backgroundColor: "#343a47" }}
        className="ptTermsContent welcomePage"
      >
        <br />
        <h1 style={{ color: "inherit" }}>Cover Art Requirements</h1>
        <br />
        <b>Quality and format</b>
        <br />
        <br />
        <ol>
          <li>
            Quality of images. The cover art must not be any of the following:
            blurry, pixelated, mismatched, misaligned, rotated, incorrect,
            stretched, or have other quality issues.
          </li>
          <br />
          The information shown in the cover image should always match the
          information of the metadata as accurately as possible and without any
          class of abbreviation. <br /> 
          <li>
            Dimensions of the cover art. The cover art of all releases must
            accomplish with all following requirements: Proportion: Exactly
            squared Minimum size: 3000 x 3000 px. Maximum size: 5000 x 5000 px.
            Accepted formats: JPG, TIFF or PNG Mode: RGB (CMYK is not available)
            Maximum size of file: 36 MB
          </li>
        </ol>{" "}
        The images which do not accomplish all the requirements above will not
        be supported and may not be shown in the channels. <br />
        <br />
        <b>Must not contain</b>
        <br />
        <br />
        <ol>
          <li>
            Links and web pages. The cover art can not contain website
            addresses, websites that sell music, mentions to social media, logos
            of any stores or services related to entertainment, mentions to
            physical formats, video formats or any external reference of the
            digital release.
          </li>{" "}
          <li>
            {" "}
             Telephone or email addresses. The cover art cannot contain neither
            telephone numbers nor email addresses.{" "}
          </li>
          <li>
             Credits and collaborations. The cover art can include credits or
            other artists’ names, who are not the primary artists of the release
            as long as they are justified in the metadata.
          </li>
          <li>
            {" "}
            Additional Information: The cover art cannot include any text that
            isn’t justified on the metadata. The information in the cover will
            have to reproduce with extreme precision and without abbreviations
            the information of the metadata.{" "}
          </li>
          <li>
            Tracklisting and illegible texts. The cover art can not contain the
            track listing. It is also recommended to use legible fonts in the
            cover, as the image will be visualized in small formats and it won't
            be able to appreciate small texts. Texts that are not legible will
            not be accepted.
          </li>
          <li>
            {" "}
             Descriptions and biographies. The cover art must not include album
            descriptions or artists biographies.
          </li>
          <li>
            {" "}
             Digital, physical and video format. The cover art can not include
            references to it being a digital or physical product (such as
            “Online”, “CD”, “Compact Disc”, etc.). Mentions to the video format
            are neither allowed.
          </li>
          <li>
            {" "}
            Also the cover art must not contain references to contents that are
            missing in the album, such as “Includes DVD” or “Includes Lyrics”.
            Similar expressions like “All Rights Reserved”, “Registered
            Product”, “Under Copyright”, etc., can not be used. Not even other
            redundant or unnecessary information.
          </li>
          <li>
            {" "}
            UPC or any of the track’s ISRC: The release reference number can
            appear in the cover.
          </li>
          <li>
            {" "}
             Pricing. The cover art can not include references to the pricing,
            or any information with promotional purposes.{" "}
          </li>
          <li>
            {" "}
            Pornography and violent contents. The cover art can not include
            contents that may be racist, pornographic, or glorify or trivialize
            violence.{" "}
          </li>
          <li>
            {" "}
            Offensive symbolism. The cover art must not contain any kind of
            symbolism that offends a specific group of people or ethnicities,
            such as Nazi symbolism, restricted by the Strafgesetzbuch section
            86a.
          </li>
          <li>
            {" "}
             Misleading information. The cover art must not be misleading. For
            example, prominently depicting or referencing an artist even though
            the artist does not perform on the album.
          </li>
          <li>
            {" "}
            Translations and use of special characters. The use of
            non-occidental or special characters (like Arabic characters,
            Chinese characters or Greek letters) must be avoided unless they
            guard an explicit relation with the content. Side-by-side
            translations or transliterations of the content are not allowed. The
            information must be written following the same structure and
            alphabet as in the metadata.{" "}
          </li>
          <li>
            {" "}
             Logos, images and registered brands. All the logos (including the
            involved texts) must be justified in the metadata. The logos can be
            related to the artists, producers, labels or other information
            involved with the musical product.{" "}
            <ul>
              <li>
                {" "}
                Designers, photographers and other mentions to the cover artists
                are not allowed.
              </li>
              <li>
                {" "}
                Registered brands and private or personal images (from people or
                companies) can not be included in the cover art. The only
                exceptions are when they are visually irrelevant (being part of
                the background), they accomplish a relevant role (e.g., in a
                musical) or are justified in the metadata (for example, as the
                producer or the publisher).{" "}
              </li>
            </ul>
          </li>{" "}
        </ol>
        <br /> It may be necessary to report the corresponding documentation to
        maintain registered brands, private images or references to companies or
        institutions in order to demonstrate the user is allowed to use them.
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

// module.exports = { DistributionMetadataGuidelines };
