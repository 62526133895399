import React, { useEffect } from "react";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { blockInput } from "../utils.js";

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  useEffect(() => {
    if (!props.hasValue) {
      blockInput(false);
    }
  });
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{ opacity: "0.3" }}
        className="mr-2"
      />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
