import IgColorIcon from "../../../assets/icons/ig-color.svg";
import TwColorIcon from "../../../assets/icons/tw-color.svg";
import YtColorIcon from "../../../assets/icons/yt_icon_rgb.png";
import FbColorIcon from "../../../assets/icons/fb-color.svg";

/**
 *
 * @property {String} name - name displayed on card and as icon alt
 * @property {Sting} platform - the platform concerned
 * @property {String} objectPath - path to the cards data
 * @property {Object} icon - icon object (needs to be imported on top of this file)
 */
export default [
  {
    name: "YouTube subscribers",
    platform: "youtube",
    objectPath: "youtube.subscriberCount",
    icon: YtColorIcon,
    // comingSoon: true,
  },
  {
    name: "YouTube views",
    platform: "youtube",
    objectPath: "youtube.viewCount",
    icon: YtColorIcon,
    // comingSoon: true,
  },
  {
    name: "YouTube video count",
    platform: "youtube",
    objectPath: "youtube.videoCount",
    icon: YtColorIcon,
    // comingSoon: true,
  },
  {
    name: "twitter followers",
    platform: "twitter",
    objectPath: "twitter.followers_count",
    icon: TwColorIcon,
  },
  {
    name: "twitter friends",
    platform: "twitter",
    objectPath: "twitter.friends_count",
    icon: TwColorIcon,
  },
  {
    name: "twitter listed count",
    platform: "twitter",
    objectPath: "twitter.listed_count",
    icon: TwColorIcon,
  },
  {
    name: "twitter favourites",
    platform: "twitter",
    objectPath: "twitter.favourites_count",
    icon: TwColorIcon,
  },
  {
    name: "twitter statuses count",
    platform: "twitter",
    objectPath: "twitter.statuses_count",
    icon: TwColorIcon,
  },
  {
    name: "facebook likes",
    platform: "facebook",
    objectPath: "facebook.fan_count",
    icon: FbColorIcon,
  },
  {
    name: "new like count",
    platform: "facebook",
    objectPath: "facebook.new_like_count",
    icon: FbColorIcon,
  },
  {
    name: "talking about count",
    platform: "facebook",
    objectPath: "facebook.talking_about_count",
    icon: FbColorIcon,
  },
  {
    name: "followers count",
    platform: "instagram",
    objectPath: "instagram.followers_count",
    icon: IgColorIcon,
  },
  {
    name: "follows count",
    platform: "instagram",
    objectPath: "instagram.follows_count",
    icon: IgColorIcon,
  },
  {
    name: "media count",
    platform: "instagram",
    objectPath: "instagram.media_count",
    icon: IgColorIcon,
  },
];
