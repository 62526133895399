import React from "react";

import RoyaltiesImportComponent from "./components/AdminRoyaltiesImportComponent";
import RoyaltiesDisplayComponent from "./components/AdminRoyaltiesDisplayComponent";

function RoyaltiesAdmin() {
  return (
    <div className="adminRoyalties">
      <RoyaltiesDisplayComponent />
      <RoyaltiesImportComponent />
    </div>
  );
}

export default RoyaltiesAdmin;
