import React from "react";
import ReactTooltip from "react-tooltip";
import info from "../../../assets/icons/info.svg";
import { tooltipData } from "../../../components/distribution/data/distrFieldsData";
import { groupBy } from "lodash";
import ReleasePlaceholder from "../../../assets/releasePlaceholder.png";

const contributorsNameLookup = {
  orchestra: "Orchestra",
  conductor: "Conductor(s)",
  remixer: "Remixer(s)",
  editor: "Editor(s)",
  arranger: "Arranger(s)",
};

const ReleaseCard = ({
  data,
  direction = "horizontal",
  forAdmin = false,
  isOwner,
}) => {
  const parsedImgUrl = data?.["#cover_url"]?.replace("#", "%23") || "";

  var releaseType;
  if (data?.tracks?.length >= 1 && data?.tracks?.length <= 3) {
    releaseType = "Single";
  } else if (data?.tracks?.length >= 4 && data?.tracks?.length <= 6) {
    releaseType = "EP";
  } else {
    releaseType = "Album";
  }
  const isHorizontalCard = direction === "horizontal";

  function handleContinue() {
    window.localStorage.setItem("currentReleaseId", data.releaseId);

    window.location.assign("/distribution/newRelease/1");
  }

  //grouping participants object
  const primaryArtists = data?.["#participants"]?.filter((p) => p?.primary);

  const contributors = groupBy(
    data?.["#participants"]?.filter((p) => !!!p?.primary),
    "type"
  );

  const renderContributors = (contributorType, contributors) => (
    <>
      <div className="row">
        <div className="prrcText prrcParticipant col">{contributorType}</div>
        <div className="col">
          {contributors?.map((c) => (
            <div className="prrcText prrcParticipant prrcWhiteText row mx-0">
              {c.value}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={isHorizontalCard ? "previewReleaseCard" : "h-100"}
      style={
        isHorizontalCard
          ? {
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "10px",
            }
          : {}
      }
    >
      {/* image */}
      <div
        className={`prReleaseCardImage-wrapper ${
          isHorizontalCard ? "" : "mt-3 w-auto h-auto text-center"
        }`}
      >
        <img
          alt="cover art"
          src={
            parsedImgUrl === "" || parsedImgUrl === "initial"
              ? ReleasePlaceholder
              : parsedImgUrl
          }
          loading="lazy"
          style={isHorizontalCard ? {} : { width: 250, height: 250 }}
          className={`prReleaseCardImage ${
            isHorizontalCard ? "" : "ml-0 mt-0"
          }`}
        />
      </div>

      <div
        className={
          isHorizontalCard
            ? "prrcRightContainer"
            : "px-4 py-2 ml-0 text-center h-auto w-100"
        }
        style={isHorizontalCard ? { top: 0 } : {}}
      >
        {/* right side */}
        <div className="prrcTitle">{data?.["#title"]}</div>
        <div className="prrcType">{releaseType}</div> &nbsp;
        <img
          src={info}
          alt="info"
          className="prrcType-info"
          data-tip={tooltipData.releaseType}
        />
        <ReactTooltip
          place="top"
          backgroundColor="#1d2025"
          className="ptTooltip"
          border
          borderColor="#0ef1db"
          effect="float"
          multiline={true}
        />
        <br />
        {/* Primary genre */}
        {data?.["#primary_genre"] ? (
          <div className="prrcBadge1">{data?.["#primary_genre"]}</div>
        ) : (
          ""
        )}
        {/* Secondary genre */}
        {data?.["#secondary_genre"] ? (
          <div className="prrcBadge2">{data?.["#secondary_genre"]}</div>
        ) : (
          ""
        )}
        <br />
        {/* Label, UPC , Catalog number */}
        {data?.["#label"] ? (
          <div className="row">
            <div className="prrcText prrcParticipant col">Label</div>
            <div className="prrcText prrcParticipant prrcWhiteText col">
              {data?.["#label"]}
            </div>
          </div>
        ) : (
          ""
        )}
        {data?.["#catalog_number"] ? (
          <div className="row">
            <div className="prrcText prrcParticipant col">Catalog number</div>
            <div className="prrcText prrcParticipant prrcWhiteText col">
              {data?.["#catalog_number"]}
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="prrcText prrcParticipant col">UPC </div>
          <div className="prrcText prrcParticipant prrcWhiteText col">
            {data?.["upc"] ? data?.["upc"] : data?.["#upc"]}
          </div>
        </div>
        <div className="releaseCardLine mb-3"></div>
        {/* Primary artist(s) */}
        {primaryArtists?.length && (
          <>
            <div className="row">
              <div className="prrcText prrcParticipant col">
                Primary artist (s)
              </div>
              <div className="col">
                {primaryArtists?.map((r) => (
                  <div className="prrcText prrcParticipant prrcWhiteText row mx-0">
                    {r.primary}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {contributors?.with && renderContributors("With", contributors.with)}
        {contributors?.featuring &&
          renderContributors("Featuring", contributors.featuring)}
        <div className="releaseCardLine mt-2 mb-3"></div>
        {contributors?.composer &&
          renderContributors("Composer(s)", contributors.composer)}
        {contributors?.lyricist &&
          renderContributors("Lyricist(s)", contributors.lyricist)}
        {contributors?.producer &&
          renderContributors("Producer(s)", contributors.producer)}
        {contributors?.publisher &&
          renderContributors("Publisher(s)", contributors.publisher)}
        {Object.keys(contributors).some((cType) =>
          ["contributors", "lyricist", "producer", "producer"].includes(cType)
        ) && <div className="releaseCardLine mt-2 mb-3"></div>}
        {Object.entries(contributors)
          ?.filter(
            (c) =>
              ![
                "with",
                "featuring",
                "composer",
                "lyricist",
                "producer",
                "publisher",
              ].includes(c[0])
          )
          .map((entry) =>
            renderContributors(contributorsNameLookup[entry[0]], entry[1])
          )}
        {Object.keys(contributors).some((cType) =>
          ["orchestra", "conductor", "remixer", "editor", "arranger"].includes(
            cType
          )
        ) && <div className="releaseCardLine mt-2 mb-3"></div>}
        {/* © Field */}
        <div className="prrcText">
          <span className="rcKey">{data?.["#c_line"] ? "© " : ""} </span>
          <span className="rcValue">{data?.["#c_line"]}</span>
        </div>
        {/* ℗ Field */}
        <div className="prrcText">
          <span className="rcKey">{data?.["#p_line"] ? "℗ " : ""} </span>
          <span className="rcValue">{data?.["#p_line"]}</span>
        </div>
        <br />
        {!forAdmin &&
          isOwner &&
          data?.state === "draft" &&
          data?.tracks?.length > 0 && (
            <div
              className={"uploadTracksButton position-static "}
              onClick={handleContinue}
            >
              Finish the release
            </div>
          )}
      </div>
      <br />
    </div>
  );
};

export default ReleaseCard;
