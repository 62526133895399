import { Formik } from "formik";
import React, { memo, useState } from "react";

import ButtonComponent from "../../../components/form/ButtonComponent";
import { setCsvSentStatus, updateReleaseState, cloneRelease } from "../../../routes/admin";

import ReleaseCard from "../../Releases/components/ReleaseCard";
import ReleaseMetaSection from "../../Releases/components/ReleaseMetaSection";
import TracksMetaSection from "./components/TracksMetaSection";
import { Spinner } from "../../Airplay/Airplay";
import { useMutation, useQueryClient } from "react-query";
import { getJWT } from "../../../components/utils";
import { regenerateReleaseCSV } from "../../../services/admin/releases";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import Toast from "../../../components/Toast";

const AdminReleaseOperations = ({
  userEmail,
  releaseId,
  releaseState,
  csvSent,
  onUpdate,
  setShowToast,
}) => {
  const queryClient = useQueryClient();
  const jwtToken = getJWT();

  // Release Mutation handler
  const releaseMutuation = useMutation(({ state, csvSentState }) =>
    Promise.all([
      updateReleaseState(jwtToken, userEmail, releaseId, state),
      setCsvSentStatus(jwtToken, userEmail, releaseId, csvSentState),
    ])
  );
  const cloneMutuation = useMutation(({ state }) =>
    Promise.all([
      cloneRelease(jwtToken, userEmail, releaseId),
    ])
  );
  const handleClone = (
    { releaseState: state, csvSent: csvSentState = false },
    formActions
  ) => {
    // Using the mutation handler to update release data
    cloneMutuation.mutate(
      { state },
      {
        onSuccess: () => {
          // Delaying loading as the spinner flashes in ui due to fast updation
          setTimeout(() => {
            setShowToast({
              open: true,
              message: "Release has been cloned successfully",
              type: "success",
            });
          }, 1250);
        },
        onError: (e) => {
          setShowToast({
            open: true,
            message: e.response.data.message
              ? e.response.data.message
              : "An unexpected error occured. Please try again.",
            type: "danger",
          });
          formActions.setSubmitting(false);
          formActions.resetForm();
        },
      }
    );
  };

  const handleSubmit = (
    { releaseState: state, csvSent: csvSentState = false },
    formActions
  ) => {
    // Using the mutation handler to update release data
    releaseMutuation.mutate(
      { state, csvSentState },
      {
        onSuccess: () => {
          // Delaying loading as the spinner flashes in ui due to fast updation
          setTimeout(() => {
            queryClient.setQueryData(["adminAllReleases", getJWT()], (old) => ({
              ...old,
              // Updating the cached version
              data: old.data.map((release) => {
                if (release.release.releaseId === releaseId) {
                  const updatedState = {
                    ...release,
                    release: {
                      ...release.release,
                      state,
                      csvSent: csvSentState,
                    },
                  };
                  onUpdate({ isOpen: true, release: updatedState });

                  return updatedState;
                }
                return release;
              }),
            }));
          }, 1250);
        },
        onError: (e) => {
          setShowToast({
            open: true,
            message: e.response.data.message
              ? e.response.data.message
              : "An unexpected error occured. Please try again.",
            type: "danger",
          });
          formActions.setSubmitting(false);
          formActions.resetForm();
        },
      }
    );
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ csvSent, releaseState }}
        initialStatus={{ edit: false }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            values: { releaseState, csvSent },
            status: { edit },
            dirty,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setStatus,
          } = props;

          return (
            <div
              className="pb-0"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderRadius: "10px",
              }}
            >
              <p className="prtcTitle position-relative fs-24">
                Admin operations:
              </p>

              <div className="row mx-0 pb-2 pr-4 justify-content-end">
                <div
                  className={
                    "text-white text-decoration-underline font-family-Inter " +
                    (edit ? "opacity-05" : "cursor-pointer")
                  }
                  onClick={() => setStatus({ edit: true })}
                >
                  Edit
                </div>
              </div>

              <div class="row px-3 pb-2 align-items-center ">
                <div
                  className={`nrAlbumTitleInputWrapper col-12 col-md-7 py-2 py-md-0 text-white mt-2 ${
                    !edit ? "opacity-05" : ""
                  }`}
                  id={"releaseStateAdmin"}
                >
                  <label htmlFor="releaseState" className=" playtreksSelector">
                    State
                  </label>
                  <select
                    id="releaseState"
                    style={{
                      width: "100%",
                      background: "#1d2025",
                      borderRadius: "10px",
                      height: "56px",
                      color: "white",
                      padding: "0 10px",
                      border: "none",
                    }}
                    disabled={!edit}
                    onChange={(e) =>
                      setFieldValue("releaseState", e.target.value)
                    }
                    onBlur={() => setFieldTouched("releaseState", true)}
                    value={releaseState}
                  >
                    <option value="draft">Draft</option>
                    <option value="submitted">Submitted / in progress</option>
                    <option value="submitted_to_qc">In quality control</option>
                    <option value="failed">Failed</option>
                    <option value="taken_down">Taken down</option>
                    <option value="succeeded">Succeeded</option>
                  </select>
                </div>

                <div
                  className={`col-13 col-lg-3  ${!edit ? "opacity-05" : ""}`}
                >
                  <div className="text-white">CSV sent: &nbsp;</div>

                  <div
                    className={`nrSwitch `}
                    style={{ pointerEvents: !edit ? "none" : "auto" }}
                    onClick={() => {
                      setFieldTouched("csvSent", true);
                      setFieldValue("csvSent", !csvSent);
                    }}
                  >
                    <div
                      className={
                        "nrHandle " + (!csvSent ? "nrHandleOff" : "nrHandleOn")
                      }
                      style={{ textAlign: "center", paddingTop: "5px" }}
                      id="explicitLyrics"
                    >
                      {!csvSent ? (
                        <span
                          style={{ position: "relative" }}
                          aria-label="not sent"
                        >
                          ✖️
                        </span>
                      ) : (
                        <span
                          style={{ position: "relative" }}
                          aria-label="sent"
                        >
                          ✔️
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {edit && (
                <div className="row mx-0 px-3 pb-2 justify-content-start">
                  <div>
                    <ButtonComponent
                      onClick={() => {
                        resetForm();
                        setStatus({ edit: false });
                      }}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton bg-transparent textLink w-100 h-75"
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                  <div>
                    <ButtonComponent
                      disabled={isSubmitting || !dirty}
                      onClick={handleSubmit}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton  w-100 h-75"
                    >
                      {isSubmitting ? (
                        <>
                          <span className="mr-2">Saving... </span>
                          <span>
                            <Spinner />
                          </span>
                        </>
                      ) : (
                        "Save"
                      )}
                    </ButtonComponent>
                    </div>
                  <div>
                    <ButtonComponent
                      disabled={isSubmitting}
                      onClick={handleClone}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton  w-100 h-75"
                    >
                      {isSubmitting ? (
                        <>
                          <span className="mr-2">Saving... </span>
                          <span>
                            <Spinner />
                          </span>
                        </>
                      ) : (
                        "Clone"
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const MoreInfo = ({ release = {}, platforms, setShowToast }) => {
  const [regeneratingCSV, setRegeneratingCSV] = useState(false);

  const tracksWithLicenseFiles = (release) =>
    release?.release?.tracks?.filter(
      (track) =>
        track.licenseFiles &&
        (track?.licenseFiles?.[0] !== {} ||
          track?.licenseFiles?.[1] !== {} ||
          track?.licenseFiles?.[2] !== {})
    );

  const handleRegenerateCSV = async () => {
    setRegeneratingCSV(true);
    try {
      const response = await regenerateReleaseCSV(
        release?.email,
        release?.release?.releaseId
      );
      if (response.status === 200) {
        setRegeneratingCSV(false);
        setShowToast({
          open: true,
          message: "Release CSV has been regenerated successfully",
          type: "success",
        });
      }
    } catch (error) {
      setRegeneratingCSV(false);
      setShowToast({
        open: true,
        message: error.response.data.message
          ? error.response.data.message
          : "An unexpected error occured. Please try again.",
        type: "danger",
      });
    }
  };

  return (
    <>
      <div className="row justify-content-between px-3">
        <a href={release?.release?.csv_url?.replace("@", "%40")}>
          <u>Download CSV</u>
        </a>
        <div>
          <ButtonComponent
            disabled={regeneratingCSV}
            onClick={handleRegenerateCSV}
            buttonWrapperClassName="pr-3"
            className="playtreksButton w-100 h-75"
          >
            <FontAwesomeIcon
              icon={faFileCsv}
              style={{ opacity: "0.3" }}
              className="mr-2"
            />
            {regeneratingCSV ? (
              <>
                Regenerating...
                <span className="ml-2">
                  <Spinner />
                </span>
              </>
            ) : (
              "Regenerate CSV"
            )}
          </ButtonComponent>
        </div>
      </div>
      <br />
      <div style={{ color: "white" }} className="mb-2">
        <b>Email:</b>{" "}
        <a href={"mailto:" + release?.email}>
          <u>{release?.email}</u>
        </a>
      </div>
      <br />
      <div style={{ color: "white" }}>
        <b>
          Platforms (
          {/* {release?.release?.platforms?.length === platforms.length
            ? "all "
            : release?.release?.platforms?.length > platforms.length
            ? "all+ "
            : ""} */}
          {release?.release?.platforms?.length}
          ):
        </b>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          overflowY: "scroll",
          overflowX: "hidden",
          color: "white",
        }}
      >
        <ul>
          {release?.release?.platforms?.map((platform) => (
            <li>{platform}</li>
          ))}
        </ul>
      </div>
      <br />
      <div
        style={{
          color: "white",
          fontSize: "14px",
        }}
      >
        <div>Social platform links:</div>
        {release?.release?.socialMediaLink1 && (
          <span>
            <a href={release.release?.socialMediaLink1} target="_blank">
              {release.release?.socialMediaLink1 || ""}
            </a>
            <br />
          </span>
        )}

        {release?.release?.socialMediaLink2 && (
          <span>
            <a href={release.release?.socialMediaLink2} target="_blank">
              {release.release?.socialMediaLink2 || ""}
            </a>
            <br />
          </span>
        )}
        {release?.release?.socialMediaLink3 && (
          <a href={release.release?.socialMediaLink3} target="_blank">
            {release.release?.socialMediaLink3}
          </a>
        )}
      </div>
      <br />
      <div style={{ color: "white", fontSize: "12px" }}>
        {tracksWithLicenseFiles(release)?.length > 0
          ? "License files:"
          : "No license files"}
      </div>
      <ul>
        {tracksWithLicenseFiles(release)?.map((track) => {
          return (
            <div style={{ color: "white" }}>
              <li>
                {track.form["#track_title"]}
                {track?.licenseFiles?.map((licenseFile, index) => {
                  if (!licenseFile.url) return <></>;
                  return (
                    <a
                      style={{
                        marginLeft: "5px",
                        textDecoration: "underline",
                      }}
                      href={licenseFile?.url}
                      target="_blank"
                    >
                      file {index + 1}
                    </a>
                  );
                })}
              </li>
            </div>
          );
        })}
      </ul>
      <hr />
    </>
  );
};

const ReleaseDetailsAdmin = memo(({ release = {}, platforms, onUpdate }) => {
  const releaseId = release?.release?.releaseId;
  const [showToast, setShowToast] = useState(initialToastState);

  return (
    <section className="ml-0 dashboard-airplay-container text-white">
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />

      <ReleaseCard forAdmin data={release?.release} direction="vertical" />

      <AdminReleaseOperations
        userEmail={release?.email}
        csvSent={release?.release?.csvSent}
        releaseState={release?.release?.state}
        releaseId={releaseId}
        onUpdate={onUpdate}
        setShowToast={setShowToast}
      />
      <ReleaseMetaSection
        userEmail={release?.email}
        release={release?.release}
        releaseId={releaseId}
        onUpdate={onUpdate}
      />

      <TracksMetaSection
        userEmail={release?.email}
        tracks={release?.release?.tracks || []}
        releaseId={releaseId}
        onUpdate={onUpdate}
      />
      <div>
        <MoreInfo
          release={release}
          platforms={platforms}
          setShowToast={setShowToast}
        />
      </div>
    </section>
  );
});

export default ReleaseDetailsAdmin;
