import React, { useState } from "react";
import { Link } from "react-router-dom";

import Cheeseburger from "../../../assets/icons/Cheeseburger.svg";
import X from "../../distribution/assets/X.svg";

import LineChartIcon from "../../../assets/icons/line_chart_up.svg";
import AddToQueueIcon from "../../../assets/icons/add_to_queue.svg";
import UserIcon from "../../../assets/icons/user_gray.png";
import HelpCenterIcon from "../../../assets/icons/helpcenter.png";
import {
  // MyDataMenu,
  DistributionMenu,
  AccountingMenu,
  UserMenu,
  SubscriptionMenu,
  Charts,
  Support,
} from "../menuConfig";
import AppSwitcher from "../AppSwitcher/AppSwitcher";
import { useSelector } from "react-redux";

export default function MobileMenuBurger() {
  function handleOpenMenu() {
    document.querySelector(".mobileMenu-overlay").classList.remove("d-none");
  }

  return (
    <div className="mobileMenu-burger" onClick={handleOpenMenu}>
      <img src={Cheeseburger} alt="menu" />
    </div>
  );
}

export function MobileMenu() {
  const [selectedDropDown, setSelectedDropDown] = useState("distribution");

  const handleDropDownValueChange = (event) => {
    setSelectedDropDown(event.target.value);

    if (event.target.value === "marketplace") {
      window.location.href = "https://marketplace.playtreks.com/";
    }

    if (event.target.value === "copyright") {
      window.location.href = "https://copyright.playtreks.com/";
    }
  };
  function handleCloseMenu() {
    document.querySelector(".mobileMenu-overlay").classList.add("d-none");
  }

  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );

  return (
    <div className="mobileMenu-overlay d-none">
      <img
        src={X}
        alt="close"
        className="mobileMenu-x"
        onClick={handleCloseMenu}
      />

      <div className="mobileMenu">
        <AppSwitcher
          handleDropDownValueChange={handleDropDownValueChange}
          selectedDropDown={selectedDropDown}
        />
        {/* {selectedDropDown === "analytics" && (
          <>
            <MobileMenuSection
              headerIcon={LineChartIcon}
              headerTitle="my data"
              menus={MyDataMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
            <br />
            <MobileMenuSection
              headerIcon={LineChartIcon}
              headerTitle="charts"
              menus={Charts}
              handleCloseMenu={() => handleCloseMenu()}
            />
          </>
        )} */}

        {selectedDropDown === "distribution" && (
          <>
            <MobileMenuSection
              headerIcon={AddToQueueIcon}
              headerTitle="distribution"
              menus={DistributionMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
            <br />

            <MobileMenuSection
              headerIcon={AddToQueueIcon}
              headerTitle="accounting"
              menus={AccountingMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
            <br />

            <MobileMenuSection
              headerIcon={AddToQueueIcon}
              headerTitle="subscription"
              menus={SubscriptionMenu}
              handleCloseMenu={() => handleCloseMenu()}
            />
          </>
        )}
        <br />
        <MobileMenuSection
          headerIcon={UserIcon}
          headerTitle="my account"
          menus={UserMenu}
          handleCloseMenu={() => handleCloseMenu()}
        />
        <br />
        <MobileMenuSection
          headerIcon={HelpCenterIcon}
          headerTitle="support"
          menus={Support}
          handleCloseMenu={() => handleCloseMenu()}
        />
      </div>
    </div>
  );
}

function MobileMenuSection({
  headerIcon,
  headerTitle,
  menus,
  path,
  handleCloseMenu,
}) {
  return (
    <div className="mobileMenu-section">
      {headerTitle && (
        <label className="mobileMenu-header">
          <img src={headerIcon} alt="header" />
          {headerTitle}
        </label>
      )}
      <ul>
        {menus.map((item, index) => {
          if (
            item.url === "https://support.playtreks.com/" ||
            item.url === "https://marketplace.playtreks.com/wallet"
          ) {
            return (
              <a
                href={item.url}
                key={index}
                target="_blank"
                onClick={() => handleCloseMenu()}
              >
                <li>{item.label}</li>
              </a>
            );
          }

          if (item.url === `${global.config.AUTH_REDIRECT_URL}/logout`) {
            return (
              <a href={item.url} key={index} onClick={() => handleCloseMenu()}>
                <li>{item.label}</li>
              </a>
            );
          }

          return (
            <Link to={item.url} key={index} onClick={() => handleCloseMenu()}>
              <li>{item.label}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
