import React, { useEffect, useState } from "react";
import Header from "./components/RuslanHeader";

import ReleaseProgressCircles from "./components/ReleaseProgressCircles";

import { getReleaseData } from "../../routes/distribution";

import { checkIfUserCompletedPage2 } from "./helpers/distrHelpers";

import info from "../../assets/icons/info.svg";
import ReactTooltip from "react-tooltip";

import { tooltipData } from "./data/distrFieldsData";
import { getJWT } from "../utils";

function DistributionPreview() {
  const jwtToken = getJWT();
  const currentReleaseId = window.localStorage.getItem("currentReleaseId");

  const [data, setData] = useState({});

  useEffect(() => {
    getReleaseData(currentReleaseId, jwtToken).then((r) => {
      if (checkIfUserCompletedPage2(r.data[0])) {
        setData(r.data[0]);
      } else {
        window.location.assign("/distribution/newRelease/1");
      }
    });
  }, []);

  const parsedImgUrl = data["#cover_url"]?.replace("#", "%23");

  var releaseType;
  if (data?.tracks?.length >= 1 && data?.tracks?.length <= 3) {
    releaseType = "Single";
  } else if (data?.tracks?.length >= 4 && data?.tracks?.length <= 6) {
    releaseType = "EP";
  } else {
    releaseType = "Album";
  }

  return (
    <div
      className="distributionRoot"
      style={{ backgroundColor: "#343a47", position: "absolute" }}
      // onClick={handleGeneralBlur}
    >
      <Header
        quitCallback={() =>
          window.location.assign("/artistDashboard/myReleases")
        }
      />
      {/* title */}
      <div className="newReleaseTitle">new release</div>
      {/* release progress circles */}
      <ReleaseProgressCircles activeCircle={3} />
      {/* card release */}
      <main className="distribution-preview-overview">
        <div className="previewReleaseCard">
          {/* image */}
          <div className="prReleaseCardImage-wrapper">
            <img
              alt="cover art"
              src={parsedImgUrl}
              className="prReleaseCardImage"
            />
          </div>
          <div
            className="prrcEdit"
            onClick={() => window.location.assign("/distribution/newRelease/1")}
          >
            Edit
          </div>
          <div className="prrcRightContainer">
            {/* right side */}
            <div className="prrcTitle">
              {data["#title"]}{" "}
              {data["#remix_or_version"]
                ? ` (${data["#remix_or_version"]})`
                : ""}
            </div>
            <div className="prrcType">{releaseType}</div> &nbsp;
            <img
              src={info}
              alt="info"
              className="prrcType-info"
              data-tip={tooltipData.releaseType}
            />
            <ReactTooltip
              place="top"
              backgroundColor="#1d2025"
              className="ptTooltip"
              border
              borderColor="#0ef1db"
              effect="float"
              multiline={true}
            />
            <br />
            {data["#primary_genre"] ? (
              <div className="prrcBadge1">{data["#primary_genre"]}</div>
            ) : (
              ""
            )}
            {data["#secondary_genre"] ? (
              <div className="prrcBadge2">{data["#secondary_genre"]}</div>
            ) : (
              ""
            )}
            <br />
            {data["#label"] ? (
              <div className="prrcText prrcParticipant">
                Label: <span className="prrcWhiteText">{data["#label"]}</span>
              </div>
            ) : (
              ""
            )}
            <div className="prrcPrimary">
              {data["#participants"]?.map((r) => {
                let objKey = Object.keys(r)[0];
                let value = r[objKey];
                if (objKey === "primary") {
                  return (
                    <div className="prrcText prrcParticipant">
                      Primary artist:{" "}
                      <span className="prrcWhiteText">{value}</span>
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              })}
            </div>
            <div className="prrcContributors">
              {data["#participants"]?.map((r) => {
                let objType = r["type"];
                let value = r["value"];
                if (Object.keys(r)[0] !== "primary") {
                  const type =
                    objType?.charAt(0).toUpperCase() + objType?.slice(1); // capitalize first letter
                  return (
                    <div className="prrcText prrcParticipant">
                      {type}: <span className="prrcWhiteText">{value}</span>
                    </div>
                  );
                }
              })}
            </div>
            <div className="prrcSpaceBottom"></div>
          </div>
        </div>
        <div className="prTracksCard">
          <div className="prtcTitle">Tracks</div>
          <div
            className="prtcEdit"
            onClick={() => window.location.assign("/distribution/newRelease/2")}
          >
            Edit
          </div>
          <div className="prtcItemsWrapper">
            {data.tracks?.map((track) => (
              <div className="prtcItem">
                {track.form["#track_title"]}{" "}
                {track.form["#remix_or_version"]
                  ? ` (${track.form["#remix_or_version"]})`
                  : ""}
              </div>
            ))}
          </div>
        </div>
        <div className="releaseOptionsButtonWrapper">
          <div
            className="releaseOptionsButton"
            onClick={() =>
              window.location.replace("/distribution/newRelease/4")
            }
          >
            Go to release options
          </div>
          <div className="nrTermsAndConditionsText">
            By clicking you agree with{" "}
            <a href="/terms" target="_blank">
              <u>Terms & Conditions</u>
            </a>
          </div>
          <br />
          <br />
          <br />
          <br />
        </div>
      </main>
    </div>
  );
}

export default DistributionPreview;
