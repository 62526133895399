import * as Yup from "yup";

export const RoyaltiesSplitsValidationSchema = Yup.object().shape({
  shareholders: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Required!"),
        email: Yup.string().email("Invalid email").required("Required!"),
        share: Yup.number()
          .typeError("Invalid split")
          .required("Required!")
          .min(0, "Cannot be less than 0")
          .max(100, "Cannot be greater than 100"),
      })
    )
    .required("Cannot be empty"),
  totalShare: Yup.number()
    .typeError("Invalid percentage")
    .min(100, "Total must equal 100%!")
    .max(100, "Total must equal 100%!"),
});
