import React from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import "./index.scss";
import { Pagination, TableBody, TableHeader } from "../../Table";

export default function RadioMonitoringTable({
  data,
  columns,
  // pagination: { pageCount: totalPages = 0 } = {},
  // onPaginationChange,
  onSort,
  loading,
  loadMore,
  isFetchingNextPage,
  hasNextPage,
}) {
  data = data?.pages?.flatMap((page) => page.data) || [];

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        // pageIndex: 0,
        // pageSize: 10,
        sortBy: [{ id: "date", desc: true }],
      },
      // manualPagination: true,
      // pageCount: totalPages,
      manualSortBy: true,
      disableMultiSort: true,
      defaultCanSort: true,
    },
    useSortBy
    // usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // page,
    // canPreviousPage,
    // canNextPage,
    // pageCount,
    // nextPage,
    // previousPage,
    // setPageSize,
    state: {
      // pageIndex,
      // pageSize,
      sortBy,
    },
    rows: tableData,
  } = tableInstance;

  // Listen for changes in pagination and use the state to fetch our new data
  // React.useEffect(() => {
  //   onPaginationChange({ pageIndex, size: pageSize });
  // }, [pageIndex, pageSize]);

  // Listen for changes in sort and use the state to fetch our new data
  React.useEffect(() => {
    onSort(sortBy[0]);
  }, [sortBy?.[0]]);

  return (
    <>
      {/* List of airplays  */}
      <table {...getTableProps()} className={"airplay-table"}>
        <TableHeader
          className={"position-sticky top-0  bg-1d2025"}
          headerGroups={headerGroups}
        />
        <TableBody
          loading={loading}
          page={tableData}
          prepareRow={prepareRow}
          numberOfColumns={5}
          getTableBodyProps={getTableBodyProps}
        />
        <tfoot className="position-sticky bottom-0">
          <tr>
            <td colSpan={5} className="p-3 text-center">
              <div className="d-flex justify-content-center">
                <button
                  className="playtreksButton w-auto h-75 btn btn-secondary"
                  onClick={() => loadMore()}
                  disabled={!hasNextPage}
                >
                  {isFetchingNextPage
                    ? "Loading more..."
                    : hasNextPage
                    ? "Load More"
                    : "Nothing more to load"}
                </button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      {/* Pagination */}
      {/* <div className="row justify-content-center mx-1 mt-3 position-sticky p-3" style={{bottom: 0, background: "#1d2025"}}>
        <Pagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
        />
      </div> */}
    </>
  );
}
