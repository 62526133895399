import React, { memo } from 'react';
import Chip from "@material-ui/core/Chip";

const AppliedFilters = memo(({appliedFilters, onReset}) => {
    return ( 
        Object.keys(appliedFilters).map((filterName) =>
            appliedFilters[filterName].value ? (
              <div className="px-3 pt-2">
                <Chip
                  label={`${appliedFilters[filterName].label} ${appliedFilters[filterName].value}`}
                  onDelete={onReset.bind(this, filterName)}
                  className="color-primary border-primary text-white bg-dark"
                  variant="outlined"
                />
              </div>
            ) : null
          )
     );
})
 
export default AppliedFilters;