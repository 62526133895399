import React, { useState, useEffect, useMemo } from "react";
import path from "path";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  editCurrentTrackField,
  addUploadTracksPrimaryArtistField,
  addUploadTracksContributorField,
  removeUploadTracksPrimaryArtistField,
  removeUploadTracksContributorField,
  validateUTField,
  removeTrack,
  updateFormWithTrackLicenseFile,
  removeTrackLicenseFile,
  setUploadingInProgress,
  editCurrentTrack,
} from "../../../redux/actions/distributionActions.js";

// pkgs
import ReactTooltip from "react-tooltip";
import { deepClone } from "lodash";

// imgs
import trash from "../../../assets/icons/trash.svg";
import info from "../../../assets/icons/info.svg";
import spinner from "../../../assets/spinner.svg";
import infoRed from "../../../assets/icons/infoRed.svg";
import "./index.scss";

// data
import {
  contributorsOptions,

  administrativeOptions,
  instrumentalOptions,
  engineeringOptions,
  otherPerformersOptions,

  writersOptions,
  vocalOptions,

  secondaryPerformersOptions,

  genres,
  languages,
  explicitLyrics,
  tooltipData,
} from "../data/distrFieldsData";

// components
import InputComponent from "../../form/InputComponent";
import SelectComponent from "../../form/SelectComponent";
import Checkbox from "../../form/RuslanCheckbox";

// distribution specific
import UploadTracksPrimaryArtistFieldComponent from "./UploadTracksPrimaryArtistFieldComponent";
import UploadTracksContributorsFieldComponent from "./UploadTracksContributorsFieldComponent";
import ContributorsFieldComponent from "./ContributorsFieldComponent";

import PrimaryArtistFieldComponent from "./PrimaryArtistFieldComponent";

// validation
import ValidationTextBox from "../../form/ValidationTextBox";
import { uploadTrackLicenseFile } from "../../../routes/distribution.js";
import { addAlert } from "../../../redux/actions/appActions.js";
import { getJWT } from "../../utils.js";

import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import Portal from "../../Portal/index.js";
import VersionErrorModal from "./VersionErrorModal.js";

// props are coming from within SortableComponent.js
function ExpandedPanel({
  trackIndex,
  trackType,
  className,
  setChangesPresent,
}) {
  const jwtToken = getJWT();
  const currentReleaseId = window.localStorage.getItem("currentReleaseId");

  const [prepopulated, setPrepopulated] = useState(false);

  const [artistsArray, setArtistsArray] = useState([1]);
  const [contributorsArray, setContributorsArray] = useState([1]);

  const [secondaryArray, setSecondaryArray] = useState([0]);

  const [contributorsArrayOtherPerformers, setContributorsArrayOtherPerformers] = useState([0]);
  const [contributorsArrayEngineering, setContributorsArrayEngineering] = useState([0]);
  const [contributorsArrayInstrumental, setContributorsArrayInstrumental] = useState([0]);
  const [contributorsArrayAdminis, setContributorsArrayAdminis] = useState([0]);

  const [writerContributorsArray, setWriterContributorsArray] = useState([0]);
  const [vocalContributorsArray, setVocalContributorsArray] = useState([0]);

  const [checked, setChecked] = useState({
    isrcCodeCheckbox: false,
  });
  const [utSwitch, setUtSwitch] = useState({
    explicitLyrics: false,
    explicitLyricsEdited: false,
  });
  const [editing, setEditing] = useState(false); // don't run prepopulate from step one if user started editing

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  // REDUX
  const reduxReleaseForm = useSelector(
    (state) => state.distributionReducer?.releaseForm
  );
  const reduxTracksForm = useSelector(
    (state) => state.distributionReducer?.tracksForm
  );
  const reduxCurrentTrackForm = useSelector(
    (state) => state.distributionReducer?.tracksForm?.[trackIndex]?.form
  );

  const currentTrack = useSelector(
    (state) => state.distributionReducer?.tracksForm?.[trackIndex]
  );

  // TODO: originalfilename?
  const reduxTracksFiles = useSelector(
    (state) => state.distributionReducer?.tracksFiles
  );
  const reduxUserEmail = useSelector(
    (state) => state.distributionReducer?.userEmail
  );
  const dispatch = useDispatch();

  const originalFileName = reduxTracksForm?.[trackIndex]?.originalFileName;
  const currentTrackLicenseFiles = reduxTracksForm?.[trackIndex]?.licenseFiles;

  const reduxCurrentPrimary = useSelector(
    (state) =>
      state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
        "#participants"
      ]?.primary
  );
  const reduxCurrentContributors = useSelector(
    (state) =>
      state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
        "#participants"
      ]?.contributors
  );
  const pYearVal = useSelector(
    (state) =>
      state.distributionReducer?.tracksValidation?.[trackIndex]?.["#p_year"]
  );
  const pLineVal = useSelector(
    (state) =>
      state.distributionReducer?.tracksValidation?.[trackIndex]?.["#p_line"]
  );

  const lyricsValue = useSelector(
    (state) =>
      state.distributionReducer?.tracksForm?.[trackIndex]?.form?.["_lyrics"]
  );
  useEffect(() => {
    // updating the visual state vars if form prepopulated
    setContributorsArray(reduxCurrentContributors?.map((item) => 1));
    setArtistsArray(reduxCurrentPrimary?.map((item) => 1));

     let filtered = [];
    filtered = reduxCurrentContributors?.filter(item =>
        writersOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setWriterContributorsArray([{ type: '', value: '' }]);
    } else {
      setWriterContributorsArray(filtered);
    }
    
    // split options
    filtered = reduxCurrentContributors?.filter(item =>
        secondaryPerformersOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setSecondaryArray([{ type: '', value: '' }]);
    } else {
      setSecondaryArray(filtered);
    }
    
    filtered = reduxCurrentContributors?.filter(item =>
        otherPerformersOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setContributorsArrayOtherPerformers([{ type: '', value: '' }]);
    } else {
      setContributorsArrayOtherPerformers(filtered);
    }
    
    filtered = reduxCurrentContributors?.filter(item =>
        engineeringOptions.some(
          criterion => item.type === criterion.value
        )
      );

    if (filtered.length < 1) {
      setContributorsArrayEngineering([{ type: '', value: '' }]);
    } else {
      setContributorsArrayEngineering(filtered);
    }
    
    // console.log("engineering",filtered,engineeringOptions,r.data[0]["#engineeringcontributors"].contributors);

    filtered = reduxCurrentContributors?.filter(item =>
        instrumentalOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setContributorsArrayInstrumental([{ type: '', value: '' }]);
    } else {
      setContributorsArrayInstrumental(filtered);
    }
   
    filtered = reduxCurrentContributors?.filter(item =>
        administrativeOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setContributorsArrayAdminis([{ type: '', value: '' }]);
    } else {
      setContributorsArrayAdminis(filtered);
    }

    filtered = reduxCurrentContributors?.filter(item =>
        vocalOptions.some(
          criterion => item.type === criterion.value
        )
      );
    if (filtered.length < 1) {
      setVocalContributorsArray([{ type: '', value: '' }]);
    } else {
      setVocalContributorsArray(filtered);
    }

    // updating state of checkboxes and switches
    setChecked({
      isrcCodeCheckbox: reduxCurrentTrackForm?.["#isrc"] === "auto",
    });
    setUtSwitch({
      explicitLyrics: reduxCurrentTrackForm?.["#explicit_lyrics"],
    });
  }, [reduxCurrentTrackForm, reduxCurrentPrimary, reduxCurrentContributors]); // runs on component load

  useEffect(() => {
    if (editing) {
      setChangesPresent(true);
    }
  }, [editing]); // sends the editing variable up for the quit modal prompt

  function handleCheckbox(e) {
    setEditing(true);
    // visual state
    setChecked({ ...checked, [e.target.id]: !checked[e.target.id] });
    // redux form state update is in use effect under this function
    dispatch(
      editCurrentTrackField(
        !checked[e.target.id] ? "auto" : "",
        "#isrc",
        trackIndex
      )
    );
    dispatch(
      validateUTField(
        !checked[e.target.id] ? "auto" : "",
        "#isrc",
        "",
        "",
        "",
        trackIndex
      )
    );
  }

 

  function handleAddSecondaryContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setSecondaryArray([...secondaryArray, 1]);
  }
  function handleAddOtherContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setContributorsArrayOtherPerformers([...contributorsArrayOtherPerformers, 1]);
  }
  function handleAddEngineeringContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setContributorsArrayEngineering([...contributorsArrayEngineering, 1]);
  }
  function handleAddInstrumentalContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setContributorsArrayInstrumental([...contributorsArrayInstrumental, 1]);
  }
  function handleAddAdmisContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setContributorsArrayAdminis([...contributorsArrayAdminis, 1]);
  }
  function handleAddWriterContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setWriterContributorsArray([...writerContributorsArray, 1]);
  }
  function handleAddVocalContributor(databaseField) {
    // creating new blank form fields for UI state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    // this is only for the visual state
    setVocalContributorsArray([...vocalContributorsArray, 1]);
  }

  function inputChangeCallback(
    value,
    databaseField,
    databaseKey,
    inputId,
    subKey
  ) {
    setEditing(true);
    // setting form
    dispatch(
      editCurrentTrackField(
        value,
        databaseField,
        trackIndex,
        databaseKey,
        inputId,
        subKey
      )
    );
  }

  function inputBlurCallback(
    value,
    databaseField,
    inputId,
    databaseKey,
    databaseSubKey
  ) {
    dispatch(
      validateUTField(
        value,
        databaseField,
        databaseKey,
        databaseSubKey,
        inputId,
        trackIndex
      )
    );
  }
  function selectChangeCallback(value, databaseField, handlerType) {
    setEditing(true);
    // only update state on picked element, not on type (prevent blur bug)
    if (handlerType !== "inputChange") {
      dispatch(editCurrentTrackField(value, databaseField, trackIndex));
    }
  }

  function selectBlurCallback(commentField, databaseField, value) {
    // validation
    dispatch(validateUTField(value, databaseField, "", "", "", trackIndex));
    // visual
    setTimeout(() => {
      if (reduxCurrentTrackForm?.[databaseField]?.length > 0) {
        document.querySelector(commentField).classList.remove("d-none");
      }
    }, 0);
  }

  function removeInputCallback(target, name, itemIndex, value) {
    // console.log('Remove', itemIndex, name);
    // TODO: move to expandables helper file?
    if (name === "primary") {
      // visual update
      let newArtistsArray = artistsArray.filter((v, i) => i !== itemIndex); // removing the object according to its position in the corresponding array
      setArtistsArray(newArtistsArray);

      // redux
      dispatch(removeUploadTracksPrimaryArtistField(trackIndex, itemIndex));
    } 
    else if (name === "secondarycontributors") {
      // visual
      let newSecondaryArray = secondaryArray.filter(
        (v, i) => i !== itemIndex
      );
      setSecondaryArray(newSecondaryArray);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "othercontributors") {
      // visual
      let newContributorsArrayOtherPerformers = contributorsArrayOtherPerformers.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayOtherPerformers(newContributorsArrayOtherPerformers);

      // redux
     dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "engineeringcontributors") {
      // visual
      let newContributorsArrayEngineering = contributorsArrayEngineering.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayEngineering(newContributorsArrayEngineering);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "instrumentalcontributors") {
      // visual
      let newContributorsArrayInstrumental = contributorsArrayInstrumental.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayInstrumental(newContributorsArrayInstrumental);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "vocalcontributors") {
      // visual
      let newVocalContributorsArray = vocalContributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setVocalContributorsArray(newVocalContributorsArray);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "writercontributors") {
      // visual
      let newWriterContributorsArray = writerContributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setWriterContributorsArray(newWriterContributorsArray);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "adminiscontributors") {
      // visual
      let newContributorsArrayAdminis = contributorsArrayAdminis.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArrayAdminis(newContributorsArrayAdminis);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
    else if (name === "contributors") {
      // visual
      let newContributorsArray = contributorsArray.filter(
        (v, i) => i !== itemIndex
      );
      setContributorsArray(newContributorsArray);

      // redux
      dispatch(removeUploadTracksContributorField("#"+name, trackIndex, itemIndex));
    }
  }

  // this check happens also in the distributionReducer, can be eliminated if artistsArray is controlled by redux
  const reduxAllowedArtistFieldAmount = useSelector(
    (state) =>
      state.subscriptionReducer?.subscription?.rules
        ?.distributionPrimaryArtistsFields?.fieldAmount
  );
  const currentNumberOfFields = useSelector(
    (state) =>
      state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
        "#participants"
      ]?.primary?.length
  );

  function handleAddArtist() {
    // this is only for the visual state
    if (reduxAllowedArtistFieldAmount === currentNumberOfFields) {
      dispatch(addAlert("Please upgrade", "/artistDashboard/subscriptions"));
      return;
    }
    dispatch(addUploadTracksPrimaryArtistField(trackIndex));
    setArtistsArray([...artistsArray, 1]);
  }
  function handleAddContributor(databaseField) {
    // creating new blank form fields for state reference, will be cleared up on submission
    dispatch(addUploadTracksContributorField(trackIndex, databaseField));
    setContributorsArray([...contributorsArray, 1]);
  }
  function handleRemoveInput(name, inputIndex) {
    if (name === "artist") {
      let newArtistsArray = artistsArray.filter((v, i) => i !== inputIndex); // removing the object according to its position in the corresponding array
      setArtistsArray(newArtistsArray); // update the visual state of inputs

      dispatch(removeUploadTracksPrimaryArtistField(trackIndex, inputIndex)); // update form for proper field value update
    } else if (name === "contributors") {
      let newContributorsArray = contributorsArray.filter(
        (v, i) => i !== inputIndex
      );
      setContributorsArray(newContributorsArray); // update the visual state of inputs
      dispatch(removeUploadTracksContributorField(trackIndex, inputIndex)); // update form for proper field value update
    }
    // TODO: REMOVE VALIDATION PROPERLY WITH REDUX
  }

  useEffect(() => {
    if (currentTrack?.trackType === "instrumental") {
      setContributorsArray([1]);
      dispatch(removeUploadTracksContributorField(trackIndex, 1));
    }
  }, [currentTrack?.trackType]);
  function selectFocusCallback(e) {}

  function handleDeleteTrack() {
    // maybe make prettier UI for next version?
    if (!deleteConfirm) {
      setDeleteConfirm(true);
    } else {
      dispatch(removeTrack(trackIndex));
      setDeleteConfirm(false);
      window.scrollTo(0, 0);
    }
  }

  function handleUtSwitch() {
    setEditing(true);
    // visual state
    setUtSwitch({
      explicitLyrics: !utSwitch.explicitLyrics,
      explicitLyricsEdited: true,
    });
    dispatch(
      editCurrentTrackField(
        !utSwitch.explicitLyrics,
        "#explicit_lyrics",
        trackIndex
      )
    );
  }

  function handleLicenseFileWrapper(inputIndex) {
    let hiddenFileInput = document.getElementById(
      "licenseFileInput_track" + trackIndex + "_input" + inputIndex
    );
    hiddenFileInput.click();
  }

  const [licenseStatus, setLicenseStatus] = useState([
    { loading: false, err: "" },
    { loading: false, err: "" },
    { loading: false, err: "" },
  ]);

  useEffect(() => {
    // if one of the fields is uploading, set general status to true
    // and only if all fields have finished uploading, set the general loading status to false
    // and unblock the UI next button
    dispatch(
      setUploadingInProgress(
        licenseStatus.some((status) => status.loading === true)
      )
    );
  }, [licenseStatus]);

  function handleLicenseFileUpload(e, inputIndex) {
    setEditing(true);
    // loading
    let newStatus = [...licenseStatus];
    newStatus[inputIndex].loading = true;
    newStatus[inputIndex].err = "";
    setLicenseStatus(newStatus);
    // new data
    const file = e.target.files[0];
    const originalFileName = e.target.files[0].name;
    // uploading, block UI
    // TRUE

    uploadTrackLicenseFile(file, currentReleaseId, jwtToken)
      .then((r) => {
        // update form
        dispatch(
          updateFormWithTrackLicenseFile(
            trackIndex,
            inputIndex,
            originalFileName,
            r.data.licenseFileUrl
          )
        );
        // loading
        let statusAfterUpload = licenseStatus;
        statusAfterUpload[inputIndex].loading = false;
        setLicenseStatus(statusAfterUpload);
        // unblock UI
        // FALSE
      })
      .catch((e) => {
        // revert form
        dispatch(
          updateFormWithTrackLicenseFile(trackIndex, inputIndex, "", "")
        );
        // display error feedback
        let errStatus = [...licenseStatus];
        errStatus[inputIndex].loading = false;
        errStatus[inputIndex].err = e.response.data.msg;
        setLicenseStatus(errStatus);
        // unblock UI
        // FALSE
      });
  }

  function handleRemoveLicenseFile(inputIndex) {
    // delete from S3? might create problems if prepopulating and form not saved
    dispatch(removeTrackLicenseFile(trackIndex, inputIndex));
  }

  function originalLicenseFileName(inputIndex) {
    let fileName = currentTrackLicenseFiles?.[inputIndex]?.originalFileName;
    if (!fileName) return "";
    if (fileName.length > 20) {
      return fileName.slice(0, 20) + "...";
    } else return fileName;
  }

  // prepop fix
  const trackLangValue = useSelector(
    (state) =>
      state.distributionReducer.tracksForm?.[trackIndex]?.form["#language"]
  );

  const trackform = useSelector(
    (state) => state.distributionReducer.tracksForm?.[trackIndex]
  );

  const getLanguageLabel = () => {
    if (trackform.tracktype === "instrumental") {
      return "zxx";
    } else {
      return languages.find((lang) => lang.value === trackLangValue)?.label;
    }
  };

  // locked due to subscription
  const reduxLockedFields = useSelector(
    (state) => state.distributionReducer.lockedFields
  );

  function isFieldLocked(databaseField, databaseKey, databaseSubKey) {
    if (databaseField in reduxLockedFields) {
      return true;
    }
  }

  // subscription restriction logic
  const reduxSubscriptionRules = useSelector(
    (state) => state.subscriptionReducer.subscription.rules
  );

  useEffect(() => {
    // enforce value
    if (reduxSubscriptionRules?.distributionTrackVersionField?.value) {
      dispatch(
        editCurrentTrackField(
          reduxSubscriptionRules?.distributionTrackVersionField?.value,
          "#remix_or_version",
          trackIndex,
          "",
          "",
          ""
        )
      );
    }
  }, [reduxSubscriptionRules]);

  const uppy = useMemo(() => {
    return new Uppy({
      id: `track-upload-${trackIndex}`,
      autoProceed: false,
      meta: {
        releaseId: currentReleaseId,
      },
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["audio/wav", "audio/wave", ".wav"],
      },
      locale: {
        strings: {
          browseFiles: "browse on device",
          dropPasteFiles: "Upload track or %{browseFiles}",
        },
      },
    }).use(AwsS3, {
      companionUrl: `${global.config.API_URL}/api/companion`,
      companionHeaders: {
        "uppy-auth-token": `Bearer ${jwtToken}`,
      },
      metaFields: ["releaseId"],
    });
  }, []);

  useEffect(() => {
    uppy.on("complete", (result) => {
      result.successful.forEach((file) => {
        const trackNameFromFile = file.name.substr(
          0,
          file.name.length - path.extname(file.name).length
        );

        dispatch(
          editCurrentTrack(
            {
              "#audio_url": file?.uploadURL,
              originalFileName: trackNameFromFile,
            },
            trackIndex
          )
        );
        dispatch(
          validateUTField(file?.uploadURL, "#audio_url", "", "", "", trackIndex)
        );
      });
    });

    return () => uppy.close();
  }, [uppy]);

  const trackIsNotUploaded =
    useSelector(
      (state) =>
        state.distributionReducer?.tracksForm?.[trackIndex]?.["#audio_url"]
    ) === "";

  const trackAudioFileValidation = useSelector(
    (state) =>
      state.distributionReducer?.tracksValidation?.[trackIndex]?.["#audio_url"]
  );

  const [isOpenVersionModal, setIsOpenVersionModal] = useState(false);

  const handleOpenVersionModal = () => {
    setIsOpenVersionModal(true);
  };

  const handleOnModalClose = () => {
    setIsOpenVersionModal(false);
  };

  return (
    <div className={"utExpandedPanel " + className}>
      {trackIsNotUploaded && (
        <div className="mb-4">
          <Dashboard
            uppy={uppy}
            width={window.innerWidth > 1024 ? 864 : "90%"}
            height={200}
            theme="dark"
            className="mb-3"
            // plugins={["Dropbox", "GoogleDrive"]}
          />
          <ValidationTextBox
            display={trackAudioFileValidation}
            className="utValTextBox"
            for="#audio_url"
            // style={{ position: "absolute" }}
          >
            <span>
              Please upload the track file{" "}
              <img
                src={infoRed}
                className="nrValTextBox-infoImage cur-pointer"
              />
            </span>
          </ValidationTextBox>
        </div>
      )}

      <div className="utepSongTitleInputWrapper">
        {/* upper comment inside the field, shows on input change */}
        <div className="inputUpperComment utiuc1">Song title*</div>
        <InputComponent
          // commentField=".utiuc1"
          value={useSelector(
            (state) =>
              state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                "#track_title"
              ]
          )}
          inputGroupClassName="nrInputGroup"
          inputClassName="nrInput interMediumInput interMediumPaddingTop"
          addon={false}
          changeCallback={inputChangeCallback}
          inputBlurCallback={inputBlurCallback}
          databaseField="#track_title"
          blockPaddingRevert={true}
        />
        <ValidationTextBox
          display={useSelector(
            (state) =>
              state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                "#track_title"
              ]
          )}
          className="utValTextBox"
          for="#track_title"
          style={{ position: "absolute" }}
        >
          <span data-tip='These characters are not allowed: <, >, |, /, , -, ., :, ;, !, ¡, ?, ¿, -, ·, &, ", ‘'>
            Please provide a valid title for your track{" "}
            <img src={infoRed} className="nrValTextBox-infoImage cur-pointer" />
          </span>
        </ValidationTextBox>
      </div>
      <div className="utepTrackVersionInputWrapper">
        {/* upper comment inside the field, shows on input change */}
        <div
          className={
            "inputUpperComment utiuc2 " +
            (reduxCurrentTrackForm?.["#remix_or_version"] ? "" : "d-none")
          }
        >
          Track version
        </div>
        <InputComponent
          value={useSelector(
            (state) =>
              state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                "#remix_or_version"
              ]
          )}
          disabled={isFieldLocked("#remix_or_version")}
          databaseField="#remix_or_version"
          commentField=".utiuc2"
          placeholder="Track version"
          inputGroupClassName="nrInputGroup"
          inputClassName={
            "nrInput " +
            (reduxCurrentTrackForm?.["#remix_or_version"]
              ? "interMediumInput interMediumPaddingTop"
              : "")
          }
          paddingTopOnInput={true}
          changeCallback={inputChangeCallback}
          inputBlurCallback={inputBlurCallback}
          onBlur={inputBlurCallback}
          // addon
          addon="right"
          inputGroupTextClassName="nrRightAddon"
          img={info}
          imgClass="nrAddonRightIcon"
          alt="info"
          addonClickCallback={() => {}} // leave empty!
          // tooltip
          tooltip={true}
          tooltipText={tooltipData.trackVersion}
        />
        <ValidationTextBox
          display={useSelector(
            (state) =>
              state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                "#remix_or_version"
              ]
          )}
          className="nrValTextBox mt-4 cur-pointer"
          for="#remix_or_version"
        >
          <span data-tip="Please click on more info button for the list of words that are not allowed in version field">
            This word is not allowed in the version field.{" "}
            <img src={infoRed} className="nrValTextBox-infoImage cur-pointer" />
          </span>
          <span className="ml-3 textLink" onClick={handleOpenVersionModal}>
            More info
          </span>
        </ValidationTextBox>

        <Portal selector="body">
          {isOpenVersionModal && (
            <VersionErrorModal onCloseRequest={handleOnModalClose} />
          )}
        </Portal>
      </div>
      <div className="utepHr"></div>
      {/* dynamically created fields (user plus click) */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
          
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Enter all primary artists here (use + to add new ones) &nbsp;{" "}
            <img
              src={info}
              className="cursor-pointer"
              data-tip={tooltipData.artistSlots}
            />
          </div>
            {artistsArray.map((v, i) => {
              return (
                <div>
                  <UploadTracksPrimaryArtistFieldComponent
                    i={i}
                    artistsArray={artistsArray}
                    inputChangeCallback={inputChangeCallback}
                    inputBlurCallback={inputBlurCallback}
                    handleRemoveInput={handleRemoveInput} // TODO: MIGRATE DOWN?
                    handleAddArtist={handleAddArtist} // TODO: MIGRATE DOWN?
                    trash={trash}
                    info={info}
                    trackIndex={trackIndex}
                  />
                </div>
              );
            })}
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Featuring and secondary artists
            </div>
            {/* contributors input group */}
            {secondaryArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={secondaryPerformersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={secondaryArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddSecondaryContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="secondarycontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
          </div>

          {/* hr */}
          <br />
          <div className="nrExpandableFieldsWrapper overrule-row-grid">
          {/* contributors vocal performers */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Vocal performers
          </div>
            {/* contributors input group */}
            {vocalContributorsArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={vocalOptions}
                  InputComponent={InputComponent}
                  contributorsArray={vocalContributorsArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddVocalContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="vocalcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
           <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Writers
            </div>
            {/* contributors input group */}
            {writerContributorsArray.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={writersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={writerContributorsArray}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddWriterContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="writercontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
        {/* contributors other performers group */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
            Other performers
          </div>
            {/* contributors input group */}
            {contributorsArrayOtherPerformers.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={otherPerformersOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayOtherPerformers}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddOtherContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="othercontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
          <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Producten and engineering
            </div>
            {/* contributors input group */}
            {contributorsArrayEngineering.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={engineeringOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayEngineering}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddEngineeringContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="engineeringcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
             Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />
        <div className="nrExpandableFieldsWrapper overrule-row-grid">
        {/* contributors other Instrumental group */}
          <div className="nrPrimaryArtistInputOuterWrapper">
            <div className="nrExpFieldsHint  mb-2">
              Instrumental performers
            </div>
            {/* contributors input group */}
            {contributorsArrayInstrumental.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={instrumentalOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayInstrumental}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddInstrumentalContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="instrumentalcontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
           <div className="nrContributorsOuterWrapper">
            <div className="nrExpFieldsHint nrExpFieldsHint-2 mb-2">
              Administrative roles
            </div>
            {/* contributors input group */}
            {contributorsArrayAdminis.map((v, i) => {
              return (
                <ContributorsFieldComponent
                  i={i}
                  SelectComponent={SelectComponent}
                  contributorsOptions={administrativeOptions}
                  InputComponent={InputComponent}
                  contributorsArray={contributorsArrayAdminis}
                  trash={trash}
                  inputChangeCallback={inputChangeCallback}
                  removeInputCallback={removeInputCallback}
                  handleAddContributor={handleAddAdmisContributor}
                  inputBlurCallback={inputBlurCallback}
                  selectBlurCallback={selectBlurCallback}
                  databaseKey="adminiscontributors"
                  prepopulated={prepopulated}
                />
              );
            })}
            <ValidationTextBox
              display={useSelector(
                (state) =>
                  state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                    "#participants.contributors"
                  ]
              )}
              className="ml-0  position-static line-height-normal"
              for="#participants"
            >
              Please provide atleast one lyricist role
            </ValidationTextBox>
          </div>
        </div>
        {/* hr */}
        <br />

      <div className="utepHr2"></div>
      {/* rest of the form */}
      <div className="utepFormBelowHr p-relative">
        <div className="utepPrimaryGenreWrapper">
          <div
            className={
              "inputUpperComment playtreksSelector utiuc5 " +
              (reduxCurrentTrackForm?.["#primary_genre"] ? "" : "d-none")
            }
          >
            Primary Genre*
          </div>
          <SelectComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#primary_genre"
                ]
            )}
            disabled={true}
            commentField=".utiuc5"
            selectChangeCallback={() => {}}
            paddingTopOnInput={true}
            placeholder="Primary genre*"
            groupId="genre"
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon utepGenreInfo"
            img={info}
            imgClassName="nrAddonRightIcon utepGenreInfo"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            //
            onFocus={() => {}}
            selectBlurCallback={selectBlurCallback}
            options={genres}
            databaseField="#primary_genre"
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.uploadTrackPageGenre}
          ></SelectComponent>
        </div>
        <div className="utepSecondaryGenreWrapper">
          <div
            className={
              "inputUpperComment playtreksSelector utiuc5a " +
              (reduxCurrentTrackForm?.["#secondary_genre"] ? "" : "d-none")
            }
          >
            Secondary Genre
          </div>
          <SelectComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#secondary_genre"
                ]
            )}
            commentField=".utiuc5a"
            selectChangeCallback={selectChangeCallback}
            paddingTopOnInput={true}
            placeholder="Secondary genre"
            groupId="label"
            addon={false}
            onFocus={selectFocusCallback}
            selectBlurCallback={selectBlurCallback}
            options={genres}
            databaseField="#secondary_genre"
          ></SelectComponent>
          <ValidationTextBox
            display={useSelector(
              (state) =>
                state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                  "#secondary_genre"
                ]
            )}
            className="nrValTextBox pt-3"
            for="#secondary_genre"
          >
            Primary and secondary genres cannot be the same
          </ValidationTextBox>
        </div>
        <div className="utepLanguageWrapper">
          {currentTrack?.trackType === "lyrics" ? (
            <>
              <div
                className={
                  "inputUpperComment playtreksSelector utiuc5b " +
                  (reduxCurrentTrackForm?.["#language"] ? "" : "d-none")
                }
              >
                Language*
              </div>
              <SelectComponent
                value={getLanguageLabel()}
                commentField=".utiuc5b"
                selectChangeCallback={selectChangeCallback}
                selectBlurCallback={selectBlurCallback}
                paddingTopOnInput={true}
                placeholder="Language*"
                // groupId="label"
                addon={false}
                //
                options={languages}
                onFocus={selectFocusCallback}
                databaseField="#language"
              ></SelectComponent>
            </>
          ) : (
            ""
          )}
          <ValidationTextBox
            display={useSelector(
              (state) =>
                state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                  "#language"
                ]
            )}
            className="utValTextBox"
            for="#language"
            style={{ position: "absolute" }}
          >
            Please provide a language of your track
          </ValidationTextBox>
        </div>

        <div
          className={
            currentTrack?.trackType === "lyrics"
              ? "utepExplicitLyricsWrapper"
              : "noUtepExplicitLyricsWrapper"
          }
        >
          <div
            className="nrExplicitLyricsText utepExplicitLyricsText"
            onClick={handleUtSwitch}
            id="explicitLyrics"
            style={{ cursor: "pointer" }}
          >
            Explicit lyrics*
          </div>
          <div
            className="nrExplicitLyricsSwitch utepExplicitLyricsSwitch nrSwitch"
            onClick={handleUtSwitch}
            id="explicitLyrics"
          >
            <div
              className={
                "nrHandle " +
                (utSwitch.explicitLyrics ? "nrHandleOn" : "nrHandleOff")
              }
              id="explicitLyrics"
            ></div>
            {utSwitch.explicitLyrics ? (
              <div className="nrOn" id="explicitLyrics">
                Yes
              </div>
            ) : (
              <div className="nrOff" id="explicitLyrics">
                No
              </div>
            )}
          </div>
        </div>
        <div className="utepIsrcCodeWrapper">
          <div
            className={
              "inputUpperComment playtreksSelector utiuc6 " +
              (reduxCurrentTrackForm?.["#isrc"] ? "" : "d-none")
            }
          >
            ISRC Code*
          </div>
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#isrc"
                ]
            )}
            disabled={checked.isrcCodeCheckbox}
            commentField=".utiuc6"
            placeholder="ISRC Code*"
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "utepInput" +
              (reduxCurrentTrackForm?.["#isrc"]
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            paddingTopOnInput={true}
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            addonClickCallback={handleRemoveInput}
            databaseField="#isrc"
            maxlength={12}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.isrc}
          />
          <ValidationTextBox
            display={useSelector(
              (state) =>
                state.distributionReducer?.tracksValidation?.[trackIndex]?.[
                  "#isrc"
                ]
            )}
            className="utValTextBox utValTextBoxLower isrcValTextBox"
            for="#isrc"
          >
            Please provide a 12 digit ISRC code or tick the box above
          </ValidationTextBox>
        </div>
        <span id="isrcCodeCheckbox">
          <Checkbox
            className="utepIsrcCodeCheckbox"
            checked={checked.isrcCodeCheckbox}
            id="isrcCodeCheckbox"
            onClick={handleCheckbox}
          />
          <div
            className="utepIsrcCodeCheckboxText playtreksCheckboxText"
            id="isrcCodeCheckbox"
            onClick={handleCheckbox}
          >
            I don’t have one, assign an ISRC code to this track automatically
          </div>
        </span>
        <div className="utepIswcCodeWrapper">
          <div
            className={
              "inputUpperComment playtreksSelector utiuc7 " +
              (reduxCurrentTrackForm?.["#iswc"] ? "" : "d-none")
            }
          >
            ISWC Code
          </div>
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#iswc"
                ]
            )}
            disabled={checked.iswcCodeCheckbox}
            commentField={".utiuc7"}
            placeholder="ISWC Code"
            inputGroupClassName="nrInputGroup"
            inputClassName={
              "utepInput" +
              (reduxCurrentTrackForm?.["#iswc"]
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            paddingTopOnInput={true}
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            addonClickCallback={handleRemoveInput}
            databaseField="#iswc"
            maxlength={13}
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.iswc}
          />
        </div>
        <div className="utepPublishingRightsWrapper">
          <div
            className={
              "inputUpperComment playtreksSelector utiuc8 " +
              (reduxCurrentTrackForm?.["#p_year"] ? "" : "d-none")
            }
          >
            YYYY
          </div>
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#p_year"
                ]
            )}
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup nrcLeft"
            inputClassName={
              "utepInput text-center" +
              (reduxCurrentTrackForm?.["#p_year"]
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            commentField=".utiuc8"
            addon={false}
            placeholder="YYYY"
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            databaseField="#p_year"
            maxlength={4}
          ></InputComponent>
          <ValidationTextBox
            display={pYearVal || pLineVal || (pYearVal && pLineVal)}
            className="utValTextBox"
            for="#p_year, #p_line"
            style={{ position: "absolute", top: "58px" }}
          >
            Please provide a valid year and the holder of your copyright
          </ValidationTextBox>
          <div
            className={
              "inputUpperComment playtreksSelector utiuc9 " +
              (reduxCurrentTrackForm?.["#p_line"] ? "" : "d-none")
            }
          >
            ℗ copyright for sound recordings*
          </div>
          <InputComponent
            value={useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#p_line"
                ]
            )}
            paddingTopOnInput={true}
            inputGroupClassName="nrInputGroup nrcRight"
            inputClassName={
              "utepInput" +
              (reduxCurrentTrackForm?.["#p_line"]
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            commentField=".utiuc9"
            placeholder="℗ copyright for sound recordings*"
            changeCallback={inputChangeCallback}
            inputBlurCallback={inputBlurCallback}
            databaseField="#p_line"
            // addon
            addon="right"
            inputGroupTextClassName="nrRightAddon"
            img={info}
            imgClass="nrAddonRightIcon"
            alt="info"
            addonClickCallback={() => {}} // leave empty!
            // tooltip
            tooltip={true}
            tooltipText={tooltipData.copyright}
          ></InputComponent>
        </div>
        <div className="utepHr3"></div>
        <div className="utepLyricsWrapper">
          {currentTrack?.trackType === "lyrics" ? (
            <>
              <div className="inputUpperComment playtreksSelector utiuc10">
                Lyrics
              </div>
              <InputComponent
                value={lyricsValue}
                inputGroupClassName="nrInputGroup utepLyricsGroup"
                inputClassName="nrInput utepLyricsBox"
                addon={false}
                placeholder="Add lyrics..."
                changeCallback={inputChangeCallback}
                inputBlurCallback={inputBlurCallback}
                inputType="textarea"
                databaseField="_lyrics"
              ></InputComponent>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="utepOriginalFileName">Uploaded file name: </div>
        <span className="utepOriginalFileName-fileName ml-1">
          {originalFileName}.wav
        </span>

        <br />
        <div className="utepDeleteTrack" onClick={handleDeleteTrack}>
          <img alt="delete track" src={trash} style={{ marginRight: "8px" }} />
          {!deleteConfirm ? (
            <span>
              <u>Delete this track</u>
            </span>
          ) : (
            <span
              style={{
                color: "red",
                textDecorationColor: "red !important",
                textDecoration: "underline",
              }}
            >
              Are you sure?
            </span>
          )}
        </div>
        <div
          className={
            currentTrack?.trackType === "lyrics"
              ? "utepLicenseFiles"
              : "noUtepLicenseFiles"
          }
        >
          {currentTrack?.trackType === "lyrics" ? (
            <hr className="utepLicenseFiles-hr" />
          ) : (
            <hr className="noUtepLicenseFiles-hr" />
          )}
          <span className="utepLicenseFiles-headerText">
            <b>License files</b> <br />{" "}
            <a target="_blank" href="/distribution/licenseGuidelines">
              <u className="utepLicenseFiles-headerText-link">
                what type of license do I need to upload?
              </u>
            </a>
          </span>
          <div className="utepLicenseFiles-uploadFieldsWrapper">
            <button
              className="utepLicenseFiles-btn"
              id={"licenseFileBtn_track" + trackIndex + "_input" + 0}
              onClick={() => handleLicenseFileWrapper(0)}
            >
              {licenseStatus[0].loading ? (
                <img
                  alt="loading"
                  src={spinner}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : originalLicenseFileName(0) ? (
                "Change file"
              ) : (
                "Upload file"
              )}
            </button>
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              className="mt-1"
              onChange={(e) => handleLicenseFileUpload(e, 0)}
              placeholder="Upload file"
              hidden
              id={"licenseFileInput_track" + trackIndex + "_input" + 0}
            />
            &nbsp;
            <span className="utepLicenseFiles-err">{licenseStatus[0].err}</span>
            {originalLicenseFileName(0)}
            {originalLicenseFileName(0) && (
              <img
                alt={"delete license file"}
                src={trash}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "15px",
                  height: "15px",
                }}
                onClick={() => handleRemoveLicenseFile(0)}
              />
            )}
            <br />
            {/*  */}
            <button
              className="utepLicenseFiles-btn"
              onClick={() => handleLicenseFileWrapper(1)}
              id={"licenseFileBtn_track" + trackIndex + "_input" + 1}
            >
              {licenseStatus[1].loading ? (
                <img
                  alt="loading"
                  src={spinner}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : originalLicenseFileName(1) ? (
                "Change file"
              ) : (
                "Upload file"
              )}
            </button>
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              className="mt-1"
              onChange={(e) => handleLicenseFileUpload(e, 1)}
              placeholder="Upload file"
              hidden
              id={"licenseFileInput_track" + trackIndex + "_input" + 1}
            />
            &nbsp;
            <span className="utepLicenseFiles-err">{licenseStatus[1].err}</span>
            {originalLicenseFileName(1)}
            {originalLicenseFileName(1) && (
              <img
                alt={"delete license file"}
                src={trash}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "15px",
                  height: "15px",
                }}
                onClick={() => handleRemoveLicenseFile(1)}
              />
            )}
            <br />
            {/*  */}
            <button
              className="utepLicenseFiles-btn"
              onClick={() => handleLicenseFileWrapper(2)}
              id={"licenseFileBtn_track" + trackIndex + "_input" + 2}
            >
              {licenseStatus[2].loading ? (
                <img
                  alt="loading"
                  src={spinner}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : originalLicenseFileName(2) ? (
                "Change file"
              ) : (
                "Upload file"
              )}
            </button>
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              className="mt-1"
              onChange={(e) => handleLicenseFileUpload(e, 2)}
              placeholder="Upload file"
              hidden
              id={"licenseFileInput_track" + trackIndex + "_input" + 2}
            />
            &nbsp;
            <span className="utepLicenseFiles-err">{licenseStatus[2].err}</span>
            {originalLicenseFileName(2)}
            {originalLicenseFileName(2) && (
              <img
                alt={"delete license file"}
                src={trash}
                style={{
                  marginLeft: "10px",
                  cursor: "pointer",
                  width: "15px",
                  height: "15px",
                }}
                onClick={() => handleRemoveLicenseFile(2)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpandedPanel;
