export function getTotal(data, field, toFixed = false) {
  if (!data?.length) return "";

  let flatTotal = data?.map((doc) => doc[field]);

  let total = "";
  if (flatTotal?.length) total = flatTotal?.reduce((acc, v) => acc + v);

  return toFixed && total !== "" ? total.toFixed(2) : total;
}
