import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";
import { parseQueryParams } from "../services/utils";

export async function getUserSales({ queryKey }) {
  let [_key, queryParams] = queryKey;

  if (queryParams.group === "release") {
    queryParams.fields = "upc";
  }
  if (queryParams.group === "track") {
    queryParams.fields = "isrc";
  }

  let url = `${global.config.API_URL}/api/sales/userSales${parseQueryParams(
    queryParams
  )}`;

  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function requestPayout(amount, billingFormData, payPalEmail) {
  let url = `${global.config.API_URL}/api/sales/payoutRequest`;
  const parsedRequestedAmount = Number(`${amount}`.replace(",", ".")); // decimal sensitive fix

  return axios.post(
    url,
    {
      amount: parsedRequestedAmount,
      billingFormData: billingFormData,
      payPalEmail: payPalEmail,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function updateUserBillingInfo(billingFormData, payPalEmail) {
  let url = `${global.config.API_URL}/api/sales/userBillingInfo`;
  return axios.post(
    url,
    {
      billingFormData: billingFormData,
      payPalEmail: payPalEmail,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export async function getUserBillingInfo() {
  let url = `${global.config.API_URL}/api/sales/userBillingInfo`;
  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export async function adminGetAllUserSales() {
  let url = `${global.config.API_URL}/api/admin/sales`;
  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}
