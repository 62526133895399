import { getColorScheme } from "../utils";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function ColumnChart({
  data,
  colors = getColorScheme(),
  height = "500px",
  source,
  chartButtonsConfig = global.config.highchartButtons,
  xLabelsFormat,
  xLabelsType,
  yAxisValuePrefix,
}) {
  let innerWidth = window.innerWidth;

  const options = {
    colors: colors, // default color before colors get loaded and passed along with the data
    chart: {
      backgroundColor: "transparent",
      polar: true,
      type: "column",
      zoomType: "xy",
      panning: true,
      panKey: "shift",
      plotBorderWidth: 1,
      height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
        color: "white",
      },
      column: {
        color: "#0ef1db",
        borderWidth: 0,
        // pointWidth: 60,
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: true,
      xDateFormat: "%A, %b %e",
      //   valueSuffix: ` ${serviceText}`,
      formatter: function () {
        return "<b>" + this.points[0].key + "</b><br/> € " + this.y.toFixed(2);
      },
    },
    credits: {
      enabled: false,
    },
    series: data,
    xAxis: {
      left: innerWidth < 769 ? "5%" : undefined, // mobile
      width: innerWidth < 769 ? "95%" : undefined, // mobile
      type: xLabelsType || "linear",
      // opposite: true,
      // startOnTick: true,
      labels: {
        format: xLabelsFormat || "{value:%b %e}",
        // step: 2,
        style: {
          color: "#FFFFFF",
        },
      },
    },
    yAxis: {
      visible: true,
      lineWidth: 1,
      gridLineWidth: 0,
      reversed: false,
      title: {
        text: "",
      },
      labels: {
        useHTML: true,
        align: innerWidth < 769 ? "left" : "right", // only mobile
        x: innerWidth < 769 ? -5 : -8,
        style: {
          color: "#FFFFFF",
          backgroundColor: innerWidth < 769 ? "#33373f" : "", // mobile
          borderRadius: "10px",
          padding: "2px",
        },
        formatter: function () {
          return yAxisValuePrefix + this.value;
        },
      },
    },
    legend: {
      // itemStyle: {
      //   color: "#FFFFFF",
      //   cursor: "pointer",
      //   fontSize: "12px",
      //   fontWeight: "normal",
      //   textOverflow: "ellipsis",
      // },
      // itemHoverStyle: {
      //   color: "#FFFFFF",
      //   cursor: "pointer",
      //   fontSize: "13px",
      //   fontWeight: "bold",
      //   textOverflow: "ellipsis",
      // },
      enabled: false,
    },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      buttonPosition: {
        align: "left",
        x: innerWidth < 769 ? 5 : -10,
      },
      enabled: true,
      selected: 1,
      allButtonsEnabled: true,
      buttons: chartButtonsConfig,
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        style: {
          color: "#FFFFFF",
          fontWeight: "bold",
        },
        states: {
          hover: { fill: "#0ef1db" },
          select: {
            fill: "#0ef1db",
            style: {
              color: "white",
            },
          },
          // disabled: { ... }
        },
      },
      // floating: true,
      // y: -65,
      horizontalAlign: "center",
      labelStyle: { display: "none" },
      inputStyle: { display: "none" },
    },
  };

  // TODO: implement spinners
  if (!data?.length) return <></>;
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default ColumnChart;
