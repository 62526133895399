import jwtDecode from "jwt-decode";
import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

const endpoints = {
  updateTrackShareHolder: "/distribution/trackShareholders",
  resendCollaboratorMail: "/distribution/resendCollaboratorMail",
  deleteUserRelease: "distribution/user/releases",
  admin: {
    track: "admin/track",
    release: "admin/release",
    getSingleRelease: "admin/release",
  },
  associateContractToTrack: "/contracts/associateToTrack",
};

export const updateTrackShareholders = async (payload) => {
  let url = `${global.config.API_URL}/api/${endpoints.updateTrackShareHolder}`;
  return axios.put(url, payload, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const resendCollaboratorMail = async (payload) => {
  let url = `${global.config.API_URL}/api/${endpoints.resendCollaboratorMail}`;
  return axios.post(url, payload, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const updateTrack = async (payload) => {
  let url = `${global.config.API_URL}/api/${endpoints.admin.track}`;
  return axios.put(url, payload, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const updateRelease = async (payload) => {
  let url = `${global.config.API_URL}/api/${endpoints.admin.release}`;
  return axios.put(url, payload, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export const getRelease = async (releaseId) => {
  let url = `${global.config.API_URL}/api/${endpoints.admin.getSingleRelease}?releaseId=${releaseId}`;
  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};

export async function deleteUserRelease(releaseId) {
  let url = `${global.config.API_URL}/api/${endpoints.deleteUserRelease}/${releaseId}`;
  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}

export const associateContractToTrack = async (payload) => {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}${endpoints.associateContractToTrack}`;
  return axios.post(url, payload, {
    headers: { Authorization: "Bearer " + getJWT() },
  });
};
