import { ErrorMessage, Formik } from "formik";
import React from "react";
import ButtonComponent from "../../form/ButtonComponent";
import InputComponent from "../../form/InputComponent";
import { Spinner } from "../../../screens/Airplay/Airplay";
import { ContactValidationSchema } from "./ContactValidation";
import { createContact, updateContact } from "../../../services/contact";
import { useMutation } from "react-query";
import { useState } from "react";
import Toast from "../../Toast";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const ContactForm = ({ onClose, onUpdate, refetch, edit, contact }) => {
  const [showToast, setShowToast] = useState(initialToastState);

  const createContactMutation = useMutation(createContact);
  const updateContactMutation = useMutation(updateContact);

  const handleSubmit = (values, formActions) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    };

    if (edit) {
      updateContactMutation.mutate(
        { contactId: contact._id, ...payload },
        {
          onSuccess: () => {
            setShowToast({
              open: true,
              message: "Contact updated successfully",
              type: "success",
            });
            setTimeout(() => {
              formActions.setSubmitting(false);
              onUpdate({ isOpen: false });
            }, 1250);
            refetch();
          },
          onError: (e) => {
            formActions.setSubmitting(false);
            formActions.resetForm();
            setShowToast({
              open: true,
              message: e.response.data.message
                ? e.response.data.message
                : "An unexpected error occured. Please try again.",
              type: "danger",
            });
          },
        }
      );
    } else {
      createContactMutation.mutate(payload, {
        onSuccess: (response) => {
          setShowToast({
            open: true,
            message: "Contact has been created successfully",
            type: "success",
          });
          setTimeout(() => {
            formActions.setSubmitting(false);
            onUpdate(response.data.contact?.[0] || {});
          }, 1250);
          refetch();
        },
        onError: (e) => {
          formActions.setSubmitting(false);
          formActions.resetForm();
          setShowToast({
            open: true,
            message: e.response.data.message
              ? e.response.data.message
              : "An unexpected error occured. Please try again.",
            type: "danger",
          });
        },
      });
    }
  };

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <Formik
        enableReinitialize
        initialValues={{
          firstName: contact?.firstName ?? "",
          lastName: contact?.lastName ?? "",
          email: contact?.email ?? "",
        }}
        onSubmit={handleSubmit}
        validationSchema={ContactValidationSchema}
      >
        {(props) => {
          const {
            values: { firstName, lastName, email },
            dirty,
            isValid,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          } = props;

          return (
            <div className="row mx-2 left mt-2  contactsCard">
              <div className="contract-header p-3 mb-2">
                {edit ? "Edit contact" : "Create contact"}
              </div>
              {/* first name */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    (firstName ? "" : "d-none") + " inputUpperComment iuc1"
                  }
                >
                  First Name*
                </div>
                <InputComponent
                  value={firstName}
                  commentField=".iuc1"
                  placeholder="First Name*"
                  autofocus
                  inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100"
                  inputClassName={
                    "utepInput" +
                    (firstName ? " interMediumInput interMediumPaddingTop" : "")
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("firstName", value)}
                  inputBlurCallback={() => setFieldTouched("firstName", true)}
                />
              </div>

              <ErrorMessage
                name={"firstName"}
                component="div"
                className="w-100 text-danger line-height-normal ml-3 mb-2 fs-12 "
              />

              {/* last name */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    (lastName ? "" : "d-none") + " inputUpperComment iuc1a"
                  }
                >
                  Last Name*
                </div>
                <InputComponent
                  value={lastName}
                  commentField=".iuc1a"
                  placeholder="Last Name*"
                  inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100"
                  inputClassName={
                    "utepInput" +
                    (lastName ? " interMediumInput interMediumPaddingTop" : "")
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("lastName", value)}
                  inputBlurCallback={() => setFieldTouched("lastName", true)}
                />
              </div>
              <ErrorMessage
                name={"lastName"}
                component="div"
                className="w-100 text-danger line-height-normal ml-3 mb-2 fs-12 "
              />

              {/* email */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    (email ? "" : "d-none") + " inputUpperComment utiuc3_"
                  }
                >
                  Email*
                </div>
                <InputComponent
                  value={email}
                  commentField={".utiuc3_"}
                  placeholder="Email*"
                  inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100"
                  inputClassName={
                    "utepInput" +
                    (email ? " interMediumInput interMediumPaddingTop" : "")
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("email", value)}
                  inputBlurCallback={() => setFieldTouched("email", true)}
                />
              </div>
              <ErrorMessage
                name={"email"}
                component="div"
                className="w-100 text-danger line-height-normal ml-3 mb-2 fs-12 "
              />

              <div className="ml-0 row col justify-content-end mt-4 mb-4">
                <ButtonComponent
                  onClick={onClose}
                  className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  disabled={isSubmitting || !dirty || !isValid}
                  onClick={handleSubmit}
                  buttonWrapperClassName="pr-3"
                  className="playtreksButton w-100 h-100"
                >
                  {isSubmitting ? (
                    <>
                      <span className="mr-2">Saving... </span>
                      <span>
                        <Spinner />
                      </span>
                    </>
                  ) : (
                    "Save"
                  )}
                </ButtonComponent>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactForm;
