import React from "react";

function ErrorPopup(props) {
  if (props.error) {
    return (
      <div className={props.className + " errorPopup"} style={props.style}>
        {props.customText ? (
          props.customText
        ) : (
          <span>
            Something went wrong, <br /> please try again
          </span>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default ErrorPopup;
