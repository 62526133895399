import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

// export async function getTrackFeatures(
//   spotifyID,
//   { type = "track", feelingLucky = false }
// ) {
//   let url = `${global.config.API_URL}/api/track/get_audio_features?spotifyID=${spotifyID}&type=${type}&feelingLucky=${feelingLucky}`;
//   return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
// }

export async function getFeaturedEntity() {
  let url = `${global.config.API_URL}/api/track/featured_entity`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
}
