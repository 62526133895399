import React from "react";
import UnCheckedCircle from "../../../assets/icons/check-circle-unchecked.svg";
import CheckedCircle from "../../../assets/icons/check-circle.svg";
import "./index.scss";

function SocialRadioItem({ className, checked, children, handleChange }) {
  return (
    <div className={`social-radio-icon ${className}`} onClick={handleChange}>
      {children}
      <img src={checked ? CheckedCircle : UnCheckedCircle} alt={"icon"} />
    </div>
  );
}
export default SocialRadioItem;
