import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "react-query";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { setUserSales } from "../../../redux/actions/distributionActions";

// project dependencies
import ProfileHeader from "../../artistDashboard/Profile/components/ProfileHeader";
import {
  getAndValidateFilterValuesFromQuery,
  getAndValidateGroupValue,
  updateQueryString,
} from "./components/lib/query";
import { getFilterValue } from "./components/lib/filters";
import "./index.scss";

// aggregation table components
import DateRangeAggregation from "./components/DateRangeAggregation";
import DspAggregation from "./components/DspAggregation";
import CountryAggregation from "./components/CountryAggregation";
import ReleaseAggregation from "./components/ReleaseAggregation";
import ArtistAggregation from "./components/ArtistAggregation";
import TrackAggregation from "./components/TrackAggregation";

// filter component
import Filters from "./components/Filters";
import Groupings from "./components/Groupings";
import NoDataAggregation from "./components/NoDataAggregation";

// assets
import spinner from "../../../assets/spinner.svg";
import {
  getAllNewUsersSales,
  getMyEarningsFilters,
} from "../../../services/admin/sales";
import UserSearch from "../../admin/components/AdminUserSearch";
import { getUserSales } from "../../../routes/sales";
import UserAggregation from "./components/UserAggregation";

// aggregation types (groupings)
const DATE_RANGE = "date_range";
const DSP = "dsp";
const COUNTRY = "country";
const RELEASE = "release";
const TRACK = "track";
const ARTIST = "artist";
const USER = "user";

// filter types
const FROM_DATE_RANGE_FILTER = "from_date_range_filter";
const UNTIL_DATE_RANGE_FILTER = "until_date_range_filter";
const MUSIC_RELEASES_FILTER = "music_releases_filter";
const MUSIC_TRACKS_FILTER = "music_tracks_filter";
const MUSIC_ARTISTS_FILTER = "music_artists_filter";
const STORES_FILTER = "stores_filter";
const COUNTRIES_FILTER = "countries_filter";
const AVG_DATE_LOCKED_FILTER = "avg_date_locked_filter";
const INCLUDE_COLLAB_TRACKS = "include_collab_tracks";

const queryConfig = {
  refetchOnWindowFocus: false,
  cacheTime: 3_600_000, //milliseconds
  refetchOnMount: false,
};

function MyEarnings({ isAdmin = false }) {
  const {
    isLoading: areFiltersLoading,
    data: {
      data: filters = {
        artists: [],
        releases: [],
        tracks: [],
        channels: [],
        countries: [],
      },
    } = {},
  } = useQuery(
    ["myearningsFilters"],
    getMyEarningsFilters.bind(this, isAdmin),
    queryConfig
  );

  // SETUP //
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [userFilter, setUserFilter] = useState([]);
  const [isRevenueChecked, setIsRevenueChecked] = useState(false);
  const [avgDateLocked, setAvgDateLocked] = useState(true);

  const handleRadioChange = (e) => {
    setAvgDateLocked(e.target.value === "locked");
    setCurrentFilters({
      ...currentFilters,
      [AVG_DATE_LOCKED_FILTER]: e.target.value === "locked",
    });
  };

  const handleRevenueChange = (e) => {
    setIsRevenueChecked(e.target.checked);
    setCurrentFilters({
      ...currentFilters,
      [INCLUDE_COLLAB_TRACKS]: e.target.checked,
    });
  };

  const filterContentObject = {
    storesFilterContent: filters?.channels,
    countriesFilterContent: filters?.countries,
    releasesFilterContent: filters?.releases,
    tracksFilterContent: filters?.tracks,
    artistsFilterContent: filters?.artists,
  };

  // AGGREGATIONS / GROUPINGS //
  const [currentFilters, setCurrentFilters] = useState({
    [FROM_DATE_RANGE_FILTER]:
      Number(getFilterValue(query, FROM_DATE_RANGE_FILTER)) || 0,
    [UNTIL_DATE_RANGE_FILTER]:
      Number(getFilterValue(query, UNTIL_DATE_RANGE_FILTER)) || 0,
    [AVG_DATE_LOCKED_FILTER]: avgDateLocked,
    [INCLUDE_COLLAB_TRACKS]: isRevenueChecked,
    [MUSIC_RELEASES_FILTER]: getFilterValue(query, MUSIC_RELEASES_FILTER) || 0,
    [MUSIC_TRACKS_FILTER]: getFilterValue(query, MUSIC_TRACKS_FILTER) || 0,
    [MUSIC_ARTISTS_FILTER]: getFilterValue(query, MUSIC_ARTISTS_FILTER) || 0,
    [STORES_FILTER]: getFilterValue(query, STORES_FILTER) || 0,
    [COUNTRIES_FILTER]: getFilterValue(query, COUNTRIES_FILTER) || 0,
  });

  // current aggregation switch
  const [currentAggregation, setCurrentAggregation] = useState(
    getAndValidateGroupValue(query) || DATE_RANGE
  );

  const {
    isLoading: isNewAdminSalesDataLoading,
    data: { data: newAdminUsersSales } = {},
  } = useQuery(
    [
      "newAdminUsersSales",
      {
        group: currentAggregation,
        user_filter: userFilter,
        ...currentFilters,
      },
    ],
    getAllNewUsersSales,
    {
      ...queryConfig,
      enabled: isAdmin ? true : false,
    }
  );

  const { isLoading: isSalesDataLoading, data: { data: usersSales } = {} } =
    useQuery(
      [
        "userSales",
        {
          group: currentAggregation,
          ...currentFilters,
        },
      ],
      getUserSales,
      {
        ...queryConfig,
        enabled: isAdmin ? false : true,
      }
    );

  // aggregation table display
  function AggregationDisplayComponent() {
    // spinner
    let AggSpinner = () => (
      <div className="w-100 text-center mt-4">
        <img src={spinner} style={{ height: "15%" }} />
      </div>
    );

    // if no data is found, display the no data msg, otherwise a spinner
    if (isSalesDataLoading || isNewAdminSalesDataLoading || areFiltersLoading) {
      return <AggSpinner />;
    }

    if (!usersSales?.length && !isAdmin) {
      return <NoDataAggregation />;
    }

    if (!newAdminUsersSales?.length && isAdmin) {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            marginTop: "5rem",
            fontSize: "1.5rem",
          }}
        >
          no data found
        </div>
      );
    }

    switch (currentAggregation) {
      case DATE_RANGE:
        return (
          <DateRangeAggregation
            newUsersSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
            isAdmin={isAdmin}
          />
        );
      case DSP:
        return (
          <DspAggregation
            newUsersSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
            isAdmin={isAdmin}
          />
        );
      case COUNTRY:
        return (
          <CountryAggregation
            newUsersSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
            isAdmin={isAdmin}
          />
        );
      case RELEASE:
        return (
          <ReleaseAggregation
            userSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
          />
        );
      case ARTIST:
        return (
          <ArtistAggregation
            userSalesData={isAdmin ? newAdminUsersSales : usersSales}
            isAdmin={isAdmin}
            loading={isNewAdminSalesDataLoading}
          />
        );
      case TRACK:
        return (
          <TrackAggregation
            userSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
          />
        );

      case USER:
        return (
          <UserAggregation
            loading={isNewAdminSalesDataLoading}
            newUsersSalesData={isAdmin ? newAdminUsersSales : null}
            isAdmin={isAdmin}
          />
        );
      default:
        return (
          <DateRangeAggregation
            newUsersSalesData={isAdmin ? newAdminUsersSales : usersSales}
            loading={isNewAdminSalesDataLoading}
            isAdmin={isAdmin}
          />
        );
    }
  }

  useEffect(() => {
    // update query string
    updateQueryString(currentFilters, currentAggregation, history, location);
  }, [currentFilters, currentAggregation]);

  // validate query string contents
  useEffect(() => {
    let filterContentPresent = Object.values(filterContentObject).flat().length;
    if (filterContentPresent) {
      let validatedFilters = getAndValidateFilterValuesFromQuery(
        query,
        currentFilters,
        filterContentObject
      );
      if (JSON.stringify(currentFilters) !== JSON.stringify(validatedFilters))
        setCurrentFilters(validatedFilters);
    }
  }, [filterContentObject]);

  return (
    <>
      {isAdmin && (
        <>
          <div className="adminRoyalties-display-userSearch">
            <small>Admin filter by users:</small>
            <UserSearch userFilter={userFilter} setUserFilter={setUserFilter} />
          </div>
        </>
      )}

      <main className={isAdmin ? "" : "my-earnings"}>
        {isAdmin ? "" : <ProfileHeader label="Revenue report" />}
        {isAdmin ? (
          ""
        ) : (
          <div
            className="rounded transactions-sub-heading text-white mx-3 p-3"
            style={{ backgroundColor: "#2c313a" }}
          >
            The revenue report shows you the total net revenue generated by your
            music. Note that the amounts shown reflect the total income. If you
            are sharing your royalties with a label and/or with multiple rights
            holders, your personal earnings may be lower than the amounts
            displayed in this report.
          </div>
        )}
        <section className="my-earnings-section my-earnings-section-1">
          <div className="adminRoyalties-display-userSearch mb-3">
            {isAdmin && (
              <div>
                <input
                  disabled={userFilter.length === 0}
                  type="checkbox"
                  id="revenue"
                  checked={isRevenueChecked}
                  onChange={handleRevenueChange}
                  className="custom-checkbox"
                />
                <label htmlFor="revenue" className="ml-1">
                  <span>
                    Include revenue for tracks where user is invited as a rights
                    holder
                  </span>
                </label>

                <br />
              </div>
            )}
            <input
              type="radio"
              id="avg-date-locked"
              name="avg-date"
              value="locked"
              checked={avgDateLocked}
              onChange={handleRadioChange}
            />
            <label htmlFor="avg-date-locked">
              <span>
                View as Account periods{" "}
                <span className="text">
                  (reporting when sales were confirmed)
                </span>
              </span>
            </label>
            <br />
            <input
              type="radio"
              id="avg-date-unlocked"
              name="avg-date"
              value="unlocked"
              checked={!avgDateLocked}
              onChange={handleRadioChange}
            />
            <label htmlFor="avg-date-unlocked">
              <span>
                View as Sales periods{" "}
                <span className="text">(reporting when streams were made)</span>
              </span>
            </label>
          </div>
          <small>Filter by:</small>

          <Filters
            currentFilters={currentFilters}
            filters={filters}
            setCurrentFilters={setCurrentFilters}
          />

          <small>Group by:</small>
          <div className="my-earnings-section-1-aggregationPicker">
            <Groupings
              currentAggregation={currentAggregation}
              setCurrentAggregation={setCurrentAggregation}
              isAdmin={isAdmin}
            />
          </div>
          <br />
          <AggregationDisplayComponent />
        </section>
      </main>
    </>
  );
}

export default MyEarnings;
