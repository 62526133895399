import React, { memo } from "react";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../../screens/Airplay/Airplay";
import ButtonComponent from "./ButtonComponent";

const ExportCSVButton = memo(({ loading, onClick }) => {
  return (
    <ButtonComponent
      disabled={loading}
      onClick={onClick}
      buttonWrapperClassName="pr-3"
      className="playtreksButton w-100 h-75"
    >
      <FontAwesomeIcon
        icon={faFileCsv}
        style={{ opacity: "0.3" }}
        className="mr-2"
      />
      {loading ? (
        <>
          Downloading...
          <span className="ml-2">
            <Spinner />
          </span>
        </>
      ) : (
        "Export CSV"
      )}
    </ButtonComponent>
  );
});

export default ExportCSVButton;
