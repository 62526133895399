import React, { useState, useEffect } from "react";
import "./onboarding.scss";
import "../../assets/scss/pt/forms.scss";

import ButtonComponent from "../form/ButtonComponent";

import Footer from "./components/FooterComponent";

import ErrorPopup from "../error/ErrorPopup";
import ObProgressCircles from "./components/ObProgressCircles";
import ObDecorations from "./components/ObDecorations";
import ConnectedPlatforms from "./components/ConnectedPlatforms";
import Stepper from "../Stepper";

function Onboarding1() {
  window.localStorage.setItem("playtreksCurrentStep", window.location.pathname);

  // If the user successfully connects an account, we come back to this same page.
  window.localStorage.setItem("playtreksNextStep", window.location.pathname);

  function handleNext() {
    window.location.replace("/onboarding2");
  }

  const steps = [
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
  ];

  const color = "#15161c";
  const activeColor = "#0ef1db";

  return (
    <>
      <ObDecorations />
      <div className="obCenterBox obCenterBox-1">
        <div className="obModal obModal-1 mb-5">
          <Stepper
            steps={steps}
            currentStep={0}
            containerClasses={"oboarding-container"}
            color={color}
            activeColor={activeColor}
            lineColor={color}
            finishLineColor={activeColor}
          />
          <br />
          <div className="obModal-Header">connect your accounts</div>
          <div className="obModal-sub mt-2 mb-4">
            To get the full picture, connect your accounts
          </div>
          <ConnectedPlatforms />
          <br />
          <br />
          {/* <div className="obSkip" onClick={() => "/onboarding2"}>
            Skip this step
          </div> */}
          <ButtonComponent
            className="obNextButton obNextButton-1"
            onClick={handleNext}
          >
            Next
          </ButtonComponent>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Onboarding1;
