import React, { useState } from "react";
import X from "../assets/X.svg";

function EmailVerificationModal({
  closeModalCallback,
  handleResendVerification,
  verificationResent,
}) {
  return (
    <div>
      <div className="playtreksModal-overlay">
        <div className="playtreksModal-modal">
          <div className="playtreksModal-emailVerification">
            <img
              src={X}
              alt="close"
              className="playtreksModal-modal-close playtreksModal-modal-close-absolute"
              onClick={closeModalCallback}
            />
            <div className="playtreksModal-modal-header">
              email verification
            </div>
            <br />
            hi there, in order to complete your release we need you to verify
            your account by clicking on the link we've sent to your email.
            <br />
            please complete the verification process and try again. <br />
            <br />
            <div
              className="customAlert-resend playtreksModal-resendBtn"
              onClick={handleResendVerification}
            >
              {verificationResent ? "sent! ✓" : "resend activation link ↺"}
            </div>
            <div className="playtreksModal-resendBtn-comment">
              Note: Clicking on the activation link will log you out, but your
              release is saved as a draft and you can come back to it later.{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerificationModal;
