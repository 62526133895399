module.exports = global.config = {
  ssoAppDomain: process.env.REACT_APP_SSO_COOKIE_DOMAIN,
  searchQueryDelay: 500,
  textSearchLimit: 10,
  graphColorScheme: "nivo", // https://nivo.rocks/guides/colors <= list of colors
  API_URL:
    process.env.REACT_APP_NODE_ENV === "development"
      ? "http://localhost:48258"
      : process.env.REACT_APP_NODE_ENV === "test"
      ? "http://localhost:48259"
      : "",
  playlistsPerPage: 10,
  axiosMaxRetries: 2,
  REACT_APP_STRIPE_PK:
    process.env.REACT_APP_STRIPE_PK ||
    "pk_test_51HZueWK1PWXHBavdEufcr0kfAeZMQ6ltIRndTZ7HeRyo3UPyQK7RLLehelloPvFGYfwl3IQ0n0Do8ICz1RAyMtPI00u6qerNaR",
  AUTH_REDIRECT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL,
  gradientStretch: 10,
  highchartButtons: [
    {
      type: "month",
      count: 1,
      text: "1m",
      title: "View 1 month",
    },
    {
      type: "month",
      count: 3,
      text: "3m",
      title: "View 3 months",
    },
    {
      type: "month",
      count: 6,
      text: "6m",
      title: "View 6 months",
    },
    {
      type: "ytd",
      text: "YTD",
      title: "View year to date",
    },
    {
      type: "year",
      count: 1,
      text: "1y",
      title: "View 1 year",
    },
    {
      type: "all",
      text: "All",
      title: "View all",
    },
  ],

  // colors to be modified in 'content.scss', 'App.scss' (scss variables), Dropdown.js:208
  // landing page values to be modified in LandingPage.js
};
