import React, { useEffect, useState, useCallback, useMemo } from "react";
import moment from "moment";
import SpotifyChartTable, {
  Label,
  Track,
  Trend,
} from "./components/SpotifyChartTable";
import axios from "../../../lib/axios_retry_wrapper";
import { cancelRequest } from "../../../services/utils";
import Countries from "../../../shared/countries.json";
import {
  downloadSpotifyChartsCSV,
  getSpotifyCharts,
} from "../../../services/charts/spotify";
import CalendarComponent from "../../../components/form/CalendarComponent";
import InputComponent from "../../../components/form/InputComponent";
import calendar from "../../../assets/icons/calendar.svg";
import SelectComponent from "../../../components/form/SelectComponent";
import ExportCSV from "../../../components/form/ExportCSVButton";
import useLocalStorage from "../../../hooks/useLocalStorage";
import SpotifyChartAggregations from "./components/SpotifyChartAggregations";
import "./index.scss";
import ButtonComponent from "../../../components/form/ButtonComponent";

const countriesOptions = Object.entries(Countries).reduce(
  (accumulator, current) => {
    accumulator[current[1]] = {
      label: current[0],
      value: current[1],
    };
    return accumulator;
  },
  {}
);

const chartsList = {
  regional: { label: "Top 200", value: "regional" },
  viral: { label: "Viral 50", value: "viral" },
};
const defaultChartName = { label: "Top 200", value: "regional" };
const defaultCountry = { label: "Global", value: "global" };
const defaultChartDate = moment().subtract(2, "days").format("YYYY-MM-DD");

let sportifyChartsRequestToken;
const formatNumber = new Intl.NumberFormat().format;
const defaultColumns = [
  {
    Header: "Position",
    accessor: "position",
  },
  {
    Header: "Change",
    accessor: "diff_position",
    Cell: ({ value }) => <Trend diff={value} />,
  },
  {
    Header: "Track",
    accessor: (originalRow) => originalRow,
    Cell: ({ value }) => <Track trackData={value} />,
  },
  {
    Header: "Label",
    accessor: (originalRow) => originalRow,
    Cell: ({ value }) => <Label labelName={value} />,
  },
  {
    Header: "Streams",
    accessor: "streams",
    Cell: ({ value }) => formatNumber(value),
  },
  {
    Header: "Chng. In streams",
    accessor: "diff_streams",
    Cell: ({ value }) => <Trend diff={value} />,
  },
];

const AGG_BY_ARTISTS = "aggByArtist";
const AGG_BY_LABELS = "aggByLabel";

const SpotifyCharts = () => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [chartData, setChartData] = useState({});
  const [openChartDatePicker, setOpenChartDatePicker] = useState(false);
  const [chartPlatform, setChartPlatform] = useState("Spotify");
  const [aggregateBy, setAggregateBy] = useState(AGG_BY_LABELS);
  const [chartDate, setChartDate] = useLocalStorage(
    "chartDate",
    defaultChartDate
  );
  const [chartName, setChartName] = useLocalStorage(
    "chartName",
    defaultChartName
  );
  const [countryFilter, setCountryFilter] = useLocalStorage(
    "chartCountry",
    defaultCountry
  );

  /**
   * Cancels any pending requests on unmounting
   */
  useEffect(
    () => () => {
      cancelRequest(sportifyChartsRequestToken);
    },
    []
  );

  useEffect(() => {
    fetchCharts();
  }, []);

  /**
   * Effect to fetch airplays of main entity
   * when the dependencies change
   */
  useEffect(() => {
    fetchCharts();
  }, [chartName.label, countryFilter.label, chartDate]);

  const fetchCharts = async () => {
    try {
      setLoading(true);

      //Cancel if there are any previous pending requests
      cancelRequest(sportifyChartsRequestToken);

      //Create new token for the current request
      sportifyChartsRequestToken = axios.CancelToken.source();

      let queryParams = {
        chart_name: chartName.value,
        chart_country_code: countryFilter.value,
        chart_date: chartDate,
      };

      const response = await getSpotifyCharts(
        queryParams,
        sportifyChartsRequestToken.token
      );

      if (response?.data) {
        setChartData(response.data);
      }
      setLoading(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        setLoading(false);
      }
    }
  };

  const handleFilterChange =
    (field) => (value, dbField, commentField, databaseKey, type) => {
      if (type?.action !== "input-change") {
        if (field === "chartName") setChartName(chartsList[value]);
        else setCountryFilter(countriesOptions[value]);
      }
    };

  const datePickCallback = useCallback((date) => {
    setChartDate(date);
  }, []);

  const handleOpenCalendar = useCallback(() => {
    setOpenChartDatePicker((prevState) => !prevState);
  }, []);

  const handleExportSpotifyCharts = () => {
    setDownloading(true);

    const queryParams = {
      chart_name: chartName.value,
      chart_country_code: countryFilter.value,
      chart_date: chartDate,
    };

    const fileName = `${chartPlatform}_${chartName.label}_${countryFilter.label}_${chartDate}`;

    downloadSpotifyChartsCSV(queryParams)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
      })
      .catch((e) => {
        setDownloading(false);
      });
  };

  const handleSetCurrentAggregation = (aggBy) => () => {
    setAggregateBy(aggBy);
  };

  const columns = useMemo(() => {
    if (chartName.value === "viral") {
      return defaultColumns.filter(
        (column) => !["streams", "diff_streams"].includes(column.accessor)
      );
    }
    return defaultColumns;
  }, [chartName.value]);

  const aggregationData = useMemo(
    () => chartData[aggregateBy],
    [chartData, aggregateBy]
  );

  return (
    <section className="dashboard-airplay-container">
      {/* Export CSV */}
      <div className="row justify-content-end align-items-center">
        <ExportCSV loading={downloading} onClick={handleExportSpotifyCharts} />
      </div>

      {/* Filters */}
      <div className="col p-0">
        <div className="row text-white mx-0">Filter by:</div>
        <div className="row">
          {/* Platform */}
          <div
            className="col col-lg-3 py-2"
            style={{ opacity: 0.5, pointerEvents: "none" }}
          >
            <div className="inputUpperComment iuc3 playtreksSelector">
              Platform
            </div>
            <SelectComponent
              disabled
              value={chartPlatform}
              valuePaddingTop={true}
              placeholderTop="50%"
              placeholderFont="Inter-Medium"
              placeholderColor="white"
              placeholderOpacity="1"
              customNoOptionsMessage={<p classNam="ml-2">No options</p>}
              selectChangeCallback={handleFilterChange("chartName")} // to be used for data transfer
              selectBlurCallback={() => {}} // leave empty
            />
          </div>

          <div className="col col-lg-3 py-2">
            <div className="inputUpperComment iuc3 playtreksSelector">
              Chart
            </div>
            <SelectComponent
              value={chartName.label}
              valuePaddingTop={true}
              placeholderTop="50%"
              placeholderFont="Inter-Medium"
              placeholderColor="white"
              placeholderOpacity="1"
              options={Object.values(chartsList)}
              customNoOptionsMessage={<p classNam="ml-2">No options</p>}
              selectChangeCallback={handleFilterChange("chartName")} // to be used for data transfer
              selectBlurCallback={() => {}} // leave empty
            />
          </div>
          <div className={"col col-lg-3 py-2 mt-0 text-white"}>
            <div className="inputUpperComment iuc3 playtreksSelector">
              Country
            </div>
            <SelectComponent
              value={countryFilter.label}
              valuePaddingTop={true}
              placeholderTop="50%"
              placeholderFont="Inter-Medium"
              placeholderColor="white"
              placeholderOpacity="1"
              options={Object.values(countriesOptions)}
              customNoOptionsMessage={<p classNam="ml-2">No options</p>}
              selectChangeCallback={handleFilterChange("countryFilter")} // to be used for data transfer
              selectBlurCallback={() => {}} // leave empty
            />
          </div>
          <div className="col col-lg-3 py-2">
            <div className=" position-relative">
              <div
                className={"inputUpperComment iuc6 " + (chartDate || "d-none")}
              >
                Chart date
              </div>
              <InputComponent
                disabled={true}
                value={chartDate} // take from calendar, or pre-populate
                paddingTopOnInput={true}
                inputGroupClassName="nrInputGroup"
                inputClassName={
                  "nrInput nrInput-opacityFix " +
                  (chartDate || chartDate.length
                    ? "nrCalendarInputPopulated"
                    : "")
                }
                commentField=".iuc6"
                placeholder="Chart date"
                addonClickCallback={handleOpenCalendar}
                for="nrReleaseDate"
                id="chartDate"
                // calendar addon
                addon="right"
                calendarAddon={true}
                calImg={calendar}
                calImgClass="nrAddonRightIcon"
                calAlt="pick date"
                inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                databaseField="chartDate"
              />
              <CalendarComponent
                calendarState={openChartDatePicker}
                calendarClassName="nrReleaseDateCalendar"
                datePickCallback={datePickCallback}
                calendarId="chartDate"
                databaseField="chartDate"
                shouldCloseOnSelect={true}
                maxDate={new Date()}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Aggregations */}
      <div className="dashboard-airplay">
        <div className="my-earnings-section-1-aggregationPicker py-2 ml-5">
          <ButtonComponent
            onClick={handleSetCurrentAggregation(AGG_BY_ARTISTS)}
            className={
              "playtreksButton h-100 " +
              (aggregateBy === AGG_BY_ARTISTS ? "groupBtnActive" : "")
            }
          >
            Top Artists
          </ButtonComponent>

          <ButtonComponent
            onClick={handleSetCurrentAggregation(AGG_BY_LABELS)}
            className={
              "playtreksButton h-100 " +
              (aggregateBy === AGG_BY_LABELS ? "groupBtnActive" : "")
            }
          >
            Top Labels
          </ButtonComponent>
        </div>
        <SpotifyChartAggregations loading={loading} data={aggregationData} />
      </div>

      {/* Charts */}
      <SpotifyChartTable
        loading={loading}
        data={chartData?.results}
        columns={columns}
      />
    </section>
  );
};

export default SpotifyCharts;
