import React, { useEffect, useRef, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import CaretDownIcon from "../../assets/icons/icon-caret-down.svg";
import DefaultUserIcon from "../../assets/icons/user.svg";
import { getJWT } from "../utils";

function UserProfile({ name, avatar }) {
  const [profileOpen, setProfileOpen] = useState(false);
  const node = useRef();
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setProfileOpen(false);
  };

  const reduxUsername = useSelector(
    (state) =>
      state.dashboardReducer?.userData?.firstname ||
      state.dashboardReducer?.userData?.email
  );
  const reduxUserImg = useSelector(
    (state) => state.dashboardReducer?.userData?.profilePicture
  );

  const jwtToken = getJWT();

  useEffect(() => {
    if (profileOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileOpen]);

  return (
    <div
      className="user-profile"
      style={{ cursor: "pointer" }}
      ref={node}
      onClick={(e) => setProfileOpen(!profileOpen)}
    >
      {/* <label className="user-profile-name">{reduxUsername}</label> */}
      <img
        src={reduxUserImg || DefaultUserIcon}
        className="user-profile-avatar user-profile-avatar-desktop"
        alt="avatar"
        onClick={(e) => setProfileOpen(!profileOpen)}
      />
      <Link to="/artistDashboard/profile">
        <img
          src={reduxUserImg || DefaultUserIcon}
          className="user-profile-avatar user-profile-avatar-mobile"
          alt="avatar"
        />
      </Link>
      <img
        src={CaretDownIcon}
        className={`user-profile-caret ${profileOpen ? "up" : ""} `}
        alt="caret"
        onClick={(e) => setProfileOpen(!profileOpen)}
      />
      <div className={`user-profile-dropdown ${profileOpen ? "show" : ""} `}>
        <ul>
          <li>
            <Link to={`/artistDashboard/profile`}>
              <div className="user-profile-dropdown-inner">
                Account settings
              </div>
            </Link>
          </li>

          <li>
            <a href="https://marketplace.playtreks.com/wallet">
              <div className="user-profile-dropdown-inner">Wallet details</div>
            </a>
          </li>
          <li>
            <a href={`${global.config.AUTH_REDIRECT_URL}/logout`}>
              <div className="user-profile-dropdown-inner">Log out</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default UserProfile;
