import React, { useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import TableView from "../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../admin/ReleasesAdmin";
import { useQuery } from "react-query";
import { getContractBalanceByPeriods } from "../../../services/contracts";

import { useParams } from "react-router-dom";
import SelectComponent from "../../form/SelectComponent";
import { monthMap, periods } from "../../../shared/periods";
import moment from "moment";

const INITIAL_PAGE_SIZE = 25;

const ContractBalance = () => {
  const { contractId } = useParams();
  const [selectedPeriodId, setSelectedPeriodId] = useState(null);

  const { data: contractBalance, isLoading: isContractBalanceLoading } =
    useQuery(["contractBalance", selectedPeriodId], () =>
      getContractBalanceByPeriods(contractId, selectedPeriodId)
    );

  const contractBalances = React.useMemo(
    () => contractBalance?.data?.data ?? [],
    [contractBalance]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "endDate",
        Cell: ({ value }) => moment(value).format("YYYY-MM"),
      },
      {
        Header: "Total credit",
        accessor: "creditTotal",
        Cell: ({ value }) => {
          return <div className="font-weight-bolder text-primary">{value}</div>;
        },
      },
      {
        Header: "Total debit",
        accessor: "debitTotal",
        Cell: ({ value }) => {
          return (
            <div className="font-weight-bolder" style={{ color: "red" }}>
              {value}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "_id",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: contractBalances,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["_id"],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const periodOptions = [
    { label: "All", value: null },
    ...periods.map((p) => ({
      label: ` ${
        monthMap[new Date(p.endDate.$date).getMonth() + 1]
      }, ${new Date(p.endDate.$date).getFullYear()}`,
      value: p._id.$oid,
    })),
  ];

  const PeriodFilterOptions = ({ setSelectedPeriodId, selectedPeriodId }) => {
    return (
      <div
        className={
          "nrAlbumTitleInputWrapper col-12 col-md-4 col-lg-3 py-2 py-md-0 text-white"
        }
        id={"month"}
      >
        <div className="inputUpperComment iuc3 playtreksSelector">Period</div>
        <SelectComponent
          value={periodOptions.find((o) => o.value === selectedPeriodId) || ""}
          extendOptionWidth
          id={"month"}
          valuePaddingTop={true}
          placeholderTop="50%"
          placeholderFont="Inter-Medium"
          placeholderColor="white"
          placeholderOpacity="1"
          options={Object.values(periodOptions)}
          customNoOptionsMessage={<p classNam="ml-2">No options</p>}
          selectChangeCallback={(
            val,
            databaseField,
            commentField,
            databaseKey,
            type
          ) => {
            if (type.action !== "input-change") setSelectedPeriodId(val);
          }}
          // to be used for data transfer
          selectBlurCallback={() => {}} // leave empty
        />
      </div>
    );
  };

  return (
    <>
      <div className="allowUserSelect">
        <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
          {/* <AmountCard transactions={rhBalances} /> */}
          <div className="d-block mb-3" style={{ zIndex: 1 }}>
            <p className="font-weight-bold text-white mb-2">Filter by:</p>
            <div className="row pb-2 align-items-center justify-content-between">
              <div className="col-12 col-md">
                <div className="row">
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                  <PeriodFilterOptions
                    selectedPeriodId={selectedPeriodId}
                    setSelectedPeriodId={setSelectedPeriodId}
                  />
                </div>
              </div>
            </div>
          </div>

          <TableView
            tableInstance={tableInstance}
            loading={isContractBalanceLoading}
          />
        </main>
      </div>
    </>
  );
};

export default ContractBalance;
