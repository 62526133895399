import React from "react";
import { ResponsiveBar } from "@nivo/bar";

function Bar(props) {
  const width = props.width || 500;
  const height = props.height || 50;
  return (
    <ResponsiveBar
      {...props}
      width={width}
      height={height}
      layout="horizontal"
      colors={(d) => d.data[d.id + "Color"]}
      pointSize={10}
      pointColor={{ theme: "background" }}
      borderColor={{ theme: "background" }}
      animate={true}
    />
  );
}
export default Bar;
