import React from "react";

function SpotifyArtistSearchDropdown(props) {
  if (props.artists[0]) {
    return (
      <div className="obSpotifyConnectDropdown">
        {props.artists.map((artist) => (
          <div
            className="spCnItem"
            onMouseDown={() =>
              props.handleItemMouseDown(
                artist.name,
                artist.images[0]?.url,
                artist.id
              )
            }
          >
            {artist.name}
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export default SpotifyArtistSearchDropdown;
