import {
  sevendigital,
  amazon,
  anghami,
  audible,
  awa,
  boomplay,
  claromusica,
  deezer,
  facebook,
  gracenote,
  iheart,
  imi,
  itunes,
  jaxsta,
  jiosaavn,
  kdigital,
  kkbox,
  mixcloud,
  monkingme,
  netease,
  pandora,
  resso,
  rhapsodynapster,
  shazam,
  soundcloud,
  spotify,
  tencent,
  tidal,
  tiktok,
  uma,
  aligenie,
  yandex,
  ytcontentid,
  ytmusic,
  zvooq,
  twitch,
  beatport,
  joox,
  juno,
  kanjian,
  adaptr,
  peloton,
  soundexchange,
} from "../../assets/platforms";

const platformIcon = {
  Adaptr: { src: adaptr, alt: "adaptr" },
  Spotify: { src: spotify, alt: "spotify" },
  Pandora: { src: pandora, alt: "pandora" },
  iHeart: { src: iheart, alt: "iheart" },
  "Amazon Music": { src: amazon, alt: "amazon" },
  Beatport: { src: beatport, alt: "beatport" },
  Soundcloud: { src: soundcloud, alt: "soundcloud" },
  Shazam: { src: shazam, alt: "shazam" },
  Twitch: { src: twitch, alt: "twitch" },
  MixCloud: { src: mixcloud, alt: "mixcloud" },
  Pandora: { src: pandora, alt: "pandora" },

  Anghami: { src: anghami, alt: "anghami" },
  AWA: { src: awa, alt: "awa" },

  Boomplay: { src: boomplay, alt: "boomplay" },
  Deezer: { src: deezer, alt: "deezer" },

  Gracenote: { src: gracenote, alt: "gracenote" },
  Jaxsta: { src: jaxsta, alt: "jaxsta" },
  JioSaavn: { src: jiosaavn, alt: "jiosaavn" },

  Kkbox: { src: kkbox, alt: "kkbox" },
  MonkingMe: { src: monkingme, alt: "monkingme" },
  Resso: { src: resso, alt: "resso" },

  "Rhapsody/Napster": { src: rhapsodynapster, alt: "rhapsodynapster" },
  Zvooq: { src: zvooq, alt: "zvooq" },
  Yandex: { src: yandex, alt: "yandex" },

  AliGenie: { src: aligenie, alt: "aligenie" },
  TikTok: { src: tiktok, alt: "tiktok" },
  Tencent: { src: tencent, alt: "tencent" },
  "Claro música": { src: claromusica, alt: "claromusica" },
  "7 Digital": { src: sevendigital, alt: "sevendigital" },
  "Audible Magic": { src: audible, alt: "audible" },
  "Facebook Rights Manager": { src: facebook, alt: "facebook" },
  iTunes: { src: itunes, alt: "itunes" },
  "YouTube Audio Content ID": { src: ytcontentid, alt: "ytcontentid" },
  "YouTube Music": { src: ytmusic, alt: "ytmusic" },
  "IMI Mobile": { src: imi, alt: "imi" },
  "United Media Agency (UMA)": { src: uma, alt: "uma" },
  "NetEase Cloud Music": { src: netease, alt: "netease" },
  "KDigital Media": { src: kdigital, alt: "kdigital" },
  Tidal: { src: tidal, alt: "tidal" },
  JOOX: { src: joox, alt: "joox" },
  Juno: { src: juno, alt: "juno" },
  Kanjian: { src: kanjian, alt: "kanjian" },
  Peloton: { src: peloton, alt: "peloton" },
  Soundexchange: { src: soundexchange, alt: "soundexchange" },
};

export default platformIcon;

export const deletePlatforms = [
  "imi",
  "monkingme",
  "uma",
  "aligenie",
  "yandex",
  "zvooq",
  "juno",
];
