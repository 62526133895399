import React, { useMemo } from "react";
import ColumnChart from "../../../HighCharts/ColumnChart";
import { getTotal } from "./lib/total_calc";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";
const INITIAL_PAGE_SIZE = 25;

function TrackAggregation({
  userSalesData,
  loading,
  tableClassName = "my-earnings-section-1-table",
}) {
  let singleChartData = userSalesData?.map((track) => {
    return [track._id, track.totalNetPayable];
  });
  let chartData = [{ type: "column", data: singleChartData }];

  // total values calculations
  let totalSales = getTotal(userSalesData, "totalNetPayable"); // true for toFixed
  let totalStreams = getTotal(userSalesData, "totalStreams");
  const formatNumber = new Intl.NumberFormat().format;
  if (totalSales) totalSales = totalSales?.toFixed(2);

  const columns = useMemo(
    () => [
      {
        Header: "Track",
        accessor: "_id",
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: "ISRC",
        accessor: "isrc",
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: "Streams",
        accessor: "totalStreams",
        Cell: ({ value }) => {
          return formatNumber(value);
        },
      },

      {
        Header: "Revenue",
        accessor: "totalNetPayable",
        Cell: ({ value }) => {
          return `€ ${value?.toFixed(2)}`;
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: userSalesData,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <ColumnChart
        data={chartData}
        xLabelsType="category"
        xLabelsFormat={undefined}
        yAxisValuePrefix="€ "
      />
      <div className={"admin-releases mt-4 pb-3 "}>
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-0">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
              </div>
            </div>
          </div>
        </div>
        <TableView
          tableInstance={tableInstance}
          loading={loading}
          totalNewStreams={totalStreams}
          totalNewSales={totalSales}
          salesData={true}
          isrc={true}
        />
      </div>
    </>
  );
}

export default TrackAggregation;
