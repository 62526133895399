import React, { memo } from "react";
import InputComponent from "../../../components/form/InputComponent";
import ButtonComponent from "../../../components/form/ButtonComponent";

import calendar from "../../../assets/icons/calendar.svg";
import Spinner from "../../../assets/spinner.svg";

import { useSelector } from "react-redux";

import { labelPlans } from "../../utils";

const TotalShare = (contractDetails) => {
  const totalShare = contractDetails?.contractDetails?.rightsholders?.reduce(
    (acc, rightholder) => acc + rightholder.royaltiesShare,
    0
  );

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div
        className={
          (totalShare ? "" : "d-none") + " inputUpperComment utiuc3_totalShare"
        }
      >
        Total
      </div>
      <div>
        <div className="position-relative">
          <InputComponent
            disabled={true}
            commentField={".utiuc3_totalShare"}
            placeholder="Share"
            inputGroupClassName="nrInputGroup "
            inputClassName={
              " nrInput utepInput " +
              (totalShare ? " interMediumInput interMediumPaddingTop " : "")
            }
            paddingTopOnInput={true}
            addon={false}
            tooltip={true}
            maxlength={3}
            value={totalShare}
          />
        </div>
      </div>
    </div>
  );
};

const TotalExpenseShare = memo((contractDetails) => {
  const totalExpenseShare =
    contractDetails?.contractDetails?.rightsholders?.reduce(
      (acc, rightholder) => acc + rightholder.expenseShare,
      0
    );

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div
        className={
          (totalExpenseShare ? "" : "d-none") +
          " inputUpperComment utiuc3_totalExpenseShare"
        }
      >
        Total
      </div>
      <div>
        <div className="position-relative">
          <InputComponent
            disabled={true}
            commentField={".utiuc3_totalExpenseShare"}
            placeholder="Share"
            inputGroupClassName="nrInputGroup "
            inputClassName={
              " nrInput utepInput " +
              (totalExpenseShare
                ? " interMediumInput interMediumPaddingTop "
                : "")
            }
            paddingTopOnInput={true}
            addon={false}
            tooltip={true}
            maxlength={3}
            value={totalExpenseShare || 0}
          />
        </div>
      </div>
    </div>
  );
});

const ExpenseContractForm = ({ onClose, isLoading, contractDetails }) => {
  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const isLabelPlan = labelPlans.some((plan) =>
    plan.includes(reduxCurrentPlan)
  );

  return (
    <>
      {isLoading ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <div className="row mx-0 pb-4">
          {/* contract name */}
          <div className="py-2 py-md-0 col-12 mb-3">
            <div
              className={
                (contractDetails?.name ? "" : "d-none") +
                " inputUpperComment utiuc3_upcCode"
              }
            >
              Contract name
            </div>
            <InputComponent
              disabled
              value={contractDetails?.name}
              commentField={".utiuc3_upcCode"}
              placeholder="Contract Name*"
              autofocus
              inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100"
              inputClassName={
                "utepInput" +
                (contractDetails?.name
                  ? " interMediumInput interMediumPaddingTop"
                  : "")
              }
              paddingTopOnInput={true}
              addon={false}
            />
          </div>

          {/* Start date */}
          <div className="py-2 py-md-0 col-12 mb-3">
            <div
              className={
                "inputUpperComment iuc6 " +
                (contractDetails?.startDate || "d-none")
              }
            >
              Start date*
            </div>
            <InputComponent
              disabled
              value={contractDetails?.startDate.split("T")[0]}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup w-100"
              inputClassName={
                "nrInput nrInput-opacityFix " +
                (contractDetails?.startDate || contractDetails?.startDate.length
                  ? "nrCalendarInputPopulated"
                  : "")
              }
              commentField=".iuc6"
              placeholder="Start date*"
              for="nrReleaseDate"
              id="startDate"
              // calendar addon
              addon="right"
              calendarAddon={true}
              calImg={calendar}
              calImgClass="nrAddonRightIcon"
              calAlt="pick date"
              inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
              databaseField="startDate"
            />
          </div>

          {/* end date */}
          {contractDetails?.endDate ? (
            <div className="py-2 py-md-0 col-12 mb-3">
              <div
                className={
                  "inputUpperComment iuc6 " +
                  (contractDetails?.endDate || "d-none")
                }
              >
                End date
              </div>
              <InputComponent
                disabled={true}
                value={contractDetails?.endDate} // take from calendar, or pre-populate
                paddingTopOnInput={true}
                inputGroupClassName="nrInputGroup w-100"
                inputClassName={
                  "nrInput nrInput-opacityFix " +
                  (contractDetails?.endDate || contractDetails?.endDate?.length
                    ? "nrCalendarInputPopulated"
                    : "")
                }
                commentField=".iuc6"
                placeholder="End date"
                for="nrReleaseDate"
                id="endDate"
                // calendar addon
                addon="right"
                calendarAddon={true}
                calImg={calendar}
                calImgClass="nrAddonRightIcon"
                calAlt="pick date"
                inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                databaseField="endDate"
              />
            </div>
          ) : (
            ""
          )}

          {/* Contract splits */}
          <div
            className="col-12 p-3 rounded"
            style={{ backgroundColor: "#2c313a" }}
          >
            <div className="mb-3">Right holders & shares</div>

            <>
              <div
                className={`row px-0 mx-0 ${isLabelPlan ? "col" : "col-11"}`}
              >
                <div className={isLabelPlan ? "col-5" : "col-8"}></div>
                <div
                  className={`col-3 mb-1 align-items-start ${
                    isLabelPlan ? "pl-5" : ""
                  }`}
                >
                  <b>Royalties</b>
                </div>

                {isLabelPlan && (
                  <div className="col-3 mb-1 pl-4 ml-1 align-items-start">
                    <b>Expenses</b>
                  </div>
                )}
                <div className="col-1"></div>
              </div>

              {contractDetails?.rightsholders?.map((rightholder, index) => (
                <>
                  <div className="row px-0 mx-0 col" key={rightholder._id}>
                    <div className="col-11 mx-0 px-0 ">
                      <div className="row mx-0 px-0">
                        <div
                          className={` py-2 py-md-0 mb-3 text-white ${
                            isLabelPlan ? "col-6" : "col-8"
                          }`}
                        >
                          <div
                            className={
                              (rightholder?.contact?.firstName
                                ? ""
                                : "d-none") +
                              " inputUpperComment utiuc1_" +
                              index
                            }
                          >
                            Contact*
                          </div>
                          <div className="position-relative">
                            <InputComponent
                              disabled
                              value={
                                rightholder?.contact?.firstName +
                                " " +
                                rightholder?.contact?.lastName
                              }
                              commentField={".utiuc3_" + index}
                              placeholder="Contact*"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (rightholder?.contact?.firstName
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                            />
                          </div>
                        </div>

                        {/* royality share */}
                        <div className="py-2 col-3 py-md-0 mb-3">
                          <div className="position-relative">
                            <InputComponent
                              disabled
                              value={rightholder?.royaltiesShare}
                              inputType="number"
                              commentField={".utiuc3_" + index}
                              placeholder="Share"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (rightholder?.royaltiesShare
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                              maxlength={3}
                            />
                            {rightholder?.royaltiesShare >= 0 ? (
                              <div
                                className="position-absolute utepPercent-2"
                                style={{ right: 12, bottom: 10, zIndex: 100 }}
                              >
                                %
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        {/* Expense share */}
                        {isLabelPlan && (
                          <div className="py-2 col-3 py-md-0 mb-3">
                            <div>
                              <div className="position-relative">
                                <InputComponent
                                  disabled
                                  value={rightholder?.expenseShare}
                                  inputType="number"
                                  commentField={".utiuc3_" + index}
                                  placeholder="Share"
                                  inputGroupClassName="nrInputGroup "
                                  inputClassName={
                                    " nrInput utepInput " +
                                    (rightholder?.expenseShare
                                      ? " interMediumInput interMediumPaddingTop"
                                      : "")
                                  }
                                  paddingTopOnInput={true}
                                  addon={false}
                                  maxlength={3}
                                />
                                {rightholder?.expenseShare >= 0 ? (
                                  <div
                                    className="position-absolute utepPercent-2"
                                    style={{
                                      right: 12,
                                      bottom: 10,
                                      zIndex: 100,
                                    }}
                                  >
                                    %
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="utepHr w-100 mb-3 mt-0"
                      style={{ top: 0 }}
                    />
                  </div>
                </>
              ))}

              <div className="row col px-0 mx-0 justify-content-between w-100 my-2 mt-3 align-items-center flex-md-wrap">
                <div className="col-11 mx-0 px-0">
                  <div className="row mx-0 px-0 mb-2">
                    <div
                      className={`align-items-center d-flex py-md-0 col-6 ${
                        isLabelPlan ? "col-6" : "col-8"
                      }`}
                    ></div>
                    <div className="pb-2 py-md-0 col-3">
                      <TotalShare contractDetails={contractDetails} />
                    </div>
                    {isLabelPlan && (
                      <div className="pb-2 py-md-0 col-3 align-items-start">
                        <TotalExpenseShare contractDetails={contractDetails} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-1 mx-0 px-0" />
              </div>
            </>
          </div>

          <div className="ml-0 row col justify-content-end align-items-end mt-4">
            <ButtonComponent
              onClick={onClose}
              className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
            >
              Cancel
            </ButtonComponent>
          </div>
        </div>
      )}
    </>
  );
};

export default ExpenseContractForm;
