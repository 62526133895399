import React, { useState, useEffect } from "react";
import "./onboarding.scss";
import "../../assets/scss/pt/forms.scss";

import ButtonComponent from "../form/ButtonComponent";

import Footer from "./components/FooterComponent";

import { setSpotifyArtist, getSpotifyProfile } from "../../routes/users";

import spotifyIcon from "../../assets/icons/spotify_white.png";

import ErrorPopup from "../error/ErrorPopup";
import ObDecorations from "./components/ObDecorations";
import ObProgressCircles from "./components/ObProgressCircles";
import SpotifyPagePicker from "./components/SpotifyPagePicker";
import Stepper from "../Stepper";
import { getJWT } from "../utils";

function Onboarding2() {
  const [artistId, setArtistId] = useState("");
  const [isError, setIsError] = useState(false);

  const [prepopulatedArtistName, setPrepopulatedArtistName] = useState();
  const [prepopulatedArtistImage, setPrepopulatedArtistImage] = useState();

  const jwtToken = getJWT();
  const nextStep = "/onboarding4";

  useEffect(() => {
    getSpotifyProfile(jwtToken).then((r) => {
      if (r.data) {
        setPrepopulatedArtistImage(r.data.images?.[0].url);
        setPrepopulatedArtistName(r.data.name);
        setArtistId(r.data.id);
      }
    }); // getter function for prepopulating
  }, []); // on page load

  function handleNext() {
    // save artist artistId
    // todo: button says skip if artistId is empty
    // todo: option to deselect, to proceed without setting artistId
    setSpotifyArtist(artistId, jwtToken) // <== TODO: ROUTE TO BE for setting artist - follow function declaration - can be abstracted or another function set for new back-end call
      .then((result) => {
        // All good, proceed to next page
        // TODO: replace with onboarding3 when adding Apple artist profile
        window.location.replace(nextStep);
      })
      .catch((err) => {
        setIsError(true);
        console.log(err);
      });
  }

  function isErrorCallback(v) {
    setIsError(v);
  }

  function artistIdCallback(artistId) {
    setArtistId(artistId); // here the artist (later IG or FB page) gets picked
  }

  const steps = [
    {
      name: "",
      callback: () => window.location.replace(`/onboarding1`),
    },
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
    {
      name: "",
      callback: () => {},
    },
  ];

  const color = "#15161c";
  const activeColor = "#0ef1db";

  return (
    <>
      <ObDecorations />
      <div className="obCenterBox obCenterBox-2">
        <div className="obModal obModal-2">
          <Stepper
            steps={steps}
            currentStep={1}
            containerClasses={"oboarding-container"}
            color={color}
            activeColor={activeColor}
            lineColor={color}
            finishLineColor={activeColor}
          />
          <div className="obModal-Header obModal-Header-2 noWrap mt-4">
            select spotify artist
          </div>
          <SpotifyPagePicker
            isErrorCallback={isErrorCallback}
            artistIdCallback={artistIdCallback}
            prepopulatedArtistImage={prepopulatedArtistImage}
            prepopulatedArtistName={prepopulatedArtistName}
          />
          <div
            className="obSkip obSkip-2"
            onClick={() => window.location.assign(nextStep)}
          >
            Skip this step
          </div>
          <ButtonComponent
            className="obNextButton obNextButton-2"
            onClick={handleNext}
          >
            Next
          </ButtonComponent>
        </div>
      </div>
      <div className="w-100 mx-auto">
        <ErrorPopup
          error={isError}
          style={{ fontSize: "15px", position: "relative" }}
          className="text-center mx-auto"
          customText="Something went wrong, please refresh the page"
        />
      </div>
      <Footer />
    </>
  );
}

export default Onboarding2;
