import React from "react";
import CaretDownIcon from "../../assets/icons/icon-caret-down.svg";

function HorizontalScrollButtonGroup({
  handleScroll,
  disableLeft = false,
  disableRight = false,
  isVisible = false,
}) {
  return (
    <div className={isVisible ? "horizontal-scroll-button-group" : "d-none"}>
      {/* hidden for now by default cause stats cards are displayed as grid */}
      <span
        onClick={(e) => handleScroll("left")}
        className={!!disableLeft && "disable"}
      >
        <img
          src={CaretDownIcon}
          className={`horizontal-scroll-button-group-caret left ${
            !!disableLeft && "disable"
          }`}
          alt="caret"
        />
      </span>
      <span
        onClick={(e) => handleScroll("right")}
        className={!!disableRight && "disable"}
      >
        <img
          src={CaretDownIcon}
          className={`horizontal-scroll-button-group-caret right ${
            !!disableRight && "disable"
          }`}
          alt="caret"
        />
      </span>
    </div>
  );
}

export default HorizontalScrollButtonGroup;
