import React, { useEffect, useState } from "react";
// import Sidebar from "../artistDashboard/sidebar";
import Sidebar from "../../../artistDashboard/sidebar";
import MobileMenuBurger, {
  MobileMenu,
} from "../../../artistDashboard/MobileMenu/MobileMenu";

export default function DistributionLicenseGuidelines() {
  return (
    <div>
      <div
        style={{ overflowX: "none !important", backgroundColor: "#343a47" }}
        className="ptTermsContent welcomePage mt-6"
      >
        <h1 style={{ color: "white" }}>
          <b>What type of license do I need to upload? </b>
        </h1>
        <br />
        The license you need to upload depends on what type of sample you are
        using and where/who you obtained it from. <br />
        Our Quality Control is very thorough as we want to contribute to a
        fairer and transparent music industry, so to avoid any delays please
        upload real and legal licenses (no fakes, we can tell). If you have any
        further questions please contact us at support@playtreks.com. <br />
        <br />
        <b>1. If the Sample was obtained directly from the original owner</b>
        <br />
        The original owner must provide a valid document that expresses his
        agreement with the obtainment, use and commercialization of his Sample,
        by the artist who intends to distribute it through the platform.
        <br />
        <br />
        <b>
          2. If the Sample was obtained from an instrument, either physical or
          virtual instrument
        </b>
        <br />
        The license or any other evidence that can demonstrate that the Sample
        has been obtained from an instrument must be presented. Similarly, if
        applicable, you must present the license that is usually available with
        the instrument.
        <br />
        <br />
        <b>
          3. If the Sample was obtained through a payment service (per unit or
          per subscription) such as Splice, Loopmasters, Beatstars, etc.
        </b>
        <br />
        A proof of payment must be presented for the unit, or the registration
        of the subscription to said service, as well as an evidence that the
        Sample has been obtained through this specific payment service (this can
        be screenshots, payment confirmations, pdfs, etc). Both the proof of
        payment and evidence of where the sample was obtained are mandatory.
        <br />
        <br />
        <b>4. If the Sample was obtained through a free legal service</b>
        <br />
        Any valid evidence that could demonstrate that the Sample has been
        obtained through the free service must be presented, and also a valid
        evidence that could prove that the commercialization of the obtained
        Sample is authorized. <br />
        <br />
        <b>
          5. If the Sample has not been obtained by any of the aforementioned
          means
        </b>
        <br /> It must be justified and in the same way by presenting a document
        or any evidence that could prove: <br />
        <ul>
          <li>that the use of the Sample is allowed</li>
          <li>that it has been provided by legal means</li>
          <li>
            that it has been obtained in a legal manner and that it grants
            permission to the commercialization of the Sample or derivative
            works{" "}
          </li>
        </ul>
        <br />
        If the provisions of this policy are not met, then the content involved
        will not be approved, nor will its distribution be accepted.
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
