import React, { useState } from "react";
import SelectComponent from "../../form/SelectComponent";

const transactionTypeFilterOptions = {
  "": { label: "All", value: "" },
  debit: {
    label: "Debit",
    value: "debit",
  },
  credit: {
    label: "Credit",
    value: "credit",
  },
};

const TransactionTypeFilter = ({
  setFilter,
  filters,
  options = transactionTypeFilterOptions,
}) => {
  const initialTransactionTypeFilter = filters.find(
    (filter) => filter.id === "type"
  );

  const [transactionTypeFilter, setTransactionTypeFilter] = useState(
    initialTransactionTypeFilter?.value || ""
  );

  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 py-2 py-md-0 text-white"
      }
      id={"transactionTypeFilter"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Type</div>
      <SelectComponent
        value={options[transactionTypeFilter].label}
        extendOptionWidth
        id={"transactionTypeFilter"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(val) => {
          setTransactionTypeFilter(val);
          setFilter("type", val);
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

export default TransactionTypeFilter;
