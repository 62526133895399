import React from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import TableView from "../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../admin/ReleasesAdmin";
import TransactionTypeFilter from "./TransactionTypeFilter";
import { useQuery } from "react-query";
import { getUserTransactionsByUser } from "../../../services/transactionV2";
import AmountCard from "./AmountCard";

const INITIAL_PAGE_SIZE = 25;

const UserTransactions = () => {
  const { data: transactions, isLoading: isTransactionsLoading } = useQuery(
    "userTransactions",
    getUserTransactionsByUser,
    { refetchOnWindowFocus: false }
  );

  const userTransactions = React.useMemo(
    () => transactions?.data ?? [],
    [transactions]
  );

  const customAmountCell = ({ value, row }) => {
    if (row.original.type === "debit") {
      return (
        <div className="font-weight-bolder" style={{ color: "red" }}>
          € {value}
        </div>
      );
    } else
      return <div className="text-primary font-weight-bolder">€ {value}</div>;
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Amount",
        accessor: "amount",
        Cell: customAmountCell,
      },

      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => {
          if (value === "debit") {
            return (
              <div className="font-weight-bolder" style={{ color: "red" }}>
                {value}
              </div>
            );
          } else
            return (
              <div className="text-primary font-weight-bolder">{value}</div>
            );
        },
      },

      {
        Header: "Description",
        accessor: "description",
      },

      {
        Header: "Transaction Date",
        accessor: "createdAt",
        Cell: ({ value }) => value?.split("T")[0] ?? "N/A",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: userTransactions,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <div className="allowUserSelect">
        <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
          <AmountCard transactions={userTransactions} />
          <div className="d-block mb-3" style={{ zIndex: 1 }}>
            <p className="font-weight-bold text-white mb-2">Filter by:</p>
            <div className="row pb-2 align-items-center justify-content-between">
              <div className="col-12 col-md">
                <div className="row">
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                  <TransactionTypeFilter
                    setFilter={setFilter}
                    filters={filters}
                  />
                </div>
              </div>
            </div>
          </div>

          <TableView
            tableInstance={tableInstance}
            loading={isTransactionsLoading}
          />
        </main>
      </div>
    </>
  );
};

export default UserTransactions;
