// REDUX
import { useSelector, useDispatch } from "react-redux";

import { getFilterValue } from "./filters";
import { cloneDeep } from "lodash";

// filter types
const MUSIC_RELEASES_FILTER = "music_releases_filter";
const MUSIC_TRACKS_FILTER = "music_tracks_filter";
const MUSIC_ARTISTS_FILTER = "music_artists_filter";
const STORES_FILTER = "stores_filter";
const COUNTRIES_FILTER = "countries_filter";

// aggregation types (groupings)
const DATE_RANGE = "date_range";
const DSP = "dsp";
const COUNTRY = "country";
const RELEASE = "release";
const TRACK = "track";
const ARTIST = "artist";
const GROUP = "group";

export function updateQueryString(
  currentFilters,
  currentAggregation,
  history,
  location
) {
  let filtersToQuery = [];
  let groupingToQuery = "";
  Object.keys(currentFilters).forEach((filter) => {
    if (![undefined].includes(currentFilters[filter])) {
      filtersToQuery.push({ [filter]: currentFilters[filter] });
    }
  });
  groupingToQuery = "group=" + currentAggregation;

  let queryString = filtersToQuery
    .map(
      (filter) => Object.keys(filter)[0] + "=" + filter[Object.keys(filter)[0]]
    )
    .join("&");

  if (queryString.length) queryString = queryString + "&" + groupingToQuery;
  else queryString = "?" + groupingToQuery;

  if (queryString === location.search || !queryString.length) return; // stop if the same thing is picked as comes from query

  history.push({
    search: "?" + queryString,
  });
}

export function getAndValidateFilterValuesFromQuery(
  query,
  currentFilters,
  {
    storesFilterContent,
    countriesFilterContent,
    releasesFilterContent,
    tracksFilterContent,
    artistsFilterContent,
  }
) {
  let newCurrentFilters = cloneDeep(currentFilters);

  if (
    !releasesFilterContent.includes(
      getFilterValue(query, MUSIC_RELEASES_FILTER)
    ) &&
    getFilterValue(query, MUSIC_RELEASES_FILTER) !== 0
  )
    newCurrentFilters[MUSIC_RELEASES_FILTER] = 0;
  if (
    !tracksFilterContent.includes(getFilterValue(query, MUSIC_TRACKS_FILTER)) &&
    getFilterValue(query, MUSIC_TRACKS_FILTER) !== 0
  )
    newCurrentFilters[MUSIC_TRACKS_FILTER] = 0;
  if (
    !artistsFilterContent.includes(
      getFilterValue(query, MUSIC_ARTISTS_FILTER)
    ) &&
    getFilterValue(query, MUSIC_ARTISTS_FILTER) !== 0
  )
    newCurrentFilters[MUSIC_ARTISTS_FILTER] = 0;
  if (
    !storesFilterContent.includes(getFilterValue(query, STORES_FILTER)) &&
    getFilterValue(query, STORES_FILTER) !== 0
  ) {
    newCurrentFilters[STORES_FILTER] = 0;
  }
  if (
    !countriesFilterContent.includes(getFilterValue(query, COUNTRIES_FILTER)) &&
    getFilterValue(query, COUNTRIES_FILTER) !== 0
  )
    newCurrentFilters[COUNTRIES_FILTER] = 0;

  return newCurrentFilters;
}

export function getAndValidateGroupValue(query) {
  if (
    [DATE_RANGE, DSP, COUNTRY, RELEASE, TRACK, ARTIST].includes(
      getFilterValue(query, GROUP)
    )
  )
    return getFilterValue(query, GROUP);
  else return DATE_RANGE;
}
