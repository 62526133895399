import React, { useEffect, useState } from "react";

import FbAliceCarousel from "./FbAliceCarousel";
import checkCircle from "../../../assets/icons/check-circle.svg";

import ErrorPopup from "../../error/ErrorPopup";

import { get as sliceObject } from "lodash";

import {
  getUserData,
  getFbProfiles,
  getConnectedAccounts,
} from "../../../routes/users";
import { getJWT } from "../../utils";

function FbPagePicker({
  pickedPageIdCallback,
  className,
  isInstagram = false,
  connectPlatformUrl = "/onboarding1",
}) {
  const jwtToken = getJWT();

  const [fbProfiles, setFbProfiles] = useState({});
  const [isError, setIsError] = useState(false);
  const [checked, setChecked] = useState();
  const [fbStatus, setFbStatus] = useState();

  const pageIdField = isInstagram
    ? "service_profiles.selectedInstagramPageID"
    : "service_profiles.selectedFacebookArtistPageID";

  const profilePicField = isInstagram
    ? "profile_picture_url"
    : "picture.data.url";

  const noPagesText = isInstagram
    ? "instagram business accounts connected"
    : "artist pages on Facebook";

  useEffect(() => {
    (async () => {
      // checking if fb connected
      const isFbConnected = await getConnectedAccounts("facebook", jwtToken);
      if (!isFbConnected.data.facebook) {
        setFbStatus("not connected");
        return;
      } else {
        setIsError(false);
      }
      const fbProfiles = await getFbProfiles(jwtToken, null, {
        isInstagram: isInstagram,
      })
        .then((r) => {
          setIsError(false);
          if (r.data.fbProfiles.length > 0) {
            setFbProfiles(r.data.fbProfiles);
          } else {
            setFbStatus("no pages");
          }
          return r.data.fbProfiles;
        })
        .catch((e) => setIsError(true));
      await getUserData(jwtToken, true) // get the previously picked one
        .then((r) => {
          if (r.data[pageIdField]) {
            let selected = fbProfiles
              .map((fbProfile, index) => {
                if (fbProfile.id === r.data[pageIdField]) return index;
              })
              .filter((item) => item !== undefined)[0];
            setChecked(selected);
            pickedPageIdCallback(r.data[pageIdField]);
          }
        });
    })();
  }, []);

  function handleSelect(e) {
    if (e !== "clear") {
      setChecked(e.target.id || e.target.offsetParent.id);
      pickedPageIdCallback(
        fbProfiles[e.target.id || e.target.offsetParent.id].id
      );
    } else {
      setChecked();
      pickedPageIdCallback();
    }
  }

  return (
    <div className={className}>
      {!fbStatus ? (
        fbProfiles.length < 4 ||
        (fbProfiles.length > 0 && window.innerWidth < 769) ? (
          <div className="ob3ProfileBoxes">
            {fbProfiles.map((fbProfile, index) => (
              <ProfileBox
                className="ob3ProfileBox"
                id={index}
                onClick={handleSelect}
              >
                <img
                  src={checkCircle}
                  alt="check"
                  className={
                    "ob3ProfileBox-checkCircle " +
                    (Number(checked) !== index ? "d-none" : "")
                  }
                />
                <div
                  className="ob3ConnectOval"
                  id={index}
                  onClick={handleSelect}
                >
                  <img
                    alt="user"
                    src={sliceObject(fbProfile, profilePicField)}
                    style={{ width: "100%", borderRadius: "100px" }}
                    id={index}
                  />
                </div>
                <div
                  className="ob3SpotifyProfileName"
                  id={index}
                  onClick={handleSelect}
                >
                  {fbProfile.name.length > 32
                    ? fbProfile.name.slice(0, 32) + "..."
                    : fbProfile.name}
                </div>
              </ProfileBox>
            ))}
          </div>
        ) : (
          <FbAliceCarousel
            fbProfiles={fbProfiles}
            handleSelect={handleSelect}
            checked={checked}
            isInstagram={isInstagram}
          />
        )
      ) : (
        <div>
          {fbStatus === "not connected" ? (
            <div className="ob3fbStatusFeedback mt-5 p-5">
              Your facebook profile is not yet connected. <br />
              You must connect your user account in order to select your artist
              page.
              <br />
              <br />{" "}
              <a href={connectPlatformUrl}>
                <u>Connect your facebook account now!</u>
              </a>
            </div>
          ) : (
            <div className="ob3fbStatusFeedback mt-5">
              {/* <br /> */}
              {className ? ( // this is a fix for making the text appear in the middle on editProfile page as well (onboarding3 is ok)
                <span>
                  <br />
                  <br />
                  <br />
                </span>
              ) : (
                ""
              )}
              It looks like you have no {noPagesText}. <br /> Create some and
              track your progress with Playtreks!
            </div>
          )}
        </div>
      )}
      <div className="w-100 mx-auto">
        <ErrorPopup
          error={isError}
          style={{ fontSize: "15px" }}
          className="text-center mx-auto"
          customText="Something went wrong, please refresh the page"
        />
      </div>
    </div>
  );
}

function ProfileBox(props) {
  return (
    <div onClick={props.onClick} className={props.className} id={props.id}>
      {props.children}
    </div>
  );
}

export default FbPagePicker;
