import React from "react";
import "./index.scss";
import X from "../distribution/assets/X.svg";

function CustomAlert({ children, variant, closeCallback, xButton, id }) {
  return (
    <div className={"alert-container" + (variant ? "-" + variant : "")} id={id}>
      {children}
      {xButton && (
        <img src={X} className="toastrStyleAlert-x" onClick={closeCallback} />
      )}
    </div>
  );
}

export default CustomAlert;
