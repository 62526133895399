import React from "react";
import InputComponent from "../../form/InputComponent";

import ButtonComponent from "../../form/ButtonComponent";
import calendar from "../../../assets/icons/calendar.svg";
import Spinner from "../../../assets/spinner.svg";

const PaidAmount = ({ expenseSplits }) => {
  const rightholderName = expenseSplits?.map((split) => {
    return (
      `${split?.rightholder?.contact?.firstName ?? ""} ${
        split?.rightholder?.contact?.lastName ?? ""
      }` ?? "".trim()
    );
  });

  return (
    <>
      {expenseSplits?.map((split, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-8 px-2">
            <div
              className={
                (rightholderName ? "" : "d-none") +
                "inputUpperComment utiuc3_" +
                index
              }
            >
              Contact
            </div>
            <div className="postion-relative w-100">
              <InputComponent
                disabled={true}
                value={rightholderName[index]}
                commentField={".utiuc3_"}
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput " +
                  (split?.rightholder
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                inputBlurCallback={() => {}}
                paddingTopOnInput={true}
                addon={false}
                tooltip={true}
              />
            </div>
          </div>
          <div className="col-4 px-2">
            <div
              className={
                (split?.paidAmount ? "" : "d-none") +
                " inputUpperComment utiuc3_"
              }
            >
              Amount
            </div>
            <div className="position-relative">
              <InputComponent
                disabled={true}
                value={split?.paidAmount ? split?.paidAmount : null}
                inputType="number"
                placeholder="Amount"
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput pl-4" +
                  (split?.paidAmount
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                inputBlurCallback={() => {}}
                paddingTopOnInput={true}
                addon={false}
              />
              {split?.paidAmount >= 0 ? (
                <div
                  className="position-absolute  font-weight-bold"
                  style={{ left: 8, bottom: 11, zIndex: 100 }}
                >
                  €
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const TotalPaidAmount = ({ expenseSplits }) => {
  const total = expenseSplits?.reduce(
    (acc, curr) => acc + Number(curr.paidAmount),
    0
  );

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div className={"inputUpperComment utiuc3_totalAmount"}>Total</div>

      <div className="position-relative">
        <InputComponent
          value={total ? total : 0}
          disabled={true}
          commentField={".utiuc3_totalAmount"}
          inputGroupClassName="nrInputGroup "
          inputClassName={
            " nrInput utepInput pl-4 font-weight-bold" +
            (total >= 0 ? " interMediumInput interMediumPaddingTop " : "")
          }
          paddingTopOnInput={true}
          addon={false}
        />
      </div>
      {total >= 0 ? (
        <div
          className="position-absolute currency-color utepPercent-2 font-weight-bold"
          style={{
            left: 10,
            bottom: 11,
            zIndex: 100,
          }}
        >
          €
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const TotalExpenseAmount = ({ expenseSplits }) => {
  const total = expenseSplits?.reduce(
    (acc, curr) => acc + Number(curr.expenseShare),
    0
  );

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div className={"inputUpperComment utiuc3_totalAmount"}>Total</div>

      <div className="position-relative">
        <InputComponent
          value={total ? total : 0}
          disabled={true}
          commentField={".utiuc3_totalAmount"}
          inputGroupClassName="nrInputGroup "
          inputClassName={
            " nrInput utepInput  font-weight-bold" +
            (total >= 0 ? " interMediumInput interMediumPaddingTop " : "")
          }
          paddingTopOnInput={true}
          addon={false}
        />
      </div>
      {total >= 0 ? (
        <div
          className="position-absolute currency-color utepPercent-2 font-weight-bold"
          style={{
            right: 10,
            bottom: 11,
            zIndex: 100,
          }}
        >
          %
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const TotalRecoupingAmount = ({ expenseSplits }) => {
  return (
    <>
      {expenseSplits?.map((split, index) => (
        <div className="row mb-3 mx-0 px-0" key={index}>
          <div className="position-relative w-100">
            <InputComponent
              disabled={true}
              value={split?.recoupableAmount}
              inputType="number"
              placeholder="Amount"
              inputGroupClassName="nrInputGroup "
              inputClassName={
                " nrInput utepInput text-white pl-4" +
                (split?.recoupableAmount >= 0 || split?.recoupableAmount < 0
                  ? " interMediumInput .utiuc3_ interMediumPaddingTop"
                  : "")
              }
              inputBlurCallback={() => {}}
              paddingTopOnInput={true}
              addon={false}
            />

            <div
              className="position-absolute currency-color font-weight-bold"
              style={{ left: 8, bottom: 11, zIndex: 100 }}
            >
              €
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

const ExpenseSplits = ({ expenseSplits }) => {
  return (
    <>
      {expenseSplits?.map((split, index) => (
        <div className="mb-3 row col mx-0 px-0" key={index}>
          <div className="position-relative w-100">
            <InputComponent
              disabled={true}
              value={split?.expenseShare ? split?.expenseShare : null}
              inputType="number"
              placeholder="Amount"
              inputGroupClassName="nrInputGroup "
              inputClassName={
                " nrInput utepInput " +
                (split?.expenseShare
                  ? " interMediumInput interMediumPaddingTop"
                  : "")
              }
              inputBlurCallback={() => {}}
              paddingTopOnInput={true}
              addon={false}
            />
            {split?.expenseShare >= 0 ? (
              <div
                className="position-absolute utepPercent-2 font-weight-bold"
                style={{ right: 12, bottom: 10, zIndex: 100 }}
              >
                %
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const ContractExpenseForm = ({ onClose, expense, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <div className="row mx-0 pb-4">
          {/* name */}
          <div className="py-2 py-md-0 col-12 mb-3">
            <div
              className={
                (expense?.name ? "" : "d-none") + " inputUpperComment iuc1"
              }
            >
              Name*
            </div>
            <InputComponent
              disabled={true}
              value={expense?.name}
              commentField=".iuc1"
              placeholder="Expense Name*"
              autofocus
              inputGroupClassName="nrInputGroup nrInputGroup-title w-100"
              inputClassName={
                (expense?.name
                  ? "interMediumInput interMediumPaddingTop"
                  : "") + " nrInput"
              }
              inputBlurCallback={() => {}}
              paddingTopOnInput={true}
              addon={false}
            />
          </div>
          {/* Contracts */}
          <>
            <div className="py-2 py-md-0 col-12 mb-3">
              <div
                className={
                  (expense?.contract ? "" : "d-none") +
                  " inputUpperComment utiuc1_" +
                  expense?.contract
                }
              >
                Contract*
              </div>

              <InputComponent
                disabled={true}
                value={expense?.contract?.name}
                commentField=".iuc1"
                autofocus
                inputGroupClassName="nrInputGroup nrInputGroup-title w-100"
                inputClassName={
                  (expense?.contract?.name
                    ? "interMediumInput interMediumPaddingTop"
                    : "") + " nrInput"
                }
                inputBlurCallback={() => {}}
                paddingTopOnInput={true}
                addon={false}
              />
            </div>
          </>
          {/* Expense date */}
          <div className="py-2 py-md-0 col-12 mb-3">
            <div
              className={
                "inputUpperComment iuc6 " + (expense?.expenseDate || "d-none")
              }
            >
              Expense date*
            </div>
            <InputComponent
              disabled={true}
              value={expense?.expenseDate.split("T")[0]}
              paddingTopOnInput={true}
              inputGroupClassName="nrInputGroup w-100"
              inputClassName={
                "nrInput nrInput-opacityFix " +
                (expense?.expenseDate || expense?.expenseDate.length
                  ? "nrCalendarInputPopulated"
                  : "")
              }
              commentField=".iuc6"
              placeholder="Expense date*"
              for="nrReleaseDate"
              id="startDate"
              // calendar addon
              addon="right"
              calendarAddon={true}
              calImg={calendar}
              calImgClass="nrAddonRightIcon"
              calAlt="pick date"
              inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
              databaseField="expenseDate"
              inputBlurCallback={() => {}}
            />
          </div>
          <>
            <div
              className="mb-4 w-100 mx-3 p-3 rounded"
              style={{ backgroundColor: "#1d2025" }}
            >
              <div className="mb-1 expense-splits-header">
                Expense payment and recouping
              </div>

              <div className="row  mx-0">
                <div className="expenseSplitsCard col mb-4 mt-2">
                  <div className=" rounded text-white py-4 px-0 px-md-3 mb-2">
                    <div className="row  align-items-end expense-payment-spacing">
                      <div className="col-8 expense-splits-header">CONTACT</div>
                      <div className="col-4">
                        <div className="mb-1 expense-splits-header">
                          PAID AMOUNT
                        </div>
                        <div className="fs-14">
                          Amount(s) paid by each contact.
                        </div>
                      </div>
                    </div>

                    <PaidAmount expenseSplits={expense?.expenseSplits} />

                    <div className="row justify-content-end">
                      <div className="col-4 px-2">
                        <TotalPaidAmount
                          expenseSplits={expense?.expenseSplits}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="expenseSplitsContainer col-sm-12  col-lg-3 mb-4 mx-sm-0 mx-lg-2 mt-2">
                  <div className=" px-0 rounded text-white py-4 mb-2">
                    <div className="row mx-0 align-items-end expense-payment-spacing">
                      <div className="mb-1 expense-splits-header">
                        EXPENSE SPLIT
                      </div>
                      <div className="fs-14">
                        Prefilled according to the contract.
                      </div>
                    </div>

                    <ExpenseSplits expenseSplits={expense?.expenseSplits} />

                    <TotalExpenseAmount
                      expenseSplits={expense?.expenseSplits}
                    />
                  </div>
                </div>
                <div className="expenseSplitsContainer  col-sm-12 col-lg-3 mb-4 mt-2">
                  <div className=" px-0 rounded text-white py-4 mb-2">
                    <div className="row mx-0 align-items-end recouping-amount-spacing">
                      <div className="mb-1 expense-splits-header">
                        RECOUPABLE AMOUNT
                      </div>
                      <div className="fs-14">
                        What each contact will receive or pay.
                      </div>
                    </div>

                    <TotalRecoupingAmount
                      expenseSplits={expense?.expenseSplits}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* Notes */}
          {expense?.notes.length > 0 && (
            <div className="py-2 py-md-0 col-12 mb-3">
              <div
                className={
                  (expense?.notes ? "" : "d-none") + " inputUpperComment iuc5a"
                }
              >
                Notes
              </div>
              <InputComponent
                disabled={true}
                value={expense?.notes}
                commentField=".iuc5a"
                autofocus
                inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100 utepLyricsGroup"
                paddingTopOnInput={true}
                inputBlurCallback={() => {}}
                addon={false}
                inputType="textarea"
              />
            </div>
          )}
          <div className="ml-0 row col justify-content-end  align-items-end mt-4">
            <ButtonComponent
              onClick={onClose}
              className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
            >
              Cancel
            </ButtonComponent>
          </div>
        </div>
      )}
    </>
  );
};

export default ContractExpenseForm;
