import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";
import { parseQueryParams } from "./utils";

const endpoints = {
  airplay: "airplay",
  airplayOptions: "airplay/options",
  airplayAggregations: "airplay/aggregations",
  airplayDownload: "airplay/download",
};

/**
 * Function to fetch airplay filters options
 *
 * @returns {object} Object containing filter options of available filters
 */
export const getAirPlayOptions = async () => {
  let url = `${global.config.API_URL}/api/${endpoints.airplayOptions}`;
  return axios.get(url, { headers: { Authorization: "Bearer " + getJWT() } });
};

/**
 * Function to fetch airplays of an user
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Response object containing list of airplay of an user
 */
export async function getAirplays(queryParams = {}, cancelToken = null) {
  let url = `${global.config.API_URL}/api/${
    endpoints.airplay
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    cancelToken,
  });
}

/**
 * Function to fetch airplay aggregatins of an user
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Response object containing list of airplay of an user
 */
export async function getAirplayAggregations(
  queryParams = {},
  cancelToken = null
) {
  let url = `${global.config.API_URL}/api/${
    endpoints.airplayAggregations
  }${parseQueryParams(queryParams)}`;

  return axios.get(url, {
    headers: { Authorization: "Bearer " + getJWT() },
    cancelToken,
  });
}

/**
 * Function to download airplays of an user
 *
 * @param {object} queryParams Object containing query params
 * @returns {object} Returns downloadable airplay data
 */
export async function downloadAirplays(queryParams = {}) {
  let url = `${global.config.API_URL}/api/${
    endpoints.airplayDownload
  }${parseQueryParams(queryParams)}`;

  return axios({
    url,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Authorization: "Bearer " + getJWT(),
      "Content-Type": "text/csv",
    },
  });
}
