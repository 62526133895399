import jwtDecode from "jwt-decode";
import React from "react";
import ButtonComponent from "../../../../form/ButtonComponent";
import { formatDateDistance, getJWT } from "../../../../utils";
import moment from "moment";

const ViewBackupCodesModal = ({ backupCodes }) => {
  const handleDownloadCodes = () => {
    const element = document.createElement("a");
    const file = new Blob(
      [
        "SAVE YOUR BACKUP CODES\n",
        "Keep these backup codes somewhere safe but accessible.\n\n",
        backupCodes?.codes?.filter((c) => c)?.join("\n"),
        `\n\n(${jwtDecode(getJWT()).email})\n\n`,
        "* You can only use each backup code once.\n",
        `* These codes were generated on: ${moment(
          backupCodes?.createdAt
        ).format("MMM DD, YYYY")}.`,
      ],
      {
        type: "text/plain",
      }
    );

    element.href = URL.createObjectURL(file);
    element.download = "playtreks-backup-codes.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <div>
        <h1 className="text-white">Download codes</h1>
      </div>
      <div className="mt-3">
        <p className="px-3 text-muted">
          Download these backup codes that you can use to complete two-factor
          authentication when you lose access to your device.
        </p>
        <p className="p-3 text-info-important">
          These codes cannot be downloaded again (you can only regenerate new
          ones). Each code can only be used once. Keep these codes somewhere
          safe but accessible.
        </p>
      </div>
      <div className="mt-4 ">
        <p className="mb-2 font-weight-bold infoText">YOUR BACKUP CODES</p>

        <p className="mb-4 infoText text-muted">
          {backupCodes?.createdAt &&
            `Created ${formatDateDistance(backupCodes?.createdAt)}`}
        </p>
      </div>
      <div className="LineBreak w-100 mb-3 mt-0" style={{ top: 0 }}></div>
      {/* Codes */}
      {backupCodes?.codes?.length > 0 && (
        <div className="row justify-content-center align-items-center">
          {backupCodes?.codes?.map((code) => (
            <div className="p-3 col-6">
              {`${code.slice(0, 4)} ${code.slice(4)}`}
            </div>
          ))}
        </div>
      )}
      <div className="LineBreak w-100 mb-3 mt-0" style={{ top: 0 }}></div>
      <div className="row justify-content-end ml-0 ">
        <ButtonComponent
          className="playtreksButton w-100 h-100"
          onClick={handleDownloadCodes}
          disabled={!backupCodes?.codes?.length}
        >
          Download codes
        </ButtonComponent>
      </div>
    </>
  );
};

export default ViewBackupCodesModal;
