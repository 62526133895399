import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function ContributorsFieldComponent({
  i,
  SelectComponent,
  contributorsOptions,
  InputComponent,
  contributorsArray,
  trash,
  inputChangeCallback,
  removeInputCallback,
  handleAddContributor,
  prepopulated,
  inputBlurCallback,
  selectBlurCallback,
  databaseKey,
}) {
  function handleSelectChange(e) {
    let allowedValues = contributorsOptions.map((option) => option.value);
    if (allowedValues.includes(e))
      inputChangeCallback(e, "#"+databaseKey, "type", i, "contributors");
  }
  function handleInputChange(e) {
    inputChangeCallback(e, "#"+databaseKey, "value", i, "contributors");
  }
  function handleAddContributorChange() {
    handleAddContributor("#"+databaseKey);
  }
  const databaseField = "#"+databaseKey;
  return (
    <div
      className={
        "nrContributorsWrapper" +
        (i !== 0 ? " nrContributorsWrapperExtended" : "")
      }
      id={"contributorsWrapper" + i}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">
        Contributor(s)
      </div>
      <SelectComponent
        value={useSelector(
          (state) =>
            state.distributionReducer?.releaseForm?.[databaseField]
              ?.contributors?.[i]?.type
        )}
        placeholder="Select role"
        inputGroupClassName="nrContributorsSelectGroup"
        shortInput={true}
        valuePaddingTop={true}
        placeholderTop="60%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={contributorsOptions}
        selectChangeCallback={handleSelectChange} // to be used for data transfer
        selectBlurCallback={selectBlurCallback} // leave empty
        databaseField={databaseField}
        databaseKey={databaseKey}
        id={i}
      />

      <InputComponent
        value={useSelector(
          (state) =>
            state.distributionReducer?.releaseForm?.[databaseField]
              ?.contributors?.[i]?.value
        )}
        inputGroupClassName={
          "nrContributorsInputGroup nrInputGroup" +
          (i < contributorsArray.length - 1 && contributorsArray.length > 1
            ? " nrcigExtended"
            : "")
        }
        inputClassName={(prepopulated ? "interMediumInput" : "") + " nrInput"}
        addon={i === 0 ? false : "right"}
        inputGroupTextClassName="nrRightAddon"
        img={trash}
        imgClass="nrAddonRightIcon"
        changeCallback={handleInputChange}
        inputBlurCallback={inputBlurCallback}
        addonClickCallback={removeInputCallback}
        id={i}
        databaseField={databaseField}
        for={databaseKey}
        databaseKey={databaseKey}
      />
      {i === contributorsArray.length - 1 ? (
        <div className="nrPlus" onClick={handleAddContributorChange}>
          +
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ContributorsFieldComponent;
