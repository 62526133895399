import React, { useState } from "react";
import X from "../../../components/distribution/assets/X.svg";
import ButtonComponent from "../../../components/form/ButtonComponent";
import Toast from "../../../components/Toast";
import { resendCollaboratorMail } from "../../../services/distribution";
import { Spinner } from "../../Airplay/Airplay";

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const ResendCollaboratorMailModal = ({
  onCloseRequest,
  rightholderToResendMail,
  trackIndex,
  releaseId,
  setIsOpenResendCollabModal,
  email,
}) => {
  const handleOnClose = () => onCloseRequest?.();

  const [toast, setToast] = useState(initialToastState);

  const [isLoading, isSetLoading] = useState(false);

  const payload = {
    releaseId,
    trackIndex,
    rightholderToResendMail,
  };

  const handleResendCollaboratorMail = async () => {
    try {
      isSetLoading(true);
      await resendCollaboratorMail(payload);

      isSetLoading(false);

      setToast({
        open: true,
        message: "Mail has been successfully sent!",
        type: "success",
      });
      setTimeout(() => {
        setIsOpenResendCollabModal(false);
      }, 1500);
    } catch (e) {
      console.log(e);
      setToast({
        type: "danger",
        open: true,
        message: "Something went wrong. Please try again!",
      });
      isSetLoading(false);
      setTimeout(() => {
        setIsOpenResendCollabModal(false);
      }, 1500);
    }
  };

  return (
    <div className="playtreksModal-overlay">
      <div className="playtreksModal-modal row justify-content-center">
        <img
          src={X}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={handleOnClose}
        />
        <p>
          This will confirm a royalty share of{" "}
          <b className="prrcText">{rightholderToResendMail.royaltiesShare}%</b>{" "}
          {`${
            rightholderToResendMail.expenseShare > 0
              ? "and an expense share of "
              : ""
          }`}
          <b className="prrcText">
            {rightholderToResendMail.expenseShare > 0
              ? rightholderToResendMail.expenseShare + "%"
              : ""}
          </b>{" "}
          to{" "}
          <b className="prrcText">
            {rightholderToResendMail?.lastName
              ? rightholderToResendMail?.firstName +
                " " +
                rightholderToResendMail?.lastName
              : rightholderToResendMail?.firstName}{" "}
            ({rightholderToResendMail.email}).
          </b>
        </p>

        <ButtonComponent
          buttonWrapperClassName="pr-3"
          className="playtreksButton bg-transparent textLink w-100"
          onClick={handleOnClose}
        >
          Close
        </ButtonComponent>

        <Toast
          open={toast.open}
          onClose={() =>
            setToast((prevState) => ({ open: false, type: prevState.type }))
          }
          toastMessage={toast.message}
          toastType={toast.type}
        />
      </div>
    </div>
  );
};

export default ResendCollaboratorMailModal;
