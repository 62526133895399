import React from "react";

function StatsCardIcon({ name, icon, handleClick, iconStyle }) {
  return (
    <div className="social-icon" onClick={(e) => handleClick()}>
      <img src={icon} alt={name} style={iconStyle} />
    </div>
  );
}
export default StatsCardIcon;
