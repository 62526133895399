import React from "react";
import Track from "./Track";

const TrackInfo = ({ tracks = [], refetch, isOwner }) => {
  return (
    <div className="prTracksCard pb-0">
      <p className="prtcTitle position-relative">Tracks</p>

      {tracks.map((track, trackIndex) => {
        return (
          <Track
            tracks={tracks}
            track={track}
            trackIndex={trackIndex}
            refetch={refetch}
            isReleaseOwner={isOwner}
          />
        );
      })}
    </div>
  );
};

export default TrackInfo;
