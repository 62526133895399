import React from "react";

import credit_card from "../assets/credit_card.svg";

import { setPaymentMethod } from "../../../routes/payments";
import { loadStripe } from "@stripe/stripe-js";
import { getJWT } from "../../utils";
const stripePromise = loadStripe(global.config.REACT_APP_STRIPE_PK);

function EditPaymentMethodComponent({ redirectTo, cardData }) {
  const jwtToken = getJWT();

  function handleChangeMethod() {
    // stripe checkout
    setPaymentMethod(redirectTo, jwtToken).then(async (r) => {
      const stripe = await stripePromise;
      stripe.redirectToCheckout({
        sessionId: r.data.sessionId,
      });
    });
  }
  return (
    <div className="editPayment">
      Your payment method is: <br />
      <br />
      <div className="editPayment-box-wrapper">
        <div className="editPayment-box">
          <div className="editPayment-box-logoBox">
            <img src={credit_card} alt="paypal" />
          </div>
          <div className="editPayment-box-text">
            &nbsp; &nbsp;
            {cardData?.brand} card ending on {cardData?.last4}
          </div>
        </div>
      </div>
      <div className="editPayment-box-comment" onClick={handleChangeMethod}>
        change
      </div>
    </div>
  );
}

export default EditPaymentMethodComponent;
