import React from "react";

import Rect1 from "../assets/Rect1.svg";
import Rect2 from "../assets/Rect2.svg";
import Vector1 from "../assets/Vector1.svg";
import Vector2 from "../assets/Vector2.svg";

function ObDecorations() {
  return (
    <div className="obDecorations">
      <img src={Rect1} alt="playtreks" className="obDecorations-Rect1" />
      <img src={Rect2} alt="playtreks" className="obDecorations-Rect2" />
      <img src={Vector1} alt="playtreks" className="obDecorations-Vector1" />
      <img src={Vector2} alt="playtreks" className="obDecorations-Vector2" />
    </div>
  );
}

export default ObDecorations;
