import React, { useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "../../../components/artistDashboard/TabPanel";
import { Drawer } from "@material-ui/core";
import { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import { useRef } from "react";
import CaretDownIcon from "../../../assets/icons/icon-caret-down.svg";
import Toast from "../../Toast";
import InputComponent from "../../form/InputComponent";
import ExpenseForm from "./ExpenseForm";
import "./index.scss";
import { useQuery } from "react-query";
import { deleteExpense, getExpense } from "../../../services/expenses";
import Spinner from "../../../assets/spinner.svg";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const ExpenseDetails = () => {
  const { expenseId } = useParams();
  const history = useHistory();

  const [value, setValue] = useState(0);
  const [toast, setToast] = useState(initialToastState);

  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const [expenseActionOpen, setExpenseActionOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ isOpen });
  };

  const node = useRef();
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setExpenseActionOpen(false);
  };

  useEffect(() => {
    if (expenseActionOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expenseActionOpen]);

  const {
    data: { data: expense } = {},
    isLoading,
    refetch,
  } = useQuery(["expense", expenseId], () => getExpense(expenseId));

  const handleDelete = async () => {
    let confirmDelete = window.confirm(
      "Are you sure you want to delete these expense?"
    );
    if (confirmDelete) {
      await deleteExpense(expense._id);
      try {
        setToast({
          type: "success",
          open: true,
          message: "Deleted successfully",
        });

        setTimeout(() => {
          history.push("/artistDashboard/expenses");
        }, 1250);
      } catch {
        setToast({
          type: "danger",
          open: true,
          message: "Something went wrong",
        });
      }
    }
  };

  return (
    <main className="myReleases-overview text-white">
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />
      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 admin-release-drawer`}
          role="presentation"
        >
          <div
            onClick={toggleDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span>Edit Expense</span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
          <ExpenseForm
            onUpdate={setDrawerState}
            onClose={toggleDrawer(false)}
            expense={expense}
            edit={true}
            refetch={refetch}
          />
        </div>
      </Drawer>

      {isLoading ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <>
          <div className="row align-items-center justify-content-between px-4 ">
            <div className="contract-details-header fs-32 px-2">
              {expense?.name}
            </div>
            <div
              className="user-profile mb-0"
              style={{ cursor: "pointer" }}
              ref={node}
              onClick={(e) => setExpenseActionOpen(!expenseActionOpen)}
            >
              <div className="delete-contract rounded p-2 fs-10">
                ACTIONS
                <img
                  src={CaretDownIcon}
                  className={` user-profile-caret ${
                    expenseActionOpen ? "up" : ""
                  } `}
                  alt="caret"
                  onClick={(e) => setExpenseActionOpen(!expenseActionOpen)}
                />
              </div>
              <div
                className={`user-profile-dropdown w- ${
                  expenseActionOpen ? "show" : ""
                } `}
              >
                <ul>
                  <li>
                    <div
                      className="user-profile-dropdown-inner"
                      onClick={toggleDrawer(true)}
                    >
                      Edit
                    </div>
                  </li>

                  <li>
                    <div
                      className="user-profile-dropdown-inner"
                      onClick={handleDelete}
                    >
                      Delete
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="prTracksCard w-100 pb-0">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
            >
              <Tab label="Details" {...a11yProps(0)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div className="contract-header mb-3">METADATA</div>

              <div
                className="rounded text-white  p-3"
                style={{ backgroundColor: "#2c313a" }}
              >
                <div className="prrcText  pb-4">
                  Name:{" "}
                  <span className="text-white font-weight-normal ">
                    {expense?.name}
                  </span>
                </div>
                <div className="pb-4 prrcText">
                  Contract:{" "}
                  <span className="text-white font-weight-normal">
                    {expense?.contract?.name}
                  </span>
                </div>
                <div className="prrcText pb-4">
                  Expense Date:{" "}
                  <span className="text-white font-weight-normal">
                    {expense?.expenseDate?.split("T")[0]}
                  </span>
                </div>

                <div className="prrcText">
                  Notes:{" "}
                  <span className="text-white font-weight-normal">
                    {expense?.notes ? expense?.notes : "Not specified"}
                  </span>
                </div>
              </div>

              <div className="contract-header my-3">EXPENSE SPLITS</div>
              <div
                className="rounded text-white  p-3"
                style={{ backgroundColor: "#2c313a" }}
              >
                {expense?.expenseSplits?.map((split, index) => {
                  split.rightholderName =
                    `${split?.rightholder?.contact?.firstName} ${split?.rightholder?.contact?.lastName}`.trim();
                  return (
                    <div key={index}>
                      <div className="row col ">
                        <div className="py-2 col-12  col-xl-3 py-md-0 mb-3">
                          <div
                            className={
                              (split?.rightholderName ? "" : "d-none") +
                              "inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            Contact
                          </div>
                          <div className="postion-relative">
                            <InputComponent
                              disabled={true}
                              value={split?.rightholderName}
                              commentField={".utiuc3_" + index}
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (split?.rightholderName
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                            />
                          </div>
                        </div>
                        {/* Paid amount */}
                        <div className="py-2 col-12  col-xl-3 py-md-0 mb-3">
                          <div
                            className={
                              (split?.paidAmount ? "" : "d-none") +
                              " inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            Paid amount
                          </div>
                          <div className="position-relative">
                            <InputComponent
                              disabled={true}
                              value={split?.paidAmount}
                              inputType="number"
                              commentField={".utiuc3_" + index}
                              placeholder="Share"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (split?.paidAmount
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                            />
                          </div>
                        </div>
                        {/* Expense share */}
                        <div className="py-2 col-12  col-xl-3 py-md-0 mb-3">
                          <div
                            className={
                              (split?.expenseShare ? "" : "d-none") +
                              " inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            Expense share
                          </div>
                          <div className="position-relative">
                            <InputComponent
                              disabled={true}
                              value={split?.expenseShare}
                              inputType="number"
                              commentField={".utiuc3_" + index}
                              placeholder="Share"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (split?.expenseShare
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                            />
                          </div>
                        </div>
                        {/* recoupable amount */}
                        <div className="py-2 col-12  col-xl-3 py-md-0 mb-3">
                          <div
                            className={
                              (split?.recoupableAmount ? "" : "d-none") +
                              " inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            Amount Recoupable
                          </div>
                          <div className="position-relative">
                            <InputComponent
                              disabled={true}
                              value={split?.recoupableAmount.toFixed(2)}
                              inputType="number"
                              commentField={".utiuc3_" + index}
                              placeholder="Share"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (split?.recoupableAmount
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TabPanel>
          </div>
        </>
      )}
    </main>
  );
};

export default ExpenseDetails;
