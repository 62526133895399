import React, { useEffect, useState } from "react";
import { getPaymentMethod } from "../../../routes/payments";

// REDU
import { useSelector, useDispatch } from "react-redux";

import X from "../../distribution/assets/X.svg";
import PerformPaymentComponent from "../../payments/components/PerformPaymentComponent";
import SetPaymentMethodComponent from "../../payments/components/SetPaymentMethodComponent";

import { useLocation } from "react-router-dom";
import PerformPaypalPaymentComponent from "../../payments/components/PerformPaypalPaymentComponent";

import spinner from "../../../assets/spinner.svg";
import { getJWT } from "../../utils";

const BUNDLE = 0;
const DATA_DASHBOARD = 1;
const DISTRIBUTION = 2;

function PaymentModal({
  setPaymentModalState,
  paymentModalState,
  redirectTo = window.location.pathname,
  additionalQuery,
  additionalQueryKey,
  closeCallback = () => {},
}) {
  const jwtToken = getJWT();

  var reduxPaymentProcessor = useSelector(
    (state) => state.subscriptionReducer?.subscription?.processor
  );

  const reduxCard = useSelector(
    (state) => state.subscriptionReducer?.subscription?.card
  );

  const usedArtistSlots = useSelector(
    (state) => state.distributionReducer?.usedArtistSlots
  );
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  function handleClose() {
    closeCallback();
    setPaymentModalState({
      ...paymentModalState,
      open: false,
    });
  }

  // only get product from query if it's a string. If it returns "null" it's an object,
  // and we prevent null evaluating to 0, which would mean the user buys the BUNDLE
  const productFromQuery =
    typeof query.get("product") === "string"
      ? Number(query.get("product"))
      : false;

  const paymetProcessorPresent =
    reduxPaymentProcessor !== undefined && reduxPaymentProcessor !== null;
  const paymentCardPresent = reduxCard !== undefined && reduxCard !== null;

  return (
    <div className="playtreksModal-overlay">
      <div className="playtreksModal-modal">
        <img
          src={X}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={handleClose}
        />
        {/* if payment processor in redux, means user has commited to paying with either stripe or paypal */}
        {paymetProcessorPresent && paymentCardPresent ? (
          <div className="wpPaymentModal-methodPicker">
            <PerformPaymentComponent
              product={productFromQuery || paymentModalState.product}
              productDisplay={
                paymentModalState.productDisplay || productFromQuery
              }
              redirectTo={
                redirectTo + (additionalQuery ? "?" + additionalQuery : "")
              }
              paymentModalState={paymentModalState}
            />
          </div>
        ) : !paymetProcessorPresent ||
          (paymetProcessorPresent && !paymentCardPresent) ? (
          // In case we manually added the subscription in Stripe, the paymentProcessor is
          // set to Stripe but the card is not present.
          <div className="wpPaymentModal-methodPicker">
            <br />
            <div className="playtreksModal-modal-header">
              Please pick your payment method
            </div>
            <br />
            <SetPaymentMethodComponent
              redirectTo={
                redirectTo +
                "?pay=true&processor=stripe&product=" + // is only stripe at the moment
                (productFromQuery || paymentModalState.product) +
                (additionalQuery ? additionalQuery : "")
              }
            />
            <PerformPaypalPaymentComponent
              redirectTo={redirectTo}
              productId={productFromQuery || paymentModalState.product}
              className="setPaymentMethod-button"
              textClassName="setPaymentMethod-button-text"
              logo={true}
              paymentModalState={paymentModalState}
            />
            <div className="setPaymentMethod-button-comment mt-2">
              You will be safely redirected to checkout.
            </div>
          </div>
        ) : (
          <div className="xyFlexCenter wpPaymentModal-methodPicker-loading">
            <img src={spinner} />
          </div>
        )}
      </div>
    </div>
  );
}

export default PaymentModal;
