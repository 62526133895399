import { REDUX_SET_SUBSCRIPTION_PLAN } from "../actions/subscriptionActions";

const HISTORY = "history";
const USER = "user";

const SPOTIFY = "spotify";

var initialState = {
  subscription: {},
};

function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_SET_SUBSCRIPTION_PLAN:
      state.subscription = action.subscription;
      return state;
    default:
      return state;
  }
}

export default subscriptionReducer;
