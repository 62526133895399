import React from "react";
import ReactDOM from "react-dom";
// import "./index.css";
import "./assets/scss/argon-dashboard-pro-react.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./config.js";

// REDUX

import { Provider } from "react-redux";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import distributionReducer from "./redux/reducers/distributionReducer";
import dashboardReducer from "./redux/reducers/dashboardReducer";
import subscriptionReducer from "./redux/reducers/subscriptionReducer";
import appReducer from "./redux/reducers/appReducer";
import earningsReducer from "./redux/reducers/earningsReducer";
import moneyReducer from "./redux/reducers/moneyReducer";
import { QueryClient, QueryClientProvider } from "react-query";

// Create a client
const queryClient = new QueryClient();

let composeEnhancer = compose;

// Enable redux dev tools if requested
if (process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS)
  composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  distributionReducer: distributionReducer,
  dashboardReducer: dashboardReducer,
  subscriptionReducer: subscriptionReducer,
  appReducer: appReducer,
  earningsReducer: earningsReducer,
  moneyReducer: moneyReducer,
});

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
