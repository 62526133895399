import React from "react";

import envelope from "../../assets/icons/envelope2.svg";

function UserBlockingModal({ msg }) {
  function handleLogout() {
    window.localStorage.removeItem("jwtToken");
    document.cookie = `jwtToken= ; path=/; max-age=${0}; secure`;
    window.location.reload();
  }

  return (
    <div>
      <div className="playtreksModal-overlay">
        <div className="playtreksModal-modal">
          {msg}
          <div className="playtreksModal-blockedModalControls">
            <a href="mailto:support@playtreks.com">
              <span className="playtreksModal-blockedModalControls-left">
                contact support{" "}
                <img
                  src={envelope}
                  className="playtreksModal-blockedModalControls-left-envelope"
                />
              </span>
            </a>
            <span
              className="playtreksModal-blockedModalControls-right"
              onClick={handleLogout}
            >
              log out
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserBlockingModal;
