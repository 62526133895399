import React, { useState } from "react";

import { setUserFacebookArtistPage } from "../../../../routes/users";

import X from "../../../distribution/assets/X.svg";

import FbPagePicker from "../../../onboarding/components/FbPagePicker";
import { getJWT } from "../../../utils";

const FACEBOOK = "facebook";

function AddFbPageModal({ closeModalCallback, className, connectPlatformUrl }) {
  const jwtToken = getJWT();

  const [pageId, setPageId] = useState("");

  function handleConnect() {
    setUserFacebookArtistPage(pageId, jwtToken).then((r) =>
      closeModalCallback(true, FACEBOOK)
    ); // true if refresh artist
    // error is being handled inside FbPagePicker
  }

  function pickedPageIdCallback(pageId) {
    setPageId(pageId);
  }

  return (
    <div
      className={
        "playtreksModal-overlay playtreksModal-overlay-insideDashboard playtreksModal-overlay-insideEditProfile " +
        className
      }
    >
      <div className="playtreksModal-modal playtreksModal-modal-insideDashboard playtreksModal-modal-insideEditProfile">
        <div className="playtreksModal-modal-header-editProfile">
          Pick your Facebook page
        </div>
        <img
          src={X}
          alt="close"
          className="playtreksModal-modal-close playtreksModal-modal-close-insideEditProfile"
          onClick={() => closeModalCallback(false, FACEBOOK)}
        />
        <FbPagePicker
          pickedPageIdCallback={pickedPageIdCallback}
          className="playtreksModal-modal-insideEditProfile-fbPagePicker"
          connectPlatformUrl={connectPlatformUrl}
        />

        <div
          className={
            pageId ? "playtreksButton addSpotifyArtistButton" : "d-none"
          }
          onClick={handleConnect}
        >
          Connect
        </div>
      </div>
    </div>
  );
}

export default AddFbPageModal;
