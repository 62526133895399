import React from "react";

function Footer() {
  return (
    <div className="obFooter">
      <span className="obFooterLeft">
        <span style={{ color: "#5e72e4" }}>&#169; 2021</span> &nbsp;
        <span style={{ color: "#525f7f" }}>
          Playtreks BV, Hendrik Van Velde Singel 150, 3500 Hasselt, Belgium;
          Company No. BE0749505934
        </span>
      </span>
      <span className="obFooterRight">
        {/* About us &nbsp;&nbsp;&nbsp;&nbsp; Item &nbsp;&nbsp;&nbsp;&nbsp; Item */}
      </span>
    </div>
  );
}

export default Footer;
