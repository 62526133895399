import React, { useEffect, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { artistsJoined } from "../../utilsJSX";

import { get as sliceObject } from "lodash";

import TrackFeatures from "../TrackFeatures";
import TrackFeaturesCard from "../TrackFeatures/TrackFeaturesCard";
import TrackList from "../TrackList/TrackList";

import { belowGraphCards as cards } from "./albumFeaturesCardsConfig";

import "./index.scss";
import { Link } from "react-router-dom";
import { isIframe, setPath } from "../../utils";

function AlbumFeatures({
  mainEntity,
  sectionName = "Album features",
  CustomHeader = () => <div></div>,
  linksPathname,
}) {
  const [trackHover, setTrackHover] = useState();

  const albumFeatureAverage = mainEntity?.trackFeaturesAggregations?.average;
  const trackFeatures = mainEntity?.trackFeatures;
  const entityType = mainEntity?.type || "album";

  useEffect(() => {
    setTrackHover();
  }, [mainEntity]);

  function handleTrackHover(index) {
    if (trackHover !== undefined) {
      document
        .querySelector("#track-list-item" + trackHover)
        .classList.remove("track-list-item-active");
    }

    setTrackHover(index);
    document
      .querySelector("#track-list-item" + index)
      .classList.add("track-list-item-active");
  }

  function handleResetTrackHover() {
    setTrackHover();
    if (trackHover !== undefined)
      document
        .querySelector("#track-list-item" + trackHover)
        .classList.remove("track-list-item-active");
  }

  var isTrackHovered = trackHover !== false && trackHover !== undefined;

  var dataTarget = isTrackHovered
    ? trackFeatures?.[trackHover]
    : albumFeatureAverage;

  var title = isTrackHovered ? (
    trackHover + 1 + ". " + trackFeatures?.[trackHover]?.name
  ) : (
    <div>
      {mainEntity?.name || ""}{" "}
      <small style={{ color: "grey", float: "right" }}>
        {entityType} averages
      </small>
    </div>
  );

  var trackArtists = isTrackHovered
    ? artistsJoined(trackFeatures?.[trackHover]?.artists, linksPathname)
    : "";

  return (
    <span>
      {<CustomHeader /> || (
        <span className="dashboard-overview-streaming-stats-header-left">
          <span
            className="track-features-left-content-header-emoji"
            role="img"
            aria-label={"album features"}
          >
            {"⚗️"}
          </span>
          <label>&nbsp;{sectionName}</label>
        </span>
      )}
      <span className="album-features">
        <TrackList
          handleTrackHover={handleTrackHover}
          trackFeatures={trackFeatures}
          linksPathname={linksPathname}
          scrollableMode
        />
        <span className="album-features-graph">
          <div className="album-features-graph-title mb-1">
            {title}{" "}
            <span
              className={
                "float-right cursor-pointer " + (!isTrackHovered && "d-none")
              }
              onClick={() => handleResetTrackHover()}
            >
              🔄
            </span>
          </div>
          <TrackFeatures featureData={dataTarget} shortLabels />
          {isTrackHovered && (
            <span className="album-features-graph-under-section">
              <span className="album-features-graph-under-section-key">
                Track artists:{" "}
              </span>
              <span className="album-features-graph-under-section-value">
                {trackArtists}
              </span>
            </span>
          )}
          {isTrackHovered && (
            <span className="album-features-graph-under-cards">
              {cards.map((card, i) => {
                if (i > 1) return; // making sure only two get fed into here (albumFeaturesCardsConfig)
                return (
                  <TrackFeaturesCard
                    className="album-features-graph-under-cards-card"
                    name={card.name}
                    objectPath={sliceObject(
                      trackFeatures?.[trackHover],
                      card?.objectPath
                    )}
                    cardIcon={""}
                  />
                );
              })}
              <div className="album-features-graph-under-cards-card album-features-graph-under-cards-card-link textLink">
                <Link
                  target={isIframe() ? "_blank" : "_self"}
                  to={setPath(
                    trackFeatures?.[trackHover]?.id,
                    trackFeatures?.[trackHover]?.name,
                    "track",
                    linksPathname
                  )}
                >
                  show track →
                </Link>
              </div>
            </span>
          )}
        </span>
      </span>
    </span>
  );
}

export default AlbumFeatures;
