import React, { useState, memo } from "react";
import StatsCardIcon from "../../../components/artistDashboard/StatsCardIcon";
import StatsCard from "../../../components/artistDashboard/StatsCard";
import { get as sliceObject } from "lodash";
import Spinner from "../../../assets/spinner.svg";
//card config
import cards from "./ChartSummaryStatsConfig";
import HorizontalScrollButtonGroup from "../../../components/artistDashboard/HorizontalScrollButtonGroup";
import { isEqual } from "lodash";

const HORIZONTAL_SCROLL_SIZE = 200;

const ChartSummaryStats = memo(({ data = {}, loading }) => {
  const [streamingStatsBtn, setStreamingStatsBtn] = useState({
    left: true,
    right: false,
    scrollLeft: 1,
  });
  const stats = Object.keys(data || {}).reduce((acc, key) => {
    if (data[key].length) {
      acc[key] = data[key];
    }
    return acc;
  }, {});

  function handleStreamingStatScroll(element, direction) {
    const scrollVal =
      direction === "left"
        ? element.scrollLeft - HORIZONTAL_SCROLL_SIZE
        : element.scrollLeft + HORIZONTAL_SCROLL_SIZE;
    setStreamingStatsBtn((state) => ({
      left: element.scrollLeft === 0,
      right: state.scrollLeft > 0 && state.scrollLeft === scrollVal,
      scrollLeft: scrollVal,
    }));
    direction === "left"
      ? (element.scrollLeft -= HORIZONTAL_SCROLL_SIZE)
      : (element.scrollLeft += HORIZONTAL_SCROLL_SIZE);
  }

  return (
    <section className="streaming-stats-overview-streaming-stats">
      <div>
        <div className="dashboard-overview-streaming-stats-header">
          <span className="dashboard-overview-streaming-stats-header-left">
            <label>summary stats</label>
            <span
              className="dashboard-overview-streaming-stats-header-emoji"
              role="img"
              aria-label={"chart with upwards trend"}
            >
              {"📈"}
            </span>
          </span>
          <div className="dashboard-overview-streaming-stats-header-scroll">
            <HorizontalScrollButtonGroup
              disableLeft={streamingStatsBtn["left"]}
              disableRight={streamingStatsBtn["right"]} // no disabling arrows (problematic)
              handleScroll={(direction) =>
                handleStreamingStatScroll(
                  document.querySelector(
                    ".dashboard-overview-streaming-stats-cards"
                  ),
                  direction
                )
              }
            />
          </div>
        </div>

        {loading && (
          <div className="dashboard-overview-streaming-stats-spinner-wrapper">
            <img
              src={Spinner}
              alt="loading"
              className="dashboard-overview-streaming-stats-spinner"
            />
          </div>
        )}

        {Object.keys(stats).length ? (
          <div className=" dashboard-overview-streaming-stats-cards mt-1">
            {cards.map((card) => (
              <StatsCard
                className=" social-card dashboard-overview-streaming-stats-cards-card "
                name={card.name}
                platform={card.platform}
                forEntityCharts
                comingSoon={false}
                // objectPathDelta={}
                objectPath={sliceObject(stats, card.objectPath)}
              >
                <StatsCardIcon name={card.name} icon={card.icon} />
              </StatsCard>
            ))}
          </div>
        ) : (
          !loading && (
            <div className="row justify-content-center">
              <p className="text-white font-weight-bold">No stats found</p>
            </div>
          )
        )}
      </div>
    </section>
  );
}, isEqual);

export default ChartSummaryStats;
