import React from "react";

import { useSelector } from "react-redux";

function ContributorsFieldComponent({
  i,
  SelectComponent,
  contributorsOptions,
  InputComponent,
  contributorsArray,
  trash,
  inputChangeCallback,
  inputBlurCallback,
  handleRemoveInput,
  handleAddContributor,
  trackIndex,
  selectBlurCallback,
}) {
  const reduxCurrentTrackForm = useSelector(
    (state) => state.distributionReducer?.tracksForm?.[trackIndex]?.form
  );

  function handleSelectChange(e) {
    inputChangeCallback(
      e, // value
      "#participants",
      "contributors",
      i,
      "type" // type
    );
  }

  function handleInputChange(e) {
    inputChangeCallback(
      e, // value
      "#participants",
      "contributors",
      i,
      "name" // type
    );
  }

  return (
    <div className="utepContributorsWrapper" id={"contributorsWrapper" + i}>
      <div className="inputUpperComment utiuc4 playtreksSelector">
        Contributor(s)
      </div>
      <SelectComponent
        value={useSelector(
          (state) =>
            state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
              "#participants"
            ]?.contributors?.[i]?.type
        )}
        placeholder="Select role"
        inputGroupClassName="utepContributorsSelectGroup"
        shortInput={true}
        valuePaddingTop={true}
        placeholderTop="60%"
        placeholderFont="Inter-Medium"
        commentField=".utiuc5b"
        placeholderColor="white"
        placeholderOpacity="1"
        selectChangeCallback={handleSelectChange} // to be used for data transfer
        options={contributorsOptions}
        databaseField="#participants.contributors"
        databaseKey="contributors"
        databaseSubKey="type"
        selectBlurCallback={selectBlurCallback}
      />
      <div className="utepExpandableFieldWrapper">
        <div
          className={
            (reduxCurrentTrackForm?.["#participants"]?.contributors?.[i]?.name
              ? ""
              : "d-none") +
            " inputUpperComment utepCommentFieldFixer-contr utepCommentFieldFixer-contr-name utiuc4a utiuc4a_" +
            i
          }
        >
          Name*
        </div>
        <InputComponent
          value={useSelector(
            (state) =>
              state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                "#participants"
              ]?.contributors?.[i]?.name
          )}
          paddingTopOnInput={true}
          commentField={".utiuc4a_" + i}
          placeholder="Name*"
          inputGroupClassName="utepContributorsNameInputGroup nrInputGroup"
          inputClassName={
            "utepInput utepFieldFixer" +
            (reduxCurrentTrackForm?.["#participants"]?.contributors?.[i]?.name
              ? " interMediumInput interMediumPaddingTop"
              : "")
          }
          addon={false}
          changeCallback={handleInputChange}
          inputBlurCallback={inputBlurCallback} // keep empty
          addonClickCallback={handleRemoveInput}
          id={i}
          for="contributors"
          databaseField="#participants"
          databaseKey="contributors"
          databaseSubKey="name"
        />
      </div>

      {i === contributorsArray.length - 1 ? (
        <div className="utepPlus utepPlus-2" onClick={handleAddContributor}>
          +
        </div>
      ) : (
        ""
      )}

      {i !== 0 ? (
        <div
          className="utepTrash utepTrash-2"
          onClick={() => handleRemoveInput("contributors", i)}
        >
          <img src={trash} alt="delete contributor" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ContributorsFieldComponent;
