import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

export async function registerUser(userData) {
  if (!userData) return;

  let url = `${global.config.API_URL}/api/user/register`;

  return axios.post(url, userData);
}

export async function loginUser(userData) {
  if (!userData) return;

  let url = `${global.config.API_URL}/api/user/login`;

  return axios.post(url, userData);
}

export function setSpotifyArtist(spotifyID, jwtToken) {
  let url = `${global.config.API_URL}/api/externalLogin/userSpotifyArtist`;

  return axios.post(
    url,
    {
      service: "spotify",
      spotifyID: spotifyID,
    },
    {
      headers: {
        Authorization: "Bearer " + getJWT(),
      },
    }
  );
}

export function deleteSpotifyProfile(spotifyID, jwtToken) {
  let url = `${global.config.API_URL}/api/externalLogin/userSpotifyArtist`;

  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
    data: {
      service: "spotify",
      spotifyID: spotifyID,
    },
  });
}

export function getConnectedAccounts(service, jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/connectedPlatforms?service=${service}`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getFbProfiles(jwtToken, onlyUserSelected, options) {
  const url = `${global.config.API_URL}/api/externalLogin/getFbProfiles`;
  const isInstagram = options?.isInstagram;
  const config = {
    params: {
      service: "facebook",
      onlyUserSelected: onlyUserSelected, // if id provided, the route will return only one page; otherwise all users pages
      isInstagram: isInstagram,
    },
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getSpotifyProfile(jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/getSpotifyProfile`;
  const config = {
    params: {
      service: "spotify",
    },
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getUserData() {
  const url = `${global.config.API_URL}/api/user/getUserData`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getEmailVerificationStatus(jwtToken) {
  const url = `${global.config.API_URL}/api/user/getEmailVerificationStatus`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getUserMessage(jwtToken) {
  const url = `${global.config.API_URL}/api/user/getUserMessage`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function getUserEmail(jwtToken) {
  const url = `${global.config.API_URL}/api/user/getUserEmail`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.get(url, config);
}

export function updateUserProfile(newUserObject, jwtToken) {
  const url = `${global.config.API_URL}/api/user/updateUserProfile`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return axios.post(
    url,
    { ...newUserObject, country: newUserObject.country.value },
    config
  );
}

export function uploadProfilePictureToS3(file, jwtToken) {
  if (!file) {
    throw new Error("Select a file first!");
  }

  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", "profilePicture");
  formData.append("bucket", "users");

  let url = `${global.config.API_URL}/api/user/uploadToS3`;
  return axios.post(url, formData, {
    headers: {
      Authorization: "Bearer " + getJWT(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export function protectedRoute(jwtToken) {
  const url = `${global.config.API_URL}/api/user/protected`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };
  return axios.get(url, config);
}

export function getUserFacebookArtistPage(jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/userFacebookArtistPage`;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };
  return axios.get(url, config);
}

export function setUserFacebookArtistPage(facebookPageId, jwtToken, options) {
  const url = `${global.config.API_URL}/api/externalLogin/userFacebookArtistPage`;
  const isInstagram = options?.isInstagram;
  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };
  return axios.post(
    url,
    {
      facebookPageId: facebookPageId,
      isInstagram: isInstagram,
      service: "facebook",
    },
    config
  );
}

export function deleteUserFacebookArtistPage(facebookPageId, jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/userFacebookArtistPage`;

  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
    data: { facebookPageId: facebookPageId, service: "facebook" },
  });
}

export function deleteUserInstagramArtistPage(instagramPageId, jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/userInstagramArtistPage`;

  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
    data: { facebookPageId: instagramPageId, service: "instagram" },
  });
}

export function deleteUserAppleArtistPage(applePageId, jwtToken) {
  const url = `${global.config.API_URL}/api/externalLogin/userAppleArtistPage`;

  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
    data: { applePageId: applePageId, service: "apple" },
  });
}

export function disconnectPlatform(jwtToken, service) {
  let url = `${global.config.API_URL}/api/externalLogin/disconnectPlatform`;

  return axios.delete(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
    data: {
      service: service,
    },
  });
}

export async function resetPasswordRequest(userData) {
  if (!userData) return;

  let url = `${global.config.API_URL}/api/user/resetPasswordRequest`;

  return axios.post(url, userData);
}

export async function validateResetPasswordGuid(guid) {
  if (!guid) return;

  let url = `${global.config.API_URL}/api/user/validateGuid`;

  return axios.post(url, guid);
}

export async function resetPassword(userData) {
  if (!userData) return;

  let url = `${global.config.API_URL}/api/user/resetPassword`;

  return axios.post(url, userData);
}

export async function validateRegisteredGuid(guid) {
  if (!guid) return;

  let url = `${global.config.API_URL}/api/user/validateRegisteredUserGuid`;

  return axios.post(url, guid);
}

export async function resendValidationEmail(jwtToken) {
  let url = `${global.config.API_URL}/api/user/resendValidationEmail`;

  const config = {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  };

  return await axios.post(url, {}, config);
}
