import React, { useCallback, useEffect, useMemo } from "react";
import ColumnChart from "../../../HighCharts/ColumnChart";
import { getTotal } from "./lib/total_calc";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../../../screens/Admin/Releases/components/TableView";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";
import { useState } from "react";
const INITIAL_PAGE_SIZE = 25;

function UserAggregation({
  userSalesData,
  newUsersSalesData,
  loading,
  isAdmin,
}) {
  // total values calculations
  let totalSales = getTotal(userSalesData, "totalNetPayable"); // true for toFixed
  let totalStreams = getTotal(userSalesData, "totalStreams");
  let totalNewSales = getTotal(newUsersSalesData, "totalNetPayable");
  let totalNewStreams = getTotal(newUsersSalesData, "totalStreams");
  const formatNumber = new Intl.NumberFormat().format;
  if (totalSales) totalSales = totalSales?.toFixed(2);
  if (totalNewSales) totalNewSales = totalNewSales?.toFixed(2);

  const [excludedUsers, setExcludedUsers] = useState([]);

  const [isChecked, setIsChecked] = useState({});

  const [totalSalesState, setTotalSalesState] = useState(totalSales);
  const [totalNewSalesState, setTotalNewSalesState] = useState(totalNewSales);

  const [totalStreamsState, setTotalStreamsState] = useState(totalStreams);
  const [totalNewStreamsState, setTotalNewStreamsState] =
    useState(totalNewStreams);

  let unionIds = {};
  [...(newUsersSalesData || []), ...(userSalesData || [])].forEach((item) => {
    if (!unionIds[`${item._id}`]) {
      unionIds[`${item._id}`] = item._id;
    }
  });

  let combinedSales = [];
  Object.values(unionIds).forEach((id) => {
    const oldSalesData = userSalesData?.find(
      (i) => i._id === id && i._id === id
    );
    const newSalesData = newUsersSalesData?.find(
      (i) => i._id === id && i._id === id
    );

    const oldNetPayable = oldSalesData?.totalNetPayable ?? 0;
    const newNetPayable = newSalesData?.totalNetPayable ?? 0;
    const difference = newNetPayable - oldNetPayable;
    let differencePercentage = 0;
    if (oldNetPayable > 0) {
      differencePercentage = (difference / oldNetPayable) * 100;
    }

    combinedSales.push({
      _id: id,
      totalStreams: oldSalesData?.totalStreams ?? 0,
      totalNetPayable: oldSalesData?.totalNetPayable ?? 0,
      totalNewStreams: newSalesData?.totalStreams ?? 0,
      totalNewNetPayable: newSalesData?.totalNetPayable ?? 0,
      differencePercentage,
      difference,
    });
  });

  const combinedUserSales = useMemo(
    () => combinedSales ?? [],
    [newUsersSalesData, userSalesData]
  );

  let totalDifference = combinedUserSales.reduce((acc, item) => {
    return acc + item.difference;
  }, 0);

  let totalDifferencePercentage = combinedUserSales.reduce((acc, item) => {
    return acc + item.differencePercentage;
  }, 0);

  const [totalDifferenceState, setTotalDifferenceState] =
    useState(totalDifference);
  const [totalDifferencePercentageState, setTotalDifferencePercentageState] =
    useState(totalDifferencePercentage);

  const handleExcludeUser = (id, event) => {
    setIsChecked((isChecked[id] = event.target.checked));
    setExcludedUsers((prevExcludedUsers) => {
      if (prevExcludedUsers.includes(id)) {
        return prevExcludedUsers.filter((i) => i !== id);
      } else {
        return [...prevExcludedUsers, id];
      }
    });
  };

  useEffect(() => {
    const uncheckedUsers = combinedUserSales.filter(
      (user) => !excludedUsers.includes(user._id)
    );
    totalSales = getTotal(uncheckedUsers, "totalNetPayable");
    totalStreams = getTotal(uncheckedUsers, "totalStreams");
    totalNewSales = getTotal(uncheckedUsers, "totalNewNetPayable");
    totalNewStreams = getTotal(uncheckedUsers, "totalNewStreams");
    if (totalSales) totalSales = totalSales?.toFixed(2);
    if (totalNewSales) totalNewSales = totalNewSales?.toFixed(2);

    totalDifference = uncheckedUsers.reduce((acc, item) => {
      return acc + item.difference;
    }, 0);

    totalDifferencePercentage = uncheckedUsers.reduce((acc, item) => {
      return acc + item.differencePercentage;
    }, 0);

    setTotalSalesState(totalSales);
    setTotalNewSalesState(totalNewSales);
    setTotalStreamsState(totalStreams);
    setTotalNewStreamsState(totalNewStreams);
    setTotalDifferenceState(totalDifference);
    setTotalDifferencePercentageState(totalDifferencePercentage);
  }, [excludedUsers]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "exclude",
        Cell: ({ row }) => {
          return (
            <input
              type="checkbox"
              checked={isChecked[row.original._id]}
              onChange={(e) => handleExcludeUser(row.original._id, e)}
            />
          );
        },
      },

      {
        Header: "User email",
        accessor: "_id",
        Cell: ({ value }) => {
          return value;
        },
      },
      {
        Header: "Streams (old)",
        accessor: "totalStreams",
        Cell: ({ value }) => {
          return formatNumber(value ?? 0);
        },
      },
      {
        Header: "Streams (new)",
        accessor: "totalNewStreams",
        Cell: ({ value }) => {
          return formatNumber(value);
        },
      },
      {
        Header: "Revenue (old)",
        accessor: "totalNetPayable",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },
      {
        Header: "Revenue (new)",
        accessor: "totalNewNetPayable",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },

      {
        Header: "Difference amount",
        accessor: "difference",
        Cell: ({ value }) => {
          return <td title={value || 0}>€ {value?.toFixed(2) ?? 0}</td>;
        },
      },

      {
        Header: "Difference %",
        accessor: "differencePercentage",
        Cell: ({ value }) => {
          return <td title={value || 0}>{value?.toFixed(2) ?? 0}%</td>;
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: combinedUserSales,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <>
      <div
        style={{
          backgroundColor: "#33373f",
        }}
        className="rounded"
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          className="col-12   p-3 mb-3"
        />
      </div>

      <TableView
        tableInstance={tableInstance}
        loading={loading}
        totalStreams={totalStreamsState}
        totalNewStreams={totalNewStreamsState}
        totalNewSales={totalNewSalesState}
        totalSales={totalSalesState}
        salesData={true}
        totalDifference={isAdmin ? totalDifferenceState.toFixed(2) : null}
        totalDifferencePercentage={
          isAdmin ? totalDifferencePercentageState.toFixed(2) : null
        }
        checkbox={true}
      />
    </>
  );
}

export default UserAggregation;
