import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Arrow from "../../../../assets/icons/selectInputArrow.svg";
import TrackMeta from "./TrackMeta";

const TracksMetaSection = ({
  userEmail = "",
  releaseId,
  tracks = [],
  onUpdate,
}) => {
  return (
    <div
      className="pb-0"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        borderRadius: "10px",
      }}
    >
      <p className="prtcTitle position-relative fs-24">Tracks:</p>
      {tracks?.map((track, trackIndex) => {
        return (
          <div className="p-3" key={trackIndex}>
            <Accordion
              className="rounded text-white"
              style={{ backgroundColor: "#2c313a" }}
            >
              <AccordionSummary
                expandIcon={
                  <img
                    src={Arrow}
                    alt="expand"
                    style={{ width: "14px", height: "14px" }}
                  />
                }
                id="panel1a-header"
              >
                <div class="prrcText font-weight-normal">
                  Track:{" "}
                  <span class="prrcWhiteText">
                    {track.form["#track_title"]}
                  </span>
                </div>
              </AccordionSummary>

              <AccordionDetails className="d-block">
                <TrackMeta
                  releaseId={releaseId}
                  userEmail={userEmail}
                  track={track}
                  trackIndex={trackIndex}
                  onUpdate={onUpdate}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default TracksMetaSection;
