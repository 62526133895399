import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import { pickBy } from "lodash";

// import Sidebar from "../artistDashboard/sidebar";
import GoodMorning from "./components/GoodMorning";
import UserProfile from "../artistDashboard/UserProfile";
import UserIcon from "../../assets/icons/user.svg";

import Box from "./components/Box";

import BoxPic1 from "./assets/banner_musicdistribution.png";
import BoxPic2 from "./assets/banner_datadashboard.png";
import BoxPic3 from "./assets/banner_copyrightcontrol.png";
import BoxPic4 from "./assets/banner_theplaygrounds.png";

import "./welcomePage.scss";

import {
  getEmailVerificationStatus,
  getUserData,
  resendValidationEmail,
} from "../../routes/users";

import MobileMenuBurger, {
  MobileMenu,
} from "../artistDashboard/MobileMenu/MobileMenu";
import CustomAlert from "../customAlert";

import playtreksTransparentBg from "../../assets/playtreksTransparentBg.png";
import { getJWT, planMap } from "../utils";

const LOADING = "loading";
const INITIAL = "initial";
const TRIAL_ACTIVE = "trialActive";
const TRIAL_EXPIRED = "trialExpired";
const SUBSCRIBED = "subscribed";

function WelcomePage() {
  const jwtToken = getJWT();
  window.localStorage.removeItem("artists_search_term");

  // those two state variables control the whole state of the page - make them depend on the DB
  const [distributionState, setDistributionState] = useState(LOADING);
  const [analyticsState, setAnalyticsState] = useState(LOADING);

  // user data
  const [userData, setUserData] = useState("");

  const [verifyAccountAlert, setVerifyAccountAlert] = useState(false);

  // email verification
  const [verificationResent, setVerificationResent] = useState(false);

  //user message
  const [userMsg, setUserMsg] = useState(false);

  useEffect(() => {
    getUserData(jwtToken).then((r) => {
      setUserData(r);
      if (r.data.userMsg && r.data.userMsgType === "Information") {
        setUserMsg(r.data.userMsg);
      }
    });
    getEmailVerificationStatus(jwtToken).then((r) =>
      setVerifyAccountAlert(!r.data)
    );
  }, []);

  // subsciption logic
  const reduxSubscription = useSelector(
    (state) => state.subscriptionReducer?.subscription
  );

  const reduxSubscriptionName =
    planMap?.[reduxSubscription?.dataSubscription?.plan]?.name;

  const boxesContent = {
    text1: () => (
      <p className="wpBox-text">
        <div className="wpBox-text-header">Music Distribution</div>
        Drop your music on the world's most important digital music stores. Keep
        track of your performance and easily split earnings with collaborators.{" "}
        <br />
        <br />
      </p>
    ),
    text2: () => (
      <p className="wpBox-text wpBox-text-grey">
        <div className="wpBox-text-header wpBox-text-grey">Data Dashboard</div>
        Our data dashboard is undergoing a transformative rebuild to deliver
        even more powerful insights. During this period, we regretfully cannot
        accept new subscriptions. Stay tuned for our exciting relaunch!
        <br />
        <br />
      </p>
    ),
    text3: () => (
      <p className="wpBox-text">
        <div className="wpBox-text-header">Copyright Control</div>
        Secure your songs, ideas, riffs or any other content by registering your
        ownership in a smart contract. Permanently stored and timestamped on the
        blockchain. <br />
        <br />
      </p>
    ),
    text4: () => (
      <p className="wpBox-text">
        <div className="wpBox-text-header">
          The PlayGrounds - NFT marketplace
        </div>
        Digital NFT marketplace tailored to artists and their fans. Publish your
        music, art, video or exclusive digital content as a non-fungible token
        (NFT). Buy, sell, discover and expose your crypto collectibles. <br />
        <br />
      </p>
    ),
    tag1: () => (
      <p className="wpBox-tag">
        <div className="wpBox-tag-sub">current plan:</div>
        <br />
        <div className="wpBox-tag-header">{reduxSubscriptionName}</div>
      </p>
    ),
    tag2: () => (
      <p className="wpBox-tag">
        <div className="wpBox-tag-sub">current plan:</div>
        <br />
        <div className="wpBox-tag-header">
          {reduxSubscriptionName === "Free"
            ? "Demo"
            : reduxSubscriptionName !== undefined // not if loading
            ? "Unlimited"
            : ""}
        </div>
      </p>
    ),
    actions1: () => (
      <p className="wpBox-actions">
        <Link
          to="/artistDashboard/subscriptions"
          className="wpBox-actions-left"
        >
          Upgrade
        </Link>
        <Link to="/artistDashboard/myReleases" className="wpBox-actions-right">
          Go to distribution
        </Link>
      </p>
    ),
    actions2: () => (
      <p className="wpBox-actions">
        {/* {reduxSubscriptionName === "Free" ? (
          <Link
            to="/artistDashboard/subscriptions"
            className="wpBox-actions-left"
          >
            Upgrade
          </Link>
        ) : (
          ""
        )} */}
        {/* <Link to="/artistDashboard" className="wpBox-actions-right">
          Go to dashboard
        </Link> */}
      </p>
    ),
    actions3: () => (
      <p className="wpBox-actions">
        <a
          href="https://copyright.playtreks.com"
          className="wpBox-actions-right"
        >
          Go to Copyright control
        </a>
      </p>
    ),
    actions4: () => (
      <p className="wpBox-actions">
        <a
          href={"https://marketplace.playtreks.com"}
          className="wpBox-actions-right"
        >
          Go to Marketplace
        </a>
      </p>
    ),
  };

  function handleResendVerification() {
    resendValidationEmail(jwtToken);
    setVerificationResent(true);
    setTimeout(() => setVerificationResent(false), 5000);
  }

  const MobileLogo = () => (
    <Link to="/welcomePage">
      <img
        src={playtreksTransparentBg}
        alt="playtreks"
        className="artist-dashboard-logoMobile"
      />
    </Link>
  );

  return (
    <div className="welcomePage">
      {/* <Sidebar /> */}
      <MobileLogo />
      <MobileMenu />
      <MobileMenuBurger />

      <main className="welcomePage-overview">
        <section>
          <div className="toastrStyleAlert-wrapper">
            <CustomAlert
              variant={
                "warning customAlert opacity-08 toastrStyleAlert-sidebarMargin " +
                (verifyAccountAlert === true ? "d-flex" : "d-none")
              }
              xButton={true}
              closeCallback={() => setVerifyAccountAlert(false)}
            >
              <div>
                <span>
                  hey! in order to make a release, we need you to verify your
                  account. Please click on the link that we sent to your email.
                </span>
                <div
                  className="customAlert-resend"
                  onClick={handleResendVerification}
                >
                  {verificationResent ? "sent! ✓" : "resend activation link ↺"}
                </div>
              </div>
            </CustomAlert>
            <br />
            <CustomAlert
              variant={
                "warning customAlert opacity-08 toastrStyleAlert-sidebarMargin " +
                (userMsg ? "d-flex" : "d-none")
              }
              xButton={true}
              closeCallback={() => setUserMsg(false)}
            >
              <div style={{ width: "100%" }}>
                <span>{userMsg}</span>
              </div>
            </CustomAlert>
          </div>
        </section>
        <section className="welcomePage-header">
          <div className="welcomePage-header-right" style={{ zIndex: 999 }}>
            <UserProfile name="artist name" avatar={UserIcon} />
          </div>
          <GoodMorning
            name={userData?.data?.artistname || userData?.data?.firstname}
          />
        </section>
        <section className="welcomePage-boxes row">
          <div class="col-12 col-md-6">
            <Box
              boxPic={BoxPic1}
              BoxText={boxesContent.text1}
              BoxTag={boxesContent.tag1}
              BoxActions={boxesContent.actions1}
              state={distributionState}
              href={"/artistDashboard/myReleases"}
            />
          </div>
          <div class="col-12 col-md-6">
            <Box
              boxPic={BoxPic2}
              BoxText={boxesContent.text2}
              // BoxTag={boxesContent.tag2}
              // BoxActions={boxesContent.actions2}
              state={analyticsState}
              // href={""}
            />
          </div>
          <div class="col-12 col-md-6">
            <Box
              boxPic={BoxPic3}
              BoxText={boxesContent.text3}
              // BoxTag={boxesContent.tag1}
              BoxActions={boxesContent.actions3}
              state={distributionState}
              href={"https://copyright.playtreks.com"}
            />
          </div>
          <div class="col-12 col-md-6">
            <Box
              boxPic={BoxPic4}
              BoxText={boxesContent.text4}
              // BoxTag={boxesContent.tag1}
              BoxActions={boxesContent.actions4}
              state={distributionState}
              href={"https://marketplace.playtreks.com"}
            />
          </div>
        </section>
        {/* <FeaturedEntity entityData={welcomePageData} /> */}
        {/* <section className="welcomePage-big">
          <BigOffer
            state={
              distributionState && analyticsState === SUBSCRIBED
                ? SUBSCRIBED
                : INITIAL
            }
            setPaymentModal={() =>
              setPaymentModal({ open: true, product: BUNDLE })
            }
          />
        </section> */}
        {/* <section className="welcomePage-suggested">
          <SuggestedArtists suggestedArtists={topArtists} />
        </section> */}
      </main>
    </div>
  );
}

export default WelcomePage;
