import React, { useState, useEffect } from "react";

import { Image } from "image-js";

import Dropzone from "../../../form/DropzoneComponent";

import plus from "../../../../assets/icons/plusIcon.svg";
import spinner from "../../../../assets/spinner.svg";
import imageIcon from "../../../../assets/icons/imageIcon.svg";
import trash from "../../../../assets/icons/trash.svg";

function ImageUploadComponent({
  validation,
  imageUploadCallback,
  imageFromPlatform,
}) {
  const [imageFile, setImageFile] = useState({
    file: "",
    previewUrl: imageIcon,
  });
  const [imageLoading, setImageLoading] = useState(false);
  const [imageStatus, setImageStatus] = useState({
    isLoaded: false,
    isError: false,
    extErr: false,
    noSqErr: false,
    // tooSmErr: false,
    // cModErr: false,
    fileSizeErr: false,
  });
  // const [imgFocus, setImgFocus] = useState(false);

  const noSqErr =
    imageStatus.isLoaded && !imageStatus.extErr && imageStatus.noSqErr;
  const fileSizeErr =
    imageStatus.isLoaded && !imageStatus.extErr && imageStatus.fileSizeErr;
  const extErr = imageStatus.isLoaded && imageStatus.extErr;

  useEffect(() => {
    setImageStatus({
      isPlatform: true, // indicates if image is platform deducted or user uploaded
      isError: false,
      isLoaded: true,
      extErr: false,
      noSqErr: false,
      // tooSmErr: false,
      // cModErr: false,
      fileSizeErr: false,
    }); // validation
    setImageFile({ file: "", previewUrl: imageFromPlatform }); // preview
    setImageLoading(false); // loaded
  }, [imageFromPlatform]);

  function dropzoneAcceptCallback(file, e) {
    let reader = new FileReader();
    let blob = new Blob(file);
    reader.readAsDataURL(blob);

    setImageFile({ file: "", previewUrl: spinner }); // loading
    setImageLoading(true); // loading
    reader.onloadend = async () => {
      if (await checkImage(reader.result, blob.size)) {
        setImageStatus({
          isPlatform: false,
          isError: false,
          isLoaded: true,
          extErr: false,
          noSqErr: false,
          // tooSmErr: false,
          // cModErr: false,
          fileSizeErr: false,
        }); // validation
        setImageFile({ file: file, previewUrl: reader.result }); // preview
        imageUploadCallback(file); // transfer
        setImageLoading(false); // loaded
      } else {
        setImageLoading(false); // idle
        imageUploadCallback(""); // validation
        setImageFile({ file: "", previewUrl: imageIcon }); // back to idle
      }
    };
  }

  function dropzoneRejectCallback(file, e) {
    setImageFile({ file: "", previewUrl: imageIcon });
    setImageStatus({
      ...imageStatus,
      isLoaded: true,
      isError: true,
      extErr: true,
      noSqErr: false,
      // tooSmErr: false,
      // cModErr: false,
      fileSizeErr: false,
    });
    imageUploadCallback(""); // validation
  }

  async function checkImage(imgFileString, fileSize) {
    return await Image.load(imgFileString).then(function (image) {
      let noSqErr = image.height !== image.width;
      // let tooSmErr = image.height < 3000 || image.width < 3000;
      // let cModErr = image.colorModel !== "RGB";
      let fileSizeErr = fileSize > 10000000;
      let isError =
        noSqErr === true ||
        // tooSmErr === true ||
        // cModErr === true ||
        fileSizeErr === true;
      setImageStatus({
        ...imageStatus,
        isLoaded: true,
        extErr: false,
        isError: isError,
        noSqErr: noSqErr,
        // tooSmErr: tooSmErr,
        // cModErr: cModErr,
        fileSizeErr: fileSizeErr,
      });
      return !isError;
    });
  }

  return (
    <span>
      <Dropzone
        acceptFileType="image/jpg, image/jpeg, image/tiff, image/png"
        className="ptImageUploadField"
        inputClassName="ptImageInput"
        onReject={dropzoneRejectCallback}
        onAccept={dropzoneAcceptCallback}
      >
        <div>
          {!imageStatus.isLoaded || imageStatus.isError || imageLoading ? (
            <div className="ptImagePlaceholder">
              <img
                src={imageFile.previewUrl}
                alt="upload"
                className="ptUploadImageIcon"
              />
              <img
                src={plus}
                alt="plus"
                className={imageLoading ? "d-none" : "ptUploadImageCircle"}
              />
            </div>
          ) : (
            <img
              src={imageFile.previewUrl}
              alt="upload"
              className="ptImagePreview"
            />
          )}
          <div className="dashboard-profile-uploadField-changeImg">
            Change image
          </div>
        </div>
      </Dropzone>
      <div className="dashboard-profile-uploadField-bottom">
        <img src={trash} className="dashboard-profile-uploadField-trash" />
      </div>

      <div className="dashboard-profile-uploadField-validationText">
        <span style={noSqErr ? { color: "red" } : {}}>
          Your image should be square,{" "}
          {noSqErr ? (
            <span alt="error" aria-label="error" className="nrTick">
              ❌
            </span>
          ) : (
            ""
          )}
          {imageStatus.isLoaded &&
          !imageStatus.isError &&
          !imageStatus.isPlatform ? (
            <span alt="success" aria-label="error" className="nrTick">
              ✅
            </span>
          ) : (
            ""
          )}
        </span>
        <br />{" "}
        <span style={fileSizeErr ? { color: "red" } : {}}>
          the file size should not exceed 10mb,{" "}
          {fileSizeErr ? (
            <span alt="error" aria-label="error" className="nrTick">
              ❌
            </span>
          ) : (
            ""
          )}
          {imageStatus.isLoaded &&
          !imageStatus.isError &&
          !imageStatus.isPlatform ? (
            <span alt="success" aria-label="error" className="nrTick">
              ✅
            </span>
          ) : (
            ""
          )}
        </span>
        <br /> and{" "}
        <span style={extErr ? { color: "red" } : {}}>
          the format should be png, jpg or tiff{" "}
          {extErr ? (
            <span alt="error" aria-label="error" className="nrTick">
              ❌
            </span>
          ) : (
            ""
          )}
          {imageStatus.isLoaded &&
          !imageStatus.isError &&
          !imageStatus.isPlatform ? (
            <span alt="success" aria-label="error" className="nrTick">
              ✅
            </span>
          ) : (
            ""
          )}
        </span>
      </div>
    </span>
  );
}

export default ImageUploadComponent;
