import React, { useState } from "react";
import Portal from "../../../../Portal";
import AuthIcon from "../../../../../assets/AuthIcon.png";
import ButtonComponent from "../../../../form/ButtonComponent";
import AuthAppModal from "./AuthAppModal";
import { disable2FAAuthenticator } from "../../../../../services/auth";

import infoBulbIcon from "../../../../../assets/icons/bulb.svg";
import ConfirmPasswordModal from "../../../../modals/ConfirmPasswordModal";

const AuthApp = ({ setToast, twoFAStateLoading, refetch2FAState, enabled }) => {
  const [isOpenAuthAppModal, setIsOpenAuthAppModal] = useState(false);

  const [openDisableAuthModal, setOpenDisableAuthModal] = useState(false);

  const handleOnModalClose = () => {
    setIsOpenAuthAppModal(false);
    setOpenDisableAuthModal(false);
  };

  const handleOpenAuthAppModal = () => {
    setIsOpenAuthAppModal(true);
  };

  const handleOpenDisableAuthModal = () => {
    setOpenDisableAuthModal(true);
  };

  const handle2FADisable = async (password, setErrorMsg) => {
    try {
      const response = await disable2FAAuthenticator(password);

      if (response.data.success) {
        setTimeout(refetch2FAState, 1000);

        setOpenDisableAuthModal(false);

        setToast({
          type: "success",
          open: true,
          message: "2FA has been disabled successfully",
        });
      } else {
        setToast({
          type: "danger",
          open: true,
          message: response.data.message,
        });
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrorMsg(error.response.data.message);
      } else {
        setToast({
          open: true,
          message: "An unexpected error occurred. Please try again.",
          type: "danger",
        });
      }
    }
  };

  return (
    <div className="VerifyPhoneWrapper mt-0">
      <div className="row w-100 mx-0">
        <img src={AuthIcon} alt="texticon" width={30} height={30} />
        <div className="col">
          {/* <span className="">Authenticator app</span> */}
          <div className="row align-items-center justify-content-between ">
            <div className="col">
              <span className="text-white">Authenticator app</span>
            </div>

            {/* 2fa state badge */}
            <div className="d-flex ml-auto">
              {!!!twoFAStateLoading && (
                <span
                  className={`rounded security-two-factor-verification__status-${
                    enabled ? "enabled" : "disabled"
                  }`}
                >
                  <span className="txt--8px txt--uppercase">
                    {enabled ? "Enabled" : "Disabled"}
                  </span>
                </span>
              )}
            </div>
          </div>

          {!enabled ? (
            <span className="text-muted">
              Use the Authenticator app to get free verification codes, even
              when your phone is offline. You can use authenticator apps like
              Google authenticator, Microsoft authenticator, Bitwarden etc. to
              set up.
            </span>
          ) : (
            <div className="security-two-factor-verification__description mt-3">
              <div className="row my-money-section-1-text px-3 py-2 mx-0">
                <div className="col-auto px-0">
                  <img src={infoBulbIcon} alt="info" />
                </div>
                <div className="col text-muted">
                  Disabling two-factor authentication reduces your account
                  safety
                </div>
              </div>
            </div>
          )}

          <div className="row justify-content-start mx-0">
            {enabled ? (
              <ButtonComponent
                className="playtreksButton  bg-secondary w-100 h-75 mt-4"
                onClick={handleOpenDisableAuthModal}
              >
                Disable
              </ButtonComponent>
            ) : (
              <ButtonComponent
                className="playtreksButton w-100 h-75 mt-4"
                onClick={handleOpenAuthAppModal}
              >
                Set up
              </ButtonComponent>
            )}
          </div>

          <Portal selector="body">
            {isOpenAuthAppModal && (
              <AuthAppModal
                onCloseRequest={handleOnModalClose}
                setIsOpenAuthAppModal={setIsOpenAuthAppModal}
                setToast={setToast}
                refetch2FAState={refetch2FAState}
              />
            )}
            {openDisableAuthModal && (
              <ConfirmPasswordModal
                onCloseRequest={handleOnModalClose}
                onSubmit={handle2FADisable}
                helperText="Enter your password to disable two factor authentication using authenticator"
              />
            )}
          </Portal>
        </div>
      </div>
    </div>
  );
};

export default AuthApp;
