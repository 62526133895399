import React from "react";
import { Card } from "reactstrap";

function ArgonCard(props) {
  return (
    <div>
      <Card className={props.className} style={props.style}>
        {props.children}
      </Card>
    </div>
  );
}

export default ArgonCard;
