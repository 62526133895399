import { REDUX_ADD_ALERT, REDUX_REMOVE_ALERT } from "../actions/appActions";

var initialState = {
  customAlerts: [],
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case REDUX_ADD_ALERT:
      if (action.preventDuplicate) {
        if (state.customAlerts.some((msg) => msg.text === action.text))
          return state;
      }
      state.customAlerts = [
        ...state.customAlerts,
        { text: action.text, link: action.link, variant: action.variant },
      ];
      return state;
    case REDUX_REMOVE_ALERT:
      state.customAlerts = state.customAlerts.filter(
        (alert) => alert !== action.alert
      );
      return state;
    default:
      return state;
  }
}

export default appReducer;
