export const VersionFieldRestrictedWords = [
  "Official",
  "Unofficial",
  "Exclusive",
  "Limited",
  "Original",
  "Cover",
  "BSO",
  "OST",
  "Video",
  "Videoclip",
  "Stereo",
  "Mono",
  "Slowed",
  "Reverb",
  "Reverbed",
  "Beat",
  "Type",
  "Mastered",
  "Tributo",
  "Tribute",
  "Homenaje",
  "Hommage",
  "Continuous",
  "Primaryartist",
  "Artista",
  "primario",
  "Artiste",
  "Primaire",
  "Artista",
  "primari",
  "Performer",
  "Ejecutante",
  "Interprète",
  "Executant",
  "Executante",
  "Esecutore",
  "Producer",
  "Productor",
  "Producteur",
  "Produtor",
  "Produttore",
  "Remixer",
  "Composer",
  "Compositor",
  "Compositeur",
  "Compositore",
  "Lyricist",
  "Letrista",
  "Lletrista",
  "Parolier",
  "Lírico",
  "Autore",
  "testo",
  "Publisher",
  "Editorial",
  "Éditorial",
  "Editoriale",
  "Featuring",
  "With",
  "Con",
  "Avec",
  "Amb",
  "Com",
  "Conductor",
  "Conducteur",
  "Conduttore",
  "Director",
  "Diretor",
  "Arranger",
  "Arreglador",
  "Orchestra",
  "Orquesta",
  "Actor",
  "Single",
  "EP",
  "Album",
  "Version",
];
