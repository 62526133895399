import React, { useState } from "react";
import { Link } from "react-router-dom";

// REDUX
import { useSelector } from "react-redux";

// packages
import { get as sliceObject } from "lodash";

// components
import HorizontalScrollButtonGroup from "../HorizontalScrollButtonGroup";
import StatsCard from "../StatsCard";
import StatsCardIcon from "../StatsCardIcon";
import ButtonComponent from "../../form/ButtonComponent";

// cards config
import cards from "./socialStatsCardsConfig";
import { sortStatsCards } from "../../utils";

// assets
import Spinner from "../../../assets/spinner.svg";

const HORIZONTAL_SCROLL_SIZE = 200;

const USER = "user";

function SocialStatsCardsBox({
  entityStats,
  entityStatsDelta,
  noFooter,
  noLabel,
  handlePlusIconClick,
}) {
  const [socialStatsBtn, setSocialStatsBtn] = useState({
    left: true,
    right: false,
    scrollLeft: 1,
  });

  // redux
  const reduxMainEntity = useSelector(
    (state) => state.dashboardReducer.mainEntity
  );
  const reduxUserData = useSelector(
    (state) => state.dashboardReducer?.userData
  );

  const isMainEntityUser = reduxMainEntity?.type === USER;
  const facebookConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.facebook?.email?.length > 0
    : true;
  const facebookPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedFacebookArtistPageID?.length > 0
    : true;
  const twitterConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.twitter?.screen_name?.length > 0
    : true;
  const instagramPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedInstagramPageID?.length > 0
    : true;

  const platformConnected = {
    facebook: facebookConnected,
    twitter: twitterConnected,
    instagram: facebookConnected,
  };
  const pageConnected = {
    facebook: facebookPageConnected,
    twitter: true,
    instagram: instagramPageConnected,
  };
  const comingSoon = {
    spotify: false,
    apple: false,
  };

  function handleSocialClick() {}

  function handleSocialStatScroll(element, direction) {
    let scrollVal =
      direction === "left"
        ? element.scrollLeft - HORIZONTAL_SCROLL_SIZE
        : element.scrollLeft + HORIZONTAL_SCROLL_SIZE;

    scrollVal = Math.max(0, scrollVal);

    setSocialStatsBtn((state) => ({
      left: element.scrollLeft === 0,
      right: state.scrollLeft > 0 && state.scrollLeft === scrollVal,
      scrollLeft: scrollVal,
    }));
    direction === "left"
      ? (element.scrollLeft -= HORIZONTAL_SCROLL_SIZE)
      : (element.scrollLeft += HORIZONTAL_SCROLL_SIZE);
  }

  return (
    <div>
      <div className="dashboard-overview-social-stats-header">
        <span
          className={
            noLabel ? "d-none" : "dashboard-overview-social-stats-header-left"
          }
        >
          <label>social statistics</label>
          <span
            className="dashboard-overview-social-stats-header-emoji"
            role="img"
            aria-label={"speech balloon"}
          >
            {"💬"}
          </span>
        </span>
        <div className="dashboard-overview-streaming-stats-header-scroll">
          <HorizontalScrollButtonGroup
            // disableLeft={socialStatsBtn["left"]}
            // disableRight={socialStatsBtn["right"]} // no disabling arrows (problematic)
            handleScroll={(direction) =>
              handleSocialStatScroll(
                document.querySelector(
                  ".dashboard-overview-social-stats-cards"
                ),
                direction
              )
            }
          />
        </div>
      </div>
      {!entityStats && !entityStatsDelta ? (
        <div className="dashboard-overview-social-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-social-stats-spinner"
          />
        </div>
      ) : (
        <div className="dashboard-overview-social-stats-cards">
          {sortStatsCards(cards, platformConnected, pageConnected).map(
            (card) => (
              <StatsCard
                className="dashboard-overview-social-stats-cards-card"
                name={card.name}
                platform={card.platform}
                objectPath={sliceObject(entityStats, card?.objectPath)}
                objectPathDelta={sliceObject(
                  entityStatsDelta,
                  card?.objectPath
                )}
                connected={platformConnected[card.platform]}
                pageConnected={pageConnected[card.platform]}
                comingSoon={
                  "comingSoon" in card
                    ? card.comingSoon
                    : comingSoon[card.platform]
                }
                handlePlusIconClick={handlePlusIconClick}
              >
                <StatsCardIcon
                  name={card.name}
                  icon={card.icon}
                  handleClick={handleSocialClick}
                />
              </StatsCard>
            )
          )}
        </div>
      )}
      <div
        className={
          noFooter ? "d-none" : "dashboard-overview-social-stats-more-details"
        }
      >
        <span className="dashboard-overview-social-stats-more-details-info">
          <label>
            compare yourself to other artists and analyze your growth
          </label>
        </span>
        <Link to={"/artistDashboard/socialStats"}>
          <ButtonComponent className="dashboard-overview-social-stats-more-details-button">
            See more details
          </ButtonComponent>
        </Link>
      </div>
    </div>
  );
}

export default SocialStatsCardsBox;
