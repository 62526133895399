import React from "react";
import { Link } from "react-router-dom";
import X from "../../distribution/assets/X.svg";

const ProfileCompletenessRequiredModal = ({ onCloseRequest, body1, body2 }) => {
  body1 =
    body1 ||
    "You need to complete your profile before you can sumbit your release. Although two-factor authentication is optional to complete your profile, it is highly recommended.";
  body2 = body2 || (
    <>
      Your release is saved as draft. Please proceed to{" "}
      <a href="/artistDashboard/profile">profile</a> page to finish your profile
      and head over to <a href="/artistDashboard/myReleases">my releases</a>{" "}
      page to finish your release.
    </>
  );

  return (
    <div className="playtreksModal-overlay">
      <div className="TwoFAModal">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />

        <>
          <h2 className="mb-4 text-white">Profile completeness required</h2>

          <div className="mb-4 text-muted">
            <p className="infoText">
              {body1}
              <br /> <br />
              {body2}
            </p>
          </div>
        </>
      </div>
    </div>
  );
};

export default ProfileCompletenessRequiredModal;
