import React, { useState, useEffect } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import ExpandedPanel from "./UploadTracksFormPanel";
import {
  closeOtherTrackPanels,
  toggleExpandTrackPanel,
  closeTrackPanel,
} from "../../../redux/actions/distributionActions.js";

import { sortableElement, sortableHandle } from "react-sortable-hoc";

import arrow from "../../../assets/icons/selectInputArrow.svg";
import move from "../../../assets/icons/move.svg";

const SortableItem = sortableElement(
  ({
    value,
    trackIndex,
    trackType,
    itemClassName,
    prepopulated,
    deleting,
    setChangesPresent,
  }) => {
    const [val, setVal] = useState(false); // if true validation fails

    // REDUX
    const reduxIsExpanded = useSelector(
      (state) => state.distributionReducer?.tracksForm[trackIndex]?.isExpanded
    );
    const reduxTracksFiles = useSelector(
      (state) => state.distributionReducer?.tracksFiles
    );
    const reduxFieldValidationBoolean = useSelector(
      (state) =>
        state.distributionReducer?.tracksValidation?.[trackIndex]
          ?.__allFieldsBoolean === true
    );
    const reduxShareAmountValidationBoolean = useSelector(
      (state) =>
        state.distributionReducer?.tracksValidation?.[trackIndex]
          ?.__generalShareAmountValidation === true
    );
    const reduxReleaseOnePrimaryArtistPresentValidation = useSelector(
      (state) =>
        state.distributionReducer?.tracksValidation?.[trackIndex]
          ?.__releaseOnePrimaryArtistPresentValidation === true
    );

    const dispatch = useDispatch();

    // useEffect(() => {
    //   // if (!prepopulated && !deleting) {
    //   //   // open the recently loaded track panel
    //   //   // dispatch(closeOtherTrackPanels(trackIndex));
    //   // }
    // }, [reduxTracksFiles]);

    useEffect(() => {
      if (reduxFieldValidationBoolean) setVal("information missing");
      else if (reduxShareAmountValidationBoolean)
        setVal("sum of shares can't be over 100%");
      else if (reduxReleaseOnePrimaryArtistPresentValidation)
        setVal("primary artist doesn't match");
      else setVal(false);
    }, [
      reduxFieldValidationBoolean,
      reduxShareAmountValidationBoolean,
      reduxReleaseOnePrimaryArtistPresentValidation,
    ]);

    function itemOnClick() {
      dispatch(toggleExpandTrackPanel(trackIndex));
    }

    return (
      <div id={"trackListItem" + trackIndex}>
        <li className={itemClassName} id={trackIndex} onClick={itemOnClick}>
          <DragHandle
            className="utDndHandle"
            handleImg={move}
            handleImgClassName="utDndHandleImage"
            trackIndex={trackIndex}
          />
          <span className="handleText" id={trackIndex}>
            {/* if title has been changed and passed up to the tracks object, display that. otherwise - file name */}
            {useSelector(
              (state) =>
                state.distributionReducer?.tracksForm?.[trackIndex]?.form?.[
                  "#track_title"
                ]
            )}
          </span>
          <img
            alt="expand"
            src={arrow}
            className={(reduxIsExpanded ? "utDndArrowUp" : "") + " utDndArrow"}
          />
          <span
            className={!val ? "d-none" : ""}
            style={{
              position: "absolute",
              right: "50px",
              color: "red",
              fontSize: "12px",
            }}
          >
            {val}
          </span>
        </li>
        <ExpandedPanel
          className={reduxIsExpanded ? "" : "d-none"} // trigger showing and hiding
          trackIndex={trackIndex}
          trackType={trackType}
          trackName={value}
          prepopulated={prepopulated} // TODO: REDUX?
          setChangesPresent={setChangesPresent} // TODO: REDUX?
        />
      </div>
    );
  }
);

const DragHandle = sortableHandle(
  ({ className, handleImg, handleImgClassName, trackIndex }) => {
    const dispatch = useDispatch();
    function handleMouseDown(e) {
      e.preventDefault();
      dispatch(closeTrackPanel(trackIndex));
    }
    return (
      <span className={className} id="a">
        <img
          src={handleImg}
          alt="drag here"
          className={handleImgClassName}
          onMouseDown={handleMouseDown}
        />
      </span>
    );
  }
);

export default SortableItem;
