import React from "react";

import spinner from "../../../../assets/spinner.svg";

const SPOTIFY = "spotify";
const FACEBOOK = "facebook";
const INSTAGRAM = "instagram";
const APPLE = "apple";

function ConnectedPages({
  spotifyArtistProfile,
  setSpotifyArtistModal,
  facebookArtistProfile,
  setFbPageModal,
  instagramArtistProfile,
  setInstagramPageModal,
  appleArtistProfile,
  setApplePageModal,
  deleteProfile,
}) {
  return (
    <div className="dashboard-profile-modal-content-platforms-pages ml-3">
      Connected spotify artist: <br />
      {spotifyArtistProfile ? (
        spotifyArtistProfile !== "no profile" ? (
          <div>
            <img
              src={spotifyArtistProfile?.images?.[0]?.url}
              alt="your spotify artist image"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
              }}
            />
            &nbsp; {spotifyArtistProfile.name} &nbsp;{" "}
            <span
              className="dashboard-profile-modal-disconnect"
              onClick={() => deleteProfile(SPOTIFY)}
            >
              disconnect
            </span>
          </div>
        ) : (
          <div
            className="dashboard-profile-modal-connectNow"
            onClick={() => setSpotifyArtistModal(true)}
          >
            Connect now
          </div>
        )
      ) : (
        <img
          alt="loading"
          src={spinner}
          style={{ width: "30px", height: "30px" }}
        />
      )}
      <br />
      Connected facebook page: <br />
      {facebookArtistProfile ? (
        facebookArtistProfile !== "no profile" &&
        facebookArtistProfile !== "service not connected" ? (
          <div>
            <img
              src={facebookArtistProfile.picture.data.url}
              alt="your facebook page image"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
              }}
            />
            &nbsp; {facebookArtistProfile.name}{" "}
            <span
              className="dashboard-profile-modal-disconnect"
              onClick={() => deleteProfile(FACEBOOK)}
            >
              disconnect
            </span>
          </div>
        ) : (
          <div>
            {facebookArtistProfile === "service not connected" ? (
              <div className="dashboard-profile-modal-inactive">
                Please connect your facebook account first
              </div>
            ) : (
              <div
                className="dashboard-profile-modal-connectNow"
                onClick={() => setFbPageModal(true)}
              >
                Connect now
              </div>
            )}
          </div>
        )
      ) : (
        <img
          alt="loading"
          src={spinner}
          style={{ width: "30px", height: "30px" }}
        />
      )}
      <br />
      Connected instagram page: <br />
      {instagramArtistProfile ? (
        instagramArtistProfile !== "no profile" &&
        instagramArtistProfile !== "service not connected" ? (
          <div>
            <img
              src={instagramArtistProfile.profile_picture_url}
              alt="your instagram page image"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
              }}
            />
            &nbsp; {instagramArtistProfile.name}{" "}
            <span
              className="dashboard-profile-modal-disconnect"
              onClick={() => deleteProfile(INSTAGRAM)}
            >
              disconnect
            </span>
          </div>
        ) : (
          <div>
            {instagramArtistProfile === "service not connected" ? (
              <div className="dashboard-profile-modal-inactive">
                Please connect your facebook account first
              </div>
            ) : (
              <div
                className="dashboard-profile-modal-connectNow"
                onClick={() => setInstagramPageModal(true)}
              >
                Connect now
              </div>
            )}
          </div>
        )
      ) : (
        <img
          alt="loading"
          src={spinner}
          style={{ width: "30px", height: "30px" }}
        />
      )}
      <br />
      {/* TODO: uncomment when adding Apple artist profile */}
      {/* Connected facebook page: <br />
      {appleArtistProfile ? (
        appleArtistProfile !== "no profile" &&
        appleArtistProfile !== "service not connected" ? (
          <div>
            <img
              src={appleArtistProfile.picture.data.url}
              alt="your apple page image"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "10px",
              }}
            />
            &nbsp; {appleArtistProfile.name}{" "}
            <span
              className="dashboard-profile-modal-disconnect"
              onClick={() => deleteProfile(APPLE)}
            >
              disconnect
            </span>
          </div>
        ) : (
          <div>
            {appleArtistProfile === "service not connected" ? (
              <div className="dashboard-profile-modal-inactive">
                Please connect your apple account first
              </div>
            ) : (
              <div
                className="dashboard-profile-modal-connectNow"
                onClick={() => setApplePageModal(true)}
              >
                Connect now
              </div>
            )}
          </div>
        )
      ) : (
        <img
          alt="loading"
          src={spinner}
          style={{ width: "30px", height: "30px" }}
        />
      )} */}
    </div>
  );
}

export default ConnectedPages;
