import React, { useState } from "react";

// REDUX
import { useSelector } from "react-redux";

// packages
import { get as sliceObject } from "lodash";

// compo
import HorizontalScrollButtonGroup from "../HorizontalScrollButtonGroup";
import TrackFeaturesCard from "./TrackFeaturesCard"; // using the track cards

// assets
import Spinner from "../../../assets/spinner.svg";
import { mobileFeatures as cards } from "../AlbumFeatures/albumFeaturesCardsConfig";
import { getColorScheme } from "../../utils";

const HORIZONTAL_SCROLL_SIZE = 200;

function MobileTrackFeaturesCardsBox({ entityData }) {
  const [streamingStatsBtn, setStreamingStatsBtn] = useState({
    left: true,
    right: false,
    scrollLeft: 1,
  });

  function handleStreamingStatScroll(element, direction) {
    const scrollVal =
      direction === "left"
        ? element.scrollLeft - HORIZONTAL_SCROLL_SIZE
        : element.scrollLeft + HORIZONTAL_SCROLL_SIZE;
    setStreamingStatsBtn((state) => ({
      left: element.scrollLeft === 0,
      right: state.scrollLeft > 0 && state.scrollLeft === scrollVal,
      scrollLeft: scrollVal,
    }));
    direction === "left"
      ? (element.scrollLeft -= HORIZONTAL_SCROLL_SIZE)
      : (element.scrollLeft += HORIZONTAL_SCROLL_SIZE);
  }

  const entityFeatures =
    entityData?.type === "track"
      ? entityData?.trackFeatures
      : entityData?.trackFeaturesAggregations?.average;

  return (
    <div>
      <div className="dashboard-overview-streaming-stats-header">
        <span className="dashboard-overview-streaming-stats-header-left">
          <span
            className="track-features-left-content-header-emoji"
            role="img"
            aria-label={"features"}
          >
            {"⚗️"}
          </span>
          <label>
            &nbsp;
            {entityData?.type?.charAt(0)?.toUpperCase() +
              entityData?.type?.slice(1) || ""}
            &nbsp;Features {entityData?.type === "album" && "(averages)"}
          </label>
        </span>
        <div className="dashboard-overview-streaming-stats-header-scroll">
          <HorizontalScrollButtonGroup
            // disableLeft={streamingStatsBtn["left"]}
            // disableRight={streamingStatsBtn["right"]} // no disabling arrows (problematic)
            handleScroll={(direction) =>
              handleStreamingStatScroll(
                document.querySelector(
                  ".dashboard-overview-streaming-stats-cards"
                ),
                direction
              )
            }
          />
        </div>
      </div>
      {!entityData || !entityFeatures ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <div className="dashboard-overview-streaming-stats-cards">
          {cards.map((card, index) => {
            return (
              <TrackFeaturesCard
                name={card.name}
                objectPath={Math.round(
                  sliceObject(entityFeatures, card?.objectPath) * 100
                )}
                customLabelClass="social-card-name-white"
                valueSuffix=" %"
                cardIcon={""}
                tooltip={card.tooltip}
                barMode
                barColor={getColorScheme()[index]}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default MobileTrackFeaturesCardsBox;
