import React, { useEffect, memo, useCallback, useMemo } from "react";
import { getColorScheme } from "../utils";
import StockChart from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css";
import { isEqual } from "lodash";

// In case we need to import the extra modules
// import HC_more from "highcharts/highcharts-more";
// import StreamgraphModule from "highcharts/modules/streamgraph";
// HC_more(StockChart);
// StreamgraphModule(StockChart);

const HighchartsWrapper = memo(
  (props) => (
    <HighchartsReact highcharts={StockChart} options={props.options} />
  ),
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);

const initialToolTipData = { target: null, data: null };

function StatisticsChart({
  data,
  colors = getColorScheme(),
  height = "500px",
  source,
  chartButtonsConfig = global.config.highchartButtons,
}) {
  const [releaseToolTipData, setReleaseToolTipData] =
    React.useState(initialToolTipData);
  const [viewingEntitiesState, setViewingEntitiesState] = React.useState({});

  useEffect(() => {
    const entitiesVisibilityState = {};

    // Initial visibility will be true for all artists
    if (data?.series) {
      data.series.forEach(
        (entity) => (entitiesVisibilityState[entity.name] = true)
      );
    }

    // Setting the initial visibility state
    setViewingEntitiesState(entitiesVisibilityState);
  }, [data?.series]);

  /**
   * Handler to set the plotline tooltip target on hover
   *
   * @param {string} plotlineId Current hovered plotline ID
   */
  const handleMouseMove = (plotlineId, e) => {
    const entity = data?.plotLines?.flat().find((a) => a?.id === plotlineId);
    const target = e.currentTarget;
    setReleaseToolTipData({
      target,
      data: {
        artist: entity.artists[0].name,
        track: entity.name,
        releaseDate: entity.release_date,
      },
    });
  };

  let plotLines = useMemo(
    () =>
      data?.plotLines
        ?.flat()
        .map((a) => {
          if (!a) return a;

          return {
            color: "#e79a0a",
            dashStyle: "Dash",
            width: 2,
            value: moment(a.release_date).valueOf(), // Position, you'll have to translate this to the values on your x axis
            label: {
              text: "release",
              textAlign: "right",
              verticalAlign: "middle",
              style: {
                color: "#e79a0a",
              },
            },
            id: a.slug,
            events: {
              mousemove: handleMouseMove.bind(this, a.id),
              mouseout: (e) => {
                // On hovering out, hiding the tooltip
                setReleaseToolTipData(initialToolTipData);
              },
            },
          };
        })
        .filter((obj) => obj),
    [data?.plotLines]
  );

  /**
   * Handler to toggle the plotines of an entity when the
   * series of the entity legend item is toggled. Plotlines are
   * not toggled by highcharts.
   */
  const togglePlotlines = useCallback(
    (e) => {
      const chart = e.target;
      // Previous visibility state of each artist series
      const visibilityState = { ...viewingEntitiesState }; // Prevent mutating state variable

      // Toggling the previous state
      visibilityState[chart.name] = !visibilityState[chart.name];

      setViewingEntitiesState(visibilityState);

      // Finally filtering out all the plotlines only those the visibility state
      // of corresponding artist series is true
      const lines = Object.entries(visibilityState).flatMap((entry) => {
        if (entry[1]) {
          return plotLines.filter((obj) => obj.id === entry[0]);
        }
        return [];
      });

      // Updating the chart with latest state
      chart.xAxis.update({
        plotLines: lines,
      });
    },
    [plotLines]
  );

  let innerWidth = window.innerWidth;

  const options = {
    colors: colors, // default color before colors get loaded and passed along with the data
    chart: {
      backgroundColor: "transparent",
      polar: true,
      type: "spline",
      zoomType: "xy",
      panning: true,
      panKey: "shift",
      plotBorderWidth: 1,
      height: height,
    },
    title: {
      text: "",
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
        events: { legendItemClick: togglePlotlines },
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: true,
      xDateFormat: "%A, %b %e",
      //   valueSuffix: ` ${serviceText}`,
    },
    credits: {
      enabled: false,
    },
    series: data.series,
    xAxis: {
      left: innerWidth < 769 ? "5%" : undefined, // mobile
      width: innerWidth < 769 ? "95%" : undefined, // mobile
      type: "datetime",
      opposite: true,
      startOnTick: true,
      labels: {
        format: "{value:%b %e}",
        // step: 2,
        style: {
          color: "#FFFFFF",
        },
      },
      plotLines: plotLines,
    },
    yAxis: {
      visible: true,
      lineWidth: 1,
      gridLineWidth: 0,
      reversed: false,
      title: {
        text: "",
      },
      labels: {
        useHTML: true,
        align: innerWidth < 769 ? "left" : "right", // only mobile
        x: innerWidth < 769 ? -5 : -8,
        style: {
          color: "#FFFFFF",
          backgroundColor: innerWidth < 769 ? "#33373f" : "", // mobile
          borderRadius: "10px",
          padding: "2px",
        },
        // formatter: function () {
        //   return this.value;
        // },
      },
    },
    legend: {
      itemStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
      itemHoverStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "13px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
      },
    },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      buttonPosition: {
        align: "left",
        x: innerWidth < 769 ? 5 : -10,
      },
      enabled: true,
      selected: 1,
      allButtonsEnabled: true,
      buttons: chartButtonsConfig,
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        style: {
          color: "#FFFFFF",
          fontWeight: "bold",
        },
        states: {
          hover: { fill: "#0ef1db" },
          select: {
            fill: "#0ef1db",
            style: {
              color: "white",
            },
          },
          // disabled: { ... }
        },
      },
      // floating: true,
      // y: -65,
      horizontalAlign: "center",
      labelStyle: { display: "none" },
      inputStyle: { display: "none" },
    },
  };

  /**
   * Function to render the plotline tooltip UI
   */
  const renderPlotLineToolTip = (attrs) => (
    <div {...attrs}>
      <span data-z-index="1" className="plotlines-tooltip">
        <span>{`${releaseToolTipData?.data?.artist} - ${releaseToolTipData?.data?.track}`}</span>
        <br />
        <span style={{ "font-size": "10px" }}>
          Released on{" "}
          {releaseToolTipData?.data?.releaseDate
            ? moment(releaseToolTipData.data.releaseDate).format("dddd, MMM D")
            : ""}
        </span>
      </span>
    </div>
  );

  const open = Boolean(releaseToolTipData.target);

  // TODO: implement spinners
  if (!data?.series?.length) return <></>;

  return (
    <>
      {/* Memoized HighchartsWrapper to avoid re-renders */}
      <HighchartsWrapper options={options} />

      {/* Tooltip component for plotlines */}
      <Tippy
        visible={open}
        placement="right-end"
        followCursor={"initial"}
        plugins={[followCursor]}
        reference={releaseToolTipData.target}
        render={renderPlotLineToolTip}
      />
    </>
  );
}

export default StatisticsChart;
