import React, { memo } from "react";
import InputComponent from "../../../components/form/InputComponent";
import { ErrorMessage } from "formik";

const ShareHolder = memo(
  ({
    inputChangeCallback,
    inputBlurCallback,
    shareholdersCount,
    shareholder,
    index,
    edit,
  }) => {
    const handleInputChange = (name) => (value) => {
      inputChangeCallback(`shareholders.${index}.${name}`, value);
    };

    const handleInputBlur = (name) => () => {
      inputBlurCallback(`shareholders.${index}.${name}`, true);
    };

    return (
      <div className="row align-items-start">
        <div className="nrAlbumTitleInputWrapper py-2 py-md-0 col-12 col-md">
          <div
            className={
              (shareholder?.share ? "" : "d-none") +
              " inputUpperComment utiuc3_" +
              shareholder.id
            }
          >
            Share
          </div>
          <div>
            <div className="position-relative">
              <InputComponent
                disabled={!edit}
                value={shareholder?.share}
                inputType="number"
                commentField={".utiuc3_" + shareholder.id}
                placeholder="Share"
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput " +
                  (shareholder?.share
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                paddingTopOnInput={true}
                changeCallback={handleInputChange("share")}
                inputBlurCallback={handleInputBlur("share")}
                id={0}
                addon={false}
                for="artist"
                databaseField="#participants"
                databaseKey="contributors"
                databaseSubKey="share"
                tooltip={true}
                maxlength={3}
              />
              {shareholder?.share >= 0 ? (
                <div
                  className="position-absolute utepPercent-2"
                  style={{ right: 12, bottom: 10, zIndex: 100 }}
                >
                  %
                </div>
              ) : (
                ""
              )}
            </div>
            <ErrorMessage
              name={`shareholders.${index}.share`}
              component="div"
              className="row text-danger mx-0 fs-14 mt-2"
            />
          </div>
        </div>
        <div className="nrAlbumTitleInputWrapper py-2 py-md-0 col-12 col-md">
          <div
            className={
              (shareholder?.name ? "" : "d-none") +
              " inputUpperComment utiuc1_" +
              shareholder.id
            }
          >
            Name*
          </div>
          <InputComponent
            disabled={index < 1 || !edit}
            value={shareholder?.name}
            paddingTopOnInput={true}
            commentField={".utiuc1_" + shareholder.id}
            placeholder="Name*"
            inputGroupClassName="utepContributorsNameInputGroup nrInputGroup"
            inputClassName={
              "nrInput " +
              (shareholder?.name
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            addon={false}
            changeCallback={handleInputChange("name")}
            inputBlurCallback={handleInputBlur("name")}
            id={0}
            for="contributors"
            databaseField="#participants"
            databaseKey="contributors"
            databaseSubKey="name"
          />
          <ErrorMessage
            name={`shareholders.${index}.name`}
            component="div"
            className="row text-danger mx-0 fs-14 mt-2"
          />
        </div>
        <div className="nrAlbumTitleInputWrapper py-2 py-md-0 col-12 col-md">
          <div
            className={
              (shareholder?.email ? "" : "d-none") +
              " inputUpperComment utiuc2_" +
              shareholder.id
            }
          >
            Email
          </div>
          <InputComponent
            disabled={index < 1 || !edit}
            value={shareholder?.email}
            paddingTopOnInput={true}
            commentField={".utiuc2_" + shareholder.id}
            placeholder="Email"
            inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
            inputClassName={
              "nrInput " +
              (shareholder?.email
                ? " interMediumInput interMediumPaddingTop"
                : "")
            }
            addon={false}
            imgClass="nrAddonRightIcon"
            changeCallback={handleInputChange("email")}
            inputBlurCallback={handleInputBlur("email")}
            id={0}
            for="contributors"
            databaseField="#participants"
            databaseKey="contributors"
            databaseSubKey="email"
          />
          <ErrorMessage
            name={`shareholders.${index}.email`}
            component="div"
            className="row text-danger mx-0 fs-14 mt-2"
          />
        </div>
      </div>
    );
  }
);

export default ShareHolder;
