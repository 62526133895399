import moment from "moment";

const PLATFORM = Object.freeze({ SPOTIFY: "spotify", APPLE: "apple" });

const SOCIAL_PLATFORM = Object.freeze({
  YOUTUBE: "youtube",
  TWITTER: "twitter",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
});

/**
 *
 * @param {String} s
 * @param {Character} c
 */
function removeTrailingCharacter(s, c) {
  if (s.substr(-1) === c) return s.slice(0, -1);
  return s;
}

function artistsToString(artistsObject) {
  var artistsString = artistsObject
    .slice(0, 3)
    .map((e) => e.name)
    .join(", ");

  return artistsString;
}

function getArtists(artistsObject) {
  return artistsObject.map((e) => ({
    id: e.id,
    name: e.name,
  }));
}

// for spotify
function trackParse(hits, data, artists) {
  hits = hits.map((hit, index) => {
    return {
      index: index,
      name: hit._source.track.name,
      last_seen_pos: hit._source.track.history[0].position,
      track: {
        ...hit._source.track,
        isrc: hit._source.track.external_ids?.isrc,
      },
      album: hit._source.track.album,
      artist_name: artistsToString(hit._source.track.artists),
      artists: getArtists(hit._source.track.artists),
    };
  });

  if (hits.length === 1) hits[0].index = 0;

  return hits;
}

function parseAppleTrack(hits, data, artists) {
  hits = hits.map((hit, index) => {
    return {
      index: index,
      track: {
        name: hit._source.attributes.name,
        isrc: hit._source.attributes.isrc,
      },
      last_seen_pos: hit._source.history[0].position,
      album: {
        name: hit._source.attributes.albumName,
        copyrights: hit?._source?.relationships?.albums?.data?.[0]?.copyright,
        label: hit?._source?.relationships?.albums?.data?.[0]?.recordLabel,
      },
      artist_name: hit._source.attributes.artistName,
      artists: [],
    };
  });

  if (hits.length === 1) hits[0].index = 0;

  return hits;
}

const defaultUserChartColor = "#0ef1db";
function getColorScheme() {
  // return Colors.colorSchemes[graphColorScheme];  // Old nivo colorscheme
  return [
    "#1ED760",
    "#A157FF",
    "#5667FF",
    // "#4FD2EF", // <== removed cause the default user color is to similar
    "#EA2A2A",
    "#FF46E1",
    "#e8c1a0",
    "#f5765f",
    "#50B432",
    "#ED561B",
    "#DDDF00",
    "#bdf6ff",
    "#64E572",
    "#FF9655",
    "#FFF263",
    "#6AF9C4",
  ];
}

function initializeChartsColorsObject() {
  return Object.fromEntries(getColorScheme().map((c) => [c, false]));
}

function findFreeChartsColor(reduxStateChartsColors) {
  return Object.keys(reduxStateChartsColors).find(
    (color, i) => reduxStateChartsColors[color] === false
  );
}

function blockInput(value) {
  if (document.querySelector("input#react-select-2-input")) {
    // the number is 2 in production, 3 in dev because of the react-select package, so it has to be conditionally queried
    var input = document.querySelector("input#react-select-2-input");
  } else if (document.querySelector("input#react-select-3-input")) {
    input = document.querySelector("input#react-select-3-input");
  } else {
    return false;
  }

  if (value) {
    input.classList.add("d-none");
  } else {
    input.classList.remove("d-none");
  }
}

function parseGenericSearch(raw, filter) {
  return [
    {
      label: "artists",
      options: raw?.artists?.items?.map((i) => ({
        label: i.name,
        spotify_id: i.id,
        type: i.type,
      })),
    },
    {
      label: "tracks",
      options: raw?.tracks?.items?.map((i) => ({
        label: i.name,
        spotify_id: i.id,
        artists: i.artists,
        type: i.type,
      })),
    },
    {
      label: "albums",
      options: raw?.albums?.items?.map((i) => ({
        label: i.name,
        spotify_id: i.id,
        artists: i.artists,
        type: i.type,
      })),
    },
    {
      label: "labels",
      options: raw?.labels?.map((i) => ({
        type: "label",
        label: i,
        spotify_id: i,
      })),
    },
  ].filter((result) => {
    let resultLabel = result.label.slice(0, result.label.length - 1); // TODO: decide if "s" or not across BE and FE
    return filter.includes(resultLabel);
  });
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function disablePlatform(platform, addUserMessage) {
  platform.disabled = true;
  platform.msg = platform.constraints.msg;

  addUserMessage(platform.constraints.longMsg);
}

function checkPlatformConstraints(
  type,
  platforms,
  releaseData,
  addUserMessage
) {
  // type = "incompatible" - the script checks field values which cannot appear in the release in order to succeed
  // type = "required" - the script checks field values which have to appear in the release in order to succeed

  platforms.map((platform) => {
    if (!platform?.constraints?.[type]) return false;
    platform.constraints[type].map((constraint) => {
      // assigning incompatibilities specific to release
      if (platform.constraints.stage.includes("release")) {
        let targetPlatformProperty = Object.values(constraint)[0];
        let inspectedReleaseProperty = releaseData[Object.keys(constraint)[0]];
        if (
          typeof inspectedReleaseProperty === "string" &&
          (type === "incompatible"
            ? inspectedReleaseProperty === targetPlatformProperty
            : inspectedReleaseProperty !== targetPlatformProperty) // actual check (bidirectional - "incompatible" / "required" - depends on the "type" argument)
        ) {
          // if user set a certain prop to the incompatible value (ex. Spotify - primary genre - Trap...)
          // display messages, deactivate platforms
          disablePlatform(platform, addUserMessage);
        }
        if (
          typeof inspectedReleaseProperty === "object" &&
          inspectedReleaseProperty.length > 0
        ) {
          let requiredCount = 0;
          // checks nested arrays (ex. participants)
          inspectedReleaseProperty.forEach((nestedProperty) => {
            if (
              type === "incompatible" &&
              Object.values(nestedProperty).some(
                (value) => Object.values(targetPlatformProperty).includes(value) // actual check (bidirectional - "incompatible" / "required" - depends on the "type" argument)
              )
            ) {
              disablePlatform(platform, addUserMessage);
            }
            if (
              type === "required" &&
              Object.values(nestedProperty).some(
                (value) => Object.values(targetPlatformProperty).includes(value) // actual check (bidirectional - "incompatible" / "required" - depends on the "type" argument)
              )
            ) {
              requiredCount++;
            }
          });
          if (requiredCount === 0 && type === "required") {
            disablePlatform(platform, addUserMessage);
          }
        } else if (
          typeof inspectedReleaseProperty === "object" &&
          inspectedReleaseProperty.length === 0 &&
          type === "required"
        ) {
          disablePlatform(platform, addUserMessage);
        }
      }
      if (platform.constraints.stage.includes("track")) {
        releaseData.tracks.map((trackData) => {
          let targetPlatformProperty = Object.values(constraint)[0];
          let inspectedTrackProperty =
            trackData?.form?.[Object.keys(constraint)[0]];
          if (
            typeof inspectedTrackProperty === "string" &&
            (type === "incompatible"
              ? targetPlatformProperty === inspectedTrackProperty
              : targetPlatformProperty !== inspectedTrackProperty) // actual check (bidirectional - "incompatible" / "required" - depends on the "type" argument)
          ) {
            // checks strings in highest level props
            disablePlatform(platform, addUserMessage);
          }
          if (
            typeof inspectedTrackProperty === "object" &&
            inspectedTrackProperty.length > 0
          ) {
            let requiredCount = 0;
            // checks nested arrays (ex. participants)
            inspectedTrackProperty.forEach((nestedProperty) => {
              if (
                type === "incompatible" &&
                Object.values(nestedProperty).some((value) =>
                  Object.values(targetPlatformProperty).includes(value)
                )
              ) {
                disablePlatform(platform, addUserMessage);
              }
              if (
                type === "required" &&
                Object.values(nestedProperty).some((value) =>
                  Object.values(targetPlatformProperty).includes(value)
                )
              ) {
                requiredCount++;
              }
            });
            if (requiredCount === 0 && type === "required") {
              disablePlatform(platform, addUserMessage);
            }
          } else if (
            typeof inspectedTrackProperty === "object" &&
            inspectedTrackProperty.length === 0 &&
            type === "required"
          ) {
            disablePlatform(platform, addUserMessage);
          }
        });
      }
    });
  });
}

/**
 * Sorts cards in data dashboard
 * Cards with data go first, then unconnected, then comingSoon
 *
 * @param {Array} cards
 * @param {Object} platformConnected
 */
function sortStatsCards(cards, platformConnected, pageConnected) {
  return cards.sort((cardA, cardB) => {
    if (cardA.comingSoon) return 1;

    if (!platformConnected[cardA.platform] || !pageConnected[cardA.platform]) {
      if (cardB.comingSoon) return 1;
      return 1;
    }
    return -1;
  });
}

function setPath(id, name, type, pathname = window.location.pathname) {
  return `${pathname}?id=${id}&entityName=${name}&type=${type}`;
}

function isIframe() {
  return window.location !== window.parent.location;
}

const planMap = {
  playtreks_free: { name: "Free", price: 0, card_index: 0, plan: undefined },
  playtreks_unlimited_dashboard: {
    name: "Analyst",
    price: 7.95,
    card_index: 1,
    plan: 0,
  },
  playtreks_artist_1: { name: "Artist-1", price: 5.95, card_index: 2, plan: 1 },
  playtreks_artist_2: {
    name: "Artist-2",
    price: 9.95,
    card_index: 2,
    plan: 2,
  },
  playtreks_artist_5: {
    name: "Artist-5",
    price: 14.95,
    card_index: 2,
    plan: 5,
  },
  playtreks_artist_9: {
    name: "Artist-9",
    price: 18.95,
    card_index: 2,
    plan: 9,
  },
  playtreks_label_10: {
    name: "Label-10",
    price: 19.95,
    card_index: 3,
    plan: 10,
  },
  playtreks_label_20: {
    name: "Label-20",
    price: 29.95,
    card_index: 3,
    plan: 20,
  },
  playtreks_label_50: {
    name: "Label-50",
    price: 59.95,
    card_index: 3,
    plan: 50,
  },
  playtreks_label_100: {
    name: "Label-100",
    price: 99.95,
    card_index: 3,
    plan: 100,
  },
};

export const labelPlans = [
  "playtreks_label_10",
  "playtreks_label_20",
  "playtreks_label_50",
  "playtreks_label_100",
];

const getJWT = () => getCookie("jwtToken");

function insertAt(array, index, element) {
  // inserts an item into an array at specific index
  array.splice(index, 0, element);
}

/**
 *
 * @param {Array} source - array of numbers of which range should be applied to the target array
 * @param {Array} target - output array
 */
const numRangeOf = (source, target) =>
  target.slice(Math.min(...source) - 1, Math.max(...source));

const numMinOf = (source, target) =>
  target.slice(Math.min(...source) - 1, target.length);

const numMaxOf = (source, target) => target.slice(0, Math.max(...source));

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

const FIRST = "first";
const MIDDLE = "middle";
const LAST = "last";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url#5717133
function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  // return url.protocol === "http:" || url.protocol === "https:";
  return url.protocol === "https:";
}

const formatDateDistance = (date) => {
  const now = new Date();
  const distance = now - new Date(date);

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (days > 30) {
    return moment(date).format("MMM DD, YYYY");
  }
  if (days > 0) {
    return `${days} day(s) ago`;
  }
  if (hours > 0) {
    return `${hours} hour(s) ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute(s) ago`;
  }

  return `${seconds} second(s) ago`;
};

export {
  removeTrailingCharacter,
  artistsToString,
  trackParse,
  defaultUserChartColor,
  getColorScheme,
  initializeChartsColorsObject,
  findFreeChartsColor,
  blockInput,
  parseGenericSearch,
  parseAppleTrack,
  PLATFORM,
  SOCIAL_PLATFORM,
  numberWithCommas,
  disablePlatform,
  checkPlatformConstraints,
  sortStatsCards,
  setPath,
  isIframe,
  planMap,
  getJWT,
  insertAt,
  numRangeOf,
  numMinOf,
  numMaxOf,
  arrayEquals,
  FIRST,
  MIDDLE,
  LAST,
  capitalizeFirstLetter,
  isValidHttpUrl,
  formatDateDistance,
};

export const getCookie = (cName) => {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

export const isUserProfileIncomplete = (user) => {
  return (
    !user?.firstname ||
    !user?.lastname ||
    !user?.email ||
    !user?.phoneCountryCode ||
    !user?.phone ||
    !user?.phoneVerified ||
    !user?.city ||
    !user?.state ||
    !user?.zipcode ||
    !user?.address ||
    !user?.country
  );
};
