import React, { memo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import map from "@highcharts/map-collection/custom/world-palestine-highres.geo.json";
import proj4 from "proj4";

import Spinner from "../../../assets/spinner.svg";
import { isEqual } from "lodash";
import { getCountriesOptions } from "../utils";

// Required for to use maps with data points as lat/long
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

highchartsMap(Highcharts);

const formatNumber = new Intl.NumberFormat().format;

const countriesOptions = getCountriesOptions();

const ChartsAggregationMap = memo(
  ({ height = "450px", loading, data, setCountryFilter }) => {
    const handleOnCountryClick = (event) => {
      const clickedCountryCode = event.point.key;
      setCountryFilter(countriesOptions[clickedCountryCode]);
      window.scrollTo(0, document.body.scrollHeight);
    };

    const chartOptions = {
      title: {
        text: "",
      },
      plotOptions: {
        map: {
          borderWidth: "0.2",
          borderColor: "white",
          nullColor: "#1d2025",
        },
      },
      chart: {
        backgroundColor: "#1d2025",
        height: height,
      },
      legend: {
        itemStyle: {
          color: "#FFFFFF",
          cursor: "pointer",
          fontSize: "12px",
          fontWeight: "normal",
          textOverflow: "ellipsis",
        },
        itemHoverStyle: {
          color: "#FFFFFF",
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: "bold",
          textOverflow: "ellipsis",
        },
      },
      tooltip: {
        useHTML: true,
        backgroundColor: "#1d2025",
        borderRadius: 10,
        style: {
          zIndex: 9999,
          color: "white",
          opacity: 0.5,
        },
        shared: false,
      },
      subtitle: {
        floating: true,
        align: "right",
        y: 50,
        style: {
          fontSize: "16px",
        },
      },
      colorAxis: [
        {
          min: 0,
          minColor: "#383A3A",
          maxColor: "#3ec0b3",
          showInLegend: true,
        },
      ],
      credits: {
        enabled: false,
      },
      series: [
        {
          // Use the gb-all map with no data as a basemap
          mapData: map,
          name: "Basemap",
          borderColor: "#A0A0A0",
          showInLegend: false,
        },
        {
          name: "Separators",
          type: "mapline",
          data: Highcharts.geojson(map, "mapline"),
          color: "#FFFFFF",
          showInLegend: false,
          enableMouseTracking: false,
        },
        {
          mapData: map,
          joinBy: ["hc-key", "key"],
          name: "Countries",
          allowPointSelect: true,
          color: "#066a60",
          point: { events: { click: handleOnCountryClick } },
          colorAxis: 0,
          data: data?.map((c) => ({
            key: c._id,
            value: c.count,
            streamsCount: formatNumber(c.sum_streams),
          })),
          tooltip: {
            pointFormatter: function () {
              return `<b>Country: ${this?.name ?? ""}</b><br/>Count: ${
                this?.value
              }<br/>Streams: ${this?.streamsCount}`;
            },
          },
        },
      ],
      mapNavigation: {
        enabled: true,
        enableMouseWheelZoom: false,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
    };

    return (
      <>
        <div className="position-relative">
          {loading && (
            <div
              className="position-absolute top-40 left-50"
              style={{ zIndex: 1 }}
            >
              <img
                src={Spinner}
                alt="loading"
                className="dashboard-overview-streaming-stats-spinner"
              />
            </div>
          )}
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            constructorType={"mapChart"}
          />
        </div>
      </>
    );
  },
  isEqual
);

export default ChartsAggregationMap;
