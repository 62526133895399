import React from "react";
import ButtonComponent from "../form/ButtonComponent";
// import HeartIcon from "../../assets/icons/heart_outline.svg";

function Invite({ emoji, title, subTitle, linkText, onInviteClick }) {
  return (
    <div className="sidebar-invite">
      {/* TODO: add random heart icon in background */}
      {/* <span className="sidebar-invite-star" style={{backgroundImage: `url(${HeartIcon})`  , margin: '10px'}}></span> */}
      <span className="sidebar-invite-emoji" role="img" aria-label={emoji.name}>
        {emoji.emoji}
      </span>
      <h4 className="sidebar-invite-title">{title}</h4>
      <h5 className="sidebar-invite-subtitle">{subTitle}</h5>
      <ButtonComponent
        buttonWrapperClassName="sidebar-invite-button"
        onClick={(e) => onInviteClick()}
      >
        {linkText}
      </ButtonComponent>
    </div>
  );
}

export default Invite;
