import React, { useEffect } from "react";
import "./contract.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { TabPanel } from "../../../components/artistDashboard/TabPanel";
import { Drawer } from "@material-ui/core";
import { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContractForm from "./ContractForm";
import { deleteContract, getContract } from "../../../services/contracts";
import { useHistory, useParams } from "react-router-dom";
import { useRef } from "react";
import CaretDownIcon from "../../../assets/icons/icon-caret-down.svg";
import Toast from "../../Toast";
import InputComponent from "../../form/InputComponent";
import { useQuery } from "react-query";
import { getRelease } from "../../../services/distribution";
import { useSelector } from "react-redux";
import { labelPlans } from "../../utils";
import Spinner from "../../../assets/spinner.svg";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import ContractExpenses from "./ContractExpenses";
import ContractRhBalance from "./ContractRhBalance";
import ContractTransactions from "./ContractTransactions";
import ContractBalance from "./ContractBalance";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const INITIAL_PAGE_SIZE = 10;

const ContractDetails = () => {
  const { contractId } = useParams();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [toast, setToast] = useState(initialToastState);

  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const [contractActionOpen, setContractActionOpen] = useState(false);

  const [isOpenResendCollabModal, setIsOpenResendCollabModal] = useState(false);
  const [rightholderToResendMail, setRightholderToResendMail] = useState(null);

  const {
    data: { data: contractDetails } = {},
    isLoading,
    refetch,
  } = useQuery(["contract", contractId], () => getContract(contractId), {
    enabled: !!contractId,
  });

  const owners = contractDetails?.rightsholders?.filter((rightholder) => {
    return rightholder?.type === "owner";
  });

  const isOwner = owners?.length > 0;

  const handleOnModalClose = () => {
    setIsOpenResendCollabModal(false);
  };

  const handleOpenResendModal = (rightholder) => () => {
    setIsOpenResendCollabModal(true);
    setRightholderToResendMail({ ...rightholder, ...rightholder?.contact });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState({ isOpen });
  };

  const node = useRef();
  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    setContractActionOpen(false);
  };

  useEffect(() => {
    if (contractActionOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [contractActionOpen]);

  const handleDelete = async () => {
    let confirmDelete = window.confirm(
      "Are you sure you want to delete this contract?"
    );
    if (confirmDelete) {
      try {
        await deleteContract(contractDetails._id);
        setToast({
          type: "success",
          open: true,
          message: "Deleted successfully",
        });

        setTimeout(() => {
          history.push("/artistDashboard/contracts");
        }, 1500);
      } catch {
        setToast({
          type: "danger",
          open: true,
          message: "Something went wrong",
        });
      }
    }
  };

  const reduxCurrentPlan = useSelector(
    (state) => state?.subscriptionReducer?.subscription?.dataSubscription?.plan
  );

  const isLabelPlan = labelPlans.some((plan) =>
    plan.includes(reduxCurrentPlan)
  );
  const contractsData = [
    {
      name: "Contract 1",
      startDate: "2021-01-01",
      endDate: "2021-01-01",
      createdAt: "2021-01-01",
    },
    {
      name: "Contract 2",
      startDate: "2021-01-01",
      endDate: "2021-01-01",
      createdAt: "2021-01-01",
    },
  ];

  const contracts = React.useMemo(() => contractsData, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: contracts,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <main className="myReleases-overview text-white">
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />
      <div className="row align-items-center justify-content-between px-4 ">
        <div className="contract-details-header fs-32 px-2">
          {contractDetails?.name}
        </div>
        {isOwner && (
          <div
            className="user-profile mb-0"
            style={{ cursor: "pointer" }}
            ref={node}
            onClick={(e) => setContractActionOpen(!contractActionOpen)}
          >
            <div className="delete-contract rounded p-2 fs-10">
              ACTIONS
              <img
                src={CaretDownIcon}
                className={` user-profile-caret ${
                  contractActionOpen ? "up" : ""
                } `}
                alt="caret"
                onClick={(e) => setContractActionOpen(!contractActionOpen)}
              />
            </div>
            <div
              className={`user-profile-dropdown w- ${
                contractActionOpen ? "show" : ""
              } `}
            >
              <ul>
                <li>
                  <div
                    className="user-profile-dropdown-inner"
                    onClick={toggleDrawer(true)}
                  >
                    Edit
                  </div>
                </li>

                <li>
                  <div
                    className="user-profile-dropdown-inner"
                    onClick={handleDelete}
                  >
                    Delete
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>

      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 admin-release-drawer`}
          role="presentation"
        >
          <div
            onClick={toggleDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span>Edit Contract</span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
          <ContractForm
            onUpdate={setDrawerState}
            onClose={toggleDrawer(false)}
            contract={contractDetails}
            edit={true}
            refetch={refetch}
          />
        </div>
      </Drawer>

      <div className="prTracksCard w-100 pb-0">
        <Tabs value={value} onChange={handleChange} indicatorColor="primary">
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Balance" {...a11yProps(1)} />
          {isLabelPlan && <Tab label="Expenses" {...a11yProps(2)} />}
          {/* <Tab label="Rights-Holders balances" {...a11yProps(3)} /> */}
          {/* <Tab label="Transactions" {...a11yProps(4)} /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className="contract-header mb-3">METADATA</div>

          {isLoading ? (
            <div className="dashboard-overview-streaming-stats-spinner-wrapper">
              <img
                src={Spinner}
                alt="loading"
                className="dashboard-overview-streaming-stats-spinner"
              />
            </div>
          ) : (
            <>
              <div
                className="rounded text-white  p-3"
                style={{ backgroundColor: "#2c313a" }}
              >
                <div className="prrcText  pb-4">
                  Contract Name:{" "}
                  <span className="text-white font-weight-normal ">
                    {contractDetails?.name}
                  </span>
                </div>
                <div className="pb-4 prrcText">
                  Start Date:{" "}
                  <span className="text-white font-weight-normal">
                    {contractDetails?.startDate?.split("T")[0]}
                  </span>
                </div>
                <div className="prrcText pb-4">
                  End Date:{" "}
                  <span className="text-white font-weight-normal">
                    {contractDetails?.endDate
                      ? contractDetails?.endDate?.split("T")[0]
                      : "N/A"}
                  </span>
                </div>

                <div className="prrcText">
                  Creation Date:{" "}
                  <span className="text-white font-weight-normal">
                    {contractDetails?.createdAt?.split("T")[0]}
                  </span>
                </div>
              </div>

              <div className="contract-header my-3">CONTRACT SPLITS</div>

              <div
                className="rounded text-white  p-3"
                style={{ backgroundColor: "#2c313a" }}
              >
                <div
                  className={`row px-0 mx-0 ${isLabelPlan ? "col" : "col-11"}`}
                >
                  <div className={`col-2 mb-1  align-items-start}`}>
                    <b>Royalties</b>
                  </div>

                  {isLabelPlan && (
                    <div className="col-2 mb-1  align-items-start">
                      <b>Expenses</b>
                    </div>
                  )}
                  <div className={isLabelPlan ? "col-7" : "col-8"}></div>
                  <div className="col-1"></div>
                </div>
                {contractDetails?.rightsholders?.map((rightholder, index) => {
                  rightholder.name = `${
                    rightholder?.contact?.firstName || ""
                  } ${rightholder?.contact?.lastName || ""}`;

                  return (
                    <div key={index}>
                      <div className="row align-items-center mb-3 col mx-0 px-0">
                        {/* royaltiesShare */}
                        <div
                          className={`py-2  py-md-0  ${
                            isLabelPlan ? "col-2" : "col-3"
                          }`}
                        >
                          <div
                            className={
                              (rightholder?.royaltiesShare ? "" : "d-none") +
                              " inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            royalties share
                          </div>
                          <div className="position-relative">
                            <InputComponent
                              disabled={true}
                              value={rightholder?.royaltiesShare}
                              inputType="number"
                              commentField={".utiuc3_" + index}
                              placeholder="Share"
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (rightholder?.royaltiesShare
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                              tooltip={true}
                              maxlength={3}
                            />
                            {rightholder?.royaltiesShare >= 0 ? (
                              <div
                                className="position-absolute utepPercent-2"
                                style={{ right: 12, bottom: 10, zIndex: 100 }}
                              >
                                %
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* Expense share */}
                        {isLabelPlan && (
                          <div className="py-2 col-2 py-md-0 ">
                            <div
                              className={
                                (rightholder?.expenseShare ? "" : "d-none") +
                                " inputUpperComment utiuc3_" +
                                index
                              }
                            >
                              expense share
                            </div>
                            <div className="position-relative">
                              <InputComponent
                                disabled={true}
                                value={rightholder?.expenseShare}
                                inputType="number"
                                commentField={".utiuc3_" + index}
                                placeholder="Expense Share"
                                inputGroupClassName="nrInputGroup "
                                inputClassName={
                                  " nrInput utepInput " +
                                  (rightholder?.expenseShare
                                    ? " interMediumInput interMediumPaddingTop"
                                    : "")
                                }
                                paddingTopOnInput={true}
                                addon={false}
                                tooltip={true}
                                maxlength={3}
                              />
                              {rightholder?.expenseShare >= 0 ? (
                                <div
                                  className="position-absolute utepPercent-2"
                                  style={{ right: 12, bottom: 10, zIndex: 100 }}
                                >
                                  %
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        )}
                        {/* Contact */}
                        <div
                          className={`py-2  py-md-0 ${
                            isLabelPlan ? "col-7" : "col-8"
                          }`}
                        >
                          <div
                            className={
                              (rightholder?.name ? "" : "d-none") +
                              "inputUpperComment utiuc3_" +
                              index
                            }
                          >
                            Contact
                          </div>
                          <div className="postion-relative">
                            <InputComponent
                              disabled={true}
                              value={rightholder?.name}
                              commentField={".utiuc3_" + index}
                              inputGroupClassName="nrInputGroup "
                              inputClassName={
                                " nrInput utepInput " +
                                (rightholder?.name
                                  ? " interMediumInput interMediumPaddingTop"
                                  : "")
                              }
                              paddingTopOnInput={true}
                              addon={false}
                              for="artist"
                              databaseField="#participants"
                              databaseKey="contributors"
                              databaseSubKey="share"
                              tooltip={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContractBalance />
        </TabPanel>
        {isLabelPlan && (
          <TabPanel value={value} index={2}>
            <ContractExpenses contractId={contractId} />
          </TabPanel>
        )}

        {/* <TabPanel value={value} index={!isLabelPlan ? 2 : 3}>
          <ContractRhBalance />
        </TabPanel> */}

        {/* <TabPanel value={value} index={!isLabelPlan ? 3 : 4}>
          <ContractTransactions />
        </TabPanel> */}
      </div>
    </main>
  );
};

export default ContractDetails;
