import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ReactPostprocessor from "i18next-react-postprocessor";
// import XHR from "i18next-xhr-backend";

import en from "./locales/en.json";

i18n
  .use(LanguageDetector)
  .use(new ReactPostprocessor())
  .init({
    debug: true,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false,

    resources: {
      en: {
        translations: en,
      },
      // de: {
      //     translations: de
      // },
    },

    ns: ["translations"],
    defaultNS: "translations",

    interpolation: {
      formatSeparator: ",",
    },

    postProcess: [`reactPostprocessor`],

    react: {
      wait: true,
    },
  });

export default i18n;
