import React, { useEffect, useState } from "react";
// import Sidebar from "../artistDashboard/sidebar";
import Sidebar from "../../../artistDashboard/sidebar";
import MobileMenuBurger, {
  MobileMenu,
} from "../../../artistDashboard/MobileMenu/MobileMenu";

export default function DistributionAudioRequirements() {
  return (
    <div>
      <div
        style={{ overflowX: "none !important", backgroundColor: "#343a47" }}
        className="ptTermsContent welcomePage"
      >
        {/* <MobileMenu /> */}

        <p class="c7" id="h.vnmu7lqfrfpd">
          <span class="c8">Audio Requirements</span>
        </p>
        <p class="c4 c6">
          <span class="c3"></span>
        </p>
        <ul class="c2 lst-kix_8ofcgfh9e2qg-0 start">
          <li class="c1">
            <span class="c0">Audio files requirements</span>
          </li>
        </ul>
        <ol class="c2 lst-kix_xybsj5qqgpxr-0 start" start="1">
          <li class="c1">
            <span class="c3">
              &nbsp;Mastering. In order to ensure a good audio quality and meet
              the standards of today&rsquo;s music industry, all audio files
              must have undergone professional mastering before their
              distribution.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;Audio quality. The audio files should not have any sound
              imperfection. Audio files containing any background noise and
              other sound imperfections will not be accepted for distribution.
            </span>
          </li>
        </ol>
        <ul class="c2 lst-kix_lljj21ubtuaj-0 start">
          <li class="c1">
            <span class="c3">Format: flac or wav</span>
          </li>
          <li class="c1">
            <span class="c3">
              Requirements: Minimum of 16 bit, 44.1 Khz, stereo / Recommended 24
              bits, 48Khz or 24 bits 96Khz.
            </span>
          </li>
        </ul>
        <p class="c4">
          <span class="c3">&nbsp;</span>
        </p>
        <ol class="c2 lst-kix_xybsj5qqgpxr-0" start="3">
          <li class="c1">
            <span class="c3">
              &nbsp;Audios and titles. Audio files and track titles must always
              match. Audios uploaded to non-corresponding tracks are not
              accepted.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;Silences, pauses and cuts. Silence, cuts, pauses or extended
              silences are not allowed. If a song ends with a final silence it
              should last no longer than ten seconds.
            </span>
          </li>
        </ol>
        <p class="c4 c5">
          <span class="c3">
            Other kinds of silences, such as cuts or sudden endings, must also
            be avoided, as they can be misunderstood as audio errors.
          </span>
        </p>
        <ol class="c2 lst-kix_xybsj5qqgpxr-0" start="5">
          <li class="c1">
            <span class="c3">
              &nbsp;Silent and hidden tracks. The silent tracks, hidden tracks
              and ghost tracks should be indicated in the &ldquo;Version&rdquo;
              field. If there is a division between tracks it must follow the
              silence requirements mentioned above.
            </span>
          </li>
        </ol>
        <p class="c4 c5">
          <span class="c3">
            A justification may be needed to know the reason why they are
            included and the duration in relation to the whole content.
          </span>
        </p>
        <p class="c4 c5 c6">
          <span class="c3"></span>
        </p>
        <ul class="c2 lst-kix_og1jipqi4v3o-0 start">
          <li class="c1">
            <span class="c0">Policy of use of Samples</span>
          </li>
        </ul>
        <ol class="c2 lst-kix_wiv0lthtd9rf-0 start" start="1">
          <li class="c1">
            <span class="c3">
              &nbsp;If the Sample was obtained directly from the original owner.
              The original owner must provide a valid document that expresses
              his agreement with the obtainment, use and commercialization of
              his Sample, by the artist who intends to distribute it through the
              platform.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;If the Sample was obtained from an instrument, either
              physical or virtual instrument. The license or any other evidence
              that can demonstrate that the Sample has been obtained from an
              instrument must be presented. Similarly, if applicable, you must
              present the license that is usually available with the instrument.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;If the Sample was obtained through a payment service (per
              unit or per subscription). A proof of payment must be presented
              for the unit, or the registration of the subscription to said
              service, as well as an evidence that the Sample has been obtained
              through this specific payment service.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;If the Sample was obtained through a free legal service. Any
              valid evidence that could demonstrate that the Sample has been
              obtained through the free service, must be presented, and also a
              valid evidence that could prove that the commercialization of the
              obtained Sample is authorized.
            </span>
          </li>
          <li class="c1">
            <span class="c3">
              &nbsp;If the Sample has not been obtained by any of the
              aforementioned means. It must be justified and in the same way,
              present a document or any evidence that could prove that:
            </span>
          </li>
        </ol>
        <ul class="c2 lst-kix_x6slc0aknq9m-0 start">
          <li class="c1">
            <span class="c3">The use of the Sample is allowed</span>
          </li>
          <li class="c1">
            <span class="c3">It has been provided by a legal means</span>
          </li>
          <li class="c1">
            <span class="c3">It has been obtained in a legal manner</span>
          </li>
          <li class="c1">
            <span class="c3">
              It grants permission to the commercialization of the Sample or
              derivative works
            </span>
          </li>
        </ul>
        <p class="c4 c5">
          <span class="c3">
            If the provisions of this policy are not met, then the content
            involved will not be approved, nor will its distribution be
            accepted.
          </span>
        </p>
        <p class="c4 c5">
          <span class="c3">
            Finally, we state that it is the duty and responsibility of the
            administrator of the service provided through the platform, the
            management of the issues raised, directly with the user who provides
            the content that contains any Sample.
          </span>
        </p>
        <p class="c4 c6 c5">
          <span class="c3"></span>
        </p>
        <p class="c4 c6">
          <span class="c3"></span>
        </p>
        <div className="ppFooter">
          {/*<div className="lpfText1">{t("lpFooterText1")}</div>
        <br 
        <div className="lpfText2">{t("lpFooterText2")}</div> }
        <br /> 
        <ButtonComponent
          className="playtreksButton lpButton lpBtn5"
          onClick={handleSendEmail}
        >
          {t("contactPt")}
        </ButtonComponent> */}

          {/* <div className="lpfLegal">
            © 2021 Playtreks BV, Hendrik Van Velde Singel 150, 3500 Hasselt,
            Belgium Company No. BE0749505934 &nbsp;&nbsp; <a href="/">Home</a>{" "}
            &nbsp;&nbsp; <a href="/About">About</a>&nbsp;&nbsp;{" "}
            <a href="/HowitRolls">How it rolls</a>&nbsp;&nbsp;{" "}
            <a href="/privacypolicy">Privacy Policy</a> &nbsp;&nbsp;{" "}
            <a href="/terms">Terms & Conditions</a>
          </div> */}

          {/* <div className="lpfSocials">
          <img
            alt="fb"
            src={fb}
            onClick={() =>
              window.open("https://www.facebook.com/playtreksapp/")
            }
            style={{ cursor: "pointer" }}
          />
          <img
            alt="tw"
            src={tw}
            style={{ cursor: "pointer" }}
            onClick={() => window.open("https://twitter.com/PlayTreks")}
          />
          <img
            alt="ig"
            src={ig}
            onClick={() => window.open("https://www.instagram.com/playtreks/")}
            style={{ cursor: "pointer" }}
          />
        </div> */}
        </div>
      </div>
    </div>
  );
}

// module.exports = { DistributionMetadataGuidelines };
