import React from "react";

function MyReleasesSuccessModal({ submittedReleaseName }) {
  return (
    <div>
      {submittedReleaseName ? (
        <div className="playtreksModal-overlay">
          <div className="playtreksModal-modal">
            <div className="playtreksModal-releaseSuccess">
              <div className="playtreksModal-modal-header">
                Congratulations!&nbsp;
                <div aria-label="tada" alt="congrats">
                  🎉
                </div>
              </div>
              <br />
              You have successfully submitted {submittedReleaseName}! <br />
              <br />
              Please check your e-mail inbox for further instructions. <br />
              <br />
              <div
                className="playtreksModal-modal-btn playtreksModal-releaseSuccess-btn"
                onClick={() =>
                  window.location.assign("/artistDashboard/myReleases")
                }
              >
                Ok
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default MyReleasesSuccessModal;
