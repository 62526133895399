import React, { useState, useEffect } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import "./App.scss";
import "./fonts.css";
import "./assets/scss/components/buttons.scss";
import "./assets/scss/components/inputs.scss";
import "./assets/scss/pt/error.scss";
import "./assets/scss/pt/distribution.scss";
import "./assets/scss/pt/newrelease.scss";
import "./assets/scss/pt/uploadtracks.scss";
import "./assets/scss/pt/ruslan.scss";
import "./assets/scss/pt/preview.scss";
import "./assets/scss/pt/finish.scss";
import "./assets/scss/pt/playtreksmodal.scss";

import "./assets/scss/pt/mobile/m_newrelease.scss";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
} from "react-router-dom";

import Onboarding from "./components/onboarding/Onboarding";
import Onboarding1 from "./components/onboarding/Onboarding1";
import Onboarding2 from "./components/onboarding/Onboarding2";
import Onboarding3 from "./components/onboarding/Onboarding3";
import Onboarding4 from "./components/onboarding/Onboarding4";
import Onboarding5 from "./components/onboarding/Onboarding5";

import Admin from "./components/admin/Admin";

import {
  SpotifyRedirect,
  FacebookRedirect,
  GoogleRedirect,
  TwitterRedirect,
} from "./components/onboarding/login/ExternalGenericRedirect";

import { protectedRoute, getUserMessage, getUserData } from "./routes/users";
import { getSubscriptionPlan } from "./routes/payments";

import DistributionBasicInformation from "./components/distribution/DistributionBasicInformation";
import DistributionUploadTracks from "./components/distribution/DistributionUploadTracks";
import DistributionPreview from "./components/distribution/DistributionPreview";
import DistributionReleaseFinish from "./components/distribution/DistributionReleaseFinish";
import DistributionMetadataGuidelines from "./components/distribution/components/guidelines/DistributionMetadataGuidelines.js";
import DistributionLicenseGuidelines from "./components/distribution/components/guidelines/DistributionLicenseGuidelines.js";
import DistributionAudioRequirements from "./components/distribution/components/guidelines/DistributionAudioRequirements.js";
import DistributionCoverArtRequirements from "./components/distribution/components/guidelines/DistributionCoverArtRequirements.js";
import ReleaseDetails from "./screens/Releases/ReleaseDetails";

import ArtistDashboard from "./components/artistDashboard/artistDashboard";

import { hotjar } from "react-hotjar";
import BlankPage from "./components/BlankPage";

import { I18nextProvider } from "react-i18next";
import i18n from "./locale";

import WelcomePage from "./components/welcomepage/WelcomePage";
import ValidateRegisteredUser from "./components/onboarding/login/ValidateRegisteredUser";

import UserBlockingModal from "./components/modals/UserBlockingModal";
import {
  reduxSetUserData,
  reduxSetWelcomePageData,
} from "./redux/actions/dashboardActions";
import { setSubscriptionPlan } from "./redux/actions/subscriptionActions";
import FeaturedEntity from "./components/welcomepage/FeaturedEntity";
import CustomAlert from "./components/customAlert";
import { removeAlert } from "./redux/actions/appActions";
import LoadingPage from "./components/LoadingPage";
import { getJWT } from "./components/utils";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import themeConfig from "./theme.json";
import ExpenseDetails from "./components/distribution/Expenses/ExpenseDetails";
import ContractDetails from "./components/artistDashboard/Contracts/ContractDetails";

const theme = createMuiTheme(themeConfig);

const hjid = "1961985";
const hjsv = 6;

hotjar.initialize(hjid, hjsv);

function App() {
  const [auth, setAuth] = useState("inProgress");
  const [adminAuth, setAdminAuth] = useState(false);
  const [userBlockingModal, setUserBlockingModal] = useState(false);

  const jwtToken = getJWT();

  const dispatch = useDispatch();
  const welcomePageData = useSelector(
    (state) => state.dashboardReducer?.welcomePageData
  );

  const reduxCustomAlerts = useSelector(
    (state) => state.appReducer.customAlerts
  );

  function authenticate() {
    protectedRoute()
      .then((r) => {
        setAdminAuth(r.data?.isAdmin || false);
        setAuth(true);
        // if user is authorized, check if there is messages
        getUserMessage()
          .then((r) => {
            if (r.data.msgType === "Blocking") {
              setUserBlockingModal({ msg: r.data.msg });
            }
          })
          .catch((e) => {});
        // TODO: get rid of getUserMessage (getUserData provides user message)
        dispatch(reduxSetUserData());
      })
      .catch((e) => {
        setAuth(false);
      });
    getSubscriptionPlan(jwtToken).then((r) => {
      dispatch(setSubscriptionPlan(r.data));
    });
  }

  useEffect(() => {
    authenticate();
    dispatch(reduxSetWelcomePageData());
  }, []);

  function handleCloseAlert(alert) {
    dispatch(removeAlert(alert));
  }

  if (auth === "inProgress") {
    return <LoadingPage />;
  }

  if (!auth) {
    window.location.href = `${
      global.config.AUTH_REDIRECT_URL
    }/login?redirect_uri=${encodeURIComponent(window.location.href)}`;

    return <LoadingPage />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        {userBlockingModal ? (
          <UserBlockingModal msg={userBlockingModal.msg} />
        ) : (
          ""
        )}

        <Router>
          {/* popups and alerts */}
          <div className="alert-container-customAlert-wrapper">
            {reduxCustomAlerts.map((alert) => (
              <CustomAlert
                variant={
                  "customAlert" + (alert.variant ? "-" + alert.variant : "")
                } // yellow by default
                xButton
                closeCallback={() => handleCloseAlert(alert)}
              >
                <Link to={alert.link} style={{ color: "black" }}>
                  {alert.text}
                </Link>
              </CustomAlert>
            ))}
          </div>

          {/* routes */}
          <Route
            path="/"
            exact
            render={() => <Redirect to={"/welcomePage"} />}
          />
          <Route path="/validate_user" component={ValidateRegisteredUser} />

          {/* PUBLIC ROUTE for using the component inside an iframe (for the pt website) */}
          <Route path="/featuredEntity">
            <FeaturedEntity entityData={welcomePageData} />
          </Route>

          {/* ONBOARDING  */}
          <PrivateRoute path="/onboarding" component={Onboarding} auth={auth} />
          <PrivateRoute
            path="/onboarding1"
            component={Onboarding1}
            auth={auth}
          />
          <PrivateRoute
            path="/onboarding2"
            component={Onboarding2}
            auth={auth}
          />
          {/* TODO: enable this when adding Apple artist profile */}
          {/* <PrivateRoute path="/onboarding3" component={Onboarding3} auth={auth} /> */}
          <PrivateRoute
            path="/onboarding4"
            component={Onboarding4}
            auth={auth}
          />
          <PrivateRoute
            path="/onboarding5"
            component={Onboarding5}
            auth={auth}
          />

          {/* DISTRIBUTION NEW RELEASE */}
          <PrivateRoute
            path="/distribution/newRelease/1"
            component={DistributionBasicInformation}
            auth={auth}
          />
          <PrivateRoute
            path="/distribution/newRelease/2"
            component={DistributionUploadTracks}
            auth={auth}
          />
          <PrivateRoute
            path="/distribution/newRelease/3"
            component={DistributionPreview}
            auth={auth}
          />
          <PrivateRoute
            path="/distribution/newRelease/4"
            component={DistributionReleaseFinish}
            auth={auth}
          />

          <PrivateRoute
            path="/distribution/metadataGuidelines"
            component={DistributionMetadataGuidelines}
            auth={auth}
          />

          <PrivateRoute
            path="/distribution/audioRequirements"
            component={DistributionAudioRequirements}
            auth={auth}
          />

          <PrivateRoute
            path="/distribution/coverartRequirements"
            component={DistributionCoverArtRequirements}
            auth={auth}
          />

          <PrivateRoute
            path="/distribution/licenseGuidelines"
            component={DistributionLicenseGuidelines}
            auth={auth}
          />

          <PrivateRoute
            path="/artistDashboard"
            component={ArtistDashboard}
            auth={auth}
          />

          <PrivateRoute
            path="/artistDashboard/myReleases/:releaseId"
            component={ReleaseDetails}
            auth={auth}
          />

          <PrivateRoute
            path="/artistDashboard/expense/:expenseId"
            component={ExpenseDetails}
            auth={auth}
          />

          <PrivateRoute
            path="/artistDashboard/contract/:contractId"
            component={ContractDetails}
            auth={auth}
          />

          <PrivateRoute
            path="/welcomePage"
            component={WelcomePage}
            auth={auth}
          />

          <Route
            path="/terms"
            component={() =>
              window.location.assign("https://www.playtreks.com/terms")
            }
          />

          <Route
            path="/privacypolicy"
            component={() =>
              window.location.assign("https://www.playtreks.com/privacypolicy")
            }
          />

          {/* Dashi <3 <PrivateRoute path="/search" component={Search} auth={auth} /> */}

          {/* external login related redirects */}
          <Route path="/spotify/redirect" component={SpotifyRedirect} />
          <Route path="/facebook/redirect" component={FacebookRedirect} />
          <Route path="/google/redirect" component={GoogleRedirect} />
          <Route path="/twitter/redirect" component={TwitterRedirect} />

          {/* ADMIN */}
          <AdminRoute // <== TODO: protect, only for admin
            path="/admin"
            component={Admin}
            auth={auth}
            adminAuth={adminAuth}
          />
        </Router>
      </ThemeProvider>
    </I18nextProvider>
  );
}

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (getJWT()) {
          return <Component {...props} />;
        }

        window.location.href = `${
          global.config.AUTH_REDIRECT_URL
        }/login?redirect_uri=${encodeURIComponent(window.location.href)}`;
        return <></>;
      }}
    />
  );
};

const AdminRoute = ({ auth, adminAuth, component: Component, ...rest }) => {
  if (auth === "inProgress" || !adminAuth) {
    return <BlankPage />;
  }

  if (!auth) {
    window.location.href = `${
      global.config.AUTH_REDIRECT_URL
    }/login?redirect_uri=${encodeURIComponent(window.location.href)}`;

    return <BlankPage />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        auth && adminAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default App;
