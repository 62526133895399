import axios from "../lib/axios_retry_wrapper";

const STRIPE = "stripe";
const PAYPAL = "paypal";

export function getPaymentMethod(jwtToken) {
  return axios.get(
    `${global.config.API_URL}/api/payments/stripe/payment_method`,
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    }
  );
}

export function setPaymentMethod(returnUrl, jwtToken) {
  return axios.post(
    `${global.config.API_URL}/api/payments/stripe/payment_method`,
    { return_url: returnUrl },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export function performPayment(
  dataSubscription,
  promoCode,
  jwtToken,
  processor = STRIPE
) {
  return axios.post(
    `${global.config.API_URL}/api/payments/${processor}/perform_payment`,
    { dataSubscription: dataSubscription, promo_code_text: promoCode },
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export function getSubscriptionStatuses(jwtToken) {
  return axios.get(
    `${global.config.API_URL}/api/payments/subscriptionStatuses`,
    {
      headers: {
        Authorization: "Bearer " + jwtToken,
      }, // <== in case of post request, authorization has to be passed as 3rd argument
    }
  );
}

export async function getSubscriptionPlan(jwtToken) {
  let url = `${global.config.API_URL}/api/payments/subscriptionStatuses`;

  const config = {
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  };

  return await axios.get(url, config);
}

// export function createStripeSessionCall() {
//   return axios.post(
//     `${global.config.API_URL}/api/payments/stripe/createSession`
//   );
// }

// export function createPayPalRequestCall() {
//   return axios({
//     method: "post",
//     url: global.config.API_URL + "/api/payments/paypal/createRequest",
//     // data: amount
//   });
// }
