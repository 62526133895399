import React, { useEffect, useMemo, useState } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";

import spinner from "../../../../assets/spinner.svg";

import { groupBy } from "lodash";
import moment from "moment";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { GlobalFilter } from "../../../admin/ReleasesAdmin";
import TableView from "../../../../screens/Admin/Releases/components/TableView";

import "./../index.scss";
import { monthMap, periods } from "../../../../shared/periods";
import SelectComponent from "../../../form/SelectComponent";

const INITIAL_PAGE_SIZE = 10;

function TransactionHistory({ selectedPeriodId, setSelectedPeriodId }) {
  const [loading, setLoading] = useState(false);
  const reduxUserTransactions = useSelector(
    (state) => state.moneyReducer?.userTransactions
  );
  const userTransactions = useMemo(
    () => reduxUserTransactions ?? [],
    [reduxUserTransactions]
  );

  const noData = useSelector((state) => state.moneyReducer?.transactionsNoData);
  useEffect(() => {
    if (userTransactions?.length > 0 || noData) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [userTransactions]);

  const customAmountCell = ({ value, row }) => {
    if (row.original.amount < 0) {
      return (
        <div className="font-weight-bolder" style={{ color: "red" }}>
          - € {Math.abs(value).toFixed(2)}
        </div>
      );
    } else
      return (
        <div className="text-primary font-weight-bolder">
          + € {value.toFixed(2)}
        </div>
      );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Period",
        accessor: "metadata.period._id",
      },
      {
        Header: "Date",
        accessor: "metadata.period.endDate",
        Cell: ({ value }) => (value ? moment(value).format("YYYY-MM") : ""),
      },

      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: customAmountCell,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: userTransactions ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
        hiddenColumns: ["metadata.period._id"],
        filters: [
          {
            id: "metadata.period._id",
            value: selectedPeriodId || "",
          },
        ],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  const periodOptions = {
    "": { label: "All", value: "" },
    ...periods.reduce((acc, period) => {
      const startDate = new Date(period.startDate.$date);
      const endDate = new Date(period.endDate.$date);
      const label = `${
        monthMap[endDate.getMonth() + 1]
      }, ${endDate.getFullYear()}`;
      acc[period._id.$oid] = { label, value: period._id.$oid };
      return acc;
    }, {}),
  };

  const PeriodFilterOptions = ({
    setFilter,
    filters,
    options = periodOptions,
    selectedPeriodId,
    setSelectedPeriodId,
  }) => {
    let initialPeriodFilter = filters.find(
      (f) => f.id === "metadata.period._id"
    );

    const [selectedPeriod, setSelectedPeriod] = useState(
      initialPeriodFilter?.value
        ? periodOptions[initialPeriodFilter.value] ?? periodOptions[""]
        : periodOptions[selectedPeriodId] ?? periodOptions[""]
    );

    return (
      <div
        className={
          "nrAlbumTitleInputWrapper col-12 col-md-4 col-lg-3 py-2 py-md-0 text-white"
        }
        id={"month"}
      >
        <div className="inputUpperComment iuc3 playtreksSelector">Period</div>
        <SelectComponent
          value={selectedPeriod}
          extendOptionWidth
          id={"month"}
          valuePaddingTop={true}
          placeholderTop="50%"
          placeholderFont="Inter-Medium"
          placeholderColor="white"
          placeholderOpacity="1"
          options={Object.values(periodOptions)}
          customNoOptionsMessage={<p classNam="ml-2">No options</p>}
          selectChangeCallback={(val) => {
            setSelectedPeriod(val);
            setFilter("metadata.period._id", val);
            setSelectedPeriodId(val);
          }}
          // to be used for data transfer
          selectBlurCallback={() => {}} // leave empty
        />
      </div>
    );
  };

  return (
    <div className="allowUserSelect">
      <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
        <div className="d-block mb-3" style={{ zIndex: 1 }}>
          <p className="font-weight-bold text-white mb-2">Filter by:</p>
          <div className="row pb-2 align-items-center justify-content-between">
            <div className="col-12 col-md">
              <div className="row">
                <GlobalFilter
                  preGlobalFilteredRows={preGlobalFilteredRows}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <PeriodFilterOptions
                  setFilter={setFilter}
                  filters={filters}
                  selectedPeriodId={selectedPeriodId}
                  setSelectedPeriodId={setSelectedPeriodId}
                />
              </div>
            </div>
          </div>
        </div>

        <TableView tableInstance={tableInstance} loading={loading} />
      </main>
    </div>
  );
}

export default TransactionHistory;
