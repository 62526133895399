import React from "react";

import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

// the default behaviour for this component is an addon on the left,
// if "false" passed - no addon,
// if "right" passed - addon on the right

/**
 *
 * @param {String / Boolean} props.addon - false (as a boolean) for no addon, "right" for addon to the right, undefined for addon to the left
 * @param {Function} props.inputBlurCallback
 * @returns JSX content
 */
function InputComponent(props) {
  function handleChange(e) {
    // transform the look of the field when user input is present
    if (!props.preventChangeAnimation) {
      if (e.target.value) {
        if (props.commentField) {
          document.querySelector(props.commentField).classList.remove("d-none");
        }
        e.target.classList.add("interMediumInput");
        if (props.paddingTopOnInput) {
          e.target.classList.add("interMediumPaddingTop");
        }
      } else {
        if (props.commentField) {
          document.querySelector(props.commentField).classList.add("d-none");
        }
        if (!props.blockPaddingRevert) {
          e.target.classList.remove("interMediumInput");
          e.target.classList.remove("interMediumPaddingTop");
        }
      }
    }
    // callback to parent component
    props.changeCallback(
      e.target.value,
      props.databaseField,
      props.databaseKey,
      props.id,
      props.databaseSubKey || props.databaseKey
    );
  }
  function handleBlur(e) {
    props.inputBlurCallback(
      e.target.value,
      props.databaseField,
      props.id,
      props.databaseKey,
      props.databaseSubKey
    );
  }
  function handleAddonClick(e) {
    if (props.addonClickCallback)
      props.addonClickCallback(
        "#" + props.for + "Wrapper" + props.id,
        props.for,
        props.id
      );
  }
  return (
    <InputGroup className={props.inputGroupClassName}>
      {props.addon !== false && props.addon !== "right" ? (
        <InputGroupAddon
          addonType="prepend"
          className={props.groupAddonClassName}
        >
          <InputGroupText className={props.inputGroupTextClassName}>
            <img
              alt={props.alt}
              src={props.img}
              className={props.imgClass || "inputIcon"}
            />
          </InputGroupText>
        </InputGroupAddon>
      ) : (
        ""
      )}
      {/* this div covers the input making it conditionally unclickable (for calendar fields) */}
      <Input
        disabled={props.disabled}
        type={props.inputType || "text"}
        placeholder={props.placeholder}
        className={
          props.inputClassName + " " + props.for || "" + props.id || ""
        }
        style={{
          textDecoration: props.lineThrough ? "line-through" : "",
          ...props.style,
        }}
        // onBlur={(e) => props.onBlur(e, props.databaseField)}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={props.onClick}
        id={props.databaseField}
        value={props.value}
        maxLength={props.maxlength}
        onCopy={props.onCopy}
        onPaste={props.onPaste}
        name={props?.name || props?.databaseField}
      />
      {props.addon === "right" ? (
        <InputGroupAddon
          addonType="prepend"
          className={props.groupAddonClassName}
          onClick={handleAddonClick}
        >
          <InputGroupText
            className={
              props.inputGroupTextClassName +
              " " +
              (props.disabled ? "disabledAddon" : "")
            }
          >
            <span>
              {!props.addonContent ? (
                <img
                  alt={props.alt}
                  src={props.img}
                  className={props.imgClass || "inputIcon"}
                  data-tip={props.tooltipText}
                />
              ) : (
                <span className={props.imgClass || "inputIcon"}>
                  {props.addonContent}
                </span>
              )}
            </span>
            {props.calendarAddon ? (
              <img
                alt={props.calAlt}
                src={props.calImg}
                className={props.calImgClass || "inputIcon"}
              ></img>
            ) : (
              ""
            )}
          </InputGroupText>
        </InputGroupAddon>
      ) : (
        ""
      )}
    </InputGroup>
  );
}

export default InputComponent;
