import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addAlert } from "../../../redux/actions/appActions";
import { getUsedArtists } from "../../../routes/artist";
import SelectComponent from "../../form/SelectComponent";

function PrimaryArtistFieldComponent({
  i,
  artistsArray,
  InputComponent,
  inputChangeCallback,
  primaryArtistBlurCallback,
  trash,
  addArtistCallback,
  removeInputCallback,
  validationDisplay,
  ValidationTextBox,
  prepopulated,
}) {
  const dispatch = useDispatch();

  const usedArtists = useSelector(
    (state) => state.distributionReducer.usedArtistFields
  );

  function handleChange(value, inputId, commentFieldClassName, type) {
    hackHideMenuIfNoOptions();
    inputChangeCallback(value, "#participants", "primary", inputId, "primary");
  }

  function handleAdd() {
    addArtistCallback();
  }

  const fieldValue = useSelector(
    (state) =>
      state.distributionReducer?.releaseForm?.["#participants"]?.primary?.[i]
        ?.primary
  );

  // only hides menu if the "custom no options message" is inside
  function hackHideMenuIfNoOptions() {
    setTimeout(() => {
      let a1 = document.querySelector(".ruslanSelect__menu");
      if (a1) a1.classList.remove("d-none");
    }, 0);
    setTimeout(() => {
      let a = document.querySelector(".nrInvisibleNoOptionsChild");
      if (a) a.parentElement.parentElement.classList.add("d-none");
    }, 0);
  }

  const customNoOptionsMessage = (
    <div className="nrInvisibleNoOptionsChild"></div>
  );

  return (
    <div
      className={
        "nrPrimaryArtistInputWrapper " +
        (i < artistsArray.length - 1
          ? " nrPrimaryArtistInputWrapperExtended"
          : "")
      }
      id={"artistWrapper" + i}
    >
      <div className={"inputUpperComment iuc2_" + i}>Primary artist*</div>
      <SelectComponent
        value={fieldValue}
        isMulti={false}
        inputGroupClassName={
          "nrInputGroup nrInputGroup-short " +
          (i < artistsArray.length - 1 ? " nrpigExtended" : "")
        }
        shortInput={true}
        placeholder="Select or type..."
        valuePaddingTop={true}
        placeholderTop="60%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        commentField={".iuc2_" + i}
        selectBlurCallback={primaryArtistBlurCallback}
        options={usedArtists}
        selectChangeCallback={(v) => handleChange(v, i, ".iuc2_" + i)}
        noMenuOnClick
        disableArrow
        customNoOptionsMessage={customNoOptionsMessage}
        // removing fields
        addon={i === 0 ? false : "right"}
        inputGroupTextClassName="nrRightAddon"
        img={trash}
        imgClass="nrAddonRightIcon"
        alt="remove artist"
        addonClickCallback={() => removeInputCallback(null, "primary", i)}
        databaseField="#participants"
        databaseKey={"primary"}
      />
      {i === 0 ? (
        <ValidationTextBox
          display={validationDisplay}
          className="nrValTextBox nrPrimaryValBox"
          for="primaryArtist"
        >
          Please provide the first artist of your release
        </ValidationTextBox>
      ) : (
        ""
      )}
      {i === artistsArray.length - 1 ? (
        <div className="nrPlus" onClick={handleAdd} id={i}>
          +
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default PrimaryArtistFieldComponent;
