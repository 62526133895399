import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";
import jwtDecode from "jwt-decode";

export async function getUserTransactions() {
  const jwtToken = getJWT();

  if (!jwtToken) throw "Unauthorized";

  const decodedJWT = jwtDecode(jwtToken);

  let url = `${global.config.API_URL}/api/user/${decodedJWT?.externalId}/transactions`;
  return axios.get(url, {
    headers: {
      Authorization: "Bearer " + getJWT(),
    },
  });
}
