import React, { memo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../assets/spinner.svg";

// Note: All the components in here adheres to the react-table library

export const Pagination = memo(
  ({
    pageSize,
    pageIndex,
    pageCount,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
  }) => {
    return (
      <>
        <div className="pagination text-white">
          <button
            className="text-white bg-transparent rounded mr-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{ opacity: canPreviousPage ? 1 : 0.3, color: "white" }}
            />
          </button>{" "}
          <span className="mr-2">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{" "}
          </span>
          <button
            className="mr-3 text-white bg-transparent rounded"
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{ opacity: canNextPage ? 1 : 0.3, color: "white" }}
            />
          </button>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="text-white bg-transparent border rounded"
          >
            {[10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize} className="bg-dark">
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  }
);

export const TableHeader = ({ headerGroups, className, handleSort }) => (
  <thead className={className}>
    {headerGroups.map((headerGroup) => (
      <tr {...headerGroup.getHeaderGroupProps()}>
        {handleSort ? (
          <>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column?.getSortByToggleProps?.())}
                onClick={handleSort ? () => handleSort(column) : null}
                className={"p-3"}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </>
        ) : (
          <>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column?.getSortByToggleProps?.())}
                className={"p-3"}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </>
        )}
      </tr>
    ))}
  </thead>
);

export const TableBody = ({
  getTableBodyProps,
  page,
  prepareRow,
  loading,
  numberOfColumns = 4,
}) => (
  <tbody {...getTableBodyProps()}>
    {loading && (
      <tr>
        <td colSpan={numberOfColumns} className="p-3 text-center">
          <div className="dashboard-overview-streaming-stats-spinner-wrapper">
            <img
              src={Spinner}
              alt="loading"
              className="dashboard-overview-streaming-stats-spinner"
            />
          </div>
        </td>
      </tr>
    )}
    {page?.length && !loading
      ? page.map((row, i) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    title={cell.value}
                    {...cell.getCellProps()}
                    className={"p-3"}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })
      : !loading && (
          <tr>
            <td colSpan={numberOfColumns} className="p-3 text-center">
              No data
            </td>
          </tr>
        )}
  </tbody>
);
