import React, { useState } from "react";
import InputComponent from "../../../../components/form/InputComponent";
import ButtonComponent from "../../../../components/form/ButtonComponent";
import { updateTrack } from "../../../../services/distribution";
import { Formik } from "formik";
import { Spinner } from "../../../Airplay/Airplay";
import { useMutation, useQueryClient } from "react-query";
import { getJWT } from "../../../../components/utils";
import Toast from "../../../../components/Toast";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

function TrackMeta({ userEmail, trackIndex, track = {}, releaseId, onUpdate }) {
  const queryClient = useQueryClient();
  const [showToast, setShowToast] = useState(initialToastState);

  // Release Mutation handler
  const trackISRCMutation = useMutation(updateTrack);

  const handleSubmit = (values, formActions) => {
    const payload = {
      userEmail,
      releaseId,
      isrc: values.isrc,
      trackIndex,
      title: values.title,
      version: values.version,
    };

    // Using the mutation handler to update release data
    trackISRCMutation.mutate(payload, {
      onSuccess: () => {
        setShowToast({
          open: true,
          message: "Track has been updated successfully",
          type: "success",
        });

        // Delaying loading as the spinner flashes in ui due to fast updation
        setTimeout(() => {
          queryClient.setQueryData(["adminAllReleases", getJWT()], (old) => ({
            ...old,
            // Updating the cached version
            data: old.data.map((release) => {
              if (release.release.releaseId === releaseId) {
                const updatedState = {
                  ...release,
                  release: {
                    ...release.release,
                    tracks: release.release.tracks.map((track, idx) => {
                      if (idx === trackIndex) {
                        return {
                          ...track,
                          isrc: values.isrc,
                          form: {
                            ...track.form,
                            ["#track_title"]: values.title,
                            ["#remix_or_version"]: values.version,
                          },
                        };
                      }
                      return track;
                    }),
                  },
                };

                onUpdate({ isOpen: true, release: updatedState });

                return updatedState;
              }
              return release;
            }),
          }));
        }, 1250);
      },
      onError: (e) => {
        formActions.setSubmitting(false);
        formActions.resetForm();

        setShowToast({
          open: true,
          message: e.response.data.message
            ? e.response.data.message
            : "An unexpected error occured. Please try again.",
          type: "danger",
        });
      },
    });
  };

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />
      <Formik
        enableReinitialize
        initialValues={{
          isrc: track.isrc
            ? track.isrc
            : track.form["#isrc"] === "auto"
            ? ""
            : track.form["#isrc"],

          title: track.form["#track_title"],
          version: track.form["#remix_or_version"] || "",
        }}
        initialStatus={{ edit: false }}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            values: { isrc, title, version },
            status: { edit },
            dirty,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            resetForm,
            setStatus,
          } = props;

          return (
            <>
              <div className="row mx-0 pb-2 pr-3 justify-content-end">
                <div
                  className={
                    "text-white text-decoration-underline font-family-Inter " +
                    (edit ? "opacity-05" : "cursor-pointer")
                  }
                  onClick={() => setStatus({ edit: true })}
                >
                  Edit
                </div>
              </div>

              <div className="row pb-4 justify-content-start ">
                {/* Track Title */}
                <div className="position-relative py-2 py-md-0 col-12 mb-2">
                  <div
                    className={
                      (title ? "" : "d-none") + " inputUpperComment utiuc3_isrc"
                    }
                  >
                    Title*
                  </div>
                  <InputComponent
                    value={title}
                    disabled={!edit}
                    commentField={".utiuc3_isrc"}
                    placeholder="Title*"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (title ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#track_title"
                    addon={false}
                    changeCallback={(value) => setFieldValue("title", value)}
                    inputBlurCallback={() => setFieldTouched("title", true)}
                  />
                </div>

                {/* Track version */}

                <div className="position-relative py-2 py-md-0 col-12 mb-2">
                  <div
                    className={
                      (version ? "" : "d-none") +
                      " inputUpperComment utiuc3_isrc"
                    }
                  >
                    Version
                  </div>
                  <InputComponent
                    value={version}
                    disabled={!edit}
                    commentField={".utiuc3_isrc"}
                    placeholder="Version"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (version ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#remix_or_version"
                    maxlength={12}
                    addon={false}
                    changeCallback={(value) => setFieldValue("version", value)}
                    inputBlurCallback={() => setFieldTouched("version", true)}
                  />
                </div>

                {/* isrc */}
                <div className="position-relative py-2 py-md-0 col-12">
                  <div
                    className={
                      (isrc ? "" : "d-none") + " inputUpperComment utiuc3_isrc"
                    }
                  >
                    ISRC
                  </div>
                  <InputComponent
                    value={isrc}
                    disabled={!edit}
                    commentField={".utiuc3_isrc"}
                    placeholder="ISRC"
                    inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup"
                    inputClassName={
                      "utepInput" +
                      (isrc ? " interMediumInput interMediumPaddingTop" : "")
                    }
                    paddingTopOnInput={true}
                    databaseField="#isrc"
                    maxlength={12}
                    addon={false}
                    changeCallback={(value) => setFieldValue("isrc", value)}
                    inputBlurCallback={() => setFieldTouched("isrc", true)}
                  />
                </div>
              </div>

              {/* cancel/save buttons */}

              {edit && (
                <div className="row pb-2 justify-content-end">
                  <div>
                    <ButtonComponent
                      onClick={() => {
                        resetForm();
                        setStatus({ edit: false });
                      }}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton bg-transparent textLink w-100 h-75"
                    >
                      Cancel
                    </ButtonComponent>
                  </div>
                  <div>
                    <ButtonComponent
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                      buttonWrapperClassName="pr-3"
                      className="playtreksButton  w-100 h-75"
                    >
                      {isSubmitting ? (
                        <>
                          <span className="mr-2">Saving... </span>
                          <span>
                            <Spinner />
                          </span>
                        </>
                      ) : (
                        "Save"
                      )}
                    </ButtonComponent>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default TrackMeta;
