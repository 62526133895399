import React from "react";

function RuslanCheckbox(props) {
  let checked = props.checked ? "ruslanCheckbox_checked" : "";

  return (
    <div
      className={props.className + " ruslanCheckbox " + checked}
      id={props.id}
      onClick={props.onClick}
    ></div>
  );
}

export default RuslanCheckbox;
