import { getUserTransactions } from "../../routes/transactions";

export const REDUX_SET_USER_TRANSACTIONS = "REDUX_SET_USER_TRANSACTIONS";

export function setUserTransactions(transactions) {
  return async (dispatch, getState) => {
    try {
      const transactions = await getUserTransactions();
      dispatch({
        type: REDUX_SET_USER_TRANSACTIONS,
        transactions: transactions.data,
      });
    } catch (e) {
      console.error(e);
    }
  };
}
