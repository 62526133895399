/**
 * Query param parser
 *
 * @param {object} queryParams Object containing query params
 * @returns {string} Parsed query params string
 */
export const parseQueryParams = (queryParams = {}) => {
  queryParams = Object.entries(queryParams).filter((param) => param[1]);

  return "?" + new URLSearchParams(queryParams).toString();
};

/**
 * Helper function to cancel a request
 *
 * @param {object} cancelToken Axios cancel token generated for the request
 */
export const cancelRequest = (cancelToken = undefined) => {
  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Request cancelled");
  }
};
