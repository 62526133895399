import React from "react";
import { components } from "react-select";
import { artistsToString } from "../utils.js";

const Option = (props) => {
  if (props.data.type === "album" || props.data.type === "track") {
    var artists = artistsToString(props.data.artists);
  } else {
    artists = null;
  }
  return (
    <components.Option {...props}>
      {props.data.label}
      <span className="text-muted">
        <small> {artists ? "by " + artists : ""} </small>
      </span>
    </components.Option>
  );
};

export default Option;
