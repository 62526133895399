import React, { useState, useEffect } from "react";

import InputComponent from "../../form/InputComponent";
import SpotifyArtistSearchDropdown from "./SpotifyArtistSearchDropdown";

import searchIcon from "../../../assets/icons/search.svg";

import { genericSearch } from "../../../routes/search";

function SpotifyPagePicker({
  artistIdCallback,
  isErrorCallback,
  style,
  prepopulatedArtistImage,
  prepopulatedArtistName,
}) {
  const [picked, setPicked] = useState(false);
  const [focus, setFocus] = useState(false);
  const [artists, setArtists] = useState({});
  const [artistImage, setArtistImage] = useState("");

  useEffect(() => {
    setArtistImage(prepopulatedArtistImage);
    setPicked(prepopulatedArtistName);
  }, [prepopulatedArtistImage, prepopulatedArtistName]);

  function handleFocus() {
    if (artists[0]) {
      setFocus(true);
    }
  }

  function handleBlur() {
    setFocus(false);
  }

  function handleItemMouseDown(artistName, artistImage, artistId) {
    setPicked(artistName);
    setArtistImage(artistImage);
    artistIdCallback(artistId);
    document.querySelector(".obSpotifyInput").value = artistName;
  }

  async function changeCallback(v) {
    genericSearch(v, global.config.textSearchLimit, "artist")
      .then((r) => {
        if (r?.data?.artists?.items) {
          setArtists(r.data.artists.items);
          setFocus(true);
        } else {
          setFocus(false);
        }
        isErrorCallback(false);
      })
      .catch((e) => isErrorCallback(true));
  }
  return (
    <div style={style}>
      <InputComponent
        img={searchIcon}
        alt="search"
        placeholder="Search for artist or band"
        inputGroupTextClassName="spotifyInputGroupText"
        inputClassName="obSpotifyInput"
        inputGroupClassName="obSpotifyInputGroup"
        imgClass="whiteIconAddon inputIcon"
        changeCallback={changeCallback}
        onFocus={handleFocus}
        inputBlurCallback={handleBlur}
      />
      {focus ? (
        <SpotifyArtistSearchDropdown
          handleItemMouseDown={handleItemMouseDown}
          artists={artists}
        />
      ) : (
        ""
      )}
      {!picked ? (
        <></>
      ) : (
        <div className="obSpotifyConnectArtistImageOuterWrapper">
          <div className="obSpotifyConnectArtistImageWrapper">
            <img
              alt="user"
              src={artistImage}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          <br />
          <div className="obSpotifyProfileName">{picked}</div>
        </div>
      )}
    </div>
  );
}

export default SpotifyPagePicker;
