import React, { useState } from "react";
import Portal from "../../../../Portal";
import BackupCodesIcon from "../../../../../assets/BackupCodesIcon.png";
import ButtonComponent from "../../../../form/ButtonComponent";
import { getBackupCodes } from "../../../../../services/auth";
import { useQuery } from "react-query";
import { formatDateDistance } from "../../../../utils";
import DeleteBackupCodesModal from "./DeleteBackupCodesModal";
import RegenerateBackupCodesModal from "./RegenerateBackupCodesModal";

const BackupCodes = ({ setToast, isTwoFAEnabled }) => {
  const [openDeleteBackupCodesModal, setIsOpenDeleteBackupCodesModal] =
    useState(false);
  const [openRegenerateConfirmationModal, setOpenRegenerateConfirmationModal] =
    useState(false);

  const { data: { data: backupCodes = {} } = {}, refetch: refetchCodes } =
    useQuery("backupCodes", getBackupCodes, { retry: false });

  const handleOnModalClose = () => {
    setIsOpenDeleteBackupCodesModal(false);
    setOpenRegenerateConfirmationModal(false);
  };

  const remainingCodes = backupCodes?.count || 0;

  return (
    <div className="VerifyPhoneWrapper">
      <div className="row w-100 mx-0">
        <img src={BackupCodesIcon} alt="texticon" width={30} height={30} />
        <div className="col">
          <span className="">
            Backup codes{" "}
            <span className="infoText text-muted">
              {backupCodes?.createdAt &&
                `(created ${formatDateDistance(backupCodes?.createdAt)})`}
            </span>
          </span>

          <div className="text-muted">
            <p className="mb-3">
              These one-time passcodes allow you to complete{" "}
              <b className="text-white">two-factor authentication</b>, when you
              are unable to use or lost acess to your device. A code once used
              cannot be reused!
            </p>
            {remainingCodes} single-use codes are active at this time, but you
            can generate more as needed.
          </div>

          <div className="row justify-content-start mx-0">
            <ButtonComponent
              buttonWrapperClassName="mr-3"
              className="playtreksButton w-100 h-75 mt-4"
              onClick={() => setOpenRegenerateConfirmationModal(true)}
              disabled={!isTwoFAEnabled}
            >
              {remainingCodes > 0 ? "Regenerate codes" : "Generate codes"}
            </ButtonComponent>

            {remainingCodes > 0 && (
              <ButtonComponent
                className="playtreksButton bg-secondary w-100 h-75 mt-4"
                onClick={() => setIsOpenDeleteBackupCodesModal(true)}
                disabled={!isTwoFAEnabled}
              >
                {"Delete codes"}
              </ButtonComponent>
            )}
          </div>

          <Portal selector="body">
            {openDeleteBackupCodesModal && (
              <DeleteBackupCodesModal
                refetchCodes={refetchCodes}
                setToast={setToast}
                onCloseRequest={handleOnModalClose}
              />
            )}
            {openRegenerateConfirmationModal && (
              <RegenerateBackupCodesModal
                refetchCodes={refetchCodes}
                setToast={setToast}
                remainingCodes={remainingCodes}
                onCloseRequest={handleOnModalClose}
              />
            )}
          </Portal>
        </div>
      </div>
    </div>
  );
};

export default BackupCodes;
