import React from "react";
import X from "../../../components/distribution/assets/X.svg";
import { VersionFieldRestrictedWords } from "../../../shared/VersionFieldValidation";

const sortedVersions = VersionFieldRestrictedWords.sort((a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
});

const VersionErrorModal = ({ onCloseRequest }) => {
  return (
    <div className="playtreksModal-overlay">
      <div className="playtreksModal-modal row overflow-auto">
        <img
          src={X}
          width={16}
          height={16}
          alt="close"
          className="playtreksModal-modal-close-absolute"
          onClick={onCloseRequest}
        />

        <div className="row">
          {sortedVersions.map((restrictedWords) => (
            <div className="col-4 col-sm-3">
              <p>{restrictedWords}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VersionErrorModal;
