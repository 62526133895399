import React from "react";
import { Link } from "react-router-dom";
import ReleaseCardComponent from "../../../components/distribution/components/ReleaseCardComponent";
import { Pagination } from "../../../components/Table";
import Spinner from "../../../assets/spinner.svg";

const UserGridView = ({ tableInstance, loading }) => {
  const {
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  return (
    <>
      {loading && (
        <div
          className="dashboard-overview-streaming-stats-spinner-wrapper py-3"
          style={{ zIndex: 100 }}
        >
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      )}
      {page.length > 0
        ? page.map((row, index) => {
            prepareRow(row);
            const release = row.original;

            if (release.state === "draft" && !release.tracks) {
              var draftType = "draft - uploadTracks";
            }
            return (
              <Link
                to={{
                  pathname: `/artistDashboard/myReleases/${release["releaseId"]}`,
                  release,
                }}
                className="release-card-link p-3"
              >
                <ReleaseCardComponent
                  key={index}
                  className={"dspRcc" + (index + 1)}
                  state={draftType || release.state}
                  release={release}
                  releaseId={release["releaseId"]}
                  // disableCoverArt
                />
              </Link>
            );
          })
        : !loading && <p class="text-white">No results found</p>}
      {/* Pagination */}
      <div
        className="row justify-content-center  position-sticky  w-100"
        style={{ bottom: 0 }}
      >
        <div
          className="row h-100 w-100 w-md-50 rounded-top justify-content-center pt-3 pb-5 pb-lg-3"
          style={{ background: "rgb(34 38 44)" }}
        >
          <Pagination
            pageSize={pageSize}
            pageIndex={pageIndex}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </>
  );
};

export default UserGridView;
