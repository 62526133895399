import React from "react";
import UserProfile from "../../UserProfile";
import UserIcon from "../../../../assets/icons/user.svg";

function ProfileHeader({ SaveComponent, label = "Your Profile" }) {
  return (
    <section className="dashboard-profile-header">
      <div className="dashboard-profile-header-right">
        <UserProfile name="artist name" avatar={UserIcon} />
        {/* <MobileMenuBurger /> */}
      </div>
      <div className="dashboard-profile-header-left-title">{label}</div>
      {SaveComponent && <SaveComponent />}
    </section>
  );
}

export default ProfileHeader;
