import React, { useState } from "react";
import { Link } from "react-router-dom";

// REDUX
import { useSelector } from "react-redux";

// packages
import { get as sliceObject } from "lodash";

// compo
import StatsCard from "../StatsCard";
import StatsCardIcon from "../StatsCardIcon";
import HorizontalScrollButtonGroup from "../HorizontalScrollButtonGroup";
import ButtonComponent from "../../form/ButtonComponent";

// cards config
import cards from "./streamingStatsCardsConfig";
import { sortStatsCards } from "../../utils";

// assets
import Spinner from "../../../assets/spinner.svg";

const HORIZONTAL_SCROLL_SIZE = 200;

const USER = "user";

function StreamingStatsCardBox({
  entityStats,
  entityStatsDelta,
  noFooter,
  handlePlusIconClick,
}) {
  const [streamingStatsBtn, setStreamingStatsBtn] = useState({
    left: true,
    right: false,
    scrollLeft: 1,
  });

  const reduxUserData = useSelector(
    (state) => state.dashboardReducer?.userData
  );
  const reduxMainEntity = useSelector(
    (state) => state.dashboardReducer?.mainEntity
  );

  const isMainEntityUser = reduxMainEntity?.type === USER;

  const spotifyPageConnected = isMainEntityUser
    ? reduxUserData?.service_profiles?.selectedSpotifyID?.length > 0
    : true;
  const appleConnected = true; // TODO:

  const platformConnected = {
    spotify: true, // spotify data does not depend on connected platform, hence always true
    apple: appleConnected,
  };
  const pageConnected = {
    spotify: spotifyPageConnected,
    apple: appleConnected,
  };
  const comingSoon = {
    spotify: false,
    apple: false,
  };

  function handleSocialClick() {}

  function handleStreamingStatScroll(element, direction) {
    const scrollVal =
      direction === "left"
        ? element.scrollLeft - HORIZONTAL_SCROLL_SIZE
        : element.scrollLeft + HORIZONTAL_SCROLL_SIZE;
    setStreamingStatsBtn((state) => ({
      left: element.scrollLeft === 0,
      right: state.scrollLeft > 0 && state.scrollLeft === scrollVal,
      scrollLeft: scrollVal,
    }));
    direction === "left"
      ? (element.scrollLeft -= HORIZONTAL_SCROLL_SIZE)
      : (element.scrollLeft += HORIZONTAL_SCROLL_SIZE);
  }
  return (
    <div>
      <div className="dashboard-overview-streaming-stats-header">
        <span className="dashboard-overview-streaming-stats-header-left">
          <label>streaming stats</label>
          <span
            className="dashboard-overview-streaming-stats-header-emoji"
            role="img"
            aria-label={"chart with upwards trend"}
          >
            {"📈"}
          </span>
        </span>
        <div className="dashboard-overview-streaming-stats-header-scroll">
          <HorizontalScrollButtonGroup
            // disableLeft={streamingStatsBtn["left"]}
            // disableRight={streamingStatsBtn["right"]} // no disabling arrows (problematic)
            handleScroll={(direction) =>
              handleStreamingStatScroll(
                document.querySelector(
                  ".dashboard-overview-streaming-stats-cards"
                ),
                direction
              )
            }
          />
        </div>
      </div>
      {!entityStats && !entityStatsDelta ? (
        <div className="dashboard-overview-streaming-stats-spinner-wrapper">
          <img
            src={Spinner}
            alt="loading"
            className="dashboard-overview-streaming-stats-spinner"
          />
        </div>
      ) : (
        <div className="dashboard-overview-streaming-stats-cards">
          {sortStatsCards(cards, platformConnected, pageConnected).map(
            (card) => (
              <StatsCard
                className="dashboard-overview-streaming-stats-cards-card"
                name={card.name}
                platform={card.platform}
                objectPath={sliceObject(entityStats, card?.objectPath)}
                objectPathDelta={sliceObject(
                  entityStatsDelta,
                  card?.objectPath
                )}
                connected={platformConnected[card.platform]}
                pageConnected={pageConnected[card.platform]}
                comingSoon={
                  "comingSoon" in card
                    ? card.comingSoon
                    : comingSoon[card.platform]
                }
                handlePlusIconClick={handlePlusIconClick}
              >
                <StatsCardIcon
                  name={card.name}
                  icon={card.icon}
                  handleClick={handleSocialClick}
                />
              </StatsCard>
            )
          )}
        </div>
      )}
      {/* TODO: BRING BACK WHEN DATA AVAILABLE
      {reduxMainEntity?.type === "track" && (
        <div
          className={`dashboard-overview-streaming-stats-chart gbw${0} col-xl col-lg`}
        >
          <label>streaming statistics over time</label>
          <div className={`graphWrapper gw${0}`}>
            <StatisticsChart data={streamingStatsChartData} />
          </div>
        </div>
      )} */}
      <div
        className={
          noFooter
            ? "d-none"
            : "dashboard-overview-streaming-stats-more-details"
        }
      >
        <span className="dashboard-overview-streaming-stats-more-details-info">
          <label>
            compare yourself to other artists and analyze your growth
          </label>
        </span>
        <Link to={"/artistDashboard/streamingStats"}>
          <ButtonComponent className="dashboard-overview-streaming-stats-more-details-button">
            See more details
          </ButtonComponent>
        </Link>
      </div>
    </div>
  );
}

export default StreamingStatsCardBox;
