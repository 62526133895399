import { getJWT } from "../components/utils";
import axios from "../lib/axios_retry_wrapper";

export function facebookRedirectCall(code, state, storedState) {
  return externalRedirectCall(code, state, storedState, "facebook");
}

export function spotifyRedirectCall(code, state, storedState) {
  return externalRedirectCall(code, state, storedState, "spotify");
}

export function facebookLoginCall() {
  return externalLoginCall("facebook");
}

export function spotifyLoginCall() {
  return externalLoginCall("spotify");
}

export function googleLoginCall() {
  return externalLoginCall("google");
}

export function googleRedirectCall(code, state, storedState) {
  return externalRedirectCall(code, state, storedState, "google");
}

export function externalLoginCall(service) {
  if (service === "twitter") {
    return axios.get(
      `${global.config.API_URL}/api/externalLogin/auth/twitter`,
      {
        headers: {
          Authorization: "Bearer " + getJWT(),
        },
        params: {
          service: service,
        },
      }
    );
  }

  let url = `${global.config.API_URL}/api/externalLogin/login`;
  return axios({
    method: "get",
    url: url,
    params: {
      service: service,
    },
  });
}

export function externalRedirectCall(
  code,
  state,
  storedState,
  oauth_token,
  oauth_verifier,
  service
) {
  let url = `${global.config.API_URL}/api/externalLogin/redirectCallback`;

  return axios({
    method: "get",
    url: url,
    params: {
      service: service,
      code: code,
      state: state,
      storedState: storedState,
      oauth_token: oauth_token,
      oauth_verifier: oauth_verifier,
    },
  });
}

export function externalRedirectCallProtected(
  code,
  state,
  storedState,
  oauth_token,
  oauth_verifier,
  service,
  jwtToken
) {
  let url = `${global.config.API_URL}/api/externalLogin/redirectCallbackProtected`;

  return axios({
    method: "get",
    url: url,
    params: {
      service: service,
      code: code,
      state: state,
      storedState: storedState,
      oauth_token: oauth_token,
      oauth_verifier: oauth_verifier,
    },
    headers: {
      Authorization: "Bearer " + jwtToken,
    },
  });
}
