import Countries from "../../shared/countries.json";

export const getCountriesOptions = () =>
  Object.entries(Countries).reduce((accumulator, current) => {
    accumulator[current[1]] = {
      label: current[0],
      value: current[1],
    };
    return accumulator;
  }, {});
