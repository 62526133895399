import React, { useEffect, useState } from "react";

// REDU
import { useSelector } from "react-redux";

import spinner from "../../../assets/spinner.svg";

import { getPaymentMethod } from "../../../routes/payments";
import { performPayment } from "../../../routes/payments";

import { useLocation } from "react-router-dom";

import X from "../../distribution/assets/X.svg";
import { getJWT } from "../../utils";

const BUNDLE = 0;
const DATA_DASHBOARD = 1;
const DISTRIBUTION = 2;

const INITIAL = "initial";
const IN_PROGRESS = "in_progress";
const SUCCESS = "success";
const ERROR = "error";
const ERROR_403 = "error_403";

function CancelSubscriptionModal({
  setCancelSubscriptionModalState,
  cancelSubscriptionModalState,
  redirectTo = window.location.pathname,
}) {
  const jwtToken = getJWT();

  const [status, setStatus] = useState(INITIAL);

  const reduxPaymentProcessor = useSelector(
    (state) => state.subscriptionReducer?.subscription?.processor
  );
  const usedArtistSlots = useSelector(
    (state) => state.distributionReducer?.usedArtistSlots
  );

  function handleCancel() {
    // TODO: HANDLE PAYMENT METHOD
    setStatus(IN_PROGRESS);
    performPayment(
      cancelSubscriptionModalState.product,
      undefined,
      jwtToken,
      reduxPaymentProcessor
    )
      .then((r) => {
        setStatus(SUCCESS);
        setTimeout(() => window.location.assign(redirectTo), 2000);
      })
      .catch((e) => {
        if (e.response.status === 403) {
          setStatus(ERROR_403);
        } else {
          setStatus(ERROR);
        }
      });
  }

  function handleClose() {
    setStatus(INITIAL);
    setCancelSubscriptionModalState({
      ...cancelSubscriptionModalState,
      open: false,
    });
  }

  return (
    <div className="playtreksModal-overlay">
      {status === INITIAL ? (
        <div className="playtreksModal-modal wppaymentModalState-modal">
          <div className="playtreksModal-cancelSubscription">
            <img
              src={X}
              alt="close"
              className="playtreksModal-modal-close-absolute"
              onClick={handleClose}
            />
            <div className="playtreksModal-modal-header">
              Warning! &nbsp;
              <span aria-label="warning" alt="warning">
                ⚠️
              </span>
            </div>
            <br />
            You are about to cancel your subscription.
            <br />
            <br />
            <span
              className="playtreksButton playtreksModal-modal-btn1"
              onClick={handleClose}
            >
              Back
            </span>
            <span
              className="playtreksButton playtreksModal-modal-btn2 playtreksModal-modal-btnInactive"
              onClick={handleCancel}
            >
              Proceed
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      {status === IN_PROGRESS ? (
        <div className="playtreksModal-modal wppaymentModalState-modal">
          <span>
            Cancelling your subscription... &nbsp;
            <img
              src={spinner}
              alt="loading"
              className="performPayment-btn-spinner"
            />
          </span>
        </div>
      ) : (
        ""
      )}
      {status === SUCCESS ? (
        <div className="playtreksModal-modal wppaymentModalState-modal">
          <span>
            You have successfully cancelled your subscription.
            <br />
            <br />
            <small>Redirecting...</small>
            <br />
            <img
              src={spinner}
              alt="loading"
              className="performPayment-btn-spinner"
            />
          </span>
        </div>
      ) : (
        ""
      )}
      {status === ERROR ? (
        <div className="playtreksModal-modal wppaymentModalState-modal">
          <img
            src={X}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={handleClose}
          />
          <span>
            There has been an error, please try again{" "}
            <span alt="error" aria-label="error" className="nrTick">
              ❌
            </span>
            <br />
            <br />
            <div
              className="playtreksUnderlinePrimaryText performPayment-error-retry"
              onClick={() => setStatus(INITIAL)}
            >
              Retry
            </div>
          </span>
        </div>
      ) : (
        ""
      )}
      {status === ERROR_403 ? (
        <div className="playtreksModal-modal wppaymentModalState-modal">
          <img
            src={X}
            alt="close"
            className="playtreksModal-modal-close-absolute"
            onClick={handleClose}
          />
          <div>
            <span
              alt="forbidden"
              aria-label="forbidden"
              style={{ fontSize: "3rem" }}
            >
              ⛔
            </span>
            <br /> <br />
            You are currently using {usedArtistSlots.length} artist slots within
            PlayTreks. Therefore, you cannot downgrade to this plan. Please
            choose a plan with at least {usedArtistSlots.length} artist slots.
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default CancelSubscriptionModal;
