export const periods = [
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba2",
    },
    startDate: {
      $date: "2021-02-28T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-03-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.078Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.078Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba3",
    },
    startDate: {
      $date: "2021-03-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-04-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba4",
    },
    startDate: {
      $date: "2021-04-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-05-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba5",
    },
    startDate: {
      $date: "2021-05-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-06-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba6",
    },
    startDate: {
      $date: "2021-06-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-07-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba7",
    },
    startDate: {
      $date: "2021-07-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-08-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba8",
    },
    startDate: {
      $date: "2021-08-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-09-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fba9",
    },
    startDate: {
      $date: "2021-09-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-10-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.079Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fbaa",
    },
    startDate: {
      $date: "2021-10-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-11-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.080Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.080Z",
    },
  },
  {
    _id: {
      $oid: "64802d75dec6ce6b7873fbab",
    },
    startDate: {
      $date: "2021-11-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2021-12-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:10:45.080Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:10:45.080Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2eef",
    },
    startDate: {
      $date: "2021-12-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-01-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef0",
    },
    startDate: {
      $date: "2022-01-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-02-27T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef1",
    },
    startDate: {
      $date: "2022-02-28T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-03-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.768Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef2",
    },
    startDate: {
      $date: "2022-03-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-04-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef3",
    },
    startDate: {
      $date: "2022-04-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-05-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef4",
    },
    startDate: {
      $date: "2022-05-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-06-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef5",
    },
    startDate: {
      $date: "2022-06-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-07-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef6",
    },
    startDate: {
      $date: "2022-07-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-08-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef7",
    },
    startDate: {
      $date: "2022-08-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-09-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef8",
    },
    startDate: {
      $date: "2022-09-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-10-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2ef9",
    },
    startDate: {
      $date: "2022-10-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-11-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802d95a3063651e08c2efa",
    },
    startDate: {
      $date: "2022-11-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2022-12-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:17.769Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1709",
    },
    startDate: {
      $date: "2022-12-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-01-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170a",
    },
    startDate: {
      $date: "2023-01-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-02-27T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170b",
    },
    startDate: {
      $date: "2023-02-28T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-03-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.364Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170c",
    },
    startDate: {
      $date: "2023-03-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-04-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170d",
    },
    startDate: {
      $date: "2023-04-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-05-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170e",
    },
    startDate: {
      $date: "2023-05-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-06-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde170f",
    },
    startDate: {
      $date: "2023-06-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-07-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1710",
    },
    startDate: {
      $date: "2023-07-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-08-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.365Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1711",
    },
    startDate: {
      $date: "2023-08-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-09-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1712",
    },
    startDate: {
      $date: "2023-09-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-10-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1713",
    },
    startDate: {
      $date: "2023-10-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-11-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
  },
  {
    _id: {
      $oid: "64802daa94c7ed4e0cde1714",
    },
    startDate: {
      $date: "2023-11-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2023-12-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:11:38.366Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec3f",
    },
    startDate: {
      $date: "2023-12-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-01-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.668Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.668Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec40",
    },
    startDate: {
      $date: "2024-01-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-02-28T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec41",
    },
    startDate: {
      $date: "2024-02-29T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-03-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec42",
    },
    startDate: {
      $date: "2024-03-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-04-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec43",
    },
    startDate: {
      $date: "2024-04-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-05-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec44",
    },
    startDate: {
      $date: "2024-05-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-06-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec45",
    },
    startDate: {
      $date: "2024-06-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-07-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.669Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec46",
    },
    startDate: {
      $date: "2024-07-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-08-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec47",
    },
    startDate: {
      $date: "2024-08-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-09-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec48",
    },
    startDate: {
      $date: "2024-09-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-10-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec49",
    },
    startDate: {
      $date: "2024-10-31T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-11-29T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
  },
  {
    _id: {
      $oid: "64802e4580a3df18d80dec4a",
    },
    startDate: {
      $date: "2024-11-30T18:30:00.000Z",
    },
    endDate: {
      $date: "2024-12-30T18:30:00.000Z",
    },
    status: "open",
    paymentStatus: "awaiting_payment",
    __v: 0,
    createdAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
    updatedAt: {
      $date: "2023-06-07T07:14:13.670Z",
    },
  },
];
