import React, { memo, useMemo } from "react";
import InputComponent from "../../../components/form/InputComponent";
import { ErrorMessage } from "formik";
import SelectComponent from "../../form/SelectComponent";

const RightHolder = memo(
  ({
    inputChangeCallback,
    inputBlurCallback,
    rightholder,
    rightholders,
    index,
    contactsOptions,
    customMenuAction,
    reduxUserData,
    disabled,
    isLabelPlan,
  }) => {
    const handleRightHolderChange = (
      value,
      databaseField,
      commentField,
      databaseKey,
      type
    ) => {
      if (type.action === "input-change") {
        return;
      }

      if (value) {
        const selectedContact = contactsOptions[value];
        inputChangeCallback(
          `rightholders.${index}.name`,
          selectedContact.label
        );
        inputChangeCallback(
          `rightholders.${index}.contact`,
          selectedContact._id
        );
      }
    };

    const handleShareChange = (name) => (value) => {
      inputChangeCallback(`rightholders.${index}.${name}`, Number(value));
    };

    const handleInputBlur = (name) => () => {
      inputBlurCallback(`rightholders.${index}.${name}`, true);
    };

    const contacts = useMemo(
      () =>
        Object.values(contactsOptions)?.filter(
          (contact) =>
            !rightholders.map((contact) => contact.name).includes(contact.label)
        ),
      [contactsOptions, rightholders]
    );

    return (
      <div className="row mx-0 px-0">
        <div
          className={` py-2 py-md-0 mb-3 text-white ${
            (disabled ? "opacity-50" : "", isLabelPlan ? "col-6" : "col-8")
          }`}
        >
          <div
            className={
              (rightholder?.name ? "" : "d-none") +
              " inputUpperComment utiuc1_" +
              index
            }
          >
            Contact*
          </div>

          <SelectComponent
            value={
              rightholder?.name
                ? rightholder?.name
                : (
                    (reduxUserData?.firstName || "") +
                    " " +
                    (reduxUserData?.lastName || "")
                  ).trim()
            }
            disabled={disabled}
            extendOptionWidth
            valuePaddingTop={true}
            placeholderTop="50%"
            placeholderFont="Inter-Medium"
            placeholderColor="white"
            placeholderOpacity="1"
            placeholder="Select Contact*"
            options={contacts}
            customNoOptionsMessage={<p className="ml-2">No contacts</p>}
            selectChangeCallback={handleRightHolderChange}
            selectBlurCallback={() => {}} // leave empty
            customMenuAction={customMenuAction(index)}
          />

          <ErrorMessage
            name={`rightholders.${index}.name`}
            component="div"
            className="row text-danger mx-0 fs-14 mt-2"
          />
        </div>
        <div className="py-2 col-3 py-md-0 mb-3">
          <div
            className={
              (rightholder?.royaltiesShare ? "" : "d-none") +
              " inputUpperComment utiuc3_" +
              index
            }
          >
            Share
          </div>
          <div>
            <div className="position-relative">
              <InputComponent
                value={rightholder?.royaltiesShare}
                inputType="number"
                commentField={".utiuc3_" + index}
                placeholder="Share"
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput " +
                  (rightholder?.royaltiesShare
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                paddingTopOnInput={true}
                changeCallback={handleShareChange("royaltiesShare")}
                inputBlurCallback={handleInputBlur("royaltiesShare")}
                addon={false}
                maxlength={3}
              />
              {rightholder?.royaltiesShare >= 0 ? (
                <div
                  className="position-absolute utepPercent-2"
                  style={{ right: 12, bottom: 10, zIndex: 100 }}
                >
                  %
                </div>
              ) : (
                ""
              )}
            </div>
            <ErrorMessage
              name={`rightholders.${index}.royaltiesShare`}
              component="div"
              className="row text-danger mx-0 fs-14 mt-2"
            />
          </div>
        </div>
        {isLabelPlan && (
          <div className="py-2 col-3 py-md-0 mb-3">
            <div
              className={
                (rightholder?.expenseShare ? "" : "d-none") +
                " inputUpperComment utiuc3_" +
                index
              }
            >
              Share
            </div>
            <div>
              <div className="position-relative">
                <InputComponent
                  value={rightholder?.expenseShare}
                  inputType="number"
                  commentField={".utiuc3_" + index}
                  placeholder="Share"
                  inputGroupClassName="nrInputGroup "
                  inputClassName={
                    " nrInput utepInput " +
                    (rightholder?.expenseShare
                      ? " interMediumInput interMediumPaddingTop"
                      : "")
                  }
                  paddingTopOnInput={true}
                  changeCallback={handleShareChange("expenseShare")}
                  inputBlurCallback={handleInputBlur("expenseShare")}
                  addon={false}
                  maxlength={3}
                />
                {rightholder?.expenseShare >= 0 ? (
                  <div
                    className="position-absolute utepPercent-2"
                    style={{ right: 12, bottom: 10, zIndex: 100 }}
                  >
                    %
                  </div>
                ) : (
                  ""
                )}
              </div>
              <ErrorMessage
                name={`rightholders.${index}.expenseShare`}
                component="div"
                className="row text-danger mx-0 fs-14 mt-2"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default RightHolder;
