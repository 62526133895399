import React from "react";
import Highcharts, { reduce } from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { getColorScheme, PLATFORM } from "../utils";
import moment from "moment";

function PlaylistPerformanceLineChart({ totalTracks, platform, data }) {
  let innerWidth = window.innerWidth;

  let chartData = data.map((innerHit) => {
    const trackInfo =
      platform === PLATFORM.SPOTIFY ? innerHit._source.track : innerHit._source;
    let historyData = trackInfo.history.map((el) => [
      moment(el.timestamp.slice(0, 10)).valueOf(),
      el.position,
    ]);

    // TODO: remove once CU-dz195e is fixed
    if (platform === "spotify") historyData = historyData.reverse();

    return {
      marker: {
        enabled: true,
      },
      name:
        platform === PLATFORM.SPOTIFY
          ? trackInfo.name
          : trackInfo.attributes.albumName,
      data: historyData,
    };
  });

  const options = {
    colors: getColorScheme(),
    chart: {
      backgroundColor: "transparent",
      polar: false,
      type: "spline",
      zoomType: "xy",
      panning: true,
      panKey: "shift",
      plotBorderWidth: 1,
    },
    title: {
      text: "",
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      // crosshairs: true,
      useHTML: true,
      backgroundColor: "#1d2025",
      borderRadius: 10,
      style: {
        zIndex: 9999,
        color: "white",
        opacity: 0.5,
      },
      shared: true,
      xDateFormat: "%A, %b %e",
    },
    credits: {
      enabled: false,
    },
    series: chartData,
    xAxis: {
      left: innerWidth < 769 ? "5%" : undefined, // mobile
      width: innerWidth < 769 ? "95%" : undefined, // mobile
      type: "datetime",
      opposite: true,
      // startOnTick: true,
      labels: {
        format: "{value:%b %e}",
        // step: 2,
        style: {
          color: "#FFFFFF",
        },
        // formatter: function () {
        //   var d = new Date(this.value);
        //   return `${d.toLocaleString("en-us", {
        //     month: "short",
        //   })} ${d.getDate()}`;
        // },
      },
    },
    yAxis: {
      startOnTick: true,
      min: 1,
      allowDecimals: false,
      visible: true,
      lineWidth: 1,
      gridLineWidth: 0,
      reversed: true,
      title: {
        text: "",
      },
      labels: {
        useHTML: true,
        align: innerWidth < 769 ? "left" : "right", // only mobile
        x: innerWidth < 769 ? -5 : -8,
        style: {
          color: "#FFFFFF",
          backgroundColor: innerWidth < 769 ? "#33373f" : "", // mobile
          borderRadius: "10px",
          padding: "2px",
        },
        formatter: function () {
          if (this.value === 0) return;
          return this.value;
        },
      },
    },
    legend: {
      itemStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "12px",
        fontWeight: "normal",
        textOverflow: "ellipsis",
      },
      itemHoverStyle: {
        color: "#FFFFFF",
        cursor: "pointer",
        fontSize: "13px",
        fontWeight: "bold",
        textOverflow: "ellipsis",
      },
    },
    navigator: {
      enabled: true,
    },
    rangeSelector: {
      buttonPosition: {
        align: "left",
        x: innerWidth < 769 ? 5 : -10,
      },
      enabled: true,
      selected: 0,
      allButtonsEnabled: true,
      buttonTheme: {
        // styles for the buttons
        fill: "none",
        stroke: "none",
        "stroke-width": 0,
        r: 8,
        style: {
          color: "#FFFFFF",
          fontWeight: "bold",
        },
        states: {
          hover: { fill: "#0ef1db" },
          select: {
            fill: "#0ef1db",
            style: {
              color: "white",
            },
          },
          // disabled: { ... }
        },
      },
      // floating: true,
      // y: -65,
      horizontalAlign: "center",
      labelStyle: { display: "none" },
      inputStyle: { display: "none" },
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

export default PlaylistPerformanceLineChart;
