import React from "react";
import { Link } from "react-router-dom";

import Spinner from "../../../assets/spinner.svg";

function SuggestedArtists({ suggestedArtists }) {
  return (
    <div className="welcomePage-suggested-modal">
      <br />
      <div className="welcomePage-suggested-header">⭐ suggested artists</div>
      <br />
      <div className="welcomePage-suggested-items">
        {suggestedArtists ? (
          suggestedArtists.slice(0, 15).map((item, index) => (
            // TODO: scroll up on picking from suggested
            <Link
              key={index}
              to={`/artistDashboard?id=${item.id}&entityName=${item.name}&type=${item.type}`}
            >
              <div className="welcomePage-suggested-item">
                <span className="welcomePage-suggested-item-img">
                  <img src={item?.images?.[0]?.url} alt={item.name} />
                  <div className="welcomePage-suggested-item-shade" />
                  <div className="welcomePage-suggested-item-name">
                    {item.name}
                  </div>
                </span>
              </div>
            </Link>
          ))
        ) : (
          <div className="welcomePage-suggested-items-spinner-wrapper">
            <img
              src={Spinner}
              alt="loading"
              className="welcomePage-suggested-items-spinner"
            />
          </div>
        )}
      </div>
      <br />
    </div>
  );
}

export default SuggestedArtists;
