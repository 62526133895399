import React, { useState } from "react";
import VectorStar from "../../../assets/vectorStar.svg";
import Group from "../../../assets/Group.png";
import Sp from "../../../assets/distrSpotify.png";
import Drawer from "@material-ui/core/Drawer";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";

import { GlobalFilter } from "../../admin/ReleasesAdmin";

import TableView from "../../../screens/Admin/Releases/components/TableView";
import TrashIcon from "../../../assets/icons/trash.svg";
import Toast from "../../Toast";
import ContactForm from "./ContactForm";
import { useQuery } from "react-query";
import { deleteContact, getContacts } from "../../../services/contact";

const INITIAL_PAGE_SIZE = 10;

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const Contacts = () => {
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
  });

  const [toast, setToast] = useState(initialToastState);

  const [editing, setEditing] = useState({
    state: false,
    contact: {},
  });

  const toggleDrawer = (isOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ isOpen });
    setEditing({
      state: false,
      contact: {},
    });
  };

  const handleEditRow = (contact) => {
    setDrawerState({ isOpen: true });
    setEditing({
      state: true,
      contact,
    });
  };

  const handleDeleteRow = async (contactId) => {
    if (window.confirm("Are you sure you want to delete this contact?")) {
      try {
        await deleteContact(contactId);
        setToast({
          type: "success",
          open: true,
          message: "Contact deleted successfully.",
        });
        refetch();
      } catch (err) {
        const toastMessage = "Something went wrong, try again later";
        setToast({
          open: true,
          message: err?.response?.data?.message
            ? err.response.data.message
            : toastMessage,
          type: "danger",
        });
      }
    }
  };

  const {
    data: contactsData,
    isLoading: areContactsLoading,
    refetch,
  } = useQuery("contacts", getContacts);

  const contacts = React.useMemo(
    () => contactsData?.data ?? [],
    [contactsData]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },

      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Email",
        accessor: "email",
      },

      {
        Header: "Creation Date",
        accessor: "createdAt",
        Cell: ({ value }) => value?.split("T")[0],
      },

      {
        Header: "Actions",
        accessor: (originalRow) => originalRow,
        disableSortBy: true,
        Cell: ({ value }) => (
          <div
            className="d-inline-flex justify-content-center align-items-center"
            title=""
          >
            <div
              className="admin-release-details-link mr-2"
              onClick={() => handleEditRow(value)}
            >
              Edit
            </div>{" "}
            |{" "}
            <span
              role="button"
              onClick={() => handleDeleteRow(value._id)}
              className="ml-2 cursor-pointer"
              title="Delete contact"
            >
              <img src={TrashIcon} alt="delete contact" />
            </span>
          </div>
        ),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: contacts,
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;
  return (
    <main className="myReleases-overview">
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />
      <section className="myReleases-header">
        <div className="distributionButton" onClick={toggleDrawer(true)}>
          New Contact
        </div>
        <br />
        <div className="distributionGuidelinesNote">
          Contacts are legal persons.
        </div>
        <br />
        {/* decoration boxes */}
        <div className="dDecoration1">
          <div className="dd1a">+</div>
        </div>
        <div className="dShader"></div>
        <div className="dDecoration2">
          <div className="dd2Text">Genre</div>
          <div className="dd2badge1">future house</div>
          <div className="dd2badge2">techno</div>
          <div className="dd2badge3">hard style</div>
          <div className="dd2badge4"></div>
        </div>
        <div className="dDecoration3">
          <img src={VectorStar} alt="star" className="dd3star" />
          <img src={Group} alt="group" className="dd3group" />
        </div>
        <div className="dDecoration4">
          <div className="dd4Inner1">
            <img src={Sp} alt="Spotify" className="dd4InnerSpotify" />
          </div>
        </div>
      </section>

      {/* GRID AND LIST VIEW */}
      <div className="allowUserSelect">
        <main className="admin-releases mt-4" style={{ marginLeft: "0" }}>
          <div className="d-block mb-3" style={{ zIndex: 1 }}>
            <p className="font-weight-bold text-white mb-2">Filter by:</p>
            <div className="row pb-2 align-items-center justify-content-between">
              <div className="col-12 col-md">
                <div className="row">
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </div>
              </div>
            </div>
          </div>

          <TableView
            tableInstance={tableInstance}
            loading={areContactsLoading}
          />
        </main>
      </div>

      <Drawer
        anchor={window.innerWidth < 768 ? "bottom" : "right"}
        open={drawerState.isOpen}
        onClose={toggleDrawer(false)}
        className="h-100"
      >
        <div
          className={`bg-dark overflow-auto vh-100 admin-release-drawer`}
          role="presentation"
        >
          <div
            onClick={toggleDrawer(false)}
            className=" mx-0 mb-4 d-flex justify-content-between align-items-center text-white px-3 py-4 contract-drawer-header"
          >
            <div>
              <span>{`${editing.state ? "Edit" : "Create"} Contact`}</span>
            </div>
            <div className="cursor-pointer">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ width: "20px", height: "20px" }}
              />
            </div>
          </div>
          <ContactForm
            onUpdate={setDrawerState}
            onClose={toggleDrawer(false)}
            edit={editing.state}
            contact={editing.contact}
            refetch={refetch}
          />
        </div>
      </Drawer>
    </main>
  );
};

export default Contacts;
