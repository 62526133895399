import React, { useEffect, useState } from "react";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import checkCircle from "../../../assets/icons/check-circle.svg";

import { get as sliceObject } from "lodash";

const FbAliceCarousel = ({
  fbProfiles,
  handleSelect,
  checked,
  setChecked,
  isInstagram = false,
}) => {
  let responsive = {
    0: { items: 3 },
    1024: { items: 3 },
  };

  const [cutter, setCutter] = useState("initial");
  const [boxes, setBoxes] = useState([]);

  useEffect(() => {
    if (fbProfiles.length > 0 && cutter !== "initial") {
      let newProfiles = [...fbProfiles];
      setBoxes(newProfiles.splice(cutter, 1));
    } else if (fbProfiles.length > 0 && cutter === "initial") {
      setBoxes(fbProfiles);
    }
  }, [cutter]);

  useEffect(() => {
    if (checked !== undefined) setCutter(checked);
    else setCutter("initial");
  }, [checked]);

  useEffect(() => {
    if (cutter === "initial") {
      setBoxes(fbProfiles);
    }
  }, [fbProfiles]); // TODO: remove? obsolete?

  function handleSelectIntercept(e) {
    setCutter(e.target.id || e.target.offsetParent.id);
    handleSelect(e);
  }

  function handleClear() {
    setCutter("initial");
    handleSelect("clear");
  }

  if (boxes.length > 1) {
    const items = boxes
      // .filter((box, i) => i <= 4) // <== dev filter for debugging - provided value (must be over 3, otherwise fake carousel display case) will cut the profiles amount
      .map((fbProfile, index) => {
        return (
          <ProfileBox
            fbProfile={fbProfile}
            index={index}
            className={"ob3ProfileBox pb" + index}
            handleSelect={handleSelectIntercept}
            checked={checked}
            isInstagram={isInstagram}
          ></ProfileBox>
        );
      });

    return (
      <>
        <AliceCarousel
          items={items}
          mouseTrackingEnabled
          responsive={responsive}
          infinite={true}
        ></AliceCarousel>
      </>
    );
  } else if (boxes.length === 1) {
    return (
      <div className="ob3ProfileBox-one">
        <ProfileBox
          fbProfile={boxes[0]}
          index={cutter}
          className={"ob3ProfileBox pbCenter"}
          handleSelect={handleSelectIntercept}
          checked={checked}
          forceTick={true}
          isInstagram={isInstagram}
        ></ProfileBox>
        <div className="pbCenter-change" onClick={handleClear}>
          pick another page
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

function ProfileBox({
  className,
  handleSelect,
  index,
  checked,
  fbProfile,
  forceTick,
  isInstagram = false,
}) {
  let profilePicField = isInstagram
    ? "profile_picture_url"
    : "picture.data.url";
  return (
    <div className={className} onClick={handleSelect} id={index}>
      <div className="ob3ConnectOval" onClick={handleSelect} id={index}>
        <img
          src={checkCircle}
          alt="check"
          className={
            "ob3ProfileBox-checkCircle " +
            (Number(checked) === index || forceTick ? "" : "d-none")
          }
          onClick={handleSelect}
        />
        <img
          alt="user"
          src={sliceObject(fbProfile, profilePicField)}
          style={{ width: "100%", borderRadius: "100px", overflow: "visible" }}
          onClick={handleSelect}
          id={index}
        />
      </div>
      <div className="ob3SpotifyProfileName" onClick={handleSelect} id={index}>
        {fbProfile?.name?.length > 32
          ? fbProfile?.name.slice(0, 32) + "..."
          : fbProfile?.name}
      </div>
    </div>
  );
}

export default FbAliceCarousel;
