import React, { useRef, useEffect, useState } from "react";

import DatePicker from "react-datepicker";
import "../../assets/scss/react-datepicker.css";
import { parseISO, format } from "date-fns";

import moment from "moment";

function CalendarComponent(props) {
  const datePickerRef = useRef();

  const [selected, setSelected] = useState();

  function handlePick(date) {
    setSelected(date);
    props.datePickCallback(
      moment(date.toDateString()).format("YYYY-MM-DD"),
      props.calendarId,
      props.databaseField
    );
  }

  useEffect(() => {
    datePickerRef.current.setOpen(props.calendarState);
  }, [props.calendarState]);

  return (
    <DatePicker
      selected={selected ? selected : ""}
      ref={datePickerRef}
      calendarClassName={props.calendarClassName}
      shouldCloseOnSelect={props.shouldCloseOnSelect ?? false}
      onChange={(date) => handlePick(date)}
      showMonthDropdown={true}
      showYearDropdown={true}
      popperClassName={props.popperClassName}
      minDate={props.minDate}
      maxDate={props.maxDate}
    />
  );
}

export default CalendarComponent;
