import React, { useEffect, useState } from "react";
import ButtonComponent from "../form/ButtonComponent";
import Toast from "../Toast";
import moment from "moment";

import spinner from "../../assets/spinner.svg";

import "./admin.scss";
import AdminEarningsModal from "./components/AdminEarningsModal";
import Portal from "../Portal";
import UserSearch from "./components/AdminUserSearch";
import { groupBy } from "lodash";
import { getUserTransactions } from "../../services/admin/transactions";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const TransactionsHistory = ({ userTransactions, loading }) => {
  function formatAmount(value) {
    if (value < 0) {
      return <div>- € {Math.abs(value).toFixed(2)}</div>;
    } else {
      return <div className="textColorPrimary">+ € {value.toFixed(2)}</div>;
    }
  }
  const formatDate = (value) => moment(value).format("YYYY-MM-DD HH:mm");

  if (!userTransactions?.length) {
    if (!loading) return <div>No transaction history found</div>;

    return (
      <div>
        <img src={spinner} />
      </div>
    );
  }

  const transactionsGrouped = groupBy(userTransactions, "description");
  const transactionsReduced = Object.keys(transactionsGrouped)
    .map((descriptionGroup) => {
      return transactionsGrouped[descriptionGroup].reduce(
        (acc, v) => {
          return {
            amount: acc.amount + v.amount,
            description: descriptionGroup,
            created_at: v.created_at,
          };
        },
        { amount: 0, description: "", created_at: "" }
      );
    })
    .reverse(); // latest first (TODO: check if sorting reliable?)

  return (
    <table>
      <tr>
        <th>Date</th>
        <th>Description</th>
        <th>Amount</th>
      </tr>
      {transactionsReduced?.map((transaction) => (
        <tr>
          <td>{formatDate(transaction.created_at)}</td>
          <td>{transaction.description}</td>
          <td>{formatAmount(transaction.amount)}</td>
        </tr>
      ))}
    </table>
  );
};

const AccountBalancesAdmin = () => {
  const [showToast, setShowToast] = useState(initialToastState);
  const [isOpenEarningsModal, setIsOpenEarningsModal] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [userTransactions, setUserTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userFilter.length) {
      fetchUserTransactions();
    }
  }, [userFilter]);

  const fetchUserTransactions = async () => {
    if (!userFilter.length) return;

    try {
      setLoading(true);
      const transactions = await getUserTransactions(userFilter[0]);
      setUserTransactions(transactions.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleOnModalClose = () => {
    setIsOpenEarningsModal(false);
    fetchUserTransactions();
  };

  const handleOpenEarningsModal = () => {
    setIsOpenEarningsModal(true);
  };

  function growingMoneyFontSize(userBalance) {
    if (userBalance < 25) {
      return { fontSize: "1.7rem" };
    } else {
      return { fontSize: "3rem", paddingTop: "0.5rem" };
    }
  }

  let userBalance = userTransactions.reduce(
    (acc, v) => {
      return { amount: acc.amount + v.amount };
    },
    { amount: 0 }
  )["amount"];

  return (
    <div className="adminMessages">
      <div className="adminTable">
        <Toast
          open={showToast.open}
          onClose={() =>
            setShowToast((prevState) => ({ open: false, type: prevState.type }))
          }
          toastMessage={showToast.message}
          toastType={showToast.type}
        />
        <div className="adminRoyalties-display-userSearch">
          <small>Admin filter by users:</small>
          <UserSearch
            isMulti={false}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
          />
        </div>

        <div className="d-flex justify-content-end mx-5 my-4">
          <ButtonComponent
            className="groupBtnActive"
            onClick={handleOpenEarningsModal}
          >
            Add transaction
          </ButtonComponent>
        </div>

        <section className="my-money-section xyFlexCenter">
          <div
            className="my-money-total-amount"
            style={growingMoneyFontSize(userBalance)}
          >
            {!userTransactions.length && !loading
              ? ""
              : "€ " + userBalance?.toFixed(2)}
          </div>
          <div className={"my-money-transactions"}>
            <div className={"my-money-transactions" + "-header"}>
              Transaction history
            </div>
            <br />
            <TransactionsHistory
              loading={loading}
              userTransactions={userTransactions}
            />
          </div>
        </section>
      </div>

      <Portal selector="body">
        {isOpenEarningsModal && (
          <AdminEarningsModal
            userEmail={userFilter?.[0]}
            onCloseRequest={handleOnModalClose}
            setIsOpenEarningsModal={setIsOpenEarningsModal}
          />
        )}
      </Portal>
    </div>
  );
};

export default AccountBalancesAdmin;
