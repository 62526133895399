import React, { useEffect, useState } from "react";

import { Image } from "image-js";

import Dropzone from "../../form/DropzoneComponent";
import ValidationTextBox from "../../form/ValidationTextBox";

import plus from "../../../assets/icons/plusIcon.svg";
import spinner from "../../../assets/spinner.svg";

import imageIcon from "../../../assets/icons/imageIcon.svg";
import ReleasePlaceholder from "../../../assets/releasePlaceholder.png";

function CoverArtUploadComponent({
  validation,
  coverArtUploadCallback,
  preloadImage,
  className,
}) {
  const [coverArtFile, setCoverArtFile] = useState({
    file: "",
    previewUrl: imageIcon,
  });
  const [coverArtLoading, setCoverArtLoading] = useState(false);
  const [coverArtStatus, setCoverArtStatus] = useState({
    isLoaded: false,
    isError: false,
    extErr: false,
    noSqErr: false,
    tooSmErr: false,
    tooBgErr: false,
    cModErr: false,
    fileSizeErr: false,
  });

  useEffect(() => {
    if (preloadImage) {
      // if user navigates backwards to basic information page and the image preloads
      setCoverArtStatus({
        isError: false,
        isLoaded: true,
        extErr: false,
        noSqErr: false,
        tooSmErr: false,
        tooBgErr: false,
        cModErr: false,
        fileSizeErr: false,
      }); // validation
      setCoverArtFile(preloadImage);
      coverArtUploadCallback("preloaded");
    }
  }, [preloadImage]);

  function dropzoneAcceptCallback(file, e) {
    let reader = new FileReader();
    let blob = new Blob(file);
    reader.readAsDataURL(blob);

    setCoverArtFile({ file: "", previewUrl: spinner }); // loading
    setCoverArtLoading(true); // loading
    reader.onloadend = async () => {
      if (await checkImage(reader.result, blob.size)) {
        setCoverArtStatus({
          isError: false,
          isLoaded: true,
          extErr: false,
          noSqErr: false,
          tooSmErr: false,
          tooBgErr: false,
          cModErr: false,
          fileSizeErr: false,
        }); // validation
        setCoverArtFile({ file: file, previewUrl: reader.result }); // preview
        coverArtUploadCallback(file); // transfer
        setCoverArtLoading(false); // loaded
      } else {
        setCoverArtLoading(false); // idle
        coverArtUploadCallback(""); // validation
        setCoverArtFile({ file: "", previewUrl: imageIcon }); // back to idle
      }
    };
  }

  function dropzoneRejectCallback(file, e) {
    setCoverArtFile({ file: "", previewUrl: imageIcon });
    setCoverArtStatus({
      ...coverArtStatus,
      isLoaded: true,
      isError: false,
      extErr: false,
      noSqErr: false,
      tooSmErr: false,
      tooBgErr: false,
      cModErr: false,
      fileSizeErr: false,
    }); // validation
    coverArtUploadCallback("preloaded");
  }

  async function checkImage(imgFileString, fileSize) {
    return await Image.load(imgFileString).then(function (image) {
      let noSqErr = image.height !== image.width;
      let tooSmErr = image.height < 3000 || image.width < 3000;
      let tooBgErr = image.height > 5000 || image.width > 5000;
      let cModErr = image.colorModel !== "RGB";
      let fileSizeErr = fileSize > 36000000;
      let isError =
        noSqErr === true ||
        tooSmErr === true ||
        tooBgErr === true ||
        cModErr === true ||
        fileSizeErr === true;
      setCoverArtStatus({
        ...coverArtStatus,
        isLoaded: true,
        extErr: false,
        isError: isError,
        noSqErr: noSqErr,
        tooBgErr: tooBgErr,
        tooSmErr: tooSmErr,
        cModErr: cModErr,
        fileSizeErr: fileSizeErr,
      });
      return !isError;
    });
  }

  return (
    <div className={className}>
      <Dropzone
        acceptFileType="image/jpg, image/jpeg, image/tiff, image/png"
        className="nrCoverArtUploadField"
        inputClassName="nrCoverArtInput"
        onReject={dropzoneRejectCallback}
        onAccept={dropzoneAcceptCallback}
      >
        {!coverArtStatus.isLoaded ||
        coverArtStatus.isError ||
        coverArtLoading ? (
          <div className="nrCoverArtPlaceholder">
            <img
              src={coverArtFile.previewUrl}
              alt="upload"
              className="uploadImageIcon"
            />
            <img
              src={plus}
              alt="plus"
              className={coverArtLoading ? "d-none" : "uploadImageCircle"}
            />
          </div>
        ) : (
          <img
            src={
              coverArtFile.previewUrl === "initial"
                ? ReleasePlaceholder
                : coverArtFile.previewUrl
            }
            alt="upload"
            className="nrCoverArtPreview"
          />
        )}
      </Dropzone>
      <div className="coverArtUploadRightText">
        Give the cover art the attention it deserves!
        <br />
        <br />
        Your cover art must be:
        <br />
        <br />
        <ul className="coverArtUploadRightTextList">
          {/*  */}
          {coverArtStatus.isLoaded && !coverArtStatus.extErr ? (
            coverArtStatus.noSqErr ? (
              <li className="coverArtErr">
                Square{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Square{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Square</li>
          )}
          {coverArtStatus.isLoaded && !coverArtStatus.extErr ? (
            coverArtStatus.tooSmErr ? (
              <li className="coverArtErr">
                Minimum size 3000x3000 pixels{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Minimum size 3000x3000 pixels{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Minimum size 3000x3000 pixels</li>
          )}
          {coverArtStatus.isLoaded && !coverArtStatus.extErr ? (
            coverArtStatus.tooBgErr ? (
              <li className="coverArtErr">
                Maximum size 5000x5000 pixels{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Maximum size 5000x5000 pixels{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Maximum size 5000x5000 pixels</li>
          )}
          {coverArtStatus.isLoaded ? (
            coverArtStatus.extErr ? (
              <li className="coverArtErr">
                Format: jpg, tiff or png{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Format: jpg, tiff or png{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Format: jpg, tiff or png</li>
          )}
          {coverArtStatus.isLoaded && !coverArtStatus.extErr ? (
            coverArtStatus.cModErr ? (
              <li className="coverArtErr">
                Mode: RGB{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Mode: RGB{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Mode: RGB</li>
          )}
          {coverArtStatus.isLoaded && !coverArtStatus.extErr ? (
            coverArtStatus.fileSizeErr ? (
              <li className="coverArtErr">
                Max 36mb{" "}
                <span alt="error" aria-label="error" className="nrTick">
                  ❌
                </span>
              </li>
            ) : (
              <li className="coverArtOk">
                Max 36mb{" "}
                <span alt="ok" aria-label="ok" className="nrTick">
                  ✅
                </span>
              </li>
            )
          ) : (
            <li>Max 36mb</li>
          )}
        </ul>
      </div>
      <ValidationTextBox
        display={validation["#cover_url"] || coverArtStatus.isError}
        className="nrValTextBox nrImgValMsg"
        for="#cover_url"
      >
        {validation["#cover_url"] && !coverArtStatus.isError
          ? "Please upload a cover for your release"
          : ""}
        {coverArtStatus.isError ? "Please review the image requirements" : ""}
      </ValidationTextBox>
    </div>
  );
}

export default CoverArtUploadComponent;
