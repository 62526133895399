import React, { useMemo, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import TableView from "../../screens/Admin/Releases/components/TableView";
import SelectComponent from "../form/SelectComponent";
import Switch from "../Switch";
import { GlobalFilter } from "./ReleasesAdmin";
import {
  getAccountingPeriods,
  setPaymentStatus,
} from "../../services/admin/periods";
import { useQuery } from "react-query";
import { useMutation, useQueryClient } from "react-query";

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const INITIAL_PAGE_SIZE = 12;

const accountingMonthFilterOptions = {
  "": { label: "All", value: "" },
  january: { label: "January", value: "january" },
  february: { label: "February", value: "february" },
  march: { label: "March", value: "march" },
  april: { label: "April", value: "april" },
  may: { label: "May", value: "may" },
  june: { label: "June", value: "june" },
  july: { label: "July", value: "july" },
  august: { label: "August", value: "august" },
  september: { label: "September", value: "september" },
  october: { label: "October", value: "october" },
  november: { label: "November", value: "november" },
  december: { label: "December", value: "december" },
};

const accountingYearFilterOptions = {
  "": { label: "All", value: "" },
  2020: { label: "2020", value: "2020" },
  2021: { label: "2021", value: "2021" },
  2022: { label: "2022", value: "2022" },
  2023: { label: "2023", value: "2023" },
  2024: { label: "2024", value: "2024" },
  2025: { label: "2025", value: "2025" },
  2026: { label: "2026", value: "2026" },
  2027: { label: "2027", value: "2027" },
  2028: { label: "2028", value: "2028" },
};

const paymentFilterOptions = {
  "": { label: "All", value: "" },
  awaiting_payment: { label: "Awaiting payment", value: "awaiting_payment" },
  payment_received: { label: "Recieved payment", value: "payment_received" },
};

const periodStatusOptions = {
  "": { label: "All", value: "" },
  open: { label: "Open", value: "open" },
  closed: { label: "Closed", value: "closed" },
  processing: { label: "Processing", value: "processing" },
};

const AccountingMonthFilter = ({
  filters,
  setFilter,
  options = accountingMonthFilterOptions,
}) => {
  const initialMonthFilter = filters.find((f) => f.id === "month")?.value || "";
  const [accountingMonthFilter, setAccountingMonthFilter] =
    React.useState(initialMonthFilter);
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"month"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Month</div>
      <SelectComponent
        value={options[initialMonthFilter].label}
        extendOptionWidth
        id={"month"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") {
            setAccountingMonthFilter(val);
            setFilter("month", val);
          }
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const AccountingYearFilter = ({
  filters,
  setFilter,
  options = accountingYearFilterOptions,
}) => {
  const initialYearFilter = filters.find((f) => f.id === "year")?.value || "";
  const [accountingYearFilter, setAccountingYearFilter] =
    React.useState(initialYearFilter);
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"year"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">Year</div>
      <SelectComponent
        value={options[accountingYearFilter].label}
        extendOptionWidth
        id={"year"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") {
            setAccountingYearFilter(val);
            setFilter("year", val);
          }
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const PaymentStatusFilter = ({
  filters,
  setFilter,
  options = paymentFilterOptions,
}) => {
  const initialPaymentFilter =
    filters.find((f) => f.id === "paymentStatus")?.value || "";
  const [paymentStatusFilter, setPaymentStatusFilter] =
    React.useState(initialPaymentFilter);
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"paymentStatus"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">
        Payment Status
      </div>
      <SelectComponent
        value={options[paymentStatusFilter].label}
        extendOptionWidth
        id={"paymentStatus"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") {
            setPaymentStatusFilter(val);
            setFilter("paymentStatus", val);
          }
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const PeriodStatusFilter = ({
  filters,
  setFilter,
  options = periodStatusOptions,
}) => {
  const initialPaymentFilter =
    filters.find((f) => f.id === "status")?.value || "";
  const [periodStatusFilter, setPeriodStatusFilter] =
    React.useState(initialPaymentFilter);
  return (
    <div
      className={
        "nrAlbumTitleInputWrapper col-12 col-md-3 col-lg-2 py-2 py-md-0 text-white"
      }
      id={"status"}
    >
      <div className="inputUpperComment iuc3 playtreksSelector">
        Period Status
      </div>
      <SelectComponent
        value={options[periodStatusFilter].label}
        extendOptionWidth
        id={"status"}
        valuePaddingTop={true}
        placeholderTop="50%"
        placeholderFont="Inter-Medium"
        placeholderColor="white"
        placeholderOpacity="1"
        options={Object.values(options)}
        customNoOptionsMessage={<p classNam="ml-2">No options</p>}
        selectChangeCallback={(
          val,
          databaseField,
          commentField,
          databaseKey,
          type
        ) => {
          if (type.action !== "input-change") {
            setPeriodStatusFilter(val);
            setFilter("status", val);
          }
        }}
        // to be used for data transfer
        selectBlurCallback={() => {}} // leave empty
      />
    </div>
  );
};

const statusCssClasses = {
  open: "light",
  processing: "warning",
  closed: "success",
};

const AccountingPeriodsAdmin = () => {
  const queryClient = useQueryClient();

  const {
    data: periodsData,
    isLoading,
    refetch,
  } = useQuery(["accountingPeriods"], getAccountingPeriods, {
    refetchOnWindowFocus: false,
  });

  const periods = useMemo(() => periodsData?.data ?? [], [periodsData?.data]);

  const paymentStatus = useMutation(setPaymentStatus);

  const columns = useMemo(
    () => [
      {
        Header: "Accounting period",
        id: "year",
        accessor: "endDate",
        Cell: ({ value }) => {
          const date = new Date(value);
          const year = date.getFullYear();
          const month = ("0" + (date.getMonth() + 1)).slice(-2);
          return (
            <span className="font-weight-bold fs-14">
              {year}-{month}
            </span>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <span
            className={`font-weight-bold fs-14 badge badge-${statusCssClasses[value]}`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",

        Cell: ({ row, value }) => {
          const [switchValue, setSwitchValue] = useState(
            value === "payment_received" ? true : false
          );

          const handlePaymentStatusChange = (periodId, status) => {
            const payload = {
              periodId,
              status,
            };

            if (
              (status === "payment_received" &&
                window.confirm(
                  "Are you sure you want to mark this payment as received?"
                )) ||
              (status === "awaiting_payment" &&
                window.confirm(
                  "Are you sure you want to mark this payment as awaiting?"
                ))
            ) {
              paymentStatus.mutate(payload, {
                onSuccess: () => {
                  queryClient.invalidateQueries("accountingPeriods");
                },
              });
            } else {
              setSwitchValue((prevSwitchValue) => !prevSwitchValue);
            }
          };

          return (
            <div className="d-flex align-items-center">
              <div className="mr-2">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={switchValue}
                    onChange={() => {
                      const newStatus = !switchValue
                        ? "payment_received"
                        : "awaiting_payment";
                      setSwitchValue(!switchValue);
                      handlePaymentStatusChange(row.original._id, newStatus);
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className="font-weight-bold fs-14">
                {switchValue ? "Payment received" : "Awaiting Payment"}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data: periods?.data ?? [],
      initialState: {
        pageIndex: 0,
        pageSize: INITIAL_PAGE_SIZE,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    state: { globalFilter, filters },
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = tableInstance;

  return (
    <main className={"admin-releases mt-4 pb-3 "}>
      <div className="d-block mb-3" style={{ zIndex: 1 }}>
        <p className="font-weight-bold text-white mb-0">Filter by:</p>
        <div className="row pb-2 align-items-center justify-content-between">
          <div class="col-12 col-md">
            <div class="row">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
              <AccountingMonthFilter filters={filters} setFilter={setFilter} />
              <AccountingYearFilter filters={filters} setFilter={setFilter} />
              <PaymentStatusFilter filters={filters} setFilter={setFilter} />
              <PeriodStatusFilter filters={filters} setFilter={setFilter} />
            </div>
          </div>
        </div>
      </div>
      <TableView tableInstance={tableInstance} loading={isLoading} />
    </main>
  );
};

export default AccountingPeriodsAdmin;
