import React from "react";

/**
 *
 * @param {Boolean} switchState
 * @returns
 */
function Switch({ switchState, inactive = false, icons = true, onClick }) {
  return (
    <div
      className={"ptSwitch" + (inactive ? "-inactive" : "")}
      // onClick={inactive === true ? () => {} : handleClick}
    >
      <div
        className={
          "ptSwitch-handle " +
          (switchState ? "ptSwitch-handle-on" : "ptSwitch-handle-off")
        }
        style={{ textAlign: "center", paddingTop: "7px" }}
      >
        {switchState ? (
          <span style={{ position: "relative" }} aria-label="ok">
            {icons && "✔️"}
          </span>
        ) : (
          <span style={{ position: "relative" }} aria-label="ok">
            {icons && "✖️"}
          </span>
        )}
      </div>
    </div>
  );
}

export default Switch;
