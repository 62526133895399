import React, { useState } from "react";
import "./index.scss";
import SMS2FASetup from "./components/SMS2FASetup";
import BackupCodes from "../2FA/components/BackupCodes";
import AuthApp2FASetup from "./components/AuthApp2FASetup";
import { get2FAState } from "../../../../services/auth";
import { useQuery } from "react-query";
import Toast from "../../../Toast";

const initialToastState = {
  open: false,
  message: "",
  type: "success",
};

const SetUp2FA = ({ reCaptchaRef }) => {
  const [toast, setToast] = useState(initialToastState);

  const {
    data: { data: twoFAState } = {},
    loading: twoFAStateLoading,
    refetch: refetch2FAState,
  } = useQuery("2fa-state", get2FAState, { retry: false });

  const isTwoFAEnabled = twoFAState?.sms?.enabled || twoFAState?.totp?.enabled;

  return (
    <>
      <Toast
        open={toast.open}
        onClose={() =>
          setToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={toast.message}
        toastType={toast.type}
      />

      <div className="TwoFACard text-white p-4">
        <AuthApp2FASetup
          setToast={setToast}
          enabled={twoFAState?.totp?.enabled}
          refetch2FAState={refetch2FAState}
          twoFAStateLoading={twoFAStateLoading}
        />

        <div className="LineBreak w-100 mb-3 mt-0" style={{ top: 0 }}></div>

        <SMS2FASetup
          reCaptchaRef={reCaptchaRef}
          setToast={setToast}
          twoFAState={twoFAState?.sms}
          refetch2FAState={refetch2FAState}
          twoFAStateLoading={twoFAStateLoading}
        />
      </div>

      {isTwoFAEnabled && (
        <div class="TwoFACard text-white p-4 pt-0 mt-3">
          <BackupCodes setToast={setToast} isTwoFAEnabled={isTwoFAEnabled} />
        </div>
      )}
    </>
  );
};

export default SetUp2FA;
