import React, { useCallback, useState, useEffect } from "react";
import InputComponent from "../../../components/form/InputComponent";
import ButtonComponent from "../../../components/form/ButtonComponent";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import Toast from "../../../components/Toast";
import { Spinner } from "../../../screens/Airplay/Airplay";
import CalendarComponent from "../../form/CalendarComponent";
import calendar from "../../../assets/icons/calendar.svg";
import SelectComponent from "../../form/SelectComponent";
import { ExpenseValidationSchema } from "./Validation";
import { getContracts } from "../../../services/contracts";
import { useQuery, useMutation } from "react-query";
import { createExpense, updateExpense } from "../../../services/expenses";
import { useSelector } from "react-redux";

const initialToastState = {
  open: false,
  message: "",
  type: "danger",
};

const TotalPaidAmount = React.memo(() => {
  const {
    values: { expenseSplits, totalPaidAmount },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const total = expenseSplits?.reduce(
      (acc, curr) => acc + Number(curr.paidAmount),
      0
    );

    setFieldValue("totalPaidAmount", total);
  }, [expenseSplits, setFieldValue]);

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div className={"inputUpperComment utiuc3_totalAmount"}>Total</div>

      <div className="position-relative">
        <InputComponent
          value={totalPaidAmount ? totalPaidAmount : 0}
          disabled={true}
          commentField={".utiuc3_totalAmount"}
          inputGroupClassName="nrInputGroup "
          inputClassName={
            " nrInput utepInput pl-4 font-weight-bold" +
            (totalPaidAmount >= 0
              ? " interMediumInput interMediumPaddingTop "
              : "")
          }
          paddingTopOnInput={true}
          addon={false}
        />
      </div>
      {totalPaidAmount >= 0 ? (
        <div
          className="position-absolute currency-color utepPercent-2 font-weight-bold"
          style={{
            left: 10,
            bottom: 11,
            zIndex: 100,
          }}
        >
          €
        </div>
      ) : (
        ""
      )}
    </div>
  );
});

const TotalExpenseAmount = React.memo(() => {
  const {
    values: { expenseSplits, totalExpenseSplitPercentage },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const total = expenseSplits?.reduce(
      (acc, curr) => acc + Number(curr.expenseShare),
      0
    );

    setFieldValue("totalExpenseSplitPercentage", total);
  }, [expenseSplits, setFieldValue]);

  return (
    <div className="nrAlbumTitleInputWrapper w-100">
      <div className={"inputUpperComment utiuc3_totalAmount"}>Total</div>

      <div className="position-relative">
        <InputComponent
          value={totalExpenseSplitPercentage ? totalExpenseSplitPercentage : 0}
          disabled={true}
          commentField={".utiuc3_totalAmount"}
          inputGroupClassName="nrInputGroup "
          inputClassName={
            " nrInput utepInput  font-weight-bold" +
            (totalExpenseSplitPercentage >= 0
              ? " interMediumInput interMediumPaddingTop "
              : "")
          }
          paddingTopOnInput={true}
          addon={false}
        />
      </div>
      {totalExpenseSplitPercentage >= 0 ? (
        <div
          className="position-absolute currency-color utepPercent-2 font-weight-bold"
          style={{
            right: 10,
            bottom: 11,
            zIndex: 100,
          }}
        >
          %
        </div>
      ) : (
        ""
      )}
    </div>
  );
});

const TotalRecoupingAmount = React.memo(() => {
  const {
    values: { expenseSplits, totalExpenseSplitPercentage, totalPaidAmount },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    setFieldValue(
      "expenseSplits",
      expenseSplits.map((split) => {
        const recoupableAmount =
          split?.paidAmount -
          ((split.expenseShare / 100) * totalPaidAmount).toFixed(2);
        return {
          ...split,
          recoupableAmount,
        };
      })
    );
  }, [totalPaidAmount, totalExpenseSplitPercentage]);

  return (
    <>
      {expenseSplits.map((split, index) => (
        <div className="row mb-3 mx-0 px-0" key={index}>
          <div className="position-relative w-100">
            <InputComponent
              disabled={true}
              value={split?.recoupableAmount.toFixed(2)}
              inputType="number"
              placeholder="Amount"
              inputGroupClassName="nrInputGroup "
              inputClassName={
                " nrInput utepInput text-white pl-4" +
                (split?.recoupableAmount >= 0 || split?.recoupableAmount < 0
                  ? " interMediumInput .utiuc3_ interMediumPaddingTop"
                  : "")
              }
              paddingTopOnInput={true}
              addon={false}
            />

            <div
              className="position-absolute currency-color font-weight-bold"
              style={{ left: 8, bottom: 11, zIndex: 100 }}
            >
              €
            </div>
          </div>
        </div>
      ))}
    </>
  );
});

const PaidAmount = React.memo(() => {
  const {
    values: { expenseSplits },
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <>
      {expenseSplits.map((split, index) => (
        <div className="row mb-3" key={index}>
          <div className="col-8 px-2">
            <div className={"inputUpperComment utiuc3_" + index}>Contact</div>
            <div className="postion-relative w-100">
              <InputComponent
                disabled={true}
                value={split?.rightholderName}
                commentField={".utiuc3_"}
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput " +
                  (split?.rightholderName
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                paddingTopOnInput={true}
                addon={false}
                tooltip={true}
              />
            </div>
          </div>
          <div className="col-4 px-2">
            <div
              className={
                (split?.paidAmount ? "" : "d-none") +
                " inputUpperComment utiuc3_"
              }
            >
              Amount
            </div>
            <div className="position-relative">
              <InputComponent
                value={split?.paidAmount ? split?.paidAmount : null}
                inputType="number"
                placeholder="Amount"
                inputGroupClassName="nrInputGroup "
                inputClassName={
                  " nrInput utepInput pl-4" +
                  (split?.paidAmount
                    ? " interMediumInput interMediumPaddingTop"
                    : "")
                }
                paddingTopOnInput={true}
                changeCallback={(paidAmount) => {
                  setFieldValue(
                    `expenseSplits.${index}.paidAmount`,
                    Number(paidAmount)
                  );
                }}
                inputBlurCallback={() => {
                  setFieldTouched(`expenseSplits.${index}.paidAmount`, true);
                  setFieldTouched("totalPaidAmount", true);
                }}
                addon={false}
              />
              {split?.paidAmount >= 0 ? (
                <div
                  className="position-absolute  font-weight-bold"
                  style={{ left: 8, bottom: 11, zIndex: 100 }}
                >
                  €
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-12 mt-2">
              <ErrorMessage
                name={`paidAmount.${index}.paidAmount`}
                component="div"
                className="w-100 text-danger line-height-normal  mb-2 fs-12 "
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
});

const ExpenseSplits = React.memo(() => {
  const {
    values: { expenseSplits },
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <>
      {expenseSplits.map((split, index) => (
        <div className="mb-3 row col mx-0 px-0" key={index}>
          <div className="position-relative w-100">
            <InputComponent
              value={split?.expenseShare ? split?.expenseShare : null}
              inputType="number"
              placeholder="Amount"
              inputGroupClassName="nrInputGroup "
              inputClassName={
                " nrInput utepInput " +
                (split?.expenseShare
                  ? " interMediumInput interMediumPaddingTop"
                  : "")
              }
              paddingTopOnInput={true}
              changeCallback={(expenseShare) =>
                setFieldValue(
                  `expenseSplits.${index}.expenseShare`,
                  Number(expenseShare)
                )
              }
              inputBlurCallback={() => {
                setFieldTouched(`expenseSplits.${index}.expenseShare`, true);
                setFieldTouched("totalExpenseSplitPercentage", true);
              }}
              addon={false}
            />
            {split?.expenseShare >= 0 ? (
              <div
                className="position-absolute utepPercent-2 font-weight-bold"
                style={{ right: 12, bottom: 10, zIndex: 100 }}
              >
                %
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-12 mt-2">
            <ErrorMessage
              name={`expenseSplits.${index}.expenseShare`}
              component="div"
              className="w-100 text-danger line-height-normal  mb-2 fs-12 "
            />
          </div>
        </div>
      ))}
    </>
  );
});

const ExpenseForm = ({ onClose, onUpdate, edit, expense, refetch }) => {
  const [showToast, setShowToast] = useState(initialToastState);

  const [calendarState, setCalendarState] = useState({
    startDate: false,
    endDate: false,
  });

  const reduxUserData = useSelector(
    (state) => state?.dashboardReducer?.userData
  );

  const handleOpenCalendar = useCallback((className, forField, id) => {
    setCalendarState((prevCalendarState) => ({
      ...prevCalendarState,
      [id]: !prevCalendarState[id],
    }));
  }, []);

  const { data: { data: contracts = [] } = {} } = useQuery(
    "contracts",
    getContracts
  );

  const contractOptions = React.useMemo(
    () =>
      contracts.reduce((acc, curr) => {
        const rightsholders = curr.rightsholders;

        acc[curr._id] = {
          label: curr.name,
          value: curr._id,
          ...curr,
          rightsholders,
        };
        return acc;
      }, {}),
    [contracts]
  );
  const createExpenseMutation = useMutation(createExpense);
  const updateExpenseMuation = useMutation(updateExpense);

  const handleSubmit = (values, formActions) => {
    const payload = {
      name: values.name,
      contract: values.contract._id,
      expenseDate: values.expenseDate,
      expenseSplits: values.expenseSplits,
      notes: values.notes,
    };

    if (edit) {
      updateExpenseMuation.mutate(
        { expenseId: expense._id, ...payload },
        {
          onSuccess: () => {
            setShowToast({
              open: true,
              message: "Expense updated successfully",
              type: "success",
            });
            setTimeout(() => {
              formActions.setSubmitting(false);
              onUpdate({ isOpen: false });
            }, 1250);
            refetch();
          },
          onError: (e) => {
            formActions.setSubmitting(false);
            formActions.resetForm();
            setShowToast({
              open: true,
              message: e.response.data.message
                ? e.response.data.message
                : "An unexpected error occured. Please try again.",
              type: "danger",
            });
          },
        }
      );
    } else {
      createExpenseMutation.mutate(payload, {
        onSuccess: () => {
          setShowToast({
            open: true,
            message: "Expense has been created successfully",
            type: "success",
          });
          setTimeout(() => {
            formActions.setSubmitting(false);
            onUpdate({ isOpen: false });
          }, 1250);
          refetch();
        },
        onError: (e) => {
          formActions.setSubmitting(false);
          formActions.resetForm();
          setShowToast({
            open: true,
            message: e.response.data.message
              ? e.response.data.message
              : "An unexpected error occured. Please try again.",
            type: "danger",
          });
        },
      });
    }
  };

  return (
    <>
      <Toast
        open={showToast.open}
        onClose={() =>
          setShowToast((prevState) => ({ open: false, type: prevState.type }))
        }
        toastMessage={showToast.message}
        toastType={showToast.type}
      />

      <Formik
        enableReinitialize
        initialValues={{
          name: expense?.name ? expense?.name : "",
          contract: { label: "", value: "" },
          expenseDate: expense?.expenseDate
            ? expense?.expenseDate?.split("T")[0]
            : "",

          expenseSplits: [],
          notes: expense?.notes ? expense?.notes : "",
          totalPaidAmount: 0,
          totalExpenseSplitPercentage: 100,
        }}
        onSubmit={handleSubmit}
        validationSchema={ExpenseValidationSchema}
      >
        {(props) => {
          const {
            values: {
              name,
              contract,
              expenseDate,
              totalPaidAmount,
              totalExpenseSplitPercentage,
              notes,
            },
            errors,
            touched,
            dirty,
            isValid,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          } = props;

          const handleContractChange = (value) => {
            if (value && contractOptions[value]) {
              const expenseSplits = contractOptions[value]?.rightsholders?.map(
                (rh) => ({
                  rightholder: rh._id,
                  rightholderName: `${rh?.contact?.firstName ?? ""} ${
                    rh?.contact?.lastName ?? ""
                  }`.trim(),
                  rightholderType: rh.type,
                  paidAmount: 0,
                  expenseShare: rh.expenseShare ?? 0,
                  recoupableAmount: 0,
                })
              );

              setFieldValue("contract", contractOptions[value]);
              setFieldValue("expenseSplits", expenseSplits);
            }
          };

          return (
            <div className="row mx-0 pb-4">
              {/* name */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={(name ? "" : "d-none") + " inputUpperComment iuc1"}
                >
                  Name*
                </div>
                <InputComponent
                  value={name}
                  commentField=".iuc1"
                  placeholder="Expense Name*"
                  autofocus
                  inputGroupClassName="nrInputGroup nrInputGroup-title w-100"
                  inputClassName={
                    (name ? "interMediumInput interMediumPaddingTop" : "") +
                    " nrInput"
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("name", value)}
                  inputBlurCallback={() => setFieldTouched("name", true)}
                />
                <ErrorMessage
                  name={"name"}
                  component="div"
                  className="row text-danger line-height-normal ml-3 my-2 fs-12 "
                />
              </div>

              {/* Contracts */}
              {!edit && (
                <>
                  <div className="py-2 py-md-0 col-12 mb-3">
                    <div
                      className={
                        (contract ? "" : "d-none") +
                        " inputUpperComment utiuc1_" +
                        contract
                      }
                    >
                      Contract*
                    </div>

                    <SelectComponent
                      value={contract.label}
                      extendOptionWidth
                      valuePaddingTop={true}
                      placeholderTop="50%"
                      placeholderFont="Inter-Medium"
                      placeholderColor="white"
                      placeholderOpacity="1"
                      placeholder="Select Contract*"
                      options={Object.values(contractOptions)}
                      customNoOptionsMessage={
                        <p className="ml-2">No Contracts</p>
                      }
                      selectChangeCallback={handleContractChange}
                      selectBlurCallback={() =>
                        setFieldTouched("contract", true)
                      }
                    />
                    <p className="row text-danger line-height-normal ml-3 my-2 fs-12 ">
                      {touched?.contract && errors?.contract?.label}
                    </p>
                  </div>
                </>
              )}

              {/* Expense date */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    "inputUpperComment iuc6 " + (expenseDate || "d-none")
                  }
                >
                  Expense date*
                </div>
                <InputComponent
                  value={expenseDate} // take from calendar, or pre-populate
                  paddingTopOnInput={true}
                  inputGroupClassName="nrInputGroup w-100"
                  inputClassName={
                    "nrInput nrInput-opacityFix " +
                    (expenseDate || expenseDate.length
                      ? "nrCalendarInputPopulated"
                      : "")
                  }
                  commentField=".iuc6"
                  placeholder="Expense date*"
                  addonClickCallback={handleOpenCalendar}
                  for="nrReleaseDate"
                  id="startDate"
                  // calendar addon
                  addon="right"
                  calendarAddon={true}
                  calImg={calendar}
                  calImgClass="nrAddonRightIcon"
                  calAlt="pick date"
                  inputGroupTextClassName="nrRightAddon nrRightAddon-opacityFix"
                  databaseField="expenseDate"
                  changeCallback={(value) =>
                    setFieldValue("expenseDate", value)
                  }
                  inputBlurCallback={() => setFieldTouched("expenseDate", true)}
                />
                <CalendarComponent
                  calendarState={calendarState["startDate"]}
                  calendarClassName="nrReleaseDateCalendar"
                  datePickCallback={(value) =>
                    setFieldValue("expenseDate", value)
                  }
                  calendarId="expenseDate"
                  databaseField="expenseDate"
                  shouldCloseOnSelect={true}
                />
                <ErrorMessage
                  name={"expenseDate"}
                  component="div"
                  className="row text-danger line-height-normal ml-3 my-2 fs-12 "
                />
              </div>

              {contract?.label && (
                <>
                  <div
                    className="mb-4 w-100 mx-3 p-3 rounded"
                    style={{ backgroundColor: "#1d2025" }}
                  >
                    <div className="mb-1 expense-splits-header">
                      Expense payment and recouping
                    </div>
                    <div className="mb-3">
                      Enter the amount(s) paid by each contact, how you want to
                      share the costs and we'll calculate the recoupable amounts
                      for you.
                    </div>
                    <div className="row  mx-0">
                      <div className="expenseSplitsCard col mb-4 mt-2">
                        <div className=" rounded text-white py-4 px-0 px-md-3 mb-2">
                          <div className="row  align-items-end  mb-4">
                            <div className="col-8 expense-splits-header">
                              CONTACT
                            </div>
                            <div className="col-4">
                              <div className="mb-1 expense-splits-header">
                                PAID AMOUNT
                              </div>
                              <div className="fs-14">
                                Enter the amount(s) paid by each contact.
                              </div>
                            </div>
                          </div>

                          <PaidAmount />

                          <div className="row justify-content-end">
                            <div className="col-4 px-2">
                              <TotalPaidAmount value={totalPaidAmount} />
                            </div>
                          </div>
                          <ErrorMessage
                            name={"totalPaidAmount"}
                            component="div"
                            className="w-100 text-danger line-height-normal ml-3 mb-2 fs-12 "
                          />
                        </div>
                      </div>
                      <div className="expenseSplitsContainer col-sm-12  col-lg-3 mb-4 mx-sm-0 mx-lg-2 mt-2">
                        <div className=" px-0 rounded text-white py-4 mb-2">
                          <div className="row mx-0 align-items-end mb-4">
                            <div className="mb-1 expense-splits-header">
                              EXPENSE SPLIT
                            </div>
                            <div className="fs-14">
                              Prefilled according to the contract, feel free to
                              change.
                            </div>
                          </div>

                          <ExpenseSplits />

                          <TotalExpenseAmount
                            value={totalExpenseSplitPercentage}
                          />

                          <ErrorMessage
                            name={"totalExpenseSplitPercentage"}
                            component="div"
                            className="w-100 text-danger line-height-normal mt-2 mb-2 fs-12 "
                          />
                        </div>
                      </div>
                      <div className="expenseSplitsContainer  col-sm-12 col-lg-3 mb-4 mt-2">
                        <div className=" px-0 rounded text-white py-4 mb-2">
                          <div className="row mx-0 align-items-end mb-4">
                            <div className="mb-1 expense-splits-header">
                              RECOUPABLE AMOUNT
                            </div>
                            <div className="fs-14">
                              What each contact will receive or pay.
                            </div>
                          </div>

                          <TotalRecoupingAmount />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Notes */}
              <div className="py-2 py-md-0 col-12 mb-3">
                <div
                  className={
                    (notes ? "" : "d-none") + " inputUpperComment iuc5a"
                  }
                >
                  Notes
                </div>
                <InputComponent
                  value={notes}
                  commentField=".iuc5a"
                  placeholder={`${edit ? "Edit" : "Enter"} notes here...`}
                  autofocus
                  inputGroupClassName="nrInputGroup utepContributorsEmailInputGroup w-100 utepLyricsGroup"
                  inputClassName={
                    "utepInput" +
                    (notes ? " interMediumInput interMediumPaddingTop" : "")
                  }
                  paddingTopOnInput={true}
                  addon={false}
                  changeCallback={(value) => setFieldValue("notes", value)}
                  inputBlurCallback={() => setFieldTouched("notes", true)}
                  inputType="textarea"
                />
              </div>

              <div className="ml-0 row col justify-content-end  align-items-end mt-4">
                <ButtonComponent
                  onClick={onClose}
                  className="playtreksButton w-100 h-100 bg-transparent textLink mr-2"
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  disabled={isSubmitting || !dirty || !isValid}
                  onClick={handleSubmit}
                  buttonWrapperClassName="pr-3"
                  className="playtreksButton   w-100 h-100"
                >
                  {isSubmitting ? (
                    <>
                      <span className="mr-2">Saving... </span>
                      <span>
                        <Spinner />
                      </span>
                    </>
                  ) : (
                    "Save"
                  )}
                </ButtonComponent>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default ExpenseForm;
