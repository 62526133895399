import React from "react";

import ButtonComponent from "../../../form/ButtonComponent";

// aggregation types (groupings)
const DATE_RANGE = "date_range";
const DSP = "dsp";
const COUNTRY = "country";
const RELEASE = "release";
const TRACK = "track";
const ARTIST = "artist";
const USER = "user";

function Groupings({ currentAggregation, setCurrentAggregation, isAdmin }) {
  // AGGREGATIONS / GROUPINGS //
  // current aggregation buttons
  let aggregationPickerConfig = [
    { agg: DATE_RANGE, title: "Month" },
    { agg: DSP, title: "Store" },
    { agg: COUNTRY, title: "Country" },
    { agg: ARTIST, title: "Artist" },
    { agg: RELEASE, title: "Release" },
    { agg: TRACK, title: "Track" },
  ];

  if (isAdmin) aggregationPickerConfig.push({ agg: USER, title: "User" });

  return aggregationPickerConfig?.map((button) => (
    <ButtonComponent
      onClick={() => setCurrentAggregation(button.agg)}
      className={
        "playtreksButton mb-3 " +
        (currentAggregation === button.agg ? "groupBtnActive" : "")
      }
    >
      {button.title}
    </ButtonComponent>
  ));
}

export default Groupings;
