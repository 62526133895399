import React, { useCallback, memo } from "react";
import Spinner from "../../../assets/spinner.svg";
import PlaylistPerformanceLineChart from "../../../components/HighCharts/PlaylistPerformanceLineChart";
import { isEqual } from "lodash";
import { trackParse } from "../../../components/utils";
import SelectComponent from "../../../components/form/SelectComponent";
import { getCountriesOptions } from "../utils";
import { ItemTrackList } from "../../../components/content/Item";

const countriesOptions = getCountriesOptions();

const EntityHistoricPerformance = memo(
  ({ data, loading, setCountryFilter, countryFilter }) => {
    const handleFilterChange = useCallback(
      (value, dbField, commentField, databaseKey, type) => {
        if (type?.action !== "input-change") {
          setCountryFilter(countriesOptions[value]);
        }
      },
      []
    );

    return (
      <div className="dashboard-entitycharts">
        <div className="dashboard-playlists-top-social-cards">
          {/* Filters */}
          <div className="row justify-content-between align-items-center mx-0 mb-2">
            <p className="mb-2 text-white font-weight-bold">
              Historic performance
            </p>
            <div className={"col col-lg-3 py-2 mt-0 text-white"}>
              <div className="inputUpperComment iuc3 playtreksSelector ">
                Country
              </div>
              <SelectComponent
                value={countryFilter.label}
                valuePaddingTop={true}
                placeholderTop="50%"
                placeholderFont="Inter-Medium"
                placeholderColor="white"
                placeholderOpacity="1"
                options={Object.values(countriesOptions)}
                customNoOptionsMessage={<p classNam="ml-2">No options</p>}
                selectChangeCallback={handleFilterChange} // to be used for data transfer
                selectBlurCallback={() => {}} // leave empty
                inputGroupClassName="mb-0"
              />
            </div>
          </div>

          {data.length && !loading ? (
            <>
              <span className="row item colorSecondary itemWrapper iw0 mx-0">
                <div
                  className={`playlistInfoWrapper piw0 col-xl-4 col-lg-4 col-md-4 m-2 p-3 leftPane`}
                >
                  <span>
                    <br />
                    <p className="text-white mb-3 font-weight-bold">
                      Tracks in chart
                    </p>
                    <div className="small">
                      <ItemTrackList
                        trackListObj={trackParse(data)}
                        platform={"spotify"}
                        containerStyle={{
                          maxHeight: "18.25rem",
                          overflowY: "scroll",
                        }}
                      />
                    </div>
                  </span>
                </div>
                <div
                  className={`gbw${0} col-md col-xl col-lg m-md-2 m-lg-2 m-xl-2 rightPane`}
                >
                  <div className={`graphWrapper gw${0}`}>
                    <PlaylistPerformanceLineChart
                      data={data}
                      platform={"spotify"}
                    />
                  </div>
                </div>
              </span>
            </>
          ) : (
            !loading && (
              <div className="row justify-content-center">
                <p className="font-weight-bold text-white">
                  No historic data found
                </p>
              </div>
            )
          )}
          {loading && (
            <div className="xyCenter" style={{ zIndex: 1 }}>
              <img
                src={Spinner}
                alt="loading"
                className="dashboard-overview-streaming-stats-spinner"
              />
            </div>
          )}
        </div>
      </div>
    );
  },
  isEqual
);

export default EntityHistoricPerformance;
