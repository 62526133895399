import React from "react";

const BlankPage = () => (
  <div>
    {/* <div className="backgroundBottom" />
    <div className="obBackground" /> */}
  </div>
);

export default BlankPage;
